import React, { useState, Fragment, useEffect } from "react";
import {
    DEDALO_ALL_CHAT_MSM, DEDALO_NEW_CHAT_MSM
} from "../../assets/APIAdresses";
import {
    Button, InputGroup, Form, Row, Col,
    Card, Container,
} from 'react-bootstrap';
import './Chat.scss';
import { socket } from '../../assets/js/conexion_socket';
import { useUserSession } from "../../hooks";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Swal from "sweetalert2";
import Img1 from './img/hombre.png';
import Img2 from './img/mujer.png';
import Img3 from './img/grupo.png';
import Img4 from './img/chat.png';
import soundFile from "./sonidos/Whatsapp-29.mp3";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

const Chat_btn = () => {
    const dedalo_usr = JSON.parse(localStorage.getItem('dedalo_usr'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [message, setMessage] = useState('');
    const [msmusr, setMsmUsr] = useState('');
    const [messages, setMessages] = useState([]);
    const [messageusr, setMessageUsr] = useState('');
    const [messagesusr, setMessagesUsr] = useState([]);
    const [text, setText] = useState([]);
    const [chatg, setChatG] = useState(false);
    const [usrSelect, setUsrSelect] = useState(0);
    const [chatini, setChatini] = useState(false);
    const [messagetyping, setMessageTyping] = useState('');
    const [messagetyping1, setMessageTyping1] = useState('');
    const [usr_conn, setUsr_Conn] = useState([]);
    const [chatnoti, setChatNoti] = useState([]);
    const [archivoSubido, setArchivoSubido] = useState('');
    const [archivobase64, setArchivoBase64] = useState([]);
    const [notification, setNotification] = useState(false);
    const notificationSound = new Audio(soundFile);

    let users = [];

    const [categor, setCategor] = useState({
        datos: [],
    });

    const peticionGetCategor = async () => {
        const params_data = {
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'created_at',
            order: 'desc',
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CHAT_MSM,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice.data);
                    setCategor({
                        ...categor,
                        ['datos']: datanotice.records,
                    });
                } else {
                    setCategor({
                        ...categor,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert('error: ' + error.response.data);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
                }
            });

        return info1;
    }

    const abrir_general = () => {
        setChatG(true);
        setChatini(true);
    };

    const menu_inicial = () => {
        setChatG(false);
        setChatini(false);
        setText([]);
        setChatNoti([]);
    };

    const abrir_chats_select = (id, id_socket, name_user) => {

        let info_us = [];

        // usr_conn.map((usuario) => {
        //     if (usuario.idUser === id) {
        //         info_us.push(usuario);
        //     }
        // });

        users.map((usuario) => {
            if (usuario.idUser === id) {
                info_us.push(usuario);
            }
        });

        setText([{ uno: id, idsocket: id_socket, from: name_user }]);

        setUsrSelect(id_socket);
        setChatG(false);
        setChatini(true);
        setChatNoti([]);
    };

    const getEmojiClick = (emoji) => {
        let mensaje11 = message;
        let emoji_select = emoji.native;
        let unido = mensaje11 + emoji_select;

        setMessage(unido);
    };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        socket.emit('dl:typing', user.name);
    };

    const handleInputChange1 = (e) => {
        setMessageUsr(e.target.value);
        socket.emit('dl:typing_users', user.name);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (message === '') {
            Swal.fire("!! Atencion !!", "No se puede enviar el mensaje, campo vacio", "info");
        } else {
            socket.emit('dl:message', message, user.name);

            const newMessage = {
                body: message,
                from: user.name
            };

            setMessages([newMessage, ...messages]);
            setMessage('');
            setMessageTyping('');
        }
    };

    useEffect(() => {
        const receiveMessage = (message) => {
            setMessages([message, ...messages]);
            setMessageTyping('');
        };

        socket.on('message', receiveMessage);

        return () => {
            socket.off('message', receiveMessage);
        };
    }, [messages]);

    socket.on('dl:usernames', (data) => {
        setUsr_Conn(data);
        //console.log(data); 
    });

    socket.on('dl:typing', (data) => {
        setMessageTyping(data);
    });

    socket.on('dl:typing_users', (data) => {
        setMessageTyping1(data);
    });

    let text_info = text[0] === undefined ? '' : text[0];


    const getEmojiClick2 = (emoji2) => {
        let mensaje12 = messageusr;
        let emoji_select2 = emoji2.native;
        let unido2 = mensaje12 + emoji_select2;
        //console.log(unido2);
        setMessageUsr(unido2);
    };

    const adjuntarArchivo = (e) => {
        document.getElementById("file_input").click();
        let mensaje12 = messageusr;
        let nom_archivo = e.target.files === undefined ? '' : e.target.files[0].name;
        let unido2 = mensaje12 + nom_archivo;

        //console.log(nom_archivo);
        setArchivoSubido(nom_archivo);
        setMessageUsr(unido2);
        archivos_base64(nom_archivo);
    };

    let ext_arc = archivoSubido === '' ? '' : archivoSubido.split('.')[1];

    const archivos_base64 = (nom) => {
        let arch = document.getElementById("file_input").files;

        if (arch.length > 0) {
            var value = arch[0];
            var reader = new FileReader();
            var base64;

            reader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                setArchivoBase64([...archivobase64, { user: nom, arch: base64 }]);
            }

            reader.readAsDataURL(value);
        }
    };

    const enviar_mensaje = (e) => {
        e.preventDefault();

        if (messageusr === '') {
            Swal.fire("!! Atencion !!", "No se puede enviar el mensaje, campo vacio", "info");
        } else {

            const newMessage = {
                content: messageusr,
                from: text_info.idsocket,
                envia_desde: userId,
                nom_archivo: archivoSubido,
                extension: ext_arc,
            };
            //console.log(newMessage,archivobase64);

            socket.emit("dl:private_message", {
                content: messageusr,
                to: usrSelect,
                user_id: userId,
                nom_archivo: archivoSubido,
                extension: ext_arc,
                adjunto: archivobase64
            });

            setMessagesUsr([newMessage, ...messagesusr]);
            setMessageUsr('');
            setMsmUsr('');
            setMessageTyping1('');
            setArchivoSubido('');
            handleCloseSave();
            //peticionGetCategor();
        }
    };

    const handleCloseSave = (e) => {

        let desde = userId;
        let hacia = text[0].uno;
        let mensaje = messageusr;

        const formattedData = {
            source_id: desde,
            target_id: hacia,
            message: mensaje,
        };

        axios({
            method: 'POST',
            url: DEDALO_NEW_CHAT_MSM,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    //Swal.fire("Se creó el personal exitósamente", "", "success");
                    peticionGetCategor();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
    };

    usr_conn.map(x => {
        users.push(x.name);
    });

    socket.on("dl:private_message", ({ content, from, recibe_desde, nom_archivo, extension, adjunto }) => {
        setMessagesUsr([{ content, from, recibe_desde, nom_archivo, extension, adjunto }, ...messagesusr]);
        setChatNoti([...chatnoti, { content, from, recibe_desde, nom_archivo, extension, adjunto }]);
        setNotification(true);
        setMessageTyping1('');
        setArchivoSubido('');
        setArchivoBase64(adjunto);
        //console.log({ content, from, recibe_desde, nom_archivo, extension });
    });

    useEffect(() => {
        if (notification) {
            notificationSound.play();

            const notificationTimeout = setTimeout(() => {
                setNotification(false);
            }, 2000);
            peticionGetCategor();
            return () => clearTimeout(notificationTimeout);
        }
    }, [notification]);

    useEffect(() => {
        peticionGetCategor();
    }, []);

    users.forEach(mensajes => {
        mensajes['notificaciones'] = messagesusr.filter(e => e.recibe_desde === mensajes.idUser);
    });

    const formatAMPM = () => {
        let hora_chat = new Date();
        var hours = hora_chat.getHours();
        var minutes = hora_chat.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const mostrar_chat = () => {
        let div_cuadro_mensaje = document.getElementById("div_cuadro_mensaje");
        div_cuadro_mensaje.style.display = "block";
    };

    const cerrar_chats_select = (e) => {
        let div_chat = document.getElementById('div_cuadro_mensaje');
        div_chat.style.display = 'none';
    };

    if (text.length > 0) {
        document.title = `:: DEDALO ::`;
    } else if (chatnoti.length > 0) {
        document.title = `tiene ${chatnoti.length} mensajes`;
    }

    let prueba = users;
    let setObj = new Set();

    let unique = prueba.reduce((acc, persona) => {
        if (!setObj.has(persona.nameuser)) {
            setObj.add(persona.nameuser, persona)
            acc.push(persona)
        }
        return acc;
    }, []);

    const descargar_archivos = (data_in) => {
        let arch_info = archivobase64.filter(x => x.user === data_in);
        let arch64 = arch_info[0].arch;

        const downloadinstance = document.createElement('a');
        //downloadinstance.href = url+'/'+data;
        downloadinstance.href = arch64;
        downloadinstance.target = '_blank';
        downloadinstance.download = data_in;
        downloadinstance.click();
        //console.log(downloadinstance.href);
    };

    let mensaje_bd = categor.datos === undefined ? [] : categor.datos;
    let usuario_select = text[0] === undefined ? [] : text[0].uno;
    let datainfo = [];

    mensaje_bd.forEach(element => {
        if (usuario_select === parseInt(element.target_id) || usuario_select === parseInt(element.source_id)) {
            if (userId === parseInt(element.target_id) || userId === parseInt(element.source_id)) {
                datainfo.push(element);
            }
        }
    });

    const MenuMsm = (idmsm) => {
        
        return (
            <div>
                <div class="dropdown">
                    <MdIcons.MdKeyboardArrowDown class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"/>
                    <ul class="dropdown-menu">
                        <li>
                            <a class="dropdown-item text-dark" onClick={()=>eliminarmenasje(idmsm.id)} style={{cursor: 'pointer'}}>
                                Eliminar
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const eliminarmenasje = (idmsm) =>{
        console.log('a eliminado el mensaje ' + idmsm);
    }

    //console.log(datainfo, mensaje_bd);


    return (
        <div>
            <div className="conte">
                {
                    chatnoti.length === 0 || text.length > 0 ?
                        <div>
                            <Button size="sm" style={{ backgroundColor: '#1A5276', border: '#76d7c4 1px solid' }}
                                className="mr-2 boton_chat_qc rounded-circle p-0" onClick={mostrar_chat}
                            >
                                <MdIcons.MdChatBubbleOutline size={'1.5em'} style={{ color: '#fff' }} />
                            </Button>
                        </div>
                        :
                        <div>
                            <Button size="sm" style={{ backgroundColor: '#1A5276', border: '#76d7c4 1px solid' }}
                                className="mr-2 boton_chat_qc rounded-circle p-0" onClick={mostrar_chat}
                            >
                                <div>
                                    <MdIcons.MdChatBubbleOutline size={'1.5em'} style={{ color: '#fff' }} />
                                </div>
                                <div className="noti_num"><span>{chatnoti.length}</span></div>
                            </Button>
                        </div>
                }
            </div>
            <div className="chats_abiertos" id="div_cuadro_mensaje">
                <div className="container-fluid">
                    <div className="muestra_div_chat2">
                        <div className="t1 container-fluid d-flex">
                            <div style={{ color: 'white' }} className="d-flex justify-content-center align-items-center">
                                <img src={Img4} className='img-fluid me-1' style={{ width: '2rem' }} />
                                <strong>Chat Dedalo</strong>
                            </div>
                            <div className="ms-auto d-flex justify-content-center align-items-center">
                                <Button onClick={cerrar_chats_select} title='cerrar' className="rounded-circle"
                                    style={{ background: 'transparent', border: 'none' }}>
                                    <AiIcons.AiFillCloseCircle style={{ fontSize: '1.3rem', color: '#fff' }} />
                                </Button>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <Row>
                                <Col md={4} className="px-0">
                                    <div style={{ background: '#fff', minHeight: '25rem', borderRadius: '0 0 0 14px' }} className="px-2 border-end">
                                        <div>
                                            <div className="container-fluid">
                                                <p className="mb-1 text-center">
                                                    usuarios conectados
                                                </p>
                                                <hr className="mt-0" />
                                            </div>
                                            <div className="container-fluid">
                                                <Button className="boton_chatG btn btn-light w-100" onClick={abrir_general}>
                                                    <img src={Img3} className='img-fluid me-2' style={{ width: '2rem' }} />
                                                    <span>Chat general</span>
                                                </Button>
                                                <div className="recuadro_user">
                                                    {
                                                        unique.map((usuario, ind_x) => (
                                                            usuario.nameuser === undefined || usuario.idUser === userId ?
                                                                ''
                                                                :
                                                                <div className="mb-2 mt-2" key={ind_x}>
                                                                    <Button className="validar_folders btn btn-light w-100 d-flex d-flex justify-content-center align-items-center"
                                                                        id={'user_' + usuario.id_socket}
                                                                        onClick={() => abrir_chats_select(usuario.idUser, usuario.id_socket, usuario.nameuser)}
                                                                    >
                                                                        <img src={usuario.sexuser === 'male' ? Img1 : Img2} className='img-fluid me-2'
                                                                            style={{ width: '2rem' }} />
                                                                        <span className="me-2" title={usuario.nameuser}>{usuario.nameuser}</span>
                                                                        {
                                                                            usuario.notificaciones.length === 0 || text.length > 0 || chatnoti.length === 0 ?
                                                                                ''
                                                                                :
                                                                                <div className="notiUsr">
                                                                                    <span>{usuario.notificaciones.length}</span>
                                                                                </div>
                                                                        }
                                                                    </Button>
                                                                </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8} className="px-0">
                                    <div>
                                        {chatini === false ?
                                            <div style={{ height: '28.5rem', background: '#fff' }} className='d-flex justify-content-center align-items-center'>
                                                <img src={Img4} className='img-fluid' style={{ width: '20rem' }} />
                                            </div>
                                            : chatg === false ?
                                                <div style={{ background: '#fff' }}>
                                                    <div className="px-2 border-bottom">
                                                        {text_info === '' ?
                                                            <span><b>Chat de {user.name}</b></span>
                                                            :
                                                            <div className="d-flex">
                                                                <div className="mb-1">
                                                                    <Button variant="light" size="sm" className="py-0" onClick={menu_inicial}>
                                                                        <MdIcons.MdKeyboardReturn />
                                                                    </Button>
                                                                </div>
                                                                <span>
                                                                    <b className="me-2">Chat {text_info.from}</b>
                                                                    <b className="text-muted fw-normal">{messagetyping1 === '' ? '' : <em>esta escribiendo....</em>}</b>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className="m1 container-fluid">
                                                            <div>
                                                                {datainfo.length === 0 ?
                                                                    ''
                                                                    :
                                                                    datainfo.map((msm, index) => (
                                                                        parseInt(msm.target_id) === usuario_select ?
                                                                            <div key={index} className="d-flex justify-content-end">
                                                                                <p className="px-2 m-1 text-break text-wrap"
                                                                                    style={
                                                                                        {
                                                                                            backgroundColor: "#F5B041",
                                                                                            color: "#fff", maxWidth: '70%',
                                                                                            height: "auto",
                                                                                            borderRadius: "10px 0px 10px 0px",
                                                                                        }
                                                                                    }
                                                                                >
                                                                                    <div className="d-flex">
                                                                                        <div className="me-2">
                                                                                            <span>{msm.message}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <MenuMsm id={msm.id}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-end" style={{ fontSize: '0.8rem', color: '#fff' }}>
                                                                                        {formatAMPM()}
                                                                                    </div>
                                                                                </p>
                                                                            </div>
                                                                            :
                                                                            <div key={index} className="d-flex justify-content-start">
                                                                                <p className="px-2 m-1 text-break text-wrap"
                                                                                    style={
                                                                                        {
                                                                                            backgroundColor: "#3498DB",
                                                                                            color: "#fff", maxWidth: '70%',
                                                                                            height: "auto",
                                                                                            borderRadius: "0px 10px 0px 10px",
                                                                                        }
                                                                                    }
                                                                                >
                                                                                    <div className="d-flex">
                                                                                        <div className="me-2">
                                                                                            <span>{msm.message}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <MenuMsm id={msm.id}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-end" style={{ fontSize: '0.8rem', color: '#fff' }}>
                                                                                        {formatAMPM()}
                                                                                    </div>
                                                                                </p>
                                                                            </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="f1 d-flex">
                                                        <div className="w-100 container-fluid mt-1 me-1 px-2">
                                                            <form onSubmit={enviar_mensaje}>
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        placeholder="mensaje..." aria-describedby="basic-addon1" className="mensajes_user"
                                                                        value={messageusr} onChange={handleInputChange1} id={text_info.idSocket}
                                                                        autoFocus={true}
                                                                    />
                                                                </InputGroup>
                                                            </form>

                                                        </div>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <div class="btn-group dropup">
                                                                <MdIcons.MdAttachFile type="button" class="dropdown-toggle" fontSize={22}
                                                                    data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false"
                                                                />
                                                                <ul class="bg-white dropdown-menu dropdown-menu-end dropdown-menu-lg-end mb-3">
                                                                    <li>
                                                                        <button class="text-primary dropdown-item" type="button" onClick={adjuntarArchivo}>
                                                                            Adjuntar archivo
                                                                        </button>
                                                                        <input id="file_input" type="file" style={{ display: 'none' }} onChange={adjuntarArchivo} />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <div class="btn-group dropup px-1">
                                                                <MdIcons.MdOutlineEmojiEmotions fontSize={26} color='green' class="dropdown-toggle"
                                                                    data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false"
                                                                    data-bs-auto-close="outside" style={{ cursor: 'pointer' }}
                                                                />
                                                                <ul class="bg-white dropdown-menu dropdown-menu-end dropdown-menu-lg-end mb-3">
                                                                    <li>
                                                                        <Picker data={data} onEmojiSelect={getEmojiClick2} previewPosition={'none'} searchPosition={'none'}
                                                                        />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="mensajesG">
                                                    <Card style={{ height: '28.6rem' }}>
                                                        <Card.Body className="p-0">
                                                            <div className="mb-1 d-flex">
                                                                <div className="me-2">
                                                                    <Button variant="light" size="sm" className="py-0" onClick={menu_inicial}>
                                                                        <MdIcons.MdKeyboardReturn className="me-2" />
                                                                        regresar
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    {messagetyping === '' ? '' : <em>{messagetyping} esta escribiendo....</em>}
                                                                </div>
                                                            </div>
                                                            <div className="area_msm_general1 px-2" id="area_msm_general2">
                                                                {messages.map((x, index) => (
                                                                    <div key={index} className='p-2'>
                                                                        <p className={x.from === user.name ? 'mb-0 cht ms-auto px-2' : 'mb-0 cht1 px-2'}>
                                                                            {x.from}<br />
                                                                            <span>{x.body}</span>
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <form onSubmit={handleSubmit}>
                                                                <InputGroup>
                                                                    <Form.Control
                                                                        placeholder="mensaje..." onChange={handleInputChange}
                                                                        aria-describedby="basic-addon1" value={message} autoFocus="true"
                                                                    />
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <div class="btn-group dropup px-3">
                                                                            <MdIcons.MdOutlineEmojiEmotions fontSize={26} color='green' class="dropdown-toggle"
                                                                                data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false"
                                                                                data-bs-auto-close="outside" style={{ cursor: 'pointer' }}
                                                                            />
                                                                            <ul class="bg-white dropdown-menu dropdown-menu-end dropdown-menu-lg-end mb-3">
                                                                                <li>
                                                                                    <Picker data={data} onEmojiSelect={getEmojiClick} previewPosition={'none'} searchPosition={'none'}
                                                                                    />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </InputGroup>
                                                            </form>
                                                        </Card.Footer>
                                                    </Card>
                                                </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chat_btn;