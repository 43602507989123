import React, { useState, useEffect } from 'react';
import { Redirect, Link } from "react-router-dom";
import {
  InputGroup, DropdownButton, Dropdown, Toast, Button,
  ToastContainer,
} from 'react-bootstrap';
import {
  Collapse, Navbar, NavbarToggler,
  NavbarBrand, Nav, NavItem, NavLink,
  UncontrolledDropdown, DropdownToggle,
  DropdownMenu, DropdownItem, NavbarText
} from 'reactstrap';
import {
  DEDALO_GET_ALL_USERS, DEDALO_GET_ALL_USERS_BY_ID,
} from "../assets/APIAdresses";
import axios from "axios";
import { message_responses } from "../assets/MESSAGE_RESPONSES";
import { useUserSession } from "../hooks";
import { Routes } from '../utils/routes';
import * as Faicons from 'react-icons/fa';
import * as Vscicons from 'react-icons/vsc';
import * as Aicons from 'react-icons/ai';
import * as Gricons from 'react-icons/gr';
import Cookies from "universal-cookie";
import Logo from '../imagenes/dedalo_logo.png';
import { socket } from '../assets/js/conexion_socket';

const NavbarDedalo = (props) => {
  const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
  const USER_SESSION_NAME = "dedalo_usr";
  const USER_SESSION_TOKEN = "dedalo_usr_token";
  const [isOpen, setIsOpen] = useState(false);
  const [redirectTo, setRedirect] = useState();
  const { userId } = useUserSession();
  const { user } = useUserSession();
  const { userToken } = useUserSession();
  const toggle = () => setIsOpen(!isOpen);

  const [showA, setShowA] = useState(false);
  const [creado, setCreado] = useState(false);
  const [editado, setEditado] = useState(false);
  const [notiorden, setNotiOrden] = useState([]);

  const toggleShowA = () => {
    setShowA(!showA)
    //setNotiOrden([]);
  };

  const [categor, setCategor] = useState({
    datos: [],
  });


  const onLogout = () => {
    localStorage.removeItem(USER_SESSION_NAME, { path: "/" });
    localStorage.removeItem(USER_SESSION_TOKEN, { path: "/" });
    localStorage.removeItem('projectHomi', { path: "/" });
    localStorage.removeItem('projectHomiSubtask', { path: "/" });
    localStorage.removeItem('Informe', { path: "/" });
    localStorage.removeItem('Reporte', { path: "/" });
    localStorage.removeItem('Nombre_empresa', { path: "/" });
    localStorage.removeItem('Ruta_Carpeta', { path: "/" });
    localStorage.removeItem('Project_contabilidad', { path: "/" });
    localStorage.removeItem('Project_Ficha_Tecnica', { path: "/" });

    localStorage.removeItem('emoji-mart.frequently', { path: "/" });
    localStorage.removeItem('emoji-mart.last', { path: "/" });
    window.location.href = Routes.LOGIN;
  };

  const PeticionUsuarios = async () => {
    if (userId === undefined) {
      let tr = 0;
    } else {
      //let info1 = await axios.get(DEDALO_GET_ALL_USERS_BY_ID+'&id='+userId)
      let info1 = await axios({
        method: 'POST',
        url: DEDALO_GET_ALL_USERS_BY_ID + '&id=' + userId,
        headers: { Authorization: userToken },
      })
        .then(response => {
          if (response.data.code === '200') {
            let datanotice = response.data.msg.data;
            //console.log(datanotice);
            setCategor({
              ...categor,
              ['datos']: datanotice,
            });
          } else {
            alert(message_responses[response.data.message]);
          }
        })
        .catch(error => {
          if (error.response && error.response.data) {
            alert(
              error.response.data.message === "VALIDATOR_ERROR"
                ? error.response.data.data.error_message
                : message_responses[error.response.data.message]
            );
          } else {
            alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
          }
        });

      return info1;
    }
  }

  useEffect(() => {
    PeticionUsuarios();
  }, []);

  const cambio_empresa = (IdCambio) => {
    let cambio = user.enterprices.find(c => c.id === parseInt(IdCambio));
    localStorage.setItem("Nombre_empresa", JSON.stringify(
      {
        NomEmp: cambio.name,
        IdEmp: cambio.id,
        RutEmp: cambio.rut,
        DiasLicence: cambio.expiration,
      }
    ));
    window.location.href = Routes.HOME;
  }

  socket.on("dl:nueva_orden", (data) => {
    setNotiOrden([data]);
    setCreado(true);

  });

  socket.on("dl:edicion_orden", (data) => {
    setNotiOrden([data]);
    setEditado(true);
  });

  useEffect(() => {
    if(creado){
      let btn_noti = document.getElementById('btnnoti');
      btn_noti.click()
    }
  }, [creado]);

  useEffect(() => {
    if(editado){
      let btn_noti = document.getElementById('btnnoti');
      btn_noti.click()
    }
  }, [editado]);

  let tipo = notiorden[0] === undefined ? '' : notiorden[0].tipo;
  let usuario = notiorden[0] === undefined ? '' : notiorden[0].usuario;
  let orden = notiorden[0] === undefined ? '' : notiorden[0].orden === '' ? '' : notiorden[0].orden;
  //console.log(notiorden,orden);

  const botonnavbar = () => {
    switch (props.modulo) {
      case 'Usuario Nuevo':
        return (
          <div>
            <Navbar color="light" light expand="md">
              <NavbarBrand href="/">{props.modulo}</NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <NavLink href="#" onClick={() => { window.location.href = Routes.LOGIN }}>Ingresar</NavLink>
                </NavItem>
              </Nav>
            </Navbar>
          </div>
        )
        break;
      case 'Recuperar Contraseña':
        return (
          <div>
            <Navbar color="light" light expand="md">
              <NavbarBrand href="/">{props.modulo}</NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <NavLink href="#" onClick={() => { window.location.href = Routes.LOGIN }}>Ingresar</NavLink>
                </NavItem>
              </Nav>
            </Navbar>
          </div>
        )
        break;
      case 'Reestablecer contraseña':
        return (
          <div>
            <Navbar color="light" light expand="md">
              <NavbarBrand href="/">{props.modulo}</NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <NavLink href="#" onClick={() => { window.location.href = Routes.LOGIN }}>Ingresar</NavLink>
                </NavItem>
              </Nav>
            </Navbar>
          </div>
        )
        break;
      default:
        return (
          <div>
            <div>
              <Navbar color="light" light expand="md" className="seccion_menu">
                <NavbarBrand >
                  <div className='d-flex'>
                    <div className='ml-4 d-flex justify-content-center align-items-center'>
                      <span className="media-body d-block">
                        <span className="text-big font-weight-bolder">
                          {data_emp.NomEmp}
                        </span>
                        <br />
                        <small className="text-muted" style={{ fontSize: '80%' }}>
                          {data_emp.RutEmp}
                        </small>
                      </span>
                    </div>
                  </div>
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="ms-auto" navbar >
                    <div className="ms-auto d-flex justify-content-center align-items-center me-2 notibar">
                      <Button className='rounded-circle p-0 btnnoti d-none' size="sm" onClick={toggleShowA} id='btnnoti' name='btnnoti'>
                        <Aicons.AiOutlineBell fontSize={26} />
                      </Button>
                    </div>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        {user.image === undefined || user.image === null || user.image.split('/')[0] === 'Recordings' || user.image === '' || user.image.split('.')[1] === 'undefined' ?
                          <img src={Logo} className="me-2" style={{ width: "50px", height: "50px", borderRadius: "30px" }} />
                          :
                          <img src={categor.datos.image} className="me-2" style={{ width: "50px", height: "50px", borderRadius: "30px" }} />
                        }
                        {props.usuario}
                      </DropdownToggle>
                      <DropdownMenu right >
                        <Link to={Routes.PERFIL_DEDALO} style={{ color: "black", textDecoration: "none" }}>
                          <DropdownItem>
                            <Faicons.FaUserCog className="me-2" />
                            perfil
                          </DropdownItem>
                        </Link>
                        <Link to={Routes.RESET_PASS_LOGIN} style={{ color: "black", textDecoration: "none" }}>
                          <DropdownItem>
                            <Gricons.GrShieldSecurity className="me-2" />
                            Seguridad
                          </DropdownItem>
                        </Link>

                        {user.enterprices.length > 1 &&
                          <InputGroup className="mb-3 boton_menu"> {/* boton desplegable de empresas */}
                            <DropdownButton
                              variant="outline-secondary"
                              title="Cambiar Empresa"
                              id="input-group-dropdown-1"
                            >
                              {user.enterprices.map((emp, indexemp) => (
                                emp.expiration <= 0 ?
                                  <Dropdown.Item key={indexemp} disabled>
                                    <span className="text-muted">
                                      {emp.name} - Licencia vencida
                                    </span>
                                  </Dropdown.Item>
                                  :
                                  <Dropdown.Item key={indexemp} onClick={() => cambio_empresa(emp.id)}>
                                    {emp.name}
                                  </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </InputGroup>
                        }

                        <DropdownItem divider />
                        <DropdownItem onClick={() => onLogout()}>
                          <Gricons.GrSecure className="me-2" />
                          Cerrar sesion
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
            <div className="bg-dark position-relative" style={{ zIndex: '100' }}>
              <ToastContainer className="p-3" position={'top-end'}>
                <Toast show={showA} onClose={toggleShowA} autohide delay={6000} style={{ background: 'lightblue' }}>
                  <Toast.Header>
                    <img
                      src="holder.js/20x20?text=%20"
                      className="rounded me-2"
                      alt=""
                    />
                    <strong className="me-auto">Nueva Orden</strong>
                    <small>11 mins ago</small>
                  </Toast.Header>
                  <Toast.Body>
                    <p>
                      el usuario {usuario} {tipo === 'nuevo' ? 'ha generado una nueva orden de compra' : `ha editado la orden de compra num ${orden}`}
                    </p>
                  </Toast.Body>
                </Toast>
              </ToastContainer>
            </div>
          </div>
        )
        break;
    }
  }

  if (redirectTo !== undefined) {
    if (window.location.pathname === redirectTo) {
      setRedirect(undefined);
    } else {
      return <Redirect push to={redirectTo} />;
    }
  }

  return (
    <div>
      {botonnavbar()}
    </div>
  );
}

export default NavbarDedalo;
