import React, { useState, Fragment, useEffect, useRef } from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, ListGroup, Table, DropdownButton, ButtonGroup, Dropdown,
    Card, Container,
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_NO_CONFORMIDAD, DEDALO_NO_CONFORMIDAD_BY_ID,
} from "../../../../assets/APIAdresses";
import { message_responses } from "../../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../../utils/utils";
import { useUserSession } from "../../../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../../../utils/routes';
import { Mensaje_Error_NoConformidad } from '../../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Img1 from '../../imagenes_reportes/logo_geo.png';
import * as MdIcons from "react-icons/md";
import { enIE } from "date-fns/locale";

const Elemento_No_Conforme_Edit = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_Ficha_Tecnica'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [acumulado, setAcumulado] = useState([]);
    const [resacumulado, setResAcumulado] = useState([]);

    let { IdElementEdit } = useParams();

    const [dats, setDat] = useState({
        numero: '',
        objetivo: '',
        nom_ind: '',
        cargos: '',
        periodo: '',
        year: '',
        proceso: '',
        meta: '',
        formulacion: '',
        que_mide: '',
        frecuencia: '',
        fuente: '',
        herramientas: '',
        analisis: '',
        bimonthly: '',
        month_value1: '',
        month_value2: '',
    });

    const peticionGetVendorById = async () => {
        let id_noconformidad = parseInt(IdElementEdit);

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_NO_CONFORMIDAD_BY_ID + '&id=' + id_noconformidad,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice.data);
                    setDat({
                        ...dats,
                        ['numero']: datanotice.number_not_conforming,
                        ['objetivo']: datanotice.objetive,
                        ['nom_ind']: datanotice.indicator,
                        ['cargos']: datanotice.responsible,
                        ['periodo']: datanotice.period_not_conforming,
                        ['year']: datanotice.not_conforming_year,
                        ['proceso']: datanotice.process,
                        ['meta']: datanotice.goal,
                        ['formulacion']: datanotice.formulation,
                        ['que_mide']: datanotice.it_measure,
                        ['frecuencia']: datanotice.measurement_frequency,
                        ['fuente']: datanotice.data_source,
                        ['herramientas']: datanotice.analysis_tool,
                        ['analisis']: datanotice.analysis_information,
                        ['bimonthly']: datanotice.bimonthly,
                        ['month_value1']: datanotice.month_value1,
                        ['month_value2']: datanotice.month_value2,
                    });
                    cuadro_calificacion_res(datanotice.bimonthly, datanotice.month_value1, datanotice.month_value2);
                } else {
                    setDat({
                        ...dats,
                        ['numero']: '',
                        ['objetivo']: '',
                        ['nom_ind']: '',
                        ['cargos']: '',
                        ['periodo']: '',
                        ['year']: '',
                        ['proceso']: '',
                        ['meta']: '',
                        ['formulacion']: '',
                        ['que_mide']: '',
                        ['frecuencia']: '',
                        ['fuente']: '',
                        ['herramientas']: '',
                        ['analisis']: '',
                        ['bimonthly']: '',
                        ['month_value1']: '',
                        ['month_value2']: '',
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas By_id");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetVendorById();
    }, []);

    const printContainer = useRef(null);

    const createPDF = async () => {
        let year = new Date().getFullYear();
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;

        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `elemento_no_conforme_${fecha}.pdf`
        });
    };

    const handleInputChangeObs = (event) => {
        let ene_legal = document.getElementById('ene').value;
        let feb_legal = document.getElementById('feb').value;
        let mar_legal = document.getElementById('mar').value;
        let abr_legal = document.getElementById('abr').value;
        let may_legal = document.getElementById('may').value;
        let jun_legal = document.getElementById('jun').value;
        let jul_legal = document.getElementById('jul').value;
        let ago_legal = document.getElementById('ago').value;
        let sep_legal = document.getElementById('sep').value;
        let oct_legal = document.getElementById('oct').value;
        let nov_legal = document.getElementById('nov').value;
        let dic_legal = document.getElementById('dic').value;

        let res1_legal = document.getElementById('res1').value;
        let res2_legal = document.getElementById('res2').value;
        let res3_legal = document.getElementById('res3').value;
        let res4_legal = document.getElementById('res4').value;
        let res5_legal = document.getElementById('res5').value;
        let res6_legal = document.getElementById('res6').value;

        let arr_meses = [
            { meses: 'ENE/FEB', m1: ene_legal, m2: feb_legal },
            { meses: 'MAR/ABR', m1: mar_legal, m2: abr_legal },
            { meses: 'MAY/JUN', m1: may_legal, m2: jun_legal },
            { meses: 'JUL/AGO', m1: jul_legal, m2: ago_legal },
            { meses: 'SEP/OCT', m1: sep_legal, m2: oct_legal },
            { meses: 'NOV/DIC', m1: nov_legal, m2: dic_legal },
        ];

        let res_acumulado = [
            { meses: 'ENE/FEB', res: res1_legal },
            { meses: 'MAR/ABR', res: res2_legal },
            { meses: 'MAY/JUN', res: res3_legal },
            { meses: 'JUL/AGO', res: res4_legal },
            { meses: 'SEP/OCT', res: res5_legal },
            { meses: 'NOV/DIC', res: res6_legal },
        ];

        setAcumulado(arr_meses);
        setResAcumulado(res_acumulado);

        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    let sumall_m1 = acumulado.map(item => parseInt(item.m1 === '' ? 0 : item.m1)).reduce((prev, curr) => prev + curr, 0);
    let sumall_m2 = acumulado.map(item => parseInt(item.m2 === '' ? 0 : item.m2)).reduce((prev, curr) => prev + curr, 0);
    let suma_total = (sumall_m1 + sumall_m2);
    let promedio = suma_total / 12
    promedio = promedio.toFixed(4);

    let sumall_res = resacumulado.map(item => parseInt(item.res === '' ? 0 : item.res)).reduce((prev, curr) => prev + curr, 0);
    let promedio_res = sumall_res / 6;
    promedio_res = promedio_res.toFixed(4);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };

    const labels = ['ENE/FEB', 'MAR/ABR', 'MAY/JUN', 'JUL/AGO', 'SEP/OCT', 'NOV/DIC'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Resultados',
                data: resacumulado.map(x => x.res),
                backgroundColor: '#2980b9',
            },
        ],
    };

    const cuadro_calificacion_res = (d1, d2, d3) => {
        let meses_dats1 = d1.split('/');
        let meses_dats2 = d1.split('/');
        let n1 = parseInt(d2);
        let n2 = parseInt(d3);

        let bimeses = ['ENE/FEB', 'MAR/ABR', 'MAY/JUN', 'JUL/AGO', 'SEP/OCT', 'NOV/DIC'];
        let calificacion_promedio = [];
        let res_promedio = [];

        document.getElementById('ene').value = meses_dats1[0].toLowerCase() === 'ene' ? n1 : 0;
        document.getElementById('feb').value = meses_dats2[1].toLowerCase() === 'feb' ? n2 : 0;
        document.getElementById('mar').value = meses_dats1[0].toLowerCase() === 'mar' ? n1 : 0;
        document.getElementById('abr').value = meses_dats2[1].toLowerCase() === 'abr' ? n2 : 0;
        document.getElementById('may').value = meses_dats1[0].toLowerCase() === 'may' ? n1 : 0;
        document.getElementById('jun').value = meses_dats2[1].toLowerCase() === 'jun' ? n2 : 0;
        document.getElementById('jul').value = meses_dats1[0].toLowerCase() === 'jul' ? n1 : 0;
        document.getElementById('ago').value = meses_dats2[1].toLowerCase() === 'ago' ? n2 : 0;
        document.getElementById('sep').value = meses_dats1[0].toLowerCase() === 'sep' ? n1 : 0;
        document.getElementById('oct').value = meses_dats2[1].toLowerCase() === 'oct' ? n2 : 0;
        document.getElementById('nov').value = meses_dats1[0].toLowerCase() === 'nov' ? n1 : 0;
        document.getElementById('dic').value = meses_dats2[1].toLowerCase() === 'dic' ? n2 : 0;

        for (let i = 0; i < bimeses.length; i++) {
            if (bimeses[i].toLowerCase() === d1.toLowerCase()) {
                document.getElementById('res' + (i + 1)).value = 1;
                calificacion_promedio.push({ meses: bimeses[i], m1: n1, m2: n2 },);
                res_promedio.push({ meses: bimeses[i], res: 1 },);
            } else {
                document.getElementById('res' + (i + 1)).value = 0;
                calificacion_promedio.push({ meses: bimeses[i], m1: 0, m2: 0 },);
                res_promedio.push({ meses: bimeses[i], res: 0 },);
            }
        }

        setAcumulado(calificacion_promedio);
        setResAcumulado(res_promedio);
    };

    const handleCloseSave = (e) => {
        let id_noconformidad = parseInt(IdElementEdit);
        let valor1 = 'dats.document_number';
        let valor2 = 'dats.valor_letras';
        let bi = '';
        let n1 = 0;
        let n2 = 0;

        for (let i = 0; i < acumulado.length; i++) {
            if (parseInt(acumulado[i].m1) === 1 || parseInt(acumulado[i].m2) === 1) {
                bi = acumulado[i].meses;
                n1 = parseInt(acumulado[i].m1);
                n2 = parseInt(acumulado[i].m2);
            }
        };

        const formattedData = {
            enterprice_id: data_emp2.IdEmp,
            constructions_id: data_emp.Id_Project_Conta,
            users_id: userId,
            number_not_conforming: dats.numero,
            period_not_conforming: dats.periodo,
            process: dats.proceso,
            not_conforming_year: dats.year,
            responsible: dats.cargos,
            objetive: dats.objetivo,
            indicator: dats.nom_ind,
            goal: dats.meta,
            formulation: dats.formulacion,
            it_measure: dats.que_mide,
            measurement_frequency: dats.frecuencia,
            data_source: dats.fuente,
            analysis_tool: dats.herramientas,
            bimonthly: bi,
            month_value1: n1,
            month_value2: n2,
            analysis_information: dats.analisis,
        };
        console.log(formattedData);

        /*if(valor2 === ''){
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible guardar el anticipo', "info");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_NO_CONFORMIDAD+'&id='+id_noconformidad,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    if(sumall_res > 4){
                        Swal.fire(
                            "!!! Atencion !!!",
                            "se ha guardado la ficha tecnica, pero se a encontrado mas de 4 no conformidades permitidas",
                            "success"
                        );
                        setAcumulado([]);
                        setResAcumulado([]);
                    }else{
                        Swal.fire("Se ha creado la cotizacion exitósamente", "", "success");
                        setAcumulado([]);
                        setResAcumulado([]); 
                    }
                }else {
                    Mensaje_Error_NoConformidad(response.data.msg.error)
                }
            })
            .catch(error => {
                manageError(error);
            });
        }*/
    };



    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371" }}
                            onClick={handleCloseSave}
                        >
                            Guardar Elemento no conforme
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm"
                            onClick={() => createPDF()} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br />
            <div style={{ paddingTop: '0.5em' }} ref={printContainer} className="container container-fluid">
                <div className="border border-dark container container-fluid" style={{ padding: '1em 1em 1em 1em' }}>
                    <Row className="border border-dark mx-2"> {/*  cabecera del formato */}
                        <Col md={2} className="text-center py-1 border-end border-dark">
                            <img src={Img1} className='img-fluid' width={230} />
                        </Col>
                        <Col md={8} className="d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <h3>
                                    <b>FICHA TECNICA DE INDICADORES</b>
                                </h3>
                            </div>
                        </Col>
                        <Col md={2} className="text-center py-1 border-start border-dark">
                            <div>
                                Codigo: FT-GC-01-01
                            </div>
                            <div>
                                Fecha: 2/18/2013
                            </div>
                            <div>
                                Pagina 1 de 1
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row className="border border-dark mx-2">
                        <Col md={12}>
                            <Row className='text-center border-bottom border-dark' style={{ background: '#f5b7b1' }}>
                                <Col md={3} className='border-end border-dark'>
                                    <b>AÑO</b>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <b>PERIODO DE MEDICION</b>
                                </Col>
                                <Col md={6}>
                                    <b>CARGOS RESPONSABLE DE LA MEDICION</b>
                                </Col>
                            </Row>
                            <Row className='text-center'>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.year}
                                        aria-describedby="basic-addon1" type="number" id="year" name="year"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.periodo}
                                        aria-describedby="basic-addon1" type="text" id="periodo" name="periodo"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.cargos}
                                        aria-describedby="basic-addon1" type="text" id="cargos" name="cargos"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row className="text-center border border-dark mx-2">
                        <Col md={3} className="d-flex justify-content-center align-items-center border-end border-dark "
                            style={{ background: '#f5b7b1' }}
                        >
                            <b>PROCESO</b>
                        </Col>
                        <Col md={9}>
                            <FormControl
                                aria-label="Nombre de la cotizacion" value={dats.proceso}
                                aria-describedby="basic-addon1" type="text" id="proceso" name="proceso"
                                className="border-0 text-center" onChange={handleInputChangeObs}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row className="border border-dark mx-2">
                        <Col md={12}>
                            <Row className='text-center border-bottom border-dark' style={{ background: '#f5b7b1' }}>
                                <Col md={2} className='border-end border-dark'>
                                    <b>No.</b>
                                </Col>
                                <Col md={4} className='border-end border-dark'>
                                    <b>OBJETIVO</b>
                                </Col>
                                <Col md={6}>
                                    <b>NOMBRE DEL INDICADOR</b>
                                </Col>
                            </Row>
                            <Row className='text-center'>
                                <Col md={2} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.numero}
                                        aria-describedby="basic-addon1" type="number" id="numero" name="numero"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={4} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.objetivo}
                                        aria-describedby="basic-addon1" type="text" id="objetivo" name="objetivo"
                                        className="border-0 " onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.nom_ind}
                                        aria-describedby="basic-addon1" type="text" id="nom_ind" name="nom_ind"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row className="border border-dark mx-2">
                        <Col md={12}>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark "
                                    style={{ background: '#f5b7b1' }}
                                >
                                    <b>META</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.meta}
                                        aria-describedby="basic-addon1" type="text" id="meta" name="meta"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark "
                                    style={{ background: '#f5b7b1' }}
                                >
                                    <b>FORMULACION</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.formulacion}
                                        aria-describedby="basic-addon1" type="text" id="formulacion" name="formulacion"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark "
                                    style={{ background: '#f5b7b1' }}
                                >
                                    <b>QUE MIDE</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.que_mide}
                                        aria-describedby="basic-addon1" type="text" id="que_mide" name="que_mide"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark "
                                    style={{ background: '#f5b7b1' }}
                                >
                                    <b>FRECUENCIA DE MEDICION</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.frecuencia}
                                        aria-describedby="basic-addon1" type="text" id="frecuencia" name="frecuencia"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark "
                                    style={{ background: '#f5b7b1' }}
                                >
                                    <b>FUENTE DE DATOS</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.fuente}
                                        aria-describedby="basic-addon1" type="text" id="fuente" name="fuente"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-dark "
                                    style={{ background: '#f5b7b1' }}
                                >
                                    <b>HERRAMIENTAS DE ANALISIS</b>
                                </Col>
                                <Col md={10}>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.herramientas}
                                        aria-describedby="basic-addon1" type="text" id="herramientas" name="herramientas"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row className="border border-dark mx-2">
                        <Col md={6}>
                            <Row className='text-center border-bottom border-dark' style={{ background: '#f5b7b1' }}>
                                <Col md={3} className='border-end border-dark'>
                                    <b>MES</b>
                                </Col>
                                <Col md={3} className='border-end border-dark text-center'>
                                    <b>ENE/FEB</b>
                                </Col>
                                <Col md={3} className='border-end border-dark text-center'>
                                    <b>MAR/ABR</b>
                                </Col>
                                <Col md={3} className='border-end border-dark text-center'>
                                    <b>MAY/JUN</b>
                                </Col>
                            </Row>
                            <Row className='text-center border-bottom border-dark'>
                                <Col md={3} className='border-end border-dark' style={{ background: '#f5b7b1' }}>
                                    <b>CALIFIC/MES</b>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <Row>
                                        <Col md={6} className='border-end border-dark'>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="ene" name="ene"
                                                className="border-0 text-center me-2" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="feb" name="feb"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <Row>
                                        <Col md={6} className='border-end border-dark'>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="mar" name="mar"
                                                className="border-0 text-center me-2" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="abr" name="abr"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <Row>
                                        <Col md={6} className='border-end border-dark'>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="may" name="may"
                                                className="border-0 text-center me-2" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="jun" name="jun"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='text-center'>
                                <Col md={3} className='border-end border-dark' style={{ background: '#f5b7b1' }}>
                                    <b>RESULTADO</b>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion"
                                        aria-describedby="basic-addon1" type="text" id="res1" name="res1"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion"
                                        aria-describedby="basic-addon1" type="text" id="res2" name="res2"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion"
                                        aria-describedby="basic-addon1" type="text" id="res3" name="res3"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row className='text-center border-bottom border-dark' style={{ background: '#f5b7b1' }}>
                                <Col md={3} className='border-end border-dark'>
                                    <b>JUL/AGO</b>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <b>SEP/OCT</b>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <b>NOV/DIC</b>
                                </Col>
                                <Col md={3}>
                                    <b>ACUM</b>
                                </Col>
                            </Row>
                            <Row className='text-center border-bottom border-dark'>
                                <Col md={3} className='border-end border-dark'>
                                    <Row>
                                        <Col md={6} className='border-end border-dark'>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="jul" name="jul"
                                                className="border-0 text-center me-2" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="ago" name="ago"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <Row>
                                        <Col md={6} className='border-end border-dark'>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="sep" name="sep"
                                                className="border-0 text-center me-2" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="oct" name="oct"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <Row>
                                        <Col md={6} className='border-end border-dark'>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="nov" name="nov"
                                                className="border-0 text-center me-2" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion"
                                                aria-describedby="basic-addon1" type="text" id="dic" name="dic"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3} className='d-flex justify-content-center align-items-center'>
                                    <b>{promedio}</b>
                                </Col>
                            </Row>
                            <Row className='text-center'>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion"
                                        aria-describedby="basic-addon1" type="text" id="res4" name="res4"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion"
                                        aria-describedby="basic-addon1" type="text" id="res5" name="res5"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion"
                                        aria-describedby="basic-addon1" type="text" id="res6" name="res6"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={3} className='d-flex justify-content-center align-items-center'>
                                    <b>{promedio_res}</b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br />
                    <Row className="text-center border border-dark mx-2">
                        <Col md={2} className="d-flex justify-items-center align-items-center border-end border-dark "
                            style={{ background: '#f5b7b1' }}
                        >
                            <b>ANALISIS DE LA INFORMACION</b>
                        </Col>
                        <Col md={10}>
                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                id="analisis" name="analisis" style={{ fontSize: '1.2em', textAlign: 'justify' }}
                                className='border-0 text-center' value={dats.analisis}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row className="border border-dark mx-2">
                        <Col md={12} className="border-bottom border-dark text-center"
                            style={{ background: '#f5b7b1' }}
                        >
                            <b>EFECTIVIDAD DEL SISTEMA DE GESTION DE CALIDAD</b>
                        </Col>
                        <Col md={12}>
                            <div className="m-4">
                                <Bar options={options} data={data} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <br />
            </div>
        </div>
    );
}

export default Elemento_No_Conforme_Edit;