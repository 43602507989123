import React, {useState, Fragment, useEffect} from "react";
import { 
  Card, Button, Container, Row, Col,FormControl,
  Table,NavDropdown,Modal,InputGroup,Form
} from 'react-bootstrap';
import {
    DEDALO_ALL_INVENTORY_REQUIREMENT, DEDALO_UPDATE_INVENTORY_REQUIREMENT,
    DEDALO_DEL_INVENTORY_REQUIREMENT,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import LogoDedalo from '../../../imagenes/dedalo_logo.png';
import { Routes } from '../../../utils/routes';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";

function RequerimientosNuevos() {
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession(); 
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(10);
    const [inventarios2, setInventarios2] = useState();
    const [reqselect, setReqSelect] = useState();
    
    const [categor, setCategor] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        monto: '',
        obs: '',
    });

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setReqSelect(e);
        setInventarios2(e);
        setShow2(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };
    
    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'amount',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_INVENTORY_REQUIREMENT+"?page=1&rows=100&order=&inorder=desc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_INVENTORY_REQUIREMENT,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                setCategor({
                ...categor,
                ['datos']: datanotice.records,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    }
    
    const peticionDelete = (iduser) => {
        let delId = iduser;
        //console.log(delId);
        //axios.delete(DEDALO_DEL_INVENTORY_REQUIREMENT(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_INVENTORY_REQUIREMENT+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el requerimiento exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };
    
    useEffect(() => {
        peticionGetCategor();
    }, []);

    const indexOfLastPOst = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPOst - postPerPage;
    const currentPost = categor.datos === undefined ? [] : categor.datos.slice(indexOfFirstPost, indexOfLastPOst);
    const pageNumbers = [];
    const data_page_info = categor.datos === undefined ? [] : categor.datos.length;

    for(let i=1; i <= Math.ceil(data_page_info/postPerPage);i++){
        pageNumbers.push(i);
    }

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleCloseEditReq = (e) => {
    
        //e.preventDefault();
        let monto = dats.monto;
        let observa = dats.obs;
        
        const formattedData = {
            inventory_id: inventarios2,
            user_id: userId,
            amount: monto,
            observations: observa,
        };
        //console.log(formattedData);
        //axios.put(DEDALO_UPDATE_INVENTORY_REQUIREMENT(reqselect), formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_UPDATE_INVENTORY_REQUIREMENT+'&id='+reqselect,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se hizo requerimiento de inventario exitósamente", "", "success");
                document.getElementById("monto").reset();
                document.getElementById("obs").reset();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
        /*.catch(error => {
            manageError(error);
        });*/
    };

    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div >
            {/*<Navbar modulo="Tabla usuarios" usuario={user.name}/>*/}
            <br/>
            <div className="w-100 cuerpo_requerimiento">
                <div>
                    <Button className="btn btn-primary me-2 rounded-circle ml-4 boton_regresar" title="Regresar" 
                        onClick={()=>{window.history.back()}} 
                    >
                        <MdIcons.MdOutlineKeyboardBackspace/>
                    </Button>
                </div>
                <Container>
                    <br/>
                    <Table responsive bordered hover size="sm" style={{fontSize: "0.9rem"}} >
                        <thead style={{textAlign: 'center'}}>
                            <tr className="bg-primary text-white">
                                <th>#</th>
                                <th>Usuario</th>
                                <th>Nombre Producto</th>
                                <th>Monto Requerido</th>
                                <th>Observaciones</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPost.map(item => (
                                <tr key={item} class="bg-light">
                                    <td>{item.id}</td>
                                    <td>
                                        <p className="m-0">
                                            <img src={item.image === '' ? LogoDedalo : item.image} className="me-2" 
                                                style={{width: "45px", height: "45px", borderRadius: "30px"}}
                                            />
                                            {item.nameuser === '' ? 'Sin Nombre Usuario' : item.nameuser}
                                        </p>
                                    </td>
                                    <td style={{textAlign: 'center'}}>{item.nameproduct === '' ? 'Sin Producto' : item.nameproduct}</td>
                                    <td style={{textAlign: 'center'}}>{parseInt(item.amount)}</td>
                                    <td style={{textAlign: 'center'}}>{item.observations}</td>
                                    <td style={{textAlign: 'center'}}>
                                        <NavDropdown id="collasible-nav-dropdown">
                                            <NavDropdown.Item onClick={()=>handleShow2(item.id)}>
                                                Editar
                                            </NavDropdown.Item>
                                            <NavDropdown.Item onClick={()=>peticionDelete(item.id)}>
                                                Eliminar
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <nav>
                        <ul className="pagination">
                            <li className="me-3">
                                Paginas
                            </li>
                            {pageNumbers.map(number => (
                                <li key={number} className="page-item">
                                    <a className="page-link" onClick={()=>paginate(number)} style={{cursor: "pointer"}}>
                                        {number}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </Container>
            </div>
            {/* MODAL nuevo requerimiento */}
            <Modal show={show2} onHide={handleClose2} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Requerimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaSortAmountUpAlt className="me-2 text-primary"/>
                                    Cantidad
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Monto"
                                    aria-describedby="basic-addon1"
                                    type="email" onChange={handleInputChange}
                                    id="monto" name="monto"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" 
                                        onChange={handleInputChange} id="obs" name="obs"
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCloseEditReq}>
                        Confirmar y Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default RequerimientosNuevos;
