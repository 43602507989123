import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,Dropdown,DropdownButton,
} from 'react-bootstrap';
import {
    DEDALO_ALL_SEND_FILES,DEDALO_EDIT_SEND_FILES,DEDALO_DEL_SEND_FILES,
    APIAddress,DEDALO_SEND_FILES_BY_ID,DEDALO_FILES_BY_ID,DEDALO_ALL_USER_COMBO,
    DEDALO_GET_ALL_USERS,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import Select  from 'react-select';
import {useUserSession} from "../../hooks";
import Navbar from '../navbar';
import Swal from "sweetalert2";
import LogoDedalo from '../../imagenes/dedalo_logo.png';
import * as BiIcons from 'react-icons/bi';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as CgIcons from "react-icons/cg";


const EnviadoyRecibido = (data_share) => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();   
    const {user} = useUserSession();
    const {userId} = useUserSession();
    const [productedit, setProductEdit] = useState();
    const [participantes, setParticipantes] = useState([]);
    const [envio_ok, setEnvioOk] = useState(false);

    const [categorin, setCategorIn] = useState({
        datos: [],
    });

    const [categorout, setCategorOut] = useState({
        datos: [],
    });

    const [infofilesend, setInfoFileSend] = useState({
        datos: [],
    });

    const [filesdataid, setFilesDataId] = useState({
        files_data: [],
    });

    const [categor2, setCategor2] = useState({
        datos: [],
    });

    const [getusuarios, setGetUsuarios] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        name: '',
        price: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        name_edit: '',
        unidad_edit: '',
    });

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const peticionGetOut=async()=>{
        const params_data = {
            creator_id: userId,
            target_id: '',
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_SEND_FILES,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.msg);
                if(datanotice.length === 0){
                    setCategorOut({
                        ...categorout,
                        ['datos']: [],
                    });
                }else{
                    setCategorOut({
                      ...categorout,
                      ['datos']: datanotice.records
                    });
                }
              } else {
                 alert(message_responses[response.data.message]);
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetIn=async()=>{
        const params_data = {
            creator_id: '',
            target_id: userId,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_SEND_FILES,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.msg);
                setCategorIn({
                  ...categorin,
                  ['datos']: datanotice.records,
                });
              } else {
                 alert(message_responses[response.data.message]);
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetCategorFilesById=async(idinfo)=>{
        //let info1 = await axios.get(DEDALO_ALL_FOLDERS+'?page=1&rows=10&order=name&inorder=asc&search=&creator_id=13')
        if(idinfo === undefined){
            let iu = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_SEND_FILES_BY_ID+'&id='+idinfo,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setFilesDataId({
                        ...filesdataid,
                        ['files_data']: datanotice.data,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                  }
              });
          
            return info1;
        }
    };

    const peticionGetUserCombo=async()=>{
        const formattedData = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor2({
                    ...categor2,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
            }
        });
      
        return info1;
    };

    const peticionGetUsuarios=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'desc',
        };
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS+"?page=1&rows=1000&order=id&inorder=desc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_GET_ALL_USERS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice);
                setGetUsuarios({
                    ...getusuarios,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetIn();
        peticionGetOut();
        peticionGetCategorFilesById();
        peticionGetUserCombo();
        peticionGetUsuarios();
    }, []);

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleCloseSendFile = (e) => {
    
        e.preventDefault();

        const formattedData = {
            title: filesdataid.files_data.title,
            creator_id: userId,
            target_id: participantes,
            type: filesdataid.files_data.file.split('.')[1],
            file: filesdataid.files_data.file64,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_FILES, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_EDIT_SEND_FILES+'&id='+filesdataid.files_data.id,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se creó el archivo exitósamente", "", "success");
                document.getElementById('name').value = '';
                handleClose2();
                peticionGetOut();
                peticionGetIn();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    if(data_share.actualizar === true){
        peticionGetOut();
        peticionGetIn();
    }

    const peticionDelete = (idfile) => {
        let delId = idfile;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_SEND_FILES+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el archivo compartido exitósamente", "", "success");
                //window.location.reload();
                peticionGetOut();
                peticionGetIn();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const descargaarchivo = (data) =>{
        let url = APIAddress;
        const downloadinstance = document.createElement('a');
        //downloadinstance.href = url+'/'+data;
        downloadinstance.href = filesdataid.files_data.file64;
        downloadinstance.target = '_blank';
        downloadinstance.download = data.split('/')[data.split('/').length-1];
        downloadinstance.click();
        //console.log(downloadinstance.href);
    };

    const selectusuarios = [];
    categor2.datos.map(i => {
        selectusuarios.push({
            value: i.id,
            label: i.name,
        });
    });

    const handlechangePar = (e) =>{
        setParticipantes(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    return (
        <div>
            {/*<Navbar modulo="Productos" usuario={user.name}/>*/}
            <div className="container-fluid flex-grow-1 container-p-y cardheaderimg2">
                <Row>
                    <Col md={6} xs={12}>
                        <Card >
                            <Card.Header as="h6" className="with-elements">
                                <div className="card-header-title">Archivos Compartidos Out</div>
                                {/*<div className="card-header-elements ml-auto">
                                    <Button variant="default" size="xs" className="md-btn-flat" 
                                        title="Agregar Tipo" onClick={()=>handleShow2()}
                                    >
                                        <span class="lnr lnr-plus-circle d-block text-primary"></span>
                                    </Button>
                                </div>*/}
                            </Card.Header>
                            <Card.Body>
                                <div style={{overflowY: "auto", minHeight: "22em", maxHeight: "24em"}}>
                                    <Table responsive striped bordered hover className="tableactionsshared text-center">
                                        <tbody>
                                            {categorout.datos.map(i => (
                                                <tr>
                                                    <td style={{minWidth: '30%'}}>
                                                        {getusuarios.datos.map(j => (
                                                            parseInt(i.target_id) === j.id ?
                                                                <div class="d-flex justify-content-between">
                                                                    <div class="d-flex justify-content-between" key={i.id}>
                                                                        <div>
                                                                            <img src={j.image === '' ? LogoDedalo : j.image} className="me-2" 
                                                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                                        </div>
                                                                        <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                                                                            <b style={{color: "#d35400"}}>
                                                                            {j.name}
                                                                            </b>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            :
                                                                ''
                                                        ))}
                                                    </td>
                                                    <td>{i.title}</td>
                                                    <td className="text-center" style={{minWidth: "20%"}}>
                                                        <DropdownButton
                                                            id="dropdown-button-dark-example2"
                                                            variant="primary" onClick={()=>peticionGetCategorFilesById(i.id)}
                                                            menuVariant="dark"
                                                            className="mt-2"
                                                        >
                                                            {parseInt(i.creator_id) === userId ? 
                                                                <Dropdown.Item onClick={()=>peticionDelete(i.id)}>
                                                                    <MdIcons.MdDeleteOutline className="text-danger me-2 fs-4"/>
                                                                    Eliminar
                                                                </Dropdown.Item>
                                                            :
                                                                ''
                                                            }
                                                            <Dropdown.Item onClick={handleShow2}>
                                                                <MdIcons.MdOutlineEdit className="text-info me-2 fs-4"/>
                                                                Editar
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={()=>descargaarchivo(i.file,i.id)}>
                                                                <BiIcons.BiDownload className="text-primary me-2 fs-5"/>
                                                                Descargar Item
                                                            </Dropdown.Item>
                                                        </DropdownButton>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xs={12}>
                        <Card>
                            <Card.Header as="h6" className="with-elements">
                                <div className="card-header-title">Archivos Compartidos conmigo In</div>
                                {/*<div className="card-header-elements ml-auto">
                                    <Button variant="default" size="xs" className="md-btn-flat" 
                                        title="Agregar Tipo" onClick={()=>handleShow2()}
                                    >
                                        <span class="lnr lnr-plus-circle d-block text-primary"></span>
                                    </Button>
                                </div>*/}
                            </Card.Header>
                            <Card.Body>
                                <div style={{overflowY: "auto", minHeight: "22em", maxHeight: "24em"}}>
                                    <Table responsive striped bordered hover className="tableactionsshared text-center">
                                        <tbody>
                                            {categorin.datos === undefined ?
                                                ''
                                            :
                                                categorin.datos.map(w => (
                                                    <tr>
                                                        <td style={{minWidth: '30%'}}>
                                                            {getusuarios.datos.map(e => (
                                                                parseInt(w.creator_id) === e.id ?
                                                                    <div class="d-flex justify-content-between">
                                                                        <div class="d-flex justify-content-between" key={e.id}>
                                                                            <div>
                                                                                <img src={e.image === '' ? LogoDedalo : e.image} className="me-2" 
                                                                                    style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                                            </div>
                                                                            <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
                                                                                <b style={{color: "#d35400"}}>
                                                                                {e.name}
                                                                                </b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                :
                                                                    ''
                                                            ))}
                                                        </td>
                                                        <td>{w.title}</td>
                                                        <td className="text-center" style={{minWidth: "20%"}}>
                                                            <DropdownButton
                                                                id="dropdown-button-dark-example2"
                                                                variant="primary"
                                                                menuVariant="dark"
                                                                className="mt-2"
                                                            >
                                                                {/*parseInt(w.creator_id) === userId ? 
                                                                    <Dropdown.Item>
                                                                        <MdIcons.MdDeleteOutline className="text-danger me-2 fs-4"/>
                                                                        Eliminar
                                                                    </Dropdown.Item>
                                                                :
                                                                    ''
                                                                */}
                                                                {/*<Dropdown.Item >
                                                                    <MdIcons.MdOutlineEdit className="text-info me-2 fs-4"/>
                                                                    Editar
                                                                </Dropdown.Item>*/}
                                                                <Dropdown.Item onClick={()=>descargaarchivo(w.file)}>
                                                                    <BiIcons.BiDownload className="text-primary me-2 fs-5"/>
                                                                    Descargar Item
                                                                </Dropdown.Item>
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            {/* MODAL COMPARTIR ARCHIVOS */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Archivo Compartido</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            Nombre del Archivo
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    placeholder="Nombre del archivo" aria-label="Nombre del archivo"
                                    aria-describedby="basic-addon1" disabled
                                    type="text" value={filesdataid.files_data.title}
                                    id="name" name="name" onChange={handleInputChange}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                Compartir con
                                <Select isMulti options={selectusuarios} onChange={handlechangePar} className="w-100" ></Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={handleCloseSendFile}>
                        Confirmar Envio
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EnviadoyRecibido;