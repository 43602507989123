import React, {useState, useEffect} from "react";
import { 
    Container,NavDropdown,ProgressBar,Button,Accordion,
    Row,Col,Card,
} from 'react-bootstrap';
import {
    DEDALO_REPORT_PROYECT,
} from "../../assets/APIAdresses";
import axios from "axios";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import {useUserSession} from "../../hooks";
import { Routes } from '../../utils/routes';
import Swal from "sweetalert2";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import * as Chartjs from 'react-chartjs-2'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import Imgfondo from '../../imagenes/dedalo_logo.png'
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";

const InformeProyecto = (props) => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('Informe'));
    const id = parseInt(datahomi.idinforme);

    const [categor, setCategor] = useState({
        datos: [],
        Tasks: [],
        Inventory: [],
        WorkCut: [],
        Personal: [],
        Gangs: [],
    });

    const peticionGetCategor= async()=>{
        const params_data = {
            construction_id: id,
        };
        //let info1 = await axios.get(DEDALO_CONSTRUCCION_BY_ID+'&id='+id)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_REPORT_PROYECT,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data.Tasks);
                setCategor({
                    ...categor,
                    ['datos']: datanotice.data[0],
                    ['Tasks']: datanotice.data[0].Tasks,
                    ['Inventory']: datanotice.data[0].Inventory,
                    ['WorkCut']: datanotice.data[0].WorkCut,
                    ['Personal']: datanotice.data[0].Personal,
                    ['Gangs']: datanotice.data[0].Gangs,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    let datos = [];
    let Nomcortes = [];
    if(categor.WorkCut.length === 0){
        datos.push(parseInt(0));
        Nomcortes.push('Corte 0');
    }else{
        categor.WorkCut.map(item => (
            datos.push(parseInt(item.percentage_advance)),
            Nomcortes.push('Corte '+item.workcutnumber)
        ));
    }

    var colores = [
        "#F5B041","#2ECC71","#2874A6","#BA4A00",
        "#FF0000","#76D7C4","#F7DC6F","#922B21",
        "#717D7E","#154360","#566573","#78281F",
        "#ABB2B9","#D98880","#7FB3D5","#ABB2B9"
    ];

    var data = {
        labels: Nomcortes,
        datasets: [
            {
                data: datos,
                backgroundColor: colores
            }
        ]
    };

    let diff = [];
    categor.Tasks.map((item, index) => {
        let fecha1 = new Date(item.startdate);
        let fecha2 = new Date(item.deadline);
        let resta = fecha2.getTime() - fecha1.getTime();
        let dias = Math.round(resta / (1000 * 60 * 60 * 24));
        diff.push({id: item.id, diferencia: dias});
    });

    let fecha_termina = document.getElementById('f_final');
    let domRect;
    domRect = fecha_termina === null ? '' : fecha_termina.getBoundingClientRect();

    return(
        <div>
            <div className="w-100">
                <br/>
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                    <Button className="btn btn-success rounded-circle" size="sm" onClick={()=>{window.history.back()}}
                        style={{backgroundColor: "#02B371"}} title="Regresar">
                        <MdIcons.MdArrowBackIosNew/>
                    </Button>
                </div>
                <div className="container-fluid flex-grow-1 container-p-y">
                    <div className="mb-3">
                        <h4 className="font-weight-bold mb-0 titulo1">
                            Informe de Proyecto
                        </h4>
                    </div>
                    <hr/>
                    <div className="mt-2 mb-2"> {/*Informe de Proyecto*/}
                        <p>
                            <span className="font-weight-bolder">Empresa:</span> {data_emp.NomEmp}
                        </p>
                        <p>
                            <span className="font-weight-bolder">Nombre del Proyecto:</span> {categor.datos.name}
                        </p>
                        <p>
                            <span className="font-weight-bolder">Ciudad del Proyecto:</span> {categor.datos.cityname}
                        </p>
                        <p>
                            <span className="font-weight-bolder">Presupuesto del proyecto:</span> {
                                categor.datos.budget === undefined ? formatterPeso.format(0) : formatterPeso.format(categor.datos.budget)
                            }
                        </p>
                        <p>
                            <span className="font-weight-bolder">Presupuesto ejecutado del proyecto:</span> {
                            categor.datos.executed_budget === undefined ? formatterPeso.format(0) : formatterPeso.format(categor.datos.executed_budget)
                            }
                        </p>
                        <p>
                            <span className="font-weight-bolder">Dirección del Proyecto:</span> {categor.datos.address}
                        </p>
                    </div>
                    <Accordion >
                        <Accordion.Item eventKey='0'> {/* seccion tareas */}
                            <Accordion.Header>
                                <h5>Tareas</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    {categor.Tasks.length === 0 ?
                                        <div className="text-center">
                                            <h5>No hay tareas registradas</h5>
                                        </div>
                                    :
                                        categor.Tasks.map((item, index) => (
                                            <div>
                                                <div className="mb-3">
                                                    <Accordion >
                                                        <Accordion.Item eventKey={index}>
                                                            <Accordion.Header>
                                                                Informacion de la tarea {item.title}
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <Row className="mb-4"> {/* seccion info tarea */}
                                                                    <Col md={4} xs={12}>
                                                                        Creador de la tarea: {item.nameCreator} <br/>
                                                                        Presupuesto de la tarea: {formatterPeso.format(item.budget)} <br/>
                                                                        Descripcion de la tarea: {item.description} <br/>
                                                                    </Col>
                                                                    <Col md={4} xs={12}>
                                                                        Personal de la tarea: {item.personal} <br/>
                                                                        Observaciones de la tarea: {item.observations} <br/>
                                                                        Prioridad de la tarea: {
                                                                            item.priority === 'high' ?
                                                                                'Alta'
                                                                            :item.priority === 'medium' ?
                                                                                'Medio'
                                                                            :
                                                                                'Bajo'
                                                                        }
                                                                    </Col>
                                                                    <Col md={4} xs={12}>
                                                                        Estado de la tarea: {
                                                                            item.statusTask === 'pending' ?
                                                                                'Pendiente'
                                                                            :item.statusTask === 'in process' ?
                                                                                'En proceso'
                                                                            :item.statusTask === 'under review' ?
                                                                                'En revisión'
                                                                            :item.statusTask === 'completed' ?
                                                                                'Completado'
                                                                            :item.statusTask === 'closed' ?
                                                                                'Cerrado'
                                                                            :item.statusTask === 'canceled' ?
                                                                                'Cancelado'
                                                                            :
                                                                                'Reabierto'
                                                                        } <br/>
                                                                        Suministros de la tarea: {item.means} <br/>
                                                                    </Col>
                                                                </Row>                                                            
                                                                <Row> {/* seccion info responsables,participantes,observadores */}
                                                                    <Col md={4} xs={12}>
                                                                        <div className="text-center">
                                                                            <h5 className="mb-0">
                                                                                Responsables
                                                                            </h5>
                                                                            <div className="text-center d-flex justify-content-center align-items-center">
                                                                                <hr style={{width: '90%'}}/>
                                                                            </div>
                                                                            {item.Responsable.length === 0 ?
                                                                                <div className="text-center">
                                                                                    <span className="text-big font-weight-bolder">
                                                                                        No hay Responsables asignados
                                                                                    </span>
                                                                                </div>
                                                                            :
                                                                                item.Responsable.map((it,ind) => (
                                                                                    <div key={ind} className="d-flex align-items-center mb-2">
                                                                                        <div style={{width: "10%"}}>
                                                                                            <img src={it.imageCreator === '' ? Imgfondo : it.imageCreator} 
                                                                                                alt="salida" className="img-fluid rounded-circle me-2"
                                                                                                style={{width: "100%", height: "3em"}}
                                                                                            />
                                                                                        </div>
                                                                                        <div>
                                                                                            <span className="media-body d-block ml-2">
                                                                                                <span className="text-big font-weight-bolder">
                                                                                                    {it.nameresponsable}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4} xs={12}>
                                                                        <div className="text-center">
                                                                            <h5 className="mb-0">Observadores</h5>
                                                                            <div className="text-center d-flex justify-content-center align-items-center">
                                                                                <hr style={{width: '90%'}}/>
                                                                            </div>
                                                                            {item.Observers.length === 0 ?
                                                                                <div className="text-center">
                                                                                    <span className="text-big font-weight-bolder">
                                                                                        No hay observadores asignados
                                                                                    </span>
                                                                                </div>
                                                                            :
                                                                                item.Observers.map((it1,ind1) => (
                                                                                    <div key={ind1} className="d-flex align-items-center mb-2">
                                                                                        <div style={{width: "10%"}}>
                                                                                            <img src={it1.imageCreator === '' ? Imgfondo : it1.imageCreator}
                                                                                                alt="salida" className="img-fluid rounded-circle me-2" width="100%"
                                                                                                height="3em"
                                                                                            />
                                                                                        </div>
                                                                                        <div>
                                                                                            <span className="media-body d-block ml-2">
                                                                                                <span className="text-big font-weight-bolder">
                                                                                                    {it1.nameobserver}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={4} xs={12}>
                                                                        <div className="text-center">
                                                                            <h5 className="mb-0">Participantes</h5>
                                                                            <div className="text-center d-flex justify-content-center align-items-center">
                                                                                <hr style={{width: '90%'}}/>
                                                                            </div>
                                                                            {item.Participants.length === 0 ?
                                                                                <div className="text-center">
                                                                                    <span className="text-big font-weight-bolder">
                                                                                        No hay Participantes asignados
                                                                                    </span>
                                                                                </div>
                                                                            :
                                                                                item.Participants.map((it2,ind2) => (
                                                                                    <div key={ind2} className="d-flex align-items-center mb-2">
                                                                                        <div style={{width: "10%"}}>
                                                                                            <img src={it2.imageCreator === '' ? Imgfondo : it2.imageCreator}
                                                                                                alt="salida" className="img-fluid rounded-circle me-2" width="100%"
                                                                                                height="3em"
                                                                                            />
                                                                                        </div>
                                                                                        <div>
                                                                                            <span className="media-body d-block ml-2">
                                                                                                <span className="text-big font-weight-bolder">
                                                                                                    {it2.nameobserver}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                                <div id='restante_barra'>
                                                    {
                                                        diff.map((item2, index2) => (
                                                            item2.id === item.id &&
                                                            <div key={index2}>
                                                                {item2.diferencia > 4 &&
                                                                    <div class="labels" style={{width: item2.diferencia+'%',position: 'relative',left: '25px'}}>
                                                                        <span id='f_inicio'>{item.startdate}</span>
                                                                        <span id='f_final'>{item.deadline}</span>
                                                                    </div>
                                                                }
                                                                <div class="progress">
                                                                    <div class="progress-bar barr1" role="progressbar" aria-valuenow={item2.diferencia} id='bar_progress'
                                                                        aria-valuemin="0" aria-valuemax="100" 
                                                                        style={
                                                                            {
                                                                                width: item2.diferencia+'%',position: 'relative',
                                                                                left: '25px',
                                                                            }
                                                                        }
                                                                    >
                                                                        {item2.diferencia}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                <br/>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1"> {/* seccion inventarios */}
                            <Accordion.Header>
                                <h5>Inventarios</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                {categor.Inventory.length === 0 ?
                                    <div className="text-center">
                                        <span className="text-big font-weight-bolder">
                                            No hay inventarios asignados
                                        </span>
                                    </div>
                                :
                                    categor.Inventory.map((inv, indexinv) => (
                                        <div key={indexinv}>
                                            <Row className="mb-4"> {/* seccion info Inventario */}
                                                <Col md={4} xs={12}>
                                                    Tipo de producto en Inventario: <span className="font-weight-bolder">{inv.productTypeName}</span> <br/>
                                                    Proveedor del producto en Inventario: <span className="font-weight-bolder">{inv.crmVendorName}</span> <br/>
                                                    Cantidad en Inventario: <span className="font-weight-bolder">{parseFloat(inv.amountInventory)}</span> <br/>
                                                    Cantidad minima en Inventario: <span className="font-weight-bolder">{inv.minimum_amountInventory}</span> <br/>
                                                </Col>
                                                <Col md={4} xs={12}>
                                                    Producto en Inventario: <span className="font-weight-bolder">{inv.name}</span> <br/>
                                                    Precio del producto: <span className="font-weight-bolder">{formatterPeso.format(inv.price)}</span> <br/>
                                                </Col>
                                                <Col md={4} xs={12}>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2"> {/* seccion WorkCut */}
                            <Accordion.Header>
                                <h5>Cortes de Trabajo</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div style={{margin: "auto", width: "20%"}}>
                                    <Chartjs.Pie
                                        height={120}
                                        data={data}
                                        options = {
                                            {
                                                data: data,
                                                options: {
                                                    responsive: true,
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3"> {/* seccion Personal */}
                            <Accordion.Header>
                                <h5>Personal</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    {categor.Personal.length === 0 ?
                                        <div className="text-center">
                                            <span className="text-big font-weight-bolder">
                                                No hay personal asignado
                                            </span>
                                        </div>
                                    :
                                        categor.Personal.map((per, indexper) => (
                                            <Col md={3} xs={12}>
                                                <div >
                                                    <Card>
                                                        <Card.Header>{per.name}</Card.Header>
                                                        <Card.Body style={{minHeight: '27em',maxHeight: '27em'}}>
                                                            <Card.Text>
                                                                <p>
                                                                    <span className="font-weight-bolder">Documento: </span>{per.document}
                                                                </p>
                                                                <p>
                                                                    <span className="font-weight-bolder">Email: </span>{per.email}
                                                                </p>
                                                                <p>
                                                                    <span className="font-weight-bolder">Eps: </span>{per.eps}
                                                                </p>
                                                                <p>
                                                                    <span className="font-weight-bolder">Arl: </span>{per.arl}
                                                                </p>
                                                                <p>
                                                                    <span className="font-weight-bolder">Pensiones: </span>{per.pension}
                                                                </p>
                                                                <p>
                                                                    <span className="font-weight-bolder">Tel 1: </span>{per.phone1} <br/>
                                                                    <span className="font-weight-bolder">Tel 2: </span>{per.phone2}
                                                                </p>
                                                                <p>
                                                                    <span className="font-weight-bolder">Direccion: </span>{per.address}
                                                                </p>
                                                                <p>
                                                                    <span className="font-weight-bolder">Estado: </span>{per.status}
                                                                </p>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4"> {/* seccion Gangs */}
                            <Accordion.Header>
                                <h5>Cuadrillas</h5>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    {categor.Gangs.length === 0 ?
                                        <div className="text-center">
                                            <span className="text-big font-weight-bolder">
                                                No hay cuadrillas asignadas
                                            </span>
                                        </div>
                                    :
                                        categor.Gangs.map((gang, indexgang) => (
                                            <Col md={3} xs={12}>
                                                <div key={indexgang}>
                                                    <Card>
                                                        <Card.Header as="h5">{gang.name}</Card.Header>
                                                        <Card.Body>
                                                            <Card.Title>Personal asignado</Card.Title>
                                                            <Card.Text style={{overflowY: 'auto', height: '10em'}}>
                                                                {gang.Personal.map((per, indexper) => (
                                                                    <div key={indexper} className="d-flex align-items-center mb-2 mt-2">
                                                                        <div style={{width: "14%"}}>
                                                                            <img src={Imgfondo} 
                                                                                alt="salida" className="img-fluid rounded-circle me-2"
                                                                                style={{width: "100%", height: "3em"}}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <span className="media-body d-block ml-2">
                                                                                <span className="text-big font-weight-bolder">
                                                                                    {per.nameperonal}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                
            </div>
            
        </div>
    );
}

export default InformeProyecto;