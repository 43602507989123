import React, {useState, Fragment, useEffect} from "react";
import {
    Button, NavDropdown, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,
} from 'react-bootstrap';
import {
    DEDALO_ALL_COMPARATIVO,DEDALO_DEL_COMPARATIVO,
} from "../../../../assets/APIAdresses";
import {message_responses} from "../../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../../utils/utils";
import {useUserSession} from "../../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../../utils/routes';
import Swal from "sweetalert2";
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";

const Cuadro_Comparativo = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "date",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_COMPARATIVO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const peticionDelete = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_COMPARATIVO+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el usuario exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    {user.role_id === 12 || user.role_id === 3 ?
                        <div>
                            <Link to={Routes.CUADRO_COMPARATIVO_NEW}>
                                <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}>
                                    Generar cuadro nuevo
                                </Button>
                            </Link>
                        </div>
                    :
                        ''
                    }
                </div>
            </div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-center mb-4">
                <h2 className="text-center">Cuadros comparativos Generados</h2>                
            </div>
            <hr/>
            <div className="container container-fluid">
                <Row>
                    {categor.datos === undefined ?
                        ''
                    :
                        categor.datos.map((x,index) => (
                            <Col md={3} xs={12} key={index} className='mb-4'>
                                <Card>
                                    <Card.Header as="h6" className="with-elements">
                                        <div className="card-header-title">
                                            Seleccion proveedor No. {x.id}
                                        </div>
                                        {user.role_id === 12 || user.role_id === 3 ?
                                            <div className="card-header-elements ml-auto">
                                                <div class="dropdown acciones_folders">
                                                    <BsIcons.BsThreeDotsVertical role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                                                        className="dropdown-toggle"
                                                    />
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <Link to={Routes.CUADRO_COMPARATIVO_EDIT(x.id)} className='text-decoration-none'>
                                                            <li>
                                                                <a class="dropdown-item cursor-pointer">
                                                                    Editar
                                                                </a>
                                                            </li>
                                                        </Link>
                                                        <li>
                                                            <a class="dropdown-item cursor-pointer" onClick={()=>peticionDelete(x.id)}>
                                                                Eliminar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        :
                                            ''
                                        }
                                    </Card.Header>
                                    <Card.Body>
                                        <div>
                                            <p className="mb-0">
                                                <b>Fecha de evaluacion</b>
                                            </p>
                                            <p>
                                                <span>{new Date(x.date).toLocaleDateString()}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p className="mb-0">
                                                <b>Generado por</b>
                                            </p>
                                            <p>
                                                <span>{x.nameUser}</span>
                                            </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </div>
    );
}

export default Cuadro_Comparativo;