import PropTypes from "prop-types";

export const TextFieldPropTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  onValidate: PropTypes.func,
  forceActive: PropTypes.bool,
  InputProps: PropTypes.object,
  customValidator: PropTypes.shape({
    validator: PropTypes.func,
    errorMessage: PropTypes.string,
  }),
};

export const UserSessionPropTypes = PropTypes.shape({
  account_verification_code: PropTypes.string,
  account_verified: PropTypes.string,
  account_verified_at: PropTypes.string,
  cell_phone: PropTypes.string,
  created_at: PropTypes.string,
  deleted_at: PropTypes.string,
  email: PropTypes.string,
  email_verified_at: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  last_password_reset_at: PropTypes.string,
  lastname: PropTypes.string,
  name: PropTypes.string,
  password_reset_code: PropTypes.string,
  queries: PropTypes.number,
  status: PropTypes.string,
  token: PropTypes.string,
  updated_at: PropTypes.string,
  views: PropTypes.number,
});

export const ChatSessionPropTypes = PropTypes.shape({
  id: PropTypes.number,
});

export const PaymentPropTypes = {
  productType: PropTypes.oneOf(["package", "products"]),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      quantity: PropTypes.number,
    })
  ),
  payLaterOption: PropTypes.bool,
  packageId: PropTypes.number,
  price: PropTypes.number,
  formatData: PropTypes.func,
  URL: PropTypes.string,
  successMessage: PropTypes.string,
  onSuccess: PropTypes.func,
};

export const StepsStepperPropTypes = {
  userType: PropTypes.oneOf(["owner", "requester"]),
};

export const StepComponentPropTypes = {
  ...StepsStepperPropTypes,
  stepInfo: PropTypes.object,
  updateRequests: PropTypes.func,
  id: PropTypes.number,
  spaceInfo: PropTypes.object,
  isCurrentStep: PropTypes.bool,
  requestSteps: PropTypes.object,
  setCalendarInfo: PropTypes.func
};
