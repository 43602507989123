import React, { useState, useRef, useEffect } from "react";
import {
    Form, Row, Col, FloatingLabel, Button,
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_VACACIONES,DEDALO_VACACIONES_BY_ID,
} from "../../../assets/APIAdresses";
import { message_responses } from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import { useUserSession } from "../../../hooks";
import { Routes } from '../../../utils/routes';
import { Redirect, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import html2PDF from "jspdf-html2canvas";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import * as MdIcons from "react-icons/md";
import Img1 from './img/logo_permiso.jpg';

const FormatoVacacionesEdit = () => {
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [selectedDate, handleDateChange] = useState(null);
    const [selectedDate1, handleDateChange1] = useState(null);
    const [selectedDate2, handleDateChange2] = useState(null);

    const { id } = useParams();

    const [dats, setDat] = useState({
        empresa: '',
        empleado: '',
        tipo_num_doc: '',
        cargo: '',
        periodo_solicitado: '',
        dias_solicitados: '',
        dias_pendientes: '',
        observaciones: '',
        num_dias: '',
        fecha1: '',
        fecha2: '',
        fecha3: '',
    });

    const localeMap = {
        es: esLocale,
    };

    const handleInputChange = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const peticionGetPermisoById = async () => {

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_VACACIONES_BY_ID + '&id=' + id,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice.data);
                    setDat({
                        ...dats,
                        ['empresa']: datanotice.enterprice,
                        ['empleado']: datanotice.employee_name,
                        ['tipo_num_doc']: datanotice.document_number,
                        ['cargo']: datanotice.position,
                        ['periodo_solicitado']: datanotice.periods,
                        ['dias_solicitados']: '',
                        ['dias_pendientes']: datanotice.pending_days,
                        ['observaciones']: datanotice.observations,
                        ['num_dias']: datanotice.days,
                        ['fecha1']: datanotice.date,
                        ['fecha2']: datanotice.startdate,
                        ['fecha3']: datanotice.endate,
                    });

                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetPermisoById();
    }, []);

    let f1 = new Date(dats.fecha2);
    let d1 = f1.getDate()+1;
    let m1 = f1.getMonth()+1;
    let y1 = f1.getFullYear();
    let ff1 = y1+'-'+m1+'-'+d1;

    let f2 = new Date(dats.fecha3);
    let d2 = f2.getDate()+1;
    let m2 = f2.getMonth()+1;
    let y2 = f2.getFullYear();
    let ff2 = y2+'-'+m2+'-'+d2;

    let f3 = new Date(dats.fecha1);
    let d3 = f3.getDate()+1;
    let m3 = f3.getMonth()+1;
    let y3 = f3.getFullYear();
    let ff3 = y3+'-'+m3+'-'+d3;

    let fecha1 = selectedDate === null ? new Date(ff1) : selectedDate;
    let fecha2 = selectedDate1 === null ? new Date(ff2) : selectedDate1;
    var diferenciaEnMilisegundos1 = fecha1 - fecha2;

    let dias = Math.abs(Math.floor(diferenciaEnMilisegundos1 / (1000 * 60 * 60 * 24)));

    const handleCloseSave = (e) => {
        let fecha4 = new Date(selectedDate2).toISOString().slice(0, 10).replace(/-/g, "-");
        let fecha5 = new Date(selectedDate).toISOString().slice(0, 10).replace(/-/g, "-");
        let fecha6 = new Date(selectedDate1).toISOString().slice(0, 10).replace(/-/g, "-");

        const formattedData = {
            enterprice: dats.empresa,
            date: selectedDate2 === null ? ff3 : fecha4,
            users_id: userId,
            employee_name: dats.empleado,
            document_number: dats.tipo_num_doc,
            position: dats.cargo,
            periods: dats.periodo_solicitado,
            days: dias,
            startdate: selectedDate === null ? ff1 : fecha5,
            endate: selectedDate1 === null ? ff2 : fecha6,
            pending_days: dats.dias_pendientes,
            observations: dats.observaciones
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_UPDATE_VACACIONES+'&id='+id,
            headers: { Authorization: userToken },
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se edito el formato exitósamente", "", "success");
                peticionGetPermisoById();
            } else {
                console.log(response.data);
            }
        })
        .catch(error => {
            //console.log(error);
            manageError(error);
        });
    };

    const printContainer = useRef(null);

    const createPDF = async () => {
        let year = new Date().getFullYear();
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;

        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `formato_vacaciones_${fecha}.pdf`
        });
    };

    //console.log(dias,new Date(ff1),new Date(ff2));

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371", color: '#fff' }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371", color: '#fff' }}
                            onClick={handleCloseSave}
                        >
                            Guardar formato
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm"
                            onClick={() => createPDF()} title="Regresar" style={{ backgroundColor: "#02B371", color: '#fff' }}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br />
            <div className="container" ref={printContainer} style={{ padding: '1em 5em 1em 5em' }}>
                <div className="border container border-dark">
                    <Row>
                        <Col md={4} className="border-dark border-end text-center d-flex justify-content-center align-items-center">
                            <img src={Img1} className='img-fluid' />
                        </Col>
                        <Col md={6} className="border-dark border-end">
                            <Row>
                                <Col md={3} className="text-center border-dark border-bottom border-end">
                                    <span><b>SGC</b></span>
                                </Col>
                                <Col md={9} className="text-center border-dark border-bottom">
                                    <span><b>codigo FO-RH-21-01</b></span>
                                </Col>
                            </Row>
                            <Row className="text-center d-flex justify-content-center align-items-center">
                                <Col md={12}>
                                    <div>
                                        <b>SOLICITUD DE VACACIONES</b>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} className="text-center">
                            <Row>
                                <Col>
                                    <span><b>Version 1.0</b></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span><b>Pagina</b></span> <br />
                                    <span><b>1 de 1</b></span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="container mt-3">
                    <Row className="border border-dark">
                        <Col md={6} className="px-0 border-dark border-end">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>EMPRESA</b></span>
                            </div>
                            <div>
                                <Form.Control
                                    className='border-0 text-center' autoFocus onChange={handleInputChange}
                                    aria-label="Username" id="empresa" name="empresa"
                                    aria-describedby="basic-addon1" value={dats.empresa}
                                />
                            </div>
                        </Col>
                        <Col md={6} className="px-0">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>FECHA DE DILIGENCIAMIENTO</b></span>
                            </div>
                            <div className="text-center fecha_solicitud">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                                    <DatePicker
                                        autoOk format="dd MMMM yyyy"
                                        value={selectedDate2 === null ? ff3 : selectedDate2}
                                        onChange={handleDateChange2} disabled
                                        animateYearScrolling
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="container mt-3">
                    <Row className="border border-dark">
                        <Col md={6} className="px-0 border-dark border-end">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>NOMBRE DEL EMPLEADO</b></span>
                            </div>
                            <div>
                                <Form.Control
                                    className='border-0 text-center' id="empleado" name="empleado"
                                    aria-label="Username" onChange={handleInputChange}
                                    aria-describedby="basic-addon1" value={dats.empleado}
                                />
                            </div>
                        </Col>
                        <Col md={6} className="px-0">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>DOC DE IDENTIDAD No.</b></span>
                            </div>
                            <div>
                                <Form.Control
                                    className='border-0 text-center' id="tipo_num_doc" name="tipo_num_doc"
                                    aria-label="Username" onChange={handleInputChange}
                                    aria-describedby="basic-addon1" value={dats.tipo_num_doc}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-dark border-start">
                        <Col md={4} className="px-0 border-dark border-bottom border-end d-flex justify-items-center align-items-center"
                            style={{ background: '#d6dbdf' }}>
                            <div className="px-2">
                                <span><b>CARGO DEL EMPLEADO</b></span>
                            </div>
                        </Col>
                        <Col md={8} className="px-0 border-dark border-bottom border-end">
                            <Form.Control
                                className='border-0' onChange={handleInputChange}
                                aria-label="Username" id="cargo" name="cargo"
                                aria-describedby="basic-addon1" value={dats.cargo}
                            />
                        </Col>
                    </Row>
                    <Row className="border-dark border mt-4">
                        <Col md={3} className="px-0 border-dark border-end d-flex justify-items-center align-items-center"
                            style={{ background: '#d6dbdf' }}>
                            <div className="px-2">
                                <span><b>PERIODO SOLICITADO</b></span>
                            </div>
                        </Col>
                        <Col md={9} className="px-0">
                            <Form.Control
                                className='border-0' id="periodo_solicitado" name="periodo_solicitado"
                                aria-label="Username" onChange={handleInputChange}
                                aria-describedby="basic-addon1" value={dats.periodo_solicitado}
                            />
                        </Col>
                    </Row>
                </div>
                <div className="container mt-3">
                    <Row>
                        <Col md={6}>
                            <div className="border border-dark">
                                <div className="border-dark text-center border-bottom" style={{ background: '#D6DBDF' }}>
                                    <span><b>VACACIONES DISFRUTADAS</b></span>
                                </div>
                                <div>
                                    <div className="d-flex">
                                        <div className="px-2 border-dark border-end border-bottom" style={{ background: '#D6DBDF', width: '24%' }}>
                                            <span><b>No. Dias</b></span>
                                        </div>
                                        <div className="w-100 border-dark border-bottom">
                                            <Form.Control
                                                className='border-0 text-center' value={dias}
                                                aria-label="Username" size="sm" onChange={handleInputChange}
                                                aria-describedby="basic-addon1" id="num_dias" name="num_dias"
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="px-2 border-dark border-end border-bottom" style={{ background: '#D6DBDF', width: '24%' }}>
                                            <span><b>Desde</b></span>
                                        </div>
                                        <div className="w-100 text-center border-dark border-bottom">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                                                <DatePicker
                                                    autoOk format="dd MMMM yyyy"
                                                    value={selectedDate === null ? ff1 : selectedDate}
                                                    onChange={handleDateChange}
                                                    animateYearScrolling
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="px-2 border-dark border-end" style={{ background: '#D6DBDF', width: '24%' }}>
                                            <span><b>Hasta</b></span>
                                        </div>
                                        <div className="w-100 text-center">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                                                <DatePicker
                                                    autoOk format="dd MMMM yyyy"
                                                    value={selectedDate1 === null ? ff2 : selectedDate1}
                                                    onChange={handleDateChange1}
                                                    animateYearScrolling
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="border border-dark">
                                <div className="border-dark text-center border-bottom px-2" style={{ background: '#D6DBDF' }}>
                                    <span><b>VACACIONES COMPENSADAS</b></span>
                                </div>
                                <div>
                                    <div className="d-flex">
                                        <div className="px-2 border-dark border-end border-bottom" style={{ background: '#D6DBDF', width: '70%' }}>
                                            <span><b>No. de dias solicitados</b></span>
                                        </div>
                                        <div className="w-100 border-dark border-bottom">
                                            <Form.Control
                                                className='border-0 text-center' onChange={handleInputChange}
                                                aria-label="Username" size="sm" id="dias_solicitados" name="dias_solicitados"
                                                aria-describedby="basic-addon1" value={dats.dias_solicitados}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="px-2 border-dark border-end" style={{ background: '#D6DBDF', width: '70%' }}>
                                            <span><b>No. de dias pendientes</b></span>
                                        </div>
                                        <div className="w-100">
                                            <Form.Control
                                                className='border-0 text-center' onChange={handleInputChange}
                                                aria-label="Username" size="sm" id="dias_pendientes" name="dias_pendientes"
                                                aria-describedby="basic-addon1" value={parseInt(dats.dias_pendientes) === 0 ? '' : dats.dias_pendientes}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="container mt-3">
                    <Row className="border-dark border">
                        <Col md={12} className="px-0 border-dark border-bottom">
                            <div className="text-center py-1" style={{ background: '#D6DBDF' }}>
                                <span><b>OBSERVACIONES</b></span>
                            </div>
                        </Col>
                        <Col md={12} className="px-0">
                            <div>
                                <FloatingLabel controlId="floatingTextarea">
                                    <Form.Control as="textarea" placeholder="Leave a comment here"
                                        style={{ height: '100px' }} className="py-2" onChange={handleInputChange}
                                        id="observaciones" name="observaciones" value={dats.observaciones}
                                    />
                                </FloatingLabel>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4 container">
                    <Row className="border border-dark">
                        <Col md={4} className="border-dark border-end px-0">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>FIRMA DEL EMPLEADO</b></span>
                            </div>
                            <div className="border-dark border-bottom" style={{ height: '100px' }}></div>
                            <div className="px-2">
                                <span><b>C.C No.</b></span>
                            </div>
                        </Col>
                        <Col md={4} className="border-dark border-end px-0">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>FIRMA JEFE DEPENDENCIA</b></span>
                            </div>
                            <div className="border-dark border-bottom" style={{ height: '100px' }}></div>
                            <div className="px-2">
                                <span><b>Nombre:</b></span>
                            </div>
                        </Col>
                        <Col md={4} className="px-0">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>FIRMA RRHH</b></span>
                            </div>
                            <div className="border-dark border-bottom" style={{ height: '100px' }}></div>
                            <div className="px-2">
                                <span><b>Nombre:</b></span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-3 px-2">
                    <span><b>INSTRUCCIONES</b></span>
                    <p>
                        <b>*</b> El periodo solicitado corresponde a los 365 días laborados (Ejemplo: 15 de Enero de 2017 al 14 de enero de 2018)
                    </p>
                    <p>
                        <b>*</b> Las vacaciones compensadas de acuerdo al Art. 189 del CST UNICAMENTE se reconoceran en dinero hasta la mitad
                        de las vacaciones (El empleado puede solicitar 8 o 7 días pagos)
                    </p>
                    <p>
                        <b>*</b> Para consultas enviar correo a nomina.geoestructuras@gmail.com y/o comunicarse al 3183534468
                    </p>
                </div>
            </div>
            <br /><br /><br />
        </div>
    );
}

export default FormatoVacacionesEdit;