import React, { useState, Fragment, useEffect } from "react";
import { Redirect, useParams, Link } from "react-router-dom";
import {
    LineChart, Line, CartesianGrid, ResponsiveContainer,
    XAxis, YAxis, Tooltip, Legend, BarChart, Bar
} from 'recharts';
import {
    DEDALO_BUDGET_PROJECT,
} from "../../../assets/APIAdresses";
import axios from "axios";
import { useUserSession } from "../../../hooks";
import { message_responses } from "../../../assets/MESSAGE_RESPONSES";
import { Routes } from "../../../utils/routes";

function DiagramaPresupuesto(props) {
    const { userToken } = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const id = datahomi === null ? 0 : parseInt(datahomi.idProject);

    const [budgettareas, setBudgetTareas] = useState({
        datos: [],
    });

    const peticionGetTareas = async () => {
        let ubucacion = window.location.pathname;

        if (ubucacion === Routes.HOME) {
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_BUDGET_PROJECT + '&id=' + props.id_home,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg;
                        //console.log(datanotice);
                        if (datanotice.length === 0) {
                            setBudgetTareas({
                                ...budgettareas,
                                ['datos']: [],
                            });
                        } else {
                            setBudgetTareas({
                                ...budgettareas,
                                ['datos']: datanotice.data,
                            });
                        }
                    } else {
                        alert(message_responses[response.data.message]);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 26");
                    }
                });
            return info1;
        } else {
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_BUDGET_PROJECT + '&id=' + id,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg;
                        //console.log(datanotice);
                        if (datanotice.length === 0) {
                            setBudgetTareas({
                                ...budgettareas,
                                ['datos']: [],
                            });
                        } else {
                            setBudgetTareas({
                                ...budgettareas,
                                ['datos']: datanotice.data,
                            });
                        }
                    } else {
                        alert(message_responses[response.data.message]);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 26");
                    }
                });
            return info1;
        }
    };

    useEffect(() => {
        peticionGetTareas();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="border border-dark rounded"
                    style={{ width: '12em', fontSize: '0.8em', padding: '0.5em 0.5em', backgroundColor: '#D4E6F1' }}
                >
                    <p className="label">{`Tarea: ${label}`}</p>
                    <p>{`Presupuesto tarea: ${formatterPeso.format(payload[0].value)}`}</p>
                    <p>{`Total proyectado: ${formatterPeso.format(payload[1].value)}`}</p>
                </div>
            );
        }

        return null;
    };

    const data = [];
    const datos_api = budgettareas.datos;
    for (let q = 0; q < datos_api.length; q++) {
        data.push({
            name: datos_api[q].title,
            uv: parseInt(datos_api[q].budget),
            pv: datos_api[q].totalbudgetgeneral,
        })
    };

    return (
        <div className="App">
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-center">
                <ResponsiveContainer width={600} height={400} className="text-center">
                    <BarChart
                        width={600}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid stroke="#ccc" />
                        <XAxis dataKey="name" />
                        <YAxis type="number" domain={['dataMin - 1000', 'dataMax + 1000']} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend verticalAlign="top" height={30} />
                        <Bar name="presupuesto" type="monotone" dataKey="uv" fill="#8884d8" />
                        <Bar name="total proyectado" type="monotone" dataKey="pv" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default DiagramaPresupuesto;
