import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table
} from 'react-bootstrap';
import {
    DEDALO_ALL_PRODUCTS,DEDALO_NEW_PRODUCTS,DEDALO_DEL_PRODUCTS,
    DEDALO_UPDATE_PRODUCTS,DEDALO_PRODUCT_TYPE_COMBO,DEDALO_VENDOR_COMBO,
    DEDALO_PRODUCT_BY_ID,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Navbar from '../navbar';
import Swal from "sweetalert2";
import TipoItems from './itemtype';
import TipoProducto from './tipoproducto';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";



const TablaProductos = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();   
    const {user} = useUserSession();
    const [product_type, setProduct_Type] = useState('');
    const [vendor_id, setVendor_Id] = useState('');
    const [productedit, setProductEdit] = useState();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [producttype, setProductType] = useState({
        datos: [],
    });

    const [vendorstype, setVendorsType] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        name: '',
        price: '',
    });

    const [inputfilter, setInputFilter] = useState({
        formulario: '',
    });

    const [infofiltro, setInfoFiltro] = useState({
        datos: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        name_edit: '',
        unidad_edit: '',
        product_type_id_edit: '',
        crm_vendors_id_edit: '',
        productTypeName_edit: '',
        crmVendorName_edit: '',
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 800,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_PRODUCTS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.data);
                  setCategor({
                    ...categor,
                    ['datos']: datanotice.records,
                  });
              } else {
                 //alert(message_responses[response.data.message]);
                 alert('error producto 1');
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetProductType=async()=>{
        //let info1 = await axios.get(DEDALO_PRODUCT_TYPE_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_PRODUCT_TYPE_COMBO,
            headers: {Authorization: userToken},
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setProductType({
                    ...producttype,
                    ['datos']: datanotice,
                });
            } else {
                //alert(message_responses[response.data.message]);
                setProductType({
                    ...producttype,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetVendors=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_VENDOR_TYPE_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_VENDOR_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                  //console.log(datanotice);
                  setVendorsType({
                    ...vendorstype,
                    ['datos']: datanotice,
                  });
              } else {
                 //alert(message_responses[response.data.message]);
                 //alert('error tipo producto 2');
                 setVendorsType({
                    ...vendorstype,
                    ['datos']: [],
                  });
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 22");
              }
          });
      
        return info1;
    };

    const peticionGetProductById=async()=>{
       if(productedit === undefined){
            let iu = 0;
       }else{
           //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_PRODUCT_BY_ID+'&id='+productedit,
                headers: {Authorization: userToken},
            })
            .then(response=>{
               if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setDat_Edit({
                        ...dats_edit,
                        ['name_edit']: datanotice.data.name,
                        ['unidad_edit']: datanotice.data.price,
                        ['product_type_id_edit']: datanotice.data.product_type_id,
                        ['crm_vendors_id_edit']: datanotice.data.crm_vendors_id,
                        ['productTypeName_edit']: datanotice.data.productTypeName,
                        ['crmVendorName_edit']: datanotice.data.crmVendorName,
                    });
                 } else {
                    alert(message_responses[response.data.message]);
                 }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
         
           return info1;
       }
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetProductType();
        peticionGetVendors();
    }, []);

    useEffect(() => {
        peticionGetProductById();
    }, [productedit]);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setProductEdit(e);
        setShow3(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {   
        setDat_Edit({
            ...dats_edit,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeSelectE = (event) => {
        setProduct_Type(event.target.value);
    };

    const handleChangeSelectP = (event) => {
        setVendor_Id(event.target.value);
    };

    const handleInputChangeInputFilter = (event) => {   
        setInputFilter({
            ...inputfilter,
            [event.target.name] : event.target.value
        })
    };

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };

    const handleCloseSave = (e) => {
    
        let name = dats.name;
        let precio = dats.price;
        
        const formattedData = {
            name: name,
            price: precio,
            product_type_id: product_type,
            crm_vendors_id: vendor_id,
        };
        //console.log(formattedData);
        if(name === '' || precio === '' || product_type === '' || vendor_id === ''){
            Swal.fire("No se pudo crear el producto, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.post(DEDALO_NEW_PRODUCTS, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NEW_PRODUCTS,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el producto exitósamente", "", "success");
                    document.getElementById('name').value = '';
                    document.getElementById('price').value = '';
                    document.getElementById('select_product').value = '';
                    document.getElementById('select_vendor').value = '';
                    peticionGetCategor();
                    handleClose2();
                } else {
                    //alert("ERROR: Server Error");
                    Swal.fire("El nombre "+name+" ya existe", "", "warning");
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    const handleCloseSaveEdit = (e) => {
    
        let name_edit = dats_edit.name_edit;
        let unidad_edit = dats_edit.unidad_edit;
        
        const formattedData = {
            name: name_edit,
            price: unidad_edit,
            product_type_id: product_type === '' ? dats_edit.product_type_id_edit : product_type,
            crm_vendors_id: vendor_id === '' ? dats_edit.crm_vendors_id_edit : vendor_id,
        };
        //console.log(formattedData);
        if(name_edit === ''){
            Swal.fire("No se pudo editar el producto, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.put(DEDALO_UPDATE_PRODUCTS(productedit), formattedData, attachToken(user))
            axios({
                method: 'PUT',
                url: DEDALO_UPDATE_PRODUCTS+'&id='+productedit,
                headers: {Authorization: userToken},
                data: formattedData,
            }) 
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el producto exitósamente", "", "success");
                    document.getElementById('name_edit').value = '';
                    document.getElementById('unidad_edit').value = '';
                    document.getElementById('select_product').value = '';
                    document.getElementById('select_vendor').value = '';
                    peticionGetCategor();
                    handleClose3();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
            
        }
    };

    const peticionDelete = (iduser) => {
        let delId = iduser;
            
        //axios.delete(DEDALO_DEL_PRODUCTS(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_PRODUCTS+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el producto exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
        
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const filtrar = async(e) => {
        e.preventDefault();
        let input_text = inputfilter.formulario;

        if(input_text === ''){
            setInfoFiltro({
                ...infofiltro,
                ['datos']: [],
            });
        }else{
            const params_data = {
                search: input_text,
                perpage: 900,
                page: 1,
                orderField: 'name',
                order: 'asc',
            };
            //console.log(params_data);
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_PRODUCTS,
                headers: {Authorization: userToken},
                data: params_data
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    if(datanotice.length === 0){
                        mensaje_data(input_text);
                    }else{
                        setInfoFiltro({
                            ...infofiltro,
                            ['datos']: datanotice.records,
                        });
                    }
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const mensaje_data = (texto_in) => {
        setInfoFiltro({
            ...infofiltro,
            ['datos']: [],
        });
        return Swal.fire("No hay datos con el nombre "+texto_in+" ingresado", "", "warning");
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = categor.datos === undefined ? [] : categor.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = categor.datos === undefined ? [] : categor.datos.length/postPerPage; // numero total de paginas
    let pageNumbers = []; //se guardan los numeros de paginas

    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () =>{
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () =>{
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };
    
    const paginador = () =>{
        return(
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{cursor: 'pointer'}}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div>
            {/*<Navbar modulo="Productos" usuario={user.name}/>*/}
            <div className="container-fluid flex-grow-1 container-p-y mt-4 cardheaderimg2">
                <Row>
                    <Col md={7} xs={12}>
                        <Card className="tablaproductos mb-4">
                            <Card.Header as="h6" className="with-elements">
                                <div className="card-header-title">Productos</div>
                                <div className="card-header-elements ml-auto">
                                    <Button variant="default" size="xs" className="md-btn-flat" 
                                        title="Agregar Tipo" onClick={()=>handleShow2()}
                                    >
                                        <span class="lnr lnr-plus-circle d-block text-primary"></span>
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="mb-3 w-100 d-flex justify-content-center"> {/* barra de busqueda */}
                                    <div>
                                        <Form className="d-flex">
                                            <FormControl
                                                type="search" size="sm"
                                                placeholder="Ingrese el nombre"
                                                className="me-2"
                                                aria-label="Buscar"
                                                id="formulario" 
                                                name="formulario"
                                                style={{width: '100%'}}
                                                onChange={handleInputChangeInputFilter}
                                            />
                                            <Button id="buscar" size="sm" onClick={filtrar} className="btn_buscar">
                                                Buscar
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                                {infofiltro.datos.length === 0 ?
                                    <div> {/* seccion de adtos */}
                                        <Table responsive bordered hover size="sm" >
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Nombre Producto</th>
                                                    <th>Nombre Tipo Producto</th>
                                                    <th>Precio Producto</th>
                                                    <th>Vendedor del Producto</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.87em"}}>
                                                {currentPost.map(i => (
                                                    <tr className="text-center">
                                                        <td>{i.id}</td>
                                                        <td>{i.name}</td>
                                                        <td>{i.productTypeName}</td>
                                                        <td>{formatterPeso.format(i.price)}</td>
                                                        <td>
                                                            {vendorstype.datos.map(q => (
                                                                q.id === parseInt(i.crm_vendors_id) ?
                                                                    q.name
                                                                :
                                                                    ''
                                                            ))}
                                                        </td>
                                                        <td>
                                                            <div className="opciones">
                                                                <div class="dropdown">
                                                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" 
                                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                                    </button>
                                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a class="dropdown-item" href="#" onClick={()=>handleShow3(i.id)}>
                                                                                Editar
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a class="dropdown-item" href="#" onClick={()=>peticionDelete(i.id)}>
                                                                                Eliminar
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        {paginador()}
                                    </div>
                                :
                                    <div> {/* seccion de adtos filtrados */}
                                        <Table responsive bordered hover size="sm" >
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Nombre Producto</th>
                                                    <th>Nombre Tipo Producto</th>
                                                    <th>Precio Producto</th>
                                                    <th>Vendedor del Producto</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{fontSize: "0.87em"}}>
                                                {infofiltro.datos.map(a => (
                                                    <tr className="text-center">
                                                        <td>{a.id}</td>
                                                        <td>{a.name}</td>
                                                        <td>{a.productTypeName}</td>
                                                        <td>{formatterPeso.format(a.price)}</td>
                                                        <td>
                                                            {vendorstype.datos.map(w => (
                                                                w.id === parseInt(a.crm_vendors_id) ?
                                                                    w.name
                                                                :
                                                                    ''
                                                            ))}
                                                        </td>
                                                        <td>
                                                            <div className="opciones">
                                                                <div class="dropdown">
                                                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" 
                                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                                    </button>
                                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a class="dropdown-item" href="#" onClick={()=>handleShow3(a.id)}>
                                                                                Editar
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a class="dropdown-item" href="#" onClick={()=>peticionDelete(a.id)}>
                                                                                Eliminar
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={5} xs={12}>
                        <TipoProducto/>
                    </Col>
                </Row>
            </div>
            {/* MODAL CREAR PRODUCTO */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Crear Producto Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDriveFileRenameOutline/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre del producto" aria-label="Nombre del producto"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="name" name="name"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdPriceChange/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Precio del producto" aria-label="Precio del producto"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="price" name="price"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdProductionQuantityLimits/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectE} id="select_product" name="select_product"
                                >
                                    <option value="">Seleccione producto</option>
                                    {producttype.datos.length === 0 ?
                                        ''
                                    :
                                        producttype.datos.map(i => (
                                            <option value={i.id}>{i.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdSell/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectP} id="select_vendor" name="select_vendor"
                                >
                                    <option value="">Seleccione vendedor</option>
                                    {vendorstype.datos.length === 0 ?
                                        ''
                                    :
                                        vendorstype.datos.map(q => (
                                            <option value={q.id}>{q.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={()=>handleCloseSave()}>
                        Crear Producto
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* MODAL EDITAR PRODUCTO */}
            <Modal show={show3} onHide={handleClose3} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDriveFileRenameOutline/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre del producto" aria-label="Nombre del producto"
                                    aria-describedby="basic-addon1" value={dats_edit.name_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="name_edit" name="name_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdPriceChange/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Precio del producto" aria-label="Precio del producto"
                                    aria-describedby="basic-addon1" value={dats_edit.unidad_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="unidad_edit" name="unidad_edit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdProductionQuantityLimits/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectE} id="select_product" name="select_product"
                                >
                                    <option value={dats_edit.crm_vendors_id_edit}>
                                        {dats_edit.productTypeName_edit}
                                    </option>
                                    {producttype.datos.length === 0 ?
                                        ''
                                    :
                                        producttype.datos.map(i => (
                                            <option value={i.id}>
                                                {i.name}
                                            </option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdSell/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectP} id="select_vendor" name="select_vendor"
                                >
                                    <option value={dats_edit.crm_vendors_id_edit}>
                                        {dats_edit.crmVendorName_edit}
                                    </option>
                                    {vendorstype.datos.length === 0 ?
                                        ''
                                    :
                                        vendorstype.datos.map(w => (
                                            <option value={w.id}>
                                                {w.name}
                                            </option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={()=>handleCloseSaveEdit()}>
                        Editar Producto
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    )
}

export default TablaProductos;