import React, { useState, Fragment, useEffect } from "react";
import {
    Card, Button, InputGroup, Modal, Accordion, Row, Col,
    Form, Nav, Table, Dropdown, DropdownButton, FormControl,
} from 'react-bootstrap';
import {
    DEDALO_ALL_INVENTORY_GENERAL, DEDALO_INVENTORY_GENERAL_BY_ID, DEDALO_INVENTORY_GENERAL_COMBO,
    DEDALO_NEW_INVENTORY_GENERAL, DEDALO_UPDATE_INVENTORY_GENERAL, DEDALO_DEL_INVENTORY_GENERAL,
    DEDALO_CRM_ENTERPRICES_COMBO, DEDALO_COMBO_PRODUCTO,
} from "../../assets/APIAdresses";
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import { useUserSession } from "../../hooks";
import { Mensaje_Error_Inven_Gene } from '../mensajeserror/mensajeserror';
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../utils/routes';
import Navbar from '../navbar';
import Swal from "sweetalert2";
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const Inventario_general = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { user } = useUserSession();
    const [empresaselect, setEmpresaSelect] = useState(0);
    const [productselect, setProductSelect] = useState(0);
    const [inventarioselect, setInventariotSelect] = useState(0);
    const [saenselect, setSaEntSelect] = useState('');

    const [categor1, setCategor1] = useState({
        datos: [],
    });

    const [categor4, setCategor4] = useState({
        datos: [],
    });

    const [categor5, setCategor5] = useState({
        datos: [],
    });

    const [categor6, setCategor6] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        amount: 0,
        minimum_amount: 0,
        id_gen_edit: 0,
        name_gen_enterprice_edit: '',
        amount_edit: 0,
        minimum_amount_edit: 0,
        products_id_edit: 0,
        enterprice_id_edit: 0,
        name_product_edit: '',

        amount_move: 0,
        observations_move: '',
        id_move_edit: 0,
        type_move_edit: 0,
        amount_move_edit: 0,
        inventory_general_id_move_edit: 0,
        name_move_edit: '',
        observations_move_edit: '',

        amount_req: 0,
        observations_req: '',
        id_req_edit: 0,
        amount_req_edit: 0,
        inventory_general_id_req_edit: 0,
        name_req_edit: '',
        observations_req_edit: '',
    });

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => {
        setShow1(true);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => {
        setShow4(true);
    };

    const peticionGetInventarioGeneral = async (e) => {
        //e.preventDefault();
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_INVENTORY_GENERAL,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice);
                    setCategor1({
                        ...categor1,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor1({
                        ...categor1,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar el inventario 1");
                }
            });

        return info1;
    };

    const peticionGetEmpresasCombo = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CRM_ENTERPRICES_COMBO,
            headers: { Authorization: userToken },
            data: params_data,
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setCategor4({
                        ...categor4,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor4({
                        ...categor4,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;

    };

    const peticionGetProductosCombo = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_PRODUCTO,
            headers: { Authorization: userToken },
            data: params_data,
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setCategor5({
                        ...categor5,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor5({
                        ...categor5,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;

    };

    const peticionGetInventarioGCombo = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_INVENTORY_GENERAL_COMBO,
            headers: { Authorization: userToken },
            data: params_data,
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setCategor6({
                        ...categor6,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor6({
                        ...categor6,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;

    };

    const peticionGetInvGenById = async (id_edit) => {
        let idgen_edit = parseInt(id_edit);

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_INVENTORY_GENERAL_BY_ID + '&id=' + idgen_edit,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setDat({
                        ...dats,
                        ['id_gen_edit']: datanotice.id,
                        ['amount_edit']: datanotice.amountInventory,
                        ['minimum_amount_edit']: datanotice.minimum_amountInventory,
                        ['products_id_edit']: datanotice.products_id,
                        ['name_product_edit']: datanotice.productTypeName,
                        ['enterprice_id_edit']: datanotice.enterpriceid,
                        ['name_gen_enterprice_edit']: datanotice.enterpricesName,
                    });
                    handleShow4();
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                }
            });

        return info1;
    };

    const handleCloseSave1 = (e) => {
        e.preventDefault();
        const formattedData = {
            amount: parseInt(dats.amount),
            minimum_amount: parseInt(dats.minimum_amount),
            products_id: parseInt(productselect),
            enterprice_id: parseInt(empresaselect),
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_NEW_INVENTORY_GENERAL,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se a creado el inventario exitósamente", "", "success");
                    peticionGetInventarioGeneral();
                    peticionGetEmpresasCombo();
                    peticionGetProductosCombo();
                    peticionGetInventarioGCombo();
                    handleClose1();
                } else {
                    Mensaje_Error_Inven_Gene(response.data.msg.error);
                    //console.log(response.data.msg.error);
                }
            })
            .catch(error => {
                manageError(error);
            })
    };

    const handleCloseSave4 = (e) => {
        e.preventDefault();
        const formattedData = {
            amount: parseInt(dats.amount_edit),
            minimum_amount: parseInt(dats.minimum_amount_edit),
            products_id: parseInt(productselect === 0 ? dats.products_id_edit : productselect),
            enterprice_id: parseInt(empresaselect === 0 ? dats.enterprice_id_edit : dats.empresaselect),
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_UPDATE_INVENTORY_GENERAL + '&id=' + parseInt(dats.id_gen_edit),
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se a editado el inventario exitósamente", "", "success");
                    peticionGetInventarioGeneral();
                    handleClose4();
                } else {
                    //Mensaje_Error_Inven_Gene(response.data.msg.error);
                    console.log(response.data.msg.error);
                }
            })
            .catch(error => {
                manageError(error);
            })
    };

    const peticionDelete1 = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_INVENTORY_GENERAL + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el movimiento exitósamente", "", "success");
                    //window.location.reload();
                    peticionGetInventarioGeneral();
                } else {
                    //alert("ERROR: Server Error");
                    console.log(response);
                }
            })
    };

    useEffect(() => {
        peticionGetInventarioGeneral();
        peticionGetEmpresasCombo();
        peticionGetProductosCombo();
        peticionGetInventarioGCombo();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const handleInputChange1 = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const handleChangeProductos = (event) => {
        setProductSelect(event.target.value);
    };

    const handleChangeEmpresa = (event) => {
        setEmpresaSelect(event.target.value);
    };

    return (
        <div>
            <div className="d-flex container-fluid my-2">
                <div>
                    <Button className="btn btn-success rounded-circle" size="sm"
                        onClick={() => { window.history.back() }} title="Regresar"
                    >
                        <MdIcons.MdArrowBackIosNew />
                    </Button>
                </div>
            </div>
            <div class="container">
                <Card className='mt-2'>
                    <Card.Header>
                        <div className="d-flex">
                            <div>
                                Inventario general
                            </div>
                            <div className="ms-auto">
                                <BsIcons.BsPlusCircle title='Agregar nuevo invetario'
                                    onClick={handleShow1} style={{ cursor: 'pointer', fontSize: '1.3rem'}} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body style={{ height: '40em', maxHeight: '40em', overflowY: 'auto' }}>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th>Id</th>
                                    <th>producto</th>
                                    <th>Tipo de producto</th>
                                    <th>Proveedor</th>
                                    <th>Cantidad registrada</th>
                                    <th>Cantidad minima registrada</th>
                                    <th>Precio</th>
                                    <th>acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categor1.datos === undefined ?
                                    <div className="text-center">
                                        <h4>En el momento no hay registros</h4>
                                    </div>
                                    :
                                    categor1.datos.map((x, ind) => (                                                
                                        <tr className="text-center">
                                            <td>{ind + 1}</td>
                                            <td>{x.name}</td>
                                            <td>{x.productTypeName}</td>
                                            <td>{x.crmVendorName}</td>
                                            <td>{parseInt(x.amount) + ' ' + x.productTypemeasurement}</td>
                                            <td>{parseInt(x.minimum_amount) + ' ' + x.productTypemeasurement}</td>
                                            <td>{formatterPeso.format(x.price)}</td>
                                            <td className="d-flex justify-content-center align-items-center">
                                                <div class="dropdown">
                                                    <MdIcons.MdMenuOpen type="button" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                                                        style={{ fontSize: '1.5rem' }}
                                                    />
                                                    <ul class="dropdown-menu dropdown-menu-dark">
                                                        <li>
                                                            <a class="dropdown-item" onClick={() => peticionGetInvGenById(x.id)} style={{ cursor: 'pointer' }}>
                                                                Editar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" onClick={() => peticionDelete1(x.id)} style={{ cursor: 'pointer' }}>
                                                                Eliminar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>

            {/* MODAL CREAR INVENTARIO GENERAL */}
            <Modal show={show1} onHide={handleClose1} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Ingresar inventario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Nombre de la carpeta"
                                    aria-describedby="basic-addon1" autoFocus={true} type="text"
                                    onChange={handleInputChange1}
                                    id="amount" name="amount"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChangeProductos(e)}>
                                    <option>Seleccione un producto</option>
                                    {categor5.datos === undefined ?
                                        ''
                                        :
                                        categor5.datos.map((x, ind) => (
                                            <option value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad minima" aria-label="Nombre de la carpeta"
                                    aria-describedby="basic-addon1" type="text" onChange={handleInputChange1}
                                    id="minimum_amount" name="minimum_amount"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChangeEmpresa(e)}>
                                    <option>Seleccione una empresa</option>
                                    {categor4.datos === undefined ?
                                        ''
                                        :
                                        categor4.datos.map((x, ind) => (
                                            <option value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose1}>
                        Cancelar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={handleCloseSave1}>
                        Ingresar Inventario
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR INVENTARIO GENERAL */}
            <Modal show={show4} onHide={handleClose4} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar inventario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Nombre de la carpeta"
                                    aria-describedby="basic-addon1" autoFocus={true} type="text"
                                    onChange={handleInputChange1} value={dats.amount_edit}
                                    id="amount_edit" name="amount_edit"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChangeProductos(e)}>
                                    <option>{dats.name_product_edit}</option>
                                    {categor5.datos === undefined ?
                                        ''
                                        :
                                        categor5.datos.map((x, ind) => (
                                            <option value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad minima" aria-label="Nombre de la carpeta"
                                    aria-describedby="basic-addon1" type="text" onChange={handleInputChange1}
                                    id="minimum_amount_edit" name="minimum_amount_edit" value={dats.minimum_amount_edit}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChangeEmpresa(e)}>
                                    <option>{dats.name_gen_enterprice_edit}</option>
                                    {categor4.datos === undefined ?
                                        ''
                                        :
                                        categor4.datos.map((x, ind) => (
                                            <option value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose4}>
                        Cancelar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={handleCloseSave4}>
                        Ingresar Inventario Editado
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Inventario_general;