import React, {useState, Fragment, useEffect} from "react";
import { 
  Modal, Button, Container, Row, Col,
  Table,NavDropdown,Nav,InputGroup,FormControl
} from 'react-bootstrap';
import {
    DEDALO_ALL_WORKCUT,DEDALO_EDIT_WORKCUT,DEDALO_ALL_COMMENTS_WORKCUT,
    DEDALO_DEL_WORKCUT,DEDALO_NEW_WORKCUT,DEDALO_NEW_COMMENTS_WORKCUT,
    DEDALO_RESP_COMMENTS_WORKCUT,DEDALO_ALL_RES_COMMENTS_WORKCUT,
    DEDALO_WORKCUT_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import Navbar from '../../navbar';
import { Routes } from '../../../utils/routes';
import LogoDedalo from '../../../imagenes/dedalo_logo.png';
import * as MdIcons from 'react-icons/md';
import * as CgIcons from "react-icons/cg";
import * as FiIcons from 'react-icons/fi';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as BsIcons from 'react-icons/bs';

const TablaCortes = () => {
    const {userToken} = useUserSession();    
    const {idtarea} = useParams();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [cortes, setCortes] = useState();
    const [comentarioselect, setComentarioSelect] = useState();
    const [comentariores, setComentarioRes] = useState();
    const [comentarioresselect, setComentarioResSelect] = useState();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (e) => {
        setCortes(e);
        setShow1(true);
        peticionGetWorkCutById(e);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (i) => {
        setComentarioSelect(i);
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (i) => {
        setComentarioRes(i);
        setShow3(true);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (i) => {
        setComentarioResSelect(i);
        setShow4(true);
    };

    const [dats, setDat] = useState({
        comment_cut: '',
        observaciones: '',
        num_corte: '',
        porcentaje_corte: '',
        comment_rescut: '',
        presupuesto: '',
    });

    const [datsedit, setDatEdit] = useState({
        observaciones_edit: '',
        num_corte_edit: '',
        porcentaje_corte_edit: '',
        presupuesto_edit: '',
    });
    
    const [categor, setCategor] = useState({
        datos: [],
    });

    const [allcomments, setAllComments] = useState({
        datos: [],
    });

    const [allrescomments, setAllResComments] = useState({
        datos: [],
    });

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {   
        setDatEdit({
            ...datsedit,
            [event.target.name] : event.target.value
        })
    };
    
    const peticionGetCategor=async()=>{
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'percentage_advance',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_WORKCUT+'?page=1&rows=100&order=percentage_advance&inorder=asc&constructions_id=1&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_WORKCUT,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                ...categor,
                ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };
    
    const peticionDelete = (iduser) => {
        let delId = iduser;
        
        //axios.delete(DEDALO_DEL_WORKCUT(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_WORKCUT+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el corte exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const peticionGetAllCommentCuts=async()=>{
        if(comentarioselect === undefined){
            let oi = 0;
        }else{
            const params_data = {
                constructions_id: id,
                search: '',
                perpage: 1000,
                page: 1,
                orderField: 'percentage_advance',
                order: 'asc',
            };
            //let info1 = await axios.get(DEDALO_ALL_COMMENTS_WORKCUT+'?workcut_id='+comentarioselect)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_COMMENTS_WORKCUT+'&workcut_id='+comentarioselect,
                headers: {Authorization: userToken},
                data: params_data
            })
              .then(response=>{
                if (response.data.code === '200') {
                      let datanotice = response.data.data;
                      //console.log(datanotice.data);
                      setAllComments({
                        ...allcomments,
                        ['datos']: datanotice,
                      });
                  } else {
                     alert(message_responses[response.data.message]);
                  }
              })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                  }
              });
          
            return info1;
        }
    };

    const peticionGetAllResCommentsCuts=async()=>{
        if(comentarioresselect === undefined){
            let oi = 0;
        }else{
            const params_data = {
                constructions_id: id,
                search: '',
                perpage: 1000,
                page: 1,
                orderField: 'percentage_advance',
                order: 'asc',
            };
            //let info1 = await axios.get(DEDALO_ALL_RES_COMMENTS_WORKCUT+'?cuts_comments_id='+comentarioresselect)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_RES_COMMENTS_WORKCUT+'&cuts_comments_id='+comentarioresselect,
                headers: {Authorization: userToken},
                data: params_data
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    setAllResComments({
                    ...allrescomments,
                    ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
              })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                  }
              });
          
            return info1;
        }
    };

    const peticionGetWorkCutById=async(IdCut)=>{
        if(IdCut === undefined){
            let iu = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_WORKCUT+'?page=1&rows=100&order=percentage_advance&inorder=asc&constructions_id=1&search=')
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_WORKCUT_BY_ID+'&id='+IdCut,
                headers: {Authorization: userToken},
            })
              .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setDatEdit({
                        ...datsedit,
                        ['observaciones_edit']: datanotice.data.observations,
                        ['num_corte_edit']: datanotice.data.workcutnumber,
                        ['porcentaje_corte_edit']: datanotice.data.percentage_advance,
                        ['presupuesto_edit']: datanotice.data.executed,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
              })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                  }
              });
          
            return info1;
        }
    };
    
    useEffect(() => {
        peticionGetCategor();
    }, []);

    useEffect(() => {
        peticionGetAllCommentCuts();
    }, [comentarioselect]);

    useEffect(() => {
        peticionGetAllResCommentsCuts();
    }, [comentarioresselect]);

    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let observaciones = dats.observaciones;
        let porcentaje_corte = dats.porcentaje_corte;
        let corte_num = dats.num_corte;
        
        const formattedData = {
            constructions_id: datahomi.idProject,
            user_id: userId,
            workcutnumber: corte_num,
            percentage_advance: porcentaje_corte,
            executed: parseFloat(dats.presupuesto),
            observations: observaciones,
        };
        //console.log(formattedData);
        if(corte_num === '' || porcentaje_corte === ''){
            Swal.fire({
                icon: 'info',
                title: 'No se puede crear el corte',
                text: 'Porfavor verifique que los campos numero corte y porcentaje de corte no esten vacios',
            });
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NEW_WORKCUT,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el corte exitósamente", "", "success");
                    document.getElementById('num_corte').value = '';
                    document.getElementById('porcentaje_corte').value = '';
                    document.getElementById('presupuesto').value = '';
                    document.getElementById('observaciones').value = '';
                    handleClose(false);
                    peticionGetCategor();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const handleCloseSaveEdit = () => {
    
        //e.preventDefault();
        let observaciones_edit = datsedit.observaciones_edit;
        let porcentaje_corte_edit = datsedit.porcentaje_corte_edit;
        let corte_num_edit = datsedit.num_corte_edit;
        
        const formattedData = {
            constructions_id: id,
            user_id: userId,
            workcutnumber: corte_num_edit,
            percentage_advance: porcentaje_corte_edit,
            executed: parseFloat(datsedit.presupuesto_edit),
            observations: observaciones_edit,
        };
        //console.log([formattedData,cortes]);
        //axios.put(DEDALO_EDIT_WORKCUT(cortes), formattedData, attachToken(user))
        axios({
            method: 'PUT',
            url: DEDALO_EDIT_WORKCUT+'&id='+cortes,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se actualizo el corte exitósamente", "", "success");
                handleClose1(false);
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const handleCloseSaveCommet = (e) => {
    
        //e.preventDefault();
        let observaciones = dats.comment_cut;
        
        const formattedData = {
            workcut_id: comentarioselect,
            creator_id: userId,
            comment: observaciones,
        };
        //console.log(formattedData);
        if(observaciones === ''){
            Swal.fire({
                icon: 'info',
                title: 'No se puede guardar el comentario',
                text: 'Porfavor verifique que el campo no este vacio',
            });
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NEW_COMMENTS_WORKCUT,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se envio el comentario exitósamente", "", "success");
                    document.getElementById('comment_cut').value = '';
                    //handleClose2(false);
                    peticionGetAllCommentCuts();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const handleCloseResCommet = (e) => {
    
        //e.preventDefault();
        let observaciones = dats.comment_rescut;
        
        const formattedData = {
            cuts_comments_id: comentariores,
            creator_id: userId,
            response: observaciones,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_RESP_COMMENTS_WORKCUT, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_RESP_COMMENTS_WORKCUT,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se envio el comentario exitósamente", "", "success");
                document.getElementById('comment_rescut').value = '';
                handleClose3(false);
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };
    
    const fecha_comentario = (datadate) =>{
        let fecha_comentarios = new Date(datadate);
        let dia_comentario = fecha_comentarios.getDate();
        let mes_comentario = fecha_comentarios.getMonth();
        let año_comentario = fecha_comentarios.getFullYear();
        let fecha_res = dia_comentario+'/'+mes_comentario+'/'+año_comentario;

        return fecha_res;
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        //minimumFractionDigits: 0
    });

    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div >
            {/*<Navbar modulo="Tabla Subtareas" usuario={user.name}/>*/}
            <br/>
            <div className="w-100">
                <div className="btnreport">
                    <Button className="btn btn-primary me-2 rounded-circle ml-4 regresar" title="Regresar" 
                        onClick={()=>{window.history.back()}} size='sm'
                    >
                        <MdIcons.MdOutlineKeyboardBackspace/>
                    </Button>
                    <Button className="btn btn-success me-2 nuevo" onClick={handleShow}
                        style={{backgroundColor: "#02B371"}} size='sm'>
                        Corte Nuevo
                    </Button>
                </div>
                <Container className="tabla_cortes">
                    <br/>
                    <Table responsive bordered hover size="sm" style={{fontSize: "0.9rem"}} >
                        <thead style={{textAlign: 'center'}}>
                            <tr className="bg-primary text-white ">
                                <th>#</th>
                                <th>Proyecto</th>
                                <th>Usuario</th>
                                <th>Fecha Corte</th>
                                <th>Corte N°</th>
                                <th>Porcentaje Corte</th>
                                <th>Presupuesto ejecutado</th>
                                <th>Observaciones</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody style={{textAlign: 'center'}}>
                            {categor.datos === undefined ?
                                ''
                            :
                                categor.datos.map(item => (
                                    <tr key={item}>
                                        <td>
                                            {item.id}
                                        </td>
                                        <td>
                                            {item.constructionsName}
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-between">
                                                <div class="d-flex justify-content-between" key={item.id}>
                                                    <div>
                                                        <img src={LogoDedalo} className="me-2" 
                                                            style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                    </div>
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <b style={{color: "#d35400"}}>
                                                            {item.userName}
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {item.date}
                                        </td>
                                        <td>
                                            {item.workcutnumber}
                                        </td>
                                        <td style={{width: '9rem'}}>
                                            {parseInt(item.percentage_advance)}%
                                        </td>
                                        <td style={{width: '11rem'}}>
                                            {formatterPeso.format(parseFloat(item.executed))}
                                        </td>
                                        <td>
                                            {item.observations}
                                        </td>
                                        <td>
                                            <NavDropdown id="collasible-nav-dropdown">
                                                <NavDropdown.Item onClick={()=>handleShow1(item.id)}>Editar</NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>peticionDelete(item.id)}>Eliminar</NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleShow2(item.id)}>Ver Comentarios</NavDropdown.Item>
                                            </NavDropdown>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>

                    {/* MODAL NUEVO CORTE */}
                    <Modal show={show} onHide={handleClose} size="md" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar Corte Nuevo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <AiIcons.AiOutlineFieldNumber />
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Numero de Corte" aria-label="Numero de Corte"
                                            aria-describedby="basic-addon1"
                                            type="email" onChange={handleInputChange}
                                            id="num_corte" name="num_corte"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <FiIcons.FiPercent />
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Porcentaje del corte" aria-label="Porcentaje del corte"
                                            aria-describedby="basic-addon1"
                                            type="email" onChange={handleInputChange}
                                            id="porcentaje_corte" name="porcentaje_corte"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <BsIcons.BsCurrencyDollar />
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Presupuesto del corte" aria-label="Presupuesto del corte"
                                            aria-describedby="basic-addon1"
                                            type="email" onChange={handleInputChange}
                                            id="presupuesto" name="presupuesto"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="textareatask">
                                        <InputGroup.Text>
                                            <CgIcons.CgNotes/>
                                        </InputGroup.Text>
                                        <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChange}
                                            placeholder="Descripcion del corte" id="observaciones" name="observaciones"
                                            style={{resize: "none"}}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} size='sm'>
                                Cancelar
                            </Button>
                            <Button variant="primary" onClick={handleCloseSave} size='sm'>
                                Agregar Corte
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* MODAL EDITAR CORTE */}
                    <Modal show={show1} onHide={handleClose1} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>Editar Corte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <AiIcons.AiOutlineFieldNumber />
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Numero de Corte" aria-label="Numero de Corte"
                                            aria-describedby="basic-addon1" value={datsedit.num_corte_edit}
                                            type="email" onChange={handleInputChangeEdit}
                                            id="num_corte_edit" name="num_corte_edit"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <FiIcons.FiPercent />
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Porcentaje del corte" aria-label="Porcentaje del corte"
                                            aria-describedby="basic-addon1" value={datsedit.porcentaje_corte_edit}
                                            type="email" onChange={handleInputChangeEdit}
                                            id="porcentaje_corte_edit" name="porcentaje_corte_edit"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <BsIcons.BsCurrencyDollar />
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Presupuesto del corte" aria-label="Presupuesto del corte"
                                            aria-describedby="basic-addon1" value={datsedit.presupuesto_edit}
                                            type="email" onChange={handleInputChangeEdit}
                                            id="presupuesto_edit" name="presupuesto_edit"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputGroup className="textareatask">
                                        <InputGroup.Text>
                                            <CgIcons.CgNotes/>
                                        </InputGroup.Text>
                                        <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeEdit}
                                            placeholder="Descripcion de la tarea" id="observaciones_edit" name="observaciones_edit"
                                            style={{resize: "none"}} value={datsedit.observaciones_edit}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose1}>
                                Cancelar
                            </Button>
                            <Button variant="primary" onClick={handleCloseSaveEdit}>
                                Editar Corte
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* MODAL COMENTARIOS CORTE */}
                    <Modal show={show2} onHide={handleClose2} size="md" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Comentarios Corte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                    <BiIcons.BiCommentDetail/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Comentar Corte" id="comment_cut"
                                    aria-label="Comentar Corte" name="comment_cut"
                                    aria-describedby="basic-addon1" onChange={handleInputChange}
                                />
                                <Button variant="success" onClick={handleCloseSaveCommet} title="Enviar Comentario">
                                    <MdIcons.MdSend/>
                                </Button>
                            </InputGroup>
                            <div style={{maxHeight: "18em",overflow: "scroll",overflowX: "hidden"}}>
                                {allcomments.datos.map(r => (
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="d-flex justify-content-between mb-2" key={r.idcomment}>
                                            <div>
                                                <img src={r.image === '' ? LogoDedalo : r.image} className="me-2" 
                                                    style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                            </div>
                                            <div style={{fontSize: "0.8em"}}>
                                                <b style={{color: "#d35400"}}>
                                                    {r.creatorName}
                                                </b> - {fecha_comentario(r.date)}
                                                <br/>
                                                {r.comment}
                                                <br/>
                                                <a href="#" onClick={()=>handleShow3(r.id)} className="text-decoration-none me-2">
                                                    Comentar
                                                </a>
                                                <a href="#" onClick={()=>handleShow4(r.id)} className="text-decoration-none">
                                                    Ver Respuestas 
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose2}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* MODAL COMENTAR COMENTARIO CORTE */}
                    <Modal show={show3} onHide={handleClose3} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>Responder comentario Corte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                    <BiIcons.BiCommentDetail/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Comentar Corte" id="comment_rescut"
                                    aria-label="Comentar Corte" name="comment_rescut"
                                    aria-describedby="basic-addon1" onChange={handleInputChange}
                                />
                                <Button variant="success" onClick={handleCloseResCommet} title="Enviar Comentario">
                                    <MdIcons.MdSend/>
                                </Button>
                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose3}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* MODAL RESPUESTAS COMENTARIOS CORTE */}
                    <Modal show={show4} onHide={handleClose4} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>Respuestas Comentarios Corte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{maxHeight: "18em",overflow: "scroll",overflowX: "hidden"}}>
                                {allrescomments.datos.map(r => (
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="d-flex justify-content-between mb-2" key={r.idcomment}>
                                            <div>
                                                <img src={r.image === '' ? LogoDedalo : r.image} className="me-2" 
                                                    style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                            </div>
                                            <div style={{fontSize: "0.8em"}}>
                                                <b style={{color: "#d35400"}}>
                                                    {r.creatorName}
                                                </b> - {fecha_comentario(r.date)}
                                                <br/>
                                                {r.response}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
        </div>
    )
}

export default TablaCortes
