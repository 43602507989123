import React, {useState, Fragment, useEffect} from "react";
import { 
  Card, Button, Container, Row, Col,InputGroup,
  Table,DropdownButton,Dropdown,Modal,FormControl,
  Form,
} from 'react-bootstrap';
import {
    DEDALO_ALL_CRM_ENTERPRICES,DEDALO_DEL_CRM_ENTERPRICES,
    DEDALO_NEW_ENTERPRICES,DEDALO_UPDATE_CRM_ENTERPRICES,
    DEDALO_CRM_ENTERPRICES_BY_ID,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import Navbar from '../navbar';
import { Routes } from '../../utils/routes';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as GrIcons from "react-icons/gr";

function CrmEmpresas() {
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [ciudadselect, setCiudadSelect] = useState();
    const [empresaid, setEmpresaId] = useState();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        nombre: '',
        dir: '',
        rut: '',
        ciudad: '',
        tel1: '',
        tel2: '',
        email: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        nombre_edit: '',
        dir_edit: '',
        rut_edit: '',
        ciudad_edit: '',
        ciudadId_edit: '',
        tel1_edit: '',
        tel2_edit: '',
        email_edit: '',
    });
    
    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CRM_ENTERPRICES+'?page=1&rows=100&order=name&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CRM_ENTERPRICES,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice.records,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
            }
        });
      
        return info1;
    }
    
    const peticionDelete = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_DEL_CRM_ENTERPRICES(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_CRM_ENTERPRICES+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se ha eliminado la empresa exitosamente", "", "success");
                peticionGetCategor();
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                Swal.fire("No se pudo eliminar la empresa", "", "warning");
            }
        });
        
    };

    const peticionGetEmpresaById=async()=>{
        if(empresaid === undefined){
             let iu = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_CRM_ENTERPRICES_BY_ID+'&id='+empresaid,
                headers: {Authorization: userToken},
            })
            .then(response=>{
            if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setDat_Edit({
                        ...dats_edit,
                        ['nombre_edit']: datanotice.data.name,
                        ['dir_edit']: datanotice.data.address,
                        ['rut_edit']: datanotice.data.rut,
                        ['ciudad_edit']: datanotice.data.cityname,
                        ['ciudadId_edit']: datanotice.data.cities_id,
                        ['tel1_edit']: datanotice.data.phone1,
                        ['tel2_edit']: datanotice.data.phone2,
                        ['email_edit']: datanotice.data.email,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
        
            return info1;
        }
     };
    
    useEffect(() => {
        peticionGetCategor();
    }, []);

    useEffect(() => {
        peticionGetEmpresaById();
    }, [empresaid]);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setEmpresaId(e);
        setShow3(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {   
        setDat_Edit({
            ...dats_edit,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeSelectCiudad = (event) => {
        setCiudadSelect(event.target.value);
    };

    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let nombre_emp = dats.nombre;
        let dir_emp = dats.dir;
        let rut_emp = dats.rut;
        let tel_emp1 = dats.tel1;
        let tel_emp2 = dats.tel2;
        let email_emp2 = dats.email;
        
        const formattedData = {
            name: nombre_emp,
            address: dir_emp,
            cities_id: ciudadselect,
            phone1: tel_emp1,
            phone2: tel_emp2,
            email: email_emp2,
            rut: rut_emp,
        };
        console.log(formattedData);
        if(nombre_emp === '' || ciudadselect === undefined || email_emp2 === ''){
            Swal.fire({
                title: 'Atencion',
                text: 'Los campos nombre, ciudad e email son obligatorios',
                icon: 'warning',
            });
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NEW_ENTERPRICES,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creo la empresa exitósamente", "", "success");
                    document.getElementById("nombre").value = "";
                    document.getElementById("dir").value = "";
                    document.getElementById("rut").value = "";
                    document.getElementById("tel1").value = "";
                    document.getElementById("tel2").value = "";
                    document.getElementById("email").value = "";
                    document.getElementById("select_city").value = "";
                    peticionGetCategor();
                    handleClose2();
                }else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    const handleCloseSaveEdit = (e) => {
    
        //e.preventDefault();
        let nombre_emp_edit = dats_edit.nombre_edit;
        let dir_emp_edit = dats_edit.dir_edit;
        let rut_emp_edit = dats_edit.rut_edit;
        let tel_emp1_edit = dats_edit.tel1_edit;
        let tel_emp2_edit = dats_edit.tel2_edit;
        let email_emp2_edit = dats_edit.email_edit;
        
        const formattedData = {
            name: nombre_emp_edit,
            address: dir_emp_edit,
            cities_id: ciudadselect === undefined ? parseInt(dats_edit.ciudadId_edit) : ciudadselect,
            phone1: tel_emp1_edit,
            phone2: tel_emp2_edit,
            email: email_emp2_edit,
            rut: rut_emp_edit,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_ENTERPRICES, formattedData, attachToken(user))
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_CRM_ENTERPRICES+'&id='+empresaid,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se edito la empresa exitósamente", "", "success");
                peticionGetCategor();
                handleClose3();
            }else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div >
            {/*<Navbar modulo="Empresas" usuario={user.name}/>*/}
            <div className="w-100">
                <br/>
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                    <Button className="botonobras me-2" size="sm" onClick={handleShow2}
                        style={{backgroundColor: "#02B371"}}>
                        Agregar Empresa
                    </Button>
                </div>
                <br/>
                <Container >
                    <Row xs={12} md={4} className="g-4">
                        {categor.datos.length === 0 ?
                            <div className="mensajeinfo">
                                <h4>
                                    En el momento no hay empresas registradas.
                                </h4>
                            </div>
                        :
                            categor.datos.map(i => (
                                <Col style={{borderRadius: "40%", width: "30em"}}>
                                    <Card key={i} style={{borderRadius: "1em"}}>
                                        <Card.Header as="h6" className="with-elements">
                                            <div className="card-header-title">{i.name}</div>
                                            <div className="card-header-elements ml-auto tableactions">
                                                <DropdownButton
                                                    id="dropdown-button-dark-example2"
                                                    variant="primary"
                                                    menuVariant="dark"
                                                    className="mt-2"
                                                >
                                                    <Dropdown.Item onClick={()=>handleShow3(i.id)}>
                                                        Editar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={()=>peticionDelete(i.id)}>
                                                        Eliminar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <Link to={Routes.DEP_EMP(i.id)} className="text-decoration-none">
                                                            Departamentos
                                                        </Link>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <Link to={Routes.VER_ORGANIGRAMA(i.id)} className="text-decoration-none">
                                                            Ver Organigrama
                                                        </Link>
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <p>
                                                    <b>Direccion</b> <br/> {i.address}
                                                </p>
                                                <p>
                                                    <b>Rut</b> <br/> {i.rut}
                                                </p>
                                                <p>
                                                    <b>Ciudad</b> <br/> {i.cityname}
                                                </p>
                                                <p>
                                                    <b>Telefonos</b> <br/> {i.phone1} - {i.phone2}
                                                </p>
                                                <p>
                                                    <b>Correo de contacto</b> <br/> {i.email}
                                                </p>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                    <br/>
                </Container>
            </div>
            {/* MODAL NUEVA EMPRESA */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered className="iconosmodal">
                    <Modal.Header closeButton>
                        <Modal.Title>Cargar Empresa Nueva</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsBuilding className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Nombre de la Empresa" aria-label="Nombre de la Empresa"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="nombre" name="nombre"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <FaIcons.FaMapMarkerAlt className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Direccion" aria-label="Direccion"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="dir" name="dir"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <AiIcons.AiOutlineNumber className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Rut" aria-label="Rut"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="rut" name="rut"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdLocationCity className="text-primary"/>
                                    </InputGroup.Text>
                                    <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectCiudad} id="select_city" name="select_city"
                                    >
                                        <option value="">Seleccione la ciudad</option>
                                        <option value={1}>Bogota</option>
                                        <option value={2}>Cali</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsTelephonePlus className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Telefono 1" aria-label="Telefono 1"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="tel1" name="tel1"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsTelephonePlus className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Telefono 2" aria-label="Telefono 2"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="tel2" name="tel2"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdOutlineAlternateEmail className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Correo contacto" aria-label="Correo contacto"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="email" name="email"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="boton1" onClick={handleClose2}>
                            Cerrar
                        </Button>
                        <Button className="boton2" onClick={()=>handleCloseSave()}>
                            Cargar Empresa
                        </Button>
                    </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR EMPRESA */}
            <Modal show={show3} onHide={handleClose3} size="lg" centered className="iconosmodal">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Empresa Nueva</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsBuilding className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Nombre de la Empresa" aria-label="Nombre de la Empresa"
                                        aria-describedby="basic-addon1" value={dats_edit.nombre_edit}
                                        type="text" onChange={handleInputChangeEdit}
                                        id="nombre_edit" name="nombre_edit"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <FaIcons.FaMapMarkerAlt className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Direccion" aria-label="Direccion"
                                        aria-describedby="basic-addon1" value={dats_edit.dir_edit}
                                        type="text" onChange={handleInputChangeEdit}
                                        id="dir_edit" name="dir_edit"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <AiIcons.AiOutlineNumber className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Rut" aria-label="Rut" value={dats_edit.rut_edit}
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChangeEdit}
                                        id="rut_edit" name="rut_edit"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdLocationCity className="text-primary"/>
                                    </InputGroup.Text>
                                    <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectCiudad} id="select_city" name="select_city"
                                    >
                                        <option value="">{dats_edit.ciudad_edit}</option>
                                        <option value={1}>Bogota</option>
                                        <option value={2}>Cali.</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsTelephonePlus className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Telefono 2" aria-label="Telefono 2"
                                        aria-describedby="basic-addon1" value={dats_edit.tel2_edit}
                                        type="text" onChange={handleInputChangeEdit}
                                        id="tel2_edit" name="tel2_edit"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsTelephonePlus className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Telefono 1" aria-label="Telefono 1"
                                        aria-describedby="basic-addon1" value={dats_edit.tel1_edit}
                                        type="text" onChange={handleInputChangeEdit}
                                        id="tel1_edit" name="tel1_edit"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdOutlineAlternateEmail className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Correo contacto" aria-label="Correo contacto"
                                        aria-describedby="basic-addon1" value={dats_edit.email_edit}
                                        type="text" onChange={handleInputChangeEdit}
                                        id="email_edit" name="email_edit"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="boton1" onClick={handleClose3}>
                            Cerrar
                        </Button>
                        <Button className="boton2" onClick={()=>handleCloseSaveEdit()}>
                            Editar Empresa
                        </Button>
                    </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CrmEmpresas;