import React, {useState, Fragment, useEffect} from "react";
import { 
  Card, Button, Container, Row, Col,
  FormControl,InputGroup,Form
} from 'react-bootstrap';
import {
    DEDALO_NEW_SUBTASK,DEDALO_ALL_USER_COMBO
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import Navbar from '../../navbar';
import { Routes } from '../../../utils/routes';
import Select  from 'react-select';
import {DateTimePicker} from '@material-ui/pickers';
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as GrIcons from "react-icons/gr";
import * as BiIcons from "react-icons/bi";

function NuevaSubTarea() {
    const {userToken} = useUserSession();
    const {idtarea} = useParams();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [boton, setBoton] = useState(0);
    const [prioridad, setPrioridad] = useState('');
    const [tareaestado, setTareaestado] = useState('');
    const [observadores, setObservadores] = useState([]);
    const [responsables, setResponsables] = useState([]);
    const [participantes, setParticipantes] = useState([]);
    const [fechaseleccionada, setFechaSeleccionada] = useState(new Date());
    const [fechaseleccionada1, setFechaSeleccionada1] = useState(new Date());
    const [archivobase64, setArchivoBase64] = useState();

    const [dats, setDat] = useState({
        titulo: '',
        obs: '',
        descripcion: '',
        pres: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const handleChangePrioridad = (event) => {
        setPrioridad(event.target.value);
    };

    const handleChangeEstadoTarea = (event) => {
        setTareaestado(event.target.value);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetUserCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
            }
        });
      
        return info1;
    }

    useEffect(() => {
        peticionGetUserCombo();
    }, []);

    const selectusuarios = [];
    categor.datos.map(i => {
        selectusuarios.push({
            value: i.id,
            label: i.name,
        });
    });

    const [datsfile, setDatFile] = useState({
        ext: '',
        title_file: '',
    });

    const handlechangeObs = (e) =>{
        setObservadores(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const handlechangeRes = (e) =>{
        setResponsables(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const handlechangePar = (e) =>{
        setParticipantes(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const convertToBase64 = () => {
        //Read File
        var selectedFile = document.getElementById("pdfup").files;
        let selectedFile1 = document.getElementById("pdfup").value;
        let nom1_archivo = document.getElementById('pdfup').files[0];
        let nom2_archivo = document.getElementById('pdfup');
        let extension = selectedFile1.substring(selectedFile1.lastIndexOf('.'),selectedFile1.length);
        let MAXIMO_TAMANIO_BYTES = 50000000;

        setDatFile({
            ...datsfile,
            ['title_file']: nom1_archivo.name.split('.')[0],
            ['ext']: extension
        });

        if(nom1_archivo.size > MAXIMO_TAMANIO_BYTES){
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!",`El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`,"info");
            nom2_archivo.value = "";
            
            setDatFile({
                ...datsfile,
                ['title_file']: '',
                ['ext']: ''
            });
        }else{
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                //var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.split(',')
                    setArchivoBase64(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                // Obtener extensión del archivo
            }
        }
    };
    
    let dia = fechaseleccionada.getDate();
    let mes = fechaseleccionada.getMonth()+1;
    let año = fechaseleccionada.getFullYear();
    let hora = fechaseleccionada.getHours();
    let minutos = fechaseleccionada.getMinutes();
    let segundos = fechaseleccionada.getSeconds();
    let fecha = año+'-'+mes+'-'+dia;

    let fechafinal = fecha+' '+hora+':'+minutos+':'+segundos;

    let dia1 = fechaseleccionada1.getDate();
    let mes1 = fechaseleccionada1.getMonth()+1;
    let año1 = fechaseleccionada1.getFullYear();
    let hora1 = fechaseleccionada1.getHours();
    let minutos1 = fechaseleccionada1.getMinutes();
    let segundos1 = fechaseleccionada1.getSeconds();
    let fecha1 = año1+'-'+mes1+'-'+dia1;

    let fechafinal1 = fecha1+' '+hora1+':'+minutos1+':'+segundos1;

    const handleCloseSave = (e) => {
    
        let titulo = dats.titulo;
        let obs = dats.obs;
        let des = dats.descripcion;
        let fechaincial = fechafinal1;
        let fechalimite = fechafinal;
        let subtarea_prioridad = prioridad;
        let subtarea_estado = tareaestado;
        let observador_subtarea = observadores;
        let responsable_subtarea = responsables;
        let participante_subtarea = participantes;
        let presupuesto = dats.pres;
        
        const formattedData = {
            constructions_id: datahomi.idProject,
            creator_id: userId,
            constructions_task_id: idtarea,
            startdate: fechaincial,
            deadline: fechalimite,
            title: titulo,
            description: des,
            observations: obs,
            budget: presupuesto,
            priority: subtarea_prioridad,
            status: subtarea_estado,
            responsable: responsable_subtarea,
            observers: observador_subtarea,
            participants: participante_subtarea,
            type: datsfile.ext.split('.')[1],
            file: archivobase64,
        };
        
        //console.log(formattedData);
        if(titulo === ''){
            Swal.fire("No se pudo crear la subtarea, valide que los campos no esten en blanco", "", "warning");
        }else{
            //axios.post(DEDALO_NEW_SUBTASK, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NEW_SUBTASK,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó la Subtarea exitósamente", "", "success");
                    document.getElementById('titulo').value = '';
                    document.getElementById('obs').value = '';
                    document.getElementById('descripcion').value = '';
                    document.getElementById('pres').value = '';
                    document.getElementById('select_tarea').value = '';
                    document.getElementById('select_status').value = '';
                } else {
                    alert("ERROR: Server Error");
                }
            })
            //.catch(error => {
            //    manageError(error);
            //})
        }
    };


    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div >
            {/*<Navbar modulo="Nueva Subtarea" usuario={user.name}/>*/}
            <br/>
            <div className="w-100">
                <div>
                    <Button className="btn btn-success me-2 rounded-circle ml-4" title="Regresar" 
                        onClick={()=>{window.history.back()}} style={{backgroundColor: "#02B371"}}
                    >
                        <MdIcons.MdOutlineKeyboardBackspace/>
                    </Button>
                </div>
                <Container className="w-50">
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdIcons.MdSubtitles />
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Titulo subtarea" aria-label="Titulo subtarea"
                                                aria-describedby="basic-addon1"
                                                type="email" onChange={handleInputChange}
                                                id="titulo" name="titulo"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdIcons.MdOutlineSpeakerNotes/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Observaciones" aria-label="Observaciones"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="obs" name="obs"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <GrIcons.GrStatusCritical/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangePrioridad} id="select_tarea" name="select_tarea"
                                            >
                                                <option value="">Prioridad de la subtarea</option>
                                                <option value='high'>Alto</option>
                                                <option value='medium'>Medio</option>
                                                <option value='low'>Bajo</option>
                                            </Form.Select>
                                        </InputGroup>    
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BiIcons.BiStats/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeEstadoTarea} id="select_status" name="select_status"
                                            >
                                                <option value="">Estado de la subtarea</option>
                                                <option value='pending'>pendiente</option>
                                                <option value='in process'>en progreso</option>
                                                <option value='under review'>bajo revisión</option>
                                                <option value='completed'>Completado</option>
                                                <option value='closed'>Cerrada</option>
                                                <option value='canceled'>Cancelada</option>
                                                <option value='reopened'>Reabierta</option>
                                            </Form.Select>
                                        </InputGroup> 
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdIcons.MdOutlineSpeakerNotes/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Presupuesto" aria-label="Presupuesto"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="pres" name="pres"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1" className="me-2">
                                                <BiIcons.BiCalendarAlt/>
                                            </InputGroup.Text>
                                            <DateTimePicker value={fechaseleccionada} onChange={setFechaSeleccionada}/>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1" className="me-2">
                                                <BiIcons.BiCalendarAlt/>
                                            </InputGroup.Text>
                                            <DateTimePicker value={fechaseleccionada1} onChange={setFechaSeleccionada1}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <Form.Label htmlFor="basic-url">Observadores</Form.Label>
                                            <Select isMulti options={selectusuarios} className="w-100" onChange={handlechangeObs}></Select>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <Form.Label htmlFor="basic-url">Responsables</Form.Label>
                                            <Select isMulti options={selectusuarios} onChange={handlechangeRes} className="w-100" ></Select>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <Form.Label htmlFor="basic-url">Participantes</Form.Label>
                                            <Select isMulti options={selectusuarios} onChange={handlechangePar} className="w-100" ></Select>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <label style={{fontSize: 14,fontWeight: 'bold'}}>Seleccione un archivo</label>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <FormControl
                                                type="file" onChange={()=>convertToBase64()}
                                                id="pdfup" name="pdfup"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xs={12}>
                                        <InputGroup className="textareatask">
                                            <InputGroup.Text>
                                                <CgIcons.CgNotes/>
                                            </InputGroup.Text>
                                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChange}
                                                placeholder="Descripcion de la subtarea" id="descripcion" name="descripcion"/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <div className="text-end">
                                <Button onClick={()=>handleCloseSave()} className="btn btn-success" 
                                    style={{backgroundColor: "#02B371"}}>
                                    Crear Subtarea
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Container>
            </div>
        </div>
    );
}

export default NuevaSubTarea;
