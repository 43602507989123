import React, {useState, Fragment, useEffect} from "react";
import Navbar from '../navbar';
import { 
    Card, Row, Col, Accordion, Dropdown, InputGroup,
    DropdownButton, Button, Modal, Table, FormControl,
    Form,
} from 'react-bootstrap'
import { Badge } from "reactstrap";
import Select  from 'react-select';
import {useUserSession} from "../../hooks";
import { Routes } from "../../utils/routes";

import {
    DEDALO_ALL_MEETING,DEDALO_NEW_MEETING,DEDALO_EDIT_MEETING,
    DEDALO_DEL_MEETING,DEDALO_MEETING_BY_ID,DEDALO_MEETING_FILE,
    DEDALO_MEETING_COMMENT,DEDALO_ALL_USER_COMBO
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import { 
    attachToken,
    manageError,
} from "../../utils/utils";
import {Redirect, useParams, Link} from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactHtmlParser from 'html-react-parser';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";

const Reuniones = () => {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [responsables, setResponsables] = useState([]);
    const [archivobase64, setArchivoBase64] = useState();
    const [body, setBody] = useState('');

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [datsfile, setDatFile] = useState({
        ext: '',
        title_file: '',
    });

    const [dats, setDat] = useState({
        titulo_1: '',
        observaciones_acta: '',
    });

    const convertToBase64 = () => {
        //Read File
        var selectedFile = document.getElementById("pdfup").files;
        let selectedFile1 = document.getElementById("pdfup").value;
        let nom1_archivo = document.getElementById('pdfup').files[0];
        let nom2_archivo = document.getElementById('pdfup');
        let extension = selectedFile1.substring(selectedFile1.lastIndexOf('.'),selectedFile1.length);
        let MAXIMO_TAMANIO_BYTES = 50000000;

        setDatFile({
            ...datsfile,
            ['title_file']: nom1_archivo.name.split('.')[0],
            ['ext']: extension
        });

        if(nom1_archivo.size > MAXIMO_TAMANIO_BYTES){
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!",`El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`,"info");
            nom2_archivo.value = "";
            
            setDatFile({
                ...datsfile,
                ['title_file']: '',
                ['ext']: ''
            });
        }else{
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                //var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.split(',')
                    setArchivoBase64(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                // Obtener extensión del archivo
            }
        }
    };

    const peticionGetUserCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor({
                ...categor,
                ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetUserCombo();
    }, []);

    const handleChange = (e) => {
        //console.log(e);
        setBody(e);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const modules = {
        toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
        {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'link', 'image', 'video', 
        'code-block',
    ];

    const handlechangeRes = (e) =>{
        setResponsables(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const selectusuarios = [];
    categor.datos.map(i => {
        selectusuarios.push({
            value: i.id,
            label: i.name,
        });
    });

    const mensaje_enviado = () => {
        Swal.fire({
            title: 'Mensaje enviado',
            text: 'Mensaje enviado correctamente',
            icon: 'success',
            confirmButtonText: 'Ok'
        })
    };

    let fecha_acta = new Date();
    let dia = fecha_acta.getDate();
    let mes = fecha_acta.getMonth()+1;
    let año = fecha_acta.getFullYear();
    let fecha = año+'-'+mes+'-'+dia;

    const handleCloseSave = (e) => {
    
        let name = dats.titulo_1;
        let obs = dats.observaciones_acta;
        
        const formattedData = {
            construction_id: id,
            title: name,
            date: fecha,
            content: body,
            observations: obs,
            signatories: responsables,
        };
        //console.log(formattedData);
        if(responsables.length === 0 || body === ''){
            Swal.fire("No se pudo crear el acta, valide que los campos titulo, observaciones y responsables este con informacion", "", "warning");
        }else{
            //axios.post(DEDALO_NUEVA_CONSTRUCCION, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NEW_MEETING,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el acta exitósamente", "", "success");
                    document.getElementById('titulo_1').value = '';
                    document.getElementById('observaciones_acta').value = '';
                } else {
                    Swal.fire("!!! Atencion !!!", response.data.msg.error, "info");
                }
            })
            .catch(error => {
                manageError(error);
            });   
        };
    };

    //console.log([archivobase64,datsfile]);


    return(
        <div>
            <div className="container-fluid flex-grow-1 container-p-y div_acta">
                <div className="d-flex ">
                    <div>
                        <Button className="btn btn-success rounded-circle" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar"
                            style={{backgroundColor: '#198754', color: '#FFFFFF'}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div className="text-center text-primary w-100">
                        <h2>Acta de reunion</h2>
                    </div>
                </div>
                <hr className="mb-4"/>
                <div className="container">
                    <Row>
                        <Col md={12} xs={12} className="mb-4">
                            <Button className="btn btn-primary mb-2 b1" size="sm" onClick={handleCloseSave} 
                                style={{backgroundColor: '#0D6EFD', color: '#FFFFFF'}}
                            >
                                Guardar Acta
                            </Button>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdSubtitles />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Titulo del acta" size="sm"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="titulo_1" name="titulo_1"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdSubtitles />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Observaciones del acta" aria-label="Observaciones del acta"
                                    aria-describedby="basic-addon1" size="sm"
                                    type="text" onChange={handleInputChange}
                                    id="observaciones_acta" name="observaciones_acta"
                                />
                            </InputGroup>
                            {/*<InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    type="file" onChange={()=>convertToBase64()}
                                    id="pdfup" name="pdfup" size="sm"
                                />
                            </InputGroup>*/}
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <Form.Label htmlFor="basic-url">Responsables</Form.Label>
                                <Select isMulti options={selectusuarios} onChange={handlechangeRes} className="w-100" ></Select>
                            </InputGroup>
                            <ReactQuill
                                className="bg-light text-dark area_acta"
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                placeholder="Escribe aquí..."
                                value={body}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Reuniones;