import React, {useState, Fragment, useEffect} from "react";
import { 
  Card, Button, Container, Row, Col,
  FormControl,InputGroup,Form
} from 'react-bootstrap';
import {
    DEDALO_EDIT_SUBTASK,DEDALO_ALL_USER_COMBO
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import Navbar from '../../navbar';
import { Routes } from '../../../utils/routes';
import Select  from 'react-select';
import {DateTimePicker} from '@material-ui/pickers';
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as GrIcons from "react-icons/gr";
import * as BiIcons from "react-icons/bi";

function EditarSubTarea() {
    const {userToken} = useUserSession();
    const {idtarea} = useParams();
    const datahomi_id = JSON.parse(localStorage.getItem('projectHomi'));
    const id = parseInt(datahomi_id.idProject);
    const datahomi = JSON.parse(localStorage.getItem('projectHomiSubtask'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id_task = parseInt(datahomi.idtask);
    const id_subtask = parseInt(datahomi.idsubtask);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [boton, setBoton] = useState(0);
    const [prioridad, setPrioridad] = useState('');
    const [tareaestado, setTareaestado] = useState('');
    const [observadores, setObservadores] = useState([]);
    const [responsables, setResponsables] = useState([]);
    const [participantes, setParticipantes] = useState([]);
    const [fechaseleccionada, setFechaSeleccionada] = useState(new Date());

    const [dats, setDat] = useState({
        titulo: '',
        obs: '',
        descripcion: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const handleChangePrioridad = (event) => {
        setPrioridad(event.target.value);
    };

    const handleChangeEstadoTarea = (event) => {
        setTareaestado(event.target.value);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetUserCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                  //console.log(datanotice.data);
                  setCategor({
                    ...categor,
                    ['datos']: datanotice,
                  });
              } else {
                 alert(message_responses[response.data.message]);
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    }

    useEffect(() => {
        peticionGetUserCombo();
    }, []);

    const selectusuarios = [];
    categor.datos.map(i => {
        selectusuarios.push({
            value: i.id,
            label: i.name,
        });
    });

    const handlechangeObs = (e) =>{
        setObservadores(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const handlechangeRes = (e) =>{
        setResponsables(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const handlechangePar = (e) =>{
        setParticipantes(Array.isArray(e)?e.map(x=>x.value):[]);
    };
    
    let dia = fechaseleccionada.getDate();
    let mes = fechaseleccionada.getMonth();
    let año = fechaseleccionada.getFullYear();
    let hora = fechaseleccionada.getHours();
    let minutos = fechaseleccionada.getMinutes();
    let segundos = fechaseleccionada.getSeconds();
    let fecha = año+'-'+mes+'-'+dia;

    let fechafinal = fecha+' '+hora+':'+minutos+':'+segundos;

    const handleCloseSave = (e) => {
    
        let titulo = dats.titulo;
        let obs = dats.obs;
        let des = dats.descripcion;
        let fechalimite = fechafinal;
        let subtarea_prioridad = prioridad;
        let subtarea_estado = tareaestado;
        let observador_subtarea = observadores;
        let responsable_subtarea = responsables;
        let participante_subtarea = participantes;
        
        const formattedData = {
            constructions_id: id,
            creator_id: userId,
            constructions_task_id: id_task,
            deadline: fechalimite,
            title: titulo,
            description: des,
            observations: obs,
            priority: subtarea_prioridad,
            status: subtarea_estado,
            responsable: responsable_subtarea,
            observers: observador_subtarea,
            participants: participante_subtarea,
        };
        
        //console.log(formattedData);
        if(titulo === ''){
            Swal.fire("No se pudo crear la subtarea, valide que los campos no esten en blanco", "", "warning");
        }else{
            //axios.put(DEDALO_EDIT_SUBTASK(id_subtask), formattedData, attachToken(user))
            axios({
                method: 'PUT',
                url: DEDALO_EDIT_SUBTASK+'&id='+id_subtask,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito la Subtarea exitósamente", "", "success");
                    document.getElementById('titulo').value = '';
                    document.getElementById('obs').value = '';
                    document.getElementById('descripcion').value = '';
                    setBoton(1);
                } else {
                    alert("ERROR: Server Error");
                }
            })
            //.catch(error => {
            //    manageError(error);
            //})
        }
    };

    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div >
            {/*<Navbar modulo="Editar Subtarea" usuario={user.name}/>*/}
            <br/>
            <div className="w-100">
                <div>
                    <Button className="btn btn-success me-2 rounded-circle ml-4" title="Regresar" 
                        onClick={()=>{window.history.back()}} style={{backgroundColor: "#02B371"}}
                    >
                        <MdIcons.MdOutlineKeyboardBackspace/>
                    </Button>
                </div>
                <Container className="w-50">
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdIcons.MdSubtitles />
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Titulo tarea" aria-label="Titulo tarea"
                                                aria-describedby="basic-addon1"
                                                type="email" onChange={handleInputChange}
                                                id="titulo" name="titulo"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdIcons.MdOutlineSpeakerNotes/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Observaciones" aria-label="Observaciones"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="obs" name="obs"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <GrIcons.GrStatusCritical/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangePrioridad} id="select" name="select"
                                            >
                                                <option value="">Prioridad de la tarea</option>
                                                <option value='high'>Alto</option>
                                                <option value='medium'>Medio</option>
                                                <option value='low'>Bajo</option>
                                            </Form.Select>
                                        </InputGroup>    
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BiIcons.BiStats/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeEstadoTarea} id="select" name="select"
                                            >
                                                <option value="">Estado de la tarea</option>
                                                <option value='pending'>pendiente</option>
                                                <option value='in process'>en progreso</option>
                                                <option value='under review'>bajo revisión</option>
                                                <option value='completed'>Completado</option>
                                                <option value='closed'>Cerrada</option>
                                                <option value='canceled'>Cancelada</option>
                                                <option value='reopened'>Reabierta</option>
                                            </Form.Select>
                                        </InputGroup> 
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1" className="me-2">
                                                <BiIcons.BiCalendarAlt/>
                                            </InputGroup.Text>
                                            <DateTimePicker value={fechaseleccionada} onChange={setFechaSeleccionada}/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <Form.Label htmlFor="basic-url">Observadores</Form.Label>
                                            <Select isMulti options={selectusuarios} className="w-100" onChange={handlechangeObs}></Select>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <Form.Label htmlFor="basic-url">Responsables</Form.Label>
                                            <Select isMulti options={selectusuarios} onChange={handlechangeRes} className="w-100" ></Select>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <Form.Label htmlFor="basic-url">Participantes</Form.Label>
                                            <Select isMulti options={selectusuarios} onChange={handlechangePar} className="w-100" 
                                            >
                                            </Select>
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12} xs={12}>
                                        <InputGroup className="textareatask">
                                            <InputGroup.Text>
                                                <CgIcons.CgNotes/>
                                            </InputGroup.Text>
                                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChange}
                                                placeholder="Descripcion de la tarea" id="descripcion" name="descripcion"/>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <div className="text-end">
                                {boton === 1 ?
                                    
                                    <Button onClick={()=>{window.history.back()}} className="btn btn-success" 
                                        style={{backgroundColor: "#02B371"}}>
                                        Regresar
                                    </Button>
                                    
                                :
                                    <Button onClick={()=>handleCloseSave()} className="btn btn-success" 
                                        style={{backgroundColor: "#02B371"}}>
                                        Editar Subtarea
                                    </Button>
                                }
                            </div>
                        </Card.Footer>
                    </Card>
                </Container>
            </div>
        </div>
    );
}

export default EditarSubTarea;
