import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sidebar3.css';
import {
    Button,
} from 'react-bootstrap';
import { NavLink } from "react-router-dom";
//import Logo from '../../imagenes/dedalo_logo.png';
import Logo from '../../imagenes/Logo.png';
import { useUserSession } from "../../hooks";
import { Routes } from "../../utils/routes";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import * as Bsicons from 'react-icons/bs';
import * as GiIcons from 'react-icons/gi';
import * as RiIcons from 'react-icons/ri';
import * as BiIcons from 'react-icons/bi';
import Img1 from '../../imagenes/logo_zpazium.jpg';

const Sidebar2 = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const login = () => {
        window.location.href = Routes.LOGIN;
    }

    return (
        <div className='conteiner'>
            <div className='sidebar' style={{ width: isOpen ? '170px' : '50px' }}>
                <div className='top_section'>
                    <h1 style={{ display: isOpen ? 'block' : 'none' }} className='logo'>
                        <img src={Logo} class="img-fluid" title="::DEDALO::" alt="::DEDALO::" style={{backgroundColor: 'transparent'}}/>
                    </h1>
                    <div className='bars' style={{ marginLeft: isOpen ? '10px' : '0px', cursor: 'pointer' }}>
                        <Faicons.FaBars onClick={toggle} />
                    </div>
                </div>
                <NavLink to="/home" exact className='link mb-2' activeClassName="active">
                    <div className='icon'><Faicons.FaHome /></div>
                    <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>inicio</div>
                </NavLink>
                {user === undefined ?
                    <div>
                        {() => login()}
                    </div>
                    : user.role_id === 3 || user.role_id === 2 ?
                        <div>
                            <NavLink to="/tabla_usuario" exact className='link mb-2' activeClassName="active">
                                <div className='icon'><Faicons.FaUserFriends /></div>
                                <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>Usuarios</div>
                            </NavLink>
                            <NavLink to="/Crm_Empresas" exact className='link mb-2' activeClassName="active">
                                <div className='icon'><Faicons.FaBuilding /></div>
                                <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>Empresas</div>
                            </NavLink>
                        </div>
                        :
                        ''
                }
                <NavLink to="/Personal" exact className='link mb-2' activeClassName="active">
                    <div className='icon'><Bsicons.BsFillPersonBadgeFill /></div>
                    <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>Personal</div>
                </NavLink>
                <NavLink to="/Productos" exact className='link mb-2' activeClassName="active">
                    <div className='icon'><Faicons.FaAsymmetrik /></div>
                    <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>Productos</div>
                </NavLink>
                <NavLink to="/Proveedores" exact className='link mb-2' activeClassName="active">
                    <div className='icon'><GiIcons.GiSellCard /></div>
                    <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>Proveedores</div>
                </NavLink>
                <NavLink to="/Compartir_Archivos" exact className='link mb-2' activeClassName="active">
                    <div className='icon'><RiIcons.RiFolderSharedFill /></div>
                    <div className='link_text' style={{ display: isOpen ? 'block' : 'none' }}>Compartir archivos</div>
                </NavLink>
                {user === undefined ?
                    <div>
                        {() => login()}
                    </div>
                    : user.role_id === 3 || user.role_id === 2 ?
                        <NavLink to="/Formularios_Zpazium" exact className='link mb-2' activeClassName="active">
                            <img className='img-fluid rounded' src={Img1} style={{ width: '9em' }} />
                        </NavLink>
                        :
                        ''
                }

            </div>
        </div>
    );
}

export default Sidebar2;