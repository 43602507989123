import React, {useState, Fragment, useEffect} from "react";
import { 
    Row, Modal, Button, InputGroup, Form, 
    Col, FormControl, Table
} from 'react-bootstrap'
import Select  from 'react-select';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as GrIcons from "react-icons/gr";
import * as BiIcons from "react-icons/bi";
import * as RiIcons from "react-icons/ri";

import {
    DEDALO_CREAR_CUADRILLAS,DEDALO_PERSONAL_CUADRILLAS,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";

function ProyectoHomi(actualizar){
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [personalselect, setPersonalSelect] = useState([]);
    const {userToken} = useUserSession(); 
    const {user} = useUserSession();  
    
    const [personalcuadrilla, setPersonalCuadrilla] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        titulo: '',
    });

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChangePersonalSelect = (e) => {
        setPersonalSelect(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const peticionGetPersonal=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_PERSONAL_CUADRILLAS)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_PERSONAL_CUADRILLAS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setPersonalCuadrilla({
                    ...personalcuadrilla,
                    ['datos']: datanotice,
                });
            } else {
                setPersonalCuadrilla({
                    ...personalcuadrilla,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetPersonal();
    }, []);

    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let titulo = dats.titulo;
        let personal_select = personalselect;
        
        const formattedData = {
            name: titulo,
            constructions_id: id,
            personal: personal_select,
        };
        //console.log(formattedData);
        if(titulo === '' || personal_select.length === 0){
            Swal.fire({
                icon: 'info',
                title: 'No se puede crear la cuadrilla',
                text: 'Porfavor verifique que los campos no este vacio',
            });
        }else{
            axios({
                method: 'POST',
                url: DEDALO_CREAR_CUADRILLAS,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó la cuadrilla exitósamente", "", "success");
                    document.getElementById('titulo').value = '';
                    actualizar.actualizar();
                    handleClose();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            //.catch(error => {
            //    manageError(error);
            //})
        }
    };

    const selectusuarios = [];
    if(personalcuadrilla.datos.length > 0){
        personalcuadrilla.datos.map(i => {
            selectusuarios.push({
                value: i.id,
                label: i.name,
            });
        });
    }
    
    return (
        <div>
            <Button variant="default" size="xs" className="md-btn-flat" title="Agregar Cuadrilla" onClick={handleShow}>
                <span class="lnr lnr-plus-circle d-block text-primary"></span>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Cuadrilla Nueva
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineGroups className="text-primary"/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre de la cuadrilla" aria-label="Nombre de la cuadrilla"
                                    aria-describedby="basic-addon1" onChange={handleInputChange} name="titulo"
                                    id="titulo"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3">
                                <RiIcons.RiGroupLine className="text-primary me-2"/>
                                <Form.Label htmlFor="basic-url">Personal Homi</Form.Label>
                                <Select isMulti options={selectusuarios} 
                                    className="w-100" onChange={handleChangePersonalSelect}
                                >    
                                </Select>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSave}>
                        Crear Cuadrilla
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


export default ProyectoHomi;