import React, {useState, Fragment, useEffect} from "react";
import { 
    Row, Modal, Button, InputGroup, Form, 
    Col, FormControl, Table
} from 'react-bootstrap';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";

import {
    DEDALO_COMBO_PRODUCTO,DEDALO_ACTUALIZAR_INVENTARIO,DEDALO_INVENTARIO_BY_ID
} from "../../../../assets/APIAdresses";
import {message_responses} from "../../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../../../hooks";
import Swal from "sweetalert2";
import {
    attachToken,
    manageError,
} from "../../../../utils/utils";

function InventarioEdiatar(datos){
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const idProject = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();    
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [productoselect, setProductoSelect] = useState('');

    const [dats, setDat] = useState({
        monto1: '',
        monto_minimo1: '',
        nombre_producto1: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const handleChangeEstadoTarea = (event) => {
        setProductoSelect(event.target.value);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetUserCombo=async()=>{
        //let info1 = await axios.get(DEDALO_COMBO_PRODUCTO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_PRODUCTO,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };

    const peticionGetInventoryId=async()=>{
        //let info1 = await axios.get(DEDALO_INVENTARIO_BY_ID(datos.Iditem))
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_INVENTARIO_BY_ID+'&id='+datos.Iditem,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setDat({
                    ...dats,
                    ['monto1']: datanotice.amountInventory,
                    ['monto_minimo1']: datanotice.minimum_amountInventory,
                    ['nombre_producto1']: datanotice.name,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    }

    useEffect(() => {
        peticionGetUserCombo();
        peticionGetInventoryId();
    }, []);

    const selectusuarios = [];
    categor.datos.map(i => {
        selectusuarios.push({
            value: i.id,
            label: i.name,
        });
    });
    
    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let monto = dats.monto1;
        let monto_minimo = dats.monto_minimo1;
        let producto_id = productoselect;
        
        const formattedData = {
            amount: monto,
            minimum_amount: monto_minimo,
            products_id: producto_id,
            constructions_id: idProject,
        };
        //console.log(formattedData);
        //axios.put(DEDALO_ACTUALIZAR_INVENTARIO(datos.Iditem), formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_ACTUALIZAR_INVENTARIO+'&id='+datos.Iditem,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se actualizo el item exitósamente", "", "success");
                datos.actualizar();
                handleClose1();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    return (
        <div>
            <div variant="default" size="xs" className="md-btn-flat" title="Agregar Item" onClick={handleShow1}>
                Editar
            </div>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaSortAmountUpAlt className="me-2 text-primary"/>
                                    Cantidad
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Monto"
                                    aria-describedby="basic-addon1" value={dats.monto1}
                                    type="email" onChange={handleInputChange}
                                    id="monto1" name="monto1"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaSortAmountDown className="me-2 text-primary"/>
                                    Cant. Mínima
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad Minima" aria-label="Monto Minima"
                                    aria-describedby="basic-addon1" value={dats.monto_minimo1}
                                    type="text" onChange={handleInputChange}
                                    id="monto_minimo1" name="monto_minimo1"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaElementor/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" 
                                    onChange={handleChangeEstadoTarea} id="select" name="select"
                                >
                                    <option value="">{dats.nombre_producto1}</option>
                                    {categor.datos.map(i => (
                                        <option value={i.id}>{i.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup> 
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSave}>
                        Editar Inventario
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default InventarioEdiatar;