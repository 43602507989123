import React, { useState, Fragment, useEffect } from "react";
import {
    Button, Row, Col,Container,
} from 'react-bootstrap';
import {
    DEDALO_CONSTRUCCION_BY_ID,
} from "../../assets/APIAdresses";
import { Routes } from '../../utils/routes';
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import { useUserSession } from "../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import VerResumen from './resumen';
import Img1 from './imagenes_iconos/compras3.png';
import Img2 from './imagenes_iconos/orden.png';
import Img3 from './imagenes_iconos/inventario.png';
import Img4 from './imagenes_iconos/estado_cuenta.png';
import Img5 from './imagenes_iconos/proveedor.png';
import Img6 from './imagenes_iconos/cotizacion.png';
import Img7 from './imagenes_iconos/acta.png';
import Img8 from './imagenes_iconos/salida.png';
import Img9 from './imagenes_iconos/movimiento.png';
import Img10 from './imagenes_iconos/requerimiento.png';
import Img11 from './imagenes_iconos/vacaciones.png';
import Img12 from './imagenes_iconos/permiso.png';
import * as MdIcons from "react-icons/md";

const MenuContabilidad = () => {
    const { userToken } = useUserSession();
    const { user } = useUserSession();
    const { IdProjectContabilidad } = useParams();
    const [verResumen, setVerResumen] = useState(false);

    const [dats_edit, setDat_Edit] = useState({
        name_edit: '',
        dir_edit: '',
        product_type_id_edit: '',
        crm_vendors_id_edit: '',
        productTypeName_edit: '',
        crmVendorName_edit: '',
    });

    const peticionGetProductById = async () => {
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_BY_ID + '&id=' + parseInt(IdProjectContabilidad),
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setDat_Edit({
                        ...dats_edit,
                        ['name_edit']: datanotice.data.name,
                        ['dir_edit']: datanotice.data.address,
                        ['product_type_id_edit']: datanotice.data.product_type_id,
                        ['crm_vendors_id_edit']: datanotice.data.crm_vendors_id,
                        ['productTypeName_edit']: datanotice.data.productTypeName,
                        ['crmVendorName_edit']: datanotice.data.crmVendorName,
                    });
                } else {
                    setDat_Edit({
                        ...dats_edit,
                        ['name_edit']: '',
                        ['dir_edit']: '',
                        ['product_type_id_edit']: '',
                        ['crm_vendors_id_edit']: '',
                        ['productTypeName_edit']: '',
                        ['crmVendorName_edit']: '',
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetProductById();
    }, []);

    localStorage.setItem("Project_contabilidad", JSON.stringify(
        {
            Id_Project_Conta: parseInt(IdProjectContabilidad),
            name_construction: dats_edit.name_edit,
            dir_construction: dats_edit.dir_edit,
        }
    ));

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371", color: '#fff' }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm"
                            onClick={() => setVerResumen(!verResumen)} title="Regresar" style={{ backgroundColor: "#02B371", color: '#fff' }}
                        >
                            Generar resumen
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-center mt-4">
                <div>
                    <h2 className="text-center">Sistema gestion de Calidad</h2>
                </div>
            </div>
            <hr />
            <div>
                <Container>
                    <Row>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.REQ}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center' }} title="COMPRAS">
                                        <img src={Img1} width="60%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Requisiciones</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.ORD}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center' }} title="ORDEN DE COMPRA">
                                        <img src={Img2} width="56%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Orden de Compra</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.COT_LICITACIONES}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center' }} title="COMPRAS">
                                        <img src={Img6} width="45%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Cotizaciones</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.ANTICIPOS}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center', marginBottom: "1em" }} title="PROVEEDORES">
                                        <img src={Img4} width="75%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Anticipos</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.MENU_FORMATOS_PROVEEDORES}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center', marginBottom: "1em" }} title="BANCOS">
                                        <img src={Img5} width="80%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Formatos proveedores</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.ACTAS}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center' }} title="ORDEN DE COMPRA">
                                        <img src={Img7} width="46%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">acta de Inicio</b>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.FORMATO_SALIDA}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center', marginBottom: "1em" }} title="FACTURAS">
                                        <img src={Img8} width="45%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Formato de salida</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.INVENTARIO_GENERAL}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center', marginBottom: "1em" }} title="PROVEEDORES">
                                        <img src={Img3} width="45%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Inventario general</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.MOVE_INVENTARIO_GENERAL}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center', marginBottom: "1em" }} title="BANCOS">
                                        <img src={Img9} width="45%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Movimiento Inventario</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.REQ_INVENTARIO_GENERAL}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center', marginBottom: "1em" }} title="BANCOS">
                                        <img src={Img10} width="45%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Requerimiento Inventario</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.FORMATO_VACACIONES_LISTA}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center', marginBottom: "1em" }} title="vacaciones">
                                        <img src={Img11} width="48%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Formato vacaciones</b>
                            </div>
                        </Col>
                        <Col md={2} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.FORMATO_PERMISO_LISTA}>
                                    <a style={{ cursor: 'pointer', textAlign: 'center', marginBottom: "1em" }} title="permiso">
                                        <img src={Img12} width="50%" alt="prueba" />
                                    </a>
                                </Link>
                                <br />
                                <b className="mt-4">Formato permiso</b>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="container my-4">
               <VerResumen estado={verResumen}/>
            </div>
        </div>
    );
};

export default MenuContabilidad;