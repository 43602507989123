import React, { useState, Fragment, useEffect } from "react";
import {
    Card, Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav, Table, Dropdown, DropdownButton,
} from 'react-bootstrap';
import {
    DEDALO_ALL_VACACIONES,DEDALO_DEL_VACACIONES,DEDALO_CHANGE_STATUS_VACACIONES,
} from "../../../assets/APIAdresses";
import { message_responses } from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import { useUserSession } from "../../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../../utils/routes';
import Navbar from '../../navbar';
import Swal from "sweetalert2";
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const ListaDeVacaciones = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [idvacaciones, setIdVacaciones] = useState(0);
    const [estadonuevo, setEstadoNuevo] = useState('');

    const [categor, setCategor] = useState({
        datos: [],
    });

    const peticionGetCategor = async () => {
        const params_data = {
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'date',
            order: 'desc',
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_VACACIONES,
            headers: { Authorization: userToken },
            data: params_data
        })
        .then(response => {
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                console.log(response.data.code);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });

        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (id) => {
        setShow2(true);
        setIdVacaciones(id);
    };

    const handleChangeEstado = (event) => {
        setEstadoNuevo(event.target.value);
    };

    const handleCloseSaveStatus = (e) => {

        const formattedData = {
            status: estadonuevo,
        };
        //console.log(formattedData);

        axios({
            method: 'POST',
            url: DEDALO_CHANGE_STATUS_VACACIONES + '&id=' + idvacaciones,
            headers: { Authorization: userToken },
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se cambio el estado exitósamente", "", "success");
                peticionGetCategor();
                handleClose2();
            } else {
                //alert("ERROR: Server Error");
                console.log('response.data.code');
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    const peticionDelete = (iduser) => {
        let delId = iduser;

        axios({
            method: 'DELETE',
            url: DEDALO_DEL_VACACIONES + '&id=' + delId,
            headers: { Authorization: userToken },
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se elimino el formato exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371", color: '#fff' }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Link to={Routes.FORMATO_VACACIONES}>
                            <Button className="btn btn-success me-2" size="sm"
                                title="Nuevo" style={{ backgroundColor: "#02B371", color: '#fff' }}
                            >
                                Generar vacaciones
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <Card>
                    <Card.Header as='h5'>
                        <div className="d-flex">
                            <div>Formatos de vacaciones generados</div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr className="text-center fw-bolder">
                                    <td>Id</td>
                                    <td>Empresa</td>
                                    <td>Nombre del formato</td>
                                    <td>Fecha del formato</td>
                                    <td>Fecha salida</td>
                                    <td>Fecha ingreso</td>
                                    <td>Estado</td>
                                    <td>Observaciones</td>
                                    <td>Accciones</td>
                                </tr>
                            </thead>
                            <tbody>
                                {categor.datos === undefined ?
                                    ''
                                    :
                                    categor.datos.map((x, ind) => (
                                        <tr key={ind} className="text-center">
                                            <td>{ind+1}</td>
                                            <td>{x.enterprice}</td>
                                            <td>{x.employee_name}</td>
                                            <td>{x.date}</td>
                                            <td>{x.startdate}</td>
                                            <td>{x.endate}</td>
                                            <td>
                                                {x.status === 'pending' ? 'pendiente' : x.status === 'rejected' ? 'no aprobado' : 'aprobado'}
                                            </td>
                                            <td style={{maxWidth: '20rem'}}>
                                                <div>{x.observations}</div>
                                            </td>
                                            <td>
                                                {userId === 3 || userId === 2 || userId === 4 || parseInt(x.users_id) === userId ?
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div class="dropdown">
                                                            <BsIcons.BsThreeDotsVertical class="dropdown-toggle" type="button"
                                                                data-bs-toggle="dropdown" aria-expanded="false" />
                                                            <ul class="dropdown-menu menu_actas">
                                                                <Link to={Routes.FORMATO_VACACIONES_EDIT(x.id)} class="text-decoration-none">
                                                                    <li>
                                                                        <a class="dropdown-item" style={{ cursor: 'pointer' }}>
                                                                            Editar
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                                <li>
                                                                    <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => peticionDelete(x.id)}>
                                                                        Eliminar
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleShow2(x.id)}>
                                                                        cambiar estado
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                :
                                                    ''
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
            {/* MODAL CAMBIAR ESTADO DEL PERMISO */}
            <Modal show={show2} onHide={handleClose2} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar estado del permiso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <Form.Select aria-label="Default select example" onChange={handleChangeEstado}>
                            <option>seleccione una opcion</option>
                            <option value="rejected">Rechazado</option>
                            <option value="approved">Aprobado</option>
                        </Form.Select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose2} style={{background: '#1A5276', color: '#fff'}}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={() => handleCloseSaveStatus()} style={{background: '#1A5276', color: '#fff'}}>
                        Cambiar estado
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}

export default ListaDeVacaciones;