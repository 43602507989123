import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Button, InputGroup, FormControl, Container, Row, Col,
  Form, Table,Card,Modal,Accordion
} from 'react-bootstrap';
import {
    DEDALO_TASK_CONSTRUCTION_REPORT,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import { Routes } from "../../utils/routes";
import Imgfondo from '../../imagenes/dedalo_logo.png'
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import * as Bsicons from 'react-icons/bs';
import * as Aiicons from 'react-icons/ai';
import * as Hiicons from 'react-icons/hi';
import * as Biicons from "react-icons/bi";
import Navbar from '../navbar';
import logo from '../../imagenes/dedalo_logo.png';

const Reporte = () => {
    const {userId} = useUserSession();
    const {userToken} = useUserSession();
    const {user} = useUserSession();
    const datareporte = JSON.parse(localStorage.getItem('DataReport'));
    const [fechaseleccionada, setFechaSeleccionada] = useState(new Date());
    const [fechaseleccionada1, setFechaSeleccionada1] = useState(new Date());

    const [categor, setCategor] = useState({
        datos_task: [],
        datos_task_comments: [],
        datos_task_response_comments: [],
        datos_subtask: [],
    });

    let dia = fechaseleccionada.getDate();
    let mes = fechaseleccionada.getMonth()+1;
    let año = fechaseleccionada.getFullYear();
    let hora = fechaseleccionada.getHours();
    let minutos = fechaseleccionada.getMinutes();
    let segundos = fechaseleccionada.getSeconds();
    let fecha = año+'-'+mes+'-'+dia;

    let fechafinal = fecha+' '+hora+':'+minutos+':'+segundos;

    let dia1 = fechaseleccionada1.getDate();
    let mes1 = fechaseleccionada1.getMonth()+1;
    let año1 = fechaseleccionada1.getFullYear();
    let hora1 = fechaseleccionada1.getHours();
    let minutos1 = fechaseleccionada1.getMinutes();
    let segundos1 = fechaseleccionada1.getSeconds();
    let fecha1 = año1+'-'+mes1+'-'+dia1;

    let fechafinal1 = fecha1+' '+hora1+':'+minutos1+':'+segundos1;

    const peticionGetCategor=async()=>{
        const params_data = {
            creator_id: userId,
            startdate: fecha,
            endate: fecha1,
            //creator_id: 47,
            //startdate: "2022-1-26",
            //endate: "2022-1-31",
        };
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS_BY_ID+'&id='+id)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_TASK_CONSTRUCTION_REPORT,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos_task']: datanotice.data,
                    ['datos_task_comments']: datanotice.data[0].TaskComment,
                    ['datos_subtask']: datanotice.data[0].SubTask,
                });
                handleClose2();
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
            }
        });
      
        return info1;
    }
    
    useEffect(() => {
        //peticionGetCategor();
    }, []);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const fecha_comentario = (datadate) =>{
        let fecha_comentarios = new Date(datadate);
        let dia_comentario = fecha_comentarios.getDate();
        let mes_comentario = fecha_comentarios.getMonth()+1;
        let año_comentario = fecha_comentarios.getFullYear();
        let fecha_res = dia_comentario+'-'+mes_comentario+'-'+año_comentario;

        return fecha_res;
    };

    if(window.location.pathname === Routes.REPORTE){
        let yt = 0;
    }else{
        categor.datos_task = [];
        categor.datos_subtask = [];
    }

    return (
        <div>
            <div className="w-100">
                <br/>
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start btnreport">
                    <Button className="btn btn-success rounded-circle regresar" size="sm" onClick={()=>{window.history.back()}}
                        title="Regresar"
                    >
                        <Mdicons.MdArrowBackIosNew/>
                    </Button>
                    <Button className="botonpersonal me-2 nuevo" size="sm" onClick={handleShow2}>
                        Seleccionar Fecha de Reporte
                    </Button>
                    {categor.datos_task.length === 0 && categor.datos_subtask.length === 0 ?
                        ''
                    :
                        <Link to={Routes.REPORTE_PDF} style={{color: "white", textDecoration: "none"}}
                            onClick={()=>{localStorage.setItem("Reporte", JSON.stringify({creator_id: userId,startdate: fecha,endate: fecha1}));}}
                        >
                            <Button className="botonpersonal me-2 nuevo" size="sm">
                                Ver reporte en PDF
                            </Button>
                        </Link>
                    }
                </div>
                <div className="container-fluid flex-grow-1 container-p-y">
                    <div className="mb-3 text-center">
                        <h4 className="font-weight-bold mb-0 titulo1">
                            Informe General
                        </h4>
                    </div>
                    <hr/>
                    <div>
                        <Accordion>
                            <Row>
                                <Col md={6} xs={12}>
                                    <Accordion.Item eventKey="0"> {/* INFORMACION DE LAS TAREAS */}
                                        <Accordion.Header>Tareas</Accordion.Header>
                                        <Accordion.Body>
                                            <Accordion>
                                                {categor.datos_task.map((itemTask, indexTask) => (
                                                    <Accordion.Item eventKey={indexTask + 1} key={indexTask}> 
                                                        <Accordion.Header>Tarea {itemTask.title}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <Col md={6} xs={12}>
                                                                    Proyecto: <b>{itemTask.nameConstruction}</b> <br/>
                                                                    Creado por: <b>{itemTask.nameCreator}</b> <br/>
                                                                    Fecha de inicio: <b>{itemTask.startdate}</b> <br/>
                                                                    Fecha de fin: <b>{itemTask.deadline}</b> <br/>
                                                                    Presupuesto: <b>{
                                                                        itemTask.budget === '' ? 
                                                                            formatterPeso.format(0)
                                                                        : 
                                                                            formatterPeso.format(itemTask.budget)
                                                                        }</b> <br/>
                                                                    Presupuesto ejecutado: <b>{
                                                                        itemTask.executed_budget === '' ?
                                                                            formatterPeso.format(0)
                                                                        :
                                                                            formatterPeso.format(itemTask.executed_budget)
                                                                    }</b> <br/>
                                                                    Descripcion: <b>{itemTask.description}</b> <br/>
                                                                </Col>
                                                                <Col md={6} xs={12}>
                                                                    Medio: <b>{itemTask.means}</b> <br/>
                                                                    Personal: <b>{itemTask.personal}</b> <br/>
                                                                    Implementos: <b>{itemTask.supplies}</b> <br/>
                                                                    Observaciones: <b>{itemTask.observations}</b> <br/>
                                                                    Prioridad: <b>{
                                                                        itemTask.priority === 'high' ?
                                                                            'Alta'
                                                                        :itemTask.priority === 'medium' ?
                                                                            'Medio'
                                                                        :
                                                                            'Bajo'
                                                                    }</b> <br/>
                                                                    Estado: <b>{
                                                                        itemTask.statusTask === 'pending' ?
                                                                            'Pendiente'
                                                                        :itemTask.statusTask === 'in process' ?
                                                                            'En proceso'
                                                                        :itemTask.statusTask === 'under review' ?
                                                                            'En revisión'
                                                                        :itemTask.statusTask === 'completed' ?
                                                                            'Completado'
                                                                        :itemTask.statusTask === 'closed' ?
                                                                            'Cerrado'
                                                                        :itemTask.statusTask === 'canceled' ?
                                                                            'Cancelado'
                                                                        :
                                                                            'Reabierto'
                                                                    }</b> <br/>
                                                                </Col>
                                                            </Row>
                                                            <hr/>
                                                            <Row>
                                                                <Col md={12} xs={12}>
                                                                    <h4 className="font-weight-bold mb-0 titulo1 text-center">
                                                                        Comentrarios
                                                                    </h4>
                                                                    <div className="seccion_comentarios_task mt-2">
                                                                        {categor.datos_task_comments.map((r,rindex) => (
                                                                            <div class="d-flex justify-content-between mb-2">
                                                                                <div class="d-flex justify-content-between mb-2" key={rindex}>
                                                                                    <div>
                                                                                        <img src={r.image === '' ? Imgfondo : r.image} className="me-2" 
                                                                                            style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                                                    </div>
                                                                                    <div style={{fontSize: "1em"}}>
                                                                                        <b style={{color: "#d35400"}}>
                                                                                            {r.creatorName}
                                                                                        </b> {fecha_comentario(r.date)}
                                                                                        <br/>
                                                                                        {r.comment}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Col>
                                <Col md={6} xs={12}>
                                    <Accordion.Item eventKey="1"> {/* INFORMACION DE LAS SUBTAREAS */}
                                        <Accordion.Header>Sub Tareas</Accordion.Header>
                                        <Accordion.Body>
                                            <Accordion>
                                                {categor.datos_subtask.length === 0 ? 
                                                    <div className="text-center">
                                                        <h4>No hay subtareas registradas</h4>
                                                    </div>
                                                :
                                                    categor.datos_subtask.map((itemSubTask, indexSubTask) => (
                                                        <Accordion.Item eventKey={indexSubTask+1} key={indexSubTask}> 
                                                            <Accordion.Header>Sub Tarea {itemSubTask.title}</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Row>
                                                                    <Col md={6} xs={12}>
                                                                        Proyecto: <b>{itemSubTask.nameConstruction}</b> <br/>
                                                                        Creado por: <b>{itemSubTask.nameCreator}</b> <br/>
                                                                        Fecha de inicio: <b>{itemSubTask.startdate}</b> <br/>
                                                                        Fecha de fin: <b>{itemSubTask.deadline}</b> <br/>
                                                                        Presupuesto: <b>{
                                                                            itemSubTask.budget === '' ? 
                                                                                formatterPeso.format(0)
                                                                            : 
                                                                                formatterPeso.format(itemSubTask.budget)
                                                                            }</b> <br/>
                                                                        Presupuesto ejecutado: <b>{
                                                                            itemSubTask.executed_budget === '' ?
                                                                                formatterPeso.format(0)
                                                                            :
                                                                                formatterPeso.format(itemSubTask.executed_budget)
                                                                        }</b> <br/>
                                                                        Descripcion: <b>{itemSubTask.description}</b> <br/>
                                                                    </Col>
                                                                    <Col md={6} xs={12}>
                                                                        Observaciones: <b>{itemSubTask.observations}</b> <br/>
                                                                        Prioridad: <b>{
                                                                            itemSubTask.priority === 'high' ?
                                                                                'Alta'
                                                                            :itemSubTask.priority === 'medium' ?
                                                                                'Medio'
                                                                            :
                                                                                'Bajo'
                                                                        }</b> <br/>
                                                                        Estado: <b>{
                                                                            itemSubTask.statusSubTask === 'pending' ?
                                                                                'Pendiente'
                                                                            :itemSubTask.statusSubTask === 'in process' ?
                                                                                'En proceso'
                                                                            :itemSubTask.statusSubTask === 'under review' ?
                                                                                'En revisión'
                                                                            :itemSubTask.statusSubTask === 'completed' ?
                                                                                'Completado'
                                                                            :itemSubTask.statusSubTask === 'closed' ?
                                                                                'Cerrado'
                                                                            :itemSubTask.statusSubTask === 'canceled' ?
                                                                                'Cancelado'
                                                                            :
                                                                                'Reabierto'
                                                                        }</b> <br/>
                                                                    </Col>
                                                                </Row>
                                                                <hr/>
                                                                <Row>
                                                                    <Col md={12} xs={12}>
                                                                        <h4 className="font-weight-bold mb-0 titulo1 text-center">
                                                                            Comentrarios
                                                                        </h4>
                                                                        <div className="seccion_comentarios_task mt-2">
                                                                            {itemSubTask.SubTaskComment.map((r,rindex) => (
                                                                                <div class="d-flex justify-content-between mb-2">
                                                                                    <div class="d-flex justify-content-between mb-2" key={rindex}>
                                                                                        <div>
                                                                                            <img src={r.image === '' ? Imgfondo : r.image} className="me-2" 
                                                                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                                                        </div>
                                                                                        <div style={{fontSize: "0.8em"}}>
                                                                                            <b style={{color: "#d35400"}}>
                                                                                                {r.creatorName}
                                                                                            </b> {fecha_comentario(r.date)}
                                                                                            <br/>
                                                                                            {r.comment}
                                                                                            {r.SubTaskResponse.map((rr, rrindex) => (
                                                                                                <div class="d-flex justify-content-between mt-2">
                                                                                                    <div class="d-flex justify-content-between mt-2" key={rrindex}>
                                                                                                        <div>
                                                                                                            <img src={Imgfondo} className="me-2" 
                                                                                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                                                                        </div>
                                                                                                        <div style={{fontSize: "1em"}}>
                                                                                                            <b style={{color: "#d35400"}}>
                                                                                                                {rr.creatorName}
                                                                                                            </b> {fecha_comentario(r.date)}
                                                                                                            <br/>
                                                                                                            <p >
                                                                                                                {rr.response}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))
                                                }
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Col>
                            </Row>
                        </Accordion>
                        
                    </div>
                </div>
            </div>
            {/* MODAL FECHAS REPORTE */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered className="iconosmodal">
                    <Modal.Header closeButton>
                        <Modal.Title>Generar Reporte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <Form.Label htmlFor="basic-url">Fecha Inicial</Form.Label>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1" className="me-2">
                                        <Biicons.BiCalendarAlt/>
                                    </InputGroup.Text>
                                    <DateTimePicker value={fechaseleccionada1} onChange={setFechaSeleccionada1}/>
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <Form.Label htmlFor="basic-url">Fecha Final</Form.Label>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1" className="me-2">
                                        <Biicons.BiCalendarAlt/>
                                    </InputGroup.Text>
                                    <DateTimePicker value={fechaseleccionada} onChange={setFechaSeleccionada}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="boton1" onClick={handleClose2}>
                            Cerrar
                        </Button>
                        <Button className="boton2" onClick={peticionGetCategor}>
                            Cargar Reporte
                        </Button>
                    </Modal.Footer>
            </Modal>
        </div>
    );   
}

export default Reporte;