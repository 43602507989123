import React, {useState, Fragment, useEffect} from "react";
import { 
  Card, Button, Container, Row, Col,Modal,
  Table,NavDropdown,Dropdown,InputGroup,Form
} from 'react-bootstrap';
import {
    DEDALO_GET_ALL_USERS, DEDALO_ELIMINAR_USUARIO,DEDALO_USERS_ACTIVE,
    DEDALO_USERS_INACTIVE,DEDALO_USERS_BLOCK,DEDALO_DELETE_USER_ENTERPRICE,
    DEDALO_UNDELETE_USER_ENTERPRICE,DEDALO_ALL_USER_DISCHARGED,
    DEDALO_CRM_ENTERPRICES_COMBO,DEDALO_USERS_ADD_ENTERPRICE
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import Navbar from '../navbar';
import { Routes } from '../../utils/routes';
import Cookies from "universal-cookie";
import { 
    MdLocationCity, MdAddLocationAlt
} from "react-icons/md";

function Supportuser() {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();    
    const {user} = useUserSession();
    const [empresaselect, setEmpresaSelect] = useState('');
    const [userselect, setUserSelect] = useState('');
    
    const [categor, setCategor] = useState({
        datos: [],
    });

    const [comboempresas, setComboEmpresas] = useState({
        datos: [],
    });

    const [allpersonaldiscarged, setAllPersonalDischarged] = useState({
        datos: [],
    });
    
    const peticionGetCategor=async(e)=>{
        //e.preventDefault();
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'desc',
        };
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS+"?page=1&rows=1000&order=id&inorder=desc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_GET_ALL_USERS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetComboEmpresas=async(e)=>{
        
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS+"?page=1&rows=1000&order=id&inorder=desc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CRM_ENTERPRICES_COMBO,
            headers: {Authorization: userToken},
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setComboEmpresas({
                    ...comboempresas,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetAllUserDischarged=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PERSONAL_DEDALO+'?page=1&rows=100&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_DISCHARGED,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.data);
                setAllPersonalDischarged({
                    ...allpersonaldiscarged,
                    ['datos']: datanotice.records,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };
    
    const peticionDelete = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_ELIMINAR_USUARIO+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el usuario exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const handleCloseSaveActive = (idactive) => {
        //axios.put(DEDALO_USERS_ACTIVE(idactive))
        axios({
            method: 'PUT',
            url: DEDALO_USERS_ACTIVE+'&id='+idactive,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se a activado el usuario exitósamente", "", "success");
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const handleCloseSaveInactive = (idInactive) => {
        //axios.put(DEDALO_USERS_INACTIVE(idInactive))
        if(idInactive === undefined){
            let ou = 0;
        }else{
            axios({
                method: 'PUT',
                url: DEDALO_USERS_INACTIVE+'&id='+idInactive,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    Swal.fire("Se a Inactivado el usuario exitósamente", "", "success");
                    peticionGetCategor();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const handleCloseSaveBlock = (idblock) => {
        //axios.put(DEDALO_USERS_BLOCK(idblock))
        axios({
            method: 'PUT',
            url: DEDALO_USERS_BLOCK+'&id='+idblock,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se a bloqueado el usuario exitósamente", "", "success");
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };
    
    useEffect(() => {
        peticionGetCategor();
        peticionGetAllUserDischarged();
        peticionGetComboEmpresas();
    }, []);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setUserSelect(e);
        setShow2(true);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (e) => {
        setShow4(true);
    };

    const handleCloseSaveActiveDischarged = (idactive) => {
        const formattedData = {
            id: idactive,
            enterprice_id: data_emp.IdEmp,
        };
        //axios.put(DEDALO_USERS_ACTIVE(idactive))
        axios({
            method: 'POST',
            url: DEDALO_UNDELETE_USER_ENTERPRICE,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se a activado el usuario exitósamente", "", "success");
                peticionGetAllUserDischarged();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const handleCloseSaveInactiveDischarged = (idInactive) => {
        const formattedData = {
            id: idInactive,
            enterprice_id: data_emp.IdEmp, 
        };
        //axios.put(DEDALO_USERS_INACTIVE(idInactive))
        if(idInactive === undefined){
            let ou = 0;
        }else{
            axios({
                method: 'POST',
                url: DEDALO_DELETE_USER_ENTERPRICE,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response=>{
                if (response.data.code === '200') {
                    Swal.fire("Se a dado de baja el usuario exitósamente", "", "success");
                    peticionGetAllUserDischarged();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const handleCloseSaveAddEnterprice = () => {
        const formattedData = {
            id: userselect,
            enterprice_id: parseInt(empresaselect), 
        };
        //axios.put(DEDALO_USERS_INACTIVE(idInactive))
        if('idInactive' === undefined){
            let ou = 0;
        }else{
            axios({
                method: 'POST',
                url: DEDALO_USERS_ADD_ENTERPRICE,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response=>{
                if (response.data.code === '200') {
                    Swal.fire("Se agrego empresa el usuario exitósamente", "", "success");
                    handleClose2();
                } else {
                    Swal.fire("Este usuario ya tiene asignada esta empresa", "", "info");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const handleChangeCiudad = (event) => {
        setEmpresaSelect(event.target.value);
    };

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = categor.datos === undefined ? [] : categor.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = categor.datos === undefined ? [] : categor.datos.length/postPerPage; // numero total de paginas
    let pageNumbers = []; //se guardan los numeros de paginas

    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () =>{
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () =>{
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };
    
    const paginador = () =>{
        return(
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{cursor: 'pointer'}}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };

    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div >
            {/*<Navbar modulo="Tabla usuarios" usuario={user.name}/>*/}
            <br/>
            <div className="w-100">
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                    <Dropdown >
                        <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm" 
                            style={{backgroundColor: "#02B371"}} className="botonobras"
                        >
                            Opciones de usuarios
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="opciones_usuarios">
                            <Dropdown.Item className="opciones_usuarios1">
                                <Link to={Routes.USUARIO_NUEVO_SISTEMAS} style={{textDecoration: "none"}}>
                                    Usuario Nuevo
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleShow4}>
                                Ver Usuarios de baja
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Container>
                    <br/>
                    <Table responsive striped bordered size="sm" style={{fontSize: "0.9rem"}} className="tableuser" id="dtBasicExample">
                        <thead>
                            <tr>
                                <th colspan="10" className="bg-success">
                                    Usuarios de la empresa {data_emp.NomEmp}
                                </th>
                            </tr>
                            <tr>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Telefono 1</th>
                                <th>Telefono 2</th>
                                <th>Dirección</th>
                                <th>Estado</th>
                                <th>Rol</th>
                                <th>Genero</th>
                                <th>Departamento</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody style={{textAlign: 'center'}}>
                            {currentPost.map(item => (
                                item.status === 'blocked' ?
                                    <tr key={item} class="bg-warning text-dark">
                                        <td>
                                            <Link to={Routes.EDITAR_USUARIO(item.id)}>
                                                <a href="#" style={{color: "black"}}>
                                                    {item.name}
                                                </a>
                                            </Link>
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{item.phone1}</td>
                                        <td>{item.phone2}</td>
                                        <td>{item.address}</td>
                                        <td>
                                            {item.status === 'active' ?
                                                'activo'
                                            :item.status === 'inactive' ?
                                                'inactivo'
                                            :
                                                'bloqueado'
                                            }
                                        </td>
                                        <td>
                                            {item.role_id === 1 ?
                                                'Usuario'
                                            :item.role_id === 2 ?
                                                'Administrador'
                                            :item.role_id === 3 ?
                                                'Soporte'
                                            :item.role_id === 5 ?
                                                'Planos'
                                            :
                                                'Almacen'
                                            }
                                        </td>
                                        <td>
                                            {item.sex === 'female'? 'Mujer' : 'Hombre'}
                                        </td>
                                        <td>
                                            {item.constructionname}
                                        </td>
                                        <td>
                                            <NavDropdown id="collasible-nav-dropdown" className="acciones_usuario">
                                                <NavDropdown.Item className="editar_usuario">
                                                    <Link to={Routes.EDITAR_USUARIO(item.id)}>
                                                        Editar
                                                    </Link>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item href="#" onClick={()=>peticionDelete(item.id)}>
                                                    Eliminar
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveActive(item.id)}>
                                                    Activar
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveBlock(item.id)}>
                                                    Bloqueado
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveInactive(item.id)}>
                                                    Inactivo
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveInactiveDischarged(item.id)}>
                                                    Dar de baja
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleShow2(item.id)}>
                                                    Agregar usuario a empresa
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </td>
                                    </tr>
                                :item.status === 'inactive' ?
                                    <tr key={item} className="inactivotable" >
                                        <td>
                                            <Link to={Routes.EDITAR_USUARIO(item.id)}>
                                                <a href="#">
                                                    {item.name}
                                                </a>
                                            </Link>
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{item.phone1}</td>
                                        <td>{item.phone2}</td>
                                        <td>{item.address}</td>
                                        <td>
                                            {item.status === 'active' ?
                                                'activo'
                                            :item.status === 'inactive' ?
                                                'inactivo'
                                            :
                                                'bloqueado'
                                            }
                                        </td>
                                        <td>
                                            {item.role_id === 1 ?
                                                'Usuario'
                                            :item.role_id === 2 ?
                                                'Administrador'
                                            :item.role_id === 3 ?
                                                'Soporte'
                                            :item.role_id === 5 ?
                                                'Planos'
                                            :
                                                'Almacen'
                                            }
                                        </td>
                                        <td>
                                            {item.sex === 'female'? 'Mujer' : 'Hombre'}
                                        </td>
                                        <td>
                                            {item.constructionname}
                                        </td>
                                        <td>
                                            <NavDropdown id="collasible-nav-dropdown" className="acciones_usuario">
                                                <NavDropdown.Item className="editar_usuario">
                                                    <Link to={Routes.EDITAR_USUARIO(item.id)}>
                                                        Editar
                                                    </Link>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item href="#" onClick={()=>peticionDelete(item.id)}>
                                                    Eliminar
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveActive(item.id)}>
                                                    Activar
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveBlock(item.id)}>
                                                    Bloqueado
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveInactive(item.id)}>
                                                    Inactivo
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveInactiveDischarged(item.id)}>
                                                    Dar de baja
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleShow2(item.id)}>
                                                    Agregar usuario a empresa
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </td>
                                    </tr>
                                :
                                    <tr key={item}>
                                        <td>
                                            <Link to={Routes.EDITAR_USUARIO(item.id)}>
                                                <a href="#">
                                                    {item.name}
                                                </a>
                                            </Link>
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{item.phone1}</td>
                                        <td>{item.phone2}</td>
                                        <td>{item.address}</td>
                                        <td>
                                            {item.status === 'active' ?
                                                'activo'
                                            :item.status === 'inactive' ?
                                                'inactivo'
                                            :
                                                'bloqueado'
                                            }
                                        </td>
                                        <td>
                                            {item.role_id === 1 ?
                                                'Usuario'
                                            :item.role_id === 2 ?
                                                'Administrador'
                                            :item.role_id === 3 ?
                                                'Soporte'
                                            :item.role_id === 5 ?
                                                'Planos'
                                            :
                                                'Almacen'
                                            }
                                        </td>
                                        <td>
                                            {item.sex === 'female'? 'Mujer' : 'Hombre'}
                                        </td>
                                        <td>
                                            {item.nameDepartment}
                                        </td>
                                        <td>
                                            <NavDropdown id="collasible-nav-dropdown" className="acciones_usuario">
                                                <NavDropdown.Item className="editar_usuario">
                                                    <Link to={Routes.EDITAR_USUARIO(item.id)}>
                                                        Editar
                                                    </Link>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item href="#" onClick={()=>peticionDelete(item.id)}>
                                                    Eliminar
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveActive(item.id)}>
                                                    Activar
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveBlock(item.id)}>
                                                    Bloqueado
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveInactive(item.id)}>
                                                    Inactivo
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleCloseSaveInactiveDischarged(item.id)}>
                                                    Dar de baja
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>handleShow2(item.id)} className="fs-6">
                                                    Agregar usuario a empresa
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </td>
                                    </tr>
                            ))}
                        </tbody>
                    </Table>
                    {paginador()}
                </Container>
            </div>
            {/* MODAL PERSONAL DADO DE BAJA */}
            <Modal show={show4} onHide={handleClose4} size="xl" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Personal Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="cardheaderimg2" style={{maxHeight: '30em', overflowY: 'auto'}}>
                    <Table responsive striped bordered size="sm" style={{fontSize: "0.9rem"}} className="tableuser" id="dtBasicExample">
                        <thead>
                            <tr>
                                <th colspan="10" className="bg-success">
                                    Usuarios de la empresa {data_emp.NomEmp}
                                </th>
                            </tr>
                            <tr>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Telefono 1</th>
                                <th>Telefono 2</th>
                                <th>Dirección</th>
                                <th>Estado</th>
                                <th>Rol</th>
                                <th>Genero</th>
                                <th>Proyecto</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody style={{textAlign: 'center'}} className="acciones_usuario">
                            {allpersonaldiscarged.datos === undefined ?
                                ''
                            :
                                allpersonaldiscarged.datos.map(item => (
                                    <tr key={item} >
                                        <td>
                                            <a href={Routes.EDITAR_USUARIO(item.id)}>
                                                {item.name}
                                            </a>
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{item.phone1}</td>
                                        <td>{item.phone2}</td>
                                        <td>{item.address}</td>
                                        <td>
                                            {item.status === 'active' ?
                                                'activo'
                                            :item.status === 'inactive' ?
                                                'inactivo'
                                            :
                                                'bloqueado'
                                            }
                                        </td>
                                        <td>
                                            {item.role_id === 1 ?
                                                'Usuario'
                                            :item.role_id === 2 ?
                                                'Administrador'
                                            :item.role_id === 3 ?
                                                'Soporte'
                                            :item.role_id === 5 ?
                                                'Planos'
                                            :
                                                'Almacen'
                                            }
                                        </td>
                                        <td>
                                            {item.sex === 'female'? 'Mujer' : 'Hombre'}
                                        </td>
                                        <td>
                                            {item.constructionname}
                                        </td>
                                        <td>
                                            <NavDropdown id="collasible-nav-dropdown">
                                                <NavDropdown.Item onClick={()=>handleCloseSaveActiveDischarged(item.id)}>Reactivar</NavDropdown.Item>
                                            </NavDropdown>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose4}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL PERSONAL DADO DE BAJA */}
            <Modal show={show2} onHide={handleClose2} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Asignar a empresas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                        <InputGroup.Text id="basic-addon1">
                            <MdLocationCity/>
                        </InputGroup.Text>
                        <Form.Select aria-label="Default select example"
                            onChange={handleChangeCiudad} id="select" name="select"
                        >
                            <option value="">Seleccione la empresa</option>
                            {comboempresas.datos.map(i => (
                                <option value={i.id}>
                                    {i.name}
                                </option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSaveAddEnterprice}>
                        Asignar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Supportuser;
