import React, {useState, Fragment, useEffect} from "react";
import { Bar } from 'react-chartjs-2';
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {useUserSession} from "../../../hooks";
import {
    DEDALO_ALL_CONSTRUCTIONS_TASK,DEDALO_EXECUTE_TASK,
} from "../../../assets/APIAdresses";
import { 
    attachToken,
    manageError,
} from "../../../utils/utils";
import {Redirect, useParams, Link} from "react-router-dom";
import Swal from "sweetalert2";
import { Chart } from "react-google-charts";
import { 
    Card, Row, Col, Accordion, Dropdown, InputGroup,
    DropdownButton, Button, Modal, Table, FormControl,
    Form,
} from 'react-bootstrap'
import * as MdIcons from "react-icons/md";

const DiagramaTareas = () => {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const {Iddiagrama} = useParams();

    const [tareas, setTareas] = useState({
        datos: [],
    });

    const [tareascompletas, setTareasCompletas] = useState({
        datos: [],
    });

    const peticionGetTareas=async()=>{
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CONSTRUCTIONS_TASK+'?constructions_id='+id+'&page=1&rows=1000&order=id&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CONSTRUCTIONS_TASK,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                if(datanotice.length === 0){
                    setTareas({
                        ...tareas,
                        ['datos']: [],
                    });
                }else{
                    if(datanotice.records.length > 0){
                        setTareas({
                            ...tareas,
                            ['datos']: datanotice.records,
                        });
                    }
                }
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };
    
    const peticionGetTareasCompletas=async()=>{
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 200,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CONSTRUCTIONS_TASK+'?constructions_id='+id+'&page=1&rows=1000&order=id&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_EXECUTE_TASK,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                if(datanotice.length === 0){
                    setTareasCompletas({
                        ...tareascompletas,
                        ['datos']: [],
                    });
                }else{
                    if(datanotice.records.length > 0){
                        setTareasCompletas({
                            ...tareascompletas,
                            ['datos']: datanotice.records,
                        });
                    }
                }
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetTareas();
        peticionGetTareasCompletas();
    }, []);

    const porcentaje_tarea = (fechaini,fechafin) => {
        let f1,f2,fh,porcentaje,res1,res2,fa;
        fa = Date.now();
        f1 = new Date(fechaini);
        f2 = new Date(fechafin);
        res1 = fa-f1;
        res2 = f2-f1;
        fh = (res1/res2)*100;
        porcentaje = fh.toFixed(0);
        return porcentaje;
    }

    const fecha_ini_diagramna = (Date_ini_tarea) => {
        let fecha_1 = new Date(Date_ini_tarea);
        let año = fecha_1.getFullYear();
        let mes = fecha_1.getMonth()+1;
        let dia = fecha_1.getDate();
        let fechaf = año+","+mes+","+dia;
        return fechaf;
    };

    const fecha_fin_diagramna = (Date_fin_tarea) => {
        let fecha_2 = new Date(Date_fin_tarea);
        let año_2 = fecha_2.getFullYear();
        let mes_2 = fecha_2.getMonth()+1;
        let dia_2 = fecha_2.getDate();
        let fechaf_2 = año_2+","+mes_2+","+dia_2;
        return fechaf_2;
    }
    
    const columns = [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duracion" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
    ];

    const columns2 = [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duracion" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
    ];
    
    const rows_data = [];
    for(let i=0;i < tareas.datos.length;i++){
        rows_data.push([
            tareas.datos[i].id,
            tareas.datos[i].title,
            tareas.datos[i].description,
            new Date(fecha_ini_diagramna(tareas.datos[i].startdate)),
            new Date(fecha_fin_diagramna(tareas.datos[i].deadline)),
            null,
            porcentaje_tarea(tareas.datos[i].startdate,tareas.datos[i].deadline),
            null,
        ]);
    };

    const rows_data2 = [];
    for(let e=0;e < tareascompletas.datos.length;e++){
        rows_data2.push([
            tareascompletas.datos[e].id,
            tareascompletas.datos[e].title,
            tareascompletas.datos[e].description,
            new Date(fecha_ini_diagramna(tareascompletas.datos[e].startdate)),
            new Date(fecha_fin_diagramna(tareascompletas.datos[e].deadline)),
            null,
            porcentaje_tarea(tareascompletas.datos[e].startdate,tareascompletas.datos[e].deadline),
            null,
        ]);
    }
    
    const data = [columns, ...rows_data];
    const data2 = [columns2, ...rows_data2];

    const options = {
        height: "auto",
        gantt: {
            trackHeight: 40,
            criticalPathEnabled: false,
        },
    };
    
    return (
        <div>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start mb-4 mt-4">
                <div className="mt-2">
                    <Button className="btn btn-success rounded-circle" size="sm" onClick={()=>{window.history.back()}}
                        style={{backgroundColor: "#02B371"}} title="Regresar.">
                        <MdIcons.MdArrowBackIosNew/>
                    </Button>
                </div>
                <div className="text-center d-flex align-items-center justify-content-center w-100">
                    <h4>Cronograma tareas</h4>
                </div>
            </div>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <Chart
                    chartType="Gantt"
                    width="100%"
                    height="50%"
                    data={data}
                    options={options}
                    chartLanguage="es"
                />
            </div>
            <div className="mt-4">
                <div className="text-center d-flex align-items-center justify-content-center w-100">
                    <h4>Cronograma tareas completas</h4>
                </div>
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                    <Chart
                        chartType="Gantt"
                        width="100%"
                        height="50%"
                        data={data2}
                        options={options}
                        chartLanguage="es"
                    />
                </div>
            </div>
        </div>
    );
}

export default DiagramaTareas;