import React, {useState, Fragment, useEffect} from "react";
import {
    Button, NavDropdown, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,
} from 'react-bootstrap';
import {
    DEDALO_ALL_START_CERTIFICATE,DEDALO_DEL_START_CERTIFICATE,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import Swal from "sweetalert2";
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";

const Actas_Inicio = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "number_certificate",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_START_CERTIFICATE,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const peticionDelete = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_START_CERTIFICATE+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el usuario exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    //console.log(categor.datos);

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Link to={Routes.ACTA_NUEVA}>
                            <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}>
                                Generar Acta nueva
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-center mb-4">
                <h2 className="text-center">Actas Generadas</h2>                
            </div>
            <hr/>
            <div className="container container-fluid">
                <Table responsive striped bordered size="sm">
                    <thead>
                        <tr className="text-center">
                            <th>#</th>
                            <th>Num. acta</th>
                            <th>Año acta</th>
                            <th>Construccion</th>
                            <th>Nombre constructor</th>
                            <th>Valor</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categor.datos === undefined ?
                            ''
                        :
                            categor.datos.map((x,index) =>(
                                <tr className="text-center">
                                    <td>{x.id}</td>
                                    <td>{x.number_certificate}</td>
                                    <td>{x.year_certificate}</td>
                                    <td>{x.nameConstruction}</td>
                                    <td>{x.constructor_name}</td>
                                    <td>{formatterPeso.format(x.value)}</td>
                                    <td>
                                        <div class="dropdown">
                                            <HiIcons.HiDotsVertical class="cursor-pointer dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                                            />
                                            <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                <Link to={Routes.ACTA_EDITAR(x.id)} className='text-decoration-none'>
                                                    <li>
                                                        <a class="dropdown-item cursor-pointer">
                                                            Editar
                                                        </a>
                                                    </li>
                                                </Link>
                                                <li>
                                                    <a class="dropdown-item cursor-pointer" onClick={()=>peticionDelete(x.id)}>
                                                        Eliminar
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Actas_Inicio;