import React, {useState, useEffect} from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { 
    Container,Accordion,InputGroup,FormControl,Modal,Button,
    Row,Col,Card,DropdownButton,Dropdown,
} from 'react-bootstrap';
import {
    DEDALO_COMMENT_SUBTASK_BY_ID,DEDALO_ALL_USER_COMBO,
    DEDALO_ALL_TASK_COMMENTS_RESP,DEDALO_ALL_TASK_COMMENTS,DEDALO_TASK_COMMENTS,
    DEDALO_TASK_COMMENTS_RESP,DEDALO_ALL_SUBTASK_COMMENTS,DEDALO_ALL_CONSTRUCTIONS_SUBTASK,
    DEDALO_SUBTASK_RESP,DEDALO_SUBTASK_COMMENTS,DEDALO_SUBTASK_COMMENTS_RESP,
    DEDALO_TASK_COMMENT_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {Redirect, useParams} from "react-router-dom";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import LogoDedalo from '../../../imagenes/dedalo_logo.png';
import Word from '../../../imagenes/iconos/word.png';
import Excel from '../../../imagenes/iconos/excel.png';
import Powerpoint from '../../../imagenes/iconos/powerpoint.png';
import Publisher from '../../../imagenes/iconos/publisher.png';
import Visio from '../../../imagenes/iconos/visio.png';
import Pdf from '../../../imagenes/iconos/pdf.png';
import WinRar from '../../../imagenes/iconos/winrar.png';
import AutoDesk from '../../../imagenes/iconos/autodesk.png';
import JPG from '../../../imagenes/iconos/iconoJPG.png';
import PNG from '../../../imagenes/iconos/iconoPNG.png';
import * as BiIcons from 'react-icons/bi';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from "react-icons/fa";

const ComentariosSubtareas = (nombre_proyecto) => {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const {Idtask} = useParams();
    const [rescomment,setResComment] = useState(0);
    const [subrescomment,setSubResComment] = useState(0);
    const [subtaskcomment,setSubTaskComment] = useState(0);
    const [archivobase64, setArchivoBase64] = useState();
    const [btn_cancelar_adjunto, setBtnCancelarAdjunto] = useState();   

    const [dats, setDat] = useState({
        comentariopri: '',
        comentario_res: '',
        comentario_subres: '',
        subcomentariopri: '',
    });
    
    const [datsfilesub, setDatFileSub] = useState({
        ext: '',
        title_file: '',
    });

    const [subtaskdata, setSubTaskData] = useState({
        datos: [],
    });

    const [subtaskalldata, setSubTaskAllData] = useState({
        datos: [],
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [subtaskfile, setSubTaskFile] = useState({
        datos: [],
        subfiles: [],
    });

    const [allcommentsubtask, setAllCommentSubTask] = useState({
        datos: [],
    });

    const [categor2, setCategor2] = useState({
        datos: [],
    });

    const handleInputChange = (id_text,event) => {
        let new_dats;
        if(document.getElementById("subcomentariopri"+id_text).value === null){
            new_dats = '';
            setDat({
                ...dats,
                ["subcomentariopri"]: new_dats,
                //["comentario_subres"]: new_dats1,
            });
        }else{
            new_dats = document.getElementById("subcomentariopri"+id_text).value;
            setBtnCancelarAdjunto(id_text);
            setDat({
                ...dats,
                ["subcomentariopri"]: new_dats,
                //["comentario_subres"]: new_dats1,
            });
        }
        //console.log([new_dats]);
    };

    const handleInputChange2 = (id_text,event) => {
        let new_dats1 = document.getElementById("comentario_subres"+subrescomment).value === null ? 
                            '' 
                        : 
                            document.getElementById("comentario_subres"+subrescomment).value;
        setDat({
            ...dats,
            ["comentario_subres"]: new_dats1,
        });
        //console.log([new_dats1]);
    }
    
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (idcomment_subres) => {
        setSubResComment(idcomment_subres);
        setShow3(true);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (idcomment_subres) => {
        peticionGetAllCommentSubTask(idcomment_subres);
        setShow4(true);
    };

    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = (IdfileSub) => {
        peticionGetCommentSubTaskById(IdfileSub);
        setShow5(true);
    };

    const peticionGetAllSubTaskData=async()=>{
        const params_data = {
            constructions_task_id: parseInt(Idtask),
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CONSTRUCTIONS_SUBTASK+'?constructions_task_id='+Idtask+'&page=1&rows=1000&order=id&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CONSTRUCTIONS_SUBTASK,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.length);
                if(datanotice.length === 0){
                    setSubTaskAllData({
                        ...subtaskalldata,
                        ['datos']: [],
                    });
                }else{
                    if(datanotice.records.length > 0){
                        setSubTaskAllData({
                            ...subtaskalldata,
                            ['datos']: datanotice.records,
                        });
                        peticionGetAllCommentsSubtask(datanotice.records);
                    }
                }
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };

    const peticionGetAllCommentsSubtask=(infoid)=>{
        let arr_info = [];
        const info_comments_id = infoid === undefined ? [] : infoid;
        //let info1 = await axios.get(DEDALO_ALL_SUBTASK_COMMENTS+'?constructions_subtask_id='+subtaskcomment)
        //console.log(info_comments_id);
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'percentage_advance',
            order: 'asc',
        };
        info_comments_id.map((item)=>{
            let info1 = axios({
                method: 'POST',
                url: DEDALO_ALL_SUBTASK_COMMENTS+'&constructions_subtask_id='+item.id,
                headers: {Authorization: userToken},
                data: params_data
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    arr_info.push({id: item.id, datanotice});
                    setSubTaskData({
                        ...subtaskdata,
                        ['datos']: arr_info
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        });
    };

    const peticionGetAllCommentSubTask=async(subcommentsId)=>{
        if(subcommentsId === undefined){
            let po = 0;
        }else{
            //let info1 = await axios.get(DEDALO_SUBTASK_COMMENTS_RESP+'?construction_subtask_comments_id='+subcommentsId)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_SUBTASK_COMMENTS_RESP+'&construction_subtask_comments_id='+subcommentsId,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    setAllCommentSubTask({
                        ...allcommentsubtask,
                        ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
                } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const peticionGetCommentSubTaskById=async(commentsId)=>{
        if(commentsId === undefined){
            let oi = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_TASK_COMMENTS_RESP+'?construction_task_comments_id='+commentsId)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_COMMENT_SUBTASK_BY_ID+'&id='+commentsId,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice);
                    setSubTaskFile({
                        ...subtaskfile,
                        ['datos']: datanotice.data,
                        ['subfiles']: datanotice.data.Files,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
                } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const peticionGetUserCombo=async()=>{
        const formattedData = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor2({
                    ...categor2,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetAllSubTaskData();
        peticionGetAllCommentSubTask();
        peticionGetUserCombo();
    }, []);
    
    
    let sub_responses_comment = allcommentsubtask.datos;
    let info_base64 = archivobase64 === undefined ? 0 : archivobase64;
    let info_files_sub = subtaskfile.datos.length === 0 ? [] : subtaskfile.subfiles;
    
    const adjuntar_sub = (FileIdSub) => {
        var file = document.getElementById("file_sub_"+FileIdSub).click();
        var file_name = document.getElementById("file_sub_"+FileIdSub);
    };

    const borraradjunto_sub = () => {
        setArchivoBase64();
        setDatFileSub({
            ...datsfilesub,
            ['ext']: '',
            ['title_file']: '',
        });
    };

    const convertToBase64 = (File_Id_Sub) => {
        //Read File
        var selectedFile = document.getElementById("file_sub_"+File_Id_Sub).files;
        let selectedFile1 = document.getElementById("file_sub_"+File_Id_Sub).value;
        let nom1_archivo = document.getElementById('file_sub_'+File_Id_Sub).files[0];
        let nom2_archivo = document.getElementById('file_sub_'+File_Id_Sub);
        let extension = selectedFile1.substring(selectedFile1.lastIndexOf('.'),selectedFile1.length);
        let MAXIMO_TAMANIO_BYTES = 50000000;

        setDatFileSub({
            ...datsfilesub,
            ['title_file']: nom1_archivo.name.split('.')[0],
            ['ext']: extension
        });

        if(nom1_archivo.size > MAXIMO_TAMANIO_BYTES){
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!",`El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`,"info");
            nom2_archivo.value = "";
            
            setDatFileSub({
                ...datsfilesub,
                ['title_file']: '',
                ['ext']: ''
            });
        }else{
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                //var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.split(',')
                    setArchivoBase64(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                // Obtener extensión del archivo
            }
        }
    };

    const handleCloseSaveSubResp = (e) => {

        let titulo_subres = dats.comentario_subres;
        
        const formattedData = {
            constructions_id: id,
            construction_subtask_comments_id: subrescomment,
            creator_id: userId,
            response: titulo_subres,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_SUBTASK_RESP, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_SUBTASK_RESP,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Comentario enviado", "", "success");
                document.getElementById('comentario_subres'+subrescomment).value = '';
                handleClose3();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };
    
    const handleCloseSaveSubRespPri = (e) => {

        let titulo_subres = dats.subcomentariopri;
        
        const formattedData = {
            constructions_id: id,
            constructions_subtask_id: e,
            creator_id: userId,
            comment: titulo_subres,
            type: datsfilesub.ext,
            title: datsfilesub.title_file,
            file: archivobase64 === undefined ? '' : archivobase64,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_SUBTASK_COMMENTS, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_SUBTASK_COMMENTS,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Comentario enviado", "", "success");
                document.getElementById("subcomentariopri"+btn_cancelar_adjunto).value = '';
                peticionGetAllSubTaskData();
                borraradjunto_sub();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };
    
    const fecha_comentario = (datadate) =>{
        let fecha_comentarios = new Date(datadate);
        let dia_comentario = fecha_comentarios.getDate();
        let mes_comentario = fecha_comentarios.getMonth()+1;
        let año_comentario = fecha_comentarios.getFullYear();
        let fecha_res = dia_comentario+'/'+mes_comentario+'/'+año_comentario;

        return fecha_res;
    };
    
    const fechas_respuestas = (datadate) =>{
        let fecha_respuesta = new Date(datadate);
        let dia_respuesta = fecha_respuesta.getDate();
        let mes_respuesta = fecha_respuesta.getMonth()+1;
        let año_respuesta = fecha_respuesta.getFullYear();
        let fecha_res = dia_respuesta+'/'+mes_respuesta+'/'+año_respuesta;

        return fecha_res;
    };

    const descargaarchivo = (data) =>{
        const downloadinstance = document.createElement('a');
        downloadinstance.href = subtaskfile.subfiles[0].file64;
        downloadinstance.target = '_blank';
        downloadinstance.download = subtaskfile.subfiles[0].file.split('/')[subtaskfile.subfiles[0].file.split('/').length-1];
        downloadinstance.click();
        //console.log(downloadinstance.href);
    };

    return(
        <div>
            {subtaskalldata.datos.map((sub, index) => (
                <VerticalTimelineElement
                    className="vertical-timeline-element--work" key={index}
                    date={
                        categor2.datos.map((u,u_index) => (
                            u.id === parseInt(sub.creator_id) &&
                                <b style={{color: "black"}} key={u_index}>
                                    {u.name}
                                </b>
                        ))}
                    iconStyle={
                        {
                            backgroundImage: `url(${LogoDedalo})`,
                            backgroundSize: "cover",
                            //backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }
                    }responsablessubtarea
                >
                    <h3 className="vertical-timeline-element-title">Proyecto {nombre_proyecto.nom_project}</h3>
                    <h4 className="vertical-timeline-element-subtitle">SubTarea {sub.title}</h4>
                    <br/>
                    <div>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Comentar SubTarea" id={`subcomentariopri${sub.id}`} 
                                aria-label="Comentar SubTarea" name={`subcomentariopri${sub.id}`}
                                aria-describedby="basic-addon1" onChange={()=>handleInputChange(sub.id)}
                            />
                                {info_base64 === 0 ?
                                    <InputGroup.Text id="basic-addon1" onClick={()=>adjuntar_sub(sub.id)} style={{cursor: "pointer"}}>
                                        <MdIcons.MdAttachFile className="text-primary" title="Adjuntar archivo"/>
                                        <input id={"file_sub_"+sub.id} type = "file" style={{display: "none"}} onChange={()=>convertToBase64(sub.id)}/>
                                    </InputGroup.Text>
                                :
                                    <InputGroup.Text id="basic-addon1" onClick={borraradjunto_sub} style={{cursor: "pointer"}}>
                                        <MdIcons.MdCancel style={{color: 'red'}} title="Cancelar adjunto"/>
                                    </InputGroup.Text>
                                }
                            <Button variant="success" onClick={()=>handleCloseSaveSubRespPri(sub.id)} title="Enviar Comentario">
                                <MdIcons.MdSend/>
                            </Button>
                        </InputGroup>
                    </div>
                    <div style={
                            {
                                color: "black", height: "14em", minHeight: "14em", padding: "0.5em 1em",
                                maxHeight: "14em",overflowY: "auto", backgroundColor: "white",
                                borderRadius: "0.3em",
                            }
                        }
                    >
                        {subtaskdata.datos.map(r => (
                            parseInt(sub.id) === parseInt(r.id) &&
                            r.datanotice.map((i,index_comm) => (
                                <div class="d-flex justify-content-between mb-2">
                                    <div class="d-flex justify-content-between mb-2" key={index_comm}>
                                        <div>
                                            <img src={i.image === '' ? LogoDedalo : i.image} className="me-2" 
                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                        </div>
                                        <div style={{fontSize: "0.8em"}}>
                                            <b style={{color: "#d35400"}}>
                                                {i.creatorName}
                                            </b> - {fecha_comentario(i.date)}
                                            <br/>
                                                {i.comment}
                                            <br/>
                                            <a href="#" className="text-decoration-none me-2" onClick={()=>handleShow3(i.id)}>
                                                Comentar
                                            </a>
                                            <a href="#" className="text-decoration-none me-2" onClick={()=>handleShow4(i.id)}>
                                                Ver Comentarios 
                                            </a>
                                            <a href="#" className="text-decoration-none me-2" onClick={()=>handleShow5(i.id)}>
                                                Ver Adjunto 
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ))}
                    </div>
                </VerticalTimelineElement>
            ))}

            {/** MODAL VER COMENTARIOS SUBTAREA  **/}
            <Modal show={show4} onHide={handleClose4} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Respuestas del Comentario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {sub_responses_comment === undefined ?
                            ''
                        :sub_responses_comment.length === 0 ?
                            <div className="text-center">
                                <h5>No hay respuestas</h5>
                            </div>
                        :
                            sub_responses_comment.map(item => (
                                <div class="d-flex justify-content-between mb-2">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div>
                                            <img src={item.image === '' ? LogoDedalo : item.image} className="me-2" 
                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                        </div>
                                        <div style={{fontSize: "0.8em"}}>
                                            <b style={{color: "#229954"}}>
                                                {item.creatorName}
                                            </b> - {fechas_respuestas(item.date)}
                                            <br/>
                                            {item.response}
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                </Modal.Body>
            </Modal>
            {/** MODAL COMENTAR SUBTAREA **/}
            <Modal show={show3} onHide={handleClose3} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Respuestas del Comentario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            <MdIcons.MdOutlineQuestionAnswer/>
                        </InputGroup.Text>
                        <FormControl
                            placeholder="Responder Comentario Subtarea" id={`comentario_subres${subrescomment}`}
                            aria-label="Responder Comentario Subtarea" name={`comentario_subres${subrescomment}`}
                            aria-describedby="basic-addon1" onChange={handleInputChange2}
                            as="textarea"ne rows="3" style={{resize: "none"}}
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSaveSubResp}>
                        Responder
                    </Button>
                </Modal.Footer>
            </Modal>
            {/** MODAL VER ADJUNTO **/}
            <Modal show={show5} onHide={handleClose5} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Archivo Adjunto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div> {/* archivos */}
                        {info_files_sub.length === 0 ?
                            <div className="text-center">
                                <h5>No hay archivos adjuntos</h5>
                            </div>
                        :
                            <div className="d-flex align-items-center justify-content-center">
                                <Card style={{ width: '24rem' }}>
                                    <Card.Header variant="top" className="text-center">
                                        {info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.pptx' ?
                                            <img src={Powerpoint} alt="pptx" title="pptx" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.docx' ?
                                            <img src={Word} alt="word" title="word" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.rar' ?
                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.zip' ?
                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.7z' ?
                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.pdf' ?
                                            <img src={Pdf} alt="pdf" title="pdf" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.xlsx' ?
                                            <img src={Excel} alt="xlsx" title="xlsx" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.xls' ?
                                            <img src={Excel} alt="xls" title="xls" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.dwg' ?
                                            <img src={AutoDesk} alt="DWG" title="DWG" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.jpg' ?
                                            <img src={JPG} alt="JPG" title="JPG" width="100px" height="100px" />
                                        :info_files_sub[0].file.substring(info_files_sub[0].file.lastIndexOf('.'),info_files_sub[0].file.length) === '.png' ?
                                            <img src={PNG} alt="PNG" title="PNG" width="100px" height="100px" />
                                        :
                                            ''
                                        }
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title className="with-elements">
                                            <div className="fs-6 text">
                                                {info_files_sub[0].file.split('/')[2].split('_')[0]}
                                            </div>
                                            <div className="ml-auto seccion_menu_archivos">
                                                <DropdownButton id="dropdown-basic-button" size="sm"
                                                    variant="light" className="rounded"
                                                >
                                                    <Dropdown.Item onClick={descargaarchivo}>
                                                        <FaIcons.FaDownload className="text-primary me-2 fs-5"/>
                                                        Descargar Item
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose5}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ComentariosSubtareas;