import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Row, Col, Accordion, Dropdown, InputGroup,
    DropdownButton, Button, Modal, Table, FormControl,
    Form,
} from 'react-bootstrap';
import {
    DEDALO_ALL_FLOWCHART,DEDALO_NEW_FLOWCHART,DEDALO_UPDATE_FLOWCHART,
    DEDALO_DEL_FLOWCHART,DEDALO_FLOWCHART_COMBO,DEDALO_FLOWCHART_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import { 
    attachToken,
    manageError,
} from "../../../utils/utils";
import {Redirect, useParams, Link} from "react-router-dom";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import { SymbolPaletteComponent, DiagramComponent, Node } from "@syncfusion/ej2-react-diagrams";
import { ToolbarComponent } from "@syncfusion/ej2-react-navigations";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-buttons/styles/material.css";
import "@syncfusion/ej2-react-popups/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-layouts/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";

const FlujogramaProyectos = (InfoData) => {
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    let {Idproject} = useParams();
    let {IdFlujo} = useParams();
    const [btn_cargar, setBtn_Cargar] = useState();

    const [dats, setDat] = useState({
        title: '',
        obs: '',
        flowchart: '',
        datos: '',
    });

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetUserById=async()=>{
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS_BY_ID(id))
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_FLOWCHART_BY_ID+'&id='+Idproject,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setDat({
                    ...dats,
                    ['datos']: datanotice,
                    ['title']: datanotice.title,
                    ['obs']: datanotice.description,
                    ['flowchart']: JSON.stringify(datanotice.flowchart[0]),
                });
                setBtn_Cargar(document.getElementById('carga'));
              } else {
                 //alert(message_responses[response.data.message]);
                 setDat([]);
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos2");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetUserById();
    }, []);

    let diagramInstance;
    let interval;

    interval = [
        1,
        9,
        0.25,
        9.75,
        0.25,
        9.75,
        0.25,
        9.75,
        0.25,
        9.75,
        0.25,
        9.75,
        0.25,
        9.75,
        0.25,
        9.75,
        0.25,
        9.75,
        0.25,
        9.75
    ];

    let gridlines = {
        lineColor: "#e0e0e0",
        lineIntervals: interval
    };

    //Initialize the flowshapes for the symbol palatte
    let flowshapes = [
        { id: "Terminator", shape: { type: "Flow", shape: "Terminator" } },
        { id: "Decision", shape: { type: "Flow", shape: "Decision" } },
        { id: "Process", shape: { type: "Flow", shape: "Process" } },
        { id: "Delay", shape: { type: "Flow", shape: "Delay" } },
        { id: "Document", shape: { type: "Flow", shape: "Document" },  },
        { id: "Sort", shape: { type: "Flow", shape: "Sort" } },
        { id: "MultiDocument", shape: { type: "Flow", shape: "MultiDocument" } },
        { id: "Extract", shape: { type: "Flow", shape: "Extract" } },
        { id: "data", shape: { type: "Flow", shape: "Data" } },
        { id: "Card", shape: { type: "Flow", shape: "Card" } },
        //{ id: "Annotation", shape: { type: "Flow", shape: "Annotation" } },
        //{ id: "Annotation2", shape: { type: "Flow", shape: "Annotation2" } },
        //{ id: "PreDefinedProcess", shape: { type: "Flow", shape: "PreDefinedProcess" } },
        //{ id: "PaperTap", shape: { type: "Flow", shape: "PaperTap" } },
        //{ id: "DirectData", shape: { type: "Flow", shape: "DirectData" } },
        //{ id: "SequentialData", shape: { type: "Flow", shape: "SequentialData" } },
        //{ id: "Collate", shape: { type: "Flow", shape: "Collate" } },
        //{ id: "SummingJunction", shape: { type: "Flow", shape: "SummingJunction" } },
        //{ id: "Or", shape: { type: "Flow", shape: "Or" } },
        //{ id: "InternalStorage", shape: { type: "Flow", shape: "InternalStorage" } },
        //{ id: "Merge", shape: { type: "Flow", shape: "Merge" } },
        //{ id: "ManualOperation", shape: { type: "Flow", shape: "ManualOperation" } },
        //{ id: "OffPageReference", shape: { type: "Flow", shape: "OffPageReference" } },
        //{ id: "SequentialAccessStorage", shape: { type: "Flow", shape: "SequentialAccessStorage" } },
    ];

    //Initializes connector symbols for the symbol palette
    let connectorSymbols = [
        {
            id: "Link1",
            type: "Orthogonal",
            sourcePoint: { x: 0, y: 0 },
            targetPoint: { x: 40, y: 40 },
            targetDecorator: { shape: "Arrow", style: { strokeColor: '#757575', fill: '#757575' } },
            style: { strokeWidth: 2, strokeColor: '#757575' }
        },
        {
            id: "link2",
            type: "Orthogonal",
            sourcePoint: { x: 0, y: 0 },
            targetPoint: { x: 40, y: 40 },
            style: { strokeWidth: 2, strokeColor: '#757575' },
            targetDecorator: { shape: "None" }
        },
        {
            id: "Link3",
            type: "Straight",
            sourcePoint: { x: 0, y: 0 },
            targetPoint: { x: 40, y: 40 },
            targetDecorator: { shape: "Arrow", style: { strokeColor: '#757575', fill: '#757575' } },
            style: { strokeWidth: 2, strokeColor: '#757575' }
        },
        {
            id: "link4",
            type: "Straight",
            sourcePoint: { x: 0, y: 0 },
            targetPoint: { x: 40, y: 40 },
            style: { strokeWidth: 2, strokeColor: '#757575' },
            targetDecorator: { shape: "None" }
        },
        {
            id: "link5",
            type: "Bezier",
            sourcePoint: { x: 0, y: 0 },
            targetPoint: { x: 40, y: 40 },
            style: { strokeWidth: 2, strokeColor: '#757575' },
            targetDecorator: { shape: "None" }
        }
    ];

    //save the diagram object in json data.
    function download(data) {
        if (window.navigator.msSaveBlob) {
            var blob = new Blob([data], { type: "data:text/json;charset=utf-8," });
            window.navigator.msSaveOrOpenBlob(blob, "Diagram.json");
        }
        else {
            var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(data);
            var a = document.createElement("a");
            a.href = dataStr;
            a.download = "Diagram.json";
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    };

    //create and add ports for Node.
    function getPorts(obj) {
        let ports = [
            { id: "port1", shape: "Circle", offset: { x: 0, y: 0.5 } },
            { id: "port2", shape: "Circle", offset: { x: 0.5, y: 1 } },
            { id: "port3", shape: "Circle", offset: { x: 1, y: 0.5 } },
            { id: "port4", shape: "Circle", offset: { x: 0.5, y: 0 } }
        ];
        let additionalports = [
            { id: "port2", shape: "Circle", offset: { x: 0.5, y: 1 } },
            { id: "port4", shape: "Circle", offset: { x: 0.5, y: 0 } }
        ];
        if (obj.id === "Data") {
            return additionalports;
        }
        else {
            return ports;
        }
    };

    function openPalette() {
        let isMobile;
        let paletteSpace = document.getElementById('palette-space');
        isMobile = window.matchMedia('(max-width:550px)').matches;
        if (isMobile) {
            if (!paletteSpace.classList.contains('sb-mobile-palette-open')) {
                paletteSpace.classList.add('sb-mobile-palette-open');
            }
            else {
                paletteSpace.classList.remove('sb-mobile-palette-open');
            }
        }
    };

    const handleCloseSave = (data) => {
        let name = dats.title;
        let obs = dats.obs;
        
        const formattedData = {
            constructions_id: parseInt(IdFlujo),
            title: name,
            creator_id: userId,
            description: obs,
            flowchart: [JSON.parse(data)],
        };
        //console.log(formattedData);
        //axios.put(DEDALO_ACTUALIZAR_USUARIO(id), formattedData)
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_FLOWCHART+'&id='+parseInt(Idproject),
            headers: {Authorization: userToken},
            data: formattedData
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se guardo el flujograma exitósamente", "", "success");
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    const loadDiagram2 = () => {
        diagramInstance.loadDiagram(dats.flowchart);
        //alert('loadDiagram2');
    };

    if(btn_cargar === undefined){
        let yt = 0;
    }else{
        btn_cargar.click();
    }
    

    return(
        <div >
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start mt-4">
                <div className="mt-2">
                    <Button className="btn btn-success rounded-circle" size="sm" onClick={()=>{window.history.back()}}
                        style={{backgroundColor: "#02B371"}} title="Regresar.">
                        <MdIcons.MdArrowBackIosNew/>
                    </Button>
                </div>
                <div className="text-center d-flex align-items-center justify-content-center w-100">
                    <h4>Flujograma</h4>
                </div>
            </div>
            <div className="container-fluid flex-grow-1 container-p-y contenido_flujograma">
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdOutlineSubtitles/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Titulo del flujograma" aria-label="Titulo del flujograma"
                                        aria-describedby="basic-addon1" value={dats.title}
                                        type="text" onChange={handleInputChange} disabled={true}
                                        id="title" name="title"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdDescription/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Observaciones del flujograma" aria-label="Observaciones del flujograma"
                                        aria-describedby="basic-addon1" value={dats.obs}
                                        type="text" onChange={handleInputChange} disabled={true}
                                        id="obs" name="obs"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={12}>
                        <div className="mb-2">
                            <Button className="btn btn-success botonflujo btnAdd me-2" size="sm" 
                                id="carga" name="carga" onClick={loadDiagram2} style={{display: 'none'}}
                            >
                                Cargar flujograma
                            </Button>
                        </div>
                    </Col>
                    <Col sm={12} md={12}>
                        <Row>
                            <Col sm={12} md={2}>
                                <div className="bg-info">
                                    <SymbolPaletteComponent 
                                        id="symbolpalette" 
                                        expandMode="Multiple" 
                                        palettes={[
                                            {
                                                id: "flow",
                                                expanded: true,
                                                symbols: flowshapes,
                                                iconCss: "e-diagram-icons1 e-diagram-flow",
                                                title: "Flow Shapes"
                                            },
                                            {
                                                id: "connectors",
                                                expanded: true,
                                                symbols: connectorSymbols,
                                                iconCss: "e-diagram-icons1 e-diagram-connector",
                                                title: "Connectors"
                                            }
                                        ]} //set default value for Node.
                                        getNodeDefaults={(symbol) => {
                                            symbol.style.strokeColor = '#757575';
                                            if (symbol.id === "Terminator") {
                                                symbol.width = 80;
                                                symbol.height = 40;
                                                symbol.style.fill = '#D2E5B3';
                                            }
                                            else if (symbol.id === "Decision") {
                                                symbol.width = 50;
                                                symbol.height = 40;
                                                symbol.style.fill = '#F96B13';
                                            }
                                            else if (symbol.id === "Process") {
                                                symbol.width = 80;
                                                symbol.height = 40;
                                                symbol.style.fill = '#E5E7E9';
                                            }else if (symbol.id === "Delay" ||
                                                    symbol.id === "Document" ||
                                                    symbol.id === "Sort" ||
                                                    symbol.id === "MultiDocument" ||
                                                    symbol.id === "PaperTap" ||
                                                    symbol.id === "Extract" ||
                                                    symbol.id === "data" ||
                                                    symbol.id === "Card"
                                            ) {
                                                symbol.width = 60;
                                                symbol.height = 40;
                                                symbol.style.fill = '#BB8FCE';
                                            }else {
                                                symbol.width = 50;
                                                symbol.height = 50;
                                            }
                                        }} 
                                        symbolMargin={{ left: 11, right: 11, top: 11, bottom: 11 }} 
                                        getSymbolInfo={(symbol) => {
                                            return { fit: true };
                                        }} 
                                        width={"100%"} height={"auto"} symbolHeight={55} symbolWidth={55}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={10}>
                                <div className="border border-dark">
                                    <DiagramComponent 
                                        id="diagramedit"
                                        ref={diagram => (diagramInstance = diagram)} 
                                        nodes=''
                                        width={"100%"} height={"600px"}
                                        snapSettings={{
                                        horizontalGridlines: gridlines,
                                        verticalGridlines: gridlines
                                        }}
                                        dragEnter={(args) => {
                                            let obj = args.element;
                                            if (obj instanceof Node) {
                                                let oWidth = obj.width;
                                                let oHeight = obj.height;
                                                let ratio = 100 / obj.width;
                                                obj.width = 100;
                                                obj.height *= ratio;
                                                obj.offsetX += (obj.width - oWidth) / 2;
                                                obj.offsetY += (obj.height - oHeight) / 2;
                                                //obj.style = { fill: "#357BD2", strokeColor: "white" };
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default FlujogramaProyectos;