import React, { useState, Fragment, useEffect, useRef } from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav, Table, DropdownButton, ButtonGroup, Dropdown,
    Card, Container,
} from 'react-bootstrap';
import {
    DEDALO_CIUDADES, DEDALO_NEW_START_CERTIFICATE,
} from "../../../assets/APIAdresses";
import { message_responses } from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import { useUserSession } from "../../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../../utils/routes';
import { Mensaje_Error_Create_Acta } from '../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import Img1 from '../imagenes_reportes/logo_geo.png';
import * as MdIcons from "react-icons/md";

const Acta_Nueva = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [vistapdf, serVistaPdf] = useState(false);
    const [ciudad, setCiudad] = useState('');
    const [ciudadid, setCiudadId] = useState(0);

    const [dats, setDat] = useState({
        dia: '',
        mes: '',
        year: '',
        person1: '',
        idntificacion: '',
        idntificacion2: '',
        contrato: '',
        representante: '',
        nit: '',
        observations: '',
        numero: '',
        year_acta: '',
        contratante: '',
        contratista: '',
        objeto: '',
        valor: '',
        plazo: '',
        fecha_ini: '',
    });

    const [ciudadesproyectos, setCiudadesProyectos] = useState({
        datos: [],
    });

    const peticionGetCategor = async () => {
        const params_data = {
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CIUDADES,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice);
                    setCiudadesProyectos({
                        ...ciudadesproyectos,
                        ['datos']: datanotice.data,
                    });
                } else {
                    setCiudadesProyectos({
                        ...ciudadesproyectos,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const handleChangePDF = (e) => {
        serVistaPdf(e.target.checked);
    };

    const handleInputChange = (e) => {
        let id_City = e.target.value;
        let datos_ciudad = ciudadesproyectos.datos === undefined ? [] : ciudadesproyectos.datos;
        let nom_ciudad = datos_ciudad.filter(x => parseInt(x.id) === parseInt(id_City));
        setCiudad(nom_ciudad[0].name);
        setCiudadId(parseInt(id_City));
    };

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const printContainer = useRef(null);

    const createPDF = async () => {
        let year = new Date().getFullYear();
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;

        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `acta_inicio_${fecha}.pdf`
        });
    };

    const handleCloseSave = (e) => {
        let obs = dats.observations;

        const formattedData = {
            users_id: userId,
            enterprice_id: data_emp2.IdEmp,
            city_id: ciudadid,
            constructions_id: data_emp.Id_Project_Conta,
            number_certificate: dats.numero,
            year_certificate: dats.year,
            contractor: dats.contratante,
            constructor_name: dats.contratista,
            object: dats.objeto,
            value: dats.valor,
            term: dats.plazo,
            startdate: dats.fecha_ini,
            meet_day: dats.dia,
            meet_month: dats.mes,
            meet_year: dats.year,
            meet_person_one: dats.person1,
            document_person_one: dats.idntificacion,
            document_person_two: dats.idntificacion2,
            contractual_order: dats.contrato,
            enterprice: dats.representante,
            enterprice_nit: dats.nit,
            observations: dats.observations,
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_NEW_START_CERTIFICATE,
            headers: { Authorization: userToken },
            data: formattedData
        })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire("Se ha creado la cotizacion exitósamente", "", "success");
                } else {
                    Mensaje_Error_Create_Acta(response.data.msg.error)
                }
            })
            .catch(error => {
                manageError(error);
            });
    };

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371" }}
                            onClick={() => handleCloseSave()}
                        >
                            Guardar Acta nueva
                        </Button>
                    </div>
                    {vistapdf === true ?
                        <div>
                            <Button className="btn btn-success me-2" size="sm"
                                onClick={() => createPDF()} title="Regresar" style={{ backgroundColor: "#02B371" }}
                            >
                                descargar PDF
                            </Button>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
            <br />
            <div className="container-fluid">
                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Vista de pdf"
                        onChange={handleChangePDF}
                    />
                </Form>
            </div>
            <div className="container container-fluid" ref={printContainer} style={{ padding: '1em 3em 1em 3em', width: '72em' }}>
                <Row className="border border-dark mb-4"> {/*  cabecera del formato */}
                    <Col md={2} className="text-center py-1 border-end border-dark">
                        <img src={Img1} className='img-fluid' width={230} />
                    </Col>
                    <Col md={8} className="d-flex justify-content-center align-items-center">
                        <div>
                            <h3>
                                <b>ACTA DE INCIO</b>
                            </h3>
                        </div>
                    </Col>
                    <Col md={2} className="text-center py-1 border-start border-dark">
                        <div>
                            Codigo: FO-C0-09-01
                        </div>
                        <div>
                            Fecha: 30/12/2013
                        </div>
                        <div>
                            Pagina 1 de 1
                        </div>
                    </Col>
                </Row>
                <br />
                <Row> {/*  campos del formato */}
                    <Col md={12}>
                        <Row>
                            <Col md={12} className="border border-dark text-center" style={{ backgroundColor: '#95a5a6' }}>
                                <h4>
                                    <b>CONTRATO / ORDEN CONTRACTUAL DE COMPRA / DE PRESTACION DE SERVICIOS</b>
                                </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} className="px-3 d-flex justify-items-center align-items-center border-end border-start border-bottom border-dark">
                                <span style={{ fontSize: '1.1em' }}>
                                    <b>Numero:</b>
                                </span>
                            </Col>
                            <Col md={3} className='border-end border-dark border-bottom'>
                                <FormControl
                                    aria-label="Nombre de la cotizacion"
                                    aria-describedby="basic-addon1" type="text" id="numero" name="numero"
                                    className="border-0" onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={2} className="px-3 d-flex justify-items-center align-items-center border-end border-dark border-bottom">
                                <span style={{ fontSize: '1.1em' }}>
                                    <b>Del Año:</b>
                                </span>
                            </Col>
                            <Col md={5} className='border-bottom border-end border-dark'>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={handleInputChangeObs}
                                    aria-describedby="basic-addon1" type="text" id="year_acta" name="year_acta"
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={2} className="px-3 d-flex justify-items-center align-items-center border-end border-start border-dark">
                                <span style={{ fontSize: '1.1em' }}>
                                    <b>Contratante:</b>
                                </span>
                            </Col>
                            <Col md={10} className="border-end border-dark">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={handleInputChangeObs}
                                    aria-describedby="basic-addon1" type="text" id="contratante" name="contratante"
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={2} className="px-3 d-flex justify-items-center align-items-center border-end border-start border-dark">
                                <span style={{ fontSize: '1.1em' }}>
                                    <b>Contratista:</b>
                                </span>
                            </Col>
                            <Col md={10} className="border-end border-dark">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={handleInputChangeObs}
                                    aria-describedby="basic-addon1" type="text" id="contratista" name="contratista"
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={2} className="px-3 d-flex justify-items-center align-items-center border-end border-start border-dark">
                                <span style={{ fontSize: '1.1em' }}>
                                    <b>Objeto:</b>
                                </span>
                            </Col>
                            <Col md={10} className="border-end border-dark">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={handleInputChangeObs}
                                    aria-describedby="basic-addon1" type="text" id="objeto" name="objeto"
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={2} className="px-3 d-flex justify-items-center align-items-center border-end border-start border-dark">
                                <span style={{ fontSize: '1.1em' }}>
                                    <b>Valor:</b>
                                </span>
                            </Col>
                            <Col md={10} className="border-end border-dark">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={handleInputChangeObs}
                                    aria-describedby="basic-addon1" type="text" id="valor" name="valor"
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={2} className="px-3 d-flex justify-items-center align-items-center border-end border-start border-dark">
                                <span style={{ fontSize: '1.1em' }}>
                                    <b>
                                        Plazo: <br />
                                        <small className="text-muted">
                                            (En dias calendario)
                                        </small>
                                    </b>
                                </span>
                            </Col>
                            <Col md={10} className="border-end border-dark">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={handleInputChangeObs}
                                    aria-describedby="basic-addon1" type="text" id="plazo" name="plazo"
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={2} className="px-3 d-flex justify-items-center align-items-center border-end border-start border-dark">
                                <div>
                                    <span style={{ fontSize: '1.1em' }}>
                                        <b>
                                            Fecha de Inicio:
                                        </b>
                                    </span>
                                    <small className="mt-0 text-muted">
                                        (Dia/mes/año)
                                    </small>
                                </div>
                            </Col>
                            <Col md={10} className="border-end border-dark">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={handleInputChangeObs}
                                    aria-describedby="basic-addon1" type="text" id="fecha_ini" name="fecha_ini"
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                {vistapdf === true ?
                    <Row>
                        <Col md={12} className='mt-4' style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                            El dia ( {dats.dia} ) del mes ( {dats.mes} ) de año ( {dats.year} ), se reunieron en la ciudad de, los suscritos,
                            mayores de edad, a saber: {dats.person1} identificado con Cedula de
                            Ciudadania No. {dats.idntificacion}, en su condicion de Contratante del Contrato/
                            Orden Contractual N° {dats.contrato}, en nombre y representacion de {dats.representante},
                            identificada con <b>NIT:</b> {dats.nit}, de una
                            parte; y por la otra,   JORGE SALAZAR ESCOBAR, identificado(a) con cedula de
                            Ciudadania No. {dats.idntificacion2}, en su calidad de Representante
                            Legal de la empresa GEOESTRUCTURAS LTDA, identificada con NIT 800.028.676-2,
                            con el fin de dar inicio a la ejecucion del presente Contrato.
                        </Col>
                    </Row>
                    :
                    <div>
                        <p style={{ fontSize: '1.2em' }}>
                            <b>Datos a ingresar</b>
                        </p>
                        <Row>
                            <Col md={1} style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="dia"
                                    aria-describedby="basic-addon1" type="text" id="dia" name="dia"
                                    onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={1} style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="mes"
                                    aria-describedby="basic-addon1" type="text" id="mes" name="mes"
                                    onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={1} style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="año"
                                    aria-describedby="basic-addon1" type="text" id="year" name="year"
                                    onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={3} style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="a saber"
                                    aria-describedby="basic-addon1" type="text" id="person1" name="person1"
                                    onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={3} style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="No. identificacion"
                                    aria-describedby="basic-addon1" type="text" id="idntificacion" name="idntificacion"
                                    onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={3} style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="No. contrato"
                                    aria-describedby="basic-addon1" type="text" id="contrato" name="contrato"
                                    onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className='mt-4' style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="representante"
                                    aria-describedby="basic-addon1" type="text" id="representante" name="representante"
                                    onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={2} className='mt-4' style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="nit"
                                    aria-describedby="basic-addon1" type="text"
                                    id="nit" name="nit" onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={3} className='mt-4' style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <Form.Select
                                    aria-label="Default select example"
                                    id={"select_supplies_"} name="id_supllies" onChange={(e) => handleInputChange(e)}
                                    className="me-2"
                                >
                                    <option>Seleccione una ciudad</option>
                                    {ciudadesproyectos.datos === undefined ?
                                        ''
                                        :
                                        ciudadesproyectos.datos.map((supply, i) => (
                                            <option id={supply.id} value={supply.id} name={supply.name}>
                                                {supply.name}
                                            </option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                            <Col md={3} className='mt-4' style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" placeholder="No. identificacion2"
                                    aria-describedby="basic-addon1" type="text" id="idntificacion2" name="idntificacion2"
                                    onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                    </div>
                }
                <Row>
                    {vistapdf === true ?
                        <Col md={12} className='mt-4'>
                            <p style={{ fontSize: '1.2em' }}>
                                <b>Obserevaciones</b>
                            </p>
                            <div style={{ fontSize: '1.2em', height: '5em' }}>
                                {dats.observations}
                            </div>
                        </Col>
                        :
                        <Col md={12} className='mt-4'>
                            <p style={{ fontSize: '1.2em' }}>
                                <b>Obserevaciones</b>
                            </p>
                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                id="observations" name="observations" style={{ fontSize: '1.2em', textAlign: 'justify' }}
                            />
                        </Col>
                    }
                </Row>
                <Row>
                    {vistapdf === true ?
                        <Col md={12} className='mt-4' style={{ fontSize: '1.2em', textAlign: 'justify' }}>
                            Para constancia se firma por las partes, en la ciudad de <b>{ciudad}</b>, el dia ( {dats.dia} )
                            del mes ( {dats.mes} ) de año ( {dats.year} ).
                        </Col>
                        :
                        ''
                    }
                </Row>
                <br /><br /><br />
                <Row>
                    <Col md={5}>
                        __________________________________________ <br />
                        <small className="text-muted">Firma</small> <br />
                        <b>Nombre contratante:</b><br />
                        <b>C.C:</b><br />
                        <b>Razon social contratante:</b>
                    </Col>
                    <Col md={2}>
                    </Col>
                    <Col md={5}>
                        __________________________________________ <br />
                        <small className="text-muted">Firma</small> <br />
                        <b>JORGE SALAZAR ESCOBAR:</b><br />
                        <b>C.C:</b><br />
                        <b>GEOESTRUCTURAS LTDA:</b>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Acta_Nueva;