import React, { useState, Fragment, useEffect } from "react";
import {
    Accordion, Table,
} from 'react-bootstrap';
import {
    DEDALO_ALL_SHOPPING_SUPPLIES, DEDALO_ALL_PERMISOS, DEDALO_ALL_QUOTATION,
    DEDALO_ALL_SALIDA_EQUIPO, DEDALO_ALL_INVENTORY_GENERAL, DEDALO_ALL_REQUISITIONS,
    DEDALO_ALL_MOVE_INVENTORY_GENERAL, DEDALO_ALL_REQ_INVENTORY_GENERAL,
    DEDALO_ALL_VACACIONES, DEDALO_ALL_START_CERTIFICATE,DEDALO_ALL_COMPARATIVO,
    DEDALO_ALL_EVALUACION_PROVEEDOR,DEDALO_ALL_REEVALUACION_PROVEEDOR,
    DEDALO_ALL_SELECCION_PROVEEDOR,DEDALO_ALL_ADVANCES,DEDALO_ALL_LEGALIZATION,
} from "../../assets/APIAdresses";
import { Routes } from '../../utils/routes';
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import { useUserSession } from "../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";

const ResumenData = (data) => {
    const data_emp1 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const data_emp2 = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const { userToken } = useUserSession();
    const { user } = useUserSession();

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        //minimumFractionDigits: 0
    });

    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
    const [data6, setData6] = useState([]);
    const [data7, setData7] = useState([]);
    const [data8, setData8] = useState([]);
    const [data9, setData9] = useState([]);
    const [data10, setData10] = useState([]);
    const [data11, setData11] = useState([]);
    const [data12, setData12] = useState([]);
    const [data13, setData13] = useState([]);
    const [data14, setData14] = useState([]);
    const [data15, setData15] = useState([]);
    const [data16, setData16] = useState([]);

    const peticionGetCategor1 = async () => {
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: '',
            perpage: 10000,
            page: 1,
            orderField: 'date',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_REQUISITIONS,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setData1(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 1");
                }
            });

        return info1;
    };

    const peticionGetCategor2 = async () => {
        const params_data = {
            constructions_id: data_emp2.Id_Project_Conta,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'way_to_pay',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_SHOPPING_SUPPLIES,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice);
                    setData2(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos ordenes");
                }
            });

        return info1;
    };

    const peticionGetCategor3 = async () => {
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_QUOTATION,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setData3(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 2");
                }
            });

        return info1;
    };

    const peticionGetCategor4 = async () => {
        const params_data = {
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "out_date",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_SALIDA_EQUIPO,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setData4(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.3");
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
                }
            });

        return info1;
    };

    const peticionGetCategor5 = async (e) => {
        //e.preventDefault();
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_INVENTORY_GENERAL,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice);
                    setData5(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar el inventario 1");
                }
            });

        return info1;
    };

    const peticionGetCategor6 = async (e) => {
        //e.preventDefault();
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'desc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_MOVE_INVENTORY_GENERAL,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice);
                    setData6(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar el inventario 2");
                }
            });

        return info1;
    };

    const peticionGetCategor7 = async (e) => {
        //e.preventDefault();
        const params_data = {
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'amount',
            order: 'desc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_REQ_INVENTORY_GENERAL,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice);
                    setData7(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar el inventario 4");
                }
            });

        return info1;
    };

    const peticionGetCategor8 = async () => {
        const params_data = {
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'date',
            order: 'desc',
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_VACACIONES,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setData8(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 5");
                }
            });

        return info1;
    };

    const peticionGetCategor9 = async () => {
        const params_data = {
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'date',
            order: 'desc',
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_PERMISOS,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setData9(datanotice === undefined ? [] : datanotice);
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 6");
                }
            });

        return info1;
    };

    const peticionGetCategor10 = async () => {
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "number_certificate",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_START_CERTIFICATE,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setData10(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.5");
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.5");
                }
            });

        return info1;
    };

    const peticionGetCategor11=async()=>{
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "date",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_COMPARATIVO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setData11(datanotice === undefined ? [] : datanotice);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.7");
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.7");
              }
          });
      
        return info1;
    };

    const peticionGetCategor12=async()=>{
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "date",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_EVALUACION_PROVEEDOR,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setData12(datanotice === undefined ? [] : datanotice);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.8");
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.8");
              }
          });
      
        return info1;
    };

    const peticionGetCategor13=async()=>{
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "date",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_REEVALUACION_PROVEEDOR,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setData13(datanotice === undefined ? [] : datanotice);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.9");
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.9");
              }
          });
      
        return info1;
    };

    const peticionGetCategor14=async()=>{
        const params_data = {
            enterprice_id: data_emp1.IdEmp,
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "supplier_type",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_SELECCION_PROVEEDOR,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setData14(datanotice === undefined ? [] : datanotice);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              }
          });
      
        return info1;
    };

    const peticionGetCategor15 = async () => {
        const params_data = {
            enterprice_id: parseInt(data_emp1.IdEmp),
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "number_advances",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_ADVANCES,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setData15(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.10");
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.10");
                }
            });

        return info1;
    };

    const peticionGetCategor16 = async () => {
        const params_data = {
            enterprice_id: parseInt(data_emp1.IdEmp),
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "number_legalization",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_LEGALIZATION,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice);
                    setData16(datanotice === undefined ? [] : datanotice);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.11");
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.11");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetCategor1();
        peticionGetCategor2();
        peticionGetCategor3();
        peticionGetCategor4();
        peticionGetCategor5();
        peticionGetCategor6();
        peticionGetCategor7();
        peticionGetCategor8();
        peticionGetCategor9();
        peticionGetCategor10();
        peticionGetCategor11();
        peticionGetCategor12();
        peticionGetCategor13();
        peticionGetCategor14();
        peticionGetCategor15();
        peticionGetCategor16();
    }, []);

    let hoy = new Date();
    let ayer = new Date(hoy);
    let ayer_fecha = ayer.setDate(hoy.getDate() - 1); 

    const datosFiltrados1 = data1.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados2 = data2.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados3 = data3.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados4 = data4.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados5 = data5.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados6 = data6.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados7 = data7.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados8 = data8.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados9 = data9.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados10 = data10.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados11 = data11.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados12 = data12.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados13 = data13.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados14 = data14.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados15 = data15.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));
    const datosFiltrados16 = data16.filter(dato => new Date(dato.created_at) >= new Date(ayer_fecha));

    //console.log(datosFiltrados8,data8);

    return (
        <div>
            <div className={data.estado ? "d-blok" : "d-none"}>
            {/*<div>*/}
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><b>Requisiciones</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>realizado por</td>
                                            <td>compra realizada</td>
                                            <td>fecha de solcicitud</td>
                                            <td>observaciones</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data1.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados1.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.nameuser}</td>
                                                    <td>{x.isshopping}</td>
                                                    <td>{new Date(x.date).toLocaleDateString()}</td>
                                                    <td>{x.observations}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><b>Ordenes de Compra</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>concepto</td>
                                            <td>proyecto</td>
                                            <td>proveedor</td>
                                            <td>Numero de orden</td>
                                            <td>fecha inicio</td>
                                            <td>fecha final</td>
                                            <td>realizado por</td>
                                            <td>tipo de orden</td>
                                            <td>modo de pago</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data2.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados2.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.concept}</td>
                                                    <td>{x.constructions_name}</td>
                                                    <td>{x.crm_vendor_name}</td>
                                                    <td>{x.number_shopping}</td>
                                                    <td>{new Date(x.date).toLocaleDateString()}</td>
                                                    <td>{new Date(x.dead_date).toLocaleDateString()}</td>
                                                    <td>{x.produced_by}</td>
                                                    <td>{x.type === 'purchase order' ? 'oden de compra' : 'orden de servicio'}</td>
                                                    <td>{x.way_to_pay === 'cash' ? 'efectivo' : x.way_to_pay === 'transfer' ? 'transferencia' : 'deposito'}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><b>Cotizaciones</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>nombre</td>
                                            <td>estado</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data3.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados3.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.name}</td>
                                                    <td>{x.status}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><b>Formatos de salida</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>Generado por</td>
                                            <td>formato No.</td>
                                            <td>fecha generadas</td>
                                            <td>se autoriza a</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data4.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados4.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.nameuser}</td>
                                                    <td>{x.out_number}</td>
                                                    <td>{x.out_date}</td>
                                                    <td>{x.authorized}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><b>Inventario General</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>cantidad</td>
                                            <td>cantidad minima</td>
                                            <td>proveedor</td>
                                            <td>nombre del producto</td>
                                            <td>nombre tipo producto</td>
                                            <td>tipo de unidad</td>
                                            <td>precio</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data5.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados5.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{parseInt(x.amount)}</td>
                                                    <td>{parseInt(x.minimum_amount)}</td>
                                                    <td>{x.crmVendorName}</td>
                                                    <td>{x.name}</td>
                                                    <td>{x.productTypeName}</td>
                                                    <td>{x.productTypemeasurement}</td>
                                                    <td>{formatterPeso.format(parseFloat(x.price))}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><b>Movimiento de inventario</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>cantidad</td>
                                            <td>cantidad en invetario</td>
                                            <td>proveedor</td>
                                            <td>nombre del producto</td>
                                            <td>tipo de producto</td>
                                            <td>precio</td>
                                            <td>tipo</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data6.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados6.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{parseInt(x.amount)}</td>
                                                    <td>{parseInt(x.amountInventory)}</td>
                                                    <td>{x.crmVendorName}</td>
                                                    <td>{x.productTypeName}</td>
                                                    <td>{x.name}</td>
                                                    <td>{formatterPeso.format(parseFloat(x.price))}</td>
                                                    <td>{x.type === 'out' ? 'Salida' : 'Entrada'}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><b>Requerimiento de Inventario</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>proyecto</td>
                                            <td>nombre del producto</td>
                                            <td>cantidad</td>
                                            <td>cantidad en invetario</td>
                                            <td>fecha solcicitud</td>
                                            <td>realizado por</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data7.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados7.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.constructionsname}</td>
                                                    <td>{x.nameproduct}</td>
                                                    <td>{x.amount}</td>
                                                    <td>{x.inventoryamount}</td>
                                                    <td>{new Date(x.date).toLocaleDateString()}</td>
                                                    <td>{x.nameuser}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header><b>Formatos de vacaciones</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>empleado</td>
                                            <td>cargo</td>
                                            <td>dias</td>
                                            <td>fecha solcicitud</td>
                                            <td>fecha salida</td>
                                            <td>fecha entrada</td>
                                            <td>estado</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data8.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados8.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.employee_name}</td>
                                                    <td>{x.position}</td>
                                                    <td>{x.days}</td>
                                                    <td>{x.date}</td>
                                                    <td>{x.startdate}</td>
                                                    <td>{x.endate}</td>
                                                    <td>{x.status === 'pending' ? 'Pendiente' : x.status === 'rejected' ? ' No Aprobado' : 'Aprobado'}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header><b>Formatos de permisos</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>empleado</td>
                                            <td>cargo</td>
                                            <td>area</td>
                                            <td>fecha solcicitud</td>
                                            <td>fecha salida</td>
                                            <td>fecha entrada</td>
                                            <td>estado</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data9.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados9.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.employee_name}</td>
                                                    <td>{x.position}</td>
                                                    <td>{x.department}</td>
                                                    <td>{x.date}</td>
                                                    <td>{x.out_date}</td>
                                                    <td>{x.in_date}</td>
                                                    <td>{x.status === 'pending' ? 'Pendiente' : x.status === 'rejected' ? ' No Aprobado' : 'Aprobado'}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header><b>Actas de Inicio</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>numero de certificado</td>
                                            <td>año certificado</td>
                                            <td>valor</td>
                                            <td>nom construccion</td>
                                            <td>nom constructor</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data10.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados10.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.number_certificate}</td>
                                                    <td>{x.year_certificate}</td>
                                                    <td>{formatterPeso.format(parseFloat(x.value))}</td>
                                                    <td>{x.nameConstruction}</td>
                                                    <td>{x.constructor_name}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header><b>Cuadro Comparativo</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>fecha de cuadro</td>
                                            <td>generado por</td>
                                            <td>No. requisicion</td>
                                            <td>descripcion</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data11.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados11.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{new Date(x.date).toLocaleDateString()}</td>
                                                    <td>{x.nameUser}</td>
                                                    <td>{x.number_requisition}</td>
                                                    <td>{x.desription}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header><b>Evaluacion proveedor</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>fecha cuadro comparativo</td>
                                            <td>generado por</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data12.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados12.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{new Date(x.date).toLocaleDateString()}</td>
                                                    <td>{x.nameUser}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="12">
                        <Accordion.Header><b>Reevaluacion proveedor</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>fecha reevaluacion</td>
                                            <td>generado por</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data13.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados13.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{new Date(x.date).toLocaleDateString()}</td>
                                                    <td>{x.nameUser}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                        <Accordion.Header><b>Seleccion proveedor</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>fecha reevaluacion</td>
                                            <td>generado por</td>
                                            <td>tipo de proveedor</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data14.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados14.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{new Date(x.date).toLocaleDateString()}</td>
                                                    <td>{x.nameUser}</td>
                                                    <td>{x.supplier_type}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                        <Accordion.Header><b>Anticipos generados</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>No. anticipo</td>
                                            <td>generado por</td>
                                            <td>valor anticipo</td>
                                            <td>fecha anticipo</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data15.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados15.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.number_advances}</td>
                                                    <td>{x.nameUser}</td>
                                                    <td>{formatterPeso.format(parseFloat(x.totalvalue))}</td>
                                                    <td>{x.advance_day+"/"+x.advance_month+"/"+x.advance_year}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                        <Accordion.Header><b>Anticipos generados</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>No. anticipo</td>
                                            <td>generado por</td>
                                            <td>valor anticipo</td>
                                            <td>fecha anticipo</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data15.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados15.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.number_advances}</td>
                                                    <td>{x.nameUser}</td>
                                                    <td>{formatterPeso.format(parseFloat(x.totalvalue))}</td>
                                                    <td>{x.advance_day+"/"+x.advance_month+"/"+x.advance_year}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="16">
                        <Accordion.Header><b>legalizaciones generados</b></Accordion.Header>
                        <Accordion.Body>
                            <div style={{ maxHeight: '20rem', overflowY: 'auto' }}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center fw-bolder">
                                            <td>id</td>
                                            <td>departamento</td>
                                            <td>numero de legalizacion</td>
                                            <td>cargo</td>
                                            <td>año anticipo</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data16.length === 0 ?
                                            ''
                                            :
                                            datosFiltrados16.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.department}</td>
                                                    <td>{x.number_legalization}</td>
                                                    <td>{x.position}</td>
                                                    <td>{x.legalization_year}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
}

export default ResumenData;