import React, { useState, Fragment, useEffect } from "react";
import {
    Card, Button, InputGroup, Modal, Accordion, Row, Col,
    Form, Nav, Table, Dropdown, DropdownButton, FormControl,
} from 'react-bootstrap';
import {
    DEDALO_INVENTORY_GENERAL_COMBO, DEDALO_ALL_REQ_INVENTORY_GENERAL,
    DEDALO_REQ_INVENTORY_GENERAL_BY_ID, DEDALO_NEW_REQ_INVENTORY_GENERAL,
    DEDALO_UPDATE_REQ_INVENTORY_GENERAL, DEDALO_DEL_REQ_INVENTORY_GENERAL,
    DEDALO_CRM_ENTERPRICES_COMBO, DEDALO_COMBO_PRODUCTO, DEDALO_COMBO_CONSTRUCTION
} from "../../assets/APIAdresses";
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import { useUserSession } from "../../hooks";
import { Mensaje_Error_Inven_Gene } from '../mensajeserror/mensajeserror';
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../utils/routes';
import Navbar from '../navbar';
import Swal from "sweetalert2";
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const Requerimiento_inventario_general = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { user } = useUserSession();
    const [constructionselect, setConstructionSelect] = useState(0);
    const [inventarioselect, setInventariotSelect] = useState(0);

    const [categor3, setCategor3] = useState({
        datos: [],
    });

    const [categor4, setCategor4] = useState({
        datos: [],
    });

    const [categor5, setCategor5] = useState({
        datos: [],
    });

    const [categor6, setCategor6] = useState({
        datos: [],
    });

    const [categor7, setCategor7] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        amount_req: 0,
        observations_req: '',
        id_req_edit: 0,
        amount_req_edit: 0,
        inventory_general_id_req_edit: 0,
        name_req_edit: '',
        observations_req_edit: '',
        constructions_id_edit: 0,
        constructions_name_edit: 0,
    });

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setShow3(true);
    };

    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = (e) => {
        setShow6(true);
    };

    const peticionGetReqInventario = async (e) => {
        //e.preventDefault();
        const params_data = {
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'amount',
            order: 'desc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_REQ_INVENTORY_GENERAL,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice);
                    setCategor3({
                        ...categor3,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor3({
                        ...categor3,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar el inventario 3");
                }
            });

        return info1;
    };

    const peticionGetEmpresasCombo = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CRM_ENTERPRICES_COMBO,
            headers: { Authorization: userToken },
            data: params_data,
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setCategor4({
                        ...categor4,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor4({
                        ...categor4,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;

    };

    const peticionGetProductosCombo = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_PRODUCTO,
            headers: { Authorization: userToken },
            data: params_data,
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setCategor5({
                        ...categor5,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor5({
                        ...categor5,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;

    };

    const peticionGetInventarioGCombo = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_INVENTORY_GENERAL_COMBO,
            headers: { Authorization: userToken },
            data: params_data,
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setCategor6({
                        ...categor6,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor6({
                        ...categor6,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;

    };

    const peticionGetInvReqById = async (id_edit) => {
        let idgen_edit = parseInt(id_edit);

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_REQ_INVENTORY_GENERAL_BY_ID + '&id=' + idgen_edit,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setDat({
                        ...dats,
                        ['id_req_edit']: datanotice.id,
                        ['amount_req_edit']: datanotice.amount,
                        ['inventory_general_id_req_edit']: parseInt(datanotice.inventory_id),
                        ['observations_req_edit']: datanotice.observations,
                        ['name_req_edit']: datanotice.nameproduct,
                        ['constructions_id_edit']: datanotice.constructionsid,
                        ['constructions_name_edit']: datanotice.constructionsname,
                    });
                    handleShow6();
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                }
            });

        return info1;
    };

    const handleCloseSave3 = (e) => {
        e.preventDefault();
        const formattedData = {
            inventory_general_id: parseInt(inventarioselect),
            user_id: user.id,
            amount: parseInt(dats.amount_req),
            constructions_id: constructionselect === 0 ? null : parseInt(constructionselect),
            observations: dats.observations_req,
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_NEW_REQ_INVENTORY_GENERAL,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se a creado el requerimiento exitósamente", "", "success");
                    peticionGetReqInventario();
                    peticionGetEmpresasCombo();
                    peticionGetProductosCombo();
                    peticionGetInventarioGCombo();
                    handleClose3();
                } else {
                    Mensaje_Error_Inven_Gene(response.data.msg.error);
                }
            })
            .catch(error => {
                manageError(error);
            })
    };

    const handleCloseSave6 = (e) => {
        e.preventDefault();
        const formattedData = {
            inventory_general_id: parseInt(inventarioselect === 0 ? dats.inventory_general_id_req_edit : inventarioselect),
            user_id: user.id,
            amount: parseInt(dats.amount_req_edit),
            constructions_id: constructionselect === 0 ? parseInt(dats.constructions_id_edit) : parseInt(constructionselect),
            observations: dats.observations_req_edit,
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_UPDATE_REQ_INVENTORY_GENERAL + '&id=' + parseInt(dats.id_req_edit),
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se a editado el requerimiento exitósamente", "", "success");
                    peticionGetReqInventario();
                    handleClose6();
                } else {
                    //Mensaje_Error_Inven_Gene(response.data.msg.error);
                    alert(response.data.msg.error);
                }
            })
            .catch(error => {
                manageError(error);
            })
    };

    const peticionDelete3 = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_REQ_INVENTORY_GENERAL + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el movimiento exitósamente", "", "success");
                    //window.location.reload();
                    peticionGetReqInventario();
                } else {
                    alert("ERROR: Server Error");
                }
            })
    };

    const peticionGetConstruccionesCombo = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_CONSTRUCTION,
            headers: { Authorization: userToken },
            data: params_data,
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setCategor7({
                        ...categor7,
                        ['datos']: datanotice,
                    });
                } else {
                    setCategor7({
                        ...categor7,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;

    };

    useEffect(() => {
        peticionGetReqInventario();
        peticionGetEmpresasCombo();
        peticionGetProductosCombo();
        peticionGetInventarioGCombo();
        peticionGetConstruccionesCombo();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const handleInputChange1 = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const handleChangeInventarioSelect = (event) => {
        setInventariotSelect(event.target.value);
    };

    const handleChangeConstructionSelect = (event) => {
        setConstructionSelect(event.target.value);
    };

    return (
        <div>
            <div className="d-flex container-fluid my-2">
                <div>
                    <Button className="btn btn-success rounded-circle" size="sm"
                        onClick={() => { window.history.back() }} title="Regresar"
                    >
                        <MdIcons.MdArrowBackIosNew />
                    </Button>
                </div>
            </div>
            <div className="container">
                <Card style={{ width: '100%' }} className='mt-2'>
                    <Card.Header>
                        <div className="d-flex">
                            <div>
                                Requerimiento de Inventario general
                            </div>
                            <div className="ms-auto">
                                <BsIcons.BsPlusCircle title='Agregar nuevo invetario'
                                    onClick={handleShow3} style={{ cursor: 'pointer', fontSize: '1.3rem' }} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body style={{ height: '40em', maxHeight: '40em', overflowY: 'auto' }}>
                        {categor3.datos === undefined ?
                            <div className="text-center">
                                <h4>En el momento no hay registros</h4>
                            </div>
                        :
                            <Table striped bordered hover>
                                <thead>
                                    <tr className="text-center">
                                        <th>Id</th>
                                        <th>producto</th>
                                        <th>cantidad solicitada</th>
                                        <th>cantidad en inventario</th>
                                        <th>generado por</th>
                                        <th>fecha de solicitud</th>
                                        <th>proyecto</th>
                                        <th>Observaciones</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categor3.datos === undefined ?
                                        ''
                                        :
                                        categor3.datos.map((x, ind) => (
                                            <tr className="text-center">
                                                <td>{ind + 1}</td>
                                                <td>{x.nameproduct}</td>
                                                <td>{parseInt(x.amount)}</td>
                                                <td>{parseInt(x.inventoryamount)}</td>
                                                <td>{x.nameuser}</td>
                                                <td>{x.date}</td>
                                                <td>{x.constructionsname}</td>
                                                <td>{x.observations}</td>
                                                <td className="d-flex justify-content-center align-items-center">
                                                    <div class="dropdown">
                                                        <MdIcons.MdMenuOpen type="button" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                                                            style={{ fontSize: '1.5rem' }}
                                                        />
                                                        <ul class="dropdown-menu dropdown-menu-dark">
                                                            <li>
                                                                <a class="dropdown-item" onClick={() => peticionGetInvReqById(x.id)} style={{ cursor: 'pointer' }}>
                                                                    Editar
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" onClick={() => peticionDelete3(x.id)} style={{ cursor: 'pointer' }}>
                                                                    Eliminar
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        }
                    </Card.Body>
                </Card>
            </div>

            {/* MODAL CREAR REQUERIMIENTO DE INVENTARIO GENERAL */}
            <Modal show={show3} onHide={handleClose3} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Ingresar Requerimiento de inventario general</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChangeInventarioSelect(e)}>
                                    <option>Seleccione un inventario</option>
                                    {categor6.datos === undefined ?
                                        ''
                                        :
                                        categor6.datos.map((x, ind) => (
                                            <option value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Nombre de la carpeta"
                                    aria-describedby="basic-addon1" autoFocus={true} type="text"
                                    onChange={handleInputChange1}
                                    id="amount_req" name="amount_req"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChangeConstructionSelect(e)}>
                                    <option>Seleccione una construccion</option>
                                    {categor7.datos === undefined ?
                                        ''
                                        :
                                        categor7.datos.map((x, ind) => (
                                            <option value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Control
                                    as="textarea" id="observations_req" name="observations_req"
                                    placeholder="Observaciones del requerimiento" onChange={handleInputChange1}
                                    style={{ height: '100px' }}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose3}>
                        Cancelar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={handleCloseSave3}>
                        Ingresar Requerimiento
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR REQUERIMIENTO DE INVENTARIO GENERAL */}
            <Modal show={show6} onHide={handleClose6} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Requerimiento de inventario general</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChangeInventarioSelect(e)}>
                                    <option>seleccionado {dats.name_req_edit}</option>
                                    {categor6.datos === undefined ?
                                        ''
                                        :
                                        categor6.datos.map((x, ind) => (
                                            x.id === dats.inventory_general_id_req_edit && x.name === dats.name_req_edit ?
                                                ''
                                                :
                                                <option value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Nombre de la carpeta"
                                    aria-describedby="basic-addon1" autoFocus={true} type="text"
                                    onChange={handleInputChange1} value={dats.amount_req_edit}
                                    id="amount_req_edit" name="amount_req_edit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={(e) => handleChangeConstructionSelect(e)}>
                                    <option>{dats.constructions_name_edit}</option>
                                    {categor7.datos === undefined ?
                                        ''
                                        :
                                        categor7.datos.map((x, ind) => (
                                            <option value={x.id}>{x.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <Form.Control
                                    as="textarea" id="observations_req_edit" name="observations_req_edit"
                                    placeholder="Observaciones del requerimiento" onChange={handleInputChange1}
                                    style={{ height: '100px' }} value={dats.observations_req_edit}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose6}>
                        Cancelar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={handleCloseSave6}>
                        Ingresar Requerimiento editado
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Requerimiento_inventario_general;