import React, {useState, useEffect, useCallback} from "react";
import ReactFlow, {
  removeElements, isNode,
  addEdge, ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
} from 'react-flow-renderer';
import { 
  Card, Button, Container, Row, Col,InputGroup,
  Table,DropdownButton,Dropdown,Modal,FormControl,
  Form,
} from 'react-bootstrap';
import {
  DEDALO_ORGANIZATION_CHART,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import { Routes } from '../../utils/routes';
import dagre from 'dagre';
import Img1 from '../../imagenes/dedalo_logo.png';
import * as MdIcons from "react-icons/md";

const Organigrama = () => {
  const nodeWidth = 172;
  const nodeHeight = 36;
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const {userToken} = useUserSession();
  const {userId} = useUserSession();
  const {user} = useUserSession();
  
  let {Id_emp} = useParams();
  
  const [categor, setCategor] = useState({
    datos: [],
  });
  
  let info_org = [];
  
  const [info_org1, setInfo_org1] = useState({
    nodes: info_org,
  });
  
  const peticionGetCategor=async()=>{
    const params_data = {
        enterprice_id: Id_emp,
    };
    //let info1 = await axios.get(DEDALO_ALL_CRM_ENTERPRICES+'?page=1&rows=100&order=name&inorder=asc&search=')
    let info1 = await axios({
        method: 'POST',
        url: DEDALO_ORGANIZATION_CHART,
        headers: {Authorization: userToken},
        data: params_data
    })
    .then(response=>{
        if (response.data.code === '200') {
            let datanotice = response.data.data;
            //console.log(datanotice.data);
            setCategor({
                ...categor,
                ['datos']: datanotice,
            });
        } else {
            alert(message_responses[response.data.message]);
        }
    })
    .catch(error => {
        if (error.response && error.response.data) {
            alert(
                error.response.data.message === "VALIDATOR_ERROR"
                ? error.response.data.data.error_message
                : message_responses[error.response.data.message]
            );
        } else {
            alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
        }
    });
  
    return info1;
  };

  useEffect(() => {
    peticionGetCategor();
  }, []);
  
  const edgeType = 'smoothstep';
  const position = { x: 0, y: 0 };

  const getLayoutedElements = (elements, direction = 'TB') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });
  
    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });
  
    dagre.layout(dagreGraph);
  
    return elements.map((el) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? 'left' : 'top';
        el.sourcePosition = isHorizontal ? 'right' : 'bottom';
  
        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - nodeHeight / 2,
        };
      }
  
      return el;
    });
  };

  const layoutedElements = getLayoutedElements(info_org);
  
  const [elements, setElements] = useState(info_org);
  const onConnect = (params) =>{
    setElements((els) =>
      addEdge({ ...params, type: 'smoothstep', animated: true }, els)
    );
  };

  const onElementsRemove = (elementsToRemove) =>{
    setElements((els) => removeElements(elementsToRemove, els));
  };

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction);
      setElements(layoutedElements);
    },
    [elements]
  );

  let data_org = categor.datos.length > 0 && categor.datos;
  let nodo_primario = 0;

  for (let i = 0; i < data_org.length; i++) {
    
    if(data_org[i].parent_id === '' && data_org[i].name === 'Presidencia') {
      nodo_primario = data_org[i].id;
      info_org.push(
        {
          id: `${data_org[i].id}`,
          type: 'input',
          data: { label: data_org[i].name },
          position: { x: 800, y: 100 },
        },
      )
    }else{
      info_org.push(
        {
          id: `${data_org[i].id}`,
          data: { label:
            <div>
              <div className="w-100 text-center">{data_org[i].name}</div>
              <div className="text-start mt-3">
                {
                  data_org[i].Users.map((user) => (
                    <div className="mb-2">
                      <img src={user.image64 === '' ? Img1 : user.image64}
                        class="rounded-circle me-2"
                        alt={user.name} 
                        title={user.name} 
                        className="me-1" 
                        style={{width: '2em', height: '2em'}} 
                      />
                      <span className="text-muted">
                        {user.name}
                      </span>
                    </div>
                  ))
                }
              </div>
            </div>
          },
          position: { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
          style: { width: '18em' },
        },
        { 
          id: `e${data_org[i].id}`, 
          source: data_org[i].parent_id === '' ? `${nodo_primario}` : `${data_org[i].parent_id}`, 
          target: `${data_org[i].id}`, 
          type: edgeType, 
          animated: true 
        },
      )
    }
  };
  
  return (
    <div className="app">
      <div className="d-flex container-fluid d-grid gap-2 d-md-flex mt-3">
          <div>
              <Button className="btn btn-success rounded-circle" size="sm" 
                  onClick={() => {window.history.back()}} title="Regresar"
              >
                  <MdIcons.MdArrowBackIosNew/>
              </Button>
          </div>
          <div className="text-center text-primary w-100 titulo_emp">
              <h2>Organigrama de la empresa </h2>
          </div>
      </div>
      <ReactFlowProvider>
        <ReactFlow
          elements={info_org}
          onConnect={onConnect}
          onElementsRemove={onElementsRemove}
          connectionLineType="smoothstep"
          style={{height: '70vh'}}
        >
          <Controls />
        </ReactFlow>
        {/*<div className="controls">
          <Button onClick={() => onLayout('LR')}>Vista Horizontal</Button>
        </div>*/}
      </ReactFlowProvider>
    </div>
  );
}

export default Organigrama;
