import React, { useState, Fragment, useEffect, useRef } from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, ListGroup, Table, DropdownButton, ButtonGroup, Dropdown,
    Card, Container,
} from 'react-bootstrap';
import {
    DEDALO_NEW_SELECCION_PROVEEDOR,
} from "../../../../assets/APIAdresses";
import { message_responses } from "../../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../../utils/utils";
import { useUserSession } from "../../../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../../../utils/routes';
import { Mensaje_Error_Seleccion_Proveedor } from '../../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import Img1 from '../../imagenes_reportes/logo_geo.png';
import * as MdIcons from "react-icons/md";

const Select_Proveedor_New = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();

    const [dats, setDat] = useState({
        fecha: '',
        proveedor: '',
        valoir_total_comercial: '',
        valoir_total_calidad: '',
        valoir_total_entrega: '',
        valoir_total_sistema: '',
        cumple1: '',
        cumple2: '',
        cumple3: '',
        cumple4: '',
        cumple5: '',
        cumple6: '',
        cumple7: '',
        cumple8: '',
        cumple9: '',
        cumple10: '',
        no_cumple1: '',
        no_cumple2: '',
        no_cumple3: '',
        no_cumple4: '',
        no_cumple5: '',
        no_cumple6: '',
        no_cumple7: '',
        no_cumple8: '',
        no_cumple9: '',
        no_cumple10: '',
    });

    let valildar = {
        business_criteria1: dats.cumple1 === '' ? dats.cumple1 === '' ? '' : 'fails' : 'meets',
        business_criteria2: dats.cumple2 === '' ? dats.cumple2 === '' ? '' : 'fails' : 'meets',
        business_criteria3: dats.cumple3 === '' ? dats.cumple3 === '' ? '' : 'fails' : 'meets',
        business_criteria4: dats.cumple4 === '' ? dats.cumple4 === '' ? '' : 'fails' : 'meets',
        quality_criteria1: dats.cumple5 === '' ? dats.cumple5 === '' ? '' : 'fails' : 'meets',
        quality_criteria2: dats.cumple6 === '' ? dats.cumple6 === '' ? '' : 'fails' : 'meets',
        compliance_criteria1: dats.cumple7 === '' ? dats.cumple7 === '' ? '' : 'fails' : 'meets',
        compliance_criteria2: dats.cumple8 === '' ? dats.cumple8 === '' ? '' : 'fails' : 'meets',
        management_criteria1: dats.cumple9 === '' ? dats.cumple9 === '' ? '' : 'fails' : 'meets',
        management_criteria2: dats.cumple10 === '' ? dats.cumple10 === '' ? '' : 'fails' : 'meets',
    };

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    let dia = new Date().getDate();
    let mes = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    const printContainer = useRef(null);

    const createPDF = async () => {
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;

        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter",
                orientation: 'l'
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `seleccion_proveedor_${fecha}.pdf`
        });
    };

    const campos_blanco = () => {
        document.getElementById('proveedor').value = '';
        document.getElementById('valoir_total_comercial').value = '';
        document.getElementById('valoir_total_calidad').value = '';
        document.getElementById('valoir_total_entrega').value = '';
        document.getElementById('valoir_total_sistema').value = '';
        document.getElementById('cumple1').value = '';
        document.getElementById('cumple2').value = '';
        document.getElementById('cumple3').value = '';
        document.getElementById('cumple4').value = '';
        document.getElementById('cumple5').value = '';
        document.getElementById('cumple6').value = '';
        document.getElementById('cumple7').value = '';
        document.getElementById('cumple8').value = '';
        document.getElementById('cumple9').value = '';
        document.getElementById('cumple10').value = '';
        document.getElementById('no_cumple1').value = '';
        document.getElementById('no_cumple2').value = '';
        document.getElementById('no_cumple3').value = '';
        document.getElementById('no_cumple4').value = '';
        document.getElementById('no_cumple5').value = '';
        document.getElementById('no_cumple6').value = '';
        document.getElementById('no_cumple7').value = '';
        document.getElementById('no_cumple8').value = '';
        document.getElementById('no_cumple9').value = '';
        document.getElementById('no_cumple10').value = '';
    }

    const handleCloseSave = (e) => {
        let valor1 = 'dats.document_number';
        let valor2 = '';

        for (var key in valildar) {
            if (valildar.hasOwnProperty(key)) {
                if (!valildar[key]) {
                    //console.log("vacia la propiedad "+key);
                    valor2 = 'vacio';
                } else {
                    valor2 = '';
                }
            }
        }
        //console.log();
        const formattedData = {
            enterprice_id: data_emp2.IdEmp,
            constructions_id: data_emp.Id_Project_Conta,
            users_id: userId,
            date: `${year}/${mes}/${dia}`,
            supplier_type: dats.proveedor,
            business_value: parseInt(dats.valoir_total_comercial),
            quality_value: parseInt(dats.valoir_total_calidad),
            compliance_value: parseInt(dats.valoir_total_entrega),
            management_value: parseInt(dats.valoir_total_sistema),
            business_criteria1: dats.cumple1 === '' ? dats.cumple1 === '' ? 'fails' : '' : 'meets',
            business_criteria2: dats.cumple2 === '' ? dats.cumple2 === '' ? 'fails' : '' : 'meets',
            business_criteria3: dats.cumple3 === '' ? dats.cumple3 === '' ? 'fails' : '' : 'meets',
            business_criteria4: dats.cumple4 === '' ? dats.cumple4 === '' ? 'fails' : '' : 'meets',
            quality_criteria1: dats.cumple5 === '' ? dats.cumple5 === '' ? 'fails' : '' : 'meets',
            quality_criteria2: dats.cumple6 === '' ? dats.cumple6 === '' ? 'fails' : '' : 'meets',
            compliance_criteria1: dats.cumple7 === '' ? dats.cumple7 === '' ? 'fails' : '' : 'meets',
            compliance_criteria2: dats.cumple8 === '' ? dats.cumple8 === '' ? 'fails' : '' : 'meets',
            management_criteria1: dats.cumple9 === '' ? dats.cumple9 === '' ? 'fails' : '' : 'meets',
            management_criteria2: dats.cumple10 === '' ? dats.cumple10 === '' ? 'fails' : '' : 'meets',
        };
        //console.log(formattedData,dats);
        if (valor2 === 'vacio') {
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible guardar el formato', "info");
        } else {
            axios({
                method: 'POST',
                url: DEDALO_NEW_SELECCION_PROVEEDOR,
                headers: { Authorization: userToken },
                data: formattedData
            })
                .then(response => {
                    //console.log(response);
                    if (response.data.code === '200') {
                        Swal.fire(
                            "!!! Atencion !!!",
                            "se ha guardado la seleccion exitosamente",
                            "success"
                        );
                    } else {
                        Mensaje_Error_Seleccion_Proveedor(response.data.msg.error)
                    }
                })
                .catch(error => {
                    manageError(error);
                });
        }
    };

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371" }}
                            onClick={handleCloseSave}
                        >
                            Guardar seleccion
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm"
                            onClick={() => campos_blanco()} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            limpiar formato
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm"
                            onClick={() => createPDF()} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br />
            <div className="container container-fluid" ref={printContainer} style={{ padding: '1em 1em 1em 1em' }}>
                <Row className="border border-dark mx-1"> {/*  cabecera del formato */}
                    <Col md={2} className="text-center py-1 border-end border-dark">
                        <img src={Img1} className='img-fluid' width={230} />
                    </Col>
                    <Col md={8} className="d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <h3>
                                <b>FORMATO SELECCION DE PROVEEDORES</b>
                            </h3>
                        </div>
                    </Col>
                    <Col md={2} className="text-center py-1 border-start border-dark">
                        <div>
                            Codigo: FO-CP-09-01
                        </div>
                        <div>
                            Fecha: 01/01/2015
                        </div>
                        <div>
                            Pagina 1 de 1
                        </div>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark mx-1">
                    <Col md={12}>
                        <Row className="border-bottom border-dark">
                            <Col md={3} className='text-center border-end border-dark' style={{ background: '#AAB7B8' }}>
                                <b>FECHA</b>
                            </Col>
                            <Col md={9} className='text-center' style={{ background: '#AAB7B8' }}>
                                <b>TIPO DE PROVEEDOR</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className='text-center border-end border-dark'>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" value={`${year}/${mes}/${dia}`} disabled
                                    aria-describedby="basic-addon1" type="text" id="fecha" name="fecha" style={{ background: '#fff', color: 'black' }}
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={9}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion"
                                    aria-describedby="basic-addon1" type="text" id="proveedor" name="proveedor"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark mx-1">
                    <Col md={12}>
                        <div>
                            <Row className='text-center' style={{ background: '#AAB7B8' }}>
                                <Col md={1} className='border-end border-dark border-bottom'>
                                    <span><b>No.</b></span>
                                </Col>
                                <Col md={2} className='border-end border-dark border-bottom'>
                                    <span><b>CRITERIOS DE EVALUACION</b></span>
                                </Col>
                                <Col md={1} className='border-end border-dark border-bottom'>
                                    <span><b>VALOR TOTAL</b></span>
                                </Col>
                                <Col md={6} className='border-end border-dark border-bottom'>
                                    <span><b>DESCRIPCION</b></span>
                                </Col>
                                <Col md={1} className='border-dark border-end border-bottom'>
                                    <span><b>CUMPLE</b></span>
                                </Col>
                                <Col md={1} className='border-dark border-bottom'>
                                    <span><b>NO CUMPLE</b></span>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row className='text-center'>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>1</b></span>
                                </Col>
                                <Col md={2} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>COMERCIAL</b></span>
                                </Col>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <div>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                aria-label="Nombre de la cotizacion" size="sm" name="valoir_total_comercial"
                                                aria-describedby="basic-addon1" type="text" id="valoir_total_comercial"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={8} className='border-dark px-0'>
                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0'>
                                            <div>
                                                <span><b>1</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Proveedor Legalmente constituido y acreditado por la SIC
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-end border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple1" name="cumple1"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple1" name="no_cumple1"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>2</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    El proveedor es fabricante, productor y/o distribuidor autorizado de la marca
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-end border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple2" name="cumple2"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple2" name="no_cumple2"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>3</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Dispone de la infrastura y/o Logistica necesaria (personal calificado, transporte, catalogos, fichas tecnicas,
                                                    certificados de calidad, equipo y/o herramienta) para cumplir el acuerdo comercial pactado
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple3" name="cumple3"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple3" name="no_cumple3"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0'>
                                            <div>
                                                <span><b>4</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Precio Ofrecido Competitivo y Forma de Pago (crédito)
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple4" name="cumple4"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple4" name="no_cumple4"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row className='text-center'>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>2</b></span>
                                </Col>
                                <Col md={2} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>CALIDAD</b></span>
                                </Col>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <div>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                aria-label="Nombre de la cotizacion" size="sm"
                                                aria-describedby="basic-addon1" type="text" id="valoir_total_calidad" name="valoir_total_calidad"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={8} className='border-dark px-0'>
                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>1</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Cuenta con personal técnico calificado para cumplir las actividades propias del servicio
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple5" name="cumple5"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple5" name="no_cumple5"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>2</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    El Insumo cuenta con Ficha Técnica y/o Certificado de Calidad correspondiente
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple6" name="cumple6"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple6" name="no_cumple6"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row className='text-center'>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>3</b></span>
                                </Col>
                                <Col md={2} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>ENTREGA Y CUMPLIMIENTO</b></span>
                                </Col>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <div>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                aria-label="Nombre de la cotizacion" size="sm" name="valoir_total_entrega"
                                                aria-describedby="basic-addon1" type="text" id="valoir_total_entrega"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={8} className='border-dark px-0'>
                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>1</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Dispone de procesos de programación, control y estado de solicitudes
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple7" name="cumple7"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple7" name="no_cumple7"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>2</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Maneja el 100% de la operación del surtido, la distribución, entrega y soporte técnico del insumo
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple8" name="cumple8"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple8" name="no_cumple8"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row className='text-center'>
                                <Col md={1} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <span><b>4</b></span>
                                </Col>
                                <Col md={2} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <span><b>SISTEMA DE GESTION DE CALIDAD</b></span>
                                </Col>
                                <Col md={1} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <div>
                                        <InputGroup size="sm">
                                            <Form.Control
                                                aria-label="Nombre de la cotizacion" size="sm"
                                                aria-describedby="basic-addon1" type="text" id="valoir_total_sistema" name="valoir_total_sistema"
                                                className="border-0 text-center" onChange={handleInputChangeObs}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                                <Col md={8} className='border-dark px-0'>
                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>1</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Cuenta con un SGC ISO 9001 y de Mejora Continua
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple9" name="cumple9"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple9" name="no_cumple9"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>2</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Cuenta con un Sistema de Control y Seguridad Física BASC y/o está en proceso de implementación
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-end border-dark px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="cumple10" name="cumple10"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        <Col md={1} className='border-dark px-0 d-flex justify-content-center align-items-center' style={{ width: '6.6rem' }}>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm"
                                                        aria-describedby="basic-addon1" type="text" id="no_cumple10" name="no_cumple10"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark mx-1">
                    <Col md={6} className="border-end border-dark">
                        <Row className="border-bottom border-dark">
                            <Col md={12}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion"
                                    aria-describedby="basic-addon1" type="text" id="year" name="year"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center" style={{ background: '#AAB7B8' }}>
                                <b>NOMBRE COORDINADOR DE COMPRAS</b>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row className="border-bottom border-dark">
                            <Col md={12}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion"
                                    aria-describedby="basic-addon1" type="text" id="year" name="year"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center" style={{ background: '#AAB7B8' }}>
                                <b>FIRMA COORDINADOR DE COMPRAS</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Select_Proveedor_New;