import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Row, Col, Accordion, Dropdown, InputGroup,
    DropdownButton, Button, Modal, Table, FormControl,
    Form, Container, NavDropdown,
} from 'react-bootstrap';
import {
    DEDALO_ALL_FLOWCHART,DEDALO_FLOWCHART_DUPLICATE,DEDALO_UPDATE_FLOWCHART,
    DEDALO_DEL_FLOWCHART,DEDALO_FLOWCHART_COMBO,DEDALO_FLOWCHART_BY_ID,
    DEDALO_COMBO_CONSTRUCTION,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import { 
    attachToken,
    manageError,
} from "../../../utils/utils";
import {Redirect, useParams, Link} from "react-router-dom";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import { Routes } from '../../../utils/routes';
import VistaFlujograma from './flujograma';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";


const CrudFlujograma = () => {
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession(); 
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const data_flujo_success = JSON.parse(localStorage.getItem('FlujoCreado'));
    let {Id_project} = useParams();
    const [copyflowchart, setCopyFlowchart] = useState('');
    const [idflowchart, setIdFlowchart] = useState('');

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [validaciondatos, setValidacionDatos] = useState({
        datos: [],
    });

    const [comboproyectos, setComboProyectos] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        titulo: '',
        descripcion: '',
    });

    const peticionGetCategor=async(e)=>{
        //e.preventDefault();
        const params_data = {
            constructions_id: Id_project,
            search: '',
            perpage: 200,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS+"?page=1&rows=1000&order=id&inorder=desc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_FLOWCHART,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice.records,
                });
                setValidacionDatos({
                    ...validaciondatos,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                //alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
              }
          });
      
        return info1;
    };

    const peticionGetComboProyectos=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_COMBO_CONSTRUCTION)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_CONSTRUCTION,
            headers: {Authorization: userToken},
            data: params_data,
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setComboProyectos({
                    ...comboproyectos,
                    ['datos']: datanotice,
                });
            } else {
                Swal.fire("No se pudo cargar los datos del combo", "warning");
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetComboProyectos();
    }, []);

    const peticionDelete = (idflowchart) => {
        let delId = idflowchart;
        Swal.fire({
            title: 'Esta apunto de eliminar el flujograma, oprima si para confirmar o cancelar la operacion',
            showDenyButton: true,
            confirmButtonText: 'Si, eliminar',
            denyButtonText: `No, cancelar`,
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                //axios.delete(DEDALO_ELIMINAR_CONSTRUCCION(delId), attachToken(user))
                axios({
                    method: 'DELETE',
                    url: DEDALO_DEL_FLOWCHART+'&id='+delId,
                    headers: {Authorization: userToken},
                })
                .then(response=>{
                    if (response.data.code === '200') {
                        Swal.fire("Se ha eliminado el flujograma exitosamente", "", "success");
                        peticionGetCategor();
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        Swal.fire("No se pudo eliminar el flujograma ya que se encuentra en curso", "", "warning");
                    }
                });
            } else if (result.isDenied) {
                Swal.fire('Se ha cancelado la operacion', '', 'info')
            }
        })
    };

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (e) => {
        setIdFlowchart(e);
        setShow1(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeCopyFlowChart = (event) => {
        setCopyFlowchart(event.target.value);
    };

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };

    const handleCloseSave = () => {
        let titulo = dats.titulo;
        let descripcion = dats.descripcion;
        
        const formattedData = {
            flowchart_id: idflowchart,
            title: titulo,
            creator_id: userId,
            description: descripcion,
            constructions_id: copyflowchart === '' ? '' : parseInt(copyflowchart),
        };
        //console.log(formattedData);
        if(titulo === '' || descripcion === ''){
            Swal.fire("Todos los campos son obligatorios", "", "warning");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_FLOWCHART_DUPLICATE,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se duplico el flujograma exitósamente", "", "success");
                    document.getElementById("titulo").value = '';
                    document.getElementById("descripcion").value = '';
                    document.getElementById("select").value = '';
                    peticionGetCategor();
                    handleClose1();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = categor.datos === undefined ? [] : categor.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = categor.datos === undefined ? [] : categor.datos.length/postPerPage; // numero total de paginas
    let pageNumbers = []; //se guardan los numeros de paginas

    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () =>{
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () =>{
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };
    
    const paginador = () =>{
        return(
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{cursor: 'pointer'}}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };
    
    return(
        <div>
            {validaciondatos.datos.length === 0 ? 
                <VistaFlujograma IDProject={Id_project}/>
            :
                <div>
                    <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start mt-4 mb-4">
                        <div>
                            <Button className="btn btn-success rounded-circle" size="sm" onClick={()=>{window.history.back()}}
                                style={{backgroundColor: "#02B371"}} title="Regresar.">
                                <MdIcons.MdArrowBackIosNew/>
                            </Button>
                        </div>
                        {user.role_id === 3 || user.role_id === 2?
                            <div>
                                <Link to={Routes.FLUJOGRAMA_NUEVO(Id_project)}>
                                    <Button className="btn btn-success" size="sm" style={{backgroundColor: "#02B371", width: '10em'}} 
                                        title="Nuevo Flujograma"
                                    >
                                        Nuevo Flujograma
                                    </Button>
                                </Link>
                            </div>
                        :
                            ''
                        }
                        <div className="text-center d-flex align-items-center justify-content-center w-100">
                            <h4>Flujogramas reailzados {Id_project}</h4>
                        </div>
                    </div>
                    <div>
                        <Container>
                            <Table responsive striped bordered size="md" style={{fontSize: "0.9rem"}} className="tableuser" id="dtBasicExample">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre Flujograma</th>
                                        <th>Descripcion</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody style={{textAlign: 'center'}}>
                                    {categor.datos === undefined ?
                                        ''
                                    :
                                        currentPost.map((item,index) => (
                                            <tr key={index}>
                                                <td>{item.id}</td>
                                                <td>{item.title}</td>
                                                <td>{item.description}</td>
                                                {user.role_id === 3 || user.role_id === 2 ?
                                                    <td>
                                                        <NavDropdown id="collasible-nav-dropdown" className="acciones_usuario">
                                                            <NavDropdown.Item className="editar_usuario">
                                                                <Link to={Routes.FLUJOGRAMA_EDIT(item.id,Id_project)}>
                                                                    Editar
                                                                </Link>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={()=>peticionDelete(item.id)}>
                                                                Eliminar
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={()=>handleShow1(item.id)}>
                                                                Duplicar Flujograma
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                    </td>
                                                :
                                                    parseInt(item.creator_id) === userId ?
                                                        <NavDropdown id="collasible-nav-dropdown" className="acciones_usuario">
                                                            <NavDropdown.Item className="editar_usuario">
                                                                <Link to={Routes.FLUJOGRAMA_EDIT(item.id,Id_project)}>
                                                                    Editar
                                                                </Link>
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={()=>peticionDelete(item.id)}>
                                                                Eliminar
                                                            </NavDropdown.Item>
                                                            <NavDropdown.Item onClick={()=>handleShow1(item.id)}>
                                                                Duplicar Flujograma
                                                            </NavDropdown.Item>
                                                        </NavDropdown>
                                                    :
                                                        <td>
                                                            <NavDropdown id="collasible-nav-dropdown" className="acciones_usuario">
                                                                <NavDropdown.Item className="editar_usuario">
                                                                    <Link to={Routes.FLUJOGRAMA_VIEW(item.id,Id_project)}>
                                                                        Ver Flujograma
                                                                    </Link>
                                                                </NavDropdown.Item>
                                                            </NavDropdown>
                                                        </td>
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            {paginador()}
                        </Container>
                    </div>
                </div>
            }
            {/* MODAL CREAR COPIA DE FLUJOGRAMA */}
            <Modal show={show1} onHide={handleClose1} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Duplicar Flujograma</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdSubtitles/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Titulo de la copia" aria-label="Titulo de la copia"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="titulo" name="titulo"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDescription/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Descripcion de la copia" aria-label="Descripcion de la copia"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="descripcion" name="descripcion"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPeopleFill/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeCopyFlowChart} id="select" name="select"
                                >
                                    <option value="">Seleccione el proyecto</option>
                                    {comboproyectos.datos.map((item,index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose1}>
                        Cerrar
                    </Button>
                    <Button  variant="primary" onClick={handleCloseSave}>
                        Duplicar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CrudFlujograma;