import React, {useState, Fragment, useEffect} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,
} from 'react-bootstrap';
import {
    DEDALO_ALL_SHOPPING_SUPPLIES,DEDALO_DEL_SHOPPING_SUPPLIES,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import Swal from "sweetalert2";
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";

const Requisiciones = () => {
    const data_emp2 = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            constructions_id: data_emp2.Id_Project_Conta,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'way_to_pay',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_SHOPPING_SUPPLIES,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                //alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const peticionDelete = (iduser) => {
        let delId = iduser;

        axios({
            method: 'DELETE',
            url: DEDALO_DEL_SHOPPING_SUPPLIES+'&id='+delId,
            headers: {Authorization: userToken}
        })
        .then(response=>{
          if (response.data.code === '200') {
            Swal.fire("Se elimino el item exitósamente.", "", "success");
            peticionGetCategor();
          } else {
            console.log(response.data.msg.error);
          }
        })
    };

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    {user.role_id === 12 || user.role_id === 3 ?
                        <div>
                            <Link to={Routes.ORD_NUEVA}>
                                <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}>
                                    Generar Orden nueva
                                </Button>
                            </Link>
                        </div>
                    :
                        ''
                    }
                </div>
            </div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-center mb-4">
                <div>
                    <h2 className="text-center">Ordenes de Compra Generadas</h2>
                </div>
            </div>
            <div className="container container-fluid ">
                <Row>
                    {categor.datos === undefined ?
                        <div className="text-center">
                            <p>
                                En el momento no hay ordenes de compra generadas
                            </p>
                        </div>
                    :
                        categor.datos.map((item,index) => (
                            <Col key={index} md={4} xs={12}>
                                <Card className="mb-4">
                                    <Card.Header as="h6" className="with-elements d-flex">
                                        <div className="card-header-title">Ordene de compra # {item.number_shopping}</div>
                                        {user.role_id === 12 || user.role_id === 3 ?
                                            <div className="card-header-elements ms-auto">
                                                <div class="dropdown">
                                                    <MdIcons.MdMoreVert type="button" size={'1.3em'}
                                                        class="dropdown-toggle" data-bs-toggle="dropdown" 
                                                        aria-expanded="false" id="dropdownMenuButton2"
                                                    />
                                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                        <Link to={Routes.ORD_EDIT(item.id)} className='text-decoration-none'>
                                                            <li>
                                                                <a class="dropdown-item cursor-pointer">
                                                                    Editar
                                                                </a>
                                                            </li>
                                                        </Link>
                                                        <li>
                                                            <a class="dropdown-item cursor-pointer" onClick={()=>peticionDelete(item.id)}>
                                                                Eliminar
                                                            </a>
                                                        </li>
                                                        <Link to={Routes.VER_ORD(item.id)} className='text-decoration-none'>
                                                            <li>
                                                                <a class="dropdown-item cursor-pointer">
                                                                    Ver Orden
                                                                </a>
                                                            </li>
                                                        </Link>
                                                    </ul>
                                                </div>
                                            </div>
                                        :
                                            ''
                                        }
                                    </Card.Header>
                                    <Card.Body>
                                        <div >
                                            <div className="d-flex mb-2">
                                                <div className="me-5">
                                                    <b>Fecha de inicio</b>
                                                    <br/>
                                                    <span>{new Date(item.date).toLocaleDateString()}</span>
                                                </div>
                                                <div>
                                                    <b>Fecha de fin</b>
                                                    <br/>
                                                    <span>{new Date(item.dead_date).toLocaleDateString()}</span>
                                                </div>
                                            </div>
                                            <p>
                                                <b>Generado por</b>
                                                <br/>
                                                <span>{item.produced_by}</span>
                                            </p>
                                            <p>
                                                <b>Tipo</b>
                                                <br/>
                                                <span>{item.type === 'service order' ? 'Orden de servicio' : 'Orden de compra'}</span>
                                            </p>
                                            <div className="d-flex mb-2">
                                                <div className="me-5">
                                                    <b>Medio de pago</b>
                                                    <br/>
                                                    <span>
                                                        {item.way_to_pay === 'cash' ?
                                                            'Efectivo'
                                                        :item.way_to_pay === 'transfer' ?
                                                            'Transferencia'
                                                        :
                                                            'Deposito'
                                                        }
                                                    </span>
                                                </div>
                                                <div>
                                                    <b>Numero de pago</b>
                                                    <br/>
                                                    <span>{item.numberpay}</span>
                                                </div>
                                            </div>
                                            <p>
                                                <b>Concepto</b>
                                                <br/>
                                                <div style={{maxHeight: '5em', overflowY: 'auto'}}>
                                                    <p className="obs">
                                                        {item.concept}
                                                    </p>
                                                </div>
                                            </p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </div>

        </div>
    );
};

export default Requisiciones;