import React, {useState, Fragment, useEffect} from "react";
import { 
    Row, Modal, Button, InputGroup, Form, 
    Col, FormControl, Table
} from 'react-bootstrap'
import Select  from 'react-select';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as GrIcons from "react-icons/gr";
import * as BiIcons from "react-icons/bi";

import {
    DEDALO_ALL_USER_COMBO,DEDALO_EDIT_TAREA_CONSTRUCTION,DEDALO_CONSTRUCCION_TASK_BY_ID,
    DEDALO_CONSTRUCCION_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";

function ProyectoEditar(actualizar){
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [prioridad, setPrioridad] = useState('');
    const [tareaestado, setTareaestado] = useState('');
    const [observadores, setObservadores] = useState([]);
    const [responsables, setResponsables] = useState([]);
    const [participantes, setParticipantes] = useState([]);
    const [clearedDate1, handleClearedDateChange1] = useState(null);
    const [clearedDate2, handleClearedDateChange2] = useState(null);
    const [archivobase64, setArchivoBase64] = useState();

    const [dats, setDat] = useState({
        titulo_1: '',
        obs_1: '',
        medio_1: '',
        personal_1: '',
        suministros_1: '',
        pres_1: '',
        pres_exe_1: '',
        descripcion_1: '',
        prioridad_1: '',
        startdate: '',
        deadline: '',
        Responsable: [],
        Observers: [],
        Participants: [],
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [presupuesto, setPresupuesto] = useState({
        datos: [],
    });

    const [tareasbyid, setTareasById] = useState({
        datos: [],
    });

    const [datsfile, setDatFile] = useState({
        ext: '',
        title_file: '',
    });

    const handleChangePrioridad = (event) => {
        setPrioridad(event.target.value);
    };

    const handleChangeEstadoTarea = (event) => {
        setTareaestado(event.target.value);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const convertToBase64 = () => {
        //Read File
        var selectedFile = document.getElementById("pdfup").files;
        let selectedFile1 = document.getElementById("pdfup").value;
        let nom1_archivo = document.getElementById('pdfup').files[0];
        let nom2_archivo = document.getElementById('pdfup');
        let extension = selectedFile1.substring(selectedFile1.lastIndexOf('.'),selectedFile1.length);
        let MAXIMO_TAMANIO_BYTES = 50000000;

        setDatFile({
            ...datsfile,
            ['title_file']: nom1_archivo.name.split('.')[0],
            ['ext']: extension
        });

        
        if(nom1_archivo.size > MAXIMO_TAMANIO_BYTES){
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!",`El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`,"info");
            nom2_archivo.value = "";
            
            setDatFile({
                ...datsfile,
                ['title_file']: '',
                ['ext']: ''
            });
        }else{
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                //var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.split(',')
                    setArchivoBase64(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                // Obtener extensión del archivo
            }
        };
        }

    const peticionGetUserCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetTareasById=async()=>{
        if(actualizar.id_tarea == undefined){
            let pi = 0;
        }else{
            //let info1 = await axios.get(DEDALO_BLUEPRINTS_BY_ID(selectplano))
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_CONSTRUCCION_TASK_BY_ID+'&id='+actualizar.id_tarea,
                headers: {Authorization: userToken},
            })
              .then(response=>{
                if (response.data.code === '200') {
                        let datanotice = response.data.msg;
                        //console.log(datanotice.data);
                        setDat({
                            ...dats,
                            ['titulo_1']: datanotice.data.title,
                            ['obs_1']: datanotice.data.observations,
                            ['medio_1']: datanotice.data.means,
                            ['personal_1']: datanotice.data.personal,
                            ['suministros_1']: datanotice.data.supplies,
                            ['pres_1']: datanotice.data.budget,
                            ['pres_exe_1']: datanotice.data.executed_budget,
                            ['descripcion_1']: datanotice.data.description,
                            ['prioridad_1']: datanotice.data.priority,
                            ['Responsable']: datanotice.data.Responsable,
                            ['Observers']: datanotice.data.Observers,
                            ['Participants']: datanotice.data.Participants,
                            ['startdate']: datanotice.data.startdate,
                            ['deadline']: datanotice.data.deadline,
                        });
                  } else {
                     alert(message_responses[response.data.message]);
                  }
              })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                  }
              });
          
            return info1;
        }
    };

    const peticionGetPresupuesto=()=>{
        //let info1 = await axios.get(DEDALO_CONSTRUCCION_BY_ID+'&id='+id)
        let info1 = axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_BY_ID+'&id='+actualizar.id_tarea,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setPresupuesto({
                    ...presupuesto,
                    ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetUserCombo();
        //peticionGetTareasById();
    }, []);

    useEffect(() => {
        //peticionGetUserCombo();
        peticionGetTareasById();
    }, [actualizar.id_tarea]);

    /*useEffect(() => {
        peticionGetPresupuesto();
    }, [actualizar.id_tarea]);*/

    const selectusuarios = [];
    const resp = [];
    const obser = [];
    const part = [];

    const resp_edit = [];
    const obser_edit = [];
    const part_edit = [];

    const fecha_edit1 = new Date(dats.startdate);
    const fecha_edit2 = new Date(dats.deadline);
    
    categor.datos.map(i => {
        selectusuarios.push({
            value: i.id,
            label: i.name,
        });
    });

    dats.Responsable.map(i => {
        resp.push({
            value: parseInt(i.user_id),
            label: i.nameresponsable,
        });

        resp_edit.push(parseInt(i.user_id));
    });

    dats.Observers.map(i => {
        obser.push({
            value: parseInt(i.user_id),
            label: i.nameobserver,
        });

        obser_edit.push(parseInt(i.user_id));
    });

    dats.Participants.map(i => {
        part.push({
            value: parseInt(i.user_id),
            label: i.nameobserver,
        });

        part_edit.push(parseInt(i.user_id));
    });

    const handlechangeObs = (e) =>{
        setObservadores(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const handlechangeRes = (e) =>{
        setResponsables(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const handlechangePar = (e) =>{
        setParticipantes(Array.isArray(e)?e.map(x=>x.value):[]);
    };
    
    let dia = new Date(clearedDate1).getDate();
    let mes = new Date(clearedDate1).getMonth()+1;
    let año = new Date(clearedDate1).getFullYear();
    let hora = new Date(clearedDate1).getHours();
    let minutos = new Date(clearedDate1).getMinutes();
    let segundos = new Date(clearedDate1).getSeconds();
    let fecha = año+'-'+mes+'-'+dia;

    let fechafinal = fecha+' '+hora+':'+minutos+':'+segundos;

    let dia1 = new Date(clearedDate2).getDate();
    let mes1 = new Date(clearedDate2).getMonth()+1;
    let año1 = new Date(clearedDate2).getFullYear();
    let hora1 = new Date(clearedDate2).getHours();
    let minutos1 = new Date(clearedDate2).getMinutes();
    let segundos1 = new Date(clearedDate2).getSeconds();
    let fecha1 = año1+'-'+mes1+'-'+dia1;

    let fechafinal1 = fecha1+' '+hora1+':'+minutos1+':'+segundos1;

    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let titulo = dats.titulo_1;
        let obs = dats.obs_1;
        let des = dats.descripcion_1;
        let medio = dats.medio_1;
        let personal_tarea = dats.personal_1;
        let sumi = dats.suministros_1;
        let fechalimite = fechafinal;
        let fechainicio = fechafinal1;
        let tarea_prioridad = prioridad === '' ? dats.prioridad_1 : prioridad;
        let tarea_estado = tareaestado;
        let presupuesto = dats.pres_1;
        let presupuesto_exe = dats.pres_exe_1;
        let observador_tarea = observadores.length === 0 ? obser_edit : observadores;
        let responsable_tarea = responsables.length === 0 ? resp_edit : responsables;
        let participante_tarea = participantes.length === 0 ? part_edit : participantes;
        
        const formattedData = {
            constructions_id: datahomi.idProject,
            creator_id: userId,
            startdate: fechainicio,
            deadline: fechalimite,
            title: titulo,
            description: des,
            observations: obs,
            budget: presupuesto,
            means: medio,
            personal: personal_tarea,
            supplies: sumi,
            executed_budget: presupuesto_exe,
            priority: tarea_prioridad,
            status: tarea_estado,
            responsable: responsable_tarea,
            observers: observador_tarea,
            participants: participante_tarea,
            type: datsfile.ext.split('.')[1],
            file: archivobase64,
        };
        //console.log(formattedData);
        axios({
            method: 'PUT',
            url: DEDALO_EDIT_TAREA_CONSTRUCTION+'&id='+actualizar.id_tarea,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se edito la tarea exitósamente", "", "success");
                //peticionGetPresupuesto();
                actualizar.actualizar();
                actualizar.tareas();
                handleClose();
            } else {
                alert("ERROR: Server Error");
            }
        })
        //.catch(error => {
        //    manageError(error);
        //})
    };

    

    return (
        <div>
            <div onClick={handleShow}>
                Editar
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdSubtitles />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Titulo tarea" aria-label="Titulo tarea"
                                    aria-describedby="basic-addon1" value={dats.titulo_1}
                                    type="email" onChange={handleInputChange}
                                    id="titulo_1" name="titulo_1"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSpeakerNotes/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Observaciones" aria-label="Observaciones"
                                    aria-describedby="basic-addon1" value={dats.obs_1}
                                    type="text" onChange={handleInputChange}
                                    id="obs_1" name="obs_1"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFileMedical />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Medio" aria-label="Medio"
                                    aria-describedby="basic-addon1" value={dats.medio_1}
                                    type="email" onChange={handleInputChange}
                                    id="medio_1" name="medio_1"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillFilePersonFill/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Personal que lo conforma" aria-label="Personal que lo conforma"
                                    aria-describedby="basic-addon1" value={dats.personal_1}
                                    type="text" onChange={handleInputChange}
                                    id="personal_1" name="personal_1"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <GrIcons.GrStatusCritical/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangePrioridad} id="select_1" name="select_1"
                                >
                                    <option value="">Prioridad de la tarea</option>
                                    <option value='high'>Alto</option>
                                    <option value='medium'>Medio</option>
                                    <option value='low'>Bajo</option>
                                </Form.Select>
                            </InputGroup>    
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BiIcons.BiStats/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeEstadoTarea} id="select_tarea_1" name="select_tarea_1"
                                >
                                    <option value="">Estado de la tarea</option>
                                    <option value='pending'>pendiente</option>
                                    <option value='in process'>en progreso</option>
                                    <option value='under review'>bajo revisión</option>
                                    <option value='completed'>Completado</option>
                                    <option value='closed'>Cerrada</option>
                                    <option value='canceled'>Cancelada</option>
                                    <option value='reopened'>Reabierta</option>
                                </Form.Select>
                            </InputGroup> 
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAttachMoney/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Presupuesto" aria-label="Presupuesto"
                                    aria-describedby="basic-addon1" value={dats.pres_1}
                                    type="text" onChange={handleInputChange}
                                    id="pres_1" name="pres_1"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdMoneyOffCsred/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Presupuesto Ejecutado" aria-label="Presupuesto Ejecutado"
                                    aria-describedby="basic-addon1" value={dats.pres_exe_1}
                                    type="text" onChange={handleInputChange}
                                    id="pres_exe_1" name="pres_exe_1"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdConstruction/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Suministros" aria-label="Suministros"
                                    aria-describedby="basic-addon1" value={dats.suministros_1}
                                    type="email" onChange={handleInputChange}
                                    id="suministros_1" name="suministros_1"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <Form.Label htmlFor="basic-url">Fecha Inicial</Form.Label>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1" className="me-2">
                                    <BiIcons.BiCalendarAlt/>
                                </InputGroup.Text>
                                <DateTimePicker value={clearedDate1 === null ? fecha_edit1 : clearedDate1} onChange={handleClearedDateChange1}/>
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <Form.Label htmlFor="basic-url">Fecha Final</Form.Label>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1" className="me-2">
                                    <BiIcons.BiCalendarAlt/>
                                </InputGroup.Text>
                                <DateTimePicker value={clearedDate2 === null ? fecha_edit2 : clearedDate2} onChange={handleClearedDateChange2}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <Form.Label htmlFor="basic-url">Observadores</Form.Label>
                                <Select 
                                    isMulti
                                    options={selectusuarios}
                                    className="w-100"
                                    onChange={handlechangeObs}
                                    defaultValue={obser}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <Form.Label htmlFor="basic-url">Responsables</Form.Label>
                                <Select 
                                    isMulti
                                    options={selectusuarios}
                                    onChange={handlechangeRes}
                                    className="w-100"
                                    defaultValue={resp}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <Form.Label htmlFor="basic-url">Participantes</Form.Label>
                                <Select
                                    isMulti
                                    options={selectusuarios}
                                    onChange={handlechangePar}
                                    className="w-100"
                                    defaultValue={part}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <label style={{fontSize: 14,fontWeight: 'bold'}}>Seleccione un archivo</label>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    type="file" onChange={()=>convertToBase64()}
                                    id="pdfup" name="pdfup"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="textareatask">
                                <InputGroup.Text>
                                    <CgIcons.CgNotes/>
                                </InputGroup.Text>
                                <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChange} value={dats.descripcion_1}
                                    placeholder="Descripcion de la tarea" id="descripcion_1" name="descripcion_1"/>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    {actualizar.estado === 'canceled' || actualizar.estado === 'closed' ?
                        <Button variant="primary" disabled>
                            Editar Tarea
                        </Button>
                    :
                        <Button variant="primary" onClick={handleCloseSave}>
                            Editar Tarea
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default ProyectoEditar;