import React, {useState, useEffect} from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { 
    Container,Accordion,InputGroup,FormControl,Modal,Button,
    Row,Col,
} from 'react-bootstrap';
import Navbar from '../navbar';
import {
    DEDALO_CONSTRUCCION_TASK_BY_ID,DEDALO_CONSTRUCCION_SUBTASK_BY_ID,
    DEDALO_ALL_TASK_COMMENTS_RESP,DEDALO_ALL_TASK_COMMENTS,DEDALO_TASK_COMMENTS,
    DEDALO_TASK_COMMENTS_RESP,DEDALO_ALL_SUBTASK_COMMENTS,DEDALO_ALL_CONSTRUCTIONS_SUBTASK,
    DEDALO_SUBTASK_RESP,DEDALO_SUBTASK_COMMENTS,DEDALO_SUBTASK_COMMENTS_RESP
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {Redirect, useParams} from "react-router-dom";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import LogoDedalo from '../../imagenes/dedalo_logo.png';
import * as BiIcons from 'react-icons/bi';
import * as MdIcons from 'react-icons/md';

function LineaDeTiempo(){
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const {Idtask} = useParams();
    const [rescomment,setResComment] = useState(0);
    const [subrescomment,setSubResComment] = useState(0);
    const [subtaskcomment,setSubTaskComment] = useState(0);
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (idcomment) => {
        peticionGetCommentTaskById(idcomment);
        setShow(true);
    };

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (idcomment_res) => {
        setResComment(idcomment_res);
        setShow1(true);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (idcomment_subres) => {
        setSubResComment(idcomment_subres);
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (idcomment_subres) => {
        peticionGetAllCommentSubTask(idcomment_subres);
        setShow3(true);
    };

    const [dats, setDat] = useState({
        comentariopri: '',
        comentario_res: '',
        comentario_subres: '',
        subcomentariopri: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [task, setTask] = useState({
        datos: [],
    });

    const [allcommentsubtask, setAllCommentSubTask] = useState({
        datos: [],
    });

    const [subtask, setSubTask] = useState({
        datos: [],
    });

    const [categor1, setCategor1] = useState({
        datos: [],
    });

    const [subtaskdata, setSubTaskData] = useState({
        datos: [],
    });

    const [subtaskalldata, setSubTaskAllData] = useState({
        datos: [],
    });

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetTaskById=async()=>{
        //let info1 = await axios.get(DEDALO_CONSTRUCCION_TASK_BY_ID(Idtask))
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_TASK_BY_ID+'&id='+Idtask,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setCategor({
                ...categor,
                ['datos']: datanotice,
                });
            } else {
                alert('message_responses[response.data.message]');
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert('message_responses[response.data.message]');
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetSubTaskById=async()=>{
        //let info1 = await axios.get(DEDALO_CONSTRUCCION_SUBTASK_BY_ID(Idtask))
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_SUBTASK_BY_ID+'&id='+Idtask,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setSubTask({
                    ...subtask,
                    ['datos']: datanotice,
                });
            } else {
                setSubTask({
                    ...subtask,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert('message_responses[response.data.message]2');
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetCommentTaskById=async(commentsId)=>{
        if(commentsId === undefined){
            let oi = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_TASK_COMMENTS_RESP+'?construction_task_comments_id='+commentsId)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_TASK_COMMENTS_RESP+'&construction_task_comments_id='+commentsId,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    setTask({
                    ...task,
                    ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
                } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const peticionGetAllComments=async()=>{
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'percentage_advance',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_TASK_COMMENTS+'?constructions_task_id='+Idtask)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_TASK_COMMENTS+'&constructions_task_id='+Idtask,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                setCategor1({
                ...categor1,
                ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };
    
    const peticionGetAllCommentsSubtask=async()=>{
        //let info1 = await axios.get(DEDALO_ALL_SUBTASK_COMMENTS+'?constructions_subtask_id='+subtaskcomment)
        if(subtaskcomment > 0){
            const params_data = {
                constructions_id: id,
                search: '',
                perpage: 100,
                page: 1,
                orderField: 'percentage_advance',
                order: 'asc',
            };        
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_SUBTASK_COMMENTS+'&constructions_subtask_id='+subtaskcomment,
                headers: {Authorization: userToken},
                data: params_data
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    console.log(datanotice);
                    setSubTaskData({
                    ...subtaskdata,
                    ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };
    
    const peticionGetAllSubTaskData=async()=>{
        const params_data = {
            constructions_task_id: Idtask,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CONSTRUCTIONS_SUBTASK+'?constructions_task_id='+Idtask+'&page=1&rows=1000&order=id&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CONSTRUCTIONS_SUBTASK,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.length);
                if(datanotice.length === 0){
                    setSubTaskAllData({
                        ...subtaskalldata,
                        ['datos']: [],
                    });
                }else{
                    if(datanotice.records.length > 0){
                        setSubTaskAllData({
                            ...subtaskalldata,
                            ['datos']: datanotice.records,
                        });
                    }
                }
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };
    
    const peticionGetAllCommentSubTask=async(subcommentsId)=>{
        if(subcommentsId === undefined){
            let po = 0;
        }else{
            //let info1 = await axios.get(DEDALO_SUBTASK_COMMENTS_RESP+'?construction_subtask_comments_id='+subcommentsId)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_SUBTASK_COMMENTS_RESP+'&construction_subtask_comments_id='+subcommentsId,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    console.log(datanotice);
                    setAllCommentSubTask({
                    ...allcommentsubtask,
                    ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
                } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    useEffect(() => {
        peticionGetTaskById();
        peticionGetSubTaskById();
        peticionGetAllComments();
        peticionGetCommentTaskById();
        peticionGetAllSubTaskData();
        peticionGetAllCommentSubTask();
    }, []);

    useEffect(() => {
        peticionGetAllCommentsSubtask();
    }, [subtaskcomment]);

    let responsablestarea = categor.datos.Responsable;
    let observadorestarea = categor.datos.Observers;
    let participantestarea = categor.datos.Participants;
    let responses_comment = task.datos;
    let sub_responses_comment = allcommentsubtask.datos;

    const fecha_comentario = (datadate) =>{
        let fecha_comentarios = new Date(datadate);
        let dia_comentario = fecha_comentarios.getDate();
        let mes_comentario = fecha_comentarios.getMonth()+1;
        let año_comentario = fecha_comentarios.getFullYear();
        let fecha_res = dia_comentario+'/'+mes_comentario+'/'+año_comentario;

        return fecha_res;
    };
    
    const fechas_respuestas = (datadate) =>{
        let fecha_respuesta = new Date(datadate);
        let dia_respuesta = fecha_respuesta.getDate();
        let mes_respuesta = fecha_respuesta.getMonth()+1;
        let año_respuesta = fecha_respuesta.getFullYear();
        let fecha_res = dia_respuesta+'/'+mes_respuesta+'/'+año_respuesta;

        return fecha_res;
    };

    const handleCloseSave = (e) => {

        let titulo = dats.comentariopri;
        
        const formattedData = {
            constructions_task_id: Idtask,
            creator_id: userId,
            comment: titulo,
            type: '',
            title: '',
            file: '',
        };
        //console.log(formattedData);
        //axios.post(DEDALO_TASK_COMMENTS, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_TASK_COMMENTS,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Comentario enviado", "", "success");
                document.getElementById('comentariopri').value = '';
                peticionGetAllComments();
            } else {
                alert("ERROR: Server Error");
            }
        })
        //.catch(error => {
        //    manageError(error);
        //});
    };

    const handleCloseSaveResp = (e) => {

        let titulo_res = dats.comentario_res;
        
        const formattedData = {
            construction_task_comments_id: rescomment,
            creator_id: userId,
            response: titulo_res,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_TASK_COMMENTS_RESP, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_TASK_COMMENTS_RESP,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Comentario enviado", "", "success");
                document.getElementById('comentario_res').value = '';
                handleClose1();
            } else {
                alert("ERROR: Server Error");
            }
        })
        //.catch(error => {
        //    manageError(error);
        //})
    };

    const handleCloseSaveSubResp = (e) => {

        let titulo_subres = dats.comentario_subres;
        
        const formattedData = {
            constructions_id: id,
            construction_subtask_comments_id: subrescomment,
            creator_id: userId,
            response: titulo_subres,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_SUBTASK_RESP, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_SUBTASK_RESP,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Comentario enviado", "", "success");
                document.getElementById('comentario_subres').value = '';
                handleClose2();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const handleCloseSaveSubRespPri = (e) => {

        let titulo_subres = dats.subcomentariopri;
        
        const formattedData = {
            constructions_id: id,
            constructions_subtask_id: subtaskcomment,
            creator_id: userId,
            comment: titulo_subres,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_SUBTASK_COMMENTS, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_SUBTASK_COMMENTS,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Comentario enviado", "", "success");
                document.getElementById('subcomentariopri').value = '';
                peticionGetAllCommentsSubtask();
            } else {
                alert("ERROR: Server Error");
            }
        })
        //.catch(error => {
        //    manageError(error);
        //})
    };

    let responsablessubtarea = [];
    let observadoressubtarea = [];
    let participantessubtarea = [];

    subtaskalldata.datos.map((sub) => {
        responsablessubtarea.push({id: sub.id, resp: sub.Responsable});
        observadoressubtarea.push({id: sub.id, resp: sub.Observers});
        participantessubtarea.push({id: sub.id, resp: sub.Participants});
    });
    
    
    categor1.datos.sort((fecha_1,fecha_2) => fecha_2.idcomment - fecha_1.idcomment);
    subtaskdata.datos.sort((com_1,com_2) => com_2.idcomment - com_1.idcomment);
    
    return (
        <div>
            {/*<Navbar modulo="Tareas - linea de tiempo" usuario={user.name}/>*/}
            <br/>
            <div>
                <Button className="btn-back ml-5" onClick={() => {window.history.back()}}>
                    Regresar
                </Button>
            </div>
            <br/>
            <Container className="w-100 p-3 mb-4" style={{borderRadius: "4em", backgroundColor: "#aed6f1"}}>
                <VerticalTimeline>
                    {/* inicio de la tarea */}
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date={<b style={{color: "black"}}>{categor.datos.nameCreator}</b>}
                        iconStyle={
                            {
                                backgroundImage: `url(${categor.datos.imageCreator === '' ? LogoDedalo : categor.datos.imageCreator})`,
                                backgroundSize: "cover",
                                //backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }
                        }
                    >
                        <h3 className="vertical-timeline-element-title">Poryecto {categor.datos.construction}</h3>
                        <h4 className="vertical-timeline-element-subtitle">Tarea {categor.datos.title}</h4>
                        <br/>
                        <div >
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Datos de la Tarea</Accordion.Header>
                                    <Accordion.Body style={
                                            {
                                                color: "black", height: "13em", minHeight: "13em", 
                                                maxHeight: "13em",overflow: "scroll", overflowX: "hidden",
                                            }
                                        }
                                    >
                                        <div>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Descripcion de la Tarea: {categor.datos.description}
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Observaciones: {categor.datos.description}
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Prioridad de la Tarea: {
                                                    categor.datos.priority === 'high' ?
                                                        'Alto'
                                                    :categor.datos.priority === 'medium' ?
                                                        'Medio'
                                                    :categor.datos.priority === 'low' ?
                                                        'Bajo'
                                                    :
                                                        ''
                                                }
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Estado de la Tarea: {
                                                    categor.datos.statusTask === 'pending' ? 
                                                        'Pendiente'
                                                    :categor.datos.statusTask === 'in process' ? 
                                                        'En Progreso'
                                                    :categor.datos.statusTask === 'under review' ? 
                                                        'Bajo Revision'
                                                    :categor.datos.statusTask === 'completed' ?
                                                        'Completada'
                                                    :categor.datos.statusTask === 'closed' ?
                                                        'Cerrada'
                                                    :categor.datos.statusTask === 'canceled' ?
                                                        'Cancelada'
                                                    :categor.datos.statusTask === 'reopened' ?
                                                        'Reabierta'
                                                    :
                                                        ''
                                                }
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Suministros de la Tarea: {categor.datos.supplies}
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Personal de la Tarea: {categor.datos.personal}
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Medio de la Tarea: {categor.datos.means}
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Fecha limite de la Tarea: {categor.datos.deadline}
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            
                            <Accordion>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Comentarios de la Tarea</Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            <InputGroup className="mb-3">
                                                <FormControl
                                                    placeholder="Comentar Tarea" id="comentariopri"
                                                    aria-label="Comentar Tarea" name="comentariopri"
                                                    aria-describedby="basic-addon1" onChange={handleInputChange}
                                                />
                                                <Button variant="success" onClick={handleCloseSave} title="Enviar Comentario">
                                                    <MdIcons.MdSend/>
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div style={
                                                {
                                                    color: "black", height: "13em", minHeight: "13em", 
                                                    maxHeight: "13em",overflowY: "auto"
                                                }
                                            }
                                        >
                                            {categor1.datos.map(r => (
                                                <div class="d-flex justify-content-between mb-2">
                                                    <div class="d-flex justify-content-between mb-2" key={r.idcomment}>
                                                        <div>
                                                            <img src={r.image === '' ? LogoDedalo : r.image} className="me-2" 
                                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                        </div>
                                                        <div style={{fontSize: "0.8em"}}>
                                                            <b style={{color: "#d35400"}}>
                                                                {r.creatorName}
                                                            </b> - {fecha_comentario(r.date)}
                                                            <br/>
                                                            {r.comment}
                                                            <br/>
                                                            <a href="#" onClick={()=>handleShow1(r.id)} className="text-decoration-none me-2">
                                                                Comentar
                                                            </a>
                                                            <a href="#" onClick={()=>handleShow(r.id)} className="text-decoration-none me-2">
                                                                Ver Comentarios 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion >
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Otros Datos</Accordion.Header>
                                    <Accordion.Body style={
                                            {
                                                color: "black", height: "13em", minHeight: "13em", 
                                                maxHeight: "13em",overflow: "scroll", overflowX: "hidden",
                                            }
                                        }
                                    >
                                        <b>Responsables</b>
                                        {responsablestarea === undefined ?
                                            ''
                                        :responsablestarea.length === 0 ?
                                            <p className="text-center">No hay Responsables</p>
                                        :
                                            responsablestarea.map(i => (
                                                <p>
                                                    <img src={i.imageCreator === '' ? LogoDedalo : i.imageCreator} className="me-2" 
                                                        style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                    <b>{i.nameresponsable}</b>
                                                </p>
                                            ))
                                        }
                                        <br/>
                                        <b>Observadores</b>
                                        {observadorestarea === undefined ?
                                            ''
                                        :observadorestarea.length === 0 ?
                                            <p className="text-center">No hay Observadores</p>
                                        :
                                            observadorestarea.map(x => (
                                                <p>
                                                    <img src={x.imageCreator === '' ? LogoDedalo : x.imageCreator} className="me-2" 
                                                        style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                    <b>{x.nameobserver}</b>
                                                </p>
                                            ))
                                        }
                                        <br/>
                                        <b>Participantes</b>
                                        {participantestarea === undefined ?
                                            ''
                                        :participantestarea.length === 0 ?
                                            <p className="text-center">No hay Participantes</p>
                                        :
                                            participantestarea.map(w => (
                                                <p>
                                                    <img src={w.imageCreator === '' ? LogoDedalo : w.imageCreator} className="me-2" 
                                                        style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                    <b>{w.nameobserver}</b>
                                                </p>
                                            ))
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        
                        
                    </VerticalTimelineElement>

                    {/* inicio de la subtarea */}
                    {subtaskalldata.datos.map(z => (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date={<b style={{color: "black"}}>{z.creator_id}</b>}
                            iconStyle={
                                {
                                    backgroundImage: `url(${LogoDedalo})`,
                                    backgroundSize: "cover",
                                    //backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }
                            }responsablessubtarea
                        >
                            <h3 className="vertical-timeline-element-title">Proyecto {z.constructions_id}</h3>
                            <h4 className="vertical-timeline-element-subtitle">SubTarea {z.title}</h4>
                            <br/>
                            <Accordion flush>
                                <Accordion.Item eventKey={z.id}>
                                    <Accordion.Header>Datos de la SubTarea</Accordion.Header>
                                    <Accordion.Body style={
                                            {
                                                color: "black", height: "13em", minHeight: "13em", 
                                                maxHeight: "13em",overflow: "scroll", overflowX: "hidden",
                                            }
                                        }
                                    >
                                        <div>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Descripcion de la SubTarea: {z.description}
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Observaciones: {z.observations}
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Prioridad de la SubTarea: {
                                                    z.priority === 'high' ?
                                                        'Alto'
                                                    :z.priority === 'medium' ?
                                                        'Medio'
                                                    :z.priority === 'low' ?
                                                        'Bajo'
                                                    :
                                                        ''
                                                }
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Estado de la SubTarea: {
                                                    z.ststus === 'pending' ? 
                                                        'Pendiente'
                                                    :z.status === 'in process' ? 
                                                        'En Progreso'
                                                    :z.status === 'under review' ? 
                                                        'Bajo Revision'
                                                    :z.status === 'completed' ?
                                                        'Completada'
                                                    :z.status === 'closed' ?
                                                        'Cerrada'
                                                    :z.status === 'canceled' ?
                                                        'Cancelada'
                                                    :z.status === 'reopened' ?
                                                        'Reabierta'
                                                    :
                                                        ''
                                                }
                                            </p>
                                            <p style={{fontSize: "0.88rem"}}>
                                                Fecha limite de la SubTarea: {z.deadline}
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion flush>
                                <Accordion.Item eventKey={z.id} onClick={()=>{setSubTaskComment(z.id)}}>
                                    <Accordion.Header>Comentarios de la SubTarea</Accordion.Header>
                                    <Accordion.Body >
                                        <div>
                                            <InputGroup className="mb-3">
                                                <FormControl
                                                    placeholder="Comentar Tarea" id="subcomentariopri"
                                                    aria-label="Comentar Tarea" name="subcomentariopri"
                                                    aria-describedby="basic-addon1" onChange={handleInputChange}
                                                />
                                                <Button variant="success" onClick={handleCloseSaveSubRespPri} title="Enviar Comentario">
                                                    <MdIcons.MdSend/>
                                                </Button>
                                            </InputGroup>
                                        </div>
                                        <div style={
                                                {
                                                    color: "black", height: "13em", minHeight: "13em", 
                                                    maxHeight: "13em",overflowY: "auto"
                                                }
                                            }
                                        >
                                            {subtaskdata.datos.map(r => (
                                                <div class="d-flex justify-content-between mb-2">
                                                    <div class="d-flex justify-content-between mb-2" key={r.idcomment}>
                                                        <div>
                                                            <img src={r.image === '' ? LogoDedalo : r.image} className="me-2" 
                                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                        </div>
                                                        <div style={{fontSize: "0.8em"}}>
                                                            <b style={{color: "#d35400"}}>
                                                                {r.creatorName}
                                                            </b> - {fecha_comentario(r.date)}
                                                            <br/>
                                                            {r.comment}
                                                            <br/>
                                                            <a href="#" onClick={()=>handleShow2(r.id)} className="text-decoration-none me-2">
                                                                Comentar
                                                            </a>
                                                            <a href="#" onClick={()=>handleShow3(r.id)} className="text-decoration-none">
                                                                Ver Respuestas 
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            
                            <Accordion flush>
                                <Accordion.Item eventKey={categor.datos.id}>
                                    <Accordion.Header>Otros Datos</Accordion.Header>
                                    <Accordion.Body style={
                                            {
                                                color: "black", height: "13em", minHeight: "13em", 
                                                maxHeight: "13em",overflow: "scroll", overflowX: "hidden",
                                            }
                                        }
                                    >
                                        <b>Responsables</b>
                                        {responsablessubtarea.map(respsub => (
                                            respsub.id === z.id ?
                                                respsub.resp.length === 0 ?
                                                    <p className="text-center">No hay responsables</p>
                                                :
                                                    respsub.resp.map(respsub1 => (
                                                        <p>
                                                            <img src={respsub1.imageCreator === '' ? LogoDedalo : respsub1.imageCreator} className="me-2" 
                                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                            <b>{respsub1.nameresponsable}</b>
                                                        </p>
                                                    ))
                                            :
                                                ''
                                        ))}
                                        <br/>
                                        <b>Observadores</b>
                                        {observadoressubtarea.map(obssub => (
                                            obssub.id === z.id ?
                                                obssub.resp.length === 0 ?
                                                    <p className="text-center">No hay Observadores</p>
                                                :
                                                    obssub.resp.map(obssub1 => (
                                                        <p>
                                                            <img src={obssub1.imageCreator === '' ? LogoDedalo : obssub1.imageCreator} className="me-2" 
                                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                            <b>{obssub1.nameobserver}</b>
                                                        </p>
                                                    ))
                                            :
                                                ''
                                        ))}
                                        <br/>
                                        <b>Participantes</b>
                                        {participantessubtarea.map(parsub => (
                                            parsub.id === z.id ?
                                                parsub.resp.length === 0 ?
                                                    <p className="text-center">No hay Participantes</p>
                                                :
                                                    parsub.resp.map(parsub1 => (
                                                        <p>
                                                            <img src={parsub1.imageCreator === '' ? LogoDedalo : parsub1.imageCreator} className="me-2" 
                                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                            <b>{parsub1.nameobserver}</b>
                                                        </p>
                                                    ))
                                            :
                                                ''
                                        ))}   
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </VerticalTimelineElement>
                    ))}

                </VerticalTimeline>
                
                {/** MODAL VER COMENTARIOS TAREA  **/}
                <Modal show={show} onHide={handleClose} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>Respuestas del Comentario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            {responses_comment === undefined ?
                                ''
                            :responses_comment.length === 0 ?
                                <div className="text-center">
                                    <h5>No hay respuestas</h5>
                                </div>
                            :
                                responses_comment.map(item => (
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="d-flex justify-content-between mb-2">
                                            <div>
                                                <img src={item.image === '' ? LogoDedalo : item.image} className="me-2" 
                                                    style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                            </div>
                                            <div style={{fontSize: "0.8em"}}>
                                                <b style={{color: "#229954"}}>
                                                    {item.creatorName}
                                                </b> - {fechas_respuestas(item.date)}
                                                <br/>
                                                {item.response}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                    </Modal.Body>
                </Modal>

                {/** MODAL VER COMENTARIOS SUBTAREA  **/}
                <Modal show={show3} onHide={handleClose3} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>Respuestas del Comentario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            {sub_responses_comment === undefined ?
                                ''
                            :sub_responses_comment.length === 0 ?
                                <div className="text-center">
                                    <h5>No hay respuestas</h5>
                                </div>
                            :
                                sub_responses_comment.map(item => (
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="d-flex justify-content-between mb-2">
                                            <div>
                                                <img src={item.image === '' ? LogoDedalo : item.image} className="me-2" 
                                                    style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                            </div>
                                            <div style={{fontSize: "0.8em"}}>
                                                <b style={{color: "#229954"}}>
                                                    {item.creatorName}
                                                </b> - {fechas_respuestas(item.date)}
                                                <br/>
                                                {item.response}
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                    </Modal.Body>
                </Modal>

                {/** MODAL COMENTAR TAREA **/}
                <Modal show={show1} onHide={handleClose1} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>Respuestas del Comentario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                <MdIcons.MdOutlineQuestionAnswer/>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Responder Comentario" id="comentario_res"
                                aria-label="Responder Comentario" name="comentario_res"
                                aria-describedby="basic-addon1" onChange={handleInputChange}
                                as="textarea"ne rows="3" style={{resize: "none"}}
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose1}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={handleCloseSaveResp}>
                            Responder
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/** MODAL COMENTAR SUBTAREA **/}
                <Modal show={show2} onHide={handleClose2} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>Respuestas del Comentario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                                <MdIcons.MdOutlineQuestionAnswer/>
                            </InputGroup.Text>
                            <FormControl
                                placeholder="Responder Comentario Subtarea" id="comentario_subres"
                                aria-label="Responder Comentario Subtarea" name="comentario_subres"
                                aria-describedby="basic-addon1" onChange={handleInputChange}
                                as="textarea"ne rows="3" style={{resize: "none"}}
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose2}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={handleCloseSaveSubResp}>
                            Responder
                        </Button>
                    </Modal.Footer>
                </Modal>

            </Container>
            
        </div>
    )
}

export default LineaDeTiempo
