const Routes = {
  HOME: "/home",
  LOGIN: "/",
  USUARIO_NUEVO: "/usuario_nuevo",
  USUARIO_ADMIN: "/admin_crear",
  USUARIO_SOPORTE: "/soporte_crear",
  USUARIO_PLANOS: "/planos_crear",
  USUARIO_ALMACEN: "/almacen_crear",
  TABLA_USUARIO: "/tabla_usuarios",
  RESET_PASS: "/reset_password",
  RESET_PASS_LOGIN: "/reestablecer_contraseña",
  SET_PASS: "/set-password",
  EDITAR_USUARIO: id => "/editar_usuario/"+id,
  USUARIO_NUEVO_SISTEMAS: "/crear_usuario_nuevo",
  PERFIL_DEDALO: "/Dedalo_perfil",
  PROYECTOS_DEDALO: "/Proyectos_Dedalo",
  PROYECTO_HOMI: "/Proyectos",
  PROYECTO_NUEVO: "/Proyecto_Nuevo",
  EDITAR_CUADRILLA: id => "/Editar_Cuadrilla/"+id,
  LINEA_DE_TIEMPO: id => "/Linea_de_Tiempo/"+id,
  SUBTAREAS: id => "/Subtareas/"+id,
  NUEVA_SUBTAREA: id => "/Nueva_Subtareas/"+id,
  EDITAR_SUBTAREA: "/Editar_Subtarea",
  TABLA_CORTES: "/Info_cortes",
  CRM_EMPRESAS: "/Crm_Empresas",
  REQUERIMIENTOS: "/Requerimientos",
  PRODUCTOS: "/Productos",
  PROVEEDORES: "/Proveedores",
  COMPARTIR_ARCHIVOS: "/Compartir_Archivos",
  CONTENIDO_CARPETA: (id,name_crp) => "/Contenido_Carpeta/"+id+"/"+name_crp,
  MOVIMIENTO_INVENTARIO: "/Movimiento_inventario",
  INFORME: "/Informe",
  INFORME_PDF: "/Informe_pdf",
  REPORTE: "/Reporte",
  REPORTE_PDF: "/Reporte_pdf",
  LICENCIA_EMPRESA: "/Licencia_Empresa",
  COMENTARIOS_TAREAS: id => "/Comentarios_Tareas/"+id,
  REPORTE_TAREAS_DIAGRAMA: "/Diagrama_tareas/",
  REUNION_NUEVA: "/Nueva_reunion",
  EDIT_REUNION: id => "/Editar_Acta/"+id,
  AGREGAR_TAREA_NUEVA: "/Nueva_tarea",
  EDITAR_TAREA: id => "/Editar_tarea/"+id,
  DEP_EMP: id => "/Dep_empresas/"+id,
  VER_ORGANIGRAMA: id => "/Ver_Organigrama/"+id,
  FLUJOGRAMA_CRUD: id => "/Crud_Flujograma/"+id,
  FLUJOGRAMA_NUEVO: id => "/Nuevo_Flujograma/"+id,
  FLUJOGRAMA_EDIT: (idp,id) => "/Editar_Flujograma/"+idp+"/"+id,
  FLUJOGRAMA_VIEW: (idp,id) => "/Ver_Flujograma/"+idp+"/"+id,
  CRONOGRAMA_PROYECT: id => "/Cronograma_proyect/"+id,
  EDITAR_PROYECTO: id => "/Proyecto_Editar/"+id,
  MENU_CONTABILIDAD: id => "/Menu_Contabilidad/"+id,
  REQ: "/Requisiciones",
  REQ_NUEVA: "/Requisicion_nueva",
  REQ_EDIT: id => "/Requisicion_edit/"+id,
  REQ_PREVIEW: id => "/Requisicion_Preview/"+id,
  ORD: "/Ordenes_Compra",
  ORD_NUEVA: "/Orden_nueva",
  ORD_EDIT: id => "/Orden_editar/"+id,
  VER_ORD: id => "/Ver_Orden/"+id,
  CENTRO_COSTOS: "/Centro_Costos",
  COT_LICITACIONES: "/Licitaciones",
  COT_NUEVA: "/Licitaciones_nueva",
  COT_EDIT: id => "/Licitaciones_editar/"+id,
  ACTAS: "/Actas",
  ACTA_NUEVA: "/Acta_nueva",
  ACTA_EDITAR: id => "/Acta_editar/"+id,
  ANTICIPOS: "/Anticipos",
  ANT_NUEVA: "/Anticipos_nueva",
  ANT_EDITAR: id => "/Anticipos_editar/"+id,
  LEGALIZACION_ANT: "/Legalizacion_Ant",
  LEGALIZACION_ANT_EDIT: id => "/Legalizacion_Ant_Edit/"+id,
  FICHA_TECNICA: id => "/Ficha_tecnica/"+id,
  ELEMENTOS_NO_CONFORMES: "/Elementos_no_conformes",
  ELEMENTOS_NO_CONFORMES_NEW: "/Elementos_no_conformes_new",
  ELEMENTOS_NO_CONFORMES_EDIT: id => "/Elementos_no_conformes_edit/"+id,
  CUMPLIMIENTO_CRONO: "/Cumplimiento_cronograma",
  CUMPLIMIENTO_CRONO_NEW: "/Cumplimiento_crono_new",
  CUMPLIMIENTO_CRONO_EDIT: id => "/Cumplimiento_crono_edit/"+id,
  PERFIL_CARGO: "/Perfil_Cargo",
  PERFIL_CARGO_NUEVO: "/Perfil_Cargo_new",
  PERFIL_CARGO_EDIT: id => "/Perfil_Cargo_edit/"+id,
  MENU_FORMATOS_PROVEEDORES: '/Menu_formatos',
  CUADRO_COMPARATIVO: "/Cuadro_Comparativo",
  CUADRO_COMPARATIVO_NEW: "/Cuadro_Comparativo_New",
  CUADRO_COMPARATIVO_EDIT: id => "/Cuadro_Comparativo_Edit/"+id,
  EVALUACION_PROVEEDOR: "/Evaluacion_Proveedor",
  EVALUACION_PROVEEDOR_NEW: "/Evaluacion_Proveedor_New",
  EVALUACION_PROVEEDOR_EDIT: id => "/Evaluacion_Proveedor_Edit/"+id,
  REEVALUACION_PROVEEDOR: "/Reevaluacion_Proveedor",
  REEVALUACION_PROVEEDOR_NEW: "/Reevaluacion_Proveedor_New",
  REEVALUACION_PROVEEDOR_EDIT: id => "/Reevaluacion_Proveedor_Edit/"+id,
  SELECT_PROVEEDOR: "/Select_Proveedor",
  SELECT_PROVEEDOR_NEW: "/Select_Proveedor_New",
  SELECT_PROVEEDOR_EDIT: id => "/Select_Proveedor_Edit/"+id,
  FORMATO_SALIDA: "/Formato_Salida",
  FORMATO_SALIDA_NEW: "/Formato_Salida_New",
  FORMATO_SALIDA_EDIT: id => "/Formato_Salida_Edit/"+id,
  FORMULARIOS_ZPAZIUM: "/Formularios_Zpazium",
  FORMULARIOS_ZPAZIUM_NEW: "/Formularios_Zpazium_New",
  FORMULARIOS_ZPAZIUM_EDIT: id => "/Formularios_Zpazium_Edit/"+id,
  INVENTARIO_GENERAL: "/Inventario_General",
  MOVE_INVENTARIO_GENERAL: "/Move_Inventario_General",
  REQ_INVENTARIO_GENERAL: "/Req_Inventario_General",
  SISTEMA_GESTION_CALIDAD: id => "/Sistema_Gestion/"+id,
  FORMATO_VACACIONES: "/Formato_Vacaciones",
  FORMATO_PERMISO: "/Formato_Permiso",
  FORMATO_PERMISO_EDIT: id => "/Formato_Permiso_Edit/"+id,
  FORMATO_PERMISO_LISTA: "/Formatos_Permisos_lista",
  FORMATO_VACACIONES_EDIT: id => "/Formato_Vacaciones_Edit/"+id,
  FORMATO_VACACIONES_LISTA: "/Formatos_Vacaciones_lista",

  FORMATO_SEGUIMIENTO: id => "/Formato_Seguimiento/"+id,
  FORMATO_SEGUIMIENTO_NEW: "/Formato_Seguimiento_New",
  FORMATO_SEGUIMIENTO_EDIT: id => "/Formato_Seguimiento_Edit/"+id,
};

module.exports = {Routes};
