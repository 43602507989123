import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,
} from 'react-bootstrap';
import {
    DEDALO_NEW_LEGALIZATION,DEDALO_CIUDADES,DEDALO_UPDATE_LEGALIZATION,
    DEDALO_LEGALIZATION_BY_ID,DEDALO_CRM_ENTERPRICES_COMBO,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import {Mensaje_Error_Legalizacion} from '../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import html2PDF from "jspdf-html2canvas";
import Img1 from '../imagenes_reportes/logo2.png';
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";

const Legalizaciones_Ant = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [ciudadid, setCiudadId] = useState(0);
    const [aumento, setAumento] = useState(0);
    const [vistapdf, serVistaPdf] = useState(false);
    const [documento, setDocumento] = useState('');

    let {IdLegAntEdit} = useParams();

    const [ciudadesproyectos, setCiudadesProyectos] = useState({
        datos: [],
    });

    const [rows, setRows] = useState([]);

    const [dats, setDat] = useState({
        responsable: user.name,
        cargo: '',
        departamento: '',
        num_legalizacion: '',
        body_data: '',
        ciudad_id: '',
        legal_dia: '',
        legal_mes: '',
        legal_ano: '',
        tipo_documento: '',
        num_documento: '',
        empresa_nom: '',
        empresa_id: '',
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CRM_ENTERPRICES_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setCiudadesProyectos({
                ...ciudadesproyectos,
                ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetRequisicionById=async()=>{
        let useredit = parseInt(IdLegAntEdit);

        if(useredit === undefined){
            let ry = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_LEGALIZATION_BY_ID+'&id='+useredit,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    let d = [];
                    //console.log(datanotice);
                    setDat({
                        ...dats,
                        ['num_legalizacion']: datanotice.observations,
                        ['body_data']: datanotice.body,
                        ['num_legalizacion']: datanotice.number_legalization,
                        ['ciudad_id']: datanotice.city_id,
                        ['legal_dia']: datanotice.legalization_day,
                        ['legal_mes']: datanotice.legalization_month,
                        ['legal_ano']: datanotice.legalization_year,
                        ['tipo_documento']: datanotice.document_type,
                        ['num_documento']: datanotice.document_number,
                        ['departamento']: datanotice.department,
                        ['cargo']: datanotice.position,
                        ['empresa_nom']: datanotice.enterprices_name,
                        ['empresa_id']: datanotice.city_id,
                    });
                    
                    datanotice.body.map((item, index) => {
                        d.push({
                            id: item.id,
                            beneficiario: item.beneficiary,
                            nit: item.nit,
                            concepto: item.concept,
                            destinacion: item.destination,
                            total: item.utility,
                            legal_id: item.legalization_id,
                        });
                    });
                    setRows(d);
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                  alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                      ? error.response.data.data.error_message
                      : message_responses[error.response.data.message]
                  );
                } else {
                  alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
            return info1;
        }
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetRequisicionById();
    }, []);

    const printContainer = useRef(null);

    const createPDF = async () => {
        let year = new Date().getFullYear();
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;
    
        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter",
                orientation: 'l'
            },
            margin: {
                top: 5,
                right: 5,
                bottom: 2,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `legalizacion_anticipo_${fecha}.pdf`
        });
    };

    const agregarFila = () =>{
        setAumento(aumento+1);
        setRows([
            ...rows,
            {
                id: rows.length + 0, beneficiario: "",
                nit: "", concepto: '', destinacion: "", total: ''
            },
        ]);
    };

    const eliminarFila = (i) => {
        const list = [...rows];
        list.splice(i, 1);
        setRows(list);
    };

    const handleChangePDF = (e) =>{
        let ocultar1 = document.getElementById('eliminar1');

        if(e.target.checked === false){
            ocultar1.style.display = 'block';
        }else{
            ocultar1.style.display = 'none';
        }
        serVistaPdf(e.target.checked);
    };

    const handleInputChangeCity = (e) =>{
        setCiudadId(parseInt(e.target.value));
    };

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChange = (e, index) =>{      
        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        setRows(list);
    };

    const handleInputChangeDocumento = (e) =>{
        setDocumento(e.target.value);
    };

    let sumall = rows.map(item => parseInt(item.total === '' ? 0 : item.total)).reduce((prev, curr) => prev + curr, 0);
    
    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    let beneficio = [];
    let nit = [];
    let concepto = [];
    let destino = [];
    let totales = [];

    rows.map((row, index) => {
        beneficio.push(row.beneficiario);
        nit.push(parseInt(row.nit));
        concepto.push(row.concepto);
        destino.push(row.destinacion);
        totales.push(row.total);
    });
    
    const handleCloseSave = (e) => {
        let valor1 = dats.document_number;
        let valor2 = dats.valor_letras;

        const formattedData = {
            enterprice_id: data_emp.IdEmp,
            users_id: userId,
            city_id: parseInt(ciudadid === 0 ? dats.empresa_id : ciudadid),
            number_legalization: dats.num_legalizacion,
            legalization_day: new Date().getDate(),
            legalization_month: new Date().getMonth()+1,
            legalization_year: new Date().getFullYear(),
            document_type: documento === '' ? dats.tipo_documento : documento,
            document_number: dats.num_documento,
            department: dats.departamento,
            position: dats.cargo,
            beneficiary: beneficio,
            nit: nit,
            concept: concepto,
            destination: destino,
            utility: totales,
        };
        console.log(formattedData);
        if(valor2 === ''){
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible guardar la legalizacion', "info");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_LEGALIZATION+'&id='+parseInt(IdLegAntEdit),
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire("Se ha editado la legalizacion exitósamente", "", "success");
                    peticionGetRequisicionById();
                }else {
                    Mensaje_Error_Legalizacion(response.data.msg.error)
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    let ciudad_nom = ciudadesproyectos.datos.filter(x => x.id === parseInt(dats.ciudad_id));
    
    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371", color: '#fff'}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371", color: '#fff'}}
                            onClick={handleCloseSave}
                        >
                            Guardar Legalizacion Anticipo
                        </Button>
                    </div>
                    {vistapdf === true ?
                        <div>
                            <Button className="btn btn-success me-2" size="sm" 
                                onClick={() => createPDF()} title="Regresar" style={{backgroundColor: "#02B371", color: '#fff'}}
                            >
                                descargar PDF
                            </Button>
                        </div>
                    :
                        ''
                    }
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371", color: '#fff'}}
                            onClick={agregarFila} title="Agregar Item"
                        >
                            Agregar Item
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="w-100 vista_pdf">
                    <Form>
                        <Form.Check 
                            type="switch"
                            id="custom-switch"
                            label="Vista de pdf"
                            onChange={handleChangePDF}
                        />
                    </Form>
                </div>
            </div>
            <div style={{padding: '1em 1em 1em 1em',width: '88em'}} ref={printContainer} className="container-fluid">
                <div className="border border-dark container-fluid">
                    <Row>
                        <Col md={2}>
                            <img src={Img1} className='img-fluid'/>
                            <b>
                                <span className="text-primary pl-2">
                                    <small>Nit: 800.028.676-2</small>
                                </span>
                            </b>
                        </Col>
                        <Col md={8} className='d-flex justify-content-center align-items-center'>
                            <h3>LEGALIZACION ANTICIPO</h3>
                        </Col>
                        <Col md={2} className="d-flex justify-items-center align-items-center">
                            <div className="me-2">
                                <b>
                                    <span>No.</span>
                                </b>
                            </div>
                            <div>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={(e) =>handleInputChangeObs(e)}
                                    aria-describedby="basic-addon1" type="number" size="sm"
                                    id="num_legalizacion" name="num_legalizacion"
                                    style={{width: '5em'}} value={dats.num_legalizacion}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className="d-flex mt-3">
                            <div className="d-flex justify-content-center align-items-center">
                                <small>
                                    <b>CENTRO COSTOS/PROYECTOS</b>
                                </small>
                            </div>
                            <div className="ms-auto d-flex justify-items-center align-items-center">
                                <Form.Select
                                    aria-label="Default select example"
                                    id={"select_ciudad"} name="select_ciudad"
                                    className="border-0" onChange={handleInputChangeCity}
                                >
                                    <option>
                                        {dats.empresa_nom}
                                    </option>
                                    {ciudadesproyectos.datos.map((x,index) => (
                                        <option key={index} value={x.id}>{x.name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col md={4}></Col>
                        <Col md={3}>
                            <div>
                                <Row>
                                    <Col md={12} className='text-center border-start border-bottom border-top border-dark'
                                        style={{backgroundColor: '#95a5a6'}}
                                    >
                                        <b>
                                            <span>FECHA DE ELABORACION</span>
                                        </b>
                                    </Col>
                                </Row>
                                <Row className='text-center'>
                                    <Col md={4} className='border-bottom border-end border-start border-dark'>
                                        <small>
                                            <b>DD</b>
                                        </small>
                                    </Col>
                                    <Col md={4} className='border-bottom border-end border-dark'>
                                        <small>
                                            <b>MM</b>
                                        </small>
                                    </Col>
                                    <Col md={4} className='border-bottom border-dark'>
                                        <small>
                                            <b>AAAA</b>
                                        </small>
                                    </Col>
                                </Row>
                                <Row className='text-center'>
                                    <Col md={4} className='border-bottom border-end border-start border-dark'>
                                        <b>
                                            <small>{dats.legal_dia}</small>
                                        </b>
                                    </Col>
                                    <Col md={4} className='border-bottom border-end border-dark'>
                                        <b>
                                            <small>{dats.legal_mes}</small>
                                        </b>
                                    </Col>
                                    <Col md={4} className='border-bottom border-dark'>
                                        <b>
                                            <small>{dats.legal_ano}</small>
                                        </b>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row style={{backgroundColor: '#95a5a6'}}>
                        <Col md={12}>
                            <b>INFORMACION GENERAL</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className="d-flex py-1">
                            <div className="d-flex justify-content-center align-items-center">
                                <small>
                                    <b>RESPONSABLE:</b>
                                </small>
                            </div>
                            <div className="ms-auto">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={handleInputChangeObs}
                                    aria-describedby="basic-addon1" type="text" value={dats.responsable}
                                    id="responsable" name="responsable" size="sm"
                                />
                            </div>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5} className="d-flex py-1">
                            <div className="d-flex justify-content-center align-items-center me-2">
                                <small>
                                    <b>Tipo documento:</b>
                                </small>
                            </div>
                            <div className="d-flex justify-content-center align-items-center me-2">
                                <Form.Select
                                    aria-label="Default select example" onChange={(e) => handleInputChangeDocumento(e)}
                                    id={"select_documento"} name="select_documento" size="sm"
                                    className="border-0 text-center" style={{width: '15em'}}
                                >
                                    <option>{dats.tipo_documento}</option>
                                    <option value='CC'>Cedulda Ciudadania</option>
                                    <option value='CE'>Cedula Extranjeria</option>
                                    <option value='PP'>Pasaporte</option>
                                    <option value='PPT'>Permiso Proteccion Temporal</option>
                                </Form.Select>
                            </div>
                            <div>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" className="text-center" size="sm"
                                    aria-describedby="basic-addon1" type="text"  onChange={(e) =>handleInputChangeObs(e)}
                                    id="num_documento" name="num_documento" style={{width: '10em'}} value={dats.num_documento}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className="d-flex py-1">
                            <div className="d-flex justify-content-center align-items-center">
                                <small>
                                    <b>CARGO:</b>
                                </small>
                            </div>
                            <div className="ms-auto">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={(e) =>handleInputChangeObs(e)}
                                    aria-describedby="basic-addon1" type="text" value={dats.cargo}
                                    id="cargo" name="cargo" size="sm"
                                />
                            </div>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={5} className="d-flex py-1">
                            <div className="d-flex justify-content-center align-items-center me-4">
                                <small>
                                    <b>Numero documento:</b>
                                </small>
                            </div>
                            <div>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" className="text-center" size="sm"
                                    aria-describedby="basic-addon1" type="text" disabled
                                    style={{width: '10rem'}} value={dats.num_documento}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} className="d-flex py-1">
                            <div className="d-flex justify-content-center align-items-center">
                                <small>
                                    <b>DEPARTAMENTO:</b>
                                </small>
                            </div>
                            <div className="ms-auto">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" onChange={(e) =>handleInputChangeObs(e)}
                                    aria-describedby="basic-addon1" type="text" value={dats.departamento}
                                    id="departamento" name="departamento" size="sm"
                                />
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row style={{backgroundColor: '#95a5a6'}}>
                        <Col md={12}>
                            <b>INFORMACION LEGALIZACION</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}> {/*  tabla de informacion */}
                            <div className="my-2">
                                <Table responsive id="tabla_req" name="tabla_req">
                                    <thead className="text-center">
                                        <tr>
                                            <th>Beneficiario</th>
                                            <th>Nit o C.C</th>
                                            <th>Concepto</th>
                                            <th>Destinacion/Item</th>
                                            <th colSpan={2}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows === undefined ?
                                            ''
                                        :
                                            rows.map((row, i) =>(
                                                <tr key={i}>
                                                    <td style={{width: '18em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="beneficiario" name="beneficiario"
                                                            value={row.beneficiario} onChange={(e) => handleInputChange(e, i)}
                                                            style={{marginRight: '0', textAlign: 'center'}} size="sm"
                                                        />
                                                    </td>
                                                    <td style={{width: '8em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="nit" name="nit" size="sm"
                                                            value={row.nit} onChange={(e) => handleInputChange(e, i)}
                                                            style={{marginRight: '0', textAlign: 'center'}}
                                                        />
                                                    </td>
                                                    <td style={{width: '30em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="concepto" name="concepto"
                                                            value={row.concepto} style={{marginRight: '0'}} onChange={(e) => handleInputChange(e, i)}
                                                            className="text-center" size="sm"
                                                        />
                                                    </td>
                                                    <td style={{width: '10em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="destinacion" name="destinacion"
                                                            value={row.destinacion} style={{marginRight: '0'}} onChange={(e) => handleInputChange(e, i)}
                                                            className="text-center" size="sm"
                                                        />
                                                    </td>
                                                    <td style={{width: '7em'}}>
                                                        <Form.Control
                                                                type="text" placeholder="total" name="total" size="sm"
                                                                value={row.total} onChange={(e) => handleInputChange(e, i)}
                                                                style={{marginRight: '0', textAlign: 'center'}}
                                                            />
                                                    </td>
                                                    <td id="eliminar" name="eliminar" style={{ width: '2em' }}>
                                                        <div className="text-danger text-center" id="eliminar1">
                                                            <MdIcons.MdDeleteForever 
                                                                style={{fontSize: '1.6em',cursor: 'pointer'}}
                                                                onClick={()=>eliminarFila(i)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr className="text-center">
                                            <td colSpan={4}>Valor total</td>
                                            <td colSpan={2}>{formatterPeso.format(sumall === '' ? 0 : sumall)}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <b/>
                    <Row style={{backgroundColor: '#95a5a6'}}>
                        <Col md={12}>
                            <b>FIRMAS</b>
                        </Col>
                    </Row>
                    <Row className="py-3">
                        <Col md={4} className='text-center'>
                            <div>
                                <div>
                                    <b>
                                        <span>FIRMA EMPLEADO</span>
                                    </b>
                                </div>
                                <br/>
                                <div>
                                    <span>
                                        ______________________________________
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className='text-center'>
                            <div>
                                <div>
                                    <b>
                                        <span>FIRMA AUTORIZADO</span>
                                    </b>
                                </div>
                                <br/>
                                <div>
                                    <span>
                                        ______________________________________
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className='text-center'>
                            <div>
                                <div>
                                    <b>
                                        <span>FIRMA CONTABILIDAD</span>
                                    </b>
                                </div>
                                <br/>
                                <div>
                                    <span>
                                        ______________________________________
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={4} className='border-dark border-top border-end'></Col>
                        <Col md={5} className='border-dark border-top border-end'>
                            <Row >
                                <Col md={8} className='border-dark border-bottom border-end'>
                                    <span className="fw-bolder">No. de documento</span>
                                </Col>
                                <Col md={4} className='border-dark border-bottom'>
                                    <span className="fw-bolder">Codigo Calidad</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div></div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={1} className='border-dark border-top border-end'>
                            <Row>
                                <Col md={12} className='border-dark border-bottom'>
                                    <span className="fw-bolder">Elaboro</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <span className="fw-bolder">Aprobado</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} className='border-dark border-top'>
                            <Row>
                                <Col md={12} className='border-dark border-bottom text-end'>
                                    <span className="text-primary fw-bolder">Patricia Cortez</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className='text-end'>
                                    <span className="text-primary fw-bolder">Jorge Salazar E</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <br/>
        </div>
    );
}

export default Legalizaciones_Ant;