import React, {useState, Fragment, useEffect} from "react";
import { 
    Row, Modal, Button, DropdownButton, Dropdown, 
    Col, FormControl, Table, InputGroup, Form,
} from 'react-bootstrap';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";

import {
    DEDALO_UPDATE_INVENTORY_MOVE,DEDALO_ALL_INVENTORY_MOVE,
    DEDALO_INVENTORY_MOVE_BY_ID,DEDALO_DEL_INVENTORY_MOVEMENT,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../../hooks";
import { Routes } from '../../../utils/routes';
import Swal from "sweetalert2";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";

function MovimientoInventario(actualizar){
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const idProject = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {userToken} = useUserSession();
    const [productoselect, setProductoSelect] = useState('');
    const [inventarios, setInventarios] = useState();
    const [idmovimiento, setIdMovimiento] = useState();
    const [fechaseleccionada, setFechaSeleccionada] = useState(new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        monto: '',
        obs: '',
        tipo_mov: '',
    });

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (e,i) => {
        setInventarios(e);
        setIdMovimiento(i);
        setShow1(true);
    };

    const handleChangeEstadoTarea = (event) => {
        setProductoSelect(event.target.value);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetAllMove=async()=>{
        const params_data = {
            constructions_id: idProject,
            search: '',
            perpage: 100,
            page: 1,
        };
        //let info1 = await axios.get(DEDALO_ALL_INVENTORY_MOVE+'?page=1&rows=100&constructions_id='+idProject+'&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_INVENTORY_MOVE,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.records);
                if(datanotice.records.length > 0){
                    setCategor({
                        ...categor,
                        ['datos']: datanotice.records,
                    });
                }
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                //alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                Swal.fire({
                    icon: 'info',
                    title: 'Atencion',
                    text: 'En el momento no hay movimientos de inventario registrados',
                });
            }
        });
      
        return info1;
    };

    const peticionGetTaskById=async()=>{
        if(idmovimiento === undefined){
            let yt = 0;
        }else{
            //let info1 = await axios.get(DEDALO_CONSTRUCCION_TASK_BY_ID(Idtask))
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_INVENTORY_MOVE_BY_ID+'&id='+idmovimiento,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setDat({
                        ...dats,
                        ['monto']: datanotice.amount,
                        ['obs']: datanotice.observations,
                        ['tipo_mov']: datanotice.type,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
              })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                  }
              });
          
            return info1;
        }
    };

    useEffect(() => {
        peticionGetAllMove();
    }, [idProject]);

    useEffect(() => {
        peticionGetTaskById();
    }, [idmovimiento]);

    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let monto = dats.monto;
        let observa = dats.obs;
        
        const formattedData = {
            inventory_id: inventarios,
            type: productoselect === '' ? dats.tipo_mov : productoselect,
            amount: monto,
            user_id: userId,
            observations: observa,
        };
        //console.log(formattedData);
        //axios.put(DEDALO_UPDATE_INVENTORY_MOVE(idmovimiento), formattedData, attachToken(user))
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_INVENTORY_MOVE+'&id='+idmovimiento,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            //console.log(response.data.success);
            if (response.data.code === '200') {
                Swal.fire("Se modifico el movimiento de inventario exitósamente", "", "success");
                peticionGetAllMove();
                handleClose1();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    const indexOfLastPOst = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPOst - postPerPage;
    const currentPost = categor.datos.slice(indexOfFirstPost, indexOfLastPOst);
    const pageNumbers = [];

    for(let i=1; i <= Math.ceil(categor.datos.length/postPerPage);i++){
        pageNumbers.push(i);
    }

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const peticionDeleteMovimiento = (iduser) => {
        let delId = iduser;

        //axios.post(DEDALO_DEL_PERSONAL_CONSTRUCTION, params_data, attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_INVENTORY_MOVEMENT+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el movimiento exitosamente", "", "success");
                peticionGetAllMove();
            } else {
                alert("ERROR: Server Error");
            }
        })
        
    };

    return (
        <div className="w-100">
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <Button className="btn btn-success rounded-circle" size="sm" onClick={()=>{window.history.back()}}
                    style={{backgroundColor: "#02B371"}} title="Regresar">
                    <MdIcons.MdArrowBackIosNew/>
                </Button>
            </div>
            <div className="container-fluid flex-grow-1 container-p-y">
                <Table responsive striped bordered hover className="tableactions text-center">
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Proyecto</th>
                        <th>Direccion Proyecto</th>
                        <th>Observaciones</th>
                        <th>Nombre producto</th>
                        <th>Cantidad en stock</th>
                        <th>Cantidad Minima</th>
                        <th>Cantidad Movida</th>
                        <th>Empresa</th>
                        <th>Estado Movimiento</th>
                        <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPost.map((i,i_move) => (
                            <tr key={i_move} className="textotable">
                                <td>{i.id}</td>
                                <td>{i.constructionsName}</td>
                                <td>{i.constructionsaddress}</td>
                                <td>{i.observations}</td>
                                <td>{i.productTypeName}</td>
                                <td>{parseInt(i.amount)}</td>
                                <td>{parseInt(i.minimum_amount)}</td>
                                <td>{parseInt(i.amountInventory)}</td>
                                <td>{i.crmVendorName}</td>
                                <td>{i.type === 'in' ? 'Entrada' : 'Salida'}</td>
                                <td className="text-center">
                                    <DropdownButton
                                        id="dropdown-button-dark-example2"
                                        variant="primary"
                                        menuVariant="dark"
                                        className="mt-2"
                                    >
                                        <Dropdown.Item >
                                            <span onClick={()=>handleShow1(i.inventoryid,i.id)}>
                                                Editar
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item >
                                            <span onClick={()=>peticionDeleteMovimiento(i.id)}>
                                                Eliminar
                                            </span>
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <nav>
                    <ul className="pagination">
                        <li className="me-3">
                            Paginas
                        </li>
                        {pageNumbers.map(number => (
                            <li key={number} className="page-item">
                                <a href="#" className="page-link" onClick={()=>paginate(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            
            {/* MODAL editar Movimiento nuevo inventario */}
            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Movimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaSortAmountUpAlt className="me-2 text-primary"/>
                                    Cantidad
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Monto"
                                    aria-describedby="basic-addon1" value={dats.monto}
                                    type="email" onChange={handleInputChange}
                                    id="monto" name="monto"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1" className="text-primary">
                                    <FaIcons.FaElementor/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeEstadoTarea} id="select" name="select"
                                >
                                    <option value="">{dats.tipo_mov === 'in' ? 'Entrada' : 'Salida'}</option>
                                    <option value='in'>Entrada</option>
                                    <option value='out'>Salida</option>
                                </Form.Select>
                            </InputGroup> 
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <br/>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" value={dats.obs}
                                        onChange={handleInputChange} id="obs" name="obs"
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSave}>
                        Confirmar y Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    );

}

export default MovimientoInventario;