import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, ListGroup ,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_INDICATORS,DEDALO_INDICATORS_BY_ID,
} from "../../../../assets/APIAdresses";
import {message_responses} from "../../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../../utils/utils";
import {useUserSession} from "../../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../../utils/routes';
import {Mensaje_Error_CumpliminetoCrono} from '../../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line,Bar } from 'react-chartjs-2';
import Img1 from '../../imagenes_reportes/logo_geo.png';
import * as MdIcons from "react-icons/md";
import { enIE } from "date-fns/locale";

const Cumplimiento_Crono_Edit = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_Ficha_Tecnica'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();

    let {IdElementEdit} = useParams();
    const arr1 = ['ENE','FEB','MAR','ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC'];
    const labels = ['ENE','FEB','MAR','ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC'];

    const [porcentaje, setPorcentaje] = useState([]);
    const [programado, setProgramado] = useState([]);

    const [dats, setDat] = useState({
        numero: '',
        objetivo: '',
        nom_ind: '',
        cargos: '',
        periodo: '',
        year: '',
        proceso: '',
        meta: '',
        formulacion: '',
        que_mide: '',
        frecuencia: '',
        fuente: '',
        herramientas: '',
        analisis: '',
    });

    const peticionGetVendorById=async()=>{
        let id_noconformidad = parseInt(IdElementEdit);

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_INDICATORS_BY_ID+'&id='+id_noconformidad,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setDat({
                    ...dats,
                    ['numero']: datanotice.number_indicator,
                    ['objetivo']: datanotice.objetive,
                    ['nom_ind']: datanotice.indicator_name,
                    ['cargos']: datanotice.position_responsible,
                    ['periodo']: datanotice.measurement_period,
                    ['year']: datanotice.indicator_year,
                    ['proceso']: datanotice.process,
                    ['meta']: datanotice.goal,
                    ['formulacion']: datanotice.formulation,
                    ['que_mide']: datanotice.it_measure,
                    ['frecuencia']: datanotice.measurement_frequency,
                    ['fuente']: datanotice.data_source,
                    ['herramientas']: datanotice.analysis_tool,
                    ['analisis']: datanotice.analysis_information,
                });
                let prog = [
                    parseInt(datanotice.programmed_january),
                    parseInt(datanotice.programmed_february),
                    parseInt(datanotice.programmed_march),
                    parseInt(datanotice.programmed_april),
                    parseInt(datanotice.programmed_may),
                    parseInt(datanotice.programmed_june),
                    parseInt(datanotice.programmed_july),
                    parseInt(datanotice.programmed_august),
                    parseInt(datanotice.programmed_september),
                    parseInt(datanotice.programmed_october),
                    parseInt(datanotice.programmed_november),
                    parseInt(datanotice.programmed_december),
                ];
                let exe = [
                    parseInt(datanotice.executed_january),
                    parseInt(datanotice.executed_february),
                    parseInt(datanotice.executed_march),
                    parseInt(datanotice.executed_april),
                    parseInt(datanotice.executed_may),
                    parseInt(datanotice.executed_june),
                    parseInt(datanotice.executed_july),
                    parseInt(datanotice.executed_august),
                    parseInt(datanotice.executed_september),
                    parseInt(datanotice.executed_october),
                    parseInt(datanotice.executed_november),
                    parseInt(datanotice.executed_december),
                ];
                setProgramado(prog);
                setPorcentaje(exe);
                for(let i=0;i < prog.length;i++){
                    document.getElementById('prog_'+(i+1)).value = prog[i];
                }
                for(let i=0;i < exe.length;i++){
                    document.getElementById('exe_'+(i+1)).value = exe[i];
                }
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas By_id");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetVendorById();
    }, []);

    const printContainer = useRef(null);

    const createPDF = async () => {
        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: "./pdf/generate.pdf"
        });
    };   

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeObs1 = (j,event) =>{
        let array_check = [];
        let array_check2 = [];
        let cant_select = document.getElementsByClassName("cantidad_");
        let cant_select2 = document.getElementsByClassName("cantidad2_");

        for (var i = 0; i < cant_select.length; i++) {
            if(cant_select[i].value != ''){
                array_check[i] = parseInt(cant_select[i].value === '' ? 0 : cant_select[i].value);
            }
        }
        
        for (var i = 0; i < cant_select2.length; i++) {
            if(cant_select[i].value != ''){
                array_check2[i] = parseInt(cant_select2[i].value === '' ? 0 : cant_select2[i].value);
            }
        }

        setPorcentaje(array_check);
        setProgramado(array_check2);
    };

    let sin_ceros1 = porcentaje.filter(x => x != 0);
    let sin_ceros2 = programado.filter(x => x != 0);
    let sumall_m1 = sin_ceros1.reduce((prev, curr) => prev + curr, 0);
    let sumall_m2 = sin_ceros2.reduce((prev, curr) => prev + curr, 0);
    let promedio1 = sin_ceros1.length === 0 ? 0 : sumall_m1/sin_ceros1.length;
    let promedio2 = sin_ceros2.length === 0 ? 0 : sumall_m2/sin_ceros2.length;

    let sin_cerosx = porcentaje; {/* enviar por el endpoint */}
    let sin_cerosy = programado; {/* enviar por el endpoint */}
    [sin_cerosx, sin_cerosy] = sin_cerosx.length<sin_cerosy.length ? [sin_cerosy, sin_cerosx] : [sin_cerosx, sin_cerosy];
    let promedio_res = sin_cerosx.map((el, index)=> ((el + sin_cerosy[index])/2) || el); {/* enviar por el endpoint */}
    let sin_ceros3 = promedio_res.filter(x => x != 0);
    let sumall_m3 = sin_ceros3.reduce((prev, curr) => prev + curr, 0);
    let promedio3 = sin_ceros3.length === 0 ? 0 : sumall_m3/sin_ceros3.length;
        
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };
    
    const data = {
        labels,
        datasets: [
            {
                label: 'Ejecutado %',
                data: porcentaje.map(x => x),
                borderColor: '#3498DB',
                backgroundColor: '#3498DB',
            },
            {
                label: 'Programado',
                data: programado.map(s => s),
                borderColor: '#E74C3C',
                backgroundColor: '#E74C3C',
            },
            {
                label: 'Resul Prom',
                data: promedio_res.map(t => t),
                borderColor: '#28B463',
                backgroundColor: '#28B463',
            },
        ],
    };
    
    const handleCloseSave = (e) => {
        let valor1 = parseInt(IdElementEdit);
        let valor2 = 0;
        
        const formattedData = {
            enterprice_id: data_emp2.IdEmp,
            constructions_id: data_emp.Id_Project_Conta,
            users_id: userId,
            number_indicator: dats.numero,
            measurement_period: dats.periodo,
            process: dats.proceso,
            indicator_year: dats.year,
            position_responsible: dats.cargos,
            objetive: dats.objetivo,
            indicator_name: dats.nom_ind,
            goal: dats.meta,
            formulation: dats.formulacion,
            it_measure: dats.que_mide,
            measurement_frequency: dats.frecuencia,
            data_source: dats.fuente,
            analysis_tool: dats.herramientas,
            analysis_information: dats.analisis,
            programmed_january: programado[0],
            programmed_february: programado[1],
            programmed_march: programado[2],
            programmed_april: programado[3],
            programmed_may: programado[4],
            programmed_june: programado[5],
            programmed_july: programado[6],
            programmed_august: programado[7],
            programmed_september: programado[8],
            programmed_october: programado[9],
            programmed_november: programado[10],
            programmed_december: programado[11],
            executed_january: porcentaje[0],
            executed_february: porcentaje[1],
            executed_march: porcentaje[2],
            executed_april: porcentaje[3],
            executed_may: porcentaje[4],
            executed_june: porcentaje[5],
            executed_july: porcentaje[6],
            executed_august: porcentaje[7],
            executed_september: porcentaje[8],
            executed_october: porcentaje[9],
            executed_november: porcentaje[10],
            executed_december: porcentaje[11],
        };
        //console.log(formattedData);
        if(valor2 === ''){
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible guardar el anticipo', "info");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_INDICATORS+'&id='+valor1,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire(
                        "!!! Atencion !!!",
                        "se ha editado la ficha tecnica exitosamente",
                        "success"
                    );
                    peticionGetVendorById();
                }else {
                    Mensaje_Error_CumpliminetoCrono(response.data.msg.error)
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}
                            onClick={handleCloseSave}
                        >
                            Guardar Cumplimiento
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" 
                            onClick={() => createPDF()} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div style={{paddingTop: '0.5em'}} ref={printContainer} className="container container-fluid">
                <div className="border border-dark container container-fluid" style={{padding: '1em 1em 1em 1em'}}>
                    <Row className="border border-dark mx-2"> {/*  cabecera del formato */}
                        <Col md={2} className="text-center py-1 border-end border-dark">
                            <img src={Img1} className='img-fluid' width={230}/>
                        </Col>
                        <Col md={8} className="d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <h3>
                                    <b>FICHA TECNICA DE INDICADORES</b>
                                </h3>
                            </div>
                        </Col>
                        <Col md={2} className="text-center py-1 border-start border-dark">
                            <div>
                                Codigo: FT-GC-01-01
                            </div>
                            <div>
                                Fecha: 2/18/2013
                            </div>
                            <div>
                                Pagina 1 de 1
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12}>
                            <Row className='text-center border-bottom border-dark' style={{background: '#f5b7b1'}}>
                                <Col md={3} className='border-end border-dark'>
                                    <b>AÑO</b>
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <b>PERIODO DE MEDICION</b>
                                </Col>
                                <Col md={6}>
                                    <b>CARGOS RESPONSABLE DE LA MEDICION</b>
                                </Col>
                            </Row>
                            <Row className='text-center'>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.year}
                                        aria-describedby="basic-addon1" type="number" id="year" name="year"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={3} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.periodo}
                                        aria-describedby="basic-addon1" type="text" id="periodo" name="periodo"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.cargos}
                                        aria-describedby="basic-addon1" type="text" id="cargos" name="cargos"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <Row className="text-center border border-dark mx-2">
                        <Col md={3} className="d-flex justify-content-center align-items-center border-end border-dark " 
                            style={{background: '#f5b7b1'}}
                        >
                            <b>PROCESO</b>
                        </Col>
                        <Col md={9}>
                            <FormControl
                                aria-label="Nombre de la cotizacion" value={dats.proceso}
                                aria-describedby="basic-addon1" type="text" id="proceso" name="proceso"
                                className="border-0 text-center" onChange={handleInputChangeObs}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12}>
                            <Row className='text-center border-bottom border-dark' style={{background: '#f5b7b1'}}>
                                <Col md={2} className='border-end border-dark'>
                                    <b>No.</b>
                                </Col>
                                <Col md={4} className='border-end border-dark'>
                                    <b>OBJETIVO</b>
                                </Col>
                                <Col md={6}>
                                    <b>NOMBRE DEL INDICADOR</b>
                                </Col>
                            </Row>
                            <Row className='text-center'>
                                <Col md={2} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.numero}
                                        aria-describedby="basic-addon1" type="number" id="numero" name="numero"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={4} className='border-end border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.objetivo}
                                        aria-describedby="basic-addon1" type="text" id="objetivo" name="objetivo"
                                        className="border-0 " onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.nom_ind}
                                        aria-describedby="basic-addon1" type="text" id="nom_ind" name="nom_ind"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12}>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark " 
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>META</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.meta}
                                        aria-describedby="basic-addon1" type="text" id="meta" name="meta"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark " 
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>FORMULACION</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.formulacion}
                                        aria-describedby="basic-addon1" type="text" id="formulacion" name="formulacion"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark " 
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>QUE MIDE</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.que_mide}
                                        aria-describedby="basic-addon1" type="text" id="que_mide" name="que_mide"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark " 
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>FRECUENCIA DE MEDICION</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.frecuencia}
                                        aria-describedby="basic-addon1" type="text" id="frecuencia" name="frecuencia"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-bottom border-dark " 
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>FUENTE DE DATOS</b>
                                </Col>
                                <Col md={10} className='border-bottom border-dark'>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.fuente}
                                        aria-describedby="basic-addon1" type="text" id="fuente" name="fuente"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} className="d-flex justify-items-center align-items-center border-end border-dark " 
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>HERRAMIENTAS DE ANALISIS</b>
                                </Col>
                                <Col md={10}>
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.herramientas}
                                        aria-describedby="basic-addon1" type="text" id="herramientas" name="herramientas"
                                        className="border-0" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/> {/* incio cuadro nuevo */}
                    <Row className="border border-dark mx-2">
                        <Col md={12} className="border-bottom border-dark">
                            <Row style={{background: '#f5b7b1'}}>
                                <Col md={2} className='border-end border-dark'>
                                    <b>MES</b>
                                </Col>
                                {arr1.map((x,index) => (
                                    <Col key={index} className='text-center border-end border-dark'>
                                        <b><span>{x}</span></b>
                                    </Col>
                                ))}
                                <Col md={1} className='text-center'>
                                    <b>ACUM</b>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className='border-bottom border-dark'>
                            <Row>
                                <Col md={2} className='border-end border-dark d-flex justify-items-center align-items-center'
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>EJECUTADO %</b>
                                </Col>
                                {arr1.map((j,index) => (
                                    <Col key={index} className='text-center border-end border-dark'>
                                        <FormControl
                                            aria-label="Nombre de la cotizacion"
                                            aria-describedby="basic-addon1" type="text" id={'exe_'+(index+1)} name={'exe_'+(index+1)}
                                            className="border-0 text-center me-2 cantidad_" onChange={()=>handleInputChangeObs1(j)}
                                        />
                                    </Col>
                                ))}
                                <Col md={1} className='text-center'>
                                    <b>{promedio1.toFixed(0)}</b>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className='border-bottom border-dark'>
                            <Row>
                                <Col md={2} className='border-end border-dark d-flex justify-items-center align-items-center'
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>PROGRAMADO</b>
                                </Col>
                                {arr1.map((c,index) => (
                                    <Col key={index} className='text-center border-end border-dark'>
                                        <FormControl
                                            aria-label="Nombre de la cotizacion"
                                            aria-describedby="basic-addon1" type="text" id={'prog_'+(index+1)} name={'prog_'+(index+1)}
                                            className="border-0 text-center me-2 cantidad2_" onChange={()=>handleInputChangeObs1(c)}
                                        />
                                    </Col>
                                ))}
                                <Col md={1} className='text-center'>
                                    <b>{promedio2.toFixed(0)}</b>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col md={2} className='border-end border-dark d-flex justify-items-center align-items-center'
                                    style={{background: '#f5b7b1'}}
                                >
                                    <b>RESUL PROM</b>
                                </Col>
                                {promedio_res.length === 0 ?
                                    arr1.map((r,index) => (
                                        <Col key={index} className='text-center border-end border-dark'>
                                            <b>--</b>
                                        </Col>
                                    ))
                                :
                                    promedio_res.map((r,index) => (
                                        <Col key={index} className='text-center border-end border-dark'>
                                            <b>{r.toFixed(0)}</b>
                                        </Col>
                                    ))
                                }
                                <Col md={1} className='text-center'>
                                    <b>{promedio3.toFixed(0)}</b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/> {/* fin cuadro nuevo */}
                    <Row className="text-center border border-dark mx-2">
                        <Col md={2} className="d-flex justify-items-center align-items-center border-end border-dark " 
                            style={{background: '#f5b7b1'}}
                        >
                            <b>ANALISIS DE LA INFORMACION</b>
                        </Col>
                        <Col md={10}>
                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                id="analisis" name="analisis" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                className='border-0 text-center' value={dats.analisis}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12} className="border-bottom border-dark text-center" 
                            style={{background: '#f5b7b1'}}
                        >
                            <b>EFECTIVIDAD DEL SISTEMA DE GESTION DE CALIDAD</b>
                        </Col>
                        <Col md={12}>
                            <div className="m-4">
                                <Line options={options} data={data} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <br/>
            </div>
        </div>
    );
}

export default Cumplimiento_Crono_Edit;