import React, { useState, useEffect } from 'react';
import { 
    Card, Button, Accordion, Row, Col,Modal,
    Table,NavDropdown,Dropdown,InputGroup,Form
} from 'react-bootstrap';
import {
    DEDALO_ALL_FORM_SHOP,DEDALO_NEW_FORM_SHOP,DEDALO_UPDATE_FORM_SHOP,
    DEDALO_DEL_FORM_SHOP,DEDALO_FORM_SHOP_BY_ID,DEDALO_FORM_SHOP_COMBO,
    DEDALO_ALL_TEMPORARY_EVENT,DEDALO_NEW_TEMPORARY_EVENT,DEDALO_UPDATE_TEMPORARY_EVENT,
    DEDALO_DEL_TEMPORARY_EVENT,DEDALO_TEMPORARY_EVENT_BY_ID,DEDALO_TEMPORARY_EVENT_COMBO,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import Navbar from '../navbar';
import { Routes } from '../../utils/routes';
import Cookies from "universal-cookie";
import * as MdIcons from "react-icons/md";

const Formularios_Zpazium = () =>{
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();    
    const {user} = useUserSession();
    
    const [categor1, setCategor1] = useState({
        datos: [],
    });

    const [categor2, setCategor2] = useState({
        datos: [],
    });

    const [prueba2, setPrueba2] = useState({
        datos: [],
    });

    const peticionGetCategor1= async()=>{
        const params_data = {
            search: '',
            perpage: 40000,
            page: 1,
            orderField: 'agent_name',
            order: 'asc',
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_FORM_SHOP,
            //headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice);
                setCategor1({
                    ...categor1,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 1");
            }
        });
      
        return info1;
    };

    const peticionGetCategor2= async()=>{
        const params_data = {
            search: '',
            perpage: 40000,
            page: 1,
            orderField: 'agent_name',
            order: 'asc',
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_TEMPORARY_EVENT,
            //headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice);
                setCategor2({
                    ...categor2,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 2");
            }
        });
      
        return info1;
    };

    /*const peticionGetCuadrillasById=async(datos)=>{
        const arr_info2 = [];
        let arr_info_2 = datos === undefined ? [] : datos;
        arr_info_2.map(element => {
            let info2 = axios({
            //axios({
                method: 'POST',
                url: DEDALO_FORM_SHOP_BY_ID+'&id='+element.id,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    arr_info2.push(datanotice.data);
                    setPrueba2({
                        ...prueba2,
                        ['datos']: arr_info2,
                    });
                  } else {
                     alert(message_responses[response.data.message]);
                  }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos21");
                }
            });
            return info2;
        });
        //console.log(datos.length);
    };*/

    useEffect(() => {
        peticionGetCategor1();
        peticionGetCategor2();
    }, []);

    // paginador para la tabla locales comerciales
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };
    
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = categor1.datos === undefined ? [] : categor1.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = categor1.datos === undefined ? [] : categor1.datos.length/postPerPage; // numero total de paginas
    let pageNumbers = []; //se guardan los numeros de paginas
    
    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () =>{
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () =>{
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };
    
    const paginador1 = () =>{
        return(
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{cursor: 'pointer'}}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };

    // paginador para la tabla eventos temporales
    const [currentPage2, setCurrentPage2] = useState(1);
    const [postPerPage2, setPostPerPage2] = useState(8);

    const handleChangePag2 = (event) => {
        setCurrentPage2(event.target.value);
    };

    let indexOfLastPOst2 = currentPage2 === '' ? 1 * postPerPage2 : currentPage2 * postPerPage2;
    let indexOfFirstPost2 = indexOfLastPOst2 - postPerPage2;
    let currentPost2 = categor2.datos === undefined ? [] : categor2.datos.slice(indexOfFirstPost2, indexOfLastPOst2); //porcion de data a mostrar
    let div_pag_p2 = categor2.datos === undefined ? [] : categor2.datos.length/postPerPage2; // numero total de paginas
    let pageNumbers2 = []; //se guardan los numeros de paginas

    const paginate2 = pageNumber2 => setCurrentPage2(pageNumber2);

    for (let i = 1; i <= Math.ceil(div_pag_p2); i++) {
        pageNumbers2.push(i);
    }

    const nextHandler2 = () =>{
        const nextPage2 = parseInt(currentPage2) + 1;
        paginate2(nextPage2);
    };

    const prevHandler2 = () =>{
        const prevPage2 = parseInt(currentPage2) - 1;
        paginate2(prevPage2);
    };
    
    const paginador2 = () =>{
        return(
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={prevHandler2}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{cursor: 'pointer'}}>
                                <div>
                                    <Form.Select onChange={handleChangePag2} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers2.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p2) ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={nextHandler2}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage2 === '' ? 1 : currentPage2} de {Math.ceil(div_pag_p2)}
                    </span>
                </div>
            </div>
        );
    };

    const actualizar = () =>{
        peticionGetCategor1();
        peticionGetCategor2();
    };

    const peticionDelete1 = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_FORM_SHOP+'&id='+delId,
            //headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino la encuesta exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor1();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const peticionDelete2 = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_TEMPORARY_EVENT+'&id='+delId,
            //headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino la encuesta exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor2();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    if(!user){
        window.location.href = Routes.LOGIN;
    }

    return(
        <div>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start mt-3">
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                    <Button className="btn btn-success rounded-circle" size="sm" onClick={()=>{window.history.back()}}
                        style={{backgroundColor: "#02B371"}} title="Regresar.">
                        <MdIcons.MdArrowBackIosNew/>
                    </Button>

                    <Button className="btn btn-success" size="sm" onClick={actualizar}
                        style={{backgroundColor: "#02B371"}} title="Regresar.">
                        actualizar informacion
                    </Button>
                </div>
            </div>
            <div className='container-fluid text-center'>
                <h3>Respuestas de los formularios en Zpazium</h3>
            </div>
            <div className='mt-4'>
                <div className='container'>
                    <p>
                        <b>Formulario Locales comerciales</b>
                    </p>
                    <div className='mb-3'>
                        <Accordion>
                            {currentPost.map((x,ind) => (
                                <Accordion.Item eventKey={ind}>
                                    <Accordion.Header>Encuesta #{ind+1}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='d-flex'>
                                            <div>
                                                <span className='fw-bolder'>Datos de la encuesta hecha por {x.agent_name}</span>
                                            </div>
                                            <div className='ms-auto'>
                                                <MdIcons.MdDelete color='red' onClick={()=>peticionDelete1(x.id)}
                                                    style={{cursor: 'pointer'}}
                                                />
                                            </div>
                                        </div>
                                        <hr className='mt-1'/>
                                        <Row>
                                            <Col>
                                                <div>
                                                    <p className='fw-bolder mb-1'>Espacio disponible si se pudiese reorganizar</p>
                                                    <p>{x.available_space}</p>
                                                </div>
                                                <div>
                                                    <p className='fw-bolder mb-1'>Nombre de Contacto</p>
                                                    <p>{x.contact}</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <p className='fw-bolder mb-1'>Tipo de negocio con el que se quisiera compartir</p>
                                                    <p>{x.busines_type}</p>
                                                </div>
                                                <div>
                                                    <p className='fw-bolder mb-1'>Correo electrónico del propietario del negocio / inmueble</p>
                                                    <p>{x.email}</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <p className='fw-bolder mb-1'>A que plazo desearía arrendar</p>
                                                    <p>{x.term_to_lease}</p>
                                                </div>
                                                <div>
                                                    <p className='fw-bolder mb-1'>Algún teléfono de contacto</p>
                                                    <p>{x.contact_phone}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                    {paginador1()}
                </div>
                <div className='container mt-4'>
                    <p>
                        <b>Formulario Eventos temporales</b>
                    </p>
                    <div className='mb-3'>
                        <Accordion>
                            {currentPost2.map((x,ind) => (
                                <Accordion.Item eventKey={ind}>
                                    <Accordion.Header>Encuesta #{ind+1}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='d-flex'>
                                            <div>
                                            <span className='fw-bolder'>Datos de la encuesta hecha por {x.agent_name}</span>
                                            </div>
                                            <div className='ms-auto'>
                                                <MdIcons.MdDelete color='red' onClick={()=>peticionDelete2(x.id)}
                                                    style={{cursor: 'pointer'}}
                                                />
                                            </div>
                                        </div>
                                        <hr className='mt-1'/>
                                        <div>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <p className='fw-bolder mb-1'>Espacio Disponible</p>
                                                        <p>{x.available_space}</p>
                                                    </div>
                                                    <div>
                                                        <p className='fw-bolder mb-1'>Tipo de Espacio</p>
                                                        <p>{x.busines_type}</p>
                                                    </div>
                                                    <div>
                                                        <p className='fw-bolder mb-1'>Algún teléfono de contacto</p>
                                                        <p>{x.contact_phone}</p>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <p className='fw-bolder mb-1'>Tipo de Evento que permitiría</p>
                                                        <p>{x.event_type === '' ? 'n/a' : x.event_type}</p>
                                                    </div>
                                                    <div>
                                                        <p className='fw-bolder mb-1'>Tiempo o franja horaria</p>
                                                        <p>{x.hours}</p>
                                                    </div>
                                                    <div>
                                                        <p className='fw-bolder mb-1'>Correo electrónico del propietario del negocio / inmueble</p>
                                                        <p>{x.email}</p>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <p className='fw-bolder mb-1'>Espacio Disponible</p>
                                                        <p>{x.additional_services}</p>
                                                    </div>
                                                    <div>
                                                        <p className='fw-bolder mb-1'>Nombre de Contacto</p>
                                                        <p>{x.contact}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                    {paginador2()}
                </div>
            </div>
        </div>
    );
}

export default Formularios_Zpazium;