import React, { useState, Fragment, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Card, Button, InputGroup, FormControl, Container, Row, Col,
    Form,
} from 'react-bootstrap';
import {
    DEDALO_LOGIN, DEDALO_SEND_RESET_PASSWORD_EMAIL,
} from "../../assets/APIAdresses";
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import { useUserSession } from "../../hooks";
import { socket } from '../../assets/js/conexion_socket';
import Swal from "sweetalert2";
import { Redirect, useParams } from "react-router-dom";
import { Routes } from "../../utils/routes";
import { MdOutlineSecurity } from 'react-icons/md';
import Logo from '../../imagenes/dedalo_logo.png';


function Login() {
    const dedalo_usr = JSON.parse(localStorage.getItem('dedalo_usr'));
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const { storeUserSession } = useUserSession();

    const [dats, setDat] = useState({
        username: '',
        pass: ''
    });

    const handleInputChange = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    let emp_user;

    const handleCloseSave = (e) => {
        e.preventDefault();

        let username = dats.username;
        let pwr = dats.pass;

        const formattedData = {
            email: username,
            password: pwr
        };

        axios.post(DEDALO_LOGIN, formattedData)
            .then(response => {
                if (response.data.code === '200') {
                    //let datanotice = response.data.data;
                    let datanotice = response.data.msg.data.result[0];
                    let token = response.data.msg.data.token;
                    emp_user = datanotice.enterprices;
                    storeUserSession(datanotice, token);
                    //console.log([datanotice,token,emp_user]);
                    if (emp_user.length > 1) {
                        window.location.href = Routes.LICENCIA_EMPRESA;
                    } else {
                        if (emp_user[0].expiration === 0) {
                            Swal.fire("Su licencia de acceso a caducado, porfavor contactar al administrador para renovar la licencia.", "", "error");
                        } else {
                            window.location.href = Routes.HOME;
                            localStorage.setItem("Nombre_empresa", JSON.stringify(
                                {
                                    NomEmp: emp_user[0].name,
                                    IdEmp: emp_user[0].id,
                                    RutEmp: emp_user[0].rut,
                                    DiasLicence: emp_user[0].expiration,
                                }
                            ));
                        }
                    }
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Datos incorrectos',
                        text: 'Porfavor verifique si el correo o la contraseña son validos',
                    });
                }
            })
            .catch(error => {
                manageError(error);
            });
    };
    
    const resetpassdedalo = () => {
        window.location.href = Routes.RESET_PASS;
    };

    if (emp_user === undefined) {
        let po = 0;
    } else {
        if (emp_user.length > 1) {
            if (user) {
                window.location.href = Routes.LICENCIA_EMPRESA;
            }
        } else {
            if (user) {
                window.location.href = Routes.HOME;
            }
        }
    }


    return (
        <div className="fondo_pantalla">
            <Container className="cardlogin">
                <form onSubmit={handleCloseSave}>
                    <Card>
                        <Card.Header className="text-center">
                            <img src={Logo} width="171" height="180" />
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col md={12} xs={12}>
                                        <InputGroup className="mb-3" style={{ margin: "auto", paddingTop: "10%" }}>
                                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                            <FormControl
                                                placeholder="Correo"
                                                aria-label="Correo"
                                                aria-describedby="basic-addon1"
                                                type="email" onChange={handleInputChange}
                                                id="username" name="username"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col md={12} xs={12}>
                                        <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdOutlineSecurity />
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Contraseña"
                                                aria-label="Contraseña" aria-describedby="basic-addon1"
                                                type="password" onChange={handleInputChange}
                                                id="pass" name="pass"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: "4%" }}>
                                    <Col className="text-start">
                                        <a className="text-muted" onClick={() => resetpassdedalo()} style={{ cursor: "pointer" }}>
                                            Recuperar Contraseña
                                        </a>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="text-center">
                                        <Button className="boton1" type="submit"
                                            style={{ letterSpacing: "0.4rem", backgroundColor: "#02B371" }}
                                        //onClick={()=>handleCloseSave()}
                                        >
                                            Ingresar
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </form>
            </Container>
        </div>
    );
}

export default Login;
