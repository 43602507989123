import React, { useState, Fragment, useEffect, PureComponent } from "react";
import { Redirect, useParams, Link } from "react-router-dom";
import {
    PieChart, Pie, Legend, Sector, Cell, ResponsiveContainer,
    Tooltip,
} from 'recharts';
import {
    DEDALO_GRAPH_TASK_ITEMS, DEDALO_GET_ALL_CONSTRUCTIONS,
} from "../../../assets/APIAdresses";
import axios from "axios";
import { useUserSession } from "../../../hooks";
import { message_responses } from "../../../assets/MESSAGE_RESPONSES";
import { Routes } from "../../../utils/routes";

function DiagramaTipoItems(props) {
    const { userToken } = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = datahomi === null ? 0 : parseInt(datahomi.idProject);

    const [budgettareas, setBudgetTareas] = useState([]);

    const peticionGetTareas = async () => {
        let ubucacion = window.location.pathname;
        let info = []

        if (ubucacion === Routes.HOME) {
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_GRAPH_TASK_ITEMS + '&id=' + props.id_home,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg;
                        //console.log(datanotice);
                        if (datanotice.length === 0) {
                            setBudgetTareas({
                                ...budgettareas,
                                ['datos']: [],
                            });
                        } else {
                            setBudgetTareas({
                                ...budgettareas,
                                ['datos']: datanotice.data,
                            });
                        }
                    } else {
                        setBudgetTareas({
                            ...budgettareas,
                            ['datos']: [],
                        });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 26");
                    }
                });
            return info1;
        }
    };

    useEffect(() => {
        peticionGetTareas();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2
    });

    const data2 = [];
    const datos_api = budgettareas.datos === undefined ? [] : budgettareas.datos;
    for (let q = 0; q < datos_api.length; q++) {
        data2.push({
            name: datos_api[q].typeitems_name,
            typeitems_id: parseInt(datos_api[q].typeitems_id),
            title: datos_api[q].title,
            totalbudget: datos_api[q].totalbudget,
        })
    };

    function removeDuplicates(array) {
        let resultado = []; // Array para almacenar el resultado final.
        let contador = 0; // Variable para contar la cantidad de veces que se repite un campo.
        array.forEach(objeto => { // Iteramos sobre cada objeto del array.
            let nombreEncontrado = false; // Variable para saber si ya encontramos el nombre en el resultado.

            resultado.forEach(elemento => { // Iteramos sobre el resultado para ver si ya existe el nombre.
                if (elemento.name === objeto.name) { // Si encontramos un elemento con el mismo nombre, aumentamos su cantidad y marcamos que ya lo encontramos.
                    elemento.value++;
                    elemento.total += objeto.totalbudget;
                    nombreEncontrado = true;
                }
            });

            if (!nombreEncontrado) { // Si no lo encontramos, agregamos un elemento al resultado con la cantidad inicial de 1 y el nombre del objeto actual.  
                resultado.push({ name: objeto.name, value: 1, typeitems_id: objeto.typeitems_id, total: objeto.totalbudget, title: objeto.title });
            }
        });
        return resultado;
    };


    let uniqueArray = removeDuplicates(data2);
    //console.log(uniqueArray, datos_api);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042','#EC7063','#1E8449','#A6ACAF','#D6DBDF'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, total, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        //{`${(percent * 100).toFixed(0)}%`}
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                ({value})
            </text>
        );
    };

    const renderLegend = (props) => {
        const { payload } = props;
        
        return (
            <ul className="list-group text-start">
                {
                    payload.map((entry, index) => (
                        <li className="list-group-item px-2" key={`item-${index}`} 
                            style={{background: entry.color, fontSize: '0.8rem', color: entry.payload.stroke}}
                        >
                            {entry.value} <span>Total</span> {formatterPeso.format(entry.payload.total)}
                        </li>
                    ))
                }
            </ul>
        );
    }


    return (
        <div className="App">
            <div class="d-flex justify-content-center text-center">
                {datos_api.length === 0 ?
                    <div>
                        <span><b>en el momento no hay tareas registradas</b></span>
                    </div>
                    :
                    <div>
                        <ResponsiveContainer width={600} height={200} className="text-center">
                            <PieChart width={600} height={200}>
                                <Legend layout="vertical" verticalAlign="middle" align="left" content={renderLegend}/>
                                <Pie
                                    data={uniqueArray}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {uniqueArray.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                }
            </div>
        </div>
    );
}

export default DiagramaTipoItems;