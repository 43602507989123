import React, { useState, useRef, useEffect } from "react";
import {
    Form, Row, Col, FloatingLabel, Button,
} from 'react-bootstrap';
import {
    DEDALO_PERMISOS_BY_ID, DEDALO_UPDATE_PERMISOS,
} from "../../../assets/APIAdresses";
import { message_responses } from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import { useUserSession } from "../../../hooks";
import { Routes } from '../../../utils/routes';
import { Redirect, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import html2PDF from "jspdf-html2canvas";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import * as MdIcons from "react-icons/md";
import Img1 from './img/logo_permiso.jpg';


const FormatoPermiso = () => {
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [selectedDate, handleDateChange] = useState(null);
    const [selectedDate1, handleDateChange1] = useState(null);
    const [selectedDate2, handleDateChange2] = useState(new Date());
    const [selectedDate3, handleDateChange3] = useState(null);
    const [selectedDate4, handleDateChange4] = useState(new Date());

    const { id } = useParams();

    const [dats, setDat] = useState({
        empresa: '',
        empleado: '',
        tipo_num_doc: '',
        cargo: '',
        dependencia: '',
        tipo_permiso_x: '',
        otro: '',
        otro_cual: '',
        anexa_soporte_si: '',
        anexa_soporte_no: '',
        anexa_justificativo_si: '',
        anexa_justificativo_no: '',
        remunerado: '',
        no_remunerado: '',
        compensatorio: '',
        tiempo_recuperable_si: '',
        tiempo_recuperable_no: '',
        horas_permiso: '',
        observaciones: '',
        opt_nom: '',
        medica_hijo: '',
        fall_fami: '',
        tram_acad: '',
        cita_pub: '',
        cita_prop: '',
        cita_fami: '',
        trami_doc: '',
        asis_grado: '',
        diligen_personales: '',
        fecha1: '',
        fecha2: '',
        fecha3: '',
        hora1: '',
        hora2: '',
    });

    const localeMap = {
        es: esLocale,
    };

    const handleInputChange = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const peticionGetPermisoById = async () => {

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_PERMISOS_BY_ID + '&id=' + id,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice.data);
                    setDat({
                        ...dats,
                        ['empresa']: datanotice.enterprice,
                        ['empleado']: datanotice.employee_name,
                        ['tipo_num_doc']: datanotice.document_number,
                        ['cargo']: datanotice.position,
                        ['dependencia']: datanotice.department,
                        ['tipo_permiso_x']: datanotice,
                        ['otro']: datanotice.other,
                        ['otro_cual']: datanotice.other_detail,
                        ['anexa_soporte_si']: datanotice.administrativesupport_yes,
                        ['anexa_soporte_no']: datanotice.administrativesupport_no,
                        ['anexa_justificativo_si']: datanotice.bringsjustification_yes,
                        ['anexa_justificativo_no']: datanotice.bringsjustification_no,
                        ['remunerado']: datanotice.paid_leave,
                        ['no_remunerado']: datanotice.nopaid_leave,
                        ['compensatorio']: datanotice.countervailing,
                        ['tiempo_recuperable_si']: datanotice.recoverabletime_yes,
                        ['tiempo_recuperable_no']: datanotice.recoverabletime_no,
                        ['horas_permiso']: datanotice.leave_hours,
                        ['observaciones']: datanotice.observations,
                        ['cita_prop']: datanotice.medical_appointment,
                        ['cita_fami']: datanotice.medical_appointment_family,
                        ['trami_doc']: datanotice.paperwork,
                        ['asis_grado']: datanotice.attendance_degree_acts,
                        ['diligen_personales']: datanotice.personal_dilligencies,
                        ['medica_hijo']: datanotice.medical_appointment_child,
                        ['fall_fami']: datanotice.due_family_death,
                        ['tram_acad']: datanotice.academic_procedures,
                        ['cita_pub']: datanotice.subpoena_public_power,
                        ['fecha1']: datanotice.date,
                        ['fecha2']: datanotice.out_date,
                        ['fecha3']: datanotice.in_date,
                        ['hora1']: datanotice.out_time,
                        ['hora2']: datanotice.in_time,
                    });

                    let p2 = datanotice.date + ' ' + datanotice.out_time;
                    let p3 = datanotice.date + ' ' + datanotice.in_time;
                    handleDateChange4(p2);
                    handleDateChange2(p3);

                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetPermisoById();
    }, []);

    let f1 = new Date(dats.fecha2);
    let d1 = f1.getDate()+1;
    let m1 = f1.getMonth()+1;
    let y1 = f1.getFullYear();
    let ff1 = y1+'-'+m1+'-'+d1;

    let f2 = new Date(dats.fecha3);
    let d2 = f2.getDate()+1;
    let m2 = f2.getMonth()+1;
    let y2 = f2.getFullYear();
    let ff2 = y2+'-'+m2+'-'+d2;

    let f3 = new Date(dats.fecha1);
    let d3 = f3.getDate()+1;
    let m3 = f3.getMonth()+1;
    let y3 = f3.getFullYear();
    let ff3 = y3+'-'+m3+'-'+d3;
    
    const handleCloseSave = (e) => {
        let fecha4 = new Date(selectedDate).toISOString().slice(0, 10).replace(/-/g, "-");
        let fecha5 = new Date(selectedDate3).toISOString().slice(0, 10).replace(/-/g, "-");
        let fecha6 = new Date(selectedDate1).toISOString().slice(0, 10).replace(/-/g, "-");

        const formattedData = {
            enterprice: dats.empresa,
            date: selectedDate === null ? ff3 : fecha4,
            users_id: userId,
            employee_name: dats.empleado,
            document_number: dats.tipo_num_doc,
            position: dats.cargo,
            department: dats.dependencia,
            medical_appointment: dats.cita_prop,
            medical_appointment_child: dats.medica_hijo,
            medical_appointment_family: dats.cita_fami,
            due_family_death: dats.fall_fami,
            paperwork: dats.trami_doc,
            academic_procedures: dats.tram_acad,
            attendance_degree_acts: dats.asis_grado,
            subpoena_public_power: dats.cita_pub,
            personal_dilligencies: dats.diligen_personales,
            other: dats.otro,
            other_detail: dats.otro_cual,
            administrativesupport_yes: dats.anexa_justificativo_si,
            administrativesupport_no: dats.anexa_justificativo_no,
            bringsjustification_yes: dats.anexa_soporte_si,
            bringsjustification_no: dats.anexa_soporte_no,
            out_date: selectedDate3 === null ? ff1 : fecha5,
            out_time: new Date(selectedDate4).toLocaleTimeString(),
            in_date: selectedDate1 === null ? ff2 : fecha6,
            in_time: new Date(selectedDate2).toLocaleTimeString(),
            paid_leave: dats.remunerado,
            nopaid_leave: dats.no_remunerado,
            countervailing: dats.compensatorio,
            recoverabletime_yes: dats.tiempo_recuperable_si,
            recoverabletime_no: dats.tiempo_recuperable_no,
            leave_hours: dats.horas_permiso,
            observations: dats.observaciones
        };
        //console.log(formattedData);
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_PERMISOS + '&id=' + id,
            headers: { Authorization: userToken },
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se edito el formato exitósamente", "", "success");
                peticionGetPermisoById();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    const printContainer = useRef(null);

    const createPDF = async () => {
        let year = new Date().getFullYear();
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;

        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `formato_permiso_${fecha}.pdf`
        });
    };

    //console.log(selectedDate3,ff,dats.fecha2);

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371", color: '#fff' }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371", color: '#fff' }}
                            onClick={handleCloseSave}
                        >
                            Guardar formato
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm"
                            onClick={() => createPDF()} title="Regresar" style={{ backgroundColor: "#02B371", color: '#fff' }}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br />
            <div className="container" ref={printContainer} style={{ padding: '1em 5em 1em 5em' }}>
                <div className="border container border-dark">
                    <Row>
                        <Col md={4} className="border-dark border-end text-center d-flex justify-content-center align-items-center">
                            <img src={Img1} className='img-fluid' />
                        </Col>
                        <Col md={6} className="border-dark border-end">
                            <Row>
                                <Col md={3} className="text-center border-dark border-bottom border-end">
                                    <span><b>SGC</b></span>
                                </Col>
                                <Col md={9} className="text-center border-dark border-bottom">
                                    <span><b>codigo FO-RH-21-01</b></span>
                                </Col>
                            </Row>
                            <Row className="text-center d-flex justify-content-center align-items-center">
                                <Col md={12}>
                                    <div>
                                        <b>PERMISO LABORAL</b>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={2} className="text-center">
                            <Row>
                                <Col>
                                    <span><b>Version 1.0</b></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span><b>Pagina</b></span> <br />
                                    <span><b>1 de 1</b></span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="text-center">
                    <p className="py-2">
                        <span>Todo permiso DEBE ser solicitado con minimo de 24 horas</span>
                    </p>
                </div>
                <div>
                    <Row>
                        <Col md={8}>
                            <div className="d-flex">
                                <div className="me-3 d-flex justify-content-center align-items-center">
                                    <span>Empresa:</span>
                                </div>
                                <div style={{ width: '26rem' }} className='border-dark border-bottom'>
                                    <Form.Control
                                        className='border-0' autoFocus onChange={handleInputChange}
                                        aria-label="Username" id="empresa" name="empresa"
                                        aria-describedby="basic-addon1" value={dats.empresa}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="d-flex">
                                <div className="me-3 d-flex justify-content-center align-items-center">
                                    <span>Fecha de solicitud:</span>
                                </div>
                                <div className="fecha_solicitud">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                                        <DatePicker
                                            autoOk format="dd MMMM yyyy"
                                            value={selectedDate === null ? ff3 : selectedDate} 
                                            onChange={handleDateChange}
                                            animateYearScrolling
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center mt-4 py-1" style={{ background: '#d6dbdf' }}>
                        <div>
                            <span><b>DATOS BASICOS</b></span>
                        </div>
                    </div>
                    <div> {/* datos basicos */}
                        <Row className="mt-3">
                            <Col md={7}>
                                <div>
                                    <div>
                                        <span>Nombre completo del empleado</span>
                                    </div>
                                    <div style={{ width: '90%' }} className='border-dark border-bottom'>
                                        <Form.Control
                                            className='border-0' onChange={handleInputChange}
                                            aria-label="Username" id="empleado" name="empleado"
                                            aria-describedby="basic-addon1" value={dats.empleado}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div>
                                    <div>
                                        <span>Tipo y N°. de Doc. identidad</span>
                                    </div>
                                    <div style={{ width: '90%' }} className='border-dark border-bottom'>
                                        <Form.Control
                                            className='border-0' onChange={handleInputChange}
                                            aria-label="Username" id="tipo_num_doc" name="tipo_num_doc"
                                            aria-describedby="basic-addon1" value={dats.tipo_num_doc}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={4}>
                                <div>
                                    <div>
                                        <span>Cargo</span>
                                    </div>
                                    <div style={{ width: '90%' }} className='border-dark border-bottom'>
                                        <Form.Control
                                            className='border-0' onChange={handleInputChange}
                                            aria-label="Username" id="cargo" name="cargo"
                                            aria-describedby="basic-addon1" value={dats.cargo}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <div>
                                        <span>Dependencia</span>
                                    </div>
                                    <div style={{ width: '90%' }} className='border-dark border-bottom'>
                                        <Form.Control
                                            className='border-0' onChange={handleInputChange}
                                            aria-label="Username" id="dependencia" name="dependencia"
                                            aria-describedby="basic-addon1" value={dats.dependencia}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="text-center mt-4 py-1" style={{ background: '#d6dbdf' }}>
                        <div>
                            <span><b>TIPO DE PERMISO</b></span>
                        </div>
                    </div>
                    <div> {/* tipo de permiso */}
                        <p className="py-2">
                            <span>Marque con una X el tipo de permiso que desea solicitar</span>
                        </p>
                        <div>
                            <Row>
                                <Col md={6}>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.cita_prop}
                                                id='cita_prop' name='cita_prop' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Cita medica propia</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.cita_fami}
                                                id='cita_fami' name='cita_fami' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Cita medica de un familiar</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.trami_doc}
                                                id='trami_doc' name='trami_doc' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Tramite de Documentos</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.asis_grado}
                                                id='asis_grado' name='asis_grado' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Asistencia a Actos de grado</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.diligen_personales}
                                                id='diligen_personales' name='diligen_personales' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Diligencias Personales</span>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={6}>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.medica_hijo}
                                                id='medica_hijo' name='medica_hijo' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Cita medica de un Hijo (a)</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.fall_fami}
                                                id='fall_fami' name='fall_fami' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>por fallecimiento de un Familiar</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.tram_acad}
                                                id='tram_acad' name='tram_acad' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Tramites Academicos</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3" style={{ width: '10%' }}>
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark fw-bolder"
                                                aria-describedby="basic-addon1" size="sm" value={dats.cita_pub}
                                                id='cita_pub' name='cita_pub' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Citacion poder publico</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3">
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark"
                                                aria-describedby="basic-addon1" size="sm" value={dats.otro}
                                                id='otro' name='otro' onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>Otro</span>
                                        </div>
                                        <div className="px-5">
                                            <div className="d-flex">
                                                <div className="me-3 d-flex justify-content-center align-items-center">
                                                    <span>Cual</span>
                                                </div>
                                                <div style={{ width: '20rem' }} className='border-dark border-bottom'>
                                                    <Form.Control
                                                        aria-label="Username" className="border-0" onChange={handleInputChange}
                                                        aria-describedby="basic-addon1" size="sm" value={dats.otro_cual}
                                                        id="otro_cual" name="otro_cual"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-3">
                            <Row>
                                <Col md={6}>
                                    <p className="py-2 mb-0">
                                        <span>¿Anexa soporte justificativos?</span>
                                    </p>
                                    <Row>
                                        <Col md={3}>
                                            <div className="d-flex mb-2">
                                                <div className="me-3" style={{ width: '45%' }}>
                                                    <Form.Control
                                                        aria-label="Username" className="fw-bolder text-center border-1 border-dark"
                                                        aria-describedby="basic-addon1" size="sm" onChange={handleInputChange}
                                                        id="anexa_soporte_si" name="anexa_soporte_si" value={dats.anexa_soporte_si}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span>SI</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="d-flex mb-2">
                                                <div className="me-3" style={{ width: '45%' }}>
                                                    <Form.Control
                                                        aria-label="Username" className="fw-bolder text-center border-1 border-dark"
                                                        aria-describedby="basic-addon1" size="sm" onChange={handleInputChange}
                                                        id="anexa_soporte_no" name="anexa_soporte_no" value={dats.anexa_soporte_no}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span>NO</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <div>
                                        <div>
                                            <span>Fecha de salida</span>
                                        </div>
                                        <div style={{ width: '90%' }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                                                <DatePicker
                                                    autoOk format="dd MMMM yyyy"
                                                    value={selectedDate3 === null ? ff1 : selectedDate3}
                                                    onChange={handleDateChange3}
                                                    animateYearScrolling
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div>
                                        <div>
                                            <span>Hora de salida</span>
                                        </div>
                                        <div style={{ width: '90%' }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                                                <TimePicker
                                                    value={selectedDate4}
                                                    onChange={handleDateChange4}
                                                    animateYearScrolling
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <p className="py-2 mb-0">
                                        <span>¿Traera Justificactivo?</span>
                                    </p>
                                    <Row>
                                        <Col md={3}>
                                            <div className="d-flex mb-2">
                                                <div className="me-3" style={{ width: '45%' }}>
                                                    <Form.Control
                                                        aria-label="Username" className="fw-bolder text-center border-1 border-dark"
                                                        aria-describedby="basic-addon1" size="sm" id="anexa_justificativo_si" name="anexa_justificativo_si"
                                                        onChange={handleInputChange} value={dats.anexa_justificativo_si}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span>SI</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={3}>
                                            <div className="d-flex mb-2">
                                                <div className="me-3" style={{ width: '45%' }}>
                                                    <Form.Control
                                                        aria-label="Username" className="fw-bolder text-center border-1 border-dark"
                                                        aria-describedby="basic-addon1" size="sm" id="anexa_justificativo_no" name="anexa_justificativo_no"
                                                        onChange={handleInputChange} value={dats.anexa_justificativo_no}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span>NO</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={3}>
                                    <div>
                                        <div>
                                            <span>Fecha de Ingreso</span>
                                        </div>
                                        <div style={{ width: '90%' }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                                                <DatePicker
                                                    autoOk format="dd MMMM yyyy"
                                                    value={selectedDate1 === null ? ff2 : selectedDate1}
                                                    onChange={handleDateChange1}
                                                    animateYearScrolling
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div>
                                        <div>
                                            <span>Hora de ingreso</span>
                                        </div>
                                        <div style={{ width: '90%' }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['es']}>
                                                <TimePicker
                                                    value={selectedDate2}
                                                    onChange={handleDateChange2}
                                                    animateYearScrolling
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="text-center mt-4 py-1" style={{ background: '#d6dbdf' }}>
                        <div>
                            <span><b>PARA USO ADMINISTRATIVO</b></span>
                        </div>
                    </div>
                    <div> {/* uso administrativo */}
                        <br />
                        <Row>
                            <Col md={3}>
                                <div className="d-flex mb-2">
                                    <div className="me-3" style={{ width: '20%' }}>
                                        <Form.Control
                                            aria-label="Username" className="text-center border-1 border-dark"
                                            aria-describedby="basic-addon1" size="sm" id="remunerado" name="remunerado"
                                            onChange={handleInputChange} value={dats.remunerado}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span>Permiso Remunerado</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="d-flex mb-2">
                                    <div className="me-3" style={{ width: '20%' }}>
                                        <Form.Control
                                            aria-label="Username" className="text-center border-1 border-dark"
                                            aria-describedby="basic-addon1" size="sm" id="no_remunerado" name="no_remunerado"
                                            onChange={handleInputChange} value={dats.no_remunerado}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span>Permiso NO Remunerado</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="d-flex mb-2">
                                    <div className="me-3" style={{ width: '20%' }}>
                                        <Form.Control
                                            aria-label="Username" className="text-center border-1 border-dark"
                                            aria-describedby="basic-addon1" size="sm" id="compensatorio" name="compensatorio"
                                            onChange={handleInputChange} value={dats.compensatorio}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span>Compensatorio</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col md={7}>
                                <div className="d-flex">
                                    <div className="d-flex justify-content-center align-items-center me-3">
                                        <span>¿Este tiempo debe ser recuperable?</span>
                                    </div>
                                    <div className="d-flex mb-2 me-3" style={{ width: '9%' }}>
                                        <div className="me-2">
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark"
                                                aria-describedby="basic-addon1" size="sm" id="tiempo_recuperable_si" name="tiempo_recuperable_si"
                                                onChange={handleInputChange} value={dats.tiempo_recuperable_si}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>SI</span>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2" style={{ width: '10%' }}>
                                        <div className="me-2">
                                            <Form.Control
                                                aria-label="Username" className="text-center border-1 border-dark"
                                                aria-describedby="basic-addon1" size="sm" id="tiempo_recuperable_no" name="tiempo_recuperable_no"
                                                onChange={handleInputChange} value={dats.tiempo_recuperable_no}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span>NO</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="d-flex mb-2">
                                    <div className="d-flex justify-content-center align-items-center me-3">
                                        <span>N°. Horas de Permiso</span>
                                    </div>
                                    <div style={{ width: '40%' }} className='border-dark border-bottom'>
                                        <Form.Control
                                            aria-label="Username" className="text-center border-0"
                                            aria-describedby="basic-addon1" size="sm" id="horas_permiso" name="horas_permiso"
                                            onChange={handleInputChange} value={dats.horas_permiso}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <div className="mb-2">
                                <div className="mb-2">
                                    <span><b>OBSERVACIONES</b></span>
                                </div>
                                <div className="me-3">
                                    <FloatingLabel
                                        controlId="floatingTextarea"
                                        className="mb-3"
                                    >
                                        <Form.Control as="textarea" placeholder="Leave a comment here"
                                            style={{ height: '100px' }} id="observaciones" name="observaciones"
                                            onChange={handleInputChange} className="py-2" value={dats.observaciones}
                                        />
                                    </FloatingLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 container">
                    <Row className="border border-dark">
                        <Col md={4} className="border-dark border-end px-0">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>FIRMA DEL EMPLEADO</b></span>
                            </div>
                            <div className="border-dark border-bottom" style={{ height: '100px' }}></div>
                            <div className="px-2">
                                <span><b>C.C No.</b></span>
                            </div>
                        </Col>
                        <Col md={4} className="border-dark border-end px-0">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>FIRMA JEFE DEPENDENCIA</b></span>
                            </div>
                            <div className="border-dark border-bottom" style={{ height: '100px' }}></div>
                            <div className="px-2">
                                <span><b>Nombre:</b></span>
                            </div>
                        </Col>
                        <Col md={4} className="px-0">
                            <div className="border-dark border-bottom text-center" style={{ background: '#d6dbdf' }}>
                                <span><b>FIRMA RRHH</b></span>
                            </div>
                            <div className="border-dark border-bottom" style={{ height: '100px' }}></div>
                            <div className="px-2">
                                <span><b>Nombre:</b></span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <br /><br /><br />
        </div>
    );
}

export default FormatoPermiso;