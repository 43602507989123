import queryString from "query-string";

export const APIAddress = process.env.REACT_APP_API_ENDPOINT;
const url2 = '?apiCode=';

export const DEDALO_LOGIN = APIAddress + url2 + "login";
export const DEDALO_CREAR_USUARIO = APIAddress + url2 + "create-user";
//export const DEDALO_CREAR_USUARIO_SOPORTE = APIAddress + url2 + "create-support";
//export const DEDALO_CREAR_USUARIO_PLANOS = APIAddress + url2 + "create-blueprints";
//export const DEDALO_CREAR_USUARIO_ALMACEN = APIAddress + url2 + "create-wharehouse";
export const DEDALO_ELIMINAR_USUARIO = APIAddress + url2 + "user";
export const DEDALO_UPDATE_PASSWORD_USER = APIAddress + url2 + "update-user-password";

export const DEDALO_GET_ALL_USERS = APIAddress + url2 + "all-users";

export const DEDALO_SEND_RESET_PASSWORD_EMAIL = APIAddress + url2 + "send-reset-password-email";
export const DEDALO_RESET_PASSWORD = APIAddress + url2 + "reset-password";
export const DEDALO_RESET_PASSWORD_OUT = APIAddress + url2 + "reset-password-out";

export const DEDALO_ACTUALIZAR_USUARIO = APIAddress + url2 + "update-user";
export const DEDALO_GET_ALL_USERS_BY_ID = APIAddress + url2 + "get-user-by-id";
export const DEDALO_USERS_ACTIVE = APIAddress + url2 + "user-active";
export const DEDALO_USERS_INACTIVE = APIAddress + url2 + "user-inactive";
export const DEDALO_USERS_BLOCK = APIAddress + url2 + "user-block";
export const DEDALO_USERS_ADD_ENTERPRICE = APIAddress + url2 + "add-user-enterprice";

export const DEDALO_DELETE_USER_ENTERPRICE = APIAddress + url2 + "delete-user-enterprice";
export const DEDALO_UNDELETE_USER_ENTERPRICE = APIAddress + url2 + "undelete-user-enterprice";
export const DEDALO_ALL_USER_DISCHARGED = APIAddress + url2 + "all-users-discharged";

export const DEDALO_GET_ALL_CONSTRUCTIONS = APIAddress + url2 + "all-constructions";
export const DEDALO_ELIMINAR_CONSTRUCCION = APIAddress + url2 + "delete-constructions";
export const DEDALO_EDITAR_CONSTRUCCION = APIAddress + url2 + "update-constructions";
export const DEDALO_CONSTRUCCION_BY_ID = APIAddress + url2 + "constructions-by-id";
export const DEDALO_NUEVA_CONSTRUCCION = APIAddress + url2 + "create-constructions";
export const DEDALO_REPORT_PROYECT = APIAddress + url2 + "report-proyect";

export const DEDALO_CIUDADES = APIAddress + url2 + "combo-city";

export const DEDALO_ALL_WORKCUT = APIAddress + url2 + "all-workcut";
export const DEDALO_ALL_USER_COMBO = APIAddress + url2 + "combo-user";
export const DEDALO_WORKCUT_BY_ID = APIAddress + url2 + "workcut-by-id";

export const DEDALO_ALL_CONSTRUCTIONS_TASK = APIAddress + url2 + "all-constructions-task";
export const DEDALO_TAREAS_CONSTRUCTION = APIAddress + url2 + "constructions-task";
export const DEDALO_DEL_TAREA_CONSTRUCTION = APIAddress + url2 + "delete-constructions-task";
export const DEDALO_EDIT_TAREA_CONSTRUCTION = APIAddress + url2 + "update-constructions-task";
export const DEDALO_CONSTRUCCION_TASK_BY_ID = APIAddress + url2 + "constructions-task-by-id";
export const DEDALO_CAMBIO_ESTADO_TASK = APIAddress + url2 + "constructions-task-status";
export const DEDALO_TASK_CONSTRUCTION_REPORT = APIAddress + url2 + "constructions-task-report";

export const DEDALO_CUADRILLAS = APIAddress + url2 + "all-gang";
export const DEDALO_DEL_CUADRILLAS = APIAddress + url2 + "delete-gang";
export const DEDALO_ACTUALIZAR_CUADRILLAS = APIAddress + url2 + "update-gang";
export const DEDALO_CREAR_CUADRILLAS = APIAddress + url2 + "create-gang";
export const DEDALO_PERSONAL_CUADRILLAS = APIAddress + url2 + "personal-combo";
export const DEDALO_CUADRILLAS_BY_ID = APIAddress + url2 + "gang-by-id";

export const DEDALO_COMBO_PRODUCTO = APIAddress + url2 + "combo-product";

export const DEDALO_ALL_INVENTORY = APIAddress + url2 + "all-inventory";
export const DEDALO_NUEVO_INVENTARIO = APIAddress + url2 + "create-inventory";
export const DEDALO_ACTUALIZAR_INVENTARIO = APIAddress + url2 + "update-inventory";
export const DEDALO_DEL_INVENTARIO = APIAddress + url2 + "delete-inventory";
export const DEDALO_INVENTARIO_BY_ID = APIAddress + url2 + "inventory-by-id";
export const DEDALO_COMBO_INVENTARIO = APIAddress + url2 + "combo-inventory";

export const DEDALO_CONSTRUCCION_SUBTASK_BY_ID = APIAddress + url2 + "constructions-subtask-by-id";
export const DEDALO_TASK_COMMENTS_BY_ID = APIAddress + url2 + "task-comments-by-id";
export const DEDALO_TASK_COMMENT_BY_ID = APIAddress + url2 + "task-comment-by-id";
export const DEDALO_ALL_TASK_COMMENTS = APIAddress + url2 + "all-task-comments";
export const DEDALO_TASK_COMMENTS = APIAddress + url2 + "create-taskcomment";
export const DEDALO_ALL_TASK_COMMENTS_RESP = APIAddress + url2 + "all-task-responses";
export const DEDALO_TASK_COMMENTS_RESP = APIAddress + url2 + "create-taskresponse";
export const DEDALO_ALL_SUBTASK_COMMENTS = APIAddress + url2 + "all-sub-task-comments";
export const DEDALO_ALL_CONSTRUCTIONS_SUBTASK = APIAddress + url2 + "all-constructions-subtask";
export const DEDALO_SUBTASK_RESP = APIAddress + url2 + "create-subtaskresponse";
export const DEDALO_SUBTASK_COMMENTS = APIAddress + url2 + "create-subtaskcomment";
export const DEDALO_SUBTASK_COMMENTS_RESP = APIAddress + url2 + "all-sub-task-responses";
export const DEDALO_NEW_SUBTASK = APIAddress + url2 + "constructions-subtask";
export const DEDALO_DEL_SUBTASK = APIAddress + url2 + "delete-constructions-subtask";
export const DEDALO_EDIT_SUBTASK = APIAddress + url2 + "update-constructions-subtask";
export const DEDALO_CHANGE_STATUS_SUBTASK = APIAddress + url2 + "constructions-subtask-status";
export const DEDALO_COMMENT_SUBTASK_BY_ID = APIAddress + url2 + "sub-task-comment-by-id";

export const DEDALO_DEL_WORKCUT = APIAddress + url2 + "delete-workcut";
export const DEDALO_NEW_WORKCUT = APIAddress + url2 + "create-workcut";
export const DEDALO_EDIT_WORKCUT = APIAddress + url2 + "update-workcut";

export const DEDALO_ALL_COMMENTS_WORKCUT = APIAddress + url2 + "all-workcut-comments";
export const DEDALO_NEW_COMMENTS_WORKCUT = APIAddress + url2 + "create-workcutcomment";
export const DEDALO_RESP_COMMENTS_WORKCUT = APIAddress + url2 + "create-workresponse";
export const DEDALO_ALL_RES_COMMENTS_WORKCUT = APIAddress + url2 + "all-workcut-responses";

export const DEDALO_ALL_BLUEPRINTS = APIAddress + url2 + "all-blueprint";
export const DEDALO_DEL_BLUEPRINTS = APIAddress + url2 + "delete-blueprint";
export const DEDALO_NEW_BLUEPRINTS = APIAddress + url2 + "create-blueprint";
export const DEDALO_EDIT_BLUEPRINTS = APIAddress + url2 + "update-blueprint";
export const DEDALO_BLUEPRINTS_BY_ID = APIAddress + url2 + "blueprint-by-id";

export const DEDALO_ALL_INVENTORY_MOVE = APIAddress + url2 + "all-inventoryMovement";
export const DEDALO_UPDATE_INVENTORY_MOVE = APIAddress + url2 + "update-inventoryMovement";
export const DEDALO_ALL_INVENTORY_REQUIREMENT = APIAddress + url2 + "all-inventory-request";
export const DEDALO_NEW_INVENTORY_MOVE = APIAddress + url2 + "create-inventoryMovement";
export const DEDALO_NEW_INVENTORY_REQUIREMENT = APIAddress + url2 + "create-inventory-request";
export const DEDALO_DEL_INVENTORY_REQUIREMENT = APIAddress + url2 + "delete-inventory-request";
export const DEDALO_UPDATE_INVENTORY_REQUIREMENT = APIAddress + url2 + "update-inventory-resquest";
export const DEDALO_INVENTORY_MOVE_BY_ID = APIAddress + url2 + "inventory-move-by-id";
export const DEDALO_DEL_INVENTORY_MOVEMENT = APIAddress + url2 + "delete-inventoryMovement";

export const DEDALO_ALL_CRM_ENTERPRICES = APIAddress + url2 + "all-enterprices";
export const DEDALO_NEW_ENTERPRICES = APIAddress + url2 + "create-enterprice";
export const DEDALO_UPDATE_CRM_ENTERPRICES = APIAddress + url2 + "update-enterprice";
export const DEDALO_DEL_CRM_ENTERPRICES = APIAddress + url2 + "delete-enterprice";
export const DEDALO_CRM_ENTERPRICES_BY_ID = APIAddress + url2 + "enterprices-by-id";
export const DEDALO_CRM_ENTERPRICES_COMBO = APIAddress + url2 + "combo-enterprice";

export const DEDALO_ALL_PERSONAL_DEDALO = APIAddress + url2 + "all-personal";
export const DEDALO_NEW_PERSONAL_DEDALO = APIAddress + url2 + "create-personal";
export const DEDALO_PERSONAL_DEDALO_COMBO = APIAddress + url2 + "eps-arl-pension-combo";
export const DEDALO_DEL_PERSONAL = APIAddress + url2 + "delete-personal";
export const DEDALO_EDIT_PERSONAL = APIAddress + url2 + "update-personal";

export const DEDALO_DELETE_PERSONAL_ENTERPRICE = APIAddress + url2 + "delete-personal-enterprice";
export const DEDALO_UNDELETE_PERSONAL_ENTERPRICE = APIAddress + url2 + "undelete-personal-enterprice";
export const DEDALO_ALL_PERSONAL_DISCHARGED = APIAddress + url2 + "all-personal-discharged";

export const DEDALO_ALL_PERSONAL_CONSTRUCTION = APIAddress + url2 + "all-personal-construction";
export const DEDALO_PERSONAL_COMBO_CONSTRUCTION = APIAddress + url2 + "personal-combo";
export const DEDALO_ASIGN_PERSONAL_CONSTRUCTION = APIAddress + url2 + "create-personal-const";
export const DEDALO_DEL_PERSONAL_CONSTRUCTION = APIAddress + url2 + "delete-personal-construction";
export const DEDALO_COMBO_CONSTRUCTION = APIAddress + url2 + "combo-constructions";
export const DEDALO_PERSONAL_BY_ID = APIAddress + url2 + "personal-by-id";

export const DEDALO_ALL_PRODUCTS = APIAddress + url2 + "all-product";
export const DEDALO_NEW_PRODUCTS = APIAddress + url2 + "create-product";
export const DEDALO_UPDATE_PRODUCTS = APIAddress + url2 + "update-product";
export const DEDALO_DEL_PRODUCTS = APIAddress + url2 + "delete-product";
export const DEDALO_PRODUCT_TYPE_COMBO = APIAddress + url2 + "combo-product-type";
export const DEDALO_PRODUCT_BY_ID = APIAddress + url2 + "product-by-id";

export const DEDALO_VENDOR_COMBO = APIAddress + url2 + "combo-crm-vendors";
export const DEDALO_ALL_PRODUCT_TYPE = APIAddress + url2 + "all-product-type";
export const DEDALO_NEW_PRODUCT_TYPE = APIAddress + url2 + "create-product-type";
export const DEDALO_DEL_PRODUCT_TYPE = APIAddress + url2 + "delete-product-type";
export const DEDALO_UPDATE_PRODUCT_TYPE = APIAddress + url2 + "update-product-type";
export const DEDALO_PRODUCT_TYPE_BY_ID = APIAddress + url2 + "product-type-by-id";

export const DEDALO_ALL_CRM_VENDORS = APIAddress + url2 + "all-crm-vendors";
export const DEDALO_NEW_CRM_VENDORS = APIAddress + url2 + "create-crm-vendors";
export const DEDALO_DEL_CRM_VENDORS = APIAddress + url2 + "delete-crm-vendors";
export const DEDALO_UPDATE_CRM_VENDORS = APIAddress + url2 + "update-crm-vendors";
export const DEDALO_CRM_VENDORS_BY_ID = APIAddress + url2 + "crm-vendors-by-id";
export const DEDALO_COMBO_VENDORS = APIAddress + url2 + "combo-crm-vendors";
export const DEDALO_COMBO_VENDORS_FULL = APIAddress + url2 + "combo-crm-vendors-full";

export const DEDALO_ALL_FOLDERS = APIAddress + url2 + "all-folders";
export const DEDALO_NEW_FOLDERS = APIAddress + url2 + "create-folder";
export const DEDALO_DEL_FOLDERS = APIAddress + url2 + "delete-folder";
export const DEDALO_UPDATE_FOLDERS = APIAddress + url2 + "update-folder";
export const DEDALO_SHARED_FOLDERS = APIAddress + url2 + "shared-folder";
export const DEDALO_FOLDERS_BY_ID = APIAddress + url2 + "folder-by-id";

export const DEDALO_ALL_FILES = APIAddress + url2 + "all-files";
export const DEDALO_NEW_FILES = APIAddress + url2 + "create-file";
export const DEDALO_DEL_FILES = APIAddress + url2 + "delete-file";
export const DEDALO_UPDATE_FILES = APIAddress + url2 + "files";
export const DEDALO_FILES_BY_ID = APIAddress + url2 + "files-by-id";

export const DEDALO_ALL_SEND_FILES = APIAddress + url2 + "all-send-files";
export const DEDALO_DEL_SEND_FILES = APIAddress + url2 + "delete-send-file";
export const DEDALO_NEW_SEND_FILES = APIAddress + url2 + "create-send-file";
export const DEDALO_SEND_FILES_BY_ID = APIAddress + url2 + "sendfiles-by-id";
export const DEDALO_EDIT_SEND_FILES = APIAddress + url2 + "update-send-file";

export const DEDALO_BUDGET_PROJECT = APIAddress + url2 + "budget-project";
export const DEDALO_EXECUTE_TASK = APIAddress + url2 + "executed-task";
export const DEDALO_EXECUTE_BUDGET_PROJECT = APIAddress + url2 + "executed-budget-project";
export const DEDALO_GRAPH_TASK_ITEMS = APIAddress + url2 + "constructions-task-typeitems";

export const DEDALO_ALL_MEETING = APIAddress + url2 + "all-meeting";
export const DEDALO_ALL_MEETING_CONTRUCTION = APIAddress + url2 + "all-meeting-contruction";
export const DEDALO_NEW_MEETING = APIAddress + url2 + "create-meeting";
export const DEDALO_EDIT_MEETING = APIAddress + url2 + "update-meeting";
export const DEDALO_EDIT_MEETING_FILE = APIAddress + url2 + "update-meeting-file";
export const DEDALO_EDIT_MEETING_COMMENT = APIAddress + url2 + "update-meeting-comment";
export const DEDALO_MEETING_BY_ID = APIAddress + url2 + "meeting-by-id";
export const DEDALO_MEETING_FILE = APIAddress + url2 + "meeting-file";
export const DEDALO_MEETING_COMMENT = APIAddress + url2 + "meeting-comment";
export const DEDALO_DEL_MEETING = APIAddress + url2 + "delete-meeting";
export const DEDALO_DEL_MEETING_FILE = APIAddress + url2 + "delete-meeting-file";
export const DEDALO_DEL_MEETING_COMMENT = APIAddress + url2 + "delete-meeting-comment";
export const DEDALO_SEND_MEETING_EMAIL = APIAddress + url2 + "send-mail";

export const DEDALO_ALL_DEPARTAMENT = APIAddress + url2 + "all-department";
export const DEDALO_NEW_DEPARTAMENT = APIAddress + url2 + "create-department";
export const DEDALO_UPDATE_DEPARTAMENT = APIAddress + url2 + "update-department";
export const DEDALO_DEL_DEPARTAMENT = APIAddress + url2 + "delete-department";
export const DEDALO_DEPARTAMENT_COMBO = APIAddress + url2 + "combo-department";
export const DEDALO_DEPARTAMENT_BY_ID = APIAddress + url2 + "department-by-id";
export const DEDALO_ORGANIZATION_CHART = APIAddress + url2 + "organization-chart";

export const DEDALO_ALL_FLOWCHART = APIAddress + url2 + "all-flowcharts";
export const DEDALO_NEW_FLOWCHART = APIAddress + url2 + "create-flowchart";
export const DEDALO_UPDATE_FLOWCHART = APIAddress + url2 + "update-flowchart";
export const DEDALO_DEL_FLOWCHART = APIAddress + url2 + "delete-flowchart";
export const DEDALO_FLOWCHART_COMBO = APIAddress + url2 + "combo-flowchart";
export const DEDALO_FLOWCHART_BY_ID = APIAddress + url2 + "flowchart-by-id";
export const DEDALO_FLOWCHART_DUPLICATE = APIAddress + url2 + "duplicate-flowchart";

export const DEDALO_ALL_MEASUREMENTS = APIAddress + url2 + "all-measurements";
export const DEDALO_NEW_MEASUREMENTS = APIAddress + url2 + "create-measurements";
export const DEDALO_UPDATE_MEASUREMENTS = APIAddress + url2 + "update-measurements";
export const DEDALO_DEL_MEASUREMENTS = APIAddress + url2 + "delete-measurements";
export const DEDALO_MEASUREMENTS = APIAddress + url2 + "combo-measurements";
export const DEDALO_MEASUREMENTS_BY_ID = APIAddress + url2 + "measurement-by-id";
export const DEDALO_MEASUREMENTS_COMBO = APIAddress + url2 + "combo-measurements";

export const DEDALO_ALL_TYPE_ITEMS = APIAddress + url2 + "all-type-items";
export const DEDALO_NEW_TYPE_ITEMS = APIAddress + url2 + "create-type-items";
export const DEDALO_UPDATE_TYPE_ITEMS = APIAddress + url2 + "update-type-items";
export const DEDALO_DEL_TYPE_ITEMS = APIAddress + url2 + "delete-type-items";
export const DEDALO_TYPE_ITEMS_COMBO = APIAddress + url2 + "combo-type-items";
export const DEDALO_TYPE_ITEMS_BY_ID = APIAddress + url2 + "type-items-by-id";

export const DEDALO_ALL_REQUISITIONS = APIAddress + url2 + "all-requisitions";
export const DEDALO_NEW_REQUISITIONS = APIAddress + url2 + "create-requisitions";
export const DEDALO_UPDATE_REQUISITIONS = APIAddress + url2 + "update-requisitions";
export const DEDALO_DEL_REQUISITIONS = APIAddress + url2 + "delete-requisitions";
export const DEDALO_REQUISITIONS_COMBO = APIAddress + url2 + "combo-requisitions";
export const DEDALO_REQUISITIONS_BY_ID = APIAddress + url2 + "requisitions-by-id";

export const DEDALO_ALL_COST_CENTER = APIAddress + url2 + "all-cost-center";
export const DEDALO_NEW_COST_CENTER = APIAddress + url2 + "create-cost-center";
export const DEDALO_UPDATE_COST_CENTER = APIAddress + url2 + "update-cost-center";
export const DEDALO_DEL_COST_CENTER = APIAddress + url2 + "delete-cost-center";
export const DEDALO_COST_CENTER_COMBO = APIAddress + url2 + "combo-cost-center";
export const DEDALO_COST_CENTER_BY_ID = APIAddress + url2 + "cost-center-by-id";

export const DEDALO_ALL_SHOPPING_SUPPLIES = APIAddress + url2 + "all-shopping-supplies";
export const DEDALO_NEW_SHOPPING_SUPPLIES = APIAddress + url2 + "create-shopping-supplies";
export const DEDALO_UPDATE_SHOPPING_SUPPLIES = APIAddress + url2 + "update-shopping-supplies";
export const DEDALO_DEL_SHOPPING_SUPPLIES = APIAddress + url2 + "delete-shopping-supplies";
export const DEDALO_SHOPPING_SUPPLIES_BY_ID = APIAddress + url2 + "shopping-supplies-by-id";

export const DEDALO_ALL_QUOTATION = APIAddress + url2 + "all-quotation";
export const DEDALO_NEW_QUOTATION = APIAddress + url2 + "create-quotation";
export const DEDALO_UPDATE_QUOTATION = APIAddress + url2 + "update-quotation";
export const DEDALO_DEL_QUOTATION = APIAddress + url2 + "delete-quotation";
export const DEDALO_QUOTATION_COMBO = APIAddress + url2 + "combo-quotation";
export const DEDALO_QUOTATION_BY_ID = APIAddress + url2 + "quotation-by-id";
export const DEDALO_UPDATE_QUOTATION_STATUS = APIAddress + url2 + "update-quotation-status";
export const DEDALO_CLONE_QUOTATION = APIAddress + url2 + "clone-quotation";

export const DEDALO_ALL_START_CERTIFICATE = APIAddress + url2 + "all-start-certificate";
export const DEDALO_NEW_START_CERTIFICATE = APIAddress + url2 + "create-start-certificate";
export const DEDALO_UPDATE_START_CERTIFICATE = APIAddress + url2 + "update-start-certificate";
export const DEDALO_DEL_START_CERTIFICATE = APIAddress + url2 + "delete-start-certificate";
export const DEDALO_START_CERTIFICATE_BY_ID = APIAddress + url2 + "start-certificate-by-id";

export const DEDALO_ALL_ADVANCES = APIAddress + url2 + "all-advances";
export const DEDALO_NEW_ADVANCES = APIAddress + url2 + "create-advances";
export const DEDALO_UPDATE_ADVANCES = APIAddress + url2 + "update-advances";
export const DEDALO_DEL_ADVANCES = APIAddress + url2 + "delete-advances";
export const DEDALO_ADVANCES_BY_ID = APIAddress + url2 + "advances-by-id";

export const DEDALO_ALL_LEGALIZATION = APIAddress + url2 + "all-legalization";
export const DEDALO_NEW_LEGALIZATION = APIAddress + url2 + "create-legalization";
export const DEDALO_UPDATE_LEGALIZATION = APIAddress + url2 + "update-legalization";
export const DEDALO_DEL_LEGALIZATION = APIAddress + url2 + "delete-legalization";
export const DEDALO_LEGALIZATION_BY_ID = APIAddress + url2 + "legalization-by-id";

export const DEDALO_ALL_NO_CONFORMIDAD = APIAddress + url2 + "all-not-conforming";
export const DEDALO_NEW_NO_CONFORMIDAD = APIAddress + url2 + "create-not-conforming";
export const DEDALO_UPDATE_NO_CONFORMIDAD = APIAddress + url2 + "update-not-conforming";
export const DEDALO_DEL_NO_CONFORMIDAD = APIAddress + url2 + "delete-not-conforming";
export const DEDALO_NO_CONFORMIDAD_BY_ID = APIAddress + url2 + "not-conforming-by-id";

export const DEDALO_ALL_INDICATORS = APIAddress + url2 + "all-indicators";
export const DEDALO_NEW_INDICATORS = APIAddress + url2 + "create-indicators";
export const DEDALO_UPDATE_INDICATORS = APIAddress + url2 + "update-indicators";
export const DEDALO_DEL_INDICATORS = APIAddress + url2 + "delete-indicators";
export const DEDALO_INDICATORS_BY_ID = APIAddress + url2 + "indicators-by-id";

export const DEDALO_ALL_JOBPROFILE = APIAddress + url2 + "all-job-profile";
export const DEDALO_NEW_JOBPROFILE = APIAddress + url2 + "create-job-profile";
export const DEDALO_UPDATE_JOBPROFILE = APIAddress + url2 + "update-job-profile";
export const DEDALO_DEL_JOBPROFILE = APIAddress + url2 + "delete-job-profile";
export const DEDALO_JOBPROFILE_BY_ID = APIAddress + url2 + "job-profile-by-id";

export const DEDALO_ALL_SELECCION_PROVEEDOR = APIAddress + url2 + "all-supplier-selection";
export const DEDALO_NEW_SELECCION_PROVEEDOR = APIAddress + url2 + "create-supplier-selection";
export const DEDALO_UPDATE_SELECCION_PROVEEDOR = APIAddress + url2 + "update-supplier-selection";
export const DEDALO_DEL_SELECCION_PROVEEDOR = APIAddress + url2 + "delete-supplier-selection";
export const DEDALO_SELECCION_PROVEEDOR_BY_ID = APIAddress + url2 + "supplier-selection-by-id";

export const DEDALO_ALL_EVALUACION_PROVEEDOR = APIAddress + url2 + "all-supplier-evaluation";
export const DEDALO_NEW_EVALUACION_PROVEEDOR = APIAddress + url2 + "create-supplier-evaluation";
export const DEDALO_UPDATE_EVALUACION_PROVEEDOR = APIAddress + url2 + "update-supplier-evaluation";
export const DEDALO_DEL_EVALUACION_PROVEEDOR = APIAddress + url2 + "delete-supplier-evaluation";
export const DEDALO_EVALUACION_PROVEEDOR_BY_ID = APIAddress + url2 + "supplier-evaluation-by-id";

export const DEDALO_ALL_REEVALUACION_PROVEEDOR = APIAddress + url2 + "all-supplier-revaluation";
export const DEDALO_NEW_REEVALUACION_PROVEEDOR = APIAddress + url2 + "create-supplier-revaluation";
export const DEDALO_UPDATE_REEVALUACION_PROVEEDOR = APIAddress + url2 + "update-supplier-revaluation";
export const DEDALO_DEL_REEVALUACION_PROVEEDOR = APIAddress + url2 + "delete-supplier-revaluation";
export const DEDALO_REEVALUACION_PROVEEDOR_BY_ID = APIAddress + url2 + "supplier-revaluation-by-id";

export const DEDALO_ALL_COMPARATIVO = APIAddress + url2 + "all-comparative-table";
export const DEDALO_NEW_COMPARATIVO = APIAddress + url2 + "create-comparative-table";
export const DEDALO_UPDATE_COMPARATIVO = APIAddress + url2 + "update-comparative-table";
export const DEDALO_DEL_COMPARATIVO = APIAddress + url2 + "delete-comparative-table";
export const DEDALO_COMPARATIVO_BY_ID = APIAddress + url2 + "comparative-table-by-id";

export const DEDALO_ALL_SALIDA_EQUIPO = APIAddress + url2 + "all-out-equipment";
export const DEDALO_NEW_SALIDA_EQUIPO = APIAddress + url2 + "create-out-equipment";
export const DEDALO_UPDATE_SALIDA_EQUIPO = APIAddress + url2 + "update-out-equipment";
export const DEDALO_DEL_SALIDA_EQUIPO = APIAddress + url2 + "delete-out-equipment";
export const DEDALO_SALIDA_EQUIPO_BY_ID = APIAddress + url2 + "out-equipment-by-id";
export const DEDALO_SALIDA_EQUIPO_COMBO = APIAddress + url2 + "combo-out-equipment";

export const DEDALO_ALL_FORM_SHOP = APIAddress + url2 + "all-forms";
export const DEDALO_NEW_FORM_SHOP = APIAddress + url2 + "create-forms";
export const DEDALO_UPDATE_FORM_SHOP = APIAddress + url2 + "update-form-shop";
export const DEDALO_DEL_FORM_SHOP = APIAddress + url2 + "delete-form-shop";
export const DEDALO_FORM_SHOP_BY_ID = APIAddress + url2 + "forms-by-id";
export const DEDALO_FORM_SHOP_COMBO = APIAddress + url2 + "combo-forms";

export const DEDALO_ALL_TEMPORARY_EVENT = APIAddress + url2 + "all-temporary-event";
export const DEDALO_NEW_TEMPORARY_EVENT = APIAddress + url2 + "create-temporary-event";
export const DEDALO_UPDATE_TEMPORARY_EVENT = APIAddress + url2 + "update-temporary-event";
export const DEDALO_DEL_TEMPORARY_EVENT = APIAddress + url2 + "delete-temporary-event";
export const DEDALO_TEMPORARY_EVENT_BY_ID = APIAddress + url2 + "temporary-event-by-id";
export const DEDALO_TEMPORARY_EVENT_COMBO = APIAddress + url2 + "combo-temporary-event";

export const DEDALO_ALL_INVENTORY_GENERAL = APIAddress + url2 + "all-inventory-general";
export const DEDALO_INVENTORY_GENERAL_BY_ID = APIAddress + url2 + "inventory-general-by-id";
export const DEDALO_INVENTORY_GENERAL_COMBO = APIAddress + url2 + "combo-inventory-general";
export const DEDALO_NEW_INVENTORY_GENERAL = APIAddress + url2 + "create-inventory-general";
export const DEDALO_UPDATE_INVENTORY_GENERAL = APIAddress + url2 + "update-inventory-general";
export const DEDALO_DEL_INVENTORY_GENERAL = APIAddress + url2 + "delete-inventory-general";

export const DEDALO_ALL_MOVE_INVENTORY_GENERAL = APIAddress + url2 + "all-inventoryMovement-general";
export const DEDALO_MOVE_INVENTORY_GENERAL_BY_ID = APIAddress + url2 + "inventory-move-general-by-id";
export const DEDALO_NEW_MOVE_INVENTORY_GENERAL = APIAddress + url2 + "create-inventoryMovement-general";
export const DEDALO_UPDATE_MOVE_INVENTORY_GENERAL = APIAddress + url2 + "update-inventoryMovement-general";
export const DEDALO_DEL_MOVE_INVENTORY_GENERAL = APIAddress + url2 + "delete-inventoryMovement-general";

export const DEDALO_ALL_REQ_INVENTORY_GENERAL = APIAddress + url2 + "all-inventory-general-request";
export const DEDALO_REQ_INVENTORY_GENERAL_BY_ID = APIAddress + url2 + "inventory-general-request-by-id";
export const DEDALO_NEW_REQ_INVENTORY_GENERAL = APIAddress + url2 + "create-inventory-general-request";
export const DEDALO_UPDATE_REQ_INVENTORY_GENERAL = APIAddress + url2 + "update-inventory-general-resquest";
export const DEDALO_DEL_REQ_INVENTORY_GENERAL = APIAddress + url2 + "delete-inventory-general-request";

export const DEDALO_ALL_CHAT_MSM = APIAddress + url2 + "all-msm-chat";
export const DEDALO_CHAT_MSM_BY_ID = APIAddress + url2 + "all-msm-chat-by-id";
export const DEDALO_NEW_CHAT_MSM = APIAddress + url2 + "create-msm-chat";
export const DEDALO_UPDATE_CHAT_MSM = APIAddress + url2 + "update-msm-chat";
export const DEDALO_DEL_CHAT_MSM = APIAddress + url2 + "delete-msm-chat";

export const DEDALO_ALL_PERMISOS = APIAddress + url2 + "all-permisos";
export const DEDALO_PERMISOS_BY_ID = APIAddress + url2 + "permisos-by-id";
export const DEDALO_NEW_PERMISOS = APIAddress + url2 + "create-permisos";
export const DEDALO_UPDATE_PERMISOS = APIAddress + url2 + "update-permisos";
export const DEDALO_DEL_PERMISOS = APIAddress + url2 + "delete-permisos";
export const DEDALO_CHANGE_STATUS_PERMISOS = APIAddress + url2 + "change-status-permisos";

export const DEDALO_ALL_VACACIONES = APIAddress + url2 + "all-vacaciones";
export const DEDALO_VACACIONES_BY_ID = APIAddress + url2 + "vacaciones-by-id";
export const DEDALO_NEW_VACACIONES = APIAddress + url2 + "create-vacaciones";
export const DEDALO_UPDATE_VACACIONES = APIAddress + url2 + "update-vacaciones";
export const DEDALO_DEL_VACACIONES = APIAddress + url2 + "delete-vacaciones";
export const DEDALO_CHANGE_STATUS_VACACIONES = APIAddress + url2 + "change-status-vacaciones";

export const DEDALO_ALL_ADMINFILES = APIAddress + url2 + "all-adminfiles";
export const DEDALO_ADMINFILES_BY_ID = APIAddress + url2 + "adminfiles-by-id";
export const DEDALO_NEW_ADMINFILES = APIAddress + url2 + "create-adminfiles";
export const DEDALO_EDIT_ADMINFILES = APIAddress + url2 + "update-adminfiles";
export const DEDALO_DEL_ADMINFILES = APIAddress + url2 + "delete-adminfiles";

export const DEDALO_ALL_SEGUIMIENTO_ACTVIDAD = APIAddress + url2 + "all-seguimiento-actividad";
export const DEDALO_SEGUIMIENTO_ACTVIDAD_BY_ID = APIAddress + url2 + "seguimiento-actividad-by-id";
export const DEDALO_NEW_SEGUIMIENTO_ACTVIDAD = APIAddress + url2 + "create-seguimiento-actividad";
export const DEDALO_UPDATE_SEGUIMIENTO_ACTVIDAD = APIAddress + url2 + "update-seguimiento-actividad";
export const DEDALO_DEL_SEGUIMIENTO_ACTVIDAD = APIAddress + url2 + "delete-seguimiento-actividad";