import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Row, Col, Accordion, Dropdown, FormControl,
    DropdownButton,Modal, Button, InputGroup, 
    Form,
} from 'react-bootstrap'
import {
    DEDALO_ALL_INVENTORY, DEDALO_DEL_INVENTARIO,DEDALO_NEW_INVENTORY_REQUIREMENT,
    DEDALO_NEW_INVENTORY_MOVE,DEDALO_ALL_INVENTORY_MOVE,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import { 
    attachToken,
    manageError,
} from "../../../utils/utils";
import Swal from "sweetalert2";
import {useUserSession} from "../../../hooks";
import EditarInventario from './modalesalmacen/editarinventario';
import * as BsIcons from "react-icons/bs";
import * as FaIcons from "react-icons/fa";
import { Routes } from "../../../utils/routes";

function Almacenhomi() {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [inventarios, setInventarios] = useState();
    const [inventarios2, setInventarios2] = useState();
    const [productoselect, setProductoSelect] = useState('');
    
    const [categor, setCategor] = useState({
        datos: [],
    });

    const [categormove, setCategorMove] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        monto: '',
        obs: '',
    });

    const [inputfilter, setInputFilter] = useState({
        formulario: '',
    });

    const [infofiltro, setInfoFiltro] = useState({
        datos: '',
    });

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (e) => {
        setInventarios(e);
        setShow1(true);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setInventarios2(e);
        setShow2(true);
    };

    const handleChangeEstadoTarea = (event) => {
        setProductoSelect(event.target.value);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeInputFilter = (event) => {   
        setInputFilter({
            ...inputfilter,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetCategor=async()=>{
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 900,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_INVENTORY+'?page=1&rows=1000&constructions_id='+id+'&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_INVENTORY,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                ...categor,
                ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetAllMove=async()=>{
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 100,
            page: 1,
            //orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_INVENTORY_MOVE+'?page=1&rows=100&constructions_id='+idProject+'&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_INVENTORY_MOVE,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.records);
                if(datanotice.records.length > 0){
                    setCategorMove({
                        ...categormove,
                        ['datos']: datanotice.records,
                    });
                }
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };

    const peticionDelete = (iduser) => {
        let delId = iduser;
            
        //axios.delete(DEDALO_DEL_INVENTARIO(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_INVENTARIO+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el item exitosamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                //alert("ERROR: Server Error");
                Swal.fire("No se puede eliminar el inventario, tiene usuarios asignados", "", "warning");
            }
        })
        
    };

    useEffect(() => {
        peticionGetCategor();
        //peticionGetAllMove();
    }, []);
    
    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let monto = dats.monto;
        let observa = dats.obs;
        
        const formattedData = {
            inventory_id: inventarios,
            type: productoselect,
            amount: monto,
            user_id: userId,
            observations: observa,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_INVENTORY_MOVE, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_NEW_INVENTORY_MOVE,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se hizo movimiento de inventario exitósamente", "", "success");
                document.getElementById("monto").value = '';
                document.getElementById("obs").value = '';
                document.getElementById("select").value = '';
                peticionGetCategor();
                peticionGetAllMove();
                handleClose1();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    const handleCloseSaveReq = (e) => {
    
        //e.preventDefault();
        let monto = dats.monto;
        let observa = dats.obs;
        
        const formattedData = {
            inventory_id: inventarios2,
            user_id: userId,
            amount: monto,
            observations: observa,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_INVENTORY_REQUIREMENT, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_NEW_INVENTORY_REQUIREMENT,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se hizo requerimiento de inventario exitósamente", "", "success");
                document.getElementById("monto").value = '';
                document.getElementById("obs").value = '';
            } else {
                alert("ERROR: Server Error");
            }
        })
        /*.catch(error => {
            manageError(error);
        });*/
    };

    const filtrar = async(e) => {
        e.preventDefault();
        let input_text = inputfilter.formulario;

        if(input_text === ''){
            setInfoFiltro({
                ...infofiltro,
                ['datos']: [],
            });
        }else{
            const params_data = {
                constructions_id: id,
                search: input_text,
                perpage: 900,
                page: 1,
                orderField: 'name',
                order: 'asc',
            };
            //console.log(params_data);
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_INVENTORY,
                headers: {Authorization: userToken},
                data: params_data
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    if(datanotice.length === 0){
                        mensaje_data(input_text);
                    }else{
                        setInfoFiltro({
                            ...infofiltro,
                            ['datos']: datanotice.records,
                        });
                    }
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const mensaje_data = (texto_in) => {
        setInfoFiltro({
            ...infofiltro,
            ['datos']: [],
        });
        return Swal.fire("No hay datos con el nombre o iniciales "+texto_in+" ingresado", "", "warning");
    };
    
    return (
        <div className="almacen_vista">
            <Accordion flush className="almacen_acordeon">
                {categor.datos === undefined ?
                    ''
                :
                    <div>
                        <div className="mb-3 w-100 d-flex justify-content-center"> {/* barra de busqueda */}
                            <div>
                                <Form className="d-flex">
                                    <FormControl
                                        type="search" size="sm"
                                        placeholder="Ingrese el nombre"
                                        className="me-2"
                                        aria-label="Buscar"
                                        id="formulario"
                                        name="formulario"
                                        style={{width: '100%'}}
                                        onChange={handleInputChangeInputFilter}
                                    />
                                    <Button id="buscar" size="sm" onClick={filtrar} className="boton_buscar">
                                        Buscar
                                    </Button>
                                </Form>
                            </div>
                        </div>
                        {infofiltro.datos.length === 0 ?
                            <div> {/* tabla de datos */}
                                {categor.datos.map(s => (
                                    <Accordion.Item eventKey={s.id} key={s.id}>
                                        <Accordion.Header>{s.name}</Accordion.Header>
                                        <Accordion.Body className="tableactions">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    Acciones del almacén
                                                </div>
                                                <div >
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark" 
                                                    >
                                                        <Dropdown.Item onClick={()=>peticionDelete(s.id)}>
                                                            Eliminar
                                                        </Dropdown.Item>
                                                        <Dropdown.Item >
                                                            <EditarInventario Iditem={s.id} actualizar={peticionGetCategor}/>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>{handleShow2(s.id)}}>
                                                            Requerimiento nuevo
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>handleShow1(s.id)}>
                                                            <span >
                                                                Movimiento de inventario
                                                            </span>
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Nombre del proyecto: {s.constructionsName}
                                                    </p>
                                                    <p>
                                                        Direccion del proyecto: {s.constructionsaddress}
                                                    </p>
                                                    <p>
                                                        Producto: {s.productTypeName}
                                                    </p>
                                                    <p>
                                                        Cantidad total: {new Intl.NumberFormat("de-DE").format(parseInt(s.amount))+ ' ' + s.productTypemeasurement}
                                                    </p>
                                                    <p>
                                                        Cantidad minima: {new Intl.NumberFormat("de-DE").format(parseInt(s.minimum_amount))+ ' ' + s.productTypemeasurement}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </div>
                        :
                            <div> {/* tabla de datos filtrados */}
                                {infofiltro.datos.map(z => (
                                    <Accordion.Item eventKey={z.id} key={z.id}>
                                        <Accordion.Header>{z.name}</Accordion.Header>
                                        <Accordion.Body className="tableactions">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    Acciones del almacén
                                                </div>
                                                <div >
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark" 
                                                    >
                                                        <Dropdown.Item onClick={()=>peticionDelete(z.id)}>
                                                            Eliminar Item
                                                        </Dropdown.Item>
                                                        <Dropdown.Item >
                                                            <EditarInventario Iditem={z.id} actualizar={peticionGetCategor}/>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>{handleShow2(z.id)}}>
                                                            Requerimiento nuevo
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>handleShow1(z.id)}>
                                                            <span >
                                                                Movimiento de inventario
                                                            </span>
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col>
                                                    <p>
                                                        Nombre del proyecto: {z.constructionsName}
                                                    </p>
                                                    <p>
                                                        Direccion del proyecto: {z.constructionsaddress}
                                                    </p>
                                                    <p>
                                                        Producto: {z.productTypeName}
                                                    </p>
                                                    <p>
                                                        Cantidad total: {new Intl.NumberFormat("de-DE").format(parseInt(z.amount))+ ' ' + z.productTypemeasurement}
                                                    </p>
                                                    <p>
                                                        Cantidad minima: {new Intl.NumberFormat("de-DE").format(parseInt(z.minimum_amount))+ ' ' + z.productTypemeasurement}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </div>
                        }    
                    </div>
                }
            </Accordion>

            {/* MODAL Movimiento nuevo inventario */}
            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo Movimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaSortAmountUpAlt className="me-2 text-primary"/>
                                    Cantidad
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Monto"
                                    aria-describedby="basic-addon1"
                                    type="email" onChange={handleInputChange}
                                    id="monto" name="monto"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1" className="text-primary">
                                    <FaIcons.FaElementor/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeEstadoTarea} id="select" name="select"
                                >
                                    <option value="">Seleccione una opcion</option>
                                    <option value='in'>Entrada</option>
                                    <option value='out'>Salida</option>
                                </Form.Select>
                            </InputGroup> 
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" 
                                        onChange={handleInputChange} id="obs" name="obs"
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSave}>
                        Confirmar y Guardar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL nuevo requerimiento */}
            <Modal show={show2} onHide={handleClose2} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo Movimiento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaSortAmountUpAlt className="me-2 text-primary"/>
                                    Cantidad
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Monto"
                                    aria-describedby="basic-addon1"
                                    type="email" onChange={handleInputChange}
                                    id="monto" name="monto"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" 
                                        onChange={handleInputChange} id="obs" name="obs"
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSaveReq}>
                        Confirmar y Guardar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
};

export default Almacenhomi
