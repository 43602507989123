import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,Dropdown,
} from 'react-bootstrap';
import {
    DEDALO_ALL_PRODUCT_TYPE,DEDALO_NEW_PRODUCT_TYPE,DEDALO_DEL_PRODUCT_TYPE,
    DEDALO_UPDATE_PRODUCT_TYPE,DEDALO_PRODUCT_TYPE_BY_ID,
    DEDALO_ALL_MEASUREMENTS,DEDALO_NEW_MEASUREMENTS,DEDALO_UPDATE_MEASUREMENTS,
    DEDALO_DEL_MEASUREMENTS,DEDALO_MEASUREMENTS,DEDALO_MEASUREMENTS_BY_ID,
    DEDALO_MEASUREMENTS_COMBO,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Navbar from '../navbar';
import Swal from "sweetalert2";
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";


const TablaProductos = () => {
    const {userToken} = useUserSession();
    const {user} = useUserSession();
    const [productedittype, setProductEditType] = useState();
    const [unidadmedida, setUnidadMedida] = useState('');
    const [unidadmedidaedit, setUnidadMedidaEdit] = useState('');

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [combo_unidades, setCombo_Unidades] = useState({
        datos: [],
    });

    const [categorunidades, setCategorUnidades] = useState({
        datos: [],
    });

    const [inputfilter, setInputFilter] = useState({
        formulario: '',
    });

    const [infofiltro, setInfoFiltro] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        name: '',
        unidad: '',
        tipo_unidad: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        name_edit: '',
        unidad_edit: '',
        tipo_unidad_edit: '',
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PRODUCT_TYPE+'?page=1&rows=1000&order=measurement&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_PRODUCT_TYPE,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                  //console.log(datanotice.records);
                  setCategor({
                    ...categor,
                    ['datos']: datanotice.records,
                  });
            } else {
                //alert(message_responses[response.data.message]);
                alert('error tipo producto 1');
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetTypeProductById=async()=>{
        if(productedittype === undefined){
             let iu = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_PRODUCT_TYPE_BY_ID+'&id='+productedittype,
                headers: {Authorization: userToken},
            })
              .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setDat_Edit({
                        ...dats_edit,
                        ['name_edit']: datanotice.data.name,
                        ['unidad_edit']: datanotice.data.measurement,
                    });
                  } else {
                     //alert(message_responses[response.data.message]);
                     alert('error tipo producto 2');
                  }
              })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                  }
              });
          
            return info1;
        }
    };

    const peticionGetUnitById=async(IdUnit)=>{
        let Id_unidad_edit = IdUnit;
        if(Id_unidad_edit === undefined){
             let iu = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_MEASUREMENTS_BY_ID+'&id='+Id_unidad_edit,
                headers: {Authorization: userToken},
            })
              .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setDat_Edit({
                        ...dats_edit,
                        ['tipo_unidad_edit']: datanotice.data.name,
                    });
                  } else {
                     //alert(message_responses[response.data.message]);
                     alert('error tipo producto 2');
                  }
              })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                  }
              });
          
            return info1;
        }
    };

    const peticionGetComboUnidades=async()=>{
        
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_MEASUREMENTS_COMBO,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setCombo_Unidades({
                    ...combo_unidades,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 23");
            }
        });
      
        return info1;
    };

    const peticionGetCategorUnidades=async()=>{
        const params_data = {
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PRODUCT_TYPE+'?page=1&rows=1000&order=measurement&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_MEASUREMENTS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                  //console.log(datanotice.records);
                  setCategorUnidades({
                    ...categorunidades,
                    ['datos']: datanotice.records,
                  });
            } else {
                //alert(message_responses[response.data.message]);
                alert('error tipo producto 1');
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetComboUnidades();
        peticionGetCategorUnidades();
    }, []);

    useEffect(() => {
        peticionGetTypeProductById();
    }, [productedittype]);

    useEffect(() => {
        peticionGetUnitById();
    }, []);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (e) => {
        setShow1(true);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setShow3(true);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (e) => {
        setShow4(true);
    };

    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = (e) => {
        setUnidadMedidaEdit(e);
        setShow5(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {   
        setDat_Edit({
            ...dats_edit,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeUnidadMedida = (event) => {
        setUnidadMedida(event.target.value);
    };

    const handleInputChangeInputFilter = (event) => {   
        setInputFilter({
            ...inputfilter,
            [event.target.name] : event.target.value
        })
    };

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };

    const handleCloseSave = (e) => {
    
        let name = dats.name;
        
        const formattedData = {
            name: name,
            measurement: unidadmedida,
        };
        //console.log(formattedData);
        if(name === '' || unidadmedida === ''){
            Swal.fire("No se pudo crear el tipo de producto, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.post(DEDALO_NEW_PRODUCT_TYPE, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NEW_PRODUCT_TYPE,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el tipo de producto exitósamente", "", "success");
                    document.getElementById('name').value = '';
                    document.getElementById('select').value = '';
                    peticionGetCategor();
                    handleClose2();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
            
        }
    };

    const handleCloseSaveEdit = (e) => {
        let name_edit = dats_edit.name_edit;
        let unidad_produc_edit = dats_edit.unidad_edit;
        
        const formattedData = {
            name: name_edit,
            measurement: unidadmedida === '' ? unidad_produc_edit : unidadmedida,
        };
        //console.log(formattedData);
        if(name_edit === ''){
            Swal.fire("No se pudo editar el tipo producto, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.put(DEDALO_UPDATE_PRODUCT_TYPE(productedit), formattedData, attachToken(user))
            axios({
                method: 'PUT',
                url: DEDALO_UPDATE_PRODUCT_TYPE+'&id='+productedittype,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el tipo producto exitósamente", "", "success");
                    document.getElementById('name_edit').value = '';
                    document.getElementById('select_edit').value = '';
                    peticionGetCategor();
                    handleClose3();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
            
        }
    };

    const handleCloseSaveTipoUnidad = (e) => {
    
        let name = dats.tipo_unidad;
        
        const formattedData = {
            name: name,
        };
        //console.log(formattedData);
        if(name === ''){
            Swal.fire("No se pudo crear el tipo de unidad, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.post(DEDALO_NEW_PRODUCT_TYPE, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NEW_MEASUREMENTS,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el tipo de unidad exitósamente", "", "success");
                    document.getElementById('tipo_unidad').value = '';
                    peticionGetComboUnidades();
                    peticionGetCategorUnidades();
                    handleClose1();
                } else {
                    //alert("ERROR: Server Error");
                    Swal.fire("El nombre "+name+" ingresado ya existe en la base de datos", "", "info");
                }
            })
            .catch(error => {
                manageError(error);
            })
            
        }
    };

    const handleCloseSaveTipoUnidadEdit = (e) => {
    
        let name = dats_edit.tipo_unidad_edit;
        
        const formattedData = {
            name: name,
        };
        //console.log(formattedData);
        if(name === ''){
            Swal.fire("No se pudo editar el tipo de unidad, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.post(DEDALO_NEW_PRODUCT_TYPE, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_MEASUREMENTS+'&id='+unidadmedidaedit,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el tipo de unidad exitósamente", "", "success");
                    peticionGetComboUnidades();
                    peticionGetCategorUnidades();
                    handleClose5();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
            
        }
    }

    const peticionDelete = (iduser) => {
        let delId = iduser;
            
        //axios.delete(DEDALO_DEL_PRODUCT_TYPE(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_PRODUCT_TYPE+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el tipo de producto exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const peticionDeleteUnidad = (idUnidad) => {
        let delId = idUnidad;
            
        //axios.delete(DEDALO_DEL_PRODUCT_TYPE(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_MEASUREMENTS+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el tipo de producto exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategorUnidades();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const filtrar = async(e) => {
        e.preventDefault();
        let input_text = inputfilter.formulario;

        if(input_text === ''){
            setInfoFiltro({
                ...infofiltro,
                ['datos']: [],
            });
        }else{
            const params_data = {
                search: input_text,
                perpage: 900,
                page: 1,
                orderField: 'name',
                order: 'asc',
            };
            //console.log(params_data);
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_PRODUCT_TYPE,
                headers: {Authorization: userToken},
                data: params_data
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    if(datanotice.length === 0){
                        mensaje_data(input_text);
                    }else{
                        setInfoFiltro({
                            ...infofiltro,
                            ['datos']: datanotice.records,
                        });
                    }
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const mensaje_data = (texto_in) => {
        setInfoFiltro({
            ...infofiltro,
            ['datos']: [],
        });
        return Swal.fire("No hay datos con el nombre o iniciales "+texto_in+" ingresado", "", "warning");
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = categor.datos === undefined ? [] : categor.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = categor.datos === undefined ? [] : categor.datos.length/postPerPage; // numero total de paginas
    let pageNumbers = []; //se guardan los numeros de paginas

    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () =>{
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () =>{
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };
    
    const paginador = () =>{
        return(
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{cursor: 'pointer'}}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="cardheaderimg2">
                <Card className="tablaproductos mb-4">
                    <Card.Header as="h6" className="with-elements">
                        <div className="card-header-title">Tipo de producto</div>
                        <div className="card-header-elements ml-auto">
                            <div >
                                <DropdownButton
                                    id="dropdown-button-dark-example2" variant="secondary"
                                    menuVariant="dark" 
                                >
                                    <Dropdown.Item onClick={()=>handleShow2()}>
                                        Nuevo Tipo de Producto
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=>handleShow1()}>
                                        Crear unidad producto
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=>handleShow4()}>
                                        Ver los tipos de unidad
                                    </Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="mb-3 w-100 d-flex justify-content-center"> {/* barra de busqueda */}
                            <div>
                                <Form className="d-flex">
                                    <FormControl
                                        type="search" size="sm"
                                        placeholder="Ingrese el nombre"
                                        className="me-2"
                                        aria-label="Buscar"
                                        id="formulario"
                                        name="formulario"
                                        style={{width: '100%'}}
                                        onChange={handleInputChangeInputFilter}
                                    />
                                    <Button id="buscar" size="sm" onClick={filtrar} className="btn_buscar">
                                        Buscar
                                    </Button>
                                </Form>
                            </div>
                        </div>
                        {infofiltro.datos.length === 0 ?
                            <div>
                                <Table responsive bordered hover size="sm" >
                                    <thead className="bg-primary text-light">
                                        <tr className="text-center">
                                            <th>#</th>
                                            <th>Tipo Producto</th>
                                            <th>Tipo unidad Producto</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{fontSize: "0.87em"}}>
                                        {currentPost.map(i => (
                                            <tr className="text-center">
                                                <td>{i.id}</td>
                                                <td>{i.name}</td>
                                                <td>{i.measurement}</td>
                                                <td>
                                                    <div className="opciones">
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" 
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                            </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" onClick={()=>setProductEditType(i.id)}>
                                                                <li>
                                                                    <a class="dropdown-item" href="#" onClick={()=>handleShow3(i.id)}>
                                                                        Editar
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item" href="#" onClick={()=>peticionDelete(i.id)}>
                                                                        Eliminar
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {paginador()}
                            </div>
                        :
                            <div style={{maxHeight: '25em', overflowY: 'auto', overflowX: 'hidden'}}> {/* seccion de filtro */}
                                <Table responsive bordered hover size="sm" >
                                    <thead className="bg-primary text-light">
                                        <tr className="text-center">
                                            <th>#</th>
                                            <th>Tipo Producto</th>
                                            <th>Tipo unidad Producto</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{fontSize: "0.87em"}}>
                                        {infofiltro.datos.map(f => (
                                            <tr className="text-center">
                                                <td>{f.id}</td>
                                                <td>{f.name}</td>
                                                <td>{f.measurement}</td>
                                                <td>
                                                    <div className="opciones">
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" 
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                            </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" onClick={()=>setProductEditType(f.id)}>
                                                                <li>
                                                                    <a class="dropdown-item" href="#" onClick={()=>handleShow3(f.id)}>
                                                                        Editar
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item" href="#" onClick={()=>peticionDelete(f.id)}>
                                                                        Eliminar
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Card.Body>
                </Card>
            </div>
            {/* MODAL CREAR PRODUCTO */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Crear Tipo Producto Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDriveFileRenameOutline/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre tipo producto" aria-label="Nombre del producto"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="name" name="name"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdPriceChange/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeUnidadMedida} id="select" name="select"
                                >
                                    <option value="">Seleccione una opcion</option>
                                    {combo_unidades.datos.map((u,index_u) => (
                                        <option key={index_u} value={u.id}>{u.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={()=>handleCloseSave()}>
                        Crear Tipo Producto
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* MODAL EDITAR TIPO PRODUCTO */}
            <Modal show={show3} onHide={handleClose3} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Tipo Producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDriveFileRenameOutline/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre del producto" aria-label="Nombre del producto"
                                    aria-describedby="basic-addon1" value={dats_edit.name_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="name_edit" name="name_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdPriceChange/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeUnidadMedida} id="select_edit" name="select_edit"
                                >
                                    <option value="">
                                        {dats_edit.unidad_edit}
                                    </option>
                                    <option value="Unidad">Unidad</option>
                                    <option value="Pieza">Pieza</option>
                                    <option value="Mts3">Mts3</option>
                                    <option value="Mts2">Mts2</option>
                                    <option value="Litros">Litros</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={()=>handleCloseSaveEdit()}>
                        Editar Tipo Producto
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL CREAR TIPO UNIDAD */}
            <Modal show={show1} onHide={handleClose1} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Tipo Unidad Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDriveFileRenameOutline/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre tipo unidad" aria-label="Nombre del unidad"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="tipo_unidad" name="tipo_unidad"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose1}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={handleCloseSaveTipoUnidad}>
                        Crear Tipo Unidad
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* MODAL VER TIPO UNIDAD */}
            <Modal show={show4} onHide={handleClose4} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Tipos de Unidad Registrados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{maxHeight: '20em', overflow: 'auto'}}>
                        <Table bordered responsive hover size="sm">
                            <thead className="text-center">
                                <tr>
                                    <th>#</th>
                                    <th>Nombre</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody className="menu_unidades">
                                {categorunidades.datos.map((c,index) => (
                                    <tr key={index}>
                                        <td>{c.id}</td>
                                        <td>{c.name}</td>
                                        <td className="text-center">
                                            <DropdownButton
                                                id="dropdown-button-dark-example2"
                                                variant="primary" onClick={()=>peticionGetUnitById(c.id)}
                                                menuVariant="dark"
                                                className="mt-2"
                                            >
                                                <Dropdown.Item onClick={()=>handleShow5(c.id)}>
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>peticionDeleteUnidad(c.id)}>
                                                    Eliminar
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose4}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* MODAL EDITAR TIPO UNIDAD */}
            <Modal show={show5} onHide={handleClose5} size="md" className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Tipo Unidad</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDriveFileRenameOutline/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre tipo unidad" aria-label="Nombre del unidad"
                                    aria-describedby="basic-addon1" value={dats_edit.tipo_unidad_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tipo_unidad_edit" name="tipo_unidad_edit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose5}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={handleCloseSaveTipoUnidadEdit}>
                        Editar Tipo Unidad
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    )
}

export default TablaProductos;