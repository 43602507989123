import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,FloatingLabel,
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_REQUISITIONS,DEDALO_CIUDADES,DEDALO_COMBO_INVENTARIO,
    DEDALO_NEW_REQUISITIONS,DEDALO_REQUISITIONS_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import html2PDF from "jspdf-html2canvas";
import Swal from "sweetalert2";
import {Mensaje_Error_Requisicion_edit} from '../../mensajeserror/mensajeserror';
import * as MdIcons from "react-icons/md";
import Img1 from '../imagenes_reportes/logo_geo.png';

const RequisicionPreview = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [aumento, setAumento] = useState(0);
    const [row_count, setRow_Count] = useState(0);
    const [ciudad, setCiudad] = useState('');

    const [rows, setRows] = useState([]);

    let {IdEditar} = useParams();

    const [dats, setDat] = useState({
        observaciones: '',
        cargo: '',
    });

    const [ciudadesproyectos, setCiudadesProyectos] = useState({
        datos: [],
    });

    const [suppliescombo, setSuppliesCombo] = useState({
        datos: [],
    });

    const [datsedit, setDatEdit] = useState({
        observaciones: '',
        name_edit: '',
        fecha_req: '',
        req_id: '',
        cities_id: '',
        cities_name: '',
        position_name: '',
        supplies_data: [],
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CIUDADES,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setCiudadesProyectos({
                ...ciudadesproyectos,
                ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetSuppliesId=async()=>{
        const params_data = {
            constructions_id: parseInt(data_emp.Id_Project_Conta),
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_INVENTARIO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setSuppliesCombo({
                    ...suppliescombo,
                    ['datos']: datanotice.data,
                });
            } else {
                setSuppliesCombo({
                    ...suppliescombo,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetRequisicionById=async()=>{
        let useredit = parseInt(IdEditar);

        if(useredit === undefined){
            let ry = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_REQUISITIONS_BY_ID+'&id='+useredit,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    let d = [];
                    //console.log(datanotice);
                    setDatEdit({
                        ...datsedit,
                        ['observaciones']: datanotice.observations,
                        ['name_edit']: datanotice.nameuser,
                        ['fecha_req']: datanotice.date,
                        ['req_id']: datanotice.id,
                        ['supplies_data']: datanotice.supplies,
                        ['cities_id']: datanotice.city_id,
                        ['cities_name']: datanotice.cities,
                        ['position_name']: datanotice.position,
                    });

                    datanotice.supplies.map((item, index) => {
                        d.push({
                            id: item.id,
                            cantidad: item.amount,
                            unidad: item.measurement,
                            id_supllies: item.product_requisition,
                            destino: item.target,
                            nom_suplies: item.nameproduct,
                        });
                    });
                    setRows(d);
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                  alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                      ? error.response.data.data.error_message
                      : message_responses[error.response.data.message]
                  );
                } else {
                  alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
            return info1;
        }
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetSuppliesId();
        peticionGetRequisicionById();
    }, []);

    const printContainer = useRef(null);

    const createPDF = async () => {
        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 9,
                right: 9,
                bottom: 7,
                left: 9
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: "./pdf/generate.pdf"
        });
    };

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371", color: '#fff'}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" onClick={createPDF}
                            title="Regresar" style={{backgroundColor: "#02B371", color: '#fff'}}
                        >
                            Descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="container" ref={printContainer}>
                    <Row className="border border-dark">
                        <Col md={2} xs={2} className="text-center">
                            <img src={Img1} alt="logo" width="200" height="150" className="img-fluid py-3" />
                        </Col>
                        <Col md={8} xs={8} className="d-flex justify-content-center align-items-center border-start border-end border-dark">
                            <div >
                                <h3>
                                    FORMATO REQUISICION
                                </h3>
                            </div>
                        </Col>
                        <Col md={2} xs={2} >
                            <div className="mt-3">
                                <div>
                                    <span>
                                        <b>Codigo:FO-CP-01-01</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b>Fecha:09/08/2013</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b>Pagina 1 de 1</b>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-dark border-start border-end border-botom">
                        <Col md={12} xs={12} className="border-dark border-bottom text-center">
                            <Row>
                                <Col md={2} xs={2} className="text-center border-end border-dark">
                                    <div className="d-flex justify-content-center align-items-center py-1">
                                        <div className="me-2">
                                            <span style={{fontSize: '0.8em'}}>
                                                <b>REQUISICIÓN</b>
                                            </span>
                                        </div>
                                        <div>
                                            {'000'+parseInt(IdEditar)}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8} xs={8} className="text-center"></Col>
                                <Col md={2} xs={2} className="text-center border-start border-dark d-flex justify-items-center align-items-center">
                                    <div>
                                        <span><b>FECHA: {new Date(datsedit.fecha_req).toLocaleDateString()}</b></span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom">
                            <Row className="border-bottom border-dark">
                                <Col md={2} xs={2} className="text-center border-end border-dark py-2">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Proyecto</b>
                                            </span>
                                        </div>
                                        <div>
                                            {data_emp.name_construction}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8} xs={8} className="text-center py-2">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Direccion</b>
                                            </span>
                                        </div>
                                        <div>
                                            {data_emp.dir_construction}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} xs={2} className="text-center border-start border-dark">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Ciudad</b>
                                            </span>
                                        </div>
                                        <div>
                                            <span>{datsedit.cities_name}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10} xs={10} className='border-end border-dark py-2'>
                                    <div className="py-1">
                                        <div className="text-center">
                                            <span>
                                                <b>FUNCIONARIO RESPONSABLE DEL PROYECTO / TRABAJADOR SOLICITANTE</b>
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            {user.name}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} xs={2} className='py-2'>
                                    <div className="py-1">
                                        <div className="text-center">
                                            <span>
                                                <b>CARGO</b>
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <span>{datsedit.position_name}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom"> {/*  tabla de informacion */}
                            <div className="my-2">
                                <Table responsive id="tabla_req" name="tabla_req">
                                    <thead className="text-center">
                                        <tr>
                                            <th>Item</th>
                                            <th colSpan={1}>Cantidad</th>
                                            <th>Unidad de medida</th>
                                            <th>Descripcion del item</th>
                                            <th>Destino y/o actividad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows === undefined ?
                                            ''
                                        :
                                            rows.map((row, i) =>(
                                                <tr key={i}>
                                                    <td className="text-center">{i+1}</td>
                                                    <td className="text-center" style={{width: '10em'}}>
                                                        <span>{row.cantidad}</span>
                                                    </td>
                                                    <td className="text-center" style={{width: '10em'}}>
                                                        <span>{row.unidad}</span>
                                                    </td>
                                                    <td className="text-center" style={{width: '50%'}}>
                                                        <span>{row.id_supllies}</span>
                                                    </td>
                                                    <td className="text-center" style={{width: '12em'}}>
                                                        <span>{row.destino}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12} className="border-dark border-bottom border-start border-top border-end page-break">
                            <div className="py-3">
                                <div>
                                    <span>
                                        <b>Observaciones</b>
                                    </span>
                                </div>
                                <div>
                                    <p>{datsedit.observaciones}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom border-start border-end">
                            <Row>
                                <Col md={2} xs={2} className="border-dark border-end text-center py-1">
                                    <b>Fecha de Entrega:</b>
                                </Col>
                                <Col md={10} xs={10} className="border-dark py-1">
                                    {new Date(datsedit.fecha_req).toLocaleDateString()}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom border-start border-end">
                            <Row>
                                <Col md={2} xs={2} className="border-dark border-end text-center py-1">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Solicitado por</b>
                                            </span>
                                        </div>
                                        <div className="mt-2">
                                            <span>{datsedit.name_edit}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8} xs={8} className="border-dark border-end text-center py-1">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Cargo</b>
                                            </span>
                                        </div>
                                        <div>
                                            {datsedit.position_name}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} xs={2} className="border-dark text-center py-1">
                                    <div className="py-1">
                                        <span>
                                            <b>Firma</b>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} xs={4} className="pt-4">
                            <Row className="border border-dark ">
                                <Col md={12} xs={12} className="border-dark border-bottom">
                                    <b>Recibido por:</b>
                                </Col>
                                <Col md={12} xs={12} className="border-dark border-bottom">
                                    <b>Cargo:</b>
                                </Col>
                                <Col md={12} xs={12}>
                                    <b>Fecha:</b>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} xs={4}></Col>
                        <Col md={4} xs={4} className="pt-4">
                            <Row className="border border-dark ">
                                <Col md={12} xs={12} className="border-dark border-bottom">
                                    <b>Autorizado por:</b>
                                </Col>
                                <Col md={12} xs={12} className="border-dark border-bottom">
                                    <b>Cargo:</b>
                                </Col>
                                <Col md={12} xs={12}>
                                    <b>Fecha:</b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12} className="pt-4">
                            <Row>
                                <Col md={4} xs={4} className="pt-4 text-center">
                                    <div className="border-dark border-top">
                                        <b>Firma</b>
                                    </div>
                                </Col>
                                <Col md={4} xs={4}></Col>
                                <Col md={4} xs={4} className="pt-4 text-center">
                                    <div className="border-dark border-top">
                                        <b>Firma</b>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default RequisicionPreview;