import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Card, Button, InputGroup, FormControl, Container, Row, Col,
  Form,
} from 'react-bootstrap';
import {
    DEDALO_NUEVA_CONSTRUCCION, DEDALO_CIUDADES
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import { 
    BsFillPeopleFill, BsFillPersonFill, BsCurrencyDollar
} from "react-icons/bs";
import { 
    MdLocationCity, MdAddLocationAlt
} from "react-icons/md";
import { 
    FaPercent,
} from "react-icons/fa";
import Navbar from '../navbar';
import { Routes } from "../../utils/routes";
import Logo from '../../imagenes/dedalo_logo.png';
//import Cookies from "universal-cookie";

function ProyectoNuevo() {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();    
    const {user} = useUserSession();
    const [ciudad, setCiudad] = useState('');
    const [boton, setBoton] = useState(0);
    const [dats, setDat] = useState({
        name: '',
        dir: '',
        valor: 0,
        valor_exe: 0,
        porcentaje_contin: 0,
        porcentaje_utl: 0,
        porcentaje_iva: 0,
        porcentaje_admin: 0,
    });
    
    const [ciudadesproyectos, setCiudadesProyectos] = useState({
        datos: [],
    });
    
    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeCiudad = (event) => {
        setCiudad(event.target.value);
    };

    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_CIUDADES)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CIUDADES,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setCiudadesProyectos({
                ...ciudadesproyectos,
                ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    }

    const handleCloseSave = (e) => {

        let name = dats.name;
        let dir = dats.dir;
        let proyecto = ciudad;
        let valor = dats.valor;
        let valor_exe = dats.valor_exe;

        let mensaje_error = [
            {error_msm: 'Name already exists', es: 'El nombre ya existe'},
            {error_msm: 'address is required!', es: 'La direccion es requerida'},
        ];

        const formattedData = {
            enterprice_id: data_emp.IdEmp,
            name: name,
            address: dir,
            cities_id: proyecto,
            budget: valor,
            executed_budget: valor_exe,
            administration: dats.porcentaje_admin === 0 ? 0 : parseInt(dats.porcentaje_admin),
            contingencies: dats.porcentaje_contin === 0 ? 0 : parseInt(dats.porcentaje_contin),
            utility: dats.porcentaje_utl === 0 ? 0 : parseInt(dats.porcentaje_utl),
            iva: dats.porcentaje_iva === 0 ? 0 : parseInt(dats.porcentaje_iva),
        };
        //console.log(formattedData);
        if(proyecto === ''){
            Swal.fire("No se pudo crear el proyecto, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.post(DEDALO_NUEVA_CONSTRUCCION, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NUEVA_CONSTRUCCION,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el proyecto exitósamente", "", "success");
                    setBoton(1);
                    document.getElementById('name').value = '';
                    document.getElementById('dir').value = '';
                    document.getElementById('valor').value = '';
                    document.getElementById('valor_exe').value = '';
                    document.getElementById('select').value = '';
                    document.getElementById('porcentaje_contin').value = '';
                    document.getElementById('porcentaje_utl').value = '';
                    document.getElementById('porcentaje_iva').value = '';
                    document.getElementById('porcentaje_admin').value = '';
                } else {
                    //alert("ERROR: Server Error");
                    let mensje = mensaje_error.find(x => x.error_msm === response.data.msg.error);
                    Swal.fire(mensje.es, "", "info");
                }
            })
            .catch(error => {
                //manageError(error);
                Swal.fire(error, "", "info");
            })
            
        }
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    if(!user){
        window.location.href = Routes.LOGIN;
    }

    return (
        <div>
            {/*<Navbar modulo="Proyecto nuevo" usuario={user.name}/>*/}
            <br/>
            <Container style={{width: '70%'}}>
                    <Row className="text-center">
                        <Col>
                            <img src={Logo} width="171" height="180"/>
                        </Col>
                    </Row>
                    <br/><br/>
                    <Card className="botonesaccion">
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BsFillPersonFill/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Nombre Proyecto" aria-label="Nombre Proyecto"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="name" name="name"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdAddLocationAlt/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Dirección del Proyecto" aria-label="Dirección del Proyecto"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="dir" name="dir"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BsCurrencyDollar/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Presupuesto Proyecto" aria-label="Presupuesto Proyecto"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="valor" name="valor"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdLocationCity/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeCiudad} id="select" name="select"
                                            >
                                                <option value="">Seleccione la Ciudad</option>
                                                {ciudadesproyectos.datos.map(i => (
                                                    <option value={i.id}>{i.name}</option>
                                                ))}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BsCurrencyDollar/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Presupuesto Ejecutado" aria-label="Presupuesto Ejecutado"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="valor_exe" name="valor_exe"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <FaPercent/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Porcentaje admin" aria-label="Porcentaje admin"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="porcentaje_admin" name="porcentaje_admin"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <FaPercent/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Porcentaje Contingencia" aria-label="Porcentaje Contingencia"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="porcentaje_contin" name="porcentaje_contin"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <FaPercent/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Porcentaje utilidad" aria-label="Porcentaje utilidad"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="porcentaje_utl" name="porcentaje_utl"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <FaPercent/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Iva proyecto" aria-label="Iva proyecto"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="porcentaje_iva" name="porcentaje_iva"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="text-center" style={{marginTop: "5%"}}>
                                    <Col>
                                        <Button onClick={()=>handleCloseSave()} className="btn btn-success me-2" 
                                            style={{backgroundColor: "#02B371"}}>
                                            Agregar Proyecto
                                        </Button>
                                        <Button onClick={()=>{window.history.back()}} className="btn btn-success" 
                                            style={{backgroundColor: "#02B371"}}>
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
            </Container>
        </div>
    );
}

export default ProyectoNuevo;
