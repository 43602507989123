import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Card, Button, InputGroup, FormControl, Container, Row, Col,
  Form,
} from 'react-bootstrap';
import {
    DEDALO_SEND_RESET_PASSWORD_EMAIL
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import { 
    BsFillPeopleFill, BsFillPersonFill, BsFillTelephoneFill
} from "react-icons/bs";
import { 
    MdSecurity, MdAddLocationAlt
} from "react-icons/md";
import Navbar from '../navbar';
import { Routes } from "../../utils/routes";
import Logo from '../../imagenes/dedalo_logo.png';
import Cookies from "universal-cookie";

function ResetPassLogin() {
    const {userId} = useUserSession();
    const {user} = useUserSession(); 
    const [age, setAge] = React.useState('');
    const [boton, setBoton] = useState(0);
    const [dats, setDat] = useState({
        correo: '',
    });
    
    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const regresar = () =>{
        window.history.back();
    }
    
    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let email = dats.correo;
        
        const formattedData = {
            email: email,
        };
        //console.log(formattedData);
        axios.post(DEDALO_SEND_RESET_PASSWORD_EMAIL, formattedData)
          .then(response => {
            
            if (response.data.success) {
                Swal.fire("Se envio el link para el cambio de contraseña al correo "+email+" registrado.", "", "success");
                document.getElementById('correo').value = '';
                setBoton(1);
            } else {
              alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    if(!user){
        window.location.href = Routes.LOGIN;
    }

    return (
        <div>
            {/*<Navbar modulo="Cambiar Contraseña" usuario={user.name}/>*/}
            <br/><br/>
            <p className="text-center" style={{marginBottom: "3%"}}>
                <img src={Logo} width="171" height="180"/>
            </p>
                <Container className="w-50">
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                
                                <Row >
                                    <Col>
                                        <p>
                                            Ingrese el correo registrado en la plataforma para enviar la informacion con los pasos para
                                            reestablecer la contraseña.
                                        </p>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                            <FormControl
                                                placeholder="Correo" aria-label="Correo"
                                                aria-describedby="basic-addon1"
                                                type="email" onChange={handleInputChange}
                                                id="correo" name="correo"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{textAlign: "center"}}>
                                        {boton === 1 ?
                                            <Button className="rounded-pill btn btn-primary" style={{letterSpacing: "0.4rem"}} onClick={()=>{window.location.href = Routes.LOGIN}}>
                                                Regresar
                                            </Button>
                                        :
                                            <Button onClick={()=>handleCloseSave()} className="btn btn-success" 
                                                style={{backgroundColor: "#02B371"}}>
                                                Enviar correo
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            
        </div>
    );
}

export default ResetPassLogin;
