import React, { useState, Fragment, useEffect } from "react";
import Navbar from '../navbar';
import {
    Card, Row, Col, Accordion, Dropdown, InputGroup,
    DropdownButton, Button, Modal, Table, FormControl,
    Form,
} from 'react-bootstrap'
import { Badge } from "reactstrap";
import * as Chartjs from 'react-chartjs-2'
import { useUserSession } from "../../hooks";
import { Routes } from "../../utils/routes";
import "bootstrap-icons/font/bootstrap-icons.css";
import '../../scss/theme-corporate.css';
import '../../scss/demo.css';
import '../../scss/style.css';
import '../../scss/main.5bb26a06.chunk.css';
import '../../scss/Loader.module.scss';
import '../../scss/NotFound.module.scss';

import {
    DEDALO_CONSTRUCCION_BY_ID, DEDALO_ALL_CONSTRUCTIONS_TASK,
    DEDALO_DEL_TAREA_CONSTRUCTION, DEDALO_CUADRILLAS, DEDALO_DEL_CUADRILLAS,
    DEDALO_ALL_BLUEPRINTS, DEDALO_DEL_BLUEPRINTS, DEDALO_BLUEPRINTS_BY_ID, DEDALO_ADMINFILES_BY_ID,
    DEDALO_NEW_BLUEPRINTS, DEDALO_EDIT_BLUEPRINTS, DEDALO_ALL_PERSONAL_CONSTRUCTION,
    DEDALO_PERSONAL_COMBO_CONSTRUCTION, DEDALO_ASIGN_PERSONAL_CONSTRUCTION,
    DEDALO_DEL_PERSONAL_CONSTRUCTION, DEDALO_CAMBIO_ESTADO_TASK, DEDALO_CUADRILLAS_BY_ID,
    DEDALO_ALL_INVENTORY, DEDALO_CONSTRUCCION_TASK_BY_ID, DEDALO_ALL_ADMINFILES, DEDALO_DEL_ADMINFILES,
    DEDALO_NEW_ADMINFILES, DEDALO_EDIT_ADMINFILES,
} from "../../assets/APIAdresses";
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import { Redirect, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Grafica from "./graficadatoshomi";
import ModalTarea from './modalesinfo/modaltareas';
import ModalEditar from './modalesinfo/modaleditar';
import ModalCuadrillas from './modalesinfo/modalcuadrillas';
import AlmacenHomi from './almacen/almacenhomi';
import NuevoInventario from './almacen/modalesalmacen/inventarionuevo';
import AllMove from './almacen/movimientoinvetario';
import Grafico_Presupuesto from './diagramas/diagramapresupuesto';
import Grafico_Pres_Exe from './diagramas/diagramatareasejecutadas';
import Cambio_task from './cambioestado/cambioestadotarea';
import Datareuniones from '../reuniones/datareuniones';
import TipoItems from '../productos/itemtype';
import Cronograma from './diagramas/cronograma';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";

function Proyectos() {
    const { userToken } = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const [selectplano, setSelectPlano] = useState();
    const [selectplano1, setSelectPlano1] = useState();
    const [planopdf, setPlanoPdf] = useState('');
    const [planopdf1, setPlanoPdf1] = useState('');
    const [planopdfedit, setPlanoPdfEdit] = useState();
    const [planopdfedit1, setPlanoPdfEdit1] = useState();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [personalselect, setPersonalSelect] = useState('');
    const [personalcargo, setPersonalCargo] = useState('');
    const [selectestadotask, setSelectEstadoTask] = useState('');
    const [btn_cambiar, setBtn_Cambiar] = useState(false);
    const [verdocumento, setVerDocumento] = useState(0);

    const [prueba, setPrueba] = useState({
        datos: [],
    });

    const [prueba2, setPrueba2] = useState({
        datos: [],
    });

    const [prueba3, setPrueba3] = useState({
        datos: [],
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [tareas, setTareas] = useState({
        datos: [],
    });

    const [cuadrillashomi, setCuadrillasHomi] = useState({
        datos: [],
    });

    const [planosproyecto, setPlanosProyecto] = useState({
        datos: [],
    });

    const [planosproyecto1, setPlanosProyecto1] = useState({
        datos: [],
    });

    const [planosselect, setPlanosSelect] = useState({
        datos: [],
        datos_image64: [],
    });

    const [planosselect1, setPlanosSelect1] = useState({
        datos: [],
        datos_image64: [],
    });

    const [personalconstruction, setPersonalConstruction] = useState({
        datos: [],
    });

    const [personalcombo, setpersonalcombo] = useState({
        datos: [],
    });

    const [allinventory, setAllInventory] = useState({
        datos: [],
    });

    const [taskselectbyid, setTaskSelectById] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        title: '',
        creator: '',
        obs: '',
        title_2: '',
        creator_2: '',
        obs_2: '',
        title_3: '',
        creator_3: '',
        obs_3: '',
        position_per: '',
    });

    const peticionGetCategor = async () => {
        //let info1 = await axios.get(DEDALO_CONSTRUCCION_BY_ID+'&id='+id)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_BY_ID + '&id=' + id,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice);
                    setCategor({
                        ...categor,
                        ['datos']: datanotice.data,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;
    };

    const peticionGetTareas = async () => {
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CONSTRUCTIONS_TASK+'?constructions_id='+id+'&page=1&rows=1000&order=id&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CONSTRUCTIONS_TASK,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    if (datanotice.length === 0) {
                        setTareas({
                            ...tareas,
                            ['datos']: [],
                        });
                    } else {
                        if (datanotice.records.length > 0) {
                            setTareas({
                                ...tareas,
                                ['datos']: datanotice.records,
                            });
                        }
                    }
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;
    };

    const peticionGetCuadrillas = async () => {
        const params_data = {
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_CUADRILLAS+'?page=1&rows=1000&order=id&inorder=asc&search=&constructions_id='+id)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CUADRILLAS,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice);
                    setCuadrillasHomi({
                        ...cuadrillashomi,
                        ['datos']: datanotice,
                    });
                    peticionGetCuadrillasById(datanotice);
                } else {
                    setCuadrillasHomi({
                        ...cuadrillashomi,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;
    };

    const peticionGetCuadrillasById = async (datos) => {
        const arr_info2 = [];
        let arr_info_2 = datos === undefined ? [] : datos;
        arr_info_2.map(element => {
            let info2 = axios({
                //axios({
                method: 'POST',
                url: DEDALO_CUADRILLAS_BY_ID + '&id=' + element.id,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg;
                        //console.log(datanotice.data);
                        arr_info2.push(datanotice.data);
                        setPrueba2({
                            ...prueba2,
                            ['datos']: arr_info2,
                        });
                    } else {
                        alert('message_responses[response.data.message]');
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(error.response.data.message);
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos21");
                    }
                });
            return info2;
        });
        //console.log(datos.length);
    };

    const peticionGetPlanosProyecto = async () => {
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_BLUEPRINTS,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice.records);
                    if (datanotice.length === 0) {
                        setPlanosProyecto({
                            ...planosproyecto,
                            ['datos']: [],
                        });
                    } else {
                        setPlanosProyecto({
                            ...planosproyecto,
                            ['datos']: datanotice.records,
                        });
                        peticionGetPlanosById(datanotice.records);
                    }
                } else {
                    setPlanosProyecto({
                        ...planosproyecto,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response.data.message);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos25");
                }
            });
        return info1;
    };

    const peticionGetPlanosById = async (datos) => {
        const arr_info = [];
        let arr_info_2 = datos.length === 0 ? [] : datos;
        arr_info_2.map(element => {
            let info2 = axios({
                //axios({
                method: 'POST',
                url: DEDALO_BLUEPRINTS_BY_ID + '&id=' + element.id,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg;
                        //console.log(datanotice.data);
                        arr_info.push(datanotice.data);
                        setPrueba({
                            ...prueba,
                            ['datos']: arr_info,
                        });
                    } else {
                        setPrueba({
                            ...prueba,
                            ['datos']: [],
                        });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos21");
                    }
                });
            return info2;
        });
    };

    const peticionGetPlanosById2 = async () => {
        if (selectplano === undefined) {
            let yr = 0;
        } else {
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_BLUEPRINTS_BY_ID + '&id=' + selectplano,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg.data;
                        //console.log(datanotice);
                        setDat({
                            ...dats,
                            ['title_2']: datanotice.title,
                            ['creator_2']: datanotice.creator,
                            ['obs_2']: datanotice.description,
                        });

                        setPlanosSelect({
                            ...planosselect,
                            ['datos']: datanotice,
                        });
                    } else {
                        setPlanosSelect({
                            ...planosselect,
                            ['datos']: [],
                        });
                        setDat({
                            ...dats,
                            ['title_2']: '',
                            ['creator_2']: '',
                            ['obs_2']: '',
                        });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos21");
                    }
                });

            return info1;
        }
    };

    const peticionGetDocumentosProyecto = async () => {
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_ADMINFILES,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice.records);
                    if (datanotice.length === 0) {
                        setPlanosProyecto1({
                            ...planosproyecto1,
                            ['datos']: [],
                        });
                    } else {
                        setPlanosProyecto1({
                            ...planosproyecto1,
                            ['datos']: datanotice.records,
                        });
                        peticionGetDocumentosById3(datanotice.records);
                    }
                } else {
                    setPlanosProyecto1({
                        ...planosproyecto1,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response.data.message);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos25");
                }
            });
        return info1;
    };

    const peticionGetDocumentosById3 = async (datos) => {
        const arr_info = [];
        let arr_info_2 = datos.length === 0 ? [] : datos;
        arr_info_2.map(element => {
            let info2 = axios({
                //axios({
                method: 'POST',
                url: DEDALO_ADMINFILES_BY_ID + '&id=' + element.id,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg;
                        //console.log(datanotice.data);
                        arr_info.push(datanotice.data);
                        setPrueba3({
                            ...prueba3,
                            ['datos']: arr_info,
                        });
                    } else {
                        setPrueba3({
                            ...prueba3,
                            ['datos']: [],
                        });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos21");
                    }
                });
            return info2;
        });
    };

    const peticionGetDocumentosById4 = async () => {
        if (selectplano1 === undefined) {
            let yr = 0;
        } else {
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ADMINFILES_BY_ID + '&id=' + selectplano1,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg.data;
                        //console.log(datanotice);
                        setDat({
                            ...dats,
                            ['title_3']: datanotice.title,
                            ['creator_3']: datanotice.creator,
                            ['obs_3']: datanotice.description,
                        });

                        setPlanosSelect1({
                            ...planosselect1,
                            ['datos']: datanotice,
                        });
                    } else {
                        setPlanosSelect1({
                            ...planosselect1,
                            ['datos']: [],
                        });
                        setDat({
                            ...dats,
                            ['title_3']: '',
                            ['creator_3']: '',
                            ['obs_3']: '',
                        });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos21");
                    }
                });

            return info1;
        }
    };

    const peticionDelete = (iduser) => {
        let delId = iduser;

        //axios.delete(DEDALO_DEL_TAREA_CONSTRUCTION+'&id='+delId, attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_TAREA_CONSTRUCTION + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.success) {
                    Swal.fire("Se elimino la tarea exitosamente", "", "success");
                    //window.location.reload();
                    peticionGetTareas();
                } else {
                    Swal.fire("No se puede eliminar la tarea ya que se encuentra en uso", "", "info");
                }
            })
    };

    const peticionDeleteCuadrillas = (iduser) => {
        let delIdcuadrilla = iduser;
        //console.log(delIdcuadrilla);
        //axios.delete(DEDALO_DEL_CUADRILLAS(delIdcuadrilla), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_CUADRILLAS + '&id=' + delIdcuadrilla,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el cuadrilla exitósamente", "", "success");
                    //window.location.reload();
                    peticionGetCuadrillas();
                } else {
                    alert("ERROR: Server Error");
                }
            });

    };

    const peticionGetPersonalConstruction = async () => {
        const params_data = {
            construction_id: id,
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PERSONAL_CONSTRUCTION+'?page=1&rows=1000&construction_id='+id+'&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_PERSONAL_CONSTRUCTION,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    if (datanotice.length === 0) {
                        setPersonalConstruction({
                            ...personalconstruction,
                            ['datos']: [],
                        });
                    } else {
                        setPersonalConstruction({
                            ...personalconstruction,
                            ['datos']: datanotice.records,
                        });
                    }
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos222");
                }
            });

        return info1;
    };

    const peticionGetPersonalCombo = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_PERSONAL_COMBO_CONSTRUCTION)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_PERSONAL_COMBO_CONSTRUCTION,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setpersonalcombo({
                        ...personalcombo,
                        ['datos']: datanotice,
                    });
                } else {
                    //alert(message_responses[response.data.message]);
                    setpersonalcombo({
                        ...personalcombo,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;
    };

    const peticionGetAllInventory = async () => {
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_INVENTORY+'?page=1&rows=1000&constructions_id='+id+'&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_INVENTORY,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setAllInventory({
                        ...allinventory,
                        ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetTareas();
        peticionGetCuadrillas();
        peticionGetPlanosProyecto();
        peticionGetDocumentosProyecto();
        peticionGetPersonalConstruction();
        peticionGetPersonalCombo();
        peticionGetAllInventory();
        //peticionGetTaskById();
    }, []);

    useEffect(() => {
        //peticionGetPlanosById();
        peticionGetPlanosById2();
    }, [selectplano]);

    useEffect(() => {
        //peticionGetPlanosById();
        peticionGetDocumentosById4();
    }, [selectplano1]);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
    };

    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = (e) => {
        setShow6(true);
    };

    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = (e) => {
        setShow5(true);
    };

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (e) => {
        setShow1(true);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setPlanoPdfEdit(e);
        setShow2(true);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (e) => {
        setPlanoPdfEdit1(e);
        setShow4(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setShow3(true);
    };

    const [show7, setShow7] = useState(false);
    const handleClose7 = () => setShow7(false);
    const handleShow7 = (e) => {
        setVerDocumento(e);
        setShow7(true);
    };

    const handleInputChange = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const convertToBase64 = (dato) => {
        //Read File
        var selectedFile = document.getElementById("pdfup").files;
        var selectedFile1 = document.getElementById("pdfup");
        let MAXIMO_TAMANIO_BYTES = 50000000;
        let tamaño_archivo = selectedFile[0].size;

        if (tamaño_archivo > MAXIMO_TAMANIO_BYTES) {
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!", `El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`, "info");
            selectedFile1.value = "";
            setPlanoPdf('');
            setPlanoPdf1('');
        } else {
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.replace(/^data:application\/pdf;base64,/, "");
                    if (dato === 'plano') {
                        setPlanoPdf(base64);
                    } else {
                        setPlanoPdf1(base64);
                    }
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            }
        }
    }

    const handleCloseSavePdf = (e) => {

        //e.preventDefault();
        let titulo_pdf = dats.title;
        let observa = dats.obs;
        let creator_pdf = dats.creator;

        const formattedData = {
            title: titulo_pdf,
            constructions_id: id,
            creator: creator_pdf,
            description: observa,
            image: planopdf,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_BLUEPRINTS, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_NEW_BLUEPRINTS,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se subio el plano exitósamente", "", "success");
                    document.getElementById("title").value = "";
                    document.getElementById("obs").value = "";
                    document.getElementById("creator").value = "";
                    peticionGetPlanosProyecto();
                    //peticionGetPlanosById();
                    handleClose1();
                } else {
                    alert("ERROR: Server Error");
                }
            })
        //.catch(error => {
        //    manageError(error);
        //});
    };

    const handleCloseSavePdfEdit = (e) => {

        //e.preventDefault();
        let titulo_pdf_2 = dats.title_2;
        let observa_2 = dats.obs_2;
        let creator_pdf_2 = dats.creator_2;

        const formattedData = {
            title: titulo_pdf_2,
            constructions_id: id,
            creator: creator_pdf_2,
            description: observa_2,
            image: planopdf === undefined ? planosselect.datos.image64 : planopdf,
        };
        //console.log(formattedData);

        axios({
            method: 'PUT',
            url: DEDALO_EDIT_BLUEPRINTS + '&id=' + planopdfedit,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el plano exitósamente", "", "success");
                    document.getElementById("title_2").value = "";
                    document.getElementById("obs_2").value = "";
                    document.getElementById("creator_2").value = "";
                    peticionGetPlanosProyecto();
                    handleClose2();
                } else {
                    alert("ERROR: Server Error");
                }
            })
        //.catch(error => {
        //    manageError(error);
        //});
    };

    const peticionDeletePdf = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_DEL_BLUEPRINTS(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_BLUEPRINTS + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el plano exitosamente", "", "success");
                    //window.location.reload();
                    peticionGetPlanosProyecto();
                } else {
                    alert("ERROR: Server Error");
                }
            })
    };

    const peticionDeletePdf1 = (iduser) => {
        let delId = iduser;

        axios({
            method: 'DELETE',
            url: DEDALO_DEL_ADMINFILES + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el documento exitosamente", "", "success");
                    //window.location.reload();
                    peticionGetDocumentosProyecto();
                } else {
                    alert("ERROR: Server Error");
                }
            })
    };

    const handleCloseSavePer = (e) => {

        //e.preventDefault();
        let cargoper = dats.position_per;

        const formattedData = {
            constructions_id: id,
            personal_id: personalselect,
            position: personalcargo,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_ASIGN_PERSONAL_CONSTRUCTION, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_ASIGN_PERSONAL_CONSTRUCTION,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se ha guardado la informacion exitósamente", "", "success");
                    document.getElementById("position_per").value = "";
                    document.getElementById("select_per").value = "";
                    peticionGetPersonalConstruction();
                    handleClose3();
                } else {
                    alert("ERROR: Server Error");
                }
            })
        /*.catch(error => {
            manageError(error);
        });*/
    };

    const peticionDeletePer = (iduser) => {
        let delId = iduser;

        const params_data = {
            constructions_id: id,
            personal_id: delId,
        };

        //axios.post(DEDALO_DEL_PERSONAL_CONSTRUCTION, params_data, attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_PERSONAL_CONSTRUCTION + '&id=' + delId,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el personal exitosamente", "", "success");
                    peticionGetPersonalConstruction();
                } else {
                    alert("ERROR: Server Error");
                }
            })

    };

    const handleCloseSavePdf1 = (e) => {

        //e.preventDefault();
        let titulo_pdf = dats.title;
        let observa = dats.obs;
        let creator_pdf = dats.creator;

        const formattedData = {
            title: titulo_pdf,
            constructions_id: id,
            creator: creator_pdf,
            description: observa,
            image: planopdf1,
        };
        //console.log(formattedData);

        axios({
            method: 'POST',
            url: DEDALO_NEW_ADMINFILES,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se subio el documento exitósamente", "", "success");
                    document.getElementById("title").value = "";
                    document.getElementById("obs").value = "";
                    document.getElementById("creator").value = "";
                    peticionGetDocumentosProyecto();
                    //peticionGetPlanosById();
                    handleClose5();
                } else {
                    alert("ERROR: Server Error");
                }
            })
        //.catch(error => {
        //    manageError(error);
        //});
    };

    const handleCloseSavePdfEdit1 = (e) => {

        //e.preventDefault();
        let titulo_pdf_2 = dats.title_3;
        let observa_2 = dats.obs_3;
        let creator_pdf_2 = dats.creator_3;

        const formattedData = {
            title: titulo_pdf_2,
            constructions_id: id,
            creator: creator_pdf_2,
            description: observa_2,
            image: planopdf1 === undefined ? planosselect1.datos.image64 : planopdf1,
        };
        //console.log(formattedData);

        axios({
            method: 'PUT',
            url: DEDALO_EDIT_ADMINFILES + '&id=' + planopdfedit1,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el documento exitósamente", "", "success");
                    peticionGetDocumentosProyecto();
                    handleClose4();
                } else {
                    alert("ERROR: Server Error");
                }
            })
        //.catch(error => {
        //    manageError(error);
        //});
    };

    const handleChangeSelectG = (event) => {
        setPersonalSelect(event.target.value);
    };

    const handleChangeSelectG2 = (event) => {
        setPersonalCargo(event.target.value);
    };

    const handleChangeSelectEstado = (event) => {
        setSelectEstadoTask(event.target.value);
    };

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };

    let imagenpdf64 = '';
    if (planosselect.datos.image64 === undefined) {
        imagenpdf64 = '';
    } else {
        imagenpdf64 = planosselect.datos.image64.split(',')[1];
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = tareas.datos === undefined ? [] : tareas.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = tareas.datos === undefined ? [] : tareas.datos.length / postPerPage; // numero total de paginas
    let pageNumbers = []; // se guardan los numeros de paginas

    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () => {
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () => {
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };

    const paginador = () => {
        return (
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{ cursor: 'pointer' }}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                                :
                                <li class="page-item" style={{ cursor: 'pointer' }}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{ cursor: 'pointer' }}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{ cursor: 'pointer' }}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                                :
                                <li class="page-item" style={{ cursor: 'pointer' }}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };

    let documentoView = prueba3.datos.length === 0 ? 'salida' : prueba3.datos.filter(x => x.id === verdocumento)[0];

    //console.log(documentoView,prueba3);

    if (!user) {
        window.location.href = Routes.LOGIN;
    }

    return (
        <div >
            {/*<Navbar modulo={"Proyecto "+categor.datos.name} usuario={user.name}/>*/}
            <br />
            <div>
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                    <Button className="btn btn-success rounded-circle" size="sm" onClick={() => { window.history.back() }}
                        style={{ backgroundColor: "#02B371" }} title="Regresar.">
                        <MdIcons.MdArrowBackIosNew />
                    </Button>
                </div>
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start mt-3">
                    <div className="w-100 rounded p-3" style={{ background: '#1A5276', color: '#fff' }}>
                        <span>{datahomi.nom_project}</span>
                    </div>
                </div>
                <div className="container-fluid flex-grow-1 container-p-y">
                    {user.role_id === 5 ?
                        <Row>
                            <Col xl={5}>
                                {/* Tasks */}
                                <Card className="mb-4" style={{ minHeight: "30em", maxHeight: "30em", height: "30em" }}>
                                    <Card.Header as="h6" className="with-elements">
                                        <div className="card-header-title">Tareas</div>
                                        <div className="card-header-elements ml-auto">
                                            <ModalTarea actualizar={peticionGetTareas} />
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div style={{ overflowY: "auto", minHeight: "22em" }}>
                                            <Table responsive striped bordered hover className="tableactions text-center">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Tarea</th>
                                                        <th>Estado Tarea</th>
                                                        <th>Fecha inicio Tarea</th>
                                                        <th>Fecha fin Tarea</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tareas.datos.map(i => (
                                                        <tr key={i.id} className="textotable">
                                                            <td>{i.id}</td>
                                                            <td>{i.title}</td>
                                                            <td>{i.status === 'in process' ? 'En Progreso' : 'Pendiente'}</td>
                                                            <td>{i.deadline}</td>
                                                            <td>{i.deadline}</td>
                                                            <td className="text-center">
                                                                <DropdownButton
                                                                    id="dropdown-button-dark-example2"
                                                                    variant="primary"
                                                                    menuVariant="dark"
                                                                    className="mt-2"
                                                                >
                                                                    {i.creator_id === userId ?
                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                        :
                                                                        ''
                                                                    }
                                                                    <Dropdown.Item ><ModalEditar actualizar={peticionGetTareas} /></Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => { window.location.href = Routes.SUBTAREAS(i.id) }}>
                                                                        Ver Subtareas
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => { window.location.href = Routes.LINEA_DE_TIEMPO(i.id) }}>
                                                                        Linea de tiempo
                                                                    </Dropdown.Item>
                                                                </DropdownButton>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card.Body>

                                </Card>
                                {/* / Tasks */}

                            </Col>
                            <Col xl={5}>
                                <Row>
                                    <Col md={8} xs={12}>
                                        <Card className="mb-4" style={{ minHeight: "30em", maxHeight: "30em", height: "30em" }}>
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">Almacen</div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item >
                                                            <NuevoInventario />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item >
                                                            <AllMove />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => { window.location.href = Routes.REQUERIMIENTOS }}>
                                                            Ver Requerimientos
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <AlmacenHomi />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        : user.role_id === 6 ?
                            <div> {/* seccion almacenista*/}
                                <Row>
                                    <Col xl={12}>
                                        {/* Tasks */}
                                        <Card className="mb-4">
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">
                                                    <span className="me-2">Tareas</span>
                                                    {btn_cambiar === false ?
                                                        <BsIcons.BsClipboardData
                                                            size={20} title="Ver Cronograma" onClick={() => setBtn_Cambiar(true)}
                                                            className="cursor-pointer"
                                                        />
                                                        :
                                                        <MdIcons.MdOutlineGradient
                                                            size={20} title="Ver tabla de tareas" onClick={() => setBtn_Cambiar(false)}
                                                            className="cursor-pointer"
                                                        />
                                                    }
                                                </div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item >
                                                            {/*<ModalTarea actualizar={peticionGetTareas}/>*/}
                                                            <Link to={Routes.AGREGAR_TAREA_NUEVA} className="text-decoration-none">
                                                                Agregar Tarea
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item >
                                                            <Link to={Routes.REPORTE_TAREAS_DIAGRAMA} style={{ color: "white", textDecoration: "none" }}>
                                                                Ver Cronograma
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                {btn_cambiar === false ?
                                                    <div>
                                                        <Table responsive bordered hover className="tableactions text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Tarea</th>
                                                                    <th>Estado Tarea</th>
                                                                    <th>Observaciones</th>
                                                                    <th>Prioridad</th>
                                                                    <th>Cantidad</th>
                                                                    <th>Presupuesto</th>
                                                                    <th>Total tarea</th>
                                                                    <th>Fecha inicio Tarea</th>
                                                                    <th>Fecha fin Tarea</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {currentPost.map(i => (
                                                                    i.status === 'pending' ?
                                                                        <tr key={i.id} className="textotable">
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Pendiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseInt(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2 menu_tareas"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                        : i.status === 'in process' ?
                                                                        <tr key={i.id} className="textotable" style={{ backgroundColor: '#abebc6', color: 'black' }}>
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Penndiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseInt(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                        : i.status === 'under review' ?
                                                                        <tr key={i.id} className="textotable" style={{ backgroundColor: '#f5b041', color: 'white' }}>
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Penndiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseInt(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                        : i.status === 'completed' ?
                                                                        <tr key={i.id} className="textotable" style={{ backgroundColor: '#52be80', color: 'white' }}>
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Penndiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseInt(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                        : i.status === 'closed' ?
                                                                        <tr key={i.id} className="textotable" style={{ backgroundColor: '#239b56', color: 'white' }}>
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Penndiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseInt(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                        : i.status === 'canceled' ?
                                                                        <tr key={i.id} className="textotable" style={{ backgroundColor: '#cb4335', color: 'white' }}>
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Penndiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseInt(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                        : i.status === 'reopened' ?
                                                                        <tr key={i.id} className="textotable" style={{ backgroundColor: '#2e86c1', color: 'white' }}>
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Penndiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseInt(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        <tr key={i.id} className="textotable">
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Penndiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseInt(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    }
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        {paginador()}
                                                    </div>
                                                    :
                                                    <div>
                                                        <Cronograma />
                                                    </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                        {/* / Tasks */}

                                    </Col>
                                    <Col xl={6}>
                                        {/* Planos */}
                                        <Card className="mb-4" style={{ minHeight: "30em", maxHeight: "30em", height: "30em" }}>
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">Planos del proyecto</div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item onClick={handleShow1}>
                                                            Cargar Plano
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <div style={{ overflow: "scroll", overflowX: "hidden", minHeight: "22em", maxHeight: "22em" }}>
                                                    {planosproyecto.datos.map(item => (
                                                        <div>
                                                            <Row className="mb-4">
                                                                <Col md={5} xs={12} className="row align-items-center">
                                                                    <div className="text-center">
                                                                        <div style={{ color: "#e67e22" }}>
                                                                            <h4>{item.title}</h4>
                                                                        </div>
                                                                        <div>
                                                                            <b>Subido por {item.creator}</b>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <b>{item.description}</b>
                                                                        </div>
                                                                        <div>
                                                                            <Dropdown >
                                                                                <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                                                                                    Acciones
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu variant="dark">
                                                                                    <Dropdown.Item onClick={() => handleShow(item.id)}>
                                                                                        Ver Plano
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item onClick={() => peticionDeletePdf(item.id)}>
                                                                                        Eliminar plano
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item onClick={() => handleShow2(item.id)}>
                                                                                        Editar plano
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                        <br />
                                                                    </div>
                                                                </Col>
                                                                <Col md={7} xs={12}>
                                                                    <iframe src={'data:application/pdf;base64,' + item.image + '#zoom=29'}
                                                                        className="me-2" alt={item.title}
                                                                        style={{ width: "100%", height: "18em" }} />
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                        </div>
                                                    ))}
                                                </div>
                                            </Card.Body>

                                        </Card>
                                        {/* / planos */}
                                    </Col>
                                    <Col md={6}> {/* cuadro 5 datos de almacen*/}
                                        <Card className="mb-4" style={{ minHeight: "30em", maxHeight: "30em", height: "30em" }}>
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">Almacen</div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item >
                                                            <NuevoInventario actualizar={peticionGetAllInventory} />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item >
                                                            <Link to={Routes.MOVIMIENTO_INVENTARIO} style={{ color: "white", textDecoration: "none" }}>
                                                                Ver Movimientos
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item >
                                                            <Link to={Routes.REQUERIMIENTOS} style={{ color: "white", textDecoration: "none" }}>
                                                                Ver Requerimientos
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <AlmacenHomi />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div>
                                <Row>
                                    <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro 1 datos presupuesto */}

                                        {/* Stats + Links */}
                                        <Card className="d-flex w-100 mb-4">
                                            <Row noGutters className="row-bordered h-100 linkscolors">
                                                <Col sm={6} md={4} lg={6} className="d-flex align-items-center">

                                                    <a href="#d" className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto">
                                                        <i className="lnr lnr-checkmark-circle display-5 d-block text-primary"></i>
                                                        <span className="media-body d-block ml-3">
                                                            <span className="text-big font-weight-bolder">
                                                                {categor.datos.budget === undefined ?
                                                                    formatterPeso.format(0)
                                                                    :
                                                                    formatterPeso.format(categor.datos.budget)
                                                                }
                                                            </span>
                                                            <br />
                                                            <small className="text-muted">Presupuesto general</small>
                                                        </span>
                                                    </a>

                                                </Col>
                                                <Col sm={6} md={4} lg={6} className="d-flex align-items-center">

                                                    <a href="#d" className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto">
                                                        <i className="lnr lnr-construction display-5 d-block text-primary"></i>
                                                        <span className="media-body d-block ml-3">
                                                            <span className="text-big font-weight-bolder">
                                                                {categor.datos.executed_budget === undefined ? formatterPeso.format(0) : formatterPeso.format(categor.datos.executed_budget)}
                                                            </span><br />
                                                            <small className="text-muted">Presupuesto ejecutado</small>
                                                        </span>
                                                    </a>

                                                </Col>
                                                <Col sm={6} md={4} lg={6} className="d-flex align-items-center">

                                                    <a href="#d" className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto">
                                                        <i className="lnr lnr-chart-bars display-5 d-block text-primary"></i>
                                                        <span className="media-body d-block ml-3">
                                                            <span className="text-big font-weight-bolder">
                                                                {categor.datos.budget === undefined ? formatterPeso.format(0) : formatterPeso.format(categor.datos.budget - categor.datos.executed_budget)}
                                                            </span>
                                                            <br />
                                                            <small className="text-muted">Presupuesto por ejecutar</small>
                                                        </span>
                                                    </a>

                                                </Col>
                                                <Col sm={6} md={4} lg={6} className="text-center">
                                                    <div className="px-3 mt-4">
                                                        <div className="d-flex">
                                                            <div><b>Total Administracion</b></div>
                                                            <div className="ms-auto">
                                                                {formatterPeso.format(categor.datos.adminvalue)}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div><b>Total Contingencia</b></div>
                                                            <div className="ms-auto">
                                                                {formatterPeso.format(categor.datos.continvalue)}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div><b>Total Utilidades</b></div>
                                                            <div className="ms-auto">
                                                                {formatterPeso.format(categor.datos.utilvalue)}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div><b>Total con Iva</b></div>
                                                            <div className="ms-auto">
                                                                {formatterPeso.format(categor.datos.ivavalue)}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div><b>Total</b></div>
                                                            <div className="ms-auto">
                                                                {formatterPeso.format(categor.datos.totalvalue)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                        {/* / Stats + Links */}

                                    </Col>

                                    <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro 2 datos del progreso */}

                                        {/* Daily progress chart */}
                                        <Card className="w-100 mb-4">
                                            <Card.Header className="with-elements">
                                                <div className="text-big card-header-title">Progreso de los cortes en %</div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2"
                                                        variant="primary"
                                                        menuVariant="dark"
                                                        className="mt-2"
                                                    >
                                                        <Dropdown.Item>
                                                            <Link to={Routes.TABLA_CORTES} style={{ color: "white", textDecoration: "none" }}>
                                                                Datos del Corte
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <div className="px-2">
                                                <Grafica />
                                                <br />
                                            </div>
                                        </Card>
                                        {/* / Daily progress chart */}

                                    </Col>
                                    <Col md={7} xs={12}>
                                        <TipoItems />
                                    </Col>
                                    <Col xl={12}> {/* cuadro 3 datos de tareas */}

                                        {/* Tasks */}
                                        <Card className="mb-4">
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">
                                                    <span className="me-2">Tareas</span>
                                                    {btn_cambiar === false ?
                                                        <BsIcons.BsClipboardData
                                                            size={20} title="Ver Cronograma" onClick={() => setBtn_Cambiar(true)}
                                                            className="cursor-pointer"
                                                        />
                                                        :
                                                        <MdIcons.MdOutlineGradient
                                                            size={20} title="Ver tabla de tareas" onClick={() => setBtn_Cambiar(false)}
                                                            className="cursor-pointer"
                                                        />
                                                    }
                                                </div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item >
                                                            {/*<ModalTarea actualizar={peticionGetTareas}/>*/}
                                                            <Link to={Routes.AGREGAR_TAREA_NUEVA} className="text-decoration-none">
                                                                Agregar Tarea
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item >
                                                            <Link to={Routes.REPORTE_TAREAS_DIAGRAMA} style={{ color: "white", textDecoration: "none" }}>
                                                                Ver Cronograma
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                {btn_cambiar === false ?
                                                    <div>
                                                        <Table responsive bordered hover className="tableactions text-center">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Tarea</th>
                                                                    <th>Estado Tarea</th>
                                                                    <th>Observaciones</th>
                                                                    <th>Prioridad</th>
                                                                    <th>Cantidad</th>
                                                                    <th>Presupuesto</th>
                                                                    <th>Tipo Tarea</th>
                                                                    <th>Total tarea</th>
                                                                    <th>Fecha inicio Tarea</th>
                                                                    <th>Fecha fin Tarea</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {currentPost.map(i => (
                                                                    i.status === 'pending' ?
                                                                        <tr key={i.id} className="textotable">
                                                                            <td>{i.id}</td>
                                                                            <td>{i.title}</td>
                                                                            <td>
                                                                                {i.status === 'pending' ?
                                                                                    'Pendiente'
                                                                                    : i.status === 'in process' ?
                                                                                        'En progreso'
                                                                                        : i.status === 'under review' ?
                                                                                            'Vajo revisión'
                                                                                            : i.status === 'completed' ?
                                                                                                'Completado'
                                                                                                : i.status === 'closed' ?
                                                                                                    'Cerrado'
                                                                                                    : i.status === 'canceled' ?
                                                                                                        'Cancelado'
                                                                                                        : i.status === 'reopened' ?
                                                                                                            'Reabierto'
                                                                                                            :
                                                                                                            ''
                                                                                }
                                                                            </td>
                                                                            <td>{i.observations}</td>
                                                                            <td>
                                                                                {i.priority === 'high' ?
                                                                                    'Alto'
                                                                                    : i.priority === 'medium' ?
                                                                                        'Medio'
                                                                                        :
                                                                                        'Bajo'
                                                                                }
                                                                            </td>
                                                                            <td>{parseFloat(i.amount)}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                            <td>{i.typeitems_name}</td>
                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                            <td>{i.startdate}</td>
                                                                            <td>{i.deadline}</td>
                                                                            <td className="text-center">
                                                                                <DropdownButton
                                                                                    id="dropdown-button-dark-example2"
                                                                                    variant="primary"
                                                                                    menuVariant="dark"
                                                                                    className="mt-2 menu_tareas"
                                                                                >
                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                    <Dropdown.Item >
                                                                                        <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                            Editar tarea
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Ver Subtareas
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Linea de tiempo
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item >
                                                                                        <Cambio_task
                                                                                            id_cam={i.id}
                                                                                            dat={i.status}
                                                                                            actu={peticionGetTareas}
                                                                                            data_pres={peticionGetCategor}
                                                                                        />
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item>
                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                        >
                                                                                            Comentarios
                                                                                        </Link>
                                                                                    </Dropdown.Item>
                                                                                </DropdownButton>
                                                                            </td>
                                                                        </tr>
                                                                        : i.status === 'in process' ?
                                                                            <tr key={i.id} className="textotable" style={{ backgroundColor: '#abdfeb', color: 'black' }}>
                                                                                <td>{i.id}</td>
                                                                                <td>{i.title}</td>
                                                                                <td>
                                                                                    {i.status === 'pending' ?
                                                                                        'Penndiente'
                                                                                        : i.status === 'in process' ?
                                                                                            'En progreso'
                                                                                            : i.status === 'under review' ?
                                                                                                'Vajo revisión'
                                                                                                : i.status === 'completed' ?
                                                                                                    'Completado'
                                                                                                    : i.status === 'closed' ?
                                                                                                        'Cerrado'
                                                                                                        : i.status === 'canceled' ?
                                                                                                            'Cancelado'
                                                                                                            : i.status === 'reopened' ?
                                                                                                                'Reabierto'
                                                                                                                :
                                                                                                                ''
                                                                                    }
                                                                                </td>
                                                                                <td>{i.observations}</td>
                                                                                <td>
                                                                                    {i.priority === 'high' ?
                                                                                        'Alto'
                                                                                        : i.priority === 'medium' ?
                                                                                            'Medio'
                                                                                            :
                                                                                            'Bajo'
                                                                                    }
                                                                                </td>
                                                                                <td>{parseFloat(i.amount)}</td>
                                                                                <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                                <td>{i.typeitems_name}</td>
                                                                                <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                                <td>{i.startdate}</td>
                                                                                <td>{i.deadline}</td>
                                                                                <td className="text-center">
                                                                                    <DropdownButton
                                                                                        id="dropdown-button-dark-example2"
                                                                                        variant="primary"
                                                                                        menuVariant="dark"
                                                                                        className="mt-2"
                                                                                    >
                                                                                        {parseInt(i.creator_id) === userId ?
                                                                                            <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                            :
                                                                                            ''
                                                                                        }
                                                                                        <Dropdown.Item >
                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                Editar tarea
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item>
                                                                                            <Link to={Routes.SUBTAREAS(i.id)}
                                                                                                style={{ color: "black", textDecoration: "none" }}
                                                                                            >
                                                                                                Ver Subtareas
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item>
                                                                                            <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                                style={{ color: "black", textDecoration: "none" }}
                                                                                            >
                                                                                                Linea de tiempo
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item >
                                                                                            <Cambio_task
                                                                                                id_cam={i.id}
                                                                                                dat={i.status}
                                                                                                actu={peticionGetTareas}
                                                                                                data_pres={peticionGetCategor}
                                                                                            />
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item>
                                                                                            <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                                style={{ color: "black", textDecoration: "none" }}
                                                                                            >
                                                                                                Comentarios
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    </DropdownButton>
                                                                                </td>
                                                                            </tr>
                                                                            : i.status === 'under review' ?
                                                                                <tr key={i.id} className="textotable" style={{ backgroundColor: '#f5b041', color: 'white' }}>
                                                                                    <td>{i.id}</td>
                                                                                    <td>{i.title}</td>
                                                                                    <td>
                                                                                        {i.status === 'pending' ?
                                                                                            'Penndiente'
                                                                                            : i.status === 'in process' ?
                                                                                                'En progreso'
                                                                                                : i.status === 'under review' ?
                                                                                                    'Vajo revisión'
                                                                                                    : i.status === 'completed' ?
                                                                                                        'Completado'
                                                                                                        : i.status === 'closed' ?
                                                                                                            'Cerrado'
                                                                                                            : i.status === 'canceled' ?
                                                                                                                'Cancelado'
                                                                                                                : i.status === 'reopened' ?
                                                                                                                    'Reabierto'
                                                                                                                    :
                                                                                                                    ''
                                                                                        }
                                                                                    </td>
                                                                                    <td>{i.observations}</td>
                                                                                    <td>
                                                                                        {i.priority === 'high' ?
                                                                                            'Alto'
                                                                                            : i.priority === 'medium' ?
                                                                                                'Medio'
                                                                                                :
                                                                                                'Bajo'
                                                                                        }
                                                                                    </td>
                                                                                    <td>{parseFloat(i.amount)}</td>
                                                                                    <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                                    <td>{i.typeitems_name}</td>
                                                                                    <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                                    <td>{i.startdate}</td>
                                                                                    <td>{i.deadline}</td>
                                                                                    <td className="text-center">
                                                                                        <DropdownButton
                                                                                            id="dropdown-button-dark-example2"
                                                                                            variant="primary"
                                                                                            menuVariant="dark"
                                                                                            className="mt-2"
                                                                                        >
                                                                                            {parseInt(i.creator_id) === userId ?
                                                                                                <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                                :
                                                                                                ''
                                                                                            }
                                                                                            <Dropdown.Item >
                                                                                                <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                    Editar tarea
                                                                                                </Link>
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item>
                                                                                                <Link to={Routes.SUBTAREAS(i.id)}
                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                >
                                                                                                    Ver Subtareas
                                                                                                </Link>
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item>
                                                                                                <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                >
                                                                                                    Linea de tiempo
                                                                                                </Link>
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item >
                                                                                                <Cambio_task
                                                                                                    id_cam={i.id}
                                                                                                    dat={i.status}
                                                                                                    actu={peticionGetTareas}
                                                                                                    data_pres={peticionGetCategor}
                                                                                                />
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item>
                                                                                                <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                >
                                                                                                    Comentarios
                                                                                                </Link>
                                                                                            </Dropdown.Item>
                                                                                        </DropdownButton>
                                                                                    </td>
                                                                                </tr>
                                                                                : i.status === 'completed' ?
                                                                                    <tr key={i.id} className="textotable" style={{ backgroundColor: '#52be80', color: 'white' }}>
                                                                                        <td>{i.id}</td>
                                                                                        <td>{i.title}</td>
                                                                                        <td>
                                                                                            {i.status === 'pending' ?
                                                                                                'Penndiente'
                                                                                                : i.status === 'in process' ?
                                                                                                    'En progreso'
                                                                                                    : i.status === 'under review' ?
                                                                                                        'Vajo revisión'
                                                                                                        : i.status === 'completed' ?
                                                                                                            'Completado'
                                                                                                            : i.status === 'closed' ?
                                                                                                                'Cerrado'
                                                                                                                : i.status === 'canceled' ?
                                                                                                                    'Cancelado'
                                                                                                                    : i.status === 'reopened' ?
                                                                                                                        'Reabierto'
                                                                                                                        :
                                                                                                                        ''
                                                                                            }
                                                                                        </td>
                                                                                        <td>{i.observations}</td>
                                                                                        <td>
                                                                                            {i.priority === 'high' ?
                                                                                                'Alto'
                                                                                                : i.priority === 'medium' ?
                                                                                                    'Medio'
                                                                                                    :
                                                                                                    'Bajo'
                                                                                            }
                                                                                        </td>
                                                                                        <td>{parseFloat(i.amount)}</td>
                                                                                        <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                                        <td>{i.typeitems_name}</td>
                                                                                        <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                                        <td>{i.startdate}</td>
                                                                                        <td>{i.deadline}</td>
                                                                                        <td className="text-center">
                                                                                            <DropdownButton
                                                                                                id="dropdown-button-dark-example2"
                                                                                                variant="primary"
                                                                                                menuVariant="dark"
                                                                                                className="mt-2"
                                                                                            >
                                                                                                {parseInt(i.creator_id) === userId ?
                                                                                                    <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                                    :
                                                                                                    ''
                                                                                                }
                                                                                                <Dropdown.Item >
                                                                                                    <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                        Editar tarea
                                                                                                    </Link>
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item>
                                                                                                    <Link to={Routes.SUBTAREAS(i.id)}
                                                                                                        style={{ color: "black", textDecoration: "none" }}
                                                                                                    >
                                                                                                        Ver Subtareas
                                                                                                    </Link>
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item>
                                                                                                    <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                                        style={{ color: "black", textDecoration: "none" }}
                                                                                                    >
                                                                                                        Linea de tiempo
                                                                                                    </Link>
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item >
                                                                                                    <Cambio_task
                                                                                                        id_cam={i.id}
                                                                                                        dat={i.status}
                                                                                                        actu={peticionGetTareas}
                                                                                                        data_pres={peticionGetCategor}
                                                                                                    />
                                                                                                </Dropdown.Item>
                                                                                                <Dropdown.Item>
                                                                                                    <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                                        style={{ color: "black", textDecoration: "none" }}
                                                                                                    >
                                                                                                        Comentarios
                                                                                                    </Link>
                                                                                                </Dropdown.Item>
                                                                                            </DropdownButton>
                                                                                        </td>
                                                                                    </tr>
                                                                                    : i.status === 'closed' ?
                                                                                        <tr key={i.id} className="textotable" style={{ backgroundColor: '#239b56', color: 'white' }}>
                                                                                            <td>{i.id}</td>
                                                                                            <td>{i.title}</td>
                                                                                            <td>
                                                                                                {i.status === 'pending' ?
                                                                                                    'Penndiente'
                                                                                                    : i.status === 'in process' ?
                                                                                                        'En progreso'
                                                                                                        : i.status === 'under review' ?
                                                                                                            'Vajo revisión'
                                                                                                            : i.status === 'completed' ?
                                                                                                                'Completado'
                                                                                                                : i.status === 'closed' ?
                                                                                                                    'Cerrado'
                                                                                                                    : i.status === 'canceled' ?
                                                                                                                        'Cancelado'
                                                                                                                        : i.status === 'reopened' ?
                                                                                                                            'Reabierto'
                                                                                                                            :
                                                                                                                            ''
                                                                                                }
                                                                                            </td>
                                                                                            <td>{i.observations}</td>
                                                                                            <td>
                                                                                                {i.priority === 'high' ?
                                                                                                    'Alto'
                                                                                                    : i.priority === 'medium' ?
                                                                                                        'Medio'
                                                                                                        :
                                                                                                        'Bajo'
                                                                                                }
                                                                                            </td>
                                                                                            <td>{parseFloat(i.amount)}</td>
                                                                                            <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                                            <td>{i.typeitems_name}</td>
                                                                                            <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                                            <td>{i.startdate}</td>
                                                                                            <td>{i.deadline}</td>
                                                                                            <td className="text-center">
                                                                                                <DropdownButton
                                                                                                    id="dropdown-button-dark-example2"
                                                                                                    variant="primary"
                                                                                                    menuVariant="dark"
                                                                                                    className="mt-2"
                                                                                                >
                                                                                                    {parseInt(i.creator_id) === userId ?
                                                                                                        <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                                        :
                                                                                                        ''
                                                                                                    }
                                                                                                    <Dropdown.Item >
                                                                                                        <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                            Editar tarea
                                                                                                        </Link>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item>
                                                                                                        <Link to={Routes.SUBTAREAS(i.id)}
                                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                                        >
                                                                                                            Ver Subtareas
                                                                                                        </Link>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item>
                                                                                                        <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                                        >
                                                                                                            Linea de tiempo
                                                                                                        </Link>
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item >
                                                                                                        <Cambio_task
                                                                                                            id_cam={i.id}
                                                                                                            dat={i.status}
                                                                                                            actu={peticionGetTareas}
                                                                                                            data_pres={peticionGetCategor}
                                                                                                        />
                                                                                                    </Dropdown.Item>
                                                                                                    <Dropdown.Item>
                                                                                                        <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                                            style={{ color: "black", textDecoration: "none" }}
                                                                                                        >
                                                                                                            Comentarios
                                                                                                        </Link>
                                                                                                    </Dropdown.Item>
                                                                                                </DropdownButton>
                                                                                            </td>
                                                                                        </tr>
                                                                                        : i.status === 'canceled' ?
                                                                                            <tr key={i.id} className="textotable" style={{ backgroundColor: '#cb4335', color: 'white' }}>
                                                                                                <td>{i.id}</td>
                                                                                                <td>{i.title}</td>
                                                                                                <td>
                                                                                                    {i.status === 'pending' ?
                                                                                                        'Penndiente'
                                                                                                        : i.status === 'in process' ?
                                                                                                            'En progreso'
                                                                                                            : i.status === 'under review' ?
                                                                                                                'Vajo revisión'
                                                                                                                : i.status === 'completed' ?
                                                                                                                    'Completado'
                                                                                                                    : i.status === 'closed' ?
                                                                                                                        'Cerrado'
                                                                                                                        : i.status === 'canceled' ?
                                                                                                                            'Cancelado'
                                                                                                                            : i.status === 'reopened' ?
                                                                                                                                'Reabierto'
                                                                                                                                :
                                                                                                                                ''
                                                                                                    }
                                                                                                </td>
                                                                                                <td>{i.observations}</td>
                                                                                                <td>
                                                                                                    {i.priority === 'high' ?
                                                                                                        'Alto'
                                                                                                        : i.priority === 'medium' ?
                                                                                                            'Medio'
                                                                                                            :
                                                                                                            'Bajo'
                                                                                                    }
                                                                                                </td>
                                                                                                <td>{parseFloat(i.amount)}</td>
                                                                                                <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                                                <td>{i.typeitems_name}</td>
                                                                                                <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                                                <td>{i.startdate}</td>
                                                                                                <td>{i.deadline}</td>
                                                                                                <td className="text-center">
                                                                                                    <DropdownButton
                                                                                                        id="dropdown-button-dark-example2"
                                                                                                        variant="primary"
                                                                                                        menuVariant="dark"
                                                                                                        className="mt-2"
                                                                                                    >
                                                                                                        {parseInt(i.creator_id) === userId ?
                                                                                                            <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                                            :
                                                                                                            ''
                                                                                                        }
                                                                                                        <Dropdown.Item >
                                                                                                            <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                                Editar tarea
                                                                                                            </Link>
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item>
                                                                                                            <Link to={Routes.SUBTAREAS(i.id)}
                                                                                                                style={{ color: "black", textDecoration: "none" }}
                                                                                                            >
                                                                                                                Ver Subtareas
                                                                                                            </Link>
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item>
                                                                                                            <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                                                style={{ color: "black", textDecoration: "none" }}
                                                                                                            >
                                                                                                                Linea de tiempo
                                                                                                            </Link>
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item >
                                                                                                            <Cambio_task
                                                                                                                id_cam={i.id}
                                                                                                                dat={i.status}
                                                                                                                actu={peticionGetTareas}
                                                                                                                data_pres={peticionGetCategor}
                                                                                                            />
                                                                                                        </Dropdown.Item>
                                                                                                        <Dropdown.Item>
                                                                                                            <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                                                style={{ color: "black", textDecoration: "none" }}
                                                                                                            >
                                                                                                                Comentarios
                                                                                                            </Link>
                                                                                                        </Dropdown.Item>
                                                                                                    </DropdownButton>
                                                                                                </td>
                                                                                            </tr>
                                                                                            : i.status === 'reopened' ?
                                                                                                <tr key={i.id} className="textotable" style={{ backgroundColor: '#2e86c1', color: 'white' }}>
                                                                                                    <td>{i.id}</td>
                                                                                                    <td>{i.title}</td>
                                                                                                    <td>
                                                                                                        {i.status === 'pending' ?
                                                                                                            'Penndiente'
                                                                                                            : i.status === 'in process' ?
                                                                                                                'En progreso'
                                                                                                                : i.status === 'under review' ?
                                                                                                                    'Vajo revisión'
                                                                                                                    : i.status === 'completed' ?
                                                                                                                        'Completado'
                                                                                                                        : i.status === 'closed' ?
                                                                                                                            'Cerrado'
                                                                                                                            : i.status === 'canceled' ?
                                                                                                                                'Cancelado'
                                                                                                                                : i.status === 'reopened' ?
                                                                                                                                    'Reabierto'
                                                                                                                                    :
                                                                                                                                    ''
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{i.observations}</td>
                                                                                                    <td>
                                                                                                        {i.priority === 'high' ?
                                                                                                            'Alto'
                                                                                                            : i.priority === 'medium' ?
                                                                                                                'Medio'
                                                                                                                :
                                                                                                                'Bajo'
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{parseFloat(i.amount)}</td>
                                                                                                    <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                                                    <td>{i.typeitems_name}</td>
                                                                                                    <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                                                    <td>{i.startdate}</td>
                                                                                                    <td>{i.deadline}</td>
                                                                                                    <td className="text-center">
                                                                                                        <DropdownButton
                                                                                                            id="dropdown-button-dark-example2"
                                                                                                            variant="primary"
                                                                                                            menuVariant="dark"
                                                                                                            className="mt-2"
                                                                                                        >
                                                                                                            {parseInt(i.creator_id) === userId ?
                                                                                                                <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                                                :
                                                                                                                ''
                                                                                                            }
                                                                                                            <Dropdown.Item >
                                                                                                                <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                                    Editar tarea
                                                                                                                </Link>
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item>
                                                                                                                <Link to={Routes.SUBTAREAS(i.id)}
                                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                                >
                                                                                                                    Ver Subtareas
                                                                                                                </Link>
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item>
                                                                                                                <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                                >
                                                                                                                    Linea de tiempo
                                                                                                                </Link>
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item >
                                                                                                                <Cambio_task
                                                                                                                    id_cam={i.id}
                                                                                                                    dat={i.status}
                                                                                                                    actu={peticionGetTareas}
                                                                                                                    data_pres={peticionGetCategor}
                                                                                                                />
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item>
                                                                                                                <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                                >
                                                                                                                    Comentarios
                                                                                                                </Link>
                                                                                                            </Dropdown.Item>
                                                                                                        </DropdownButton>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                :
                                                                                                <tr key={i.id} className="textotable">
                                                                                                    <td>{i.id}</td>
                                                                                                    <td>{i.title}</td>
                                                                                                    <td>
                                                                                                        {i.status === 'pending' ?
                                                                                                            'Penndiente'
                                                                                                            : i.status === 'in process' ?
                                                                                                                'En progreso'
                                                                                                                : i.status === 'under review' ?
                                                                                                                    'Vajo revisión'
                                                                                                                    : i.status === 'completed' ?
                                                                                                                        'Completado'
                                                                                                                        : i.status === 'closed' ?
                                                                                                                            'Cerrado'
                                                                                                                            : i.status === 'canceled' ?
                                                                                                                                'Cancelado'
                                                                                                                                : i.status === 'reopened' ?
                                                                                                                                    'Reabierto'
                                                                                                                                    :
                                                                                                                                    ''
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{i.observations}</td>
                                                                                                    <td>
                                                                                                        {i.priority === 'high' ?
                                                                                                            'Alto'
                                                                                                            : i.priority === 'medium' ?
                                                                                                                'Medio'
                                                                                                                :
                                                                                                                'Bajo'
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{parseFloat(i.amount)}</td>
                                                                                                    <td>{formatterPeso.format(Math.ceil(i.budget))}</td>
                                                                                                    <td>{i.typeitems_name}</td>
                                                                                                    <td>{formatterPeso.format(Math.ceil(i.budget * i.amount))}</td>
                                                                                                    <td>{i.startdate}</td>
                                                                                                    <td>{i.deadline}</td>
                                                                                                    <td className="text-center">
                                                                                                        <DropdownButton
                                                                                                            id="dropdown-button-dark-example2"
                                                                                                            variant="primary"
                                                                                                            menuVariant="dark"
                                                                                                            className="mt-2"
                                                                                                        >
                                                                                                            {parseInt(i.creator_id) === userId ?
                                                                                                                <Dropdown.Item onClick={() => peticionDelete(i.id)}>Eliminar</Dropdown.Item>
                                                                                                                :
                                                                                                                ''
                                                                                                            }
                                                                                                            <Dropdown.Item >
                                                                                                                <Link to={Routes.EDITAR_TAREA(i.id)} className="text-decoration-none">
                                                                                                                    Editar tarea
                                                                                                                </Link>
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item>
                                                                                                                <Link to={Routes.SUBTAREAS(i.id)}
                                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                                >
                                                                                                                    Ver Subtareas
                                                                                                                </Link>
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item>
                                                                                                                <Link to={Routes.LINEA_DE_TIEMPO(i.id)}
                                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                                >
                                                                                                                    Linea de tiempo
                                                                                                                </Link>
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item >
                                                                                                                <Cambio_task
                                                                                                                    id_cam={i.id}
                                                                                                                    dat={i.status}
                                                                                                                    actu={peticionGetTareas}
                                                                                                                    data_pres={peticionGetCategor}
                                                                                                                />
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item>
                                                                                                                <Link to={Routes.COMENTARIOS_TAREAS(i.id)}
                                                                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                                                                >
                                                                                                                    Comentarios
                                                                                                                </Link>
                                                                                                            </Dropdown.Item>
                                                                                                        </DropdownButton>
                                                                                                    </td>
                                                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        {paginador()}
                                                    </div>
                                                    :
                                                    <div>
                                                        <Cronograma />
                                                    </div>
                                                }
                                            </Card.Body>
                                        </Card>
                                        {/* / Tasks */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro Grafico presupuesto */}
                                        <Card className="w-100 mb-4">
                                            <Card.Header className="with-elements">
                                                <div className="text-small card-header-title">Grafica de presupuesto</div>
                                            </Card.Header>
                                            <div className="px-2">
                                                <br />
                                                <Grafico_Presupuesto />
                                                <br />
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro Grafico presupuesto tareas ejecutadas */}
                                        <Card className="w-100 mb-4">
                                            <Card.Header className="with-elements">
                                                <div className="text-small card-header-title">Grafica de presupuesto tareas ejecutadas</div>
                                            </Card.Header>
                                            <div className="px-2">
                                                <br />
                                                <Grafico_Pres_Exe />
                                                <br />
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={6} > {/* seccion reuniones */}
                                        <Card className="mb-4">
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">Actas del proyecto</div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item>
                                                            <Link to={Routes.REUNION_NUEVA} className="text-decoration-none">
                                                                Nueva acta
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <Datareuniones />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6} xs={12}> {/* <---- seccion personal obra */}
                                        <Card className="mb-4" style={{ minHeight: "30em", maxHeight: "30em", height: "30em", overflowY: "auto" }}>
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">Personal de la Obra</div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item onClick={handleShow3}>
                                                            Asignar Personal a la obra
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                {personalconstruction.datos.map(x => (
                                                    <div className="d-flex align-items-center mb-3">
                                                        <img src='https://postgradoingenieria.com/wp-content/uploads/control-de-proyectos.jpg'
                                                            alt="salida" className="img-fluid rounded-circle me-2" width="6%"
                                                        />
                                                        <span className="media-body d-block ml-2">
                                                            <span className="text-big font-weight-bolder">
                                                                {x.name}
                                                            </span>
                                                        </span>
                                                        <Button size="sm" className="ml-auto bg-light border-0" title="Eliminar"
                                                            onClick={() => peticionDeletePer(x.personal_id)}
                                                        >
                                                            <BsIcons.BsTrashFill className="text-danger" />
                                                        </Button>
                                                    </div>
                                                ))}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}>
                                        <Row>
                                            <Col md={6}> {/* cuadro 4 datos de cuadrillas */}
                                                <Card className="mb-4" style={{ minHeight: "30em", maxHeight: "30em", height: "30em" }}>
                                                    <Card.Header as="h6" className="with-elements">
                                                        <div className="card-header-title">Cuadrillas</div>
                                                        <div className="card-header-elements ml-auto">
                                                            <ModalCuadrillas actualizar={peticionGetCuadrillas} />
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Accordion flush>
                                                            {cuadrillashomi.datos === undefined ?
                                                                ''
                                                                :
                                                                cuadrillashomi.datos.map(s => (
                                                                    <Accordion.Item eventKey={s.id} key={s.id}>
                                                                        <Accordion.Header>{s.name}</Accordion.Header>
                                                                        <Accordion.Body className="tableactions">
                                                                            <div class="d-flex justify-content-between">
                                                                                <div>
                                                                                    Acciones de la cuadrilla
                                                                                </div>
                                                                                <div >
                                                                                    <DropdownButton
                                                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                                                        menuVariant="dark"
                                                                                    >
                                                                                        <Dropdown.Item onClick={() => peticionDeleteCuadrillas(s.id)}>
                                                                                            Eliminar
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item>
                                                                                            <Link to={Routes.EDITAR_CUADRILLA(s.id)} className="text-decoration-none">
                                                                                                Editar
                                                                                            </Link>
                                                                                        </Dropdown.Item>
                                                                                    </DropdownButton>
                                                                                </div>
                                                                            </div>
                                                                            {prueba2.datos.map(x => (
                                                                                parseInt(x.id) === parseInt(s.id) ?
                                                                                    <p>
                                                                                        <b>
                                                                                            Personal asignado
                                                                                        </b>
                                                                                        <hr style={{ marginTop: '0' }} />
                                                                                        {x.Personal.map((p, p_index) => (
                                                                                            <div key={p_index}>
                                                                                                <span>{p.nameperonal}</span>
                                                                                            </div>
                                                                                        ))}
                                                                                    </p>
                                                                                    :
                                                                                    ''
                                                                            ))}
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                ))
                                                            }
                                                        </Accordion>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={6}> {/* cuadro 5 datos de almacen*/}
                                                <Card className="mb-4" style={{ minHeight: "30em", maxHeight: "30em", height: "30em" }}>
                                                    <Card.Header as="h6" className="with-elements">
                                                        <div className="card-header-title">Almacen</div>
                                                        <div className="card-header-elements ml-auto tableactions">
                                                            <DropdownButton
                                                                id="dropdown-button-dark-example2" variant="secondary"
                                                                menuVariant="dark"
                                                            >
                                                                <Dropdown.Item >
                                                                    <NuevoInventario actualizar={peticionGetAllInventory} />
                                                                </Dropdown.Item>
                                                                <Dropdown.Item >
                                                                    <Link to={Routes.MOVIMIENTO_INVENTARIO} style={{ color: "white", textDecoration: "none" }}>
                                                                        Ver Movimientos
                                                                    </Link>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item >
                                                                    <Link to={Routes.REQUERIMIENTOS} style={{ color: "white", textDecoration: "none" }}>
                                                                        Ver Requerimientos
                                                                    </Link>
                                                                </Dropdown.Item>
                                                            </DropdownButton>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <AlmacenHomi />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12}> {/* <---- seccion planos */}
                                        {/* Planos */}
                                        <Card className="mb-4" style={{ minHeight: "50em", maxHeight: "50em", height: "50em" }}>
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">Planos del proyecto</div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item onClick={handleShow1}>
                                                            Cargar Plano
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <div style={{ overflowY: "auto", overflowX: "hidden", minHeight: "44em", maxHeight: "44em" }}>
                                                    {
                                                        planosproyecto.datos.map(item => (
                                                            <div>
                                                                <Row className="mb-4">
                                                                    <Col md={3} xs={12} className="row align-items-center">
                                                                        <div className="text-center">
                                                                            <div style={{ color: "#e67e22" }}>
                                                                                <h4>{item.title}</h4>
                                                                            </div>
                                                                            <div>
                                                                                <b>Subido por {item.creator}</b>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <b>{item.description}</b>
                                                                                <br />

                                                                            </div>
                                                                            <div>
                                                                                <Dropdown className="acciones_planos">
                                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                                                                                        Acciones
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu variant="dark" onClick={() => { setSelectPlano(item.id) }}>
                                                                                        <Dropdown.Item onClick={() => handleShow(item.id)}>
                                                                                            Ver Plano
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => peticionDeletePdf(item.id)}>
                                                                                            Eliminar plano
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => handleShow2(item.id)}>
                                                                                            Editar plano
                                                                                        </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                            <br />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={9} xs={12}>
                                                                        {prueba.datos.length === 0 ?
                                                                            <div className="text-center">
                                                                                no hay planos para mostrar
                                                                            </div>
                                                                            :
                                                                            prueba.datos.map(l => (
                                                                                parseInt(l.id) === parseInt(item.id) &&
                                                                                <div>
                                                                                    <iframe
                                                                                        src={`https://docs.google.com/gview?url=${l.image64}&embedded=true`}
                                                                                        type="application/pdf"
                                                                                        className="me-2" alt={planosselect.datos.title}
                                                                                        style={{ width: "100%", height: "30em" }}
                                                                                    ></iframe>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Card.Body>

                                        </Card>
                                        {/* / planos */}
                                    </Col>
                                    <Col xl={12}> {/* <---- seccion documentos */}
                                        {/* documentos */}
                                        <Card className="mb-4" style={{ minHeight: "50em", maxHeight: "50em", height: "50em" }}>
                                            <Card.Header as="h6" className="with-elements">
                                                <div className="card-header-title">Documentacion del proyecto</div>
                                                <div className="card-header-elements ml-auto tableactions">
                                                    <DropdownButton
                                                        id="dropdown-button-dark-example2" variant="secondary"
                                                        menuVariant="dark"
                                                    >
                                                        <Dropdown.Item onClick={handleShow5}>
                                                            Cargar Documentacion
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <div style={{ overflowY: "auto", overflowX: "hidden", minHeight: "44em", maxHeight: "44em" }}>
                                                    {
                                                        planosproyecto1.datos.map(item => (
                                                            <div>
                                                                <Row className="mb-4">
                                                                    <Col md={3} xs={12} className="row align-items-center">
                                                                        <div className="text-center">
                                                                            <div style={{ color: "#e67e22" }}>
                                                                                <h4>{item.title}</h4>
                                                                            </div>
                                                                            <div>
                                                                                <b>Subido por {item.creator}</b>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <b>{item.description}</b>
                                                                                <br />

                                                                            </div>
                                                                            <div>
                                                                                <Dropdown className="acciones_planos">
                                                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                                                                                        Acciones
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu variant="dark" onClick={() => { setSelectPlano1(item.id) }}>
                                                                                        <Dropdown.Item onClick={() => handleShow7(item.id)}>
                                                                                            Ver Documento
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => peticionDeletePdf1(item.id)}>
                                                                                            Eliminar Documento
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => handleShow4(item.id)}>
                                                                                            Editar Documento
                                                                                        </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                            <br />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={9} xs={12}>
                                                                        {prueba3.datos.length === 0 ?
                                                                            <div className="text-center">
                                                                                no hay Documentacion para mostrar
                                                                            </div>
                                                                            :
                                                                            prueba3.datos.map(l => (
                                                                                parseInt(l.id) === parseInt(item.id) &&
                                                                                <div>
                                                                                    <iframe
                                                                                        src={`https://docs.google.com/gview?url=${l.image64}&embedded=true`}
                                                                                        type="application/pdf"
                                                                                        className="me-2" alt={planosselect1.datos.title}
                                                                                        style={{ width: "100%", height: "30em" }}
                                                                                    ></iframe>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        {/* / documentos */}
                                    </Col>
                                </Row>
                            </div>
                    }

                </div>
                {/* MODAL PLANO EN PANTALLA */}
                <Modal show={show} onHide={handleClose} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Vista del plano</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <embed src={'data:application/pdf;base64,' + imagenpdf64} type="application/pdf"
                                className="me-2" alt={planosselect.datos.title}
                                style={{ width: "100%", height: "30em" }} />
                            {/*<embed src={planosselect.datos.image64} type="application/pdf"
                                className="me-2" alt={planosselect.datos.title}
                                style={{width: "100%", height: "30em"}}/>*/}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL CARGAR PLANO */}
                <Modal show={show1} onHide={handleClose1} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Carga del plano</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdOutlineSubtitles className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Titulo" aria-label="titulo"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="title" name="title"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <AiIcons.AiOutlineUserAdd className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Subido por" aria-label="Subido por"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="creator" name="creator"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <FormControl
                                        type="file" onChange={() => convertToBase64('plano')}
                                        id="pdfup" name="pdfup"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <BsIcons.BsChatText className="text-primary" />
                                        </InputGroup.Text>
                                        <FormControl as="textarea" aria-label="With textarea"
                                            onChange={handleInputChange} id="obs" name="obs"
                                        />
                                    </InputGroup>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose1}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={() => handleCloseSavePdf()}>
                            Cargar plano
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL EDITAR PLANO */}
                <Modal show={show2} onHide={handleClose2} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Editar plano</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdOutlineSubtitles className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Titulo" aria-label="titulo"
                                        aria-describedby="basic-addon1" value={dats.title_2}
                                        type="text" onChange={handleInputChange}
                                        id="title_2" name="title_2"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <AiIcons.AiOutlineUserAdd className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Subido por" aria-label="Subido por"
                                        aria-describedby="basic-addon1" value={dats.creator_2}
                                        type="text" onChange={handleInputChange}
                                        id="creator_2" name="creator_2"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <FormControl
                                        type="file" onChange={() => convertToBase64('plano')}
                                        id="pdfup" name="pdfup"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <BsIcons.BsChatText className="text-primary" />
                                        </InputGroup.Text>
                                        <FormControl as="textarea" aria-label="With textarea" value={dats.obs_2}
                                            onChange={handleInputChange} id="obs_2" name="obs_2"
                                        />
                                    </InputGroup>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose2}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={() => handleCloseSavePdfEdit()}>
                            Editar plano
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL ASIGNAR PERSONAL CONSTRUCCION */}
                <Modal show={show3} onHide={handleClose3} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Asignacion personal de obra</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsFillFilePostFill />
                                    </InputGroup.Text>
                                    <Form.Select aria-label="Default select example"
                                        onChange={handleChangeSelectG2} id="position_per" name="position_per"
                                    >
                                        <option value="">Seleccione el cargo</option>
                                        <option value='encargado'>Encargado</option>
                                        <option value='almacenista'>Almacenista</option>
                                        <option value='capataz'>Capatáz</option>
                                        <option value='obrero'>Obrero</option>
                                        <option value='asistente'>Asistente</option>
                                        <option value='director de obra'>Director de obra</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsFillPersonFill />
                                    </InputGroup.Text>
                                    <Form.Select aria-label="Default select example"
                                        onChange={handleChangeSelectG} id="select_per" name="select_per"
                                    >
                                        <option value="">Seleccione personal</option>
                                        {personalcombo.datos.length === 0 ?
                                            <div>
                                                <span>no hya personal creado en la tabla</span>
                                            </div>
                                            :
                                            personalcombo.datos.map(w => (
                                                <option value={w.id}>{w.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose3}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={() => handleCloseSavePer()}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL PLANO EN PANTALLA */}
                <Modal show={show6} onHide={handleClose6} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Vista del documento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <embed src={'data:application/pdf;base64,' + imagenpdf64} type="application/pdf"
                                className="me-2" alt={planosselect1.datos.title}
                                style={{ width: "100%", height: "30em" }} />
                            {/*<embed src={planosselect.datos.image64} type="application/pdf"
                                className="me-2" alt={planosselect.datos.title}
                                style={{width: "100%", height: "30em"}}/>*/}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose6}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL CARGAR DOCUMENTO */}
                <Modal show={show5} onHide={handleClose5} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Carga del documento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdOutlineSubtitles className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Titulo" aria-label="titulo"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="title" name="title"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <AiIcons.AiOutlineUserAdd className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Subido por" aria-label="Subido por"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChange}
                                        id="creator" name="creator"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <FormControl
                                        type="file" onChange={() => convertToBase64('doc')}
                                        id="pdfup" name="pdfup"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <BsIcons.BsChatText className="text-primary" />
                                        </InputGroup.Text>
                                        <FormControl as="textarea" aria-label="With textarea"
                                            onChange={handleInputChange} id="obs" name="obs"
                                        />
                                    </InputGroup>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose5}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={() => handleCloseSavePdf1()}>
                            Cargar documento
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL EDITAR DOCUMENTO */}
                <Modal show={show4} onHide={handleClose4} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Editar plano</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdOutlineSubtitles className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Titulo" aria-label="titulo"
                                        aria-describedby="basic-addon1" value={dats.title_3}
                                        type="text" onChange={handleInputChange}
                                        id="title_3" name="title_3"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup.Text id="basic-addon1">
                                        <AiIcons.AiOutlineUserAdd className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Subido por" aria-label="Subido por"
                                        aria-describedby="basic-addon1" value={dats.creator_3}
                                        type="text" onChange={handleInputChange}
                                        id="creator_3" name="creator_3"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <FormControl
                                        type="file" onChange={() => convertToBase64('doc')}
                                        id="pdfup" name="pdfup"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                    <InputGroup>
                                        <InputGroup.Text>
                                            <BsIcons.BsChatText className="text-primary" />
                                        </InputGroup.Text>
                                        <FormControl as="textarea" aria-label="With textarea" value={dats.obs_3}
                                            onChange={handleInputChange} id="obs_3" name="obs_3"
                                        />
                                    </InputGroup>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose4}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={() => handleCloseSavePdfEdit1()}>
                            Editar documento
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* MODAL PLANO EN PANTALLA */}
                <Modal show={show7} onHide={handleClose7} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Vista del Documento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {documentoView === undefined ?
                                ''
                            :
                                <iframe
                                    src={`https://docs.google.com/gview?url=${documentoView.image64}&embedded=true`}
                                    type="application/pdf"
                                    className="me-2" alt={documentoView.title}
                                    style={{ width: "100%", height: "30em" }}
                                ></iframe>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose7}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>
    );
}

export default Proyectos;
