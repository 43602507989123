import React, {useState, Fragment, useEffect} from "react";
import Navbar from '../navbar';
import { 
    Card, Row, Col, Accordion, Dropdown, InputGroup,
    DropdownButton, Button, Modal, Table, FormControl,
    Form,ButtonGroup,
} from 'react-bootstrap'
import {Redirect, useParams, Link} from "react-router-dom";
import { Badge } from "reactstrap";
import {useUserSession} from "../../hooks";
import { Routes } from "../../utils/routes";

import {
    DEDALO_ALL_MEETING,DEDALO_NEW_MEETING,DEDALO_EDIT_MEETING,DEDALO_ALL_MEETING_CONTRUCTION,
    DEDALO_DEL_MEETING,DEDALO_MEETING_BY_ID,DEDALO_MEETING_FILE,
    DEDALO_MEETING_COMMENT,DEDALO_DEL_MEETING_FILE,DEDALO_DEL_MEETING_COMMENT,
    DEDALO_SEND_MEETING_EMAIL,DEDALO_EDIT_MEETING_FILE,DEDALO_EDIT_MEETING_COMMENT,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import { 
    attachToken,
    manageError,
} from "../../utils/utils";
import Swal from "sweetalert2";
import ReactHtmlParser from 'html-react-parser';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { da } from "date-fns/locale";

const DataReuniones = () => {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [archivobase64, setArchivoBase64] = useState();
    const [archivo, setArchivo] = useState();
    const [comentario, setComentario] = useState();
    const [infoacta, setInfoacta] = useState();
    const [idfileedit,setIdFileEdit] = useState();
    const [idcommentedit,setIdCommentEdit] = useState();

    const [reuniones, setReuniones] = useState({
        datos: [],
    });

    const [reunionesbyid, setReunionesById] = useState({
        datos: [],
        files: [],
        signatories: [],
        meetingComments: [],
    });

    const peticionGetReuniones=async()=>{
        const params_data = {
            idconstruction: id,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_MEETING_CONTRUCTION,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            //console.log(response);
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                if(datanotice.length === 0){
                    setReuniones({
                        ...reuniones,
                        ['datos']: [],
                    });
                }else{
                    setReuniones({
                        ...reuniones,
                        ['datos']: datanotice.records,
                    });
                }
            } else {
                alert('message_responses[response.data.message]');
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetActasById=async(IdActa)=>{
        let idacta = IdActa;
        //console.log(idacta);
        if(idacta === undefined){
            let re = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_MEETING_BY_ID+'&id='+idacta,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice);
                    if(datanotice.length === 0){
                        setReunionesById({
                            ...reunionesbyid,
                            ['datos']: [],
                            ['files']: [],
                            ['signatories']: [],
                            ['meetingComments']: [],
                        });
                    }else{
                        setReunionesById({
                            ...reunionesbyid,
                            ['datos']: datanotice.data,
                            ['files']: datanotice.data.files,
                            ['signatories']: datanotice.data.signatories,
                            ['meetingComments']: datanotice.data.meetingComments,
                        });
                    }
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
        
            return info1;
        }
    };

    useEffect(() => {
        peticionGetReuniones();
        //peticionGetActasById();
    }, []);

    //useEffect(() => {
    //    peticionGetActasById();
    //}, [infoacta]);

    const [dats, setDat] = useState({
        title: '',
        creator: '',
        obs: '',
        title_2: '',
        creator_2: '',
        obs_2: '',
        position_per: '',
    });

    const [datsedit, setDatEdit] = useState({
        obs_2: '',
    });

    const [datsfile, setDatFile] = useState({
        ext: '',
        title_file: '',
    });

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {   
        setDatEdit({
            ...datsedit,
            [event.target.name] : event.target.value
        })
    };

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (e) => {
        setComentario(e);
        setShow1(true);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setIdFileEdit(e);
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setArchivo(e);
        setShow3(true);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (e) => {
        setInfoacta(e);
        setShow4(true);
    };

    let correos = '';
    let correos_adjuntos = [];
    let data_correos = reunionesbyid.signatories;
    let data_adjuntos = reunionesbyid.files;

    for(let i=0;i < data_correos.length;i++){
        correos = correos + data_correos[i].signatorieEmail + ',';
    };

    for(let i=0;i < data_adjuntos.length;i++){
        correos_adjuntos.push(data_adjuntos[i].file);
    };
    
    const convertToBase64 = () => {
        //Read File
        var selectedFile = document.getElementById("pdfup").files;
        let selectedFile1 = document.getElementById("pdfup").value;
        let nom1_archivo = document.getElementById('pdfup').files[0];
        let nom2_archivo = document.getElementById('pdfup');
        let extension = selectedFile1.substring(selectedFile1.lastIndexOf('.'),selectedFile1.length);
        let MAXIMO_TAMANIO_BYTES = 50000000;

        setDatFile({
            ...datsfile,
            ['title_file']: nom1_archivo.name.split('.')[0],
            ['ext']: extension
        });
        
        if(nom1_archivo.size > MAXIMO_TAMANIO_BYTES){
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!",`El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`,"info");
            nom2_archivo.value = "";
            
            setDatFile({
                ...datsfile,
                ['title_file']: '',
                ['ext']: ''
            });
        }else{
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                //var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.split(',')
                    setArchivoBase64(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                // Obtener extensión del archivo
            }
        }
    };

    const handleCloseSaveAdjuntarArchivo = (e) => {
    
        let name = datsfile.ext;
        let obs = dats.observaciones_acta;
        
        const formattedData = {
            record_meeting_id: archivo,
            creator_id: userId,
            type: datsfile.ext,
            file: archivobase64,
        };
        //console.log(formattedData);
        if(name === ''){
            Swal.fire("No se pudo adjuntar archivo, valide que haya seleccionado un archivo adjunto", "", "warning");
        }else{
            //axios.post(DEDALO_NUEVA_CONSTRUCCION, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_MEETING_FILE,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se adjunto el archivo al acta exitósamente", "", "success");
                    handleClose3();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });   
        };
    };

    const handleCloseSaveComment = (e) => {
    
        let name = dats.obs;
        
        const formattedData = {
            record_meeting_id: comentario,
            creator_id: userId,
            comment: name,
        };
        //console.log(formattedData);
        if(name === ''){
            Swal.fire("No se puede comentar acta, valide que el campo este con texto.", "", "warning");
        }else{
            //axios.post(DEDALO_NUEVA_CONSTRUCCION, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_MEETING_COMMENT,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se comento el acta exitósamente", "", "success");
                    handleClose1();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });   
        };
    };

    const handleCloseSendMeetingEmail = () => {
        
        const formattedData = {
            fromName: '',
            toEmail: correos,
            subject: reunionesbyid.datos.tile,
            textBody: "test email",
            filename: correos_adjuntos.length === 0 ? [] : correos_adjuntos,
            htmlBody: `<!DOCTYPE html>
                        <html>
                        <head>
                            <title>Appointment</title>
                        </head>
                        <body>
                            <div>
                                <div style="width: 80%; margin: auto;">
                                    <div style="text-align: center; padding: 1em 0.5em">
                                        <img src='https://main.d3mf5ou0dnk574.amplifyapp.com/static/media/dedalo_logo.c9bdd832.png' alt='' width='160'>
                                    </div>
                                    <div style="padding: 0.5em 0.5em">
                                        <p>
                                            <b>Nombre del Acta:</b> ${reunionesbyid.datos.tile}
                                        </p>
                                        <p>
                                            <b>Fecha del Acta:</b> ${reunionesbyid.datos.date}
                                        </p>
                                    </div>
                                    <div style="padding: 0.5em 0.5em">
                                        ${reunionesbyid.datos.content}
                                    </div>
                                </div>
                            </div>
                        </body>
                        </html>`,
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_SEND_MEETING_EMAIL,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se envio el correo exitósamente", "", "success");
                handleClose1();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    const handleCloseSaveEditAdjuntarArchivo = (e) => {
    
        let name = datsfile.ext;
        let obs = dats.observaciones_acta;
        
        const formattedData = {
            record_meeting_id: reunionesbyid.datos.id,
            creator_id: userId,
            type: datsfile.ext,
            file: archivobase64,
        };
        //console.log(formattedData);
        if(name === ''){
            Swal.fire("No se pudo adjuntar archivo, valide que haya seleccionado un archivo adjunto", "", "warning");
        }else{
            //axios.post(DEDALO_NUEVA_CONSTRUCCION, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_EDIT_MEETING_FILE+'&id='+idfileedit,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se adjunto el archivo al acta exitósamente", "", "success");
                    handleClose2();
                    peticionGetActasById(reunionesbyid.datos.id);
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });   
        };
    };

    const handleCloseSaveEditComment = (e) => {
    
        let name = datsedit.obs_2;
        
        const formattedData = {
            record_meeting_id: reunionesbyid.datos.id,
            creator_id: userId,
            comment: name,
        };
        //console.log(formattedData);
        if(name === ''){
            Swal.fire("No se puede editar comentario, valide que el campo este con texto.", "", "warning");
        }else{
            //axios.post(DEDALO_NUEVA_CONSTRUCCION, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_EDIT_MEETING_COMMENT+'&id='+idcommentedit,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito comentario del acta exitósamente", "", "success");
                    peticionGetActasById(reunionesbyid.datos.id);
                    setIdCommentEdit(0);
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });   
        };
    };

    const descargaarchivo = (FileName,File64) =>{
        const downloadinstance = document.createElement('a');
        downloadinstance.href = File64;
        downloadinstance.target = '_blank';
        downloadinstance.download = FileName.split('/')[FileName.split('/').length-1];
        downloadinstance.click();
        //console.log(downloadinstance.href);
    };

    const peticionDeleteMeeting = (idmeeting) => {
        let delId = idmeeting;
        
        //axios.delete(DEDALO_DEL_SUBTASK(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_MEETING+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el acta exitósamente", "", "success");
                peticionGetReuniones();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const peticionDeleteMeetingFile = (idfilemeeting) => {
        let delId = idfilemeeting;

        const formattedData = {
            creator_id: userId,
        };
        //axios.delete(DEDALO_DEL_SUBTASK(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_MEETING_FILE+'&id='+delId,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el archivo adjunto exitósamente", "", "success");
                peticionGetActasById(infoacta);
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const peticionDeleteMeetingComment = (idcommentmeeting) => {
        let delId = idcommentmeeting;

        const formattedData = {
            creator_id: userId,
        };
        //axios.delete(DEDALO_DEL_SUBTASK(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_MEETING_COMMENT+'&id='+delId,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el comentario exitósamente", "", "success");
                peticionGetActasById(infoacta);
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    //console.log([reunionesbyid.datos]);

    return(
        <div>
            <div className="table-responsive" style={{minHeight: "25em", maxHeight: "25em"}}>
                <Table className="mb-0 tableactions2" hover>
                    <thead className="text-center">
                        <tr>
                            <th>#</th>
                            <th>Nombre de la Reunion</th>
                            <th>Fecha de la reunion</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {reuniones.datos.map((item, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.tile}</td>
                                <td>{item.date}</td>
                                <td>
                                    <div class="dropdown dropstart" onClick={()=>peticionGetActasById(item.id)}>
                                        <MdIcons.MdArrowLeft type="button" class="dropdown-toggle" 
                                            data-bs-toggle="dropdown" aria-expanded="false" style={{fontSize: '26px'}}
                                        />
                                        <ul class="dropdown-menu menu_actas">
                                            <Link to={Routes.EDIT_REUNION(item.id)} className="text-decoration-none">
                                                <li>
                                                    <a class="dropdown-item cursor-pointer">
                                                            Editar Acta
                                                    </a>
                                                </li>
                                            </Link>
                                            {user.role_id === 3 ?
                                                <li>
                                                    <a class="dropdown-item cursor-pointer" onClick={()=>peticionDeleteMeeting(item.id)}>
                                                        Eliminar Acta
                                                    </a>
                                                </li>
                                            :
                                                ''
                                            }
                                            <li>
                                                <a class="dropdown-item cursor-pointer" onClick={()=>handleShow4(item.id)}>
                                                    Detalles del acta
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item cursor-pointer" onClick={()=>handleShow1(item.id)}>
                                                    Comentar reunion
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item cursor-pointer" onClick={()=>handleShow3(item.id)}>
                                                    Adjuntar archivo al Acta
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item cursor-pointer" onClick={()=>handleCloseSendMeetingEmail()}>
                                                    Enviar acta por correo
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            {/* MODAL COMENTAR ACTA */}
            <Modal show={show1} onHide={handleClose1} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Comentar Acta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" 
                                        onChange={handleInputChange} id="obs" name="obs"
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSaveComment}>
                        Comentar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR ARCHIVO ACTA */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Adjuntar archivo al acta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    type="file" onChange={()=>convertToBase64()}
                                    id="pdfup" name="pdfup"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSaveEditAdjuntarArchivo}>
                        Cargar archivo
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL ADJUNTAR ARCHIVO ACTA */}
            <Modal show={show3} onHide={handleClose3} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Adjuntar archivo al acta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    type="file" onChange={()=>convertToBase64()}
                                    id="pdfup" name="pdfup"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSaveAdjuntarArchivo}>
                        Cargar archivo
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL DETALLES DEL ACTA */}
            <Modal show={show4} onHide={handleClose4} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalle del acta</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{maxHeight: '35em', overflowY: 'auto'}}>
                    <Row>
                        <Col md={12} xs={12}> {/* seccion informacion del acta */}
                            <p>
                                <b>Nombre del Acta:</b> {reunionesbyid.datos.tile}
                            </p>
                            <p>
                                <b>Fecha del Acta:</b> {reunionesbyid.datos.date}
                            </p>
                            <p>
                                <b>Archivos adjuntos:</b>
                                {reunionesbyid.files.length === 0 ?
                                    <p>No hay archivos adjuntos</p>
                                :
                                    reunionesbyid.files.map((f,f_index) => (
                                        parseInt(f.creator_id) === userId ?
                                            <DropdownButton id="dropdown-basic-button" className="mb-2 menu_archivos" size="sm" drop='end'
                                                key={f_index} title={f.file.split('/')[2].split('.')[0]} variant='success'
                                            >
                                                <Dropdown.Item onClick={()=>peticionDeleteMeetingFile(f.id)}>
                                                    Eliminar
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>handleShow2(f.id)}>
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item href="#/action-1" onClick={()=>descargaarchivo(f.file,f.file64)}>
                                                    Descargar Archivo
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        :
                                            <DropdownButton id="dropdown-basic-button" className="mb-2 menu_archivos" size="sm" drop='end'
                                                key={f_index} title={f.file.split('/')[2].split('.')[0]} variant='success'
                                            >
                                                <Dropdown.Item href="#/action-1" onClick={()=>descargaarchivo(f.file,f.file64)}>
                                                    Descargar Archivo
                                                </Dropdown.Item>
                                            </DropdownButton>
                                    ))
                                }
                            </p>
                            <p>
                                <b>Personal que asistieron:</b>
                                {reunionesbyid.signatories.map((s,s_index) => (
                                    <span key={s_index}>
                                        {' '+s.signatorieName},
                                    </span>
                                ))}
                            </p>
                            <p>
                                <b>Comentarios del acta</b>
                                <div className="comentarios_detalles">
                                    {reunionesbyid.meetingComments.length === 0 ?
                                        <p>No hay comentarios</p>
                                    :
                                        reunionesbyid.meetingComments.map((c,c_index) => (
                                            <span key={c_index}>
                                                <b style={{color: "#d35400"}} className="me-2">{c.creatorName}</b>
                                                {parseInt(c.creator_id) === userId ?
                                                    <span>
                                                        <MdIcons.MdEdit
                                                            className="text-primary cursor-pointer me-2"
                                                            title="Editar comentario" onClick={()=>{
                                                                setIdCommentEdit(c.id); 
                                                                setDatEdit({
                                                                    ...datsedit,
                                                                    ['obs_2']: c.comment
                                                                });
                                                            }}
                                                        />
                                                        <MdIcons.MdDelete 
                                                            className="text-danger cursor-pointer me-2"
                                                            title="Eliminar comentario"
                                                            onClick={()=>peticionDeleteMeetingComment(c.id)}
                                                        />
                                                    </span>
                                                :
                                                    ''
                                                }
                                                <p className="text-muted">
                                                    {idcommentedit > 0 ?
                                                        <div>
                                                            <InputGroup style={{width: "100%", margin: "auto"}}>
                                                                <FormControl
                                                                    placeholder="Titulo del acta" value={datsedit.obs_2}
                                                                    aria-describedby="basic-addon1"
                                                                    type="text" onChange={handleInputChangeEdit}
                                                                    id="obs_2" name="obs_2"
                                                                />
                                                                <InputGroup.Text id="basic-addon1"
                                                                    className="text-primary cursor-pointer"
                                                                    onClick={()=>handleCloseSaveEditComment(c.id)}
                                                                >
                                                                    <MdIcons.MdEdit className="me-2"/>
                                                                    Editar
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                            <span className="font-weight-bolder fs-6">
                                                                <a className="text-decoration-none cursor-pointer" onClick={()=>{setIdCommentEdit(0)}}>
                                                                    Cancelar
                                                                </a>
                                                            </span>
                                                        </div>
                                                    :
                                                        c.comment
                                                    }
                                                </p>
                                            </span>
                                        ))
                                    }
                                </div>
                            </p>
                        </Col>
                        <Col md={12} xs={12}> {/* seccion del acta */}
                            <p>
                                <b>Acta generada</b>
                            </p>
                            <div style={{height: 'auto',padding: '10px 10px',border: '1px solid black'}}>
                                {reunionesbyid.datos.length === 0 ?
                                    <p>No hay acta generada</p>
                                :
                                    ReactHtmlParser(reunionesbyid.datos.content)
                                }
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose4}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default DataReuniones;