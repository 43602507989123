import React, {createContext, useContext, useState} from "react";
import PropTypes from "prop-types";
import {UserSessionPropTypes} from "../utils/propTypes";

const UserSessionContext = createContext();

const useUserSession = () => useContext(UserSessionContext);

export const UserSessionContextProvider = ({children, userSession, TokenUser}) => {
  const USER_SESSION_NAME = "dedalo_usr";
  const USER_SESSION_TOKEN = "dedalo_usr_token";

  const [user, setUser] = useState(userSession ? userSession : undefined);
  const [userId, setUserId] = useState(userSession ? userSession.id : undefined);
  const [userToken, setUserToken] = useState(TokenUser ? TokenUser : undefined);

  /*const storeUserSession = user_ => {
    localStorage.setItem(USER_SESSION_NAME, JSON.stringify(user_));
    setUserId(user_ ? user_.id : undefined);
    setUser(user_);
    setUserToken(user_ ? user_.token : undefined);
  };*/

  const storeUserSession = (user_, token_) => {
    localStorage.setItem(USER_SESSION_NAME, JSON.stringify(user_));
    localStorage.setItem(USER_SESSION_TOKEN, JSON.stringify(token_));
    setUserId(user_ ? user_.id : undefined);
    setUser(user_);
    setUserToken(token_ ? token_ : undefined);
  };

  /*const getUserSession = () => {
    const user_ = JSON.parse(localStorage.getItem(USER_SESSION_NAME));
    setUser(user_);
    setUserId(user_ ? user_.id : undefined);
    setUserToken(user_ ? user_.token : undefined);
  };*/

  const getUserSession = () => {
    const user_ = JSON.parse(localStorage.getItem(USER_SESSION_NAME));
    const token_ = JSON.parse(localStorage.getItem(USER_SESSION_TOKEN));
    setUser(user_);
    setUserId(user_ ? user_.id : undefined);
    setUserToken(token_ ? token_ : undefined);
  };

  const updateUserSession = userUpdates => {
    const userSessionUpdated = {
      ...user,
      ...userUpdates,
    };

    storeUserSession(userSessionUpdated);
  };

  const clearUserSession = () => {
    localStorage.removeItem(USER_SESSION_NAME);
    setUser(undefined);
    setUserId(undefined);
    setUserToken(undefined);
  };

  return (
    <UserSessionContext.Provider
      value={{
        user,
        userId,
        userToken,
        storeUserSession,
        getUserSession,
        updateUserSession,
        clearUserSession,
      }}
    >
      {children}
    </UserSessionContext.Provider>
  );
};

UserSessionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userSession: UserSessionPropTypes,
};

UserSessionContextProvider.defaultProps = {
  userSession: undefined,
};

export default useUserSession;
