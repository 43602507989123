import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,FloatingLabel,
} from 'react-bootstrap';
import {
    DEDALO_REQUISITIONS_COMBO,DEDALO_COMBO_VENDORS_FULL,
    DEDALO_COST_CENTER_COMBO,DEDALO_COMBO_INVENTARIO,
    DEDALO_NEW_SHOPPING_SUPPLIES,DEDALO_SHOPPING_SUPPLIES_BY_ID,
    DEDALO_UPDATE_SHOPPING_SUPPLIES,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import Swal from "sweetalert2";
import html2PDF from "jspdf-html2canvas";
import { socket } from '../../../assets/js/conexion_socket';
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";
import Img1 from './imagenes_reportes/logo_geo.png';

const RequisicionNueva = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const data_emp2 = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [aumento, setAumento] = useState(0);
    const [row_count, setRow_Count] = useState(0);
    const [proveedor, setProveedor] = useState('');
    const [formapago, setFormaPago] = useState('');
    const [tipoformato, setTipoFormato] = useState('');

    let {IdEditarOrd} = useParams();

    const [dats, setDat] = useState({
        date: '',
        dead_date: '',
        way_to_pay: '',
        numberpay: '',
        number_shopping: '',
        concept: '',
        descuento: '',
    });

    const [datsedit, setDatEdit] = useState({
        date: '',
        type: '',
        produced_by: '',
        dead_date: '',
        way_to_pay: '',
        numberpay: '',
        number_shopping: '',
        concept: '',
        descuento: '',
        crm_vendor_id: '',
        crm_vendor_name: '',
        shopping_supplies: [],
    });

    const [customerscombo, setCustomersCombo] = useState({
        datos: [],
    });

    const [suppliescombo, setSuppliesCombo] = useState({
        datos: [],
    });

    const [requisicionescombo, setRequisicionesCombo] = useState({
        datos: [],
    });

    const [costcentercombo, setCostCenterCombo] = useState({
        datos: [],
    });

    const [rows, setRows] = useState([
        { id: 0,req: '', desc: '', cen_cos: '', cnt: '', v_uni: '', iva: '', total: '', cen_cos_name: '' },
    ]);

    const peticionGetClientesCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_VENDORS_FULL,
            headers: {Authorization: userToken},
            data: params_data,
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setCustomersCombo({
                    ...customerscombo,
                    ['datos']: datanotice,
                });
            } else {
                setCustomersCombo({
                    ...customerscombo,
                    ['datos']: [],
                });
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
              alert(
                error.response.data.message === "VALIDATOR_ERROR"
                  ? error.response.data.data.error_message
                  : message_responses[error.response.data.message]
              );
            } else {
              alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
        
    };

    const peticionGetSuppliesId=async()=>{
        const params_data = {
            constructions_id: parseInt(data_emp2.Id_Project_Conta),
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_INVENTARIO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setSuppliesCombo({
                    ...suppliescombo,
                    ['datos']: datanotice.data,
                });
            } else {
                setSuppliesCombo({
                    ...suppliescombo,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetRequisiciones=async()=>{
        const params_data = {
            constructions_id: parseInt(data_emp2.Id_Project_Conta),
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_REQUISITIONS_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setRequisicionesCombo({
                    ...requisicionescombo,
                    ['datos']: datanotice.data,
                });
            } else {
                setRequisicionesCombo({
                    ...requisicionescombo,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetCostCenter=async()=>{
        const params_data = {
            enterprice_id: parseInt(data_emp.IdEmp),
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COST_CENTER_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setCostCenterCombo({
                    ...costcentercombo,
                    ['datos']: datanotice.data,
                });
            } else {
                setCostCenterCombo({
                    ...costcentercombo,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetOrdenById=async()=>{
        let useredit = parseInt(IdEditarOrd);

        if(useredit === undefined){
            let ry = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_SHOPPING_SUPPLIES_BY_ID+'&id='+useredit,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    let d = [];
                    //console.log(datanotice);
                    setDatEdit({
                        ...datsedit,
                        ['date']: datanotice.date,
                        ['type']: datanotice.type,
                        ['dead_date']: datanotice.dead_date,
                        ['way_to_pay']: datanotice.way_to_pay,
                        ['numberpay']: datanotice.numberpay,
                        ['number_shopping']: datanotice.number_shopping,
                        ['concept']: datanotice.concept,
                        ['descuento']: datanotice.descuento,
                        ['crm_vendor_id']: datanotice.crm_vendor_id,
                        ['crm_vendor_name']: datanotice.nameCrmVendor,
                        ['produced_by']: datanotice.produced_by,
                        ['shopping_supplies']: datanotice.shopping_supplies,
                    });
                    datanotice.shopping_supplies.map((item, index) => {
                        d.push({
                            id: item.id,
                            req: item.requisitions_id,
                            desc: item.inventory_id,
                            cen_cos: item.cost_center_id,
                            cnt: item.amount,
                            v_uni: item.price,
                            iva: item.iva,
                            total: '',
                            cen_cos_name: item.nameCostCenter,
                        });
                    });
                    setRows(d);
                    let check_s = document.getElementById('servicio');
                    let check_c = document.getElementById('compra');
                    datanotice.type === 'purchase order' ? check_c.checked = true : check_c.checked = false
                    datanotice.type === 'service order' ? check_s.checked = true : check_s.checked = false
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                  alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                      ? error.response.data.data.error_message
                      : message_responses[error.response.data.message]
                  );
                } else {
                  alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
            return info1;
        }
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    useEffect(() => {
        peticionGetClientesCombo();
        peticionGetSuppliesId();
        peticionGetRequisiciones();
        peticionGetCostCenter();
        peticionGetOrdenById();
    }, []);

    const agregarFila = () =>{
        setAumento(aumento+1);
        setRows([
            ...rows,
            {
                id: rows.length + 0,req: '', desc: '',
                cen_cos: '', cnt: '', v_uni: '', iva: '', total: ''
            },
        ]);
    };

    const eliminarFila = (i) => {
        const list = [...rows];
        list.splice(i, 1);
        setRows(list);
    };

    const handleInputChange = (e, index) =>{
        let cantidad = document.getElementById('cnt_'+index);
        let iva = document.getElementById('iva_'+index);
        let valor_unitario = document.getElementById('v_uni_'+index);

        let valor_u = valor_unitario.value === '' ? 0 : valor_unitario.value;
        let valor_c = cantidad.value === '' ? 0 : cantidad.value;
        let tatal_formato = ((parseInt(valor_u)+(parseInt(valor_u)*0.16))*parseInt(valor_c));

        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        list[index]['iva'] = (parseInt(valor_u)*0.16);
        list[index]['total'] = (tatal_formato);
        setRows(list);
    };
    
    const handleInputChangeData = (event) => {
        setDatEdit({
            ...datsedit,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeProveedor = (event) => {
        setProveedor(event.target.value);
    };

    const handleChangeFormaPago = (event) => {
        setFormaPago(event.target.value);
    };

    const handleChangeTipoFormato = (event) => {
        if(event.target.checked === true){
            setTipoFormato(event.target.value);
        }else{
            setTipoFormato('');
        }
    };

    let req_supli = [];
    let desc_supli = [];
    let cnt_supli = [];
    let cen_cos_supli = [];
    let total_supli = [];
    let iva_supli = [];
    let total_unico = [];

    rows.map((row, index) => {
        cnt_supli.push(row.cnt === '' ? '' : parseInt(row.cnt));
        req_supli.push(row.req === '' ? '' : parseInt(row.req));
        desc_supli.push(row.desc === '' ? '' : parseInt(row.desc));
        cen_cos_supli.push(row.cen_cos === '' ? '' : parseInt(row.cen_cos));
        total_supli.push(row.v_uni);
        iva_supli.push(row.iva);
        total_unico.push(
            {
                id: row.id,
                total: (parseInt(row.v_uni) + parseInt(row.iva)) * parseInt(row.cnt)
            },
        );
    });

    const printContainer = useRef(null);

    const createPDF = async () => {
        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: "./pdf/generate.pdf"
        });
    }; 

    let info_proveedor = customerscombo.datos.filter(x => x.id === parseInt(proveedor === '' ? parseInt(datsedit.crm_vendor_id) : proveedor));

    let sumall_iva = rows.map(item => item.iva === '' ? 0 : parseInt(item.iva)).reduce((prev, curr) => (prev + curr), 0);
    let sumall_vunit = rows.map(item => item.v_uni === '' ? 0 : parseInt(item.v_uni)).reduce((prev, curr) => (prev + curr), 0);
    let sumall_total = rows.map(item => item.total === '' ? 0 : parseInt(item.total)).reduce((prev, curr) => (prev + curr), 0);
    let sum_tatal = total_unico.map(item => item.total).reduce((prev, curr) => (prev + curr), 0);
        
    const handleCloseSave = (e) => {

        e.preventDefault();
        let obs = dats.observaciones;

        const formattedData = {
            constructions_id: data_emp2.Id_Project_Conta,
            crm_vendor_id: parseInt(proveedor === '' ? datsedit.crm_vendor_id : proveedor),
            type: tipoformato === '' ? datsedit.type : tipoformato,
            date: datsedit.date,
            dead_date: datsedit.dead_date,
            produced_by:  user.name,
            way_to_pay:  formapago === '' ? datsedit.way_to_pay : formapago,
            numberpay: datsedit.numberpay,
            number_shopping: 1,
            users_id: userId,
            concept: datsedit.concept,
            ivapercentaje: 0.16,
            requisitions_id: req_supli,
            supplies_id: desc_supli,
            amounts:  cnt_supli,
            cost_center_id: cen_cos_supli,
            prices: total_supli,
            iva: iva_supli,
        };
        //console.log(formattedData);
        if(obs === '' ){
            Swal.fire("no se puede crear la requisicion, verifique que los campos esten diligenciados", "", "warning");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_SHOPPING_SUPPLIES+'&id='+parseInt(IdEditarOrd),
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire("Se ha editado la orden exitósamente", "", "success");
                    peticionGetOrdenById();
                    enviarnoti();
                }else {
                    Swal.fire("No se ha editado la orden ya existe en la base de datos", "", "info");
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    const enviarnoti = () => {
        let usuario = user.name;
        let tipo = 'editado';
        let orden = datsedit.number_shopping;

        socket.emit("dl:edicion_orden", ({usuario,tipo,orden}));
    }

    let ceros = '';
    let prueba = datsedit.number_shopping.length;
    for(let i = 0;i < 10;i++){
        ceros += '0';
    }
    let newStr = ceros.substr(prueba);
    
    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" onClick={handleCloseSave}
                            title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            Guardar Requisición editada
                        </Button>
                    </div>
                    <div>
                        <Button className="bg-primary me-2" size="sm"
                            onClick={agregarFila} title="Agregar Item"
                        >
                            Agregar Item
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="container " ref={printContainer} style={{padding: '1em 2em 1em 2em'}}>
                    <Row className="border border-dark"> {/* cabecera del formato */}
                        <Col md={2} xs={2} className="text-center">
                            <img src={Img1} alt="logo" width="200" height="150" className="img-fluid py-3" />
                        </Col>
                        <Col md={8} xs={8} className="d-flex justify-content-center align-items-center border-start border-end border-dark">
                            <div >
                                <h3>
                                    FORMATO ORDEN DE COMPRA O SERVICIO
                                </h3>
                            </div>
                        </Col>
                        <Col md={2} xs={2} >
                            <div className="mt-3">
                                <div>
                                    <span>
                                        <b>Código: FO-CP-03-02</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b>Fecha: 01/08/2015</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b>Página 1 de 1</b>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-dark border-start border-end border-botom" style={{backgroundColor: '#DAEEF3'}}>
                        <Col md={12} xs={12} className="border-dark">
                            <Row>
                                <Col md={5} xs={5} className='py-3'>
                                    <div className="border border-dark text-center" style={{backgroundColor: '#ffffff'}}>
                                        <p className="m-0">
                                            GEOESTRUCTURAS LTDA. - NIT: 800.028.676-2
                                        </p>
                                        <p className="m-0">
                                            Carrera 12 No. 67-38 - Teléfono: 5 45 00  14
                                        </p>
                                    </div>
                                </Col>
                                <Col md={5} xs={5}></Col>
                                <Col md={2} xs={2} className='py-3'>
                                    <div className="border border-dark pl-4 py-2" style={{backgroundColor: '#ffffff'}}>
                                        <Form>
                                            <Form.Check 
                                                type='checkbox' onChange={(e)=>handleChangeTipoFormato(e)}
                                                id="servicio" value='service order'
                                                label={`Orden de servicio`}
                                            />
                                            <Form.Check 
                                                type='checkbox' onChange={(e)=>handleChangeTipoFormato(e)}
                                                id="compra" value='purchase order'
                                                label={`Orden de compra`}
                                            />
                                        </Form>
                                    </div>
                                    <div className="border-start border-end border-bottom border-dark d-flex px-4 py-1"
                                        style={{backgroundColor: '#ffffff'}}
                                    >
                                        <div className="me-2">
                                            <b>
                                                <span>No.</span>
                                            </b>
                                        </div>
                                        <div>
                                            <b className="text-muted">
                                                <span>{newStr+datsedit.number_shopping}</span>
                                            </b>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom">
                            <Row>
                                <Col md={7} xs={7} className='pb-3'>
                                    <div className="border border-dark">  {/* seccion info proveedor */}
                                        <div className="text-center d-flex justify-content-center align-items-center"
                                            style={{height: '2em',backgroundColor: '#D8D8D8'}}
                                        >
                                            <span>
                                                <b>PROVEEDOR</b>
                                            </span>
                                        </div>
                                        <div style={{backgroundColor: '#ffffff'}}>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-1 border-end border-dark" style={{width: '20%'}}>
                                                    <b>RAZON SOCIAL:</b>
                                                </p>
                                                <p className="m-0 px-2" style={{width: '80%'}}>
                                                    <Form.Select aria-label="Default select example"
                                                        id="select_vendor" name="select_vendor" size="sm"
                                                        onChange={handleChangeProveedor} className="border-0"
                                                    >
                                                        <option value="">{datsedit.crm_vendor_name}</option>
                                                        {customerscombo.datos === undefined ?
                                                            ''
                                                        :
                                                            customerscombo.datos.map(i => (
                                                                <option value={i.id}>{i.name}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </p>
                                            </div>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                                    <b>NIT:</b>
                                                </p>
                                                <p className="m-0 px-3" style={{width: '80%'}}>
                                                    {info_proveedor[0] === undefined ? '' : info_proveedor[0].nit}
                                                </p>
                                            </div>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                                    <b>DIRECCION:</b>
                                                </p>
                                                <p className="m-0 px-3" style={{width: '80%'}}>
                                                    {info_proveedor[0] === undefined ? '' : info_proveedor[0].address}
                                                </p>
                                            </div>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                                    <b>CIUDAD:</b>
                                                </p>
                                                <p className="m-0 px-3" style={{width: '80%'}}>
                                                    {info_proveedor[0] === undefined ? '' : info_proveedor[0].cities_id}
                                                </p>
                                            </div>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                                    <b>TÉLEFONO:</b>
                                                </p>
                                                <p className="m-0 px-3" style={{width: '80%'}}>
                                                    {info_proveedor[0] === undefined ? '' : info_proveedor[0].vendors_phone}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} xs={2}></Col>
                                <Col md={3} xs={3}  className='pb-3'>
                                    <div className="border border-dark"> {/* seccion de fechas */}
                                        <div className="d-flex border-bottom border-dark">
                                            <p className="m-0 px-2 border-end border-dark" style={{width: '40%',fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                <b>FECHA DE ELABORACION</b>
                                            </p>
                                            <p className="m-0 px-3" style={{width: '60%',fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                <b>FECHA DE VENCIMIENTO</b>
                                            </p>
                                        </div>
                                        <div className="d-flex"> 
                                            <p className="m-0 border-end border-dark" style={{width: '40%',fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                                <FormControl
                                                    placeholder="aaaa-mm-dd" aria-label="Codigo"
                                                    value={datsedit.date.split(' ')[0]}
                                                    aria-describedby="basic-addon1" className="pt-0 border-0"
                                                    type="text" style={{height: '1.7em'}} onChange={(e)=>handleInputChangeData(e)}
                                                    id="date" name="date"
                                                />
                                            </p>
                                            <p className="m-0"  style={{width: '60%',fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                                <FormControl
                                                    placeholder="aaaa-mm-dd" aria-label="Codigo"
                                                    value={datsedit.dead_date.split(' ')[0]}
                                                    aria-describedby="basic-addon1" className="pt-0 border-0"
                                                    type="text" style={{height: '1.7em'}} onChange={(e)=>handleInputChangeData(e)}
                                                    id="dead_date" name="dead_date"
                                                />
                                            </p>
                                        </div>
                                        <div>
                                            <p className="m-0 px-2 border-top border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                <b>ELABORADO POR</b>
                                            </p>
                                            <p className="m-0 px-2" style={{fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                                {user.name}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="m-0 px-2 border-top border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                <b>FORMA DE PAGO</b>
                                            </p>
                                            <p className="m-0" style={{fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                                <Form.Select aria-label="Default select example"
                                                    id="select_vendor" name="select_vendor" size="sm"
                                                    onChange={handleChangeFormaPago} className="border-0"
                                                >
                                                    <option value="">{datsedit.way_to_pay}</option>
                                                    <option value="cash">Efectivo</option>
                                                    <option value="transfer">Transferencia</option>
                                                    <option value="deposit">Deposito</option>
                                                </Form.Select>
                                            </p>
                                        </div>
                                        {formapago === 'transfer' || formapago === 'deposit' ?
                                            <div>
                                                <p className="m-0 px-2 border-top border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                    <b>NUMERO DE PAGO</b>
                                                </p>
                                                <p className="m-0 " style={{fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                                    <FormControl
                                                        placeholder="numero" aria-label="Codigo" value={datsedit.numberpay}
                                                        aria-describedby="basic-addon1" className="pt-0 border-0"
                                                        type="text" style={{height: '1.7em'}} onChange={(e)=>handleInputChangeData(e)}
                                                        id="numberpay" name="numberpay"
                                                    />
                                                </p>
                                            </div>
                                        :
                                            formapago === 'cash' ?
                                                ''
                                            :
                                                datsedit.way_to_pay === 'transfer' || datsedit.way_to_pay === 'deposit' ?
                                                    <div>
                                                        <p className="m-0 px-2 border-top border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                            <b>NUMERO DE PAGO</b>
                                                        </p>
                                                        <p className="m-0 " style={{fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                                            <FormControl
                                                                placeholder="numero" aria-label="Codigo" value={datsedit.numberpay}
                                                                aria-describedby="basic-addon1" className="pt-0 border-0"
                                                                type="text" style={{height: '1.7em'}} onChange={(e)=>handleInputChangeData(e)}
                                                                id="numberpay" name="numberpay"
                                                            />
                                                        </p>
                                                    </div>
                                                : 
                                                    ''
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div className="mb-3 border border-dark"> {/* seccion de concepto */}
                                <div className="text-center border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                    <b>POR CONCEPTO DE:</b>
                                </div>
                                <div className="px-2 py-1" style={{height: '3em',fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                    <FormControl
                                        placeholder="Concepto" aria-label="Codigo" value={datsedit.concept}
                                        aria-describedby="basic-addon1" onChange={(e)=>handleInputChangeData(e)}
                                        type="text" size="sm" className="border-0"
                                        id="concept" name="concept"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-dark border-start border-end border-botom"> {/* tabla de contenidos del formato */}
                        <Col md={12} xs={12} className="border-dark border-bottom">
                            <div className="my-2">
                                <Table responsive id="tabla_req" name="tabla_req">
                                    <thead className="text-center">
                                        <tr style={{fontSize: '0.8em'}}>
                                            <th>ord. servicio</th>
                                            <th>Requisicion</th>
                                            <th>descripcion</th>
                                            <th>centro de costos</th>
                                            <th>cantidad</th>
                                            <th>valor unitario</th>
                                            <th>iva</th>
                                            <th>Total</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows === undefined ?
                                            ''
                                        :
                                            rows.map((row, i) =>(
                                                <tr key={i}>
                                                    <td className="text-center" style={{width: '6em'}}>{i+1}</td>
                                                    <td style={{width: '5em'}}>
                                                        <Form.Select
                                                            aria-label="Default select example" value={row.descripcion}
                                                            id={"req_"+row.id} name="req" onChange={(e) => handleInputChange(e, i)}
                                                            data-testid={i+1} className="me-2" 
                                                        >
                                                            <option>{row.req}</option>
                                                            {requisicionescombo.datos === undefined ?
                                                                ''
                                                            :
                                                                requisicionescombo.datos.map((supply, i) => (
                                                                    <option id={i+1+'_'+supply.id}
                                                                        data-ReqName={supply.number_requisition} value={supply.id}
                                                                    >{supply.id}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    </td>
                                                    <td style={{width: '20em'}}>
                                                        <Form.Select
                                                            aria-label="Default select example" value={row.descripcion}
                                                            id={"desc_"+row.id} name="desc" onChange={(e) => handleInputChange(e, i)}
                                                            data-testid={i+1} className="me-2" 
                                                        >
                                                            <option>Seleccione un suministro</option>
                                                            {suppliescombo.datos === undefined ?
                                                                ''
                                                            :
                                                                suppliescombo.datos.map((supply, i) => (
                                                                    <option id={i+1+'_'+supply.id}
                                                                        data-measurementsName={supply.measurement} value={supply.id}
                                                                    >{supply.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    </td>
                                                    <td style={{width: '14em'}}>
                                                        <Form.Select
                                                            aria-label="Default select example" value={row.descripcion}
                                                            id={"cen_cos_"+row.id} name="cen_cos" onChange={(e) => handleInputChange(e, i)}
                                                            data-testid={i+1} className="me-2" 
                                                        >
                                                            <option>{row.cen_cos_name}</option>
                                                            {costcentercombo.datos === undefined ?
                                                                ''
                                                            :
                                                                costcentercombo.datos.map((supply, i) => (
                                                                    <option id={i+1+'_'+supply.id}
                                                                        data-CodeName={supply.code} value={supply.id}
                                                                    >{supply.code}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    </td>
                                                    <td style={{width: '7em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="Cantidad" name="cnt"
                                                            value={row.cnt} onChange={(e) => handleInputChange(e, i)}
                                                            style={{marginRight: '0', textAlign: 'center'}} id={'cnt_'+i}
                                                        />
                                                    </td>
                                                    <td style={{width: '8em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="Valor" name="v_uni"
                                                            value={row.v_uni} id={'v_uni_'+i}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            style={{marginRight: '0', textAlign: 'center'}}
                                                        />
                                                    </td>
                                                    <td style={{width: '8em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="Iva" name="iva" id={'iva_'+i}
                                                            value={formatterPeso.format(row.iva)} onChange={(e) => handleInputChange(e, i)}
                                                            style={{marginRight: '0', textAlign: 'center'}}
                                                        />
                                                    </td>
                                                    <td style={{width: '8em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="Total" name="total"
                                                            value={
                                                                row.total === '' ?
                                                                    formatterPeso.format((parseInt(row.v_uni) + parseInt(row.iva)) * parseInt(row.cnt))
                                                                :
                                                                    formatterPeso.format(row.total)
                                                            }
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            style={{marginRight: '0', textAlign: 'center'}}
                                                        />
                                                    </td>
                                                    <td style={{width: '3em'}}>
                                                        <div className="text-danger text-center">
                                                            <MdIcons.MdDeleteForever 
                                                                style={{fontSize: '1.6em',cursor: 'pointer'}}
                                                                onClick={()=>eliminarFila(i)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col md={12} xs={12} className='border-bottom border-top border-dark page-break'> {/* seccion de valores */}
                            <Row>
                                <Col md={9} xs={9} className='border-end border-bottom border-dark'>
                                    <div>
                                    </div>
                                </Col>
                                <Col md={3} xs={3} >
                                    <Row>
                                        <Col md={4} xs={4} className='border-end border-bottom border-dark'>
                                            <div className="text-center">
                                                {sumall_vunit === NaN ? '$ 0' : formatterPeso.format(sumall_vunit)}
                                            </div>
                                        </Col>
                                        <Col md={4} xs={4} className='border-end border-bottom border-dark'>
                                            <div className="text-center">
                                                {formatterPeso.format(sumall_iva)}
                                            </div>
                                        </Col>
                                        <Col md={4} xs={4} className='border-bottom border-dark'>
                                            <div className="text-center">
                                                {sumall_total === 0 ? formatterPeso.format(sum_tatal) : formatterPeso.format(sumall_total)}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} xs={4} className='border-end border-dark'>
                                            <div className="text-center">
                                                <b>SUBTOTAL</b>
                                            </div>
                                        </Col>
                                        <Col md={8} xs={8}>
                                            <div className="text-end">
                                                {sumall_vunit === NaN ? '$ 0' : formatterPeso.format(sumall_vunit)}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={9} xs={9} className='border-end border-dark'>
                                    <div>
                                    </div>
                                </Col>
                                <Col md={3} xs={3} >
                                    <Row className="border-top border-dark">
                                        <Col md={4} xs={4} className='border-end border-dark'>
                                            <div className="">
                                                <b>DESCUENTO</b>
                                            </div>
                                        </Col>
                                        <Col md={8} xs={8}>
                                            <div>
                                                <FormControl
                                                    aria-label="Codigo" aria-describedby="basic-addon1"
                                                    className="pt-0 pb-0 border-0 text-end" type="text" style={{height: '1.7em'}}
                                                    onChange={(e)=>handleInputChangeData(e)}
                                                    id="descuento" name="descuento"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="border-top border-dark">
                                        <Col md={4} xs={4} className='border-end border-dark'>
                                            <div className="text-center">
                                                <b>IVA</b>
                                            </div>
                                        </Col>
                                        <Col md={8} xs={8}>
                                            <div className="text-end">
                                                {formatterPeso.format(sumall_iva)}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="border-top border-dark">
                                        <Col md={4} xs={4} className='border-end border-dark'>
                                            <div className="text-center">
                                                <b>TOTAL</b>
                                            </div>
                                        </Col>
                                        <Col md={8} xs={8}>
                                            <div className="text-end">
                                                {dats.descuento === '' ?
                                                    formatterPeso.format((sumall_iva+sumall_vunit))
                                                :
                                                    formatterPeso.format(((sumall_iva+sumall_vunit)-parseInt(dats.descuento)))
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="border-start border-end border border-bottom border-dark"> {/* seccion de datos de recepcion */}
                        <Col md={12} xs={12} className='py-4'>
                            <div className='border border-dark'>
                                <div className="p-2 text-center" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                    <b>DATOS DE RECEPCION DEL SERVICIO O BIENES</b>
                                </div>
                                <div>
                                    <div className="d-flex border-top border-dark">
                                        <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                            <b>DIRECCION:</b>
                                        </p>
                                        <p className="m-0 px-3" style={{width: '80%'}}>
                                        </p>
                                    </div>
                                    <div className="d-flex border-top border-dark">
                                        <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                            <b>CIUDAD:</b>
                                        </p>
                                        <p className="m-0 px-3" style={{width: '80%'}}>
                                        </p>
                                    </div>
                                    <div className="d-flex border-top border-dark">
                                        <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                            <b>TELEFONO:</b>
                                        </p>
                                        <p className="m-0 px-3" style={{width: '80%'}}>
                                        </p>
                                    </div>
                                    <div className="d-flex border-top border-dark">
                                        <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                            <b>PROYECTO:</b>
                                        </p>
                                        <p className="m-0 px-3" style={{width: '80%'}}>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 border border-dark">
                                <div className="p-2 border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                    <b>OBSERVACIONES-CONDICIONES Y CARACTERISTICAS</b>
                                </div>
                                <div className="px-3 py-2" style={{height: '3em',fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xs={12}>
                            <Row className="border-top border-dark">
                                <Col md={6} xs={6} className='border-end border-dark'>
                                    <div className="d-flex">
                                        <div className="d-flex justify-items-center align-items-center" style={{width: '40%',height: '2em'}}>
                                            <b>FIRMA DE RESPONSABLE:</b>
                                        </div>
                                        <div style={{width: '60%'}}>
                                            
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={6}>
                                    <div className="d-flex">
                                        <div className="d-flex justify-items-center align-items-center" style={{width: '40%',height: '2em'}}>
                                            <b>VERIFICADO POR:</b>
                                        </div>
                                        <div style={{width: '60%'}}>
                                            
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default RequisicionNueva;