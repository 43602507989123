import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, FormControl, Row, Col,
} from 'react-bootstrap';
import {
    DEDALO_NEW_QUOTATION,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import {Mensaje_Error_Create_Cot} from '../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import Img1 from './imagenes_reportes/cabecera.PNG'
import Img2 from './imagenes_reportes/pie_pagina.PNG'
import * as MdIcons from "react-icons/md";

const Cotizacion_Nueva = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [body, setBody] = useState('');
    const [body1, setBody1] = useState(0);

    const [dats, setDat] = useState({
        name: '',
        observations: '',
    });

    const handleChange = (e) => {
        //console.log(e);
        setBody(e);
    };

    const handleInputChangeData = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, 
                { 'font': ['Georgia', 'times-new-roman', 'arial'] }
            ],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
                {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'link', 'image', 'video', 
        'code-block','align'
    ];

    const printContainer = useRef(null);

    const createPDF = async () => {
        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: "./pdf/generate.pdf"
        });
    }; 

    const handleCloseSave = (e) => {

        e.preventDefault();
        let obs = dats.observations;

        const formattedData = {
            name: dats.name,
            users_id: userId,
            enterprice_id: data_emp.IdEmp,
            document: body,
            observations: obs
        };
        //console.log(formattedData);
        if(obs === '' ){
            Swal.fire("no se puede crear la requisicion, verifique que los campos esten diligenciados", "", "warning");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NEW_QUOTATION,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire("Se ha creado la cotizacion exitósamente", "", "success");
                    setBody1(1);
                }else {
                    Mensaje_Error_Create_Cot(response.data.msg.error)
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };


    return( 
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                {body1 === 1 ?
                    <div className="d-flex w-100">
                        <div>
                            <Button className="btn btn-success rounded-circle me-2" size="sm" 
                                onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                            >
                                <MdIcons.MdArrowBackIosNew/>
                            </Button>
                        </div>
                        <div>
                            <Button className="btn btn-success me-2" size="sm" 
                                onClick={() => createPDF()} title="Regresar" style={{backgroundColor: "#02B371"}}
                            >
                                descargar PDF
                            </Button>
                        </div>
                    </div>
                :
                    <div className="d-flex w-100">
                        <div>
                            <Button className="btn btn-success rounded-circle me-2" size="sm" 
                                onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                            >
                                <MdIcons.MdArrowBackIosNew/>
                            </Button>
                        </div>
                        <div>
                            <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}
                                onClick={handleCloseSave}
                            >
                                Guardar Cotizacion nueva
                            </Button>
                        </div>
                    </div>
                }
            </div>
            <div className="mt-4 container-fluid">
                <h3 className="text-center">
                    Cotizaciones Nueva
                </h3>
                <hr/>
                {body1 === 1 ?
                    <div className="container" ref={printContainer} 
                        style={{padding: '1em 2em 2em 2em'}}>
                        {/*ReactHtmlParser(body)*/}
                        <div>
                            <img src={Img1} className='img-fluid' />
                        </div>
                        <div style={{maxHeight: '90em'}}>
                            <ReactQuill
                                theme="bubble"
                                value={body}
                                style={{height: '90em'}}
                            />
                        </div>
                        <div className="text-center">
                            <img src={Img2} className='img-fluid' />
                        </div>
                    </div>
                :
                    <div>
                        <div className="mb-4 px-4">
                            <Row>
                                <Col md={5} xs={12}>
                                    <FormControl
                                        placeholder="Nombre de la cotizacion" aria-label="Nombre de la cotizacion"
                                        aria-describedby="basic-addon1"
                                        type="text" onChange={handleInputChangeData}
                                        id="name" name="name"
                                    />
                                </Col>
                                <Col md={7} xs={12}>
                                    <FormControl as="textarea" aria-label="With textarea" placeholder="Descripcion"
                                        onChange={handleInputChangeData} id="observations" name="observations"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="container">
                            <ReactQuill
                                className="bg-light text-dark area_acta"
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                placeholder="Escribe aquí..."
                                value={body}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                }
            </div>
            <br/>
        </div>
    );
}

export default Cotizacion_Nueva;