import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, ListGroup ,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_COMPARATIVO,DEDALO_COMBO_VENDORS_FULL,
    DEDALO_REQUISITIONS_COMBO,DEDALO_COMPARATIVO_BY_ID
} from "../../../../assets/APIAdresses";
import {message_responses} from "../../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../../utils/utils";
import {useUserSession} from "../../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../../utils/routes';
import {Mensaje_Error_Comparativo} from '../../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import Img1 from '../../imagenes_reportes/logo_geo.png';
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";

const Cuadro_Comparativo_Edit = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [aumento, setAumento] = useState(0);
    const [proveedorselect1, setProveedorSelect1] = useState(0);
    const [proveedorselect2, setProveedorSelect2] = useState(0);
    const [proveedorselect3, setProveedorSelect3] = useState(0);
    const [vendor, setVendor] = useState(0);
    const [requi, setRequi] = useState(0);
    const [borraritem, setBorrarItem] = useState(false);

    let {IdElementEdit} = useParams();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [categor1, setCategor1] = useState({
        datos: [],
    });

    const [totalproveedor, setTotalProveedor] = useState({
            prov1: '',
            prov2: '',
            prov3: '',
    });

    const [dataitems, setDtaItems] = useState([]);

    const [dats, setDat] = useState({
        fecha: '',
        num_requisicion: '',
        requisicion_id: '',
        descripcion: '',
        proveedor1: '',
        proveedor2: '',
        proveedor3: '',
        year1: '',
        year2: '',
        year3: '',
        forma1: '',
        forma2: '',
        forma3: '',
        proveedor_final: '',
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            enterprice_id: data_emp2.IdEmp,
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_VENDORS_FULL,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              }
          });
      
        return info1;
    };

    const peticionGetCategor1=async()=>{
        const params_data = {
            constructions_id: data_emp.Id_Project_Conta,
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_REQUISITIONS_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor1({
                    ...categor1,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
              }
          });
      
        return info1;
    };

    const peticionGetRequisicionById=async()=>{
        let useredit = parseInt(IdElementEdit);

        if(useredit === undefined){
            let ry = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_COMPARATIVO_BY_ID+'&id='+useredit,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    let d = [];
                    //console.log(datanotice);
                    setDat({
                        ...dats,
                        ['fecha']: datanotice.date,
                        ['num_requisicion']: datanotice.number_requisition,
                        ['requisicion_id']: datanotice.requisitions_id,
                        ['descripcion']: datanotice.desription,
                        ['proveedor1']: datanotice.crm_vendors_id1,
                        ['proveedor2']: datanotice.crm_vendors_id2,
                        ['proveedor3']: datanotice.crm_vendors_id3,
                        ['year1']: datanotice.date_delivery1,
                        ['year2']: datanotice.date_delivery2,
                        ['year3']: datanotice.date_delivery3,
                        ['forma1']: datanotice.way_to_pay1,
                        ['forma2']: datanotice.way_to_pay2,
                        ['forma3']: datanotice.way_to_pay3,
                        ['proveedor_final']: datanotice.chosen_provider,
                    });

                    datanotice.body.map((item, index) => {
                        d.push({
                            num_item_: (index + 1),
                            item_: item.item,
                            prov1_cant_: parseInt(item.amount1),
                            prov1_vu_: parseInt(item.unit_value1), 
                            prov1_vt_: parseInt(item.amount1) * parseInt(item.unit_value1),
                            prov2_cant_: parseInt(item.amount2),
                            prov2_vu_: parseInt(item.unit_value2),
                            prov2_vt_: parseInt(item.amount2) * parseInt(item.unit_value2),
                            prov3_cant_: parseInt(item.amount3),
                            prov3_vu_: parseInt(item.unit_value3),
                            prov3_vt_: parseInt(item.amount3) * parseInt(item.unit_value3),
                        });
                    });
                    setDtaItems(d);

                    setTotalProveedor({
                        ...totalproveedor,
                        ['prov1']: d.map(item => item.prov1_vt_).reduce((prev, curr) => prev + curr, 0),
                        ['prov2']: d.map(item => item.prov2_vt_).reduce((prev, curr) => prev + curr, 0),
                        ['prov3']: d.map(item => item.prov3_vt_).reduce((prev, curr) => prev + curr, 0),
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                  alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                      ? error.response.data.data.error_message
                      : message_responses[error.response.data.message]
                  );
                } else {
                  alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
            return info1;
        }
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetCategor1();
        peticionGetRequisicionById();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const agregarFila = () =>{
        setAumento(aumento+1);
        setDtaItems([
            ...dataitems,
            {
                num_item_: '',item_: '',prov1_cant_: '',prov1_vu_: '',
                prov1_vt_: '',prov2_cant_: '',prov2_vu_: '',prov2_vt_: '',
                prov3_cant_: '',prov3_vu_: '',prov3_vt_: '',
            },
        ]);
    };

    const eliminarFila = (i) => {
        const list = [...dataitems];
        list.splice(i, 1);
        setDtaItems(list);
    };

    const handleInputChangeObs1 = (e,index) => {
        let valor1 = document.getElementById('prov1_cant_'+index).value;
        let valor2 = document.getElementById('prov1_vu_'+index).value;
        let valor3 = document.getElementById('prov2_cant_'+index).value;
        let valor4 = document.getElementById('prov2_vu_'+index).value;
        let valor5 = document.getElementById('prov3_cant_'+index).value;
        let valor6 = document.getElementById('prov3_vu_'+index).value;
        
        const { name, value } = e.target;
        let list = [...dataitems];
        list[index][name] = value;
        dataitems[index]['prov1_vt_'] = parseInt(valor1 === '' ? 0 : valor1) * parseInt(valor2 === '' ? 0 : valor2);
        dataitems[index]['prov2_vt_'] = parseInt(valor3 === '' ? 0 : valor3) * parseInt(valor4 === '' ? 0 : valor4);
        dataitems[index]['prov3_vt_'] = parseInt(valor5 === '' ? 0 : valor5) * parseInt(valor6 === '' ? 0 : valor6);
        
        const sumall1 = dataitems.map(item => item.prov1_vt_).reduce((prev, curr) => prev + curr, 0);
        const sumall2 = dataitems.map(item => item.prov2_vt_).reduce((prev, curr) => prev + curr, 0);
        const sumall3 = dataitems.map(item => item.prov3_vt_).reduce((prev, curr) => prev + curr, 0);

        setDtaItems(list);
        setTotalProveedor({
            ...totalproveedor,
            ['prov1']: sumall1,
            ['prov2']: sumall2,
            ['prov3']: sumall3,
        });
    };

    const handleInputChangeProveedorSelect1 = (e) => {
        setProveedorSelect1(e.target.value);
    };

    const handleInputChangeRequi = (e) => {
        setRequi(e.target.value);
    };

    const handleInputChangeProveedorSelect2 = (e) => {
        setProveedorSelect2(e.target.value);
    };

    const handleInputChangeProveedorSelect3 = (e) => {
        setProveedorSelect3(e.target.value);
    };

    const handleInputChangeVendor = (e) => {
        setVendor(e.target.value);
    };

    const handleInputChangeDelete = (e) => {
        setBorrarItem(e.target.checked);
    };

    const printContainer = useRef(null);

    const createPDF = async () => {
        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter",
                orientation: 'l'
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: "./pdf/generate.pdf"
        });
    };

    let dia = new Date().getDate();
    let mes = new Date().getMonth()+1;
    let year = new Date().getFullYear();

    let data_nit1 = categor.datos.filter(x1 => x1.id === parseInt(proveedorselect1));
    let data_nit2 = categor.datos.filter(x2 => x2.id === parseInt(proveedorselect2));
    let data_nit3 = categor.datos.filter(x3 => x3.id === parseInt(proveedorselect3));
    let data_nit4 = categor.datos.filter(x4 => x4.name === vendor);

    let proveedor_select = [
        data_nit1[0] === undefined ? '' : data_nit1[0].name ,
        data_nit2[0] === undefined ? '' : data_nit2[0].name ,
        data_nit3[0] === undefined ? '' : data_nit3[0].name,
    ];
    
    let item = [];
    let cnt_1 = [];
    let vu_1 = [];
    let cnt_2 = [];
    let vu_2 = [];
    let cnt_3 = [];
    let vu_3 = [];

    for(let i=0;i < dataitems.length;i++){
        item.push(dataitems[i].item_,);
        cnt_1.push(dataitems[i].prov1_cant_,);
        vu_1.push(dataitems[i].prov1_vu_,);
        cnt_2.push(dataitems[i].prov2_cant_,);
        vu_2.push(dataitems[i].prov2_vu_,);
        cnt_3.push(dataitems[i].prov3_cant_,);
        vu_3.push(dataitems[i].prov3_vu_,);
    };

    let prov1 = categor.datos.filter(x => x.id === parseInt(dats.proveedor1));
    let prov2 = categor.datos.filter(x => x.id === parseInt(dats.proveedor2));
    let prov3 = categor.datos.filter(x => x.id === parseInt(dats.proveedor3));

    const handleCloseSave = (e) => {
        let valor1 = parseInt(IdElementEdit);
        let valor2 = 0;
        
        const formattedData = {
            enterprice_id: data_emp2.IdEmp,
            users_id: userId,
            date: `${year}/${mes}/${dia}`,
            requisitions_id: parseInt(requi === 0 ? dats.requisicion_id : requi),
            desription: dats.descripcion,
            crm_vendors_id1: proveedorselect1 === 0 ? dats.proveedor1 : parseInt(proveedorselect1),
            crm_vendors_id2: proveedorselect2 === 0 ? dats.proveedor2 : parseInt(proveedorselect2),
            crm_vendors_id3: proveedorselect3 === 0 ? dats.proveedor3 : parseInt(proveedorselect3),
            date_delivery1: dats.year1,
            date_delivery2: dats.year2,
            date_delivery3: dats.year3,
            way_to_pay1: dats.forma1,
            way_to_pay2: dats.forma2,
            way_to_pay3: dats.forma3,
            chosen_provider: vendor === '' ? dats.proveedor_final : data_nit4[0] === undefined ? '' : data_nit4[0].name,
            item: item,
            amount1: cnt_1,
            unit_value1: vu_1,
            amount2: cnt_2,
            unit_value2: vu_2,
            amount3: cnt_3,
            unit_value3: vu_3,
        };
        //console.log(formattedData);
        if(valor2 === ''){
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible guardar el anticipo', "info");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_COMPARATIVO+'&id='+valor1,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire(
                        "!!! Atencion !!!",
                        "se ha editado el formato exitosamente",
                        "success"
                    );
                    peticionGetRequisicionById();
                }else {
                    Mensaje_Error_Comparativo(response.data.msg.error)
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };
    
    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}
                            onClick={handleCloseSave}
                        >
                            Guardar Cuadro comparativo
                        </Button>
                    </div>
                    <div>
                        <Button className="bg-primary me-2" size="sm"
                            onClick={agregarFila} title="Agregar Item"
                        >
                            Agregar Item
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" 
                            onClick={() => createPDF()} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid">
                <Form.Check 
                    type="switch" onChange={handleInputChangeDelete}
                    id="custom-switch"
                    label="Eliminar item"
                />
            </div>
            <div className="container container-fluid" ref={printContainer} style={{padding: '1em 1em 1em 1em'}}>
                <Row className="border border-dark mx-1"> {/*  cabecera del formato */}
                    <Col md={2} className="text-center py-1 border-end border-dark">
                        <img src={Img1} className='img-fluid' width={230}/>
                    </Col>
                    <Col md={8} className="d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <h3>
                                <b>FORMATO CUADRO COMPARATIVO</b>
                            </h3>
                        </div>
                    </Col>
                    <Col md={2} className="text-center py-1 border-start border-dark">
                        <div>
                            Codigo: FO-CP-06-01
                        </div>
                        <div>
                            Fecha: 20/01/2014
                        </div>
                        <div>
                            Pagina 1 de 1
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row className="border border-dark mx-1">
                    <Col md={2} className="border-end border-dark d-flex justify-content-center align-items-center ">
                        <b>fecha de elaboracion</b>
                    </Col>
                    <Col md={2} className="border-end border-dark">
                        <FormControl
                            aria-label="Nombre de la cotizacion" value={`${year}/${mes}/${dia}`}
                            aria-describedby="basic-addon1" type="text" id="fecha" name="fecha"
                            className="border-0 text-center" onChange={handleInputChangeObs}
                        />
                    </Col>
                    <Col md={2} className="border-end border-dark d-flex justify-content-center align-items-center ">
                        <b>No. requisicion</b>
                    </Col>
                    <Col md={1} className="border-end border-dark">
                        <Form.Select aria-label="Default select example"
                            id="select_vendor" name="select_vendor" className="border-0"
                            onChange={handleInputChangeRequi}
                        >
                            <option value="">{dats.num_requisicion}</option>
                            {categor1.datos.length === 0 ?
                                ''
                            :
                                categor1.datos.map(q => (
                                    <option value={q.id}>{q.number_requisition}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col md={2} className="border-end border-dark d-flex justify-content-center align-items-center ">
                        <b>descripcion</b>
                    </Col>
                    <Col md={3}>
                        <FormControl
                            aria-label="Nombre de la cotizacion" value={dats.descripcion}
                            aria-describedby="basic-addon1" type="text" id="descripcion" name="descripcion"
                            className="border-0 text-center" onChange={handleInputChangeObs}
                        />
                    </Col>
                </Row>
                <br/>
                <div className="border border-dark">
                    <Table responsive bordered>
                        <thead>
                            <tr>
                                {borraritem === true ?
                                    <th rowSpan={2}></th>
                                :
                                    ''
                                }
                                <th rowSpan={2}>No.</th>
                                <th rowSpan={2} className='text-center'>ITEM</th>
                                <th colSpan={3}>
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <b>proveedor 1</b>
                                        </div>
                                        <div>
                                            <Form.Select aria-label="Default select example"
                                                style={{width: '13rem',fontSize: '0.8em'}}
                                                id="select_vendor" name="select_vendor" className="border-0"
                                                onChange={handleInputChangeProveedorSelect1}
                                            >
                                                <option value="">{prov1[0] === undefined ? '' : prov1[0].name}</option>
                                                {categor.datos.length === 0 ?
                                                    ''
                                                :
                                                    categor.datos.map(q => (
                                                        <option value={q.id}>{q.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </div>
                                    </div>
                                </th>
                                <th colSpan={3}>
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <b>proveedor 2</b>
                                        </div>
                                        <div>
                                            <Form.Select aria-label="Default select example" style={{width: '13rem',fontSize: '0.8em'}}
                                                id="select_vendor" name="select_vendor" className="border-0"
                                                onChange={handleInputChangeProveedorSelect2}
                                            >
                                                <option value="">{prov2[0] === undefined ? '' : prov2[0].name}</option>
                                                {categor.datos.length === 0 ?
                                                    ''
                                                :
                                                    categor.datos.map(q => (
                                                        <option value={q.id}>{q.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </div>
                                    </div>
                                </th>
                                <th colSpan={3}>
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <b>proveedor 3</b>
                                        </div>
                                        <div>
                                            <Form.Select aria-label="Default select example" style={{width: '13rem',fontSize: '0.8em'}}
                                                id="select_vendor" name="select_vendor" className="border-0"
                                                onChange={handleInputChangeProveedorSelect3}
                                            >
                                                <option value="">{prov3[0] === undefined ? '' : prov3[0].name}</option>
                                                {categor.datos.length === 0 ?
                                                    ''
                                                :
                                                    categor.datos.map(q => (
                                                        <option value={q.id}>{q.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr className="text-center">
                                <th>cantidad</th>
                                <th>valor unitario</th>
                                <th>valor total</th>
                                <th>cantidad</th>
                                <th>valor unitario</th>
                                <th>valor total</th>
                                <th>cantidad</th>
                                <th>valor unitario</th>
                                <th>valor total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {borraritem === true ?
                                dataitems.map((x,index) => (
                                    <tr>
                                        <td>
                                            <RiIcons.RiDeleteBin6Fill onClick={()=>eliminarFila(index)} color='red'
                                                className="cursor-pointer"
                                            />
                                        </td>
                                        <td style={{width: '3em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.num_item_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="num_item_" name="num_item_"
                                                className="border-0 px-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{width: '14em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.item_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="item_" name="item_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov1_cant_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov1_cant_"+index} name="prov1_cant_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov1_vu_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov1_vu_"+index} name="prov1_vu_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{background: '#e9ecef',width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" disabled style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="prov1_vt_" name="prov1_vt_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                                value={formatterPeso.format(x.prov1_vt_)}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov2_cant_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov2_cant_"+index} name="prov2_cant_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov2_vu_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov2_vu_"+index} name="prov2_vu_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{background: '#e9ecef',width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" disabled style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="prov2_vt_" name="prov2_vt_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                                value={formatterPeso.format(x.prov2_vt_)}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov3_cant_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov3_cant_"+index} name="prov3_cant_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{width: '8em'}}>
                                            <FormControl 
                                                aria-label="Nombre de la cotizacion" value={x.prov3_vu_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov3_vu_"+index} name="prov3_vu_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{background: '#e9ecef',width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" disabled style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="prov3_vt_" name="prov3_vt_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                                value={formatterPeso.format(x.prov3_vt_)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            :
                                dataitems.map((x,index) => (
                                    <tr>
                                        <td style={{width: '3em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.num_item_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="num_item_" name="num_item_"
                                                className="border-0 px-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{width: '14em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.item_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="item_" name="item_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov1_cant_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov1_cant_"+index} name="prov1_cant_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov1_vu_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov1_vu_"+index} name="prov1_vu_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{background: '#e9ecef',width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" disabled style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="prov1_vt_" name="prov1_vt_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                                value={formatterPeso.format(x.prov1_vt_)}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov2_cant_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov2_cant_"+index} name="prov2_cant_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov2_vu_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov2_vu_"+index} name="prov2_vu_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{background: '#e9ecef',width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" disabled style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="prov2_vt_" name="prov2_vt_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                                value={formatterPeso.format(x.prov2_vt_)}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" value={x.prov3_cant_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov3_cant_"+index} name="prov3_cant_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{width: '8em'}}>
                                            <FormControl 
                                                aria-label="Nombre de la cotizacion" value={x.prov3_vu_} style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id={"prov3_vu_"+index} name="prov3_vu_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                            />
                                        </td>
                                        <td style={{background: '#e9ecef',width: '8em'}}>
                                            <FormControl
                                                aria-label="Nombre de la cotizacion" disabled style={{fontSize: '0.8em'}}
                                                aria-describedby="basic-addon1" type="text" id="prov3_vt_" name="prov3_vt_"
                                                className="border-0 text-center" onChange={(e)=>handleInputChangeObs1(e,index)}
                                                value={formatterPeso.format(x.prov3_vt_)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={2}></td>
                                <td colSpan={2}>total</td>
                                <td className="text-center">
                                    {totalproveedor.prov1 === '' ? '-' : formatterPeso.format(totalproveedor.prov1)}
                                </td>
                                <td colSpan={2}>total</td>
                                <td className="text-center">
                                    {totalproveedor.prov2 === '' ? '-' : formatterPeso.format(totalproveedor.prov2)}
                                </td>
                                <td colSpan={2}>total</td>
                                <td className="text-center">
                                    {totalproveedor.prov3 === '' ? '-' : formatterPeso.format(totalproveedor.prov3)}
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
                <Row className="border border-dark mx-1">
                    <Col md={3} className="border-end border-dark d-flex justify-items-center align-items-center">
                        <b>FECHA DE ENTREGA</b>
                    </Col>
                    <Col md={3} className="border-end border-dark">
                        <FormControl
                            aria-label="Nombre de la cotizacion" value={dats.year1}
                            aria-describedby="basic-addon1" type="text" id="year1" name="year1"
                            className="border-0 text-center" onChange={handleInputChangeObs}
                        />
                    </Col>
                    <Col md={3} className="border-end border-dark">
                        <FormControl
                            aria-label="Nombre de la cotizacion" value={dats.year2}
                            aria-describedby="basic-addon1" type="text" id="year2" name="year2"
                            className="border-0 text-center" onChange={handleInputChangeObs}
                        />
                    </Col>
                    <Col md={3}>
                        <FormControl
                            aria-label="Nombre de la cotizacion" value={dats.year3}
                            aria-describedby="basic-addon1" type="text" id="year3" name="year3"
                            className="border-0 text-center" onChange={handleInputChangeObs}
                        />
                    </Col>
                </Row>
                <Row className="border border-dark mx-1">
                    <Col md={3} className="border-end border-dark d-flex justify-items-center align-items-center">
                        <b>FORMA DE PAGO</b>
                    </Col>
                    <Col md={3} className="border-end border-dark">
                        <FormControl
                            aria-label="Nombre de la cotizacion" value={dats.forma1}
                            aria-describedby="basic-addon1" type="text" id="forma1" name="forma1"
                            className="border-0 text-center" onChange={handleInputChangeObs}
                        />
                    </Col>
                    <Col md={3} className="border-end border-dark">
                        <FormControl
                            aria-label="Nombre de la cotizacion" value={dats.forma2}
                            aria-describedby="basic-addon1" type="text" id="forma2" name="forma2"
                            className="border-0 text-center" onChange={handleInputChangeObs}
                        />
                    </Col>
                    <Col md={3}>
                        <FormControl
                            aria-label="Nombre de la cotizacion" value={dats.forma3}
                            aria-describedby="basic-addon1" type="text" id="forma3" name="forma3"
                            className="border-0 text-center" onChange={handleInputChangeObs}
                        />
                    </Col>
                </Row>
                <br/>
                <Row className="mx-1">
                    <Col md={3} className='border border-dark d-flex justify-items-center align-items-center'>
                        <b>PROVEEDOR ELEGIDO</b>
                    </Col>
                    <Col md={3} className='border-top border-bottom border-end border-dark'>
                        <Form.Select aria-label="Default select example"
                            id="select_vendor" name="select_vendor" className="border-0"
                            onChange={handleInputChangeVendor}
                        >
                            <option value="">{dats.proveedor_final}</option>
                            {proveedor_select.length === 0 ?
                                ''
                            :
                                proveedor_select.map(q => (
                                    <option value={q}>{q}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col md={3}>
                        <div className="border border-dark mx-auto" style={{width: '17em',height: '2.5em'}}></div>
                    </Col>
                    <Col md={3}>
                        <div className="border border-dark mx-auto" style={{width: '17em',height: '2.5em'}}></div>
                    </Col>
                </Row>
                <Row className="mx-1">
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                    <Col md={3}>
                        <div className="border border-dark mx-auto text-center" style={{width: '17em'}}>
                            <b style={{fontSize: '0.7em'}}>
                                FIRMA RESPONSABLE DE APROBAR LA COMPRA
                            </b>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="border border-dark mx-auto text-center" style={{width: '17em'}}>
                            <b style={{fontSize: '0.7em'}}>
                                FIRMA COORDINADOR DE COMPRAS
                            </b>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Cuadro_Comparativo_Edit;