import React, { useState, Fragment, useEffect } from "react";
import * as Chartjs from 'react-chartjs-2'
import { 
    Modal, Button,
  } from 'react-bootstrap';
import {
    DEDALO_ALL_WORKCUT
} from "../../assets/APIAdresses";
import Swal from "sweetalert2";
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import { useUserSession } from "../../hooks";

function GraficaTortaHomi() {
    const { userToken } = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const id = parseInt(datahomi.idProject);

    const [datosinf, setDatosInf] = useState({
        titulo: '',
        porcentaje: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const peticionGetCategor = async () => {
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'percentage_advance',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_WORKCUT+'?page=1&rows=1000&order=&inorder=asc&constructions_id='+id+'&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_WORKCUT,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    if (datanotice.length === 0) {
                        setCategor({
                            ...categor,
                            ['datos']: [],
                        });
                    } else {
                        if (datanotice.records.length > 0) {
                            setCategor({
                                ...categor,
                                ['datos']: datanotice.records,
                            });
                        }
                    }
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                }
            });

        return info1;
    }

    useEffect(() => {
        peticionGetCategor();
    }, []);

    let datos = [];
    let Nomcortes = [];
    if (categor.datos.length === 0) {
        datos.push(parseInt(0));
        Nomcortes.push('Corte 0');
    } else {
        categor.datos.map(item => (
            datos.push(item.percentage_advance),
            Nomcortes.push('Corte ' + item.workcutnumber)
        ));
    }

    var colores = [
        "#F5B041", "#2ECC71", "#2874A6", "#BA4A00",
        "#FF0000", "#76D7C4", "#F7DC6F", "#922B21",
        "#717D7E", "#154360", "#566573", "#78281F",
        "#ABB2B9", "#D98880", "#7FB3D5", "#ABB2B9"
    ];

    var data = {
        labels: Nomcortes,
        datasets: [
            {
                data: datos,
                backgroundColor: colores
            }
        ]
    };

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setDatosInf({
            ...datosinf,
            ['titulo']: '',
            ['porcentaje']: '',
        });
        setShow(false)
    };

    const handleShow = (e) => {
        let info = e.chart.tooltip.dataPoints[0];

        setDatosInf({
            ...datosinf,
            ['titulo']: info.label,
            ['porcentaje']: info.parsed,
        });

        setShow(true);
    };

    let cortesinf = categor.datos === undefined ? [] : categor.datos;
    let filtro = cortesinf.filter(x => parseInt(x.workcutnumber) === parseInt(datosinf.titulo.split(' ')[1]));
    let filtrof = filtro[0] === undefined ? [] : filtro[0];

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        //minimumFractionDigits: 0
    });

    //console.log(filtrof);

    return (
        <div>
            <div style={{ margin: "auto", width: "40%" }}>
                <Chartjs.Pie
                    height={120}
                    data={data}
                    options={
                        {
                            data: data,
                            options: {
                                responsive: true,
                            },
                            onClick: handleShow
                        }
                    }
                />
            </div>
            
            {/* MODAL INFO CORTE */}
            <Modal show={show} onHide={handleClose} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Inf. del corte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="mb-0">
                            <span><b>Nombre:</b></span> <span>{datosinf.titulo}</span>
                        </p>
                        <p className="mb-0">
                            <span><b>Porcentaje:</b></span> <span>{datosinf.porcentaje+'%'}</span>
                        </p>
                        <p className="mb-0">
                            <span><b>presupuesto ejecutado:</b></span> <span>{formatterPeso.format(parseFloat(filtrof.executed))}</span>
                        </p>
                        <p className="mb-0">
                            <span><b>Observaciones:</b></span> <span>{filtrof.observations}</span>
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default GraficaTortaHomi;