import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button,Row, Col,Form, Table
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_SALIDA_EQUIPO,DEDALO_SALIDA_EQUIPO_BY_ID
} from "../../../assets/APIAdresses";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import { DatePicker } from "@material-ui/pickers";
import {Mensaje_Error_Salida_Equipos} from '../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import html2PDF from "jspdf-html2canvas";
import Img1 from '../imagenes_reportes/logo2.png';
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";

const Formato_Salida_Edit = () =>{
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const [clearedDate, handleClearedDateChange] = useState(null);
    const [selectedDate2, handleDateChange2] = useState('');
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [aumento, setAumento] = useState(0);
    const [vistapdf, serVistaPdf] = useState(false);
    
    let {IdElementEdit} = useParams();

    const [rows, setRows] = useState([]);

    //const [rows, setRows] = useState([
    //    { id: 0, cant: "", nom_eq: '', marca: "", modelo: '', serie: '', cod_interno: '' },
    //]);

    const [dats, setDat] = useState({
        cargo1: '',
        dependencia: '',
        proyecto: '',
        cargo2: '',
        obs2: '',
        obs1: '',
        fecha_salida: '',
        responsable: '',
        responsable_projecto: '',
        formato_num: '',
    });

    const peticionGetActasById=async()=>{
        let idacta = parseInt(IdElementEdit);
        //console.log(idacta);
        if(idacta === undefined){
            let re = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_SALIDA_EQUIPO_BY_ID+'&id='+idacta,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    let d = [];
                    let fecha_s = datanotice.out_date;
                    //console.log(datanotice);
                    setDat({
                        ...dats,
                        ['cargo1']: datanotice.position,
                        ['dependencia']: datanotice.dependence,
                        ['proyecto']: datanotice.project,
                        ['cargo2']: datanotice.position_project,
                        ['obs2']: datanotice.observation,
                        ['obs1']: datanotice.authorized,
                        ['fecha_salida']: datanotice.out_date,
                        ['responsable']: datanotice.responsible,
                        ['responsable_projecto']: datanotice.responsible_project,
                        ['formato_num']: datanotice.out_number,
                    });

                    datanotice.equipments.map((item, index) => {
                        d.push({
                            id: item.id,
                            cant: item.amount,
                            nom_eq: item.equipment_name,
                            marca: item.brand,
                            modelo: item.model,
                            serie: item.series,
                            cod_interno: item.internal_code,
                        });
                    });
                    setRows(d);
                } else {
                    setDat({
                        ...dats,
                        ['cargo1']: '',
                        ['dependencia']: '',
                        ['proyecto']: '',
                        ['cargo2']: '',
                        ['obs2']: '',
                        ['obs1']: '',
                        ['fecha_salida']: '',
                        ['responsable']: '',
                        ['responsable_projecto']: '',
                        ['formato_num']: '',
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message 
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos by_id");
                }
            });
        
            return info1;
        }
    };

    useEffect(() => {
        peticionGetActasById();
    }, []);
    
    const printContainer = useRef(null);

    const createPDF = async () => {
        let year = new Date().getFullYear();
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;

        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter",
                //orientation: 'l'
            },
            margin: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `formato_salida_${dats.obs1}.pdf`
        });
    };

    const handleInputChangeText = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };
    
    const handleInputChange = (e, index) =>{      
        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        setRows(list);
    };

    const agregarFila = () =>{
        setAumento(aumento+1);
        setRows([
            ...rows,
            {
                id: rows.length + 0, cant: "", nom_eq: '', 
                marca: "", modelo: '', serie: '', cod_interno: ''
            },
        ]);
    };

    const eliminarFila = (i) => {
        const list = [...rows];
        list.splice(i, 1);
        setRows(list);
    };

    let cant = [];
    let nom_eq = [];
    let marca = [];
    let modelo = [];
    let serie = [];
    let cod_interno = [];

    rows.map((row, index) => {
        cant.push(row.cant);
        nom_eq.push(row.nom_eq);
        marca.push(row.marca);
        modelo.push(row.modelo);
        serie.push(row.serie);
        cod_interno.push(row.cod_interno);
    });

    const handleChangePDF = (e) =>{
        serVistaPdf(e.target.checked);
    };

    const handleCloseSave = (e) => {
        let valor1 = dats.document_number;
        let valor2 = dats.valor_letras;

        const formattedData = {
            users_id: userId,
            enterprice_id: data_emp.IdEmp,
            out_date: clearedDate.toLocaleDateString('es-Co').split('/').reverse().join('-'),
            authorized: dats.obs1,
            dependence: dats.dependencia,
            responsible: dats.responsable,
            position: dats.cargo1,
            project: dats.proyecto,
            responsible_project: dats.responsable_projecto,
            position_project: dats.cargo2,
            amount: cant,
            equipment_name: nom_eq,
            brand: marca,
            model: modelo,
            series: serie,
            internal_code: cod_interno,
            observation: dats.obs2,
        };
        //console.log(formattedData);
        if(valor2 === ''){
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible guardar la legalizacion', "info");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_SALIDA_EQUIPO+'&id='+parseInt(IdElementEdit),
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire("Se ha editado el formato exitósamente", "", "success");
                    peticionGetActasById();
                }else {
                    Mensaje_Error_Salida_Equipos(response.data.msg.error)
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    let fecha_sis = new Date(dats.fecha_salida === '' ? new Date() : dats.fecha_salida);
    let fecha_correcta = clearedDate === null ? fecha_sis.setDate(fecha_sis.getDate() + 1) : clearedDate;

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100 botonesformatos">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2 botongenerar" size="sm" 
                            style={{backgroundColor: "#02B371"}} onClick={handleCloseSave}
                        >
                            Guardar Formato salida
                        </Button>
                    </div>
                    {vistapdf === true ?
                        <div>
                            <Button className="btn btn-success me-2 botongenerar" size="sm" 
                                onClick={() => createPDF()} title="Regresar" style={{backgroundColor: "#02B371"}}
                            >
                                descargar PDF
                            </Button>
                        </div>
                    :
                        ''
                    }
                    <div>
                        <Button className="bg-primary me-2 agregaritem" size="sm"
                            onClick={agregarFila} title="Agregar Item"
                        >
                            Agregar Item
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid">
                <Form>
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label="Vista de pdf"
                        onChange={handleChangePDF}
                    />
                </Form>
            </div>
            <br/>
            <div className="container container-fluid mb-4" ref={printContainer} style={{padding: '1em 1em 1em 1em'}}>
                <Row className="border border-dark mx-1"> {/*  cabecera del formato */}
                    <Col md={2} className="py-1 border-end border-dark d-flex justify-content-center align-items-center">
                        <img src={Img1} className='img-fluid' width={230}/>
                    </Col>
                    <Col md={8} className="d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <h3>
                                <b>FORMATO SALIDA DE EQUIPOS Y HERRAMIENTAS</b>
                            </h3>
                        </div>
                    </Col>
                    <Col md={2} className="text-center py-1 border-start border-dark">
                        <div>
                            Codigo: FO-GA-10-01
                        </div>
                        <div>
                            Fecha: 20/05/2014
                        </div>
                        <div>
                            Pagina 1 de 1
                        </div>
                    </Col>
                </Row>
                <br/>
                <div className="d-flex px-1">
                    <div className="border border-dark d-flex">
                        <div className="border-dark border-end px-2">
                            SALIDA No.
                        </div>
                        <div className="px-2 text-center" style={{width: '4em'}}>{dats.formato_num}</div>
                    </div>
                    <div className="ms-auto border border-dark d-flex ">
                        <div className="border-dark border-end px-2">
                            FECHA DE SALIDA No.
                        </div>
                        <div style={{width: '7.5em'}}>
                            <DatePicker
                                clearable autoOk format="yyyy/mm/dd"
                                value={new Date(fecha_correcta).toLocaleDateString('es-Co').split('/').reverse().join('/')}
                                className="px-2"
                                onChange={handleClearedDateChange}
                            />
                        </div>
                    </div>
                </div>
                <br/>
                <div className="mx-1"> {/* seccion nombre-empresa-guia */}
                    <div className="text-center">
                        Con la presente autorizamos a:
                    </div>
                    <div className="border border-dark">
                        <div className="border-bottom border-dark">
                            <Form.Control
                                style={{border: 'none',textAlign: 'center'}} value={dats.obs1}
                                aria-label="Username" id="obs1" name="obs1"
                                aria-describedby="basic-addon1" onChange={handleInputChangeText}
                            />
                        </div>
                        <div className="text-center" style={{background: '#AAB7B8'}}>
                            <b>Nombre / Empresa / No. Guia</b>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="mx-1">{/* seccion de herramientas */}
                    <div className="text-center mb-2">
                        Para retirar de geoestructuras ltda los siguientes elementos
                    </div>
                    <div> 
                        <Table responsive bordered id="tabla_req" name="tabla_req">
                            <thead className="text-center">
                                <tr>
                                    <th>Cant</th>
                                    <th>nombre del equipo y/o herramienta</th>
                                    <th>marca</th>
                                    <th>modelo</th>
                                    <th>serie</th>
                                    <th>cod. interno</th>
                                    {vistapdf === true ?
                                        ''
                                    :
                                        <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {rows === undefined ?
                                    ''
                                :
                                    rows.map((row, i) =>(
                                        <tr key={i} className="data_rows">
                                            <td style={{width: '5em'}}>
                                                <Form.Control
                                                    type="text" name="cant" className="border-0" size="sm"
                                                    value={row.cant} onChange={(e) => handleInputChange(e, i)}
                                                    style={{marginRight: '0', textAlign: 'center'}}
                                                />
                                            </td>
                                            <td style={{width: '30em'}}>
                                                <Form.Control
                                                    type="text" name="nom_eq" className="border-0" size="sm"
                                                    value={row.nom_eq} onChange={(e) => handleInputChange(e, i)}
                                                    style={{marginRight: '0', textAlign: 'center'}}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text" name="marca" className="border-0" size="sm"
                                                    value={row.marca} onChange={(e) => handleInputChange(e, i)}
                                                    style={{marginRight: '0', textAlign: 'center'}}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text" name="modelo" className="border-0" size="sm"
                                                    value={row.modelo} onChange={(e) => handleInputChange(e, i)}
                                                    style={{marginRight: '0', textAlign: 'center'}}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text" name="serie" className="border-0" size="sm"
                                                    value={row.serie} onChange={(e) => handleInputChange(e, i)}
                                                    style={{marginRight: '0', textAlign: 'center'}}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text" name="cod_interno" className="border-0" size="sm"
                                                    value={row.cod_interno} onChange={(e) => handleInputChange(e, i)}
                                                    style={{marginRight: '0', textAlign: 'center'}}
                                                />
                                            
                                            </td>
                                            {vistapdf === true ?
                                                ''
                                            :
                                                <td className="d-flex justify-content-center align-items-center">
                                                    <div className="text-danger text-center" >
                                                        <AiIcons.AiFillDelete 
                                                            style={{fontSize: '1em',cursor: 'pointer'}}
                                                            onClick={()=>eliminarFila(i)} title='eliminar item'
                                                        />
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <br/>
                <div className="mx-1 mb-3"> {/* seccion origen/ubicacion/herramienta */}
                    <div className="text-center mb-2">
                        Origen / Ubicacion del equip y/o Herramienta
                    </div>
                    <div>
                        <Row className="border-dark border" style={{background: '#AAB7B8'}}>
                            <Col md={3} className="text-center border-dark border-end">
                                <b> Dependencia </b>
                            </Col>
                            <Col md={6} className="text-center border-dark border-end">
                                <b> Nombre del Responsable </b>
                            </Col>
                            <Col md={3} className="text-center">
                                <b> Cargo </b>
                            </Col>
                        </Row>
                        <Row className="border-dark border">
                            <Col md={3} className="border-dark border-end">
                                <Form.Control
                                    style={{textAlign: 'center',border: 'none'}} id="dependencia" name="dependencia"
                                    aria-label="Username" onChange={handleInputChangeText}
                                    aria-describedby="basic-addon1" value={dats.dependencia}
                                />
                            </Col>
                            <Col md={6} className="border-dark border-end">
                                <Form.Control
                                    style={{textAlign: 'center',border: 'none'}} id="responsable" name="responsable"
                                    aria-label="Username" onChange={handleInputChangeText}
                                    aria-describedby="basic-addon1" value={dats.responsable}
                                />
                            </Col>
                            <Col md={3} >
                                <Form.Control
                                    style={{textAlign: 'center',border: 'none'}} id="cargo1" name="cargo1"
                                    aria-label="Username" onChange={handleInputChangeText}
                                    aria-describedby="basic-addon1" value={dats.cargo1}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="mx-1 mb-3"> {/* seccion destino */}
                    <div className="text-center mb-2">
                        Destino
                    </div>
                    <div>
                        <Row className="border-dark border" style={{background: '#AAB7B8'}}>
                            <Col md={3} className="text-center border-dark border-end">
                                <b> Proyecto </b>
                            </Col>
                            <Col md={6} className="text-center border-dark border-end">
                                <b> Responsable del proyecto </b>
                            </Col>
                            <Col md={3} className="text-center">
                                <b> Cargo </b>
                            </Col>
                        </Row>
                        <Row className="border-dark border">
                            <Col md={3} className="border-dark border-end">
                                <Form.Control
                                    style={{textAlign: 'center', border: 'none'}} id="proyecto" name="proyecto"
                                    aria-label="Username" onChange={handleInputChangeText}
                                    aria-describedby="basic-addon1" value={dats.proyecto}
                                />
                            </Col>
                            <Col md={6} className="border-dark border-end">
                                <Form.Control
                                    style={{textAlign: 'center', border: 'none'}} id="responsable_projecto" name="responsable_projecto"
                                    aria-label="Username" onChange={handleInputChangeText}
                                    aria-describedby="basic-addon1" value={dats.responsable_projecto}
                                />
                            </Col>
                            <Col md={3} >
                                <Form.Control
                                    style={{textAlign: 'center', border: 'none'}} id="cargo2" name="cargo2"
                                    aria-label="Username" onChange={handleInputChangeText}
                                    aria-describedby="basic-addon1" value={dats.cargo2}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <br/>
                <div className="mb-3"> {/* seccion de retiro materiales */}
                    <div className="border border-dark" >
                        <div className=" container-fluid border-dark border-bottom" style={{background: '#AAB7B8'}}>
                            <b>Retiro de materiales</b>
                        </div>
                        <div className="container-fluid ">
                            Mediante la firma de este documento, el funcionario abajo mencionado confirma que ha recibido los 
                            equipos descritos en buen estado y acepta la responsabilidad de demostrar el uso apropiado de los mismos 
                            para fines estrictamente laborales. Todos los equipos y/o herramientas son propiedad de GEOESTRUCTURAS LTDA. 
                            El usuario no esta autorizado para instalar Piezas Adicionales o modificaciones sin el visto bueno del 
                            departamento encargado.
                        </div>
                    </div>
                    <br/>
                    <div className="border border-dark" >
                        <div className=" container-fluid border-dark border-bottom" style={{background: '#AAB7B8'}}>
                            <b>Observaciones</b>
                        </div>
                        <div>
                            <Form.Control as="textarea" aria-label="With textarea" className="border-0" 
                                id="obs2" name="obs2" onChange={handleInputChangeText} value={dats.obs2}
                            />
                        </div>
                    </div>
                </div>
                <br/>
                <div className="mb-3"> {/* seccion firmas */}
                    <Row>
                        <Col md={4} className="px-4">
                            <div className="border-dark border">
                                <div style={{height: '2em'}} className="border-bottom border-dark"></div>
                                <div className="border-bottom border-dark px-2" style={{background: '#AAB7B8'}}>
                                    <b> Entregado por </b>
                                </div>
                                <div>
                                    <p className="m-0 px-2"> Nombre </p>
                                    <p className="m-0 px-2"> C.C No. </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className="px-4">
                            <div className="border-dark border">
                                <div style={{height: '2em'}} className="border-bottom border-dark"></div>
                                <div className="border-bottom border-dark px-2" style={{background: '#AAB7B8'}}>
                                    <b> Retirado por </b>
                                </div>
                                <div>
                                    <p className="m-0 px-2"> Nombre </p>
                                    <p className="m-0 px-2"> C.C No. </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} className="px-4">
                            <div className="border-dark border">
                                <div style={{height: '2em'}} className="border-bottom border-dark"></div>
                                <div className="border-bottom border-dark px-2" style={{background: '#AAB7B8'}}>
                                    <b> Recibido por </b>
                                </div>
                                <div>
                                    <p className="m-0 px-2"> Nombre </p>
                                    <p className="m-0 px-2"> C.C No. </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Formato_Salida_Edit;