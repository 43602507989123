import React, {useState, Fragment, useEffect} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Container,Card
} from 'react-bootstrap';
import {
    DEDALO_CONSTRUCCION_BY_ID,
} from "../../../assets/APIAdresses";
import { Routes } from '../../../utils/routes';
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import Swal from "sweetalert2";
import Img1 from '../imagenes_iconos/compras3.png';
import Img2 from '../imagenes_iconos/orden.png';
import * as MdIcons from "react-icons/md";

const Menu_Formatos = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();   
    const {user} = useUserSession();
    

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-center mt-4">
                <div>
                    <h2 className="text-center">Menu Formatos proveedores</h2>
                </div>
            </div>
            <hr/>
            <div>
                <Container>
                    <Row>
                        <Col md={3} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.CUADRO_COMPARATIVO}>
                                    <a style={{cursor: 'pointer',textAlign: 'center'}} title="COMPRAS">
                                        <img src={Img2} width="56%" alt="prueba"/>
                                    </a>
                                </Link>
                                <br/>
                                <b className="mt-4">Cuadro Comparativo</b>
                            </div>
                        </Col>
                        <Col md={3} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.EVALUACION_PROVEEDOR}>
                                    <a style={{cursor: 'pointer',textAlign: 'center'}} title="ORDEN DE COMPRA">
                                        <img src={Img2} width="56%" alt="prueba"/>
                                    </a>
                                </Link>
                                <br/>
                                <b className="mt-4">Evaluacion proveedor</b>
                            </div>
                        </Col>
                        <Col md={3} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.REEVALUACION_PROVEEDOR}>
                                    <a style={{cursor: 'pointer',textAlign: 'center'}} title="ORDEN DE COMPRA">
                                        <img src={Img2} width="56%" alt="prueba"/>
                                    </a>
                                </Link>
                                <br/>
                                <b className="mt-4">Reevaluacion proveedor</b>
                            </div>
                        </Col>
                        <Col md={3} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.SELECT_PROVEEDOR}>
                                    <a style={{cursor: 'pointer',textAlign: 'center'}} title="ORDEN DE COMPRA">
                                        <img src={Img2} width="56%" alt="prueba"/>
                                    </a>
                                </Link>
                                <br/>
                                <b className="mt-4">Seleccion proveedor</b>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Menu_Formatos;