import React, {useState, useEffect} from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { 
    Container,Accordion,InputGroup,FormControl,Modal,Button,
    Row,Col,Card,DropdownButton,Dropdown,
} from 'react-bootstrap';
import {
    DEDALO_CONSTRUCCION_TASK_BY_ID,DEDALO_CONSTRUCCION_SUBTASK_BY_ID,
    DEDALO_ALL_TASK_COMMENTS_RESP,DEDALO_ALL_TASK_COMMENTS,DEDALO_TASK_COMMENTS,
    DEDALO_TASK_COMMENTS_RESP,DEDALO_ALL_SUBTASK_COMMENTS,DEDALO_ALL_CONSTRUCTIONS_SUBTASK,
    DEDALO_SUBTASK_RESP,DEDALO_SUBTASK_COMMENTS,DEDALO_SUBTASK_COMMENTS_RESP,
    DEDALO_TASK_COMMENT_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {Redirect, useParams} from "react-router-dom";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import LogoDedalo from '../../../imagenes/dedalo_logo.png';
import Word from '../../../imagenes/iconos/word.png';
import Excel from '../../../imagenes/iconos/excel.png';
import Powerpoint from '../../../imagenes/iconos/powerpoint.png';
import Publisher from '../../../imagenes/iconos/publisher.png';
import Visio from '../../../imagenes/iconos/visio.png';
import Pdf from '../../../imagenes/iconos/pdf.png';
import WinRar from '../../../imagenes/iconos/winrar.png';
import AutoDesk from '../../../imagenes/iconos/autodesk.png';
import JPG from '../../../imagenes/iconos/iconoJPG.png';
import PNG from '../../../imagenes/iconos/iconoPNG.png';
import * as BiIcons from 'react-icons/bi';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from "react-icons/fa";
import VerticalSubTask from './comentariossubtareas';

const ComentariosTareas = () => {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const {Idtask} = useParams();
    const [rescomment,setResComment] = useState(0);
    const [subtaskcomment,setSubTaskComment] = useState(0);
    const [archivobase64, setArchivoBase64] = useState();

    const [dats, setDat] = useState({
        comentariopri: '',
        comentario_res: '',
        comentario_subres: '',
        subcomentariopri: '',
    });
    
    const [categor, setCategor] = useState({
        datos: [],
    });

    const [categor1, setCategor1] = useState({
        datos: [],
    });

    const [subtaskdata, setSubTaskData] = useState({
        datos: [],
    });

    const [allcommentsubtask, setAllCommentSubTask] = useState({
        datos: [],
    });

    const [infocomentario, setInfoComentario] = useState({
        datos: [],
        files_comments: [],
    });

    const [datsfile, setDatFile] = useState({
        ext: '',
        title_file: '',
    });

    const [task, setTask] = useState({
        datos: [],
    });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (idcomment) => {
        peticionGetCommentTaskById(idcomment);
        setShow(true);
    };

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (idcomment_res) => {
        setResComment(idcomment_res);
        setShow1(true);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        peticionGetInfoCommentTaskById(e);
        setShow2(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetTaskById=async()=>{
        //let info1 = await axios.get(DEDALO_CONSTRUCCION_TASK_BY_ID(Idtask))
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_TASK_BY_ID+'&id='+Idtask,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetAllComments=async()=>{
        const params_data = {
            constructions_id: id,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'percentage_advance',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_TASK_COMMENTS+'?constructions_task_id='+Idtask)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_TASK_COMMENTS+'&constructions_task_id='+Idtask,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                setCategor1({
                ...categor1,
                ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetCommentTaskById=async(commentsId)=>{
        if(commentsId === undefined){
            let oi = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_TASK_COMMENTS_RESP+'?construction_task_comments_id='+commentsId)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_TASK_COMMENTS_RESP+'&construction_task_comments_id='+commentsId,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    setTask({
                    ...task,
                    ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
                } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const peticionGetAllCommentsSubtask=async()=>{
        //let info1 = await axios.get(DEDALO_ALL_SUBTASK_COMMENTS+'?constructions_subtask_id='+subtaskcomment)
        if(subtaskcomment > 0){
            const params_data = {
                constructions_id: id,
                search: '',
                perpage: 100,
                page: 1,
                orderField: 'percentage_advance',
                order: 'asc',
            };        
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_SUBTASK_COMMENTS+'&constructions_subtask_id='+subtaskcomment,
                headers: {Authorization: userToken},
                data: params_data
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    console.log(datanotice);
                    setSubTaskData({
                        ...subtaskdata,
                        ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const peticionGetInfoCommentTaskById=async(InfoCommentsId)=>{
        if(InfoCommentsId === undefined){
            let oi = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_TASK_COMMENTS_RESP+'?construction_task_comments_id='+commentsId)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_TASK_COMMENT_BY_ID+'&id='+InfoCommentsId,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice);
                    setInfoComentario({
                        ...infocomentario,
                        ['datos']: datanotice.data,
                        ['files_comments']: datanotice.data.Files,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
                } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    useEffect(() => {
        peticionGetTaskById();
        peticionGetAllComments();
        peticionGetCommentTaskById();
        peticionGetInfoCommentTaskById();
        //peticionGetSubTaskById();
        //peticionGetAllSubTaskData();
        //peticionGetAllCommentSubTask();
    }, []);

    useEffect(() => {
        peticionGetAllCommentsSubtask();
    }, [subtaskcomment]);

    let responsablestarea = categor.datos.Responsable;
    let observadorestarea = categor.datos.Observers;
    let participantestarea = categor.datos.Participants;
    let responses_comment = task.datos;
    let sub_responses_comment = allcommentsubtask.datos;

    const adjuntar = () => {
        var file = document.getElementById("file").click();
    };

    const borraradjunto = () => {
        setArchivoBase64();
        setDatFile({
            ...datsfile,
            ['ext']: '',
            ['title_file']: '',
        });
    };

    let info_base64 = archivobase64 === undefined ? 0 : archivobase64;
    let info_files = infocomentario.datos.length === 0 ? [] : infocomentario.files_comments;
    
    const convertToBase64 = () => {
        //Read File
        var selectedFile = document.getElementById("file").files;
        let selectedFile1 = document.getElementById("file").value;
        let nom1_archivo = document.getElementById('file').files[0];
        let nom2_archivo = document.getElementById('file');
        let extension = selectedFile1.substring(selectedFile1.lastIndexOf('.'),selectedFile1.length);
        let MAXIMO_TAMANIO_BYTES = 50000000;

        setDatFile({
            ...datsfile,
            ['title_file']: nom1_archivo.name.split('.')[0],
            ['ext']: extension
        });

        if(nom1_archivo.size > MAXIMO_TAMANIO_BYTES){
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!",`El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`,"info");
            nom2_archivo.value = "";
            
            setDatFile({
                ...datsfile,
                ['title_file']: '',
                ['ext']: ''
            });
        }else{
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                //var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.split(',')
                    setArchivoBase64(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                // Obtener extensión del archivo
            }
        }
    };
    
    const fecha_comentario = (datadate) =>{
        let fecha_comentarios = new Date(datadate);
        let dia_comentario = fecha_comentarios.getDate();
        let mes_comentario = fecha_comentarios.getMonth()+1;
        let año_comentario = fecha_comentarios.getFullYear();
        let fecha_res = dia_comentario+'/'+mes_comentario+'/'+año_comentario;

        return fecha_res;
    };
    
    const fechas_respuestas = (datadate) =>{
        let fecha_respuesta = new Date(datadate);
        let dia_respuesta = fecha_respuesta.getDate();
        let mes_respuesta = fecha_respuesta.getMonth()+1;
        let año_respuesta = fecha_respuesta.getFullYear();
        let fecha_res = dia_respuesta+'/'+mes_respuesta+'/'+año_respuesta;

        return fecha_res;
    };

    const handleCloseSave = (e) => {

        let titulo = dats.comentariopri;
        
        const formattedData = {
            constructions_task_id: Idtask,
            creator_id: userId,
            comment: titulo,
            type: datsfile.ext,
            title: datsfile.title_file,
            file: archivobase64 === undefined ? '' : archivobase64,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_TASK_COMMENTS, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_TASK_COMMENTS,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Comentario enviado", "", "success");
                document.getElementById('comentariopri').value = '';
                peticionGetAllComments();
                setArchivoBase64();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    const handleCloseSaveResp = (e) => {

        let titulo_res = dats.comentario_res;
        
        const formattedData = {
            construction_task_comments_id: rescomment,
            creator_id: userId,
            response: titulo_res,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_TASK_COMMENTS_RESP, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_TASK_COMMENTS_RESP,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Comentario enviado", "", "success");
                document.getElementById('comentario_res').value = '';
                handleClose1();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const descargaarchivo = (data) =>{
        const downloadinstance = document.createElement('a');
        downloadinstance.href = infocomentario.files_comments[0].file64;
        downloadinstance.target = '_blank';
        downloadinstance.download = infocomentario.files_comments[0].file.split('/')[infocomentario.files_comments[0].file.split('/').length-1];
        downloadinstance.click();
        //console.log(downloadinstance.href);
    };

    return (
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <Button className="btn btn-success rounded-circle" size="sm" 
                    onClick={() => {window.history.back()}} title="Regresar"
                >
                    <MdIcons.MdArrowBackIosNew/>
                </Button>
            </div>
            <br/>
            <Container className="w-100 p-3 mb-4" style={{borderRadius: "4em", backgroundColor: "#aed6f1"}}>
                <VerticalTimeline>
                    {/* inicio de la tarea */}
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                        date={<b style={{color: "black"}}>{categor.datos.nameCreator}</b>}
                        iconStyle={
                            {
                                backgroundImage: `url(${categor.datos.imageCreator === '' ? LogoDedalo : categor.datos.imageCreator})`,
                                backgroundSize: "cover",
                                //backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }
                        }
                    >
                        <h3 className="vertical-timeline-element-title">Poryecto {categor.datos.nameConstruction}</h3>
                        <h4 className="vertical-timeline-element-subtitle">Tarea {categor.datos.title}</h4>
                        <br/>
                        <div>
                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Comentar Tarea" id="comentariopri"
                                    aria-label="Comentar Tarea" name="comentariopri"
                                    aria-describedby="basic-addon1" onChange={handleInputChange}
                                />
                                    {info_base64 === 0 ? 
                                        <InputGroup.Text id="basic-addon1" onClick={adjuntar} style={{cursor: "pointer"}}>
                                            <MdIcons.MdAttachFile className="text-primary" title="Adjuntar archivo"/>
                                            <input id="file" type = "file" style={{display: "none"}} onChange={()=>convertToBase64()}/>
                                        </InputGroup.Text>
                                    :
                                        <InputGroup.Text id="basic-addon1" onClick={borraradjunto} style={{cursor: "pointer"}}>
                                            <MdIcons.MdCancel style={{color: 'red'}} title="Cancelar adjunto"/>
                                        </InputGroup.Text>
                                    }
                                <Button variant="success" onClick={handleCloseSave} title="Enviar Comentario">
                                    <MdIcons.MdSend/>
                                </Button>
                            </InputGroup>
                        </div>
                        <div style={
                                {
                                    color: "black", height: "14em", minHeight: "14em", padding: "0.5em 1em",
                                    maxHeight: "14em",overflowY: "auto", backgroundColor: "white",
                                    borderRadius: "0.3em",
                                }
                            }
                        >
                            {categor1.datos.map(r => (
                                <div class="d-flex justify-content-between mb-2">
                                    <div class="d-flex justify-content-between mb-2" key={r.idcomment}>
                                        <div>
                                            <img src={r.image === '' ? LogoDedalo : r.image} className="me-2" 
                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                        </div>
                                        <div style={{fontSize: "0.8em"}}>
                                            <b style={{color: "#d35400"}}>
                                                {r.creatorName}
                                            </b> - {fecha_comentario(r.date)}
                                            <br/>
                                            {r.comment}
                                            <br/>
                                            <a href="#" onClick={()=>handleShow1(r.id)} className="text-decoration-none me-2">
                                                Comentar
                                            </a>
                                            <a href="#" onClick={()=>handleShow(r.id)} className="text-decoration-none me-2">
                                                Ver Comentarios 
                                            </a>
                                            <a href="#" className="text-decoration-none me-2" onClick={() => handleShow2(r.id)}>
                                                Ver Adjunto 
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </VerticalTimelineElement>

                    {/* inicio de la subtarea */}
                    <VerticalSubTask nom_project={categor.datos.nameConstruction}/>

                </VerticalTimeline>
            </Container>
            {/** MODAL VER COMENTARIOS TAREA  **/}
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Respuestas del Comentario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {responses_comment === undefined ?
                            ''
                        :responses_comment.length === 0 ?
                            <div className="text-center">
                                <h5>No hay respuestas</h5>
                            </div>
                        :
                            responses_comment.map(item => (
                                <div class="d-flex justify-content-between mb-2">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div>
                                            <img src={item.image === '' ? LogoDedalo : item.image} className="me-2" 
                                                style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                        </div>
                                        <div style={{fontSize: "0.8em"}}>
                                            <b style={{color: "#229954"}}>
                                                {item.creatorName}
                                            </b> - {fechas_respuestas(item.date)}
                                            <br/>
                                            {item.response}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                </Modal.Body>
            </Modal>
            {/** MODAL COMENTAR TAREA **/}
            <Modal show={show1} onHide={handleClose1} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Respuestas del Comentario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            <MdIcons.MdOutlineQuestionAnswer/>
                        </InputGroup.Text>
                        <FormControl
                            placeholder="Responder Comentario" id="comentario_res"
                            aria-label="Responder Comentario" name="comentario_res"
                            aria-describedby="basic-addon1" onChange={handleInputChange}
                            as="textarea"ne rows="3" style={{resize: "none"}}
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSaveResp}>
                        Responder
                    </Button>
                </Modal.Footer>
            </Modal>
            {/** MODAL VER ADJUNTO **/}
            <Modal show={show2} onHide={handleClose2} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Archivo Adjunto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div> {/* archivos */}
                        {info_files.length === 0 ?
                            <div className="text-center">
                                <h5>No hay archivos adjuntos</h5>
                            </div>
                        :
                            <div className="d-flex align-items-center justify-content-center">
                                <Card style={{ width: '24rem' }}>
                                    <Card.Header variant="top" className="text-center">
                                        {info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.pptx' ?
                                            <img src={Powerpoint} alt="pptx" title="pptx" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.docx' ?
                                            <img src={Word} alt="word" title="word" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.rar' ?
                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.zip' ?
                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.7z' ?
                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.pdf' ?
                                            <img src={Pdf} alt="pdf" title="pdf" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.xlsx' ?
                                            <img src={Excel} alt="xlsx" title="xlsx" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.xls' ?
                                            <img src={Excel} alt="xls" title="xls" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.dwg' ?
                                            <img src={AutoDesk} alt="DWG" title="DWG" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.jpg' ?
                                            <img src={JPG} alt="JPG" title="JPG" width="100px" height="100px" />
                                        :info_files[0].file.substring(info_files[0].file.lastIndexOf('.'),info_files[0].file.length) === '.png' ?
                                            <img src={PNG} alt="PNG" title="PNG" width="100px" height="100px" />
                                        :
                                            ''
                                        }
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title className="with-elements">
                                            <div className="fs-6 text">
                                                {info_files[0].file.split('/')[2].split('_')[0]}
                                            </div>
                                            <div className="ml-auto seccion_menu_archivos">
                                                <DropdownButton id="dropdown-basic-button" size="sm"
                                                    variant="light" className="rounded"
                                                >
                                                    <Dropdown.Item onClick={descargaarchivo}>
                                                        <FaIcons.FaDownload className="text-primary me-2 fs-5"/>
                                                        Descargar Item
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ComentariosTareas;