import React, { useState, Fragment, useEffect, useRef } from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, ListGroup, Table, DropdownButton, ButtonGroup, Dropdown,
    Card, Container,
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_REEVALUACION_PROVEEDOR, DEDALO_COMBO_VENDORS_FULL,
    DEDALO_REEVALUACION_PROVEEDOR_BY_ID,
} from "../../../../assets/APIAdresses";
import { message_responses } from "../../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../../utils/utils";
import { useUserSession } from "../../../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../../../utils/routes';
import { Mensaje_Error_Evaluacion_Proveedor } from '../../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import Img1 from '../../imagenes_reportes/logo_geo.png';
import * as MdIcons from "react-icons/md";

const Reevaluacion_Proveedor_Edit = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [proveedorselect, setProveedorSelect] = useState(0);

    let { IdElementEdit } = useParams();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        fecha: '',
        nit: '',
        proveedor: '',
        name_proveedor: '',
        puntaje1: '',
        puntaje2: '',
        puntaje3: '',
        puntaje4: '',
        puntaje5: '',
        puntaje6: '',
        puntaje7: '',
        puntaje8: '',
        puntaje9: '',
        puntaje10: '',
        obs: '',
    });

    let valildar = {
        comercial_puntaje1: dats.puntaje1,
        comercial_puntaje2: dats.puntaje2,
        comercial_puntaje3: dats.puntaje3,
        calidad_puntaje1: dats.puntaje4,
        calidad_puntaje2: dats.puntaje5,
        calidad_puntaje3: dats.puntaje6,
        entrega_cumplimiento_puntaje1: dats.puntaje7,
        entrega_cumplimiento_puntaje2: dats.puntaje8,
        calidad_puntaje1: dats.puntaje9,
        calidad_puntaje2: dats.puntaje10,
    };

    const peticionGetCategor = async () => {
        const params_data = {
            enterprice_id: data_emp2.IdEmp,
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_VENDORS_FULL,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice.data);
                    setCategor({
                        ...categor,
                        ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
                }
            });

        return info1;
    };

    const peticionGetVendorById = async () => {
        let id_noconformidad = parseInt(IdElementEdit);

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_REEVALUACION_PROVEEDOR_BY_ID + '&id=' + id_noconformidad,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice.data);
                    setDat({
                        ...dats,
                        ['fecha']: datanotice.date,
                        ['nit']: datanotice.nitProvider,
                        ['proveedor']: datanotice.crm_vendors_id,
                        ['name_proveedor']: datanotice.nameProvider,
                        ['puntaje1']: datanotice.compliance_criteria1,
                        ['puntaje2']: datanotice.compliance_criteria2,
                        ['puntaje3']: datanotice.certificates_criteria1,
                        ['puntaje4']: datanotice.certificates_criteria2,
                        ['puntaje5']: datanotice.technical_assistance1,
                        ['puntaje6']: datanotice.technical_assistance2,
                        ['puntaje7']: datanotice.technical_assistance3,
                        ['puntaje8']: datanotice.quality_criteria1,
                        ['puntaje9']: datanotice.quality_criteria2,
                        ['puntaje10']: datanotice.quality_criteria3,
                        ['obs']: datanotice.observations,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas By_id");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetVendorById();
    }, []);

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const handleInputChangeProveedorSelect = (e) => {
        setProveedorSelect(e.target.value);
    };

    let val1 = dats.puntaje1 === '' ? 0 : parseInt(dats.puntaje1);
    let val2 = dats.puntaje2 === '' ? 0 : parseInt(dats.puntaje2);
    let val3 = dats.puntaje3 === '' ? 0 : parseInt(dats.puntaje3);
    let val4 = dats.puntaje4 === '' ? 0 : parseInt(dats.puntaje4);
    let val5 = dats.puntaje5 === '' ? 0 : parseInt(dats.puntaje5);
    let val6 = dats.puntaje6 === '' ? 0 : parseInt(dats.puntaje6);
    let val7 = dats.puntaje7 === '' ? 0 : parseInt(dats.puntaje7);
    let val8 = dats.puntaje8 === '' ? 0 : parseInt(dats.puntaje8);
    let val9 = dats.puntaje9 === '' ? 0 : parseInt(dats.puntaje9);
    let val10 = dats.puntaje10 === '' ? 0 : parseInt(dats.puntaje10);

    let total = val1 + val2 + val3 + val4 + val5 +
        val6 + val7 + val8 + val9 + val10;

    let dia = new Date().getDate();
    let mes = new Date().getMonth() + 1;
    let year = new Date().getFullYear();

    const printContainer = useRef(null);

    const createPDF = async () => {
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;

        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter",
                orientation: 'p'
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `reevaluacion_proveedor_${fecha}.pdf`
        });
    };

    const handleCloseSave = (e) => {
        let valor1 = parseInt(IdElementEdit);
        let valor2 = '';

        for (var key in valildar) {
            if (valildar.hasOwnProperty(key)) {
                if (!valildar[key]) {
                    valor2 = 'vacio';
                } else {
                    valor2 = '';
                }
            }
        }

        const formattedData = {
            enterprice_id: data_emp2.IdEmp,
            constructions_id: data_emp.Id_Project_Conta,
            users_id: userId,
            date: `${year}/${mes}/${dia}`,
            crm_vendors_id: parseInt(proveedorselect === 0 ? dats.proveedor : proveedorselect),
            compliance_criteria1: parseInt(dats.puntaje1),
            compliance_criteria2: parseInt(dats.puntaje2),
            certificates_criteria1: parseInt(dats.puntaje3),
            certificates_criteria2: parseInt(dats.puntaje4),
            technical_assistance1: parseInt(dats.puntaje5),
            technical_assistance2: parseInt(dats.puntaje6),
            technical_assistance3: parseInt(dats.puntaje7),
            quality_criteria1: parseInt(dats.puntaje8),
            quality_criteria2: parseInt(dats.puntaje9),
            quality_criteria3: parseInt(dats.puntaje10),
            observations: dats.obs,
        };
        //console.log(formattedData);
        if (valor2 === 'vacio') {
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible guardar el anticipo', "info");
        } else {
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_REEVALUACION_PROVEEDOR + '&id=' + valor1,
                headers: { Authorization: userToken },
                data: formattedData
            })
                .then(response => {
                    //console.log(response);
                    if (response.data.code === '200') {
                        Swal.fire(
                            "!!! Atencion !!!",
                            "se ha editado el formato exitosamente",
                            "success"
                        );
                        peticionGetVendorById();
                    } else {
                        Mensaje_Error_Evaluacion_Proveedor(response.data.msg.error)
                    }
                })
                .catch(error => {
                    manageError(error);
                });
        }
    };

    let data_nit = categor.datos.filter(x => x.id === parseInt(proveedorselect));

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371" }}
                            onClick={handleCloseSave}
                        >
                            Guardar reevaluacion
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm"
                            onClick={() => createPDF()} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br />
            <div className="container container-fluid" ref={printContainer} style={{ padding: '1em 1em 1em 1em' }}>
                <Row className="border border-dark mx-1"> {/*  cabecera del formato */}
                    <Col md={2} className="text-center py-1 border-end border-dark">
                        <img src={Img1} className='img-fluid' width={230} />
                    </Col>
                    <Col md={8} className="d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <h3>
                                <b>FORMATO REVALUACION DE PROVEEDORES</b>
                            </h3>
                        </div>
                    </Col>
                    <Col md={2} className="text-center py-1 border-start border-dark">
                        <div>
                            Codigo: FO-CP-09-01
                        </div>
                        <div>
                            Fecha: 01/01/2015
                        </div>
                        <div>
                            Pagina 1 de 1
                        </div>
                    </Col>
                </Row>
                <br />
                <Row className="mx-1">
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                    <Col md={3}></Col>
                    <Col md={3} className='border border-dark'>
                        <div className="text-center border-bottom border-dark">
                            <b>FECHA</b>
                        </div>
                        <div>
                            <FormControl
                                aria-label="Nombre de la cotizacion" size="sm" value={`${year}/${mes}/${dia}`}
                                aria-describedby="basic-addon1" type="text" id="fecha" name="fecha"
                                className="border-0 text-center" onChange={handleInputChangeObs}
                                disabled style={{ background: '#fff', color: 'black' }}
                            />
                        </div>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark mx-1">
                    <Col md={12}>
                        <Row className="border-bottom border-dark">
                            <Col md={3} className='text-center border-end border-dark' style={{ background: '#AAB7B8' }}>
                                <b>NIT</b>
                            </Col>
                            <Col md={9} className='text-center' style={{ background: '#AAB7B8' }}>
                                <b>PROVEEDOR</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} className='text-center border-end border-dark'>
                                <FormControl
                                    aria-label="Nombre de la cotizacion"
                                    value={
                                        proveedorselect === 0 ?
                                            dats.nit
                                            :
                                            data_nit[0] === undefined ? '' : data_nit[0].nit
                                    }
                                    aria-describedby="basic-addon1" type="text" id="nit" name="nit"
                                    className="border-0 text-center"
                                />
                            </Col>
                            <Col md={9}>
                                <Form.Select aria-label="Default select example"
                                    id="select_vendor" name="select_vendor" className="border-0"
                                    onChange={handleInputChangeProveedorSelect}
                                >
                                    <option value="">{dats.name_proveedor}</option>
                                    {categor.datos.length === 0 ?
                                        ''
                                        :
                                        categor.datos.map(q => (
                                            <option value={q.id}>{q.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark mx-1">
                    <Col md={12}>
                        <div>
                            <Row className='text-center' style={{ background: '#AAB7B8' }}>
                                <Col md={1} className='border-end border-dark border-bottom'>
                                    <span><b>No.</b></span>
                                </Col>
                                <Col md={3} className='border-end border-dark border-bottom'>
                                    <span><b>CRITERIOS DE EVALUACION</b></span>
                                </Col>
                                <Col md={6} className='border-end border-dark border-bottom'>
                                    <span><b>DESCRIPCION</b></span>
                                </Col>
                                <Col md={2} className='border-dark border-bottom'>
                                    <span><b>PUNTAJE (0-10)</b></span>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row className='text-center'>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>1</b></span>
                                </Col>
                                <Col md={3} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>CUMPLIMIENTO</b></span>
                                </Col>
                                <Col md={8} className='border-dark px-0'>
                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0'>
                                            <div>
                                                <span><b>1</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Es puntual y cumple con los programas o plazos pactados
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" size="sm" value={dats.puntaje1}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje1" name="puntaje1"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>2</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Atiende las solicitudes normales y urgentes con prontitud y puntualidad
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje2}
                                                        aria-describedby="basic-addon1" type="number" id="puntaje2" name="puntaje2"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row className='text-center'>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>2</b></span>
                                </Col>
                                <Col md={3} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>SUMINISTRO DE CERTIFICADOS Y OTROS DOCUMENTOS</b></span>
                                </Col>
                                <Col md={8} className='px-0'>
                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <span><b>1</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Suministra documentos exactos de manera inmediata
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje3}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje3" name="puntaje3"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>2</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Atiende solicitudes de documentacion de manera oportuna
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje4}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje4" name="puntaje4"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row className='text-center'>
                                <Col md={1} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>3</b></span>
                                </Col>
                                <Col md={3} className='border-end border-dark border-bottom d-flex justify-content-center align-items-center'>
                                    <span><b>ASISTENCIA TECNICA Y DESENVOLVIMIENTO</b></span>
                                </Col>
                                <Col md={8} className='px-0'>
                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <span><b>1</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Atiende solicitudes y reclamos con respecto de el producto o servicio
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje5}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje5" name="puntaje5"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>2</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Posee un oportuno servicio de postventa
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje6}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje6" name="puntaje6"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>3</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Es proactivo y atiende con eficacia
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje7}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje7" name="puntaje7"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row className='text-center'>
                                <Col md={1} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <span><b>4</b></span>
                                </Col>
                                <Col md={3} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <span><b>CALIDAD DE DESEMPEÑO DE PRODUCTOS Y SERVICIOS</b></span>
                                </Col>
                                <Col md={8} className='px-0'>
                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <span><b>1</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark border-bottom px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    La relacion de precio y calidad es adecuada
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje8}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje8" name="puntaje8"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-bottom border-dark px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>2</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-bottom border-dark px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Los productos y/o servicios entregados no presentan problemas o defectos
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark border-bottom px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje9}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje9" name="puntaje9"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mx-0'>
                                        <Col md={1} className='border-end border-dark px-0 d-flex justify-content-center align-items-center'>
                                            <div >
                                                <span><b>3</b></span>
                                            </div>
                                        </Col>
                                        <Col md={8} className='border-end border-dark px-0'>
                                            <div className='text-start px-2'>
                                                <span>
                                                    Esta certificado o en proceso de certificacion
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={3} className='border-dark px-0 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <InputGroup size="sm">
                                                    <Form.Control
                                                        aria-label="Nombre de la cotizacion" value={dats.puntaje10}
                                                        aria-describedby="basic-addon1" type="text" id="puntaje10" name="puntaje10"
                                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <Row className='border-top border-dark'>
                            <Col md={8}></Col>
                            <Col md={2} className='border-end border-start border-dark text-center' style={{ background: '#AAB7B8' }}>
                                <b>TOTAL</b>
                            </Col>
                            <Col md={2} className='text-center'>
                                <b>{total}</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="mx-1">
                    <Col md={1}></Col>
                    <Col md={9} className='text-center border border-dark'>
                        <span>
                            PROMEDIO DE CALIFICACIONES ANTERIOR
                        </span>
                    </Col>
                    <Col md={2} className='border-top border-bottom border-end border-dark'></Col>
                </Row>
                <br />
                <Row className="border border-dark mx-1">
                    <Col md={12} className='text-center border-bottom border-dark' style={{ background: '#AAB7B8' }}>
                        <b>OBSERVACIONES</b>
                    </Col>
                    <Col md={12}>
                        <Row>
                            <Col md={8} className="border-end border-dark">
                                <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                    id="obs" name="obs" style={{ fontSize: '1.2em', textAlign: 'justify' }}
                                    className='border-0' rows={4} value={dats.obs}
                                />
                            </Col>
                            <Col md={2} className="border-end border-dark">
                                <Row className="text-center border-bottom border-dark" style={{ background: '#52BE80' }}>
                                    <Col>
                                        <b>PROVEEDOR<br />CONFIABLE</b>
                                    </Col>
                                </Row>
                                <Row className="text-center border-bottom border-dark" style={{ background: '#F4D03F' }}>
                                    <Col>
                                        <b>PROVEEDOR<br />ACEPTABLE</b>
                                    </Col>
                                </Row>
                                <Row className="text-center" style={{ background: '#E74C3C' }}>
                                    <Col>
                                        <b>PROVEEDOR NO CONFIABLE</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <Row className="text-center border-bottom border-dark">
                                    <Col>
                                        <span>Proveedor Aprobado mas de 80 puntos</span>
                                    </Col>
                                </Row>
                                <Row className="text-center border-bottom border-dark" >
                                    <Col>
                                        <span>Proveedor Aprobado mas de 50 puntos en revision</span>
                                    </Col>
                                </Row>
                                <Row className="text-center">
                                    <Col>
                                        <span>Proveedor rechazado menos de 50 puntos</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark mx-1">
                    <Col md={2} className="border-end border-dark d-flex justify-content-center align-items-center" style={{ background: '#AAB7B8' }}>
                        <b>FIRMAS RESPONSABLES</b>
                    </Col>
                    <Col md={5} className="border-end border-dark">
                        <Row className="border-bottom border-dark">
                            <Col md={12}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion"
                                    aria-describedby="basic-addon1" type="number" id="year" name="year"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center" style={{ background: '#AAB7B8' }}>
                                <b>COORDINADOR DE COMPRAS</b>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        <Row className="border-bottom border-dark">
                            <Col md={12}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion"
                                    aria-describedby="basic-addon1" type="number" id="year" name="year"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center" style={{ background: '#AAB7B8' }}>
                                <b>LIDER ADMINISTRATIVO Y FINANCIERO</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Reevaluacion_Proveedor_Edit;