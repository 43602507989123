import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Button, InputGroup, FormControl, Container, Row, Col,
  Form, Table, Card,
} from 'react-bootstrap';
import {
    DEDALO_PERSONAL_CUADRILLAS,DEDALO_CUADRILLAS_BY_ID,DEDALO_ACTUALIZAR_CUADRILLAS
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import { Routes } from "../../../utils/routes";
import * as RiIcons from 'react-icons/ri';
import Navbar from '../../navbar';
import Select  from 'react-select';

function EditarUsuario() {
    const {userToken} = useUserSession();
    const {idcuadrilla} = useParams();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();    
    const [boton, setBoton] = useState(0);
    const [personalselect, setPersonalSelect] = useState([]);
    const [personalcuadrilla, setPersonalCuadrilla] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        name: '',
    });

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetPersonal=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_PERSONAL_CUADRILLAS)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_PERSONAL_CUADRILLAS,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setPersonalCuadrilla({
                    ...personalcuadrilla,
                    ['datos']: datanotice,
                });
            } else {
                setPersonalCuadrilla({
                    ...personalcuadrilla,
                    ['datos']: [],
                });
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };
    
    const peticionGetCuadrillaById=async()=>{
        //let info1 = await axios.get(DEDALO_CUADRILLAS_BY_ID(parseInt(idcuadrilla)))
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CUADRILLAS_BY_ID+'&id='+idcuadrilla,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setDat({
                    ...dats,
                    ['name']: datanotice.name,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                error.response.data.message === "VALIDATOR_ERROR"
                ? error.response.data.data.error_message
                : message_responses[error.response.data.message]
            );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetPersonal();
        peticionGetCuadrillaById();
    }, []);


    const handleCloseSave = (e) => {
        let nombre = dats.name;
        let personal_cuadrilla = personalselect;
        
        const formattedData = {
            name: nombre,
            constructions_id: id,
            personal: personal_cuadrilla,
        };
        //console.log(formattedData);
        //axios.put(DEDALO_ACTUALIZAR_CUADRILLAS(parseInt(idcuadrilla)), formattedData, attachToken(user))
        axios({
            method: 'PUT',
            url: DEDALO_ACTUALIZAR_CUADRILLAS+'&id='+parseInt(idcuadrilla),
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se actualizó la Informacion exitósamente", "", "success");
                setBoton(1);
            } else {
                alert("ERROR: Server Error");
            }
        })
        //.catch(error => {
        //    manageError(error);
        //})
    };

    const handleChangePersonalSelect = (e) => {
        setPersonalSelect(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const selectusuarios = [];
    if(personalcuadrilla.datos === undefined){
        let yu = 0;
    }else{
        personalcuadrilla.datos.map(i => {
            selectusuarios.push({
                value: i.id,
                label: i.name,
            });
        });
    }

    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div>
            {/*<Navbar modulo="Editar cuadrilla" usuario={user.name}/>*/}
            <div className="w-100" style={{paddingBottom: "2%"}}>
                <Container className="w-50 mt-5">                   
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-3" style={{width: "60%", margin: "auto", paddingTop: "5%"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <RiIcons.RiTeamFill/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Nombre de la Cuadrilla" aria-label="Nombre de la Cuadrilla"
                                                aria-describedby="basic-addon1" type="email" onChange={handleInputChange}
                                                id="name" name="name" value={dats.name}
                                            />
                                        </InputGroup>
                                    
                                        <InputGroup className="mb-3" style={{width: "60%", margin: "auto"}}>
                                            <Form.Label htmlFor="basic-url">Personal Cuadrilla</Form.Label>
                                            <Select isMulti options={selectusuarios} placeholder="Seleccione el personal de la cuadrilla"
                                                onChange={handleChangePersonalSelect} className="w-100"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            
                                <Row className="text-center" style={{marginTop: "5%"}}>
                                    <Col>
                                        {boton === 1 ?
                                            
                                            <Button onClick={()=>{window.history.back()}} className="btn btn-success" 
                                                style={{backgroundColor: "#02B371"}}>
                                                Regresar
                                            </Button>
                                            
                                        :
                                            <Button onClick={()=>handleCloseSave()} className="btn btn-success" 
                                                style={{backgroundColor: "#02B371"}}>
                                                Editar Cuadrilla
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    
                </Container>
            </div>
            
        </div>
        
    );
}

export default EditarUsuario;