import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,FloatingLabel,
} from 'react-bootstrap';
import {
    QC_NEW_REQUISITIONS,DEDALO_CIUDADES,DEDALO_COMBO_INVENTARIO,
    DEDALO_NEW_REQUISITIONS,DEDALO_ALL_REQUISITIONS,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import {Mensaje_Error_Create_Req} from '../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";
import Img1 from '../imagenes_reportes/logo_geo.png';

const RequisicionNueva = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [aumento, setAumento] = useState(0);
    const [row_count, setRow_Count] = useState(0);
    const [ciudad, setCiudad] = useState('');

    const [dats, setDat] = useState({
        observaciones: '',
        cargo: '',
    });

    const [ciudadesproyectos, setCiudadesProyectos] = useState({
        datos: [],
    });

    const [suppliescombo, setSuppliesCombo] = useState({
        datos: [],
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [rows, setRows] = useState([
        { id: 0, cantidad: "", unidad: '', id_supllies: "", destino: '' },
    ]);

    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CIUDADES,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setCiudadesProyectos({
                ...ciudadesproyectos,
                ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetSuppliesId=async()=>{
        const params_data = {
            constructions_id: parseInt(data_emp.Id_Project_Conta),
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_INVENTARIO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setSuppliesCombo({
                    ...suppliescombo,
                    ['datos']: datanotice.data,
                });
            } else {
                setSuppliesCombo({
                    ...suppliescombo,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetCategor2=async()=>{
        const params_data = {
            enterprice_id: data_emp2.IdEmp,
            search: '',
            perpage: 10000,
            page: 1,
            orderField: 'date',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_REQUISITIONS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                //alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetSuppliesId();
        peticionGetCategor2();
    }, []);

    const agregarFila = () =>{
        setAumento(aumento+1);
        setRows([
            ...rows,
            {
                id: rows.length + 0, cantidad: "",
                unidad: "", unidad: '', id_supllies: "", destino: ''
            },
        ]);
    };

    const eliminarFila = (i) => {
        const list = [...rows];
        list.splice(i, 1);
        setRows(list);
    };

    const handleInputChange = (e, index) =>{        
        const { name, value } = e.target;
        const list = [...rows];
        list[index][name] = value;
        setRows(list);
    };

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeCiudad = (event) => {
        setCiudad(event.target.value);
    };

    let id_supli = [];
    let cnt_supli = [];
    let destino_req = [];
    let unidad_req = [];

    rows.map((row, index) => {
        id_supli.push(row.id_supllies);
        cnt_supli.push(parseInt(row.cantidad));
        destino_req.push(row.destino);
        unidad_req.push(row.unidad);
    });
    
    let fecha_actual = new Date();
    let dias_actual = fecha_actual.getDate();
    let mes_actual = fecha_actual.getMonth()+1;
    let anio_actual = fecha_actual.getFullYear();
    let fecha_actual_format = anio_actual+'-'+mes_actual+'-'+dias_actual;

    const handleCloseSave = (e) => {

        e.preventDefault();
        let obs = dats.observaciones;

        const formattedData = {
            supplies_id: id_supli,
            measurement: unidad_req,
            constructions_id: parseInt(data_emp.Id_Project_Conta),
            city_id: parseInt(ciudad),
            position: dats.cargo,
            users_id: userId,
            date: fecha_actual_format,
            amounts: cnt_supli,
            targets: destino_req,
            observations: obs,
        };
        //console.log(formattedData);
        if(ciudad === ''){
            Swal("!! Atencion !!","verifique que los campos esten llenos","warning");
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NEW_REQUISITIONS,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire("Se ha creado el suminstro exitósamente", "", "success");
                    setRows([
                        { id: 0, cantidad: "", unidad: '', id_supllies: "", destino: '' },
                    ]);
                    document.getElementById('observaciones').value = '';
                }else {
                    Mensaje_Error_Create_Req(response.data.msg.error);
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    let conteo = categor.datos === undefined ? 0 : categor.datos.length;
    let ceros = '';
    let prueba = String(conteo);
    for(let i = 0;i < 10;i++){
        ceros += '0';
    }
    let newStr = ceros.substr(prueba.length);

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371", color: '#fff'}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2 botongenerar" size="sm" onClick={handleCloseSave}
                            title="Regresar" style={{backgroundColor: "#02B371", color: '#fff'}}
                        >
                            Guardar Requisición nueva
                        </Button>
                    </div>
                    <div>
                        <Button className="bg-primary me-2 agregaritem" size="sm"
                            onClick={agregarFila} title="Agregar Item" style={{backgroundColor: "#02B371", color: '#fff'}}
                        >
                            Agregar Item
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid flex-grow-1 container-p-y">   
                <div className="container" >
                    <Row className="border border-dark"> {/* cabecera del formato */}
                        <Col md={2} xs={2} className="text-center">
                            <img src={Img1} alt="logo" width="200" height="150" className="img-fluid py-3" />
                        </Col>
                        <Col md={8} xs={8} className="d-flex justify-content-center align-items-center border-start border-end border-dark">
                            <div >
                                <h3>
                                    FORMATO REQUISICION
                                </h3>
                            </div>
                        </Col>
                        <Col md={2} xs={2} >
                            <div className="mt-3">
                                <div>
                                    <span>
                                        <b>Codigo:FO-CP-01-01</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b>Fecha:09/08/2013</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b>Pagina 1 de 1</b>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-dark border-start border-end border-botom">
                        <Col md={12} xs={12} className="border-dark border-bottom text-center">
                            <Row>
                                <Col md={2} xs={2} className="text-center border-end border-dark">
                                    <div className="d-flex justify-content-center align-items-center py-1">
                                        <div className="me-2">
                                            <span style={{fontSize: '0.8em'}}>
                                                <b>REQUISICIÓN</b>
                                            </span>
                                        </div>
                                        <div>
                                            {newStr+(parseInt(prueba) === 0 ? 1 : parseInt(prueba) + 1)}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8} xs={8} className="text-center"></Col>
                                <Col md={2} xs={2} className="text-center border-start border-dark d-flex justify-items-center align-items-center">
                                    <div>
                                        <span><b>FECHA: {new Date().toLocaleDateString()}</b></span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom">
                            <Row className="border-bottom border-dark">
                                <Col md={2} xs={2} className="text-center border-end border-dark py-2">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Proyecto</b>
                                            </span>
                                        </div>
                                        <div>
                                            {data_emp2.NomEmp}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8} xs={8} className="text-center py-2">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Direccion</b>
                                            </span>
                                        </div>
                                        <div>
                                            {data_emp.dir_construction}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} xs={2} className="text-center border-start border-dark d-flex justify-items-center align-items-center">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Ciudad</b>
                                            </span>
                                        </div>
                                        <div >
                                            <InputGroup className="mb-3" style={{width: "100%" }}>
                                                <Form.Select aria-label="Default select example" size="sm"
                                                    onChange={handleChangeCiudad} id="select" name="select"
                                                >
                                                    <option value="">Seleccione la Ciudad</option>
                                                    {ciudadesproyectos.datos.map(i => (
                                                        <option value={i.id}>{i.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10} xs={10} className='border-end border-dark py-2'>
                                    <div className="py-1">
                                        <div className="text-center">
                                            <span>
                                                <b>FUNCIONARIO RESPONSABLE DEL PROYECTO / TRABAJADOR SOLICITANTE</b>
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <span>{user.name}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} xs={2} className='py-2'>
                                    <div className="py-1">
                                        <div className="text-center">
                                            <span>
                                                <b>CARGO</b>
                                            </span>
                                        </div>
                                        <div>
                                            <InputGroup>
                                                <Form.Control
                                                    className="text-center" onChange={handleInputChangeObs}
                                                    aria-label="Username" id="cargo" name="cargo"
                                                    aria-describedby="basic-addon1" size="sm"
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom"> {/*  tabla de informacion */}
                            <div className="my-2">
                                <Table responsive id="tabla_req" name="tabla_req">
                                    <thead className="text-center">
                                        <tr>
                                            <th>Item</th>
                                            <th colSpan={1}>Cantidad</th>
                                            <th>Unidad de medida</th>
                                            <th>Descripcion del item</th>
                                            <th>Destino y/o actividad</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows === undefined ?
                                            ''
                                        :
                                            rows.map((row, i) =>(
                                                <tr key={i}>
                                                    <td className="text-center">{row.id+1}</td>
                                                    <td style={{width: '10em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="cantidad" name="cantidad"
                                                            value={row.cantidad} onChange={(e) => handleInputChange(e, i)}
                                                            style={{marginRight: '0', textAlign: 'center'}}
                                                        />
                                                    </td>
                                                    <td style={{width: '10em'}}>
                                                        <Form.Control
                                                            type="text" placeholder="unidad" name="unidad" onChange={(e) => handleInputChange(e, i)}
                                                            value={row.unidad} style={{marginRight: '0'}}
                                                            className="text-center"
                                                        />
                                                    </td>
                                                    <td style={{width: '50%'}}>
                                                        <Form.Control
                                                            type="text" placeholder="Digite el suministro" name="id_supllies" 
                                                            value={row.id_supllies} style={{marginRight: '0'}}
                                                            className="text-center" onChange={(e) => handleInputChange(e, i)}
                                                        />
                                                        {/*<Form.Select
                                                            aria-label="Default select example" value={row.descripcion}
                                                            id={"select_supplies_"+row.id} name="id_supllies" onChange={(e) => handleInputChange(e, i)}
                                                            data-testid={i+1} className="me-2" 
                                                        >
                                                            <option>Seleccione un suministro</option>
                                                            {suppliescombo.datos === undefined ?
                                                                ''
                                                            :
                                                                suppliescombo.datos.map((supply, i) => (
                                                                    <option id={i+1+'_'+supply.id}
                                                                        data-measurementsName={supply.measurement} value={supply.id}
                                                                    >{supply.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Select>*/}
                                                    </td>
                                                    <td style={{width: '12em'}}>
                                                        <Form.Control
                                                                type="text" placeholder="destino" name="destino"
                                                                value={row.destino} onChange={(e) => handleInputChange(e, i)}
                                                                style={{marginRight: '0', textAlign: 'center'}}
                                                            />
                                                    </td>
                                                    <td>
                                                        <div className="text-danger text-center">
                                                            <MdIcons.MdDeleteForever 
                                                                style={{fontSize: '1.6em',cursor: 'pointer'}}
                                                                onClick={()=>eliminarFila(i)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12} className="border-dark border-bottom border-start border-top border-end page-break">
                            <div className="py-3">
                                <div>
                                    <span>
                                        <b>Observaciones</b>
                                    </span>
                                </div>
                                <div>
                                    <InputGroup>
                                        <Form.Control
                                            as="textarea" rezisable="false" id="observaciones" name="observaciones"
                                            placeholder="Observaciones" onChange={handleInputChangeObs}
                                            style={{ height: '5em',paddingTop: '0.5em' }}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom border-start border-end">
                            <Row>
                                <Col md={2} xs={2} className="border-dark border-end text-center py-1">
                                    <b>Fecha de Entrega:</b>
                                </Col>
                                <Col md={10} xs={10} className="border-dark py-1">
                                    <div></div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom border-start border-end">
                            <Row>
                                <Col md={2} xs={2} className="border-dark border-end text-center py-1">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Solicitado por</b>
                                            </span>
                                        </div>
                                        <div className="mt-2">
                                            <span>{user.name}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8} xs={8} className="border-dark border-end text-center py-1">
                                    <div className="py-1">
                                        <div>
                                            <span>
                                                <b>Cargo</b>
                                            </span>
                                        </div>
                                        <div>
                                            {dats.cargo}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} xs={2} className="border-dark text-center py-1">
                                    <div className="py-1">
                                        <span>
                                            <b>Firma</b>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} xs={4} className="pt-4">
                            <Row className="border border-dark ">
                                <Col md={12} xs={12} className="border-dark border-bottom">
                                    <b>Recibido por:</b>
                                </Col>
                                <Col md={12} xs={12} className="border-dark border-bottom">
                                    <b>Cargo:</b>
                                </Col>
                                <Col md={12} xs={12}>
                                    <b>Fecha:</b>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} xs={4}></Col>
                        <Col md={4} xs={4} className="pt-4">
                            <Row className="border border-dark ">
                                <Col md={12} xs={12} className="border-dark border-bottom">
                                    <b>Autorizado por:</b>
                                </Col>
                                <Col md={12} xs={12} className="border-dark border-bottom">
                                    <b>Cargo:</b>
                                </Col>
                                <Col md={12} xs={12}>
                                    <b>Fecha:</b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12} className="pt-4">
                            <Row>
                                <Col md={4} xs={4} className="pt-4 text-center">
                                    <div className="border-dark border-top">
                                        <b>Firma</b>
                                    </div>
                                </Col>
                                <Col md={4} xs={4}></Col>
                                <Col md={4} xs={4} className="pt-4 text-center">
                                    <div className="border-dark border-top">
                                        <b>Firma</b>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default RequisicionNueva;