import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Card, Button, InputGroup, FormControl, Container, Row, Col,
  Form,
} from 'react-bootstrap';
import {
    DEDALO_CREAR_USUARIO,DEDALO_COMBO_CONSTRUCTION,DEDALO_CRM_ENTERPRICES_COMBO,
    DEDALO_DEPARTAMENT_COMBO,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import { 
    BsFillPeopleFill, BsFillPersonFill, BsFillTelephoneFill
} from "react-icons/bs";
import { 
    MdSecurity, MdAddLocationAlt
} from "react-icons/md";
import { 
    FaUserCheck,
} from "react-icons/fa";
import Navbar from '../navbar';
import { Routes } from "../../utils/routes";
import Logo from '../../imagenes/dedalo_logo.png';
import * as FaIcons from "react-icons/fa";
//import Cookies from "universal-cookie";

function UsuarioNuevoSistema() {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();    
    const {userId} = useUserSession();
    const {user} = useUserSession(); 
    const [genero, setGenero] = useState('');
    const [poryectos, setProyectos] = useState('');
    const [rolusuario, setRolUsuario] = useState('');
    const [boton, setBoton] = useState(0);
    const [age, setAge] = useState();
    const [depselect, setDepSelect] = useState('');

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [comboemp, setComboEmp] = useState({
        datos: [],
    });

    const [combodep, setComboDep] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        correo: '',
        name: '',
        pass: '',
        c_pass: '',
        tel1: 0,
        tel2: 0,
        dir: '',
    });
    
    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeGenero = (event) => {
        setGenero(event.target.value);
    };

    const handleChangeRolUsuario = (event) => {
        setRolUsuario(event.target.value);
    };

    const handleChangeProyectos = (event) => {
        setProyectos(event.target.value);
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleChangeDepartment = (event) => {
        setDepSelect(event.target.value);
    };

    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let email = dats.correo;
        let name = dats.name;
        let pwd = dats.pass;
        let cpwd = dats.c_pass;
        let tel1 = dats.tel1;
        let tel2 = dats.tel2;
        let dir = dats.dir;
        let gen = genero;
        let proyecto = poryectos;
        
        const formattedData = {
            address: dir,
            c_password: cpwd,
            constructions_id: proyecto === '' ? 0 : parseInt(proyecto),
            email: email,
            enterprice_id: parseInt(age),
            image: '',
            name: name,
            password: pwd,
            phone1: tel1,
            phone2: tel2,
            role_id: parseInt(rolusuario),
            sex: gen,
            department: depselect,
        };
        //console.log(formattedData);
        if(name === ''){
            Swal.fire("No se pudo crear el usuario, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.post(DEDALO_CREAR_USUARIO, formattedData)
            axios({
                method: 'POST',
                url: DEDALO_CREAR_USUARIO,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el usuario exitósamente", "", "success");
                    document.getElementById('correo').value = '';
                    document.getElementById('name').value = '';
                    document.getElementById('pass').value = '';
                    document.getElementById('c_pass').value = '';
                    document.getElementById('tel1').value = '';
                    document.getElementById('tel2').value = '';
                    document.getElementById('dir').value = '';
                    document.getElementById('select').value = '';
                    document.getElementById('select_rol').value = '';
                    document.getElementById('selectproyecto').value = '';
                    document.getElementById('selectemp').value = '';
                    document.getElementById('selectdep').value = '';
                } else {
                  alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const peticionGetCategor=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_COMBO_CONSTRUCTION)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_CONSTRUCTION,
            headers: {Authorization: userToken},
            data: params_data,
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                Swal.fire("No hay proyectos asociados a la empresa "+data_emp.NomEmp, "", "warning");
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetComboEmp=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_COMBO_CONSTRUCTION)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CRM_ENTERPRICES_COMBO,
            headers: {Authorization: userToken},
            data: params_data,
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setComboEmp({
                    ...comboemp,
                    ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetComboDep=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_COMBO_CONSTRUCTION)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_DEPARTAMENT_COMBO,
            headers: {Authorization: userToken},
            data: params_data,
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setComboDep({
                    ...combodep,
                    ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetComboEmp();
        peticionGetComboDep();
    }, []);

    if(!user){
        window.location.href = Routes.LOGIN;
    }

    return (
        <div>
            {/*<Navbar modulo="Usuario Nuevo Dedalo" usuario={user.name}/>*/}
            <br/>
            <Container className="w-50">
                    <Row className="text-center">
                        <Col>
                            <img src={Logo} width="171" height="180"/>
                        </Col>
                    </Row>
                    <br/><br/>
                    <Card className="botonesaccion">
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", paddingTop: "5%"}}>
                                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                            <FormControl
                                                placeholder="Correo" aria-label="Correo"
                                                aria-describedby="basic-addon1"
                                                type="email" onChange={handleInputChange}
                                                id="correo" name="correo"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", paddingTop: "5%"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BsFillPersonFill/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Nombre" aria-label="Nombre"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="name" name="name"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdSecurity/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Contraseña" aria-label="Contraseña"
                                                aria-describedby="basic-addon1"
                                                type="password" onChange={handleInputChange}
                                                id="pass" name="pass"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdSecurity/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Confirmar Contraseña" aria-label="Confirmar Contraseña"
                                                aria-describedby="basic-addon1"
                                                type="password" onChange={handleInputChange}
                                                id="c_pass" name="c_pass"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BsFillTelephoneFill/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Telefono 1" aria-label="Telefono 1"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="tel1" name="tel1"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto",}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BsFillTelephoneFill/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Telefono 2" aria-label="Telefono 2"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="tel2" name="tel2"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdAddLocationAlt/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Dirección" aria-label="Dirección"
                                                aria-describedby="basic-addon1"
                                                type="text" onChange={handleInputChange}
                                                id="dir" name="dir"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BsFillPeopleFill/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeGenero} id="select" name="select"
                                            >
                                                <option value="">Seleccione una opcion</option>
                                                <option value="female">Mujer</option>
                                                <option value="male">Hombre</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto",}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <FaUserCheck/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeProyectos} id="selectproyecto" name="selectproyecto"
                                            >
                                                <option value="">Seleccione el Proyecto</option>
                                                {
                                                    categor.datos.map(i => (
                                                        <option key={i.id} value={i.id}>{i.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <BsFillPeopleFill/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeRolUsuario} id="select_rol" name="select_rol"
                                            >
                                                <option value="">Seleccione el rol</option>
                                                <option value={1}>Usuario</option>
                                                <option value={2}>Administrador</option>
                                                <option value={3}>Soporte</option>
                                                <option value={5}>Planos</option>
                                                <option value={6}>Almacenista</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                </Row>

                                <Row className="text-center">
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <FaIcons.FaBuilding/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChange} id="selectemp" name="selectemp"
                                            >
                                                <option value="">Seleccione una empresa</option>
                                                {comboemp.datos.map((i,index) => (
                                                    <option key={index} value={i.id}>{i.name}</option>
                                                ))}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <FaIcons.FaBuilding/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeDepartment} id="selectdep" name="selectdep"
                                            >
                                                <option value="">Seleccione el departamento</option>
                                                {combodep.datos.map((i,index) => (
                                                    <option key={index} value={i.id}>{i.name}</option>
                                                ))}
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                
                                <Row className="text-center" style={{marginTop: "5%"}}>
                                    <Col>
                                        <Button onClick={()=>handleCloseSave()} className="btn btn-success me-2" 
                                            style={{backgroundColor: "#02B371"}}>
                                            Crear Usuario
                                        </Button>
                                        <Button onClick={()=>{window.history.back()}} className="btn btn-success" 
                                            style={{backgroundColor: "#02B371"}}>
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>

                            </Card.Text>
                        </Card.Body>
                    </Card>
            </Container>
        </div>
    );
}

export default UsuarioNuevoSistema;
