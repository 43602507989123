import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, ListGroup ,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_JOBPROFILE,DEDALO_JOBPROFILE_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import {Mensaje_Error_PerfilCargo} from '../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import Img1 from '../imagenes_reportes/logo_geo.png';
import * as MdIcons from "react-icons/md";
import { enIE } from "date-fns/locale";

const Perfil_Cargo_Editar = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_Ficha_Tecnica'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();

    let {IdElementEdit} = useParams();

    const [dats, setDat] = useState({
        nom_cargo: '',
        fecha_creacion: '',
        jefe_inm: '',
        obj_cargo: '',
        exp_1: '',
        exp_2: '',
        hab_1: '',
        forma_1: '',
        edu_1: '',
        responsabilidad_1: '',
        funciones_1: '',
    });

    const peticionGetActasById=async()=>{
        let idacta = parseInt(IdElementEdit);
        //console.log(idacta);
        if(idacta === undefined){
            let re = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_JOBPROFILE_BY_ID+'&id='+idacta,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setDat({
                        ...dats,
                        ['nom_cargo']: datanotice.job_title,
                        ['fecha_creacion']: datanotice.creation_date,
                        ['jefe_inm']: datanotice.immediate_boss,
                        ['obj_cargo']: datanotice.objetive,
                        ['exp_1']: datanotice.general_experience,
                        ['exp_2']: datanotice.specific_experience,
                        ['hab_1']: datanotice.process,
                        ['forma_1']: datanotice.training,
                        ['edu_1']: datanotice.education,
                        ['responsabilidad_1']: datanotice.responsibilities,
                        ['funciones_1']: datanotice.functions,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos by_id");
                }
            });
        
            return info1;
        }
    };

    useEffect(() => {
        peticionGetActasById();
    }, []);

    const printContainer = useRef(null);
    const pages = document.getElementsByClassName('page');

    const createPDF = async () => {
        await html2PDF(pages, {
            jsPDF: {
                format: 'letter',
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY,
            },
            imageType: "image/jpeg",
            output: "./pdf/generate.pdf"
        });
    };

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleCloseSave = (e) => {
        let valor1 = parseInt(IdElementEdit);
        let valor2 = dats.valor_letras;

        const formattedData = {
            enterprice_id: parseInt(data_emp2.IdEmp),
            users_id: userId,
            job_title: dats.nom_cargo,
            creation_date: dats.fecha_creacion,
            process: dats.hab_1,
            immediate_boss: dats.jefe_inm,
            objetive: dats.obj_cargo,
            education: dats.edu_1,
            training: dats.forma_1,
            skills: dats.hab_1,
            general_experience: dats.exp_1,
            specific_experience: dats.exp_2,
            responsibilities: dats.responsabilidad_1,
            functions: dats.funciones_1,
        };
        console.log(formattedData);
        if(valor2 === ''){
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible guardar el anticipo', "info");
        }else{
            axios({
                method: 'PUT',
                url: DEDALO_UPDATE_JOBPROFILE+'&id='+valor1,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire("Se ha editado el item exitósamente", "", "success");
                    peticionGetActasById();
                }else {
                    Mensaje_Error_PerfilCargo(response.data.msg.error);
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}
                            onClick={handleCloseSave}
                        >
                            Generar perfil de cargo
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" 
                            onClick={() => createPDF()} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div>
                <div className="page page-1 container container-fluid" 
                    ref={printContainer} style={{padding: '1em 1em 1em 1em'}}
                >
                    <Row className="border border-dark mx-2"> {/*  cabecera del formato */}
                        <Col md={2} className="text-center py-1 border-end border-dark">
                            <img src={Img1} className='img-fluid' width={230}/>
                        </Col>
                        <Col md={8} className="d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <h3>
                                    <b>FORMATO PERFIL DE CARGO</b>
                                </h3>
                            </div>
                        </Col>
                        <Col md={2} className="text-center py-1 border-start border-dark">
                            <div>
                                Codigo: FO-TH-01-01
                            </div>
                            <div>
                                Fecha: 01/18/2013
                            </div>
                            <div>
                                Pagina 1 de 1
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12} className="text-center border-bottom border-dark" style={{background: '#AAB7B8'}}>
                            <b>NOMBRE DEL CARGO</b>
                        </Col>
                        <Col md={12} >
                            <FormControl
                                aria-label="Nombre de la cotizacion" value={dats.nom_cargo}
                                aria-describedby="basic-addon1" type="text" id="nom_cargo" name="nom_cargo"
                                className="border-0 text-center" onChange={handleInputChangeObs}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12} className="text-center border-bottom border-dark" style={{background: '#AAB7B8'}}>
                            <b>DATOS GENERALES</b>
                        </Col>
                        <Col md={12} className="text-center border-bottom border-dark" style={{background: '#AAB7B8'}}>
                            <Row>
                                <Col md={3} className="text-center border-end border-dark">
                                    <b>FECHA DE CREACION</b>
                                </Col>
                                <Col md={9} className="text-center">
                                    <b>JEFE INMEDIATO</b>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className="border-bottom border-dark">
                            <Row>
                                <Col md={3} className="text-center border-end border-dark">
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={new Date(dats.fecha_creacion).toLocaleDateString()}
                                        aria-describedby="basic-addon1" type="text" id="fecha_creacion" name="fecha_creacion"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                                <Col md={9} className="text-center">
                                    <FormControl
                                        aria-label="Nombre de la cotizacion" value={dats.jefe_inm}
                                        aria-describedby="basic-addon1" type="text" id="jefe_inm" name="jefe_inm"
                                        className="border-0 text-center" onChange={handleInputChangeObs}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className="text-center border-bottom border-dark" style={{background: '#AAB7B8'}}>
                            <b>OBJETIVO DEL CARGO</b>
                        </Col>
                        <Col md={12} >
                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                id="obj_cargo" name="obj_cargo" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                className='border-0' value={dats.obj_cargo}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12} className="text-center border-bottom border-dark" style={{background: '#AAB7B8'}}>
                            <b>PERFIL DEL CARGO</b>
                        </Col>
                        <Col md={12} className="border-bottom border-dark">
                            <Row>
                                <Col md={1} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <b>1.</b>
                                </Col>
                                <Col md={2} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <b>EDUCACION</b>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                                id="edu_1" name="edu_1" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                                className='border-0' value={dats.edu_1}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className="border-bottom border-dark">
                            <Row>
                                <Col md={1} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <b>2.</b>
                                </Col>
                                <Col md={2} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <b>FORMACION</b>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                                id="forma_1" name="forma_1" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                                className='border-0' value={dats.forma_1}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className="border-bottom border-dark">
                            <Row>
                                <Col md={1} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <b>3.</b>
                                </Col>
                                <Col md={2} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <b>HABILIDADES</b>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                                id="hab_1" name="hab_1" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                                className='border-0' value={dats.hab_1}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col md={1} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <b>4.</b>
                                </Col>
                                <Col md={2} className='border-end border-dark d-flex justify-content-center align-items-center'>
                                    <b>EXPERIENCIA</b>
                                </Col>
                                <Col md={9}>
                                    <Row className="border-bottom border-dark" style={{background: '#AAB7B8'}}>
                                        <Col md={12}>
                                            <b>GENERAL</b>
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom border-dark">
                                        <Col md={12}>
                                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                                id="exp_1" name="exp_1" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                                className='border-0' value={dats.exp_1}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="border-bottom border-dark" style={{background: '#AAB7B8'}}>
                                        <Col md={12}>
                                            <b>ESPECIFICA</b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                                id="exp_2" name="exp_2" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                                className='border-0' value={dats.exp_2}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12} className="text-center border-bottom border-dark" style={{background: '#AAB7B8'}}>
                            <b>RESPONSABILIDADES Y FUNCIONES</b>
                        </Col>
                        <Col md={12} className="text-center border-bottom border-dark" style={{background: '#AAB7B8'}}>
                            <Row>
                                <Col md={5} className="text-center border-end border-dark">
                                    <b>RESPONSABILIDADES</b>
                                </Col>
                                <Col md={7} className="text-center">
                                    <b>FUNCIONES</b>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row className="border-bottom border-dark">
                                <Col md={5} className="text-center border-end border-dark">
                                    <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                        id="responsabilidad_1" name="responsabilidad_1" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                        className='border-0' rows={20} value={dats.responsabilidad_1}
                                    />
                                </Col>
                                <Col md={7} className="text-center">
                                    <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                            id="funciones_1" name="funciones_1" style={{fontSize: '1.2em',textAlign: 'justify'}}
                                            className='border-0' rows={20} value={dats.funciones_1}
                                        />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <Row className="border border-dark mx-2">
                        <Col md={12} className="border-bottom border-dark">
                            <Row>
                                <Col md={6} className="border-end border-dark">
                                    <div style={{height: '2em'}}></div>
                                </Col>
                                <Col md={6}>
                                    <div style={{height: '2em'}}></div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col md={6} className="border-end border-dark text-center" style={{background: '#AAB7B8'}}>
                                    <b>FIRMA JEFE TALENTO HUMANO</b>
                                </Col>
                                <Col md={6} className="text-center" style={{background: '#AAB7B8'}}>
                                    <b>FIRMA EMPLEADO</b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Perfil_Cargo_Editar;