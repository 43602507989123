import React, {useState, Fragment, useEffect} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Container,Card
} from 'react-bootstrap';
import {
    DEDALO_CONSTRUCCION_BY_ID,
} from "../../../assets/APIAdresses";
import { Routes } from '../../../utils/routes';
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import Swal from "sweetalert2";
import Img1 from '../imagenes_iconos/compras3.png';
import Img2 from '../imagenes_iconos/orden.png';
import * as MdIcons from "react-icons/md";

const Menu_Ficha_Tecnica = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();   
    const {user} = useUserSession();
    const {IdProjectFicha} = useParams();

    const [dats_edit, setDat_Edit] = useState({
        name_edit: '',
        dir_edit: '',
        product_type_id_edit: '',
        crm_vendors_id_edit: '',
        productTypeName_edit: '',
        crmVendorName_edit: '',
    });

    const peticionGetProductById=async()=>{
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_BY_ID+'&id='+parseInt(IdProjectFicha),
            headers: {Authorization: userToken},
        })
        .then(response=>{
           if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setDat_Edit({
                    ...dats_edit,
                    ['name_edit']: datanotice.data.name,
                    ['dir_edit']: datanotice.data.address,
                    ['product_type_id_edit']: datanotice.data.product_type_id,
                    ['crm_vendors_id_edit']: datanotice.data.crm_vendors_id,
                    ['productTypeName_edit']: datanotice.data.productTypeName,
                    ['crmVendorName_edit']: datanotice.data.crmVendorName,
                });
             } else {
                alert(message_responses[response.data.message]);
             }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
     
       return info1;
    };

    useEffect(() => {
        peticionGetProductById();
    }, []);

    localStorage.setItem("Project_Ficha_Tecnica", JSON.stringify(
        {
            Id_Project_Conta: parseInt(IdProjectFicha),
            name_construction: dats_edit.name_edit,
            dir_construction: dats_edit.dir_edit,
        }
    ));

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-center mt-4">
                <div>
                    <h2 className="text-center">Menu Ficha Tecnica</h2>
                </div>
            </div>
            <hr/>
            <div>
                <Container>
                    <Row>
                        <Col md={3} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.CUMPLIMIENTO_CRONO}>
                                    <a style={{cursor: 'pointer',textAlign: 'center'}} title="COMPRAS">
                                        <img src={Img1} width="60%" alt="prueba"/>
                                    </a>
                                </Link>
                                <br/>
                                <b className="mt-4">Cumplimiento de cronograma</b>
                            </div>
                        </Col>
                        <Col md={3} xs={6} className="py-4">
                            <div className="text-center">
                                <Link to={Routes.ELEMENTOS_NO_CONFORMES}>
                                    <a style={{cursor: 'pointer',textAlign: 'center'}} title="ORDEN DE COMPRA">
                                        <img src={Img2} width="56%" alt="prueba"/>
                                    </a>
                                </Link>
                                <br/>
                                <b className="mt-4">Elementos no conformes</b>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Menu_Ficha_Tecnica;