import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Button, InputGroup, FormControl, Container, Row, Col,
  Form, Table,Card,Modal,Accordion
} from 'react-bootstrap';
import {
    DEDALO_TASK_CONSTRUCTION_REPORT,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import { Routes } from "../../utils/routes";
import html2PDF from 'jspdf-html2canvas';
import Imgfondo from '../../imagenes/dedalo_logo.png'
import * as Mdicons from 'react-icons/md';
import Navbar from '../navbar';
import logo from '../../imagenes/dedalo_logo.png';

const Reporte = () => {
    const {userId} = useUserSession();
    const {userToken} = useUserSession();
    const {user} = useUserSession();
    const datareporte = JSON.parse(localStorage.getItem('Reporte'));
    const [fechaseleccionada, setFechaSeleccionada] = useState(new Date());
    const [fechaseleccionada1, setFechaSeleccionada1] = useState(new Date());

    const [categor, setCategor] = useState({
        datos_task: [],
        datos_task_comments: [],
        datos_task_response_comments: [],
        datos_subtask: [],
    });

    let dia = fechaseleccionada.getDate();
    let mes = fechaseleccionada.getMonth()+1;
    let año = fechaseleccionada.getFullYear();
    let hora = fechaseleccionada.getHours();
    let minutos = fechaseleccionada.getMinutes();
    let segundos = fechaseleccionada.getSeconds();
    let fecha = año+'-'+mes+'-'+dia;

    let fechafinal = fecha+' '+hora+':'+minutos+':'+segundos;

    let dia1 = fechaseleccionada1.getDate();
    let mes1 = fechaseleccionada1.getMonth()+1;
    let año1 = fechaseleccionada1.getFullYear();
    let hora1 = fechaseleccionada1.getHours();
    let minutos1 = fechaseleccionada1.getMinutes();
    let segundos1 = fechaseleccionada1.getSeconds();
    let fecha1 = año1+'-'+mes1+'-'+dia1;

    let fechafinal1 = fecha1+' '+hora1+':'+minutos1+':'+segundos1;

    const peticionGetCategor=async()=>{
        const params_data = {
            creator_id: datareporte.creator_id,
            startdate: datareporte.startdate,
            endate: datareporte.endate,
            //creator_id: 47,
            //startdate: "2022-1-26",
            //endate: "2022-1-31",
        };
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS_BY_ID+'&id='+id)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_TASK_CONSTRUCTION_REPORT,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos_task']: datanotice.data,
                    ['datos_task_comments']: datanotice.data[0].TaskComment,
                    ['datos_subtask']: datanotice.data[0].SubTask,
                });
                handleClose2();
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
            }
        });
      
        return info1;
    }
    
    /*useEffect(() => {
        peticionGetCategor();
    }, [datareporte.creator_id]);*/

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const fecha_comentario = (datadate) =>{
        let fecha_comentarios = new Date(datadate);
        let dia_comentario = fecha_comentarios.getDate();
        let mes_comentario = fecha_comentarios.getMonth()+1;
        let año_comentario = fecha_comentarios.getFullYear();
        let fecha_res = dia_comentario+'-'+mes_comentario+'-'+año_comentario;

        return fecha_res;
    };

    const pages = document.getElementsByClassName('reporte_pdf');

    const imprimir_pdf_2 = () => {
        html2PDF(pages, {
            jsPDF: {
              format: 'a4',
            },
            imageType: 'image/jpeg',
            output: 'reporte_general.pdf'
        });
    };

    return (
        <div>
            <div className="w-100">
                <br/>
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start btnreport">
                    <Button className="btn btn-success rounded-circle regresar" size="sm" onClick={()=>{window.history.back()}}
                        title="Regresar"
                    >
                        <Mdicons.MdArrowBackIosNew/>
                    </Button>
                    <Button className="botonpersonal me-2 nuevo" size="sm" onClick={imprimir_pdf_2}>
                        Descargar reporte PDF
                    </Button>
                </div>
                <div className="container-fluid flex-grow-1 container-p-y">
                    <div className="mb-3 text-center">
                        <h4 className="font-weight-bold mb-0 titulo1">
                            Informe General
                        </h4>
                    </div>
                    <hr/>
                    <div className="reporte_pdf" style={{padding: '2em'}}>
                        <Row>
                            <Col md={6} xs={12}>
                                <div eventKey="0"> {/* INFORMACION DE LAS TAREAS */}
                                    <div className="text-center">
                                        <h4>Tareas</h4>
                                    </div>
                                    {categor.datos_task.map((itemTask, indexTask) => (
                                        <div key={indexTask}>
                                            <h4 className="font-weight-bold mb-0 titulo1">
                                                Tarea {itemTask.title}
                                            </h4>
                                            <Row>
                                                <Col md={6} xs={12}>
                                                    Proyecto: <b>{itemTask.nameConstruction}</b> <br/>
                                                    Creado por: <b>{itemTask.nameCreator}</b> <br/>
                                                    Fecha de inicio: <b>{itemTask.startdate}</b> <br/>
                                                    Fecha de fin: <b>{itemTask.deadline}</b> <br/>
                                                    Presupuesto: <b>{
                                                        itemTask.budget === '' ? 
                                                            formatterPeso.format(0)
                                                        : 
                                                            formatterPeso.format(itemTask.budget)
                                                        }</b> <br/>
                                                    Presupuesto ejecutado: <b>{
                                                        itemTask.executed_budget === '' ?
                                                            formatterPeso.format(0)
                                                        :
                                                            formatterPeso.format(itemTask.executed_budget)
                                                    }</b> <br/>
                                                    Descripcion: <b>{itemTask.description}</b> <br/>
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    Medio: <b>{itemTask.means}</b> <br/>
                                                    Personal: <b>{itemTask.personal}</b> <br/>
                                                    Implementos: <b>{itemTask.supplies}</b> <br/>
                                                    Observaciones: <b>{itemTask.observations}</b> <br/>
                                                    Prioridad: <b>{
                                                        itemTask.priority === 'high' ?
                                                            'Alta'
                                                        :itemTask.priority === 'medium' ?
                                                            'Medio'
                                                        :
                                                            'Bajo'
                                                    }</b> <br/>
                                                    Estado: <b>{
                                                        itemTask.statusTask === 'pending' ?
                                                            'Pendiente'
                                                        :itemTask.statusTask === 'in process' ?
                                                            'En proceso'
                                                        :itemTask.statusTask === 'under review' ?
                                                            'En revisión'
                                                        :itemTask.statusTask === 'completed' ?
                                                            'Completado'
                                                        :itemTask.statusTask === 'closed' ?
                                                            'Cerrado'
                                                        :itemTask.statusTask === 'canceled' ?
                                                            'Cancelado'
                                                        :
                                                            'Reabierto'
                                                    }</b> <br/>
                                                </Col>
                                            </Row>
                                            <hr/>
                                            <Row>
                                                <Col md={12} xs={12}>
                                                    <h4 className="font-weight-bold mb-0 titulo1 text-center">
                                                        Comentrarios
                                                    </h4>
                                                    <div className=" mt-2">
                                                        {categor.datos_task_comments.map((r,rindex) => (
                                                            <div class="d-flex justify-content-between mb-2">
                                                                <div class="d-flex justify-content-between mb-2" key={rindex}>
                                                                    <div>
                                                                        <img src={r.image === '' ? Imgfondo : r.image} className="me-2" 
                                                                            style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                                    </div>
                                                                    <div style={{fontSize: "1em"}}>
                                                                        <b style={{color: "#d35400"}}>
                                                                            {r.creatorName}
                                                                        </b> {fecha_comentario(r.date)}
                                                                        <br/>
                                                                        {r.comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col md={6} xs={12}>
                                <div> {/* INFORMACION DE LAS SUBTAREAS */}
                                    <div className="text-center">
                                        <h4>Sub Tareas</h4>
                                    </div>
                                    {categor.datos_subtask.length === 0 ? 
                                        <div className="text-center">
                                            <h4>No hay subtareas registradas</h4>
                                        </div>
                                    :
                                        categor.datos_subtask.map((itemSubTask, indexSubTask) => (
                                            <div key={indexSubTask}>
                                                <h5 className="font-weight-bold mb-0 titulo1">
                                                    Sub Tarea {itemSubTask.title}
                                                </h5>
                                                <Row>
                                                    <Col md={6} xs={12}>
                                                        Proyecto: <b>{itemSubTask.nameConstruction}</b> <br/>
                                                        Creado por: <b>{itemSubTask.nameCreator}</b> <br/>
                                                        Fecha de inicio: <b>{itemSubTask.startdate}</b> <br/>
                                                        Fecha de fin: <b>{itemSubTask.deadline}</b> <br/>
                                                        Presupuesto: <b>{
                                                            itemSubTask.budget === '' ? 
                                                                formatterPeso.format(0)
                                                            : 
                                                                formatterPeso.format(itemSubTask.budget)
                                                            }</b> <br/>
                                                        Presupuesto ejecutado: <b>{
                                                            itemSubTask.executed_budget === '' ?
                                                                formatterPeso.format(0)
                                                            :
                                                                formatterPeso.format(itemSubTask.executed_budget)
                                                        }</b> <br/>
                                                        Descripcion: <b>{itemSubTask.description}</b> <br/>
                                                    </Col>
                                                    <Col md={6} xs={12}>
                                                        Observaciones: <b>{itemSubTask.observations}</b> <br/>
                                                        Prioridad: <b>{
                                                            itemSubTask.priority === 'high' ?
                                                                'Alta'
                                                            :itemSubTask.priority === 'medium' ?
                                                                'Medio'
                                                            :
                                                                'Bajo'
                                                        }</b> <br/>
                                                        Estado: <b>{
                                                            itemSubTask.statusSubTask === 'pending' ?
                                                                'Pendiente'
                                                            :itemSubTask.statusSubTask === 'in process' ?
                                                                'En proceso'
                                                            :itemSubTask.statusSubTask === 'under review' ?
                                                                'En revisión'
                                                            :itemSubTask.statusSubTask === 'completed' ?
                                                                'Completado'
                                                            :itemSubTask.statusSubTask === 'closed' ?
                                                                'Cerrado'
                                                            :itemSubTask.statusSubTask === 'canceled' ?
                                                                'Cancelado'
                                                            :
                                                                'Reabierto'
                                                        }</b> <br/>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                                <Row>
                                                    <Col md={12} xs={12}>
                                                        <h4 className="font-weight-bold mb-0 titulo1 text-center">
                                                            Comentrarios
                                                        </h4>
                                                        <div className=" mt-2">
                                                            {itemSubTask.SubTaskComment.length === 0 ?
                                                                <div className="text-center">
                                                                    <h4>No hay comentarios registrados</h4>
                                                                </div>
                                                            :
                                                                itemSubTask.SubTaskComment.map((r,rindex) => (
                                                                    <div class="d-flex justify-content-between mb-2">
                                                                        <div class="d-flex justify-content-between mb-2" key={rindex}>
                                                                            <div>
                                                                                <img src={r.image === '' ? Imgfondo : r.image} className="me-2" 
                                                                                    style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                                            </div>
                                                                            <div style={{fontSize: "0.8em"}}>
                                                                                <b style={{color: "#d35400"}}>
                                                                                    {r.creatorName}
                                                                                </b> {fecha_comentario(r.date)}
                                                                                <br/>
                                                                                {r.comment}
                                                                                {r.SubTaskResponse.map((rr, rrindex) => (
                                                                                    <div class="d-flex justify-content-between mt-2">
                                                                                        <div class="d-flex justify-content-between mt-2" key={rrindex}>
                                                                                            <div>
                                                                                                <img src={Imgfondo} className="me-2" 
                                                                                                    style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                                                            </div>
                                                                                            <div style={{fontSize: "1em"}}>
                                                                                                <b style={{color: "#d35400"}}>
                                                                                                    {rr.creatorName}
                                                                                                </b> {fecha_comentario(r.date)}
                                                                                                <br/>
                                                                                                <p >
                                                                                                    {rr.response}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );   
}

export default Reporte;