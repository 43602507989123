import _strings from "./spanish.json";

class StringsClass {
  constructor() {
    this._strings = _strings;
  }

  get(key) {
    return this._strings[key];
  }
}

const Strings = new StringsClass();

export default Strings;
