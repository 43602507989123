import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,Dropdown,DropdownButton,
} from 'react-bootstrap';
import {
    DEDALO_ALL_PERSONAL_DEDALO,DEDALO_NEW_PERSONAL_DEDALO,DEDALO_CRM_ENTERPRICES_COMBO,
    DEDALO_PERSONAL_DEDALO_COMBO,DEDALO_DEL_PERSONAL,
    DEDALO_EDIT_PERSONAL,DEDALO_PERSONAL_BY_ID,DEDALO_DELETE_PERSONAL_ENTERPRICE,
    DEDALO_UNDELETE_PERSONAL_ENTERPRICE,DEDALO_ALL_PERSONAL_DISCHARGED,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../utils/routes';
import Navbar from '../navbar';
import Swal from "sweetalert2";
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";


const TablaPersonal = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [genero, setGenero] = useState('');
    const [arl, setArl] = useState('');
    const [eps, setEps] = useState('');
    const [emp, setEmp] = useState('');
    const [pension, setPension] = useState('');
    const [personaledit, setPersonalEdit] = useState();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [enterpricecombo, setEnterpriceCombo] = useState({
        datos: [],
    });

    const [allpersonaldiscarged, setAllPersonalDischarged] = useState({
        datos: [],
    });

    const [personalcombo, setPersonalCombo] = useState({
        eps: [],
        arl: [],
        pensiones: [],
    });

    const [dats, setDat] = useState({
        name: '',
        dir: '',
        email: '',
        tel1: '',
        tel2: '',
        tipo_doc: '',
        num_doc: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        name_edit: '',
        dir_edit: '',
        email_edit: '',
        tel_edit1: '',
        tel_edit2: '',
        tipo_doc_edit: '',
        eps_edit: '',
        pension_edit: '',
        arl_edit: '',
        sex_edit: '',
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PERSONAL_DEDALO+'?page=1&rows=100&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_PERSONAL_DEDALO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                ...categor,
                ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetAllPersonalDischarged=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PERSONAL_DEDALO+'?page=1&rows=100&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_PERSONAL_DISCHARGED,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.data);
                setAllPersonalDischarged({
                    ...allpersonaldiscarged,
                    ['datos']: datanotice.records,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetPersonalCombo=async()=>{
        //let info1 = await axios.get(DEDALO_PERSONAL_DEDALO_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_PERSONAL_DEDALO_COMBO,
            headers: {Authorization: userToken},
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setPersonalCombo({
                ...personalcombo,
                ['eps']: datanotice.Eps,
                ['arl']: datanotice.Arls,
                ['pensiones']: datanotice.Pensiones,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetPersonalById=async()=>{
        if(personaledit === undefined){
            let oi = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_TASK_COMMENTS_RESP+'?construction_task_comments_id='+commentsId)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_PERSONAL_BY_ID+'&id='+personaledit,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice);
                    setDat_Edit({
                        ...dats_edit,
                        ['name_edit']: datanotice.data.name,
                        ['dir_edit']: datanotice.data.address,
                        ['email_edit']: datanotice.data.email,
                        ['tel_edit1']: datanotice.data.phone1,
                        ['tel_edit2']: datanotice.data.phone2,
                        ['tipo_doc_edit']: datanotice.data.document_type,
                        ['num_doc_edit']: datanotice.data.document,
                        ['eps_edit']:datanotice.data.eps,
                        ['pension_edit']:datanotice.data.pension,
                        ['arl_edit']:datanotice.data.arl,
                        ['sex_edit']:datanotice.data.sex,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
                } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const peticionGetEnterpriceCombo=async()=>{
        //let info1 = await axios.get(DEDALO_ALL_PERSONAL_DEDALO+'?page=1&rows=100&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CRM_ENTERPRICES_COMBO,
            headers: {Authorization: userToken},
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setEnterpriceCombo({
                    ...enterpricecombo,
                    ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetPersonalCombo();
        peticionGetAllPersonalDischarged();
        peticionGetEnterpriceCombo();
        //peticionGetPersonalById();
    }, []);

    useEffect(() => {
        peticionGetPersonalById();
    }, [personaledit]);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setPersonalEdit(e);
        setShow3(true);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (e) => {
        setShow4(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {   
        setDat_Edit({
            ...dats_edit,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeSelectG = (event) => {
        setGenero(event.target.value);
    };

    const handleChangeSelectA = (event) => {
        setArl(event.target.value);
    };

    const handleChangeSelectE = (event) => {
        setEps(event.target.value);
    };

    const handleChangeSelectP = (event) => {
        setPension(event.target.value);
    };

    const handleChangeSelectEmp = (event) => {
        setEmp(event.target.value);
    };

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };

    const handleCloseSave = (e) => {
    
        let name = dats.name;
        let dir = dats.dir;
        let type_doc = dats.tipo_doc;
        let doc = dats.num_doc;
        let email = dats.email;
        let eps_personal = eps;
        let arl_personal = arl;
        let pension_personal = pension;
        let tel1 = dats.tel1;
        let tel2 = dats.tel2;
        let genero_personal = genero;
        
        const formattedData = {
            enterprice_id: data_emp.IdEmp,
            name: name,
            address: dir,
            document_type: type_doc,
            document: doc,
            email: email,
            eps: eps_personal,
            arl: arl_personal,
            pension: pension_personal,
            phone1: tel1,
            phone2: tel2,
            sex: genero_personal,
        };
        //console.log(formattedData);
        if(name === '' || type_doc === '' || doc === '' || tel1 === '' || tel2 === '' || dir === '') {
            Swal.fire("No se pudo crear el personal, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.post(DEDALO_NEW_PERSONAL_DEDALO, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NEW_PERSONAL_DEDALO,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el personal exitósamente", "", "success");
                    document.getElementById('name').value = '';
                    document.getElementById('dir').value = '';
                    document.getElementById('tipo_doc').value = '';
                    document.getElementById('num_doc').value = '';
                    document.getElementById('email').value = '';
                    document.getElementById('tel1').value = '';
                    document.getElementById('tel2').value = '';
                    document.getElementById('select_arl').value = '';
                    document.getElementById('select_eps').value = '';
                    document.getElementById('select_pensiones').value = '';
                    document.getElementById('select').value = '';
                    peticionGetCategor();
                    handleClose2();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
            
        }
    };

    const handleCloseSaveEdit = (e) => {
    
        let name_edit = dats_edit.name_edit;
        let dir_edit = dats_edit.dir_edit;
        let type_doc_edit = dats_edit.tipo_doc_edit;
        let doc_edit = dats_edit.num_doc_edit;
        let email_edit = dats_edit.email_edit;
        let eps_personal_edit = eps;
        let arl_personal_edit = arl;
        let pension_personal_edit = pension;
        let tel1_edit = dats_edit.tel_edit1;
        let tel2_edit = dats_edit.tel_edit2;
        let genero_personal_edit = genero;
        
        const formattedData = {
            name: name_edit,
            address: dir_edit,
            document_type: type_doc_edit,
            document: doc_edit,
            email: email_edit,
            eps: eps_personal_edit,
            arl: arl_personal_edit,
            pension: pension_personal_edit,
            phone1: tel1_edit,
            phone2: tel2_edit,
            sex: genero_personal_edit,
        };
        //console.log(formattedData);
        if(name_edit === ''){
            Swal.fire("No se pudo editar el personal, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.put(DEDALO_EDIT_PERSONAL(personaledit), formattedData, attachToken(user))
            axios({
                method: 'PUT',
                url: DEDALO_EDIT_PERSONAL+'&id='+personaledit,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el personal exitósamente", "", "success");
                    peticionGetCategor();
                    handleClose3();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
            
        }
    };

    const handleCloseSaveActive = (idactive) => {
        const formattedData = {
            id: idactive,
            enterprice_id: data_emp.IdEmp,
        };
        //axios.put(DEDALO_USERS_ACTIVE(idactive))
        axios({
            method: 'POST',
            url: DEDALO_UNDELETE_PERSONAL_ENTERPRICE,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se a activado el usuario exitósamente", "", "success");
                peticionGetAllPersonalDischarged();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const handleCloseSaveInactive = (idInactive) => {
        const formattedData = {
            id: idInactive,
            enterprice_id: data_emp.IdEmp, 
        };
        //axios.put(DEDALO_USERS_INACTIVE(idInactive))
        if(idInactive === undefined){
            let ou = 0;
        }else{
            axios({
                method: 'POST',
                url: DEDALO_DELETE_PERSONAL_ENTERPRICE,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response=>{
                if (response.data.code === '200') {
                    Swal.fire("Se a Inactivado el usuario exitósamente", "", "success");
                    peticionGetAllPersonalDischarged();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const peticionDelete = (iduser) => {
        let delId = iduser;
            
        //axios.delete(DEDALO_DEL_PERSONAL(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_PERSONAL+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el dato exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = categor.datos === undefined ? [] : categor.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = categor.datos === undefined ? [] : categor.datos.length/postPerPage; // numero total de paginas
    let pageNumbers = []; //se guardan los numeros de paginas

    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () =>{
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () =>{
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };
    
    const paginador = () =>{
        return(
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{cursor: 'pointer'}}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div>
            <br/>
            <div className="container-fluid">
                <Dropdown >
                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" size="sm">
                        Acciones personal
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="dark">
                        <Dropdown.Item onClick={handleShow2}>
                            Nuevo personal dedalo
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleShow4}>
                            Ver Personal debaja
                        </Dropdown.Item>
                        {user.role_id === 2 || user.role_id === 3 ?
                            <Dropdown.Item>
                                <Link to={Routes.PERFIL_CARGO} className='text-decoration-none'>
                                    Perfil de cargo
                                </Link>
                            </Dropdown.Item>
                        :
                            ''
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="container-fluid d-grid gap-2 mt-4 cardheaderimg2">
                <Table responsive bordered hover size="sm">
                    <thead className="bg-primary text-light">
                        <tr className="text-center bg-success">
                            <th colspan="12">
                                personal de {data_emp.NomEmp}
                            </th>
                        </tr>
                        <tr className="text-center">
                            <th>Usuario</th>
                            <th>Correo Usuario</th>
                            <th style={{width: "2em"}}>Tipo documento</th>
                            <th style={{width: "5em"}}>Documento Usuario</th>
                            <th>Telefono 1</th>
                            <th>Telefono 2</th>
                            <th>Direccion</th>
                            <th>Genero</th>
                            <th style={{width: "18em"}}>EPS</th>
                            <th>ARL</th>
                            <th style={{width: "18em"}}>Pensiones</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody style={{fontSize: "0.87em"}}>
                        {currentPost.map(i => (
                            <tr className="text-center">
                                <td>{i.name}</td>
                                <td>{i.email}</td>
                                <td>{i.document_type}</td>
                                <td>{i.document}</td>
                                <td>{i.phone1}</td>
                                <td>{i.phone2}</td>
                                <td>{i.address}</td>
                                <td>{i.sex === 'female' ? 'Mujer' : 'Hombre'}</td>
                                <td>{i.eps}</td>
                                <td>{i.arl}</td>
                                <td>{i.pension}</td>
                                <td>
                                    <div className="opciones">
                                        <div class="dropdown">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" 
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                    <a class="dropdown-item cursor-pointer" onClick={()=>handleShow3(i.id)}>
                                                        Editar
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item cursor-pointer" onClick={()=>peticionDelete(i.id)}>
                                                        Eliminar
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item cursor-pointer" onClick={()=>handleCloseSaveInactive(i.id)}>
                                                        Dar de baja
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {paginador()}
            </div>
            {/* MODAL CREAR PERSONAL */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Crear Personal Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombres y Apellidos" aria-label="Nombres y Apellidos"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="name" name="name"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Dirección del Personal" aria-label="Dirección del Personal"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="dir" name="dir"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Email" aria-label="Email"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="email" name="email"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono" aria-label="Telefono"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="tel1" name="tel1"
                                />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={5} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono 2" aria-label="Telefono 2"
                                    aria-describedby="basic-addon1" 
                                    type="text" onChange={handleInputChange}
                                    id="tel2" name="tel2"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Tipo Doc." aria-label="Tipo de Documento"
                                    aria-describedby="basic-addon1" 
                                    type="text" onChange={handleInputChange}
                                    id="tipo_doc" name="tipo_doc"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Num. Documento" aria-label="Num. Documento"
                                    aria-describedby="basic-addon1" 
                                    type="text" onChange={handleInputChange}
                                    id="num_doc" name="num_doc"
                                />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectA} id="select_arl" name="select_arl"
                                >
                                    <option value="">Seleccione Arl</option>
                                    {personalcombo.eps.map(i => (
                                        <option value={i}>{i}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectE} id="select_eps" name="select_eps"
                                >
                                    <option value="">Seleccione EPS</option>
                                    {personalcombo.arl.map(i => (
                                        <option value={i}>{i}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectP} id="select_pensiones" name="select_pensiones"
                                >
                                    <option value="">Seleccione Pensiones</option>
                                    {personalcombo.pensiones.map(i => (
                                        <option value={i}>{i}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectG} id="select" name="select"
                                >
                                    <option value="">
                                        Seleccione una opcion
                                    </option>
                                    <option value='male'>Hombre</option>
                                    <option value='female'>Mujer</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        {/*<Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectEmp} id="select" name="select"
                                >
                                    <option value="">
                                        Seleccione una empresa
                                    </option>
                                    {enterpricecombo.datos.map((em,em_index) => (
                                        <option key={em_index} value={em.id}>{em.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>*/}
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={()=>handleCloseSave()}>
                        Crear Personal
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR PERSONAL */}
            <Modal show={show3} onHide={handleClose3} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Personal Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombres y Apellidos" aria-label="Nombres y Apellidos"
                                    aria-describedby="basic-addon1" value={dats_edit.name_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="name_edit" name="name_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Dirección del Personal" aria-label="Dirección del Personal"
                                    aria-describedby="basic-addon1" value={dats_edit.dir_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="dir_edit" name="dir_edit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Email" aria-label="Email"
                                    aria-describedby="basic-addon1" value={dats_edit.email_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="email_edit" name="email_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono 1" aria-label="Telefono 1"
                                    aria-describedby="basic-addon1" value={dats_edit.tel_edit1}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tel_edit1" name="tel_edit1"
                                />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={5} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono 2" aria-label="Telefono 2"
                                    aria-describedby="basic-addon1" value={dats_edit.tel_edit2}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tel_edit2" name="tel_edit2"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Tipo Doc." aria-label="Tipo de Documento"
                                    aria-describedby="basic-addon1" value={dats_edit.tipo_doc_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tipo_doc_edit" name="tipo_doc_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Num. Documento" aria-label="Num. Documento"
                                    aria-describedby="basic-addon1" value={dats_edit.num_doc_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="num_doc_edit" name="num_doc_edit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectA} id="select_arl_edit" name="select_arl_edit"
                                >
                                    <option value="">{dats_edit.arl_edit}</option>
                                    {personalcombo.eps.map(i => (
                                        <option value={i}>{i}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectE} id="select_eps_edit" name="select_eps_edit"
                                >
                                    <option value="">{dats_edit.eps_edit}</option>
                                    {personalcombo.arl.map(i => (
                                        <option value={i}>{i}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillPersonFill/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectP} id="select_pensiones_edit" name="select_pensiones_edit"
                                >
                                    <option value="">{dats_edit.pension_edit}</option>
                                    {personalcombo.pensiones.map(i => (
                                        <option value={i}>{i}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdAddLocationAlt/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeSelectG} id="select_edit" name="select_edit"
                                >
                                    <option value="">
                                        {dats_edit.sex_edit === 'male' ? 'Hombre' : 'Mujer'}
                                    </option>
                                    <option value='male'>Hombre</option>
                                    <option value='female'>Mujer</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={()=>handleCloseSaveEdit()}>
                        Editar Personal
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL PERSONAL DADO DE BAJA */}
            <Modal show={show4} onHide={handleClose4} size="xl" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Personal Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="cardheaderimg2" style={{maxHeight: '30em', overflowY: 'auto'}}>
                        <Table responsive bordered hover size="sm" >
                            <thead className="bg-primary text-light">
                                <tr className="text-center">
                                    <th>Usuario</th>
                                    <th>Correo Usuario</th>
                                    <th style={{width: "2em"}}>Tipo documento</th>
                                    <th style={{width: "5em"}}>Documento Usuario</th>
                                    <th>Telefono 1</th>
                                    <th>Telefono 2</th>
                                    <th>Direccion</th>
                                    <th>Genero</th>
                                    <th style={{width: "18em"}}>EPS</th>
                                    <th>ARL</th>
                                    <th style={{width: "18em"}}>Pensiones</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody style={{fontSize: "0.87em"}}>
                                {allpersonaldiscarged.datos === undefined ?
                                    ''
                                :
                                    allpersonaldiscarged.datos.map(i => (
                                        <tr className="text-center">
                                            <td>{i.name}</td>
                                            <td>{i.email}</td>
                                            <td>{i.document_type}</td>
                                            <td>{i.document}</td>
                                            <td>{i.phone1}</td>
                                            <td>{i.phone2}</td>
                                            <td>{i.address}</td>
                                            <td>{i.sex === 'female' ? 'Mujer' : 'Hombre'}</td>
                                            <td>{i.eps}</td>
                                            <td>{i.arl}</td>
                                            <td>{i.pension}</td>
                                            <td>
                                                <div className="opciones">
                                                    <div class="dropdown">
                                                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" 
                                                            data-bs-toggle="dropdown" aria-expanded="false">
                                                        </button>
                                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <a class="dropdown-item cursor-pointer" onClick={()=>handleCloseSaveActive(i.id)}>
                                                                    Reactivar
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose4}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    )
}

export default TablaPersonal;