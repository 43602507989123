import React, { useState, Fragment, useEffect, useRef } from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, ListGroup, Table, DropdownButton, ButtonGroup, Dropdown,
    Card, Container,
} from 'react-bootstrap';
import {
    DEDALO_CIUDADES, DEDALO_UPDATE_ADVANCES, DEDALO_COST_CENTER_COMBO,
    DEDALO_ADVANCES_BY_ID,
} from "../../../assets/APIAdresses";
import { message_responses } from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import { useUserSession } from "../../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../../utils/routes';
import { Mensaje_Error_Create_Ant } from '../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import 'react-quill/dist/quill.snow.css';
import html2PDF from "jspdf-html2canvas";
import Img1 from '../imagenes_reportes/logo_geo.png';
import * as MdIcons from "react-icons/md";

const Anticipo_Nuevo = () => {
    const data_emp = JSON.parse(localStorage.getItem('Project_contabilidad'));
    const data_emp2 = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();

    let { IdAntEdit } = useParams();

    const [ciudad, setCiudad] = useState('');
    const [ciudadid, setCiudadId] = useState(0);
    const [centrocostosid, setCentroCostosId] = useState(0);
    const [documento, setDocumento] = useState('');

    const [dats, setDat] = useState({
        valor_letras: '',
        number_advances: '',
        document_number: '',
        description: '',
        advance_reason: '',
        ciudad_id: '',
        ciudad_name: '',
        tipo_doc_name: '',
        centro_costos_id: '',
        cargo: '',
        dia_edit: '',
        mes_edit: '',
        year_edit: '',
        usuario_edit: '',
        usuario_id_edit: '',
    });

    const [ciudadesproyectos, setCiudadesProyectos] = useState({
        datos: [],
    });

    const [costcenter, setCostCenter] = useState({
        datos: [],
    });

    const peticionGetCategor = async () => {
        const params_data = {
            search: '',
            perpage: 1000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CIUDADES,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice);
                    setCiudadesProyectos({
                        ...ciudadesproyectos,
                        ['datos']: datanotice.data,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 2.2");
                }
            });

        return info1;
    };

    const peticionGetCostCenter = async () => {
        const params_data = {
            enterprice_id: parseInt(data_emp2.IdEmp),
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COST_CENTER_COMBO,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice);
                    setCostCenter({
                        ...costcenter,
                        ['datos']: datanotice.data,
                    });
                } else {
                    setCostCenter({
                        ...costcenter,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 2.2");
                }
            });

        return info1;
    };

    const peticionGetActasById = async () => {
        let idacta = parseInt(IdAntEdit);
        //console.log(idacta);
        if (idacta === undefined) {
            let re = 0;
        } else {
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ADVANCES_BY_ID + '&id=' + idacta,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg.data;
                        //console.log(datanotice);
                        setDat({
                            ...dats,
                            ['valor_letras']: datanotice.totalvalue,
                            ['number_advances']: datanotice.number_advances,
                            ['document_number']: datanotice.document_number,
                            ['description']: datanotice.description,
                            ['advance_reason']: datanotice.advance_reason,
                            ['ciudad_id']: datanotice.city_id,
                            ['ciudad_name']: datanotice.nameCity,
                            ['tipo_doc_name']: datanotice.document_type,
                            ['centro_costos_id']: datanotice.cost_center_id,
                            ['cargo']: datanotice.position,
                            ['dia_edit']: datanotice.advance_day,
                            ['mes_edit']: datanotice.advance_month,
                            ['year_edit']: datanotice.advance_year,
                            ['usuario_edit']: datanotice.nameUser,
                            ['usuario_id_edit']: datanotice.users_id,
                        });
                    } else {
                        alert(message_responses[response.data.message]);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos by_id");
                    }
                });

            return info1;
        }
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetCostCenter();
        peticionGetActasById();
    }, []);

    const handleInputChange = (e) => {
        let id_City = e.target.value;
        let datos_ciudad = ciudadesproyectos.datos === undefined ? [] : ciudadesproyectos.datos;
        let nom_ciudad = datos_ciudad.filter(x => parseInt(x.id) === parseInt(id_City));
        setCiudad(nom_ciudad[0].name);
        setCiudadId(parseInt(id_City));
    };

    const handleInputChangeCentro = (e) => {
        setCentroCostosId(e.target.value);
    };

    const handleInputChangeDocumento = (e) => {
        setDocumento(e.target.value);
    };

    const handleInputChangeObs = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    let valor_l = dats.valor_letras === '' ? '0' : dats.valor_letras;
    const conversor = require('conversor-numero-a-letras-es-ar');
    let ClaseConversor = conversor.conversorNumerosALetras;
    let miConversor = new ClaseConversor();
    let total_letras = miConversor.convertToText(parseInt(valor_l));

    const printContainer = useRef(null);

    const createPDF = async () => {
        let year = new Date().getFullYear();
        let mes = new Date().getMonth() + 1;
        let dias = new Date().getDate();
        let fecha = dias + '_' + mes + '_' + year;

        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: `solicitud_anticipo_${fecha}.pdf`
        });
    };

    const handleCloseSave = (e) => {
        let valor1 = dats.document_number;
        let valor2 = dats.valor_letras;
        let fecha_guardar = dats.dia_edit + '-' + dats.mes_edit + '-' + dats.year_edit;

        const formattedData = {
            enterprice_id: parseInt(data_emp2.IdEmp),
            users_id: userId,
            advance_day: dats.dia_edit,
            advance_month: dats.mes_edit,
            advance_year: dats.year_edit,
            city_id: parseInt(ciudadid === 0 ? dats.ciudad_id : ciudadid),
            position: dats.cargo,
            cost_center_id: parseInt(centrocostosid === 0 ? dats.centro_costos_id : ciudadid),
            document_type: documento === '' ? dats.tipo_doc_name : documento,
            number_advances: dats.number_advances,
            document_number: parseInt(dats.document_number),
            description: dats.description,
            totalvalue: parseInt(dats.valor_letras),
            advance_reason: dats.advance_reason,
        };
        //console.log(formattedData);
        if (valor2 === '') {
            Swal.fire("!!Atencion!!", 'hay campos en blanco, y no es posible editar el anticipo', "info");
        } else {
            axios({
                method: 'POST',
                url: DEDALO_UPDATE_ADVANCES + '&id=' + parseInt(IdAntEdit),
                headers: { Authorization: userToken },
                data: formattedData
            })
                .then(response => {
                    //console.log(response);
                    if (response.data.code === '200') {
                        Swal.fire("Se ha editado la cotizacion exitósamente", "", "success");
                        peticionGetActasById();
                    } else {
                        Mensaje_Error_Create_Ant(response.data.msg.error)
                    }
                })
                .catch(error => {
                    manageError(error);
                });
        }
    };

    let centro_name = costcenter.datos;
    let name_center = centro_name.filter(x => x.id === parseInt(dats.centro_costos_id));
    //console.log(dats);

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371" }}
                            onClick={handleCloseSave}
                        >
                            Guardar Anticipo editado
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm"
                            onClick={() => createPDF()} title="Regresar" style={{ backgroundColor: "#02B371" }}
                        >
                            descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container container-fluid" ref={printContainer} style={{ padding: '1em 3em 1em 3em', width: '72em' }}>
                <Row className="border border-dark mb-1"> {/*  cabecera del formato */}
                    <Col md={2} className="text-center py-1 border-end border-dark">
                        <img src={Img1} className='img-fluid' width={230} />
                    </Col>
                    <Col md={8} className="d-flex justify-content-center align-items-center">
                        <div className="text-center">
                            <h3>
                                <b>FORMATO SOLICITUD DE <br />ANTICIPO</b>
                            </h3>
                        </div>
                    </Col>
                    <Col md={2} className="text-center py-1 border-start border-dark">
                        <div>
                            Codigo: FO-AD-03-01
                        </div>
                        <div>
                            Fecha: 01/09/2013
                        </div>
                        <div>
                            Pagina 1 de 1
                        </div>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark">
                    <Col md={3} className="border-end border-dark">
                        <div className="border-bottom border-dark text-center mb-2">
                            <b>SOLICITUD DE ANTICIPO No.</b>
                        </div>
                        <div>
                            <FormControl
                                aria-label="Nombre de la cotizacion" value={dats.number_advances}
                                aria-describedby="basic-addon1" type="text" id="number_advances" name="number_advances"
                                className="border-0 text-center" onChange={handleInputChangeObs}
                            />
                        </div>
                    </Col>
                    <Col md={4} className="border-end border-dark">
                        <div className="border-bottom border-dark text-center">
                            <b>FECHA DE SOLICITUD</b>
                        </div>
                        <Row className="text-center border-bottom border-dark">
                            <Col md={4} className="border-end border-dark">
                                <b>DD</b>
                            </Col>
                            <Col md={4} className="border-end border-dark">
                                <b>MM</b>
                            </Col>
                            <Col md={4}>
                                <b>AAAA</b>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col md={4} className="border-end border-dark">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" size="sm" value={dats.dia_edit}
                                    aria-describedby="basic-addon1" type="text" id="dia_edit" name="dia_edit"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={4} className="border-end border-dark">
                                <FormControl
                                    aria-label="Nombre de la cotizacion" size="sm" value={dats.mes_edit}
                                    aria-describedby="basic-addon1" type="text" id="mes_edit" name="mes_edit"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                            <Col md={4}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" size="sm" value={dats.year_edit}
                                    aria-describedby="basic-addon1" type="text" id="year_edit" name="year_edit"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        <div className="border-bottom border-dark text-center mb-2">
                            <b>CIUDAD</b>
                        </div>
                        <Form.Select
                            aria-label="Default select example"
                            id={"select_ciudad"} name="select_ciudad" onChange={(e) => handleInputChange(e)}
                            className="border-0 text-center"
                        >
                            <option>{dats.ciudad_name}</option>
                            {ciudadesproyectos.datos === undefined ?
                                ''
                                :
                                ciudadesproyectos.datos.map((supply, i) => (
                                    <option id={supply.id} value={supply.id} name={supply.name}>
                                        {supply.name}
                                    </option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark">
                    <Col md={12}>
                        <Row className="border-bottom border-dark" style={{ backgroundColor: '#95a5a6' }}>
                            <Col md={12}>
                                <b>I. INFORMACION PERSONAL</b>
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={6} className='text-center border-end border-dark'>
                                <b>NOMBRE DEL EMPLEADO</b>
                            </Col>
                            <Col md={6} className='text-center'>
                                <b>CARGO</b>
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={6} className='text-center border-end border-dark'>
                                {dats.usuario_edit}
                            </Col>
                            <Col md={6} className='text-center'>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" value={dats.cargo}
                                    aria-describedby="basic-addon1" id="cargo" name="cargo"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={5} className='text-center border-end border-dark'>
                                <b>TIPO DE DOCUMENTO</b>
                            </Col>
                            <Col md={7} className='text-center'>
                                <b>NUMERO</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={5} className='text-center border-end border-dark'>
                                <Form.Select
                                    aria-label="Default select example"
                                    id={"select_documento"} name="select_documento" onChange={(e) => handleInputChangeDocumento(e)}
                                    className="border-0 text-center"
                                >
                                    <option>{dats.tipo_doc_name}</option>
                                    <option value='CC'>Cedulda de Ciudadania</option>
                                    <option value='CE'>Cedula de Extranjeria</option>
                                    <option value='PP'>Pasaporte</option>
                                    <option value='PPT'>Permiso por Proteccion Temporal</option>
                                </Form.Select>
                            </Col>
                            <Col md={7} className='text-center'>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" value={dats.document_number}
                                    aria-describedby="basic-addon1" type="number" id="document_number" name="document_number"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark">
                    <Col md={12}>
                        <Row className="border-bottom border-dark" style={{ backgroundColor: '#95a5a6' }}>
                            <Col md={12}>
                                <b>II. asignacion presupuestal</b>
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={4} className='d-flex justify-items-center align-items-center border-end border-dark'>
                                <b>CENTRO DE COSTOS</b>
                            </Col>
                            <Col md={8}>
                                <Form.Select
                                    aria-label="Default select example"
                                    id={"select_costos"} name="select_costos" onChange={(e) => handleInputChangeCentro(e)}
                                    className="border-0"
                                >
                                    <option>
                                        {name_center[0] === undefined ? '' : name_center[0].name}
                                    </option>
                                    {costcenter.datos === undefined ?
                                        ''
                                        :
                                        costcenter.datos.map((supply, i) => (
                                            <option id={supply.id} value={supply.id} name={supply.name}>
                                                {supply.name}
                                            </option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark" style={{ backgroundColor: '#95a5a6' }}>
                            <Col md={12} className="text-center">
                                <b>DESCRIPCION DEL BIEN O SERVICIO SOLICITADO</b>
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={12} className="text-center">
                                <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                    id="description" name="description" style={{ fontSize: '1.2em', textAlign: 'justify' }}
                                    className='border-0' value={dats.description}
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={12} className="text-center">
                                <small>LOS VALORES DEBEN SER LIQUIDADOS DE ACUERDO CON LAS TARIFAS APROBADAS SEGUN EL CARGO</small>
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={4} className='d-flex justify-content-center align-items-center border-end border-dark text-center'>
                                <b>TOTAL</b>
                            </Col>
                            <Col md={8}>
                                <FormControl
                                    aria-label="Nombre de la cotizacion" value={dats.valor_letras}
                                    aria-describedby="basic-addon1" type="number" id="valor_letras" name="valor_letras"
                                    className="border-0 text-center" onChange={handleInputChangeObs}
                                />
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark" style={{ backgroundColor: '#95a5a6' }}>
                            <Col md={12} className="text-center">
                                <b>VALOR EN LETRAS</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                <div style={{ height: '2em' }}>
                                    {total_letras === 'cero' || dats.valor_letras === '' ? '' : total_letras}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark">
                    <Col md={12}>
                        <Row className="border-bottom border-dark" style={{ backgroundColor: '#95a5a6' }}>
                            <Col md={12}>
                                <b>III. MOTIVO DEL ANTICIPO</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChangeObs}
                                    id="advance_reason" name="advance_reason" style={{ fontSize: '1.2em', textAlign: 'justify' }}
                                    className='border-0' value={dats.advance_reason}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark">
                    <Col md={12}>
                        <Row className="border-bottom border-dark" style={{ backgroundColor: '#95a5a6' }}>
                            <Col md={12}>
                                <b>IV. REQUISITOS MINIMOS QUE DEBEN TENER LAS FACTURAS</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <small>
                                    <ul className="text-justify">
                                        <li>Apellidos y nombres o Razón Social y Nit del vendedor o de quien presta el servicio. Adjuntar el RUT</li>
                                        <li>Fecha de expedición de la Factura.</li>
                                        <li>Valor total de la operación con discriminación del IVA pagado.</li>
                                        <li>La factura se expida a nombre de Tecnocientific Ltda y no a nombre del empleado. </li>
                                        <li>Los documentos deben corresponder al mes del anticipo</li>
                                        <li>
                                            El maximo monto de servicios debe ser de 107.000 y de compras de 725.000 exceptuando casos de fuerza mayo para
                                            este caso debe ser consultado
                                        </li>
                                        <li>El departamento contable para determinar el valor a pagar ya que se deben calcular impuestos.</li>
                                        <li>
                                            El unico monto que puede ser legalizado por recibo de caja menor es el de taxis y buses ya que para los
                                            otros gastos se soporta con cuenta de cobro en caso de personas naturales o factura para personas
                                            juridicas o empresas.
                                        </li>
                                    </ul>
                                </small>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark">
                    <Col md={12}>
                        <Row className="border-bottom border-dark" style={{ backgroundColor: '#95a5a6' }}>
                            <Col md={12}>
                                <b>V. FIRMAS Y ACEPTACION DE CONDICIONES PARA LA LEGALIZACION DE ANTICIPO DE VIAJE</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <small>
                                    <ul className="text-justify">
                                        <li>
                                            El Empleado beneficiario cuenta con MAXIMO 10 días  para la legalización del anticipo a través de los recibos,
                                            facturas y demás soportes de gastos, cuando halla sobrantes de dineros debe ser consignados en la cuenta de
                                            la compañía ques banco BBVA CTA CTA 073060774
                                        </li>
                                        <li>
                                            Vencido el término de la legalización sin que medie manifestación de parte del beneficiario éste autoriza de forma expresa
                                            a Geoestructuras Ltda,  para que el anticipo recibido o el valor no legalizado se descuente de su salario del mes inmediatamente
                                            siguiente a la fecha límite de  su legalización.
                                        </li>
                                        <li>
                                            Si por cualquier motivo el contrato de trabajo llegará a darse por terminado el  empleado beneficiario, sin que medie manifestación
                                            adicional, autoriza a Tecnocientific Ltda para que se descuente el saldo del anticipo no legalizado, cuando haya lugar al mismo,
                                            sobre salarios, prestaciones sociales, vacaciones,  auxilios, beneficios, indemnizaciones, entre otros,  a los que tenga derecho
                                            al momento de la terminación.
                                        </li>
                                    </ul>
                                </small>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br />
                <Row className="border border-dark">
                    <Col md={12}>
                        <Row className="border-bottom border-dark" style={{ backgroundColor: '#95a5a6' }}>
                            <Col md={6} className='text-center'>
                                <b>SOLICITADO POR</b>
                            </Col>
                            <Col md={6} className='text-center'>
                                <b>AUTORIZADO POR</b>
                            </Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={2} className='border-end border-dark'>
                                <span>NOMBRE:</span>
                            </Col>
                            <Col md={4} className='border-end border-dark'></Col>
                            <Col md={2} className='border-end border-dark'>
                                <span>NOMBRE:</span>
                            </Col>
                            <Col md={4}></Col>
                        </Row>
                        <Row className="border-bottom border-dark">
                            <Col md={2} className='border-end border-dark'>
                                <span>CARGO:</span>
                            </Col>
                            <Col md={4} className='border-end border-dark'></Col>
                            <Col md={2} className='border-end border-dark'>
                                <span>CARGO:</span>
                            </Col>
                            <Col md={4}></Col>
                        </Row>
                        <Row>
                            <Col md={6} className='border-end border-dark'>
                                <div style={{ height: '2em' }}></div>
                                <div>FIRMA EMPLEADO:</div>
                            </Col>
                            <Col md={6}>
                                <div style={{ height: '2em' }}></div>
                                <div>FIRMA:</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Anticipo_Nuevo;