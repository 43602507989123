import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table, Container
} from 'react-bootstrap';
import {
    DEDALO_ALL_PERSONAL_DEDALO,DEDALO_NEW_PERSONAL_DEDALO,
    DEDALO_PERSONAL_DEDALO_COMBO,DEDALO_DEL_PERSONAL,
    DEDALO_EDIT_PERSONAL,DEDALO_PERSONAL_BY_ID,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import { Routes } from "../../utils/routes";
import Navbar from '../navbar';
import Swal from "sweetalert2";
import Logo from '../../imagenes/dedalo_logo.png';
import { socket } from '../../assets/js/conexion_socket';
import Licencia from '../empresas/alertlicencia';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const ComprobarLicencia = () => {
    const {userToken} = useUserSession();    
    const {userId} = useUserSession();
    const {user} = useUserSession(); 
    const [age, setAge] = useState();

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    let empresas_user = user.enterprices;
    let nom_empresa = empresas_user.find(empresa => (empresa.id === parseInt(age)));

    const validar = () => {
        //console.log(nom_empresa);
        if(age === undefined){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Selecciona una empresa',
            });
        }else{
            window.location.href = Routes.HOME;
            localStorage.setItem("Nombre_empresa", JSON.stringify(
                {
                    NomEmp: nom_empresa.name,
                    IdEmp: nom_empresa.id,
                    RutEmp: nom_empresa.rut,
                    DiasLicence: nom_empresa.expiration,
                }
            ));
        }
    };
    
    if(!user){
        window.location.href = Routes.HOME;
    }

    return (
        <div className="licencia_emp">
            <Container className="content_licencia">
                <Card style={{ width: '50%' }}>
                    <Card.Header className="text-center">
                        <img src={Logo} alt="Logo" className="img-fluid" width="180px" height="180px"/>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>Elija la empresa</Card.Title>
                        <Card.Text className="text-center">
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaBuilding style={{color: "#0B5ED7"}}/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChange} id="selectproyecto" name="selectproyecto"
                                >
                                    <option value="">Seleccione una empresa</option>
                                    {empresas_user.map((i,index) => (
                                        i.expiration <= 0 ?
                                            <option key={index} value={i.id} disabled>
                                                {i.name} ---------------- Licencia vencida
                                            </option>
                                        :
                                            <option key={index} value={i.id}>{i.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Card.Text>
                        <div className="text-end">
                            <Button variant="primary" onClick={validar}>
                                Ir a la empresa
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default ComprobarLicencia;
