import React, {useState, useEffect,useRef} from "react";
import {
    Button, Row, Col, ModalBody,Modal,FormControl,
    InputGroup,
} from 'react-bootstrap';
import {
    DEDALO_ALL_QUOTATION,DEDALO_DEL_QUOTATION,DEDALO_UPDATE_QUOTATION_STATUS,
    DEDALO_CLONE_QUOTATION,DEDALO_QUOTATION_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import {Mensaje_Error_Del_Cot} from '../../mensajeserror/mensajeserror';
import Swal from "sweetalert2";
import * as MdIcons from "react-icons/md";

const Cotizacion_Licitacion = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [idcopy, serIdCopy] = useState(0);

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        name: '',
        observations: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        name: '',
        observations: '',
        document: '',
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_QUOTATION,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                //alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
              }
          });
      
        return info1;
    };

    const peticionGetVendorById=async(e)=>{
        let id_cotizacion = parseInt(e);

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_QUOTATION_BY_ID+'&id='+id_cotizacion,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setDat_Edit({
                    ...dats_edit,
                    ['name']: datanotice.data.name,
                    ['observations']: datanotice.data.observations,
                    ['document']: datanotice.data.document,
                });
                serIdCopy(datanotice.data.id);
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setShow3(true);
    };

    const peticionDelete = (iduser) => {
        let delId = iduser;

        axios({
            method: 'DELETE',
            url: DEDALO_DEL_QUOTATION+'&id='+delId,
            headers: {Authorization: userToken}
        })
        .then(response=>{
          if (response.data.code === '200') {
            Swal.fire("Se elimino el item exitósamente.", "", "success");
            peticionGetCategor();
          } else {
            Mensaje_Error_Del_Cot(response.data.msg.error);
          }
        })
    };

    const handleCloseChangeStatus = (e,i) => {
            
        const formattedData = {
            users_id: userId,
            status: e,
        };
        //console.log(formattedData);
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_QUOTATION_STATUS+'&id='+parseInt(i),
            headers: {Authorization: userToken},
            data: formattedData,
        })
            .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se cambio el estado exitósamente", "", "success");
                peticionGetCategor();
            }else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    const handleInputChangeData = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };   

    const handleCloseSave = (e) => {
        let titulo = dats.name;
        let obser = dats.observations;

        const formattedData = {
            name: dats.name,
            users_id: userId,
            enterprice_id: data_emp.IdEmp,
            document: dats_edit.document,
            observations: dats.observations
        };
        //console.log(formattedData);
        if(titulo === '' || obser === ''){
            Swal.fire("!!! Atencion !!!","No se puede hacer la clonacion, verifique que los campos esten con datos", "info");
        }else{
            axios({
                method: 'PUT',
                url: DEDALO_CLONE_QUOTATION+'&id='+idcopy,
                headers: {Authorization: userToken},
                data: formattedData
            })
            .then(response => {
                //console.log(response);
                if (response.data.code === '200') {
                    Swal.fire("Se ha copiado la cotizacion exitósamente", "", "success");
                    peticionGetCategor();
                    handleClose3();
                }else {
                    Swal.fire("No se ha copiado la cotizacion ya existe en la base de datos", "", "info");
                    handleClose3();
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Link to={Routes.COT_NUEVA}>
                            <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}>
                                Generar Cotizacion
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mt-4 container-fluid text-center">
                <h3>
                    Cotizaciones
                </h3>
                <hr/>
                <div className="mt-4 container">
                    <Row>
                        <Col md={4} xs={12}>
                            <div className="text-center">
                                <b>
                                    <span>Cotizaciones generadas</span>
                                </b>
                            </div>
                            <hr/>
                            {categor.datos === undefined ?
                                ''
                            :
                                categor.datos.map((i,index) => (
                                    i.status === 'pending' ?
                                        <div className="d-flex px-4 my-2">
                                            <div>
                                                {i.name}
                                            </div>
                                            <div className="ms-auto">
                                                <div class="dropdown">
                                                    <MdIcons.MdMoreVert type="button" size={'1.3em'}
                                                        class="dropdown-toggle" data-bs-toggle="dropdown" 
                                                        aria-expanded="false" id="dropdownMenuButton2"
                                                        onClick={()=>peticionGetVendorById(i.id)}
                                                    />
                                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                        <Link to={Routes.COT_EDIT(i.id)} className='text-decoration-none'>
                                                            <li>
                                                                <a class="dropdown-item cursor-pointer">
                                                                    Editar
                                                                </a>
                                                            </li>
                                                        </Link>
                                                        <li>
                                                            <a class="dropdown-item cursor-pointer" onClick={()=>peticionDelete(i.id)}>
                                                                Eliminar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item cursor-pointer" onClick={handleShow3}>
                                                                Copiar Formato
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item cursor-pointer" onClick={()=>handleCloseChangeStatus('approved',i.id)}>
                                                                Aprobado
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item cursor-pointer" onClick={()=>handleCloseChangeStatus('rejected',i.id)}>
                                                                Rechazado
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        ''
                                ))
                            }
                        </Col>
                        <Col md={4} xs={12}>
                            <div className="text-center">
                                <b>
                                    <span>Cotizaciones aprobadas</span>
                                </b>
                            </div>
                            <hr/>
                            {categor.datos === undefined ?
                                ''
                            :
                                categor.datos.map((i,index) => (
                                    i.status === 'approved' ?
                                        <div className="d-flex px-4 my-2">
                                            <div>
                                                {i.name}
                                            </div>
                                            <div className="ms-auto">
                                                <div class="dropdown">
                                                    <MdIcons.MdMoreVert type="button" size={'1.3em'}
                                                        class="dropdown-toggle" data-bs-toggle="dropdown" 
                                                        aria-expanded="false" id="dropdownMenuButton2"
                                                        onClick={()=>peticionGetVendorById(i.id)}
                                                    />
                                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                        <Link to={Routes.COT_EDIT(i.id)} className='text-decoration-none'>
                                                            <li>
                                                                <a class="dropdown-item cursor-pointer">
                                                                    Editar
                                                                </a>
                                                            </li>
                                                        </Link>
                                                        <li>
                                                            <a class="dropdown-item cursor-pointer" onClick={()=>handleCloseChangeStatus('rejected',i.id)}>
                                                                Rechazado
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        ''
                                ))
                            }
                        </Col>
                        <Col md={4} xs={12}>
                            <div className="text-center">
                                <b>
                                    <span>Cotizaciones rechazadas</span>
                                </b>
                            </div>
                            <hr/>
                            <div>
                                {categor.datos === undefined ?
                                    ''
                                :
                                    categor.datos.map((i,index) => (
                                        i.status === 'rejected' ?
                                            <p>{i.name}</p>
                                        :
                                            ''
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* MODAL CLONAR COTIZACION */}
            <Modal show={show3} onHide={handleClose3} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Clonar cotizacion</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    placeholder="Nombre de la cotizacion" aria-label="Nombre de la cotizacion"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChangeData}
                                    id="name" name="name"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl as="textarea" aria-label="With textarea" placeholder="Descripcion"
                                    onChange={handleInputChangeData} id="observations" name="observations"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={()=>handleCloseSave()}>
                        Clonar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Cotizacion_Licitacion;