import React, {useState, Fragment, useEffect} from "react";
import Navbar from '../navbar';
import { 
    Card, Row, Col, Accordion, Dropdown, InputGroup,
    DropdownButton, Button, Modal, Table, FormControl,
    Form,
} from 'react-bootstrap'
import { Badge } from "reactstrap";
import Select  from 'react-select';
import {useUserSession} from "../../hooks";
import { Routes } from "../../utils/routes";

import {
    DEDALO_ALL_MEETING,DEDALO_NEW_MEETING,DEDALO_EDIT_MEETING,
    DEDALO_DEL_MEETING,DEDALO_MEETING_BY_ID,DEDALO_MEETING_FILE,
    DEDALO_MEETING_COMMENT,DEDALO_ALL_USER_COMBO
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import { 
    attachToken,
    manageError,
} from "../../utils/utils";
import {Redirect, useParams, Link} from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactHtmlParser from 'html-react-parser';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";

const EditarReuniones = () => {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [responsables, setResponsables] = useState([]);
    const [body, setBody] = useState('');

    const {IdActa} = useParams();

    const [categor, setCategor] = useState({
        title: '',
        content: '',
        observations: '',
        signatories: [],
    });

    const [usuarios, setUsuarios] = useState({
        datos: [],
    });
    
    const [dats, setDat] = useState({
        titulo_1: '',
        observaciones_acta: '',
    });

    const peticionGetUserCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setUsuarios({
                    ...usuarios,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetMeetingById=async()=>{
        if(IdActa === undefined){
            let yu = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_MEETING_BY_ID+'&id='+IdActa,
                headers: {Authorization: userToken},
            })
              .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice.data);
                    setCategor({
                        ...categor,
                        ['title']: datanotice.tile,
                        ['content']: datanotice.content,
                        ['observations']: datanotice.observations,
                        ['signatories']: datanotice.signatories,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
              })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                  }
              });
          
            return info1;
        }
    };

    useEffect(() => {
        peticionGetMeetingById();
        peticionGetUserCombo();
    }, []);

    const handleChange = (e) => {
        //console.log(e);
        setBody(e);
    };

    const handleInputChange = (event) => {   
        setCategor({
            ...categor,
            [event.target.name] : event.target.value
        })
    };

    const modules = {
        toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
        {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'link', 'image', 'video', 
        'code-block',
    ];

    const handlechangeRes = (e) =>{
        setResponsables(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const selectusuarios = [];
    usuarios.datos.map(i => {
        selectusuarios.push({
            value: i.id,
            label: i.name,
        });
    });

    let fecha_acta = new Date();
    let dia = fecha_acta.getDate();
    let mes = fecha_acta.getMonth()+1;
    let año = fecha_acta.getFullYear();
    let hora = fecha_acta.getHours();
    let minutos = fecha_acta.getMinutes();
    let segundos = fecha_acta.getSeconds();
    let fecha = año+'-'+mes+'-'+dia;

    const handleCloseSaveEdit = (e) => {
    
        let name = categor.title;
        let obs = categor.observations;
        
        const formattedData = {
            construction_id: id,
            title: name,
            date: fecha,
            content: body,
            observations: obs === '' ? categor.observations : obs,
            signatories: responsables.length === 0 ? categor.signatories : responsables,
        };
        //console.log(formattedData);
        if(responsables.length === 0){
            Swal.fire("No se pudo editar el acta, valide que los campos titulo, observaciones y responsables este con informacion", "", "warning");
        }else{
            //axios.post(DEDALO_NUEVA_CONSTRUCCION, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_EDIT_MEETING+'&id='+IdActa,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el acta exitósamente", "", "success");
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });   
        };
    };

    //console.log([categor]);


    return(
        <div>
            <div className="container-fluid flex-grow-1 container-p-y div_acta">
                <div className="d-flex ">
                    <div>
                        <Button className="btn btn-success rounded-circle" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar"
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div className="text-center text-primary w-100">
                        <h2>Editar Acta de reunion</h2>
                    </div>
                </div>
                <hr className="mb-4"/>
                <div >
                    <Row>
                        <Col md={12} xs={12} className="mb-4">
                            <Button className="btn btn-primary mb-2 b1" size="sm" onClick={handleCloseSaveEdit}>
                                Guardar Acta Editada
                            </Button>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdSubtitles />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Titulo del acta"
                                    aria-describedby="basic-addon1" value={categor.title}
                                    type="text" onChange={handleInputChange}
                                    id="title" name="title"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdSubtitles />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Observaciones del acta" aria-label="Observaciones del acta"
                                    aria-describedby="basic-addon1" value={categor.observations}
                                    type="text" onChange={handleInputChange}
                                    id="observations" name="observations"
                                />
                            </InputGroup>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <Form.Label htmlFor="basic-url">Responsables</Form.Label>
                                <Select isMulti options={selectusuarios} onChange={handlechangeRes} className="w-100" ></Select>
                            </InputGroup>
                            <ReactQuill
                                className="bg-light text-dark area_acta"
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                placeholder="Escribe aquí..."
                                value={body === '' ? categor.content : body}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default EditarReuniones;