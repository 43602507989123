import React, {useState, Fragment, useEffect} from "react";
import {Redirect, useParams, Link} from "react-router-dom";
import {
    DEDALO_ALL_CONSTRUCTIONS_TASK,
} from "../../../assets/APIAdresses";
import { 
    Card, Button, InputGroup, FormControl, Container, Row, Col,
    Form,
} from 'react-bootstrap';
import axios from "axios";
import {useUserSession} from "../../../hooks";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import { Routes } from "../../../utils/routes";
import {es} from 'date-fns/locale';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import * as MdIcons from "react-icons/md";

const Cronograma_Actividades = (props) => {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = datahomi === null ? 0 : parseInt(datahomi.idProject);

    let {IdProyect} = useParams();

    const [tareas, setTareas] = useState({
        datos: [],
    });

    const peticionGetTareas=async()=>{
        let id2 = IdProyect === undefined ? id : IdProyect;
        const params_data = {
            constructions_id: id2,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CONSTRUCTIONS_TASK,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                if(datanotice.length === 0){
                    setTareas({
                        ...tareas,
                        ['datos']: [],
                    });
                }else{
                    if(datanotice.records.length > 0){
                        setTareas({
                            ...tareas,
                            ['datos']: datanotice.records,
                        });
                    }
                }
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    useEffect(() => {
        peticionGetTareas();
    }, []);

    let tasks = [];

    if(tareas.datos.length > 0){
        for(let i = 0; i < tareas.datos.length; i++){
            let dia = new Date(tareas.datos[i].startdate).getDate();
            let mes = new Date(tareas.datos[i].startdate).getMonth() + 1;
            let anio = new Date(tareas.datos[i].startdate).getFullYear();

            let dia2 = new Date(tareas.datos[i].deadline).getDate();
            let mes2 = new Date(tareas.datos[i].deadline).getMonth() + 1;
            let anio2 = new Date(tareas.datos[i].deadline).getFullYear();

            tasks.push(
                {
                    start: new Date(anio, mes, dia),
                    end: new Date(anio2, mes2, dia2),
                    name: tareas.datos[i].title,
                    id: tareas.datos[i].id,
                    type:'task',
                    progress: 100,
                    isDisabled: false,
                    styles: { progressColor: '#1A5276', progressSelectedColor: '#73030b' },
                },
            );
        }
    }else{
        tasks.push(
            {
                start: new Date(2020, 1, 1),
                end: new Date(2020, 1, 2),
                name: 'Idea',
                id: 'Task 0',
                type:'task',
                progress: 45,
                isDisabled: true,
                styles: { progressColor: '#73030b', progressSelectedColor: '#73030b' },
            },
        );
    }

    return(
        <div>
            {window.location.pathname === Routes.PROYECTO_HOMI ?
                ''
            :
                <div className="container-fluid mt-3">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                </div>
            }
            {window.location.pathname === Routes.PROYECTO_HOMI ?
                <div className="container-fluid text-center mb-4">
                    <h3>Cronograma de actividades</h3>
                </div>
            :
                <div className="container-fluid text-center mt-4 mb-4">
                    <h3>Cronograma de actividades</h3>
                </div>
            }
            <div className="container-fluid">
                <Gantt tasks={tasks} locale="es" viewMode='Day' />
            </div>
        </div>
    );
};

export default Cronograma_Actividades;