import React, {useState, Fragment, useEffect} from "react";
import { 
    Row, Modal, Button, InputGroup, Form, 
    Col, FormControl, Table,Card,
} from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as GrIcons from "react-icons/gr";
import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";

import {
    DEDALO_ALL_USER_COMBO,DEDALO_TAREAS_CONSTRUCTION,DEDALO_TYPE_ITEMS_COMBO,
    DEDALO_MEASUREMENTS_COMBO,

    DEDALO_EDIT_TAREA_CONSTRUCTION,
    DEDALO_CONSTRUCCION_TASK_BY_ID,DEDALO_CONSTRUCCION_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {DateTimePicker,KeyboardDatePicker} from '@material-ui/pickers';
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {Redirect,useParams,Link} from "react-router-dom";
import esLocale from "date-fns/locale/es";

function VistaProyectos(){
    const {userToken} = useUserSession();    
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userId} = useUserSession();
    const {user} = useUserSession();    
    const [show, setShow] = useState(false);
    const [clearedDate1, handleClearedDateChange1] = useState(null);
    const [clearedDate2, handleClearedDateChange2] = useState(null);
    const [prioridad, setPrioridad] = useState('');
    const [tareaestado, setTareaestado] = useState('');
    const [observadores, setObservadores] = useState([]);
    const [responsables, setResponsables] = useState([]);
    const [participantes, setParticipantes] = useState([]);
    const [archivobase64, setArchivoBase64] = useState('');
    const [itemselect, setItemSelect] = useState('');
    const [unidadselect, setUnidadSelect] = useState('');

    let {id_task} = useParams();

    const [dats, setDat] = useState({
        titulo: '',
        obs: '',
        medio: '',
        personal: '',
        suministros: '',
        pres: '',
        pres_exe: '',
        descripcion: '',
        prioridad_1: '',
        Responsable: [],
        Observers: [],
        Participants: [],
        startdate: '',
        deadline: '',
        tipoitem: '',
        tipoitem_id: '',
        estado: '',
        unidad: '',
        unidad_id: '',
        files: [],
        cantidad: '',
    });

    const [datsfile, setDatFile] = useState({
        ext: '',
        title_file: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [medicioncombo, setMedicionCombo] = useState({
        datos: [],
    });

    const [typecombo, setTypeCombo] = useState({
        datos: [],
    });

    const handleChangePrioridad = (event) => {
        setPrioridad(event.target.value);
    };

    const handleChangeEstadoTarea = (event) => {
        setTareaestado(event.target.value);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeItem = (event) => {
        setItemSelect(event.target.value);
    };

    const handleChangeMedida = (event) => {
        setUnidadSelect(event.target.value);
    };

    const convertToBase64 = () => {
        //Read File
        var selectedFile = document.getElementById("pdfup").files;
        let selectedFile1 = document.getElementById("pdfup").value;
        let nom1_archivo = document.getElementById('pdfup').files[0];
        let nom2_archivo = document.getElementById('pdfup');
        let extension = selectedFile1.substring(selectedFile1.lastIndexOf('.'),selectedFile1.length);
        let MAXIMO_TAMANIO_BYTES = 50000000;

        setDatFile({
            ...datsfile,
            ['title_file']: nom1_archivo.name.split('.')[0],
            ['ext']: extension
        });

        if(nom1_archivo.size > MAXIMO_TAMANIO_BYTES){
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!",`El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`,"info");
            nom2_archivo.value = "";
            
            setDatFile({
                ...datsfile,
                ['title_file']: '',
                ['ext']: ''
            });
        }else{
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                //var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.split(',')
                    setArchivoBase64(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                // Obtener extensión del archivo
            }
        }
    };

    const peticionGetUserCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor({
                ...categor,
                ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetMedicionCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_MEASUREMENTS_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setMedicionCombo({
                    ...medicioncombo,
                    ['datos']: datanotice,
                });
            } else {
                setMedicionCombo({
                    ...medicioncombo,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetTypeItemCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_TYPE_ITEMS_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setTypeCombo({
                    ...typecombo,
                    ['datos']: datanotice,
                });
            } else {
                setTypeCombo({
                    ...typecombo,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetTareasById=async()=>{
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_TASK_BY_ID+'&id='+parseInt(id_task),
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    let resp = [];
                    setDat({
                        ...dats,
                        ['titulo']: datanotice.data.title,
                        ['obs']: datanotice.data.observations,
                        ['medio']: datanotice.data.means,
                        ['personal']: datanotice.data.personal,
                        ['suministros']: datanotice.data.supplies,
                        ['pres']: datanotice.data.budget,
                        ['pres_exe']: datanotice.data.executed_budget,
                        ['descripcion']: datanotice.data.description,
                        ['prioridad_1']: datanotice.data.priority,
                        ['Responsable']: datanotice.data.Responsable,
                        ['Observers']: datanotice.data.Observers,
                        ['Participants']: datanotice.data.Participants,
                        ['startdate']: datanotice.data.startdate,
                        ['deadline']: datanotice.data.deadline,
                        ['tipoitem']: datanotice.data.nameTypeItem,
                        ['tipoitem_id']: datanotice.data.typeitems_id,
                        ['estado']: datanotice.data.statusTask,
                        ['unidad']: datanotice.data.nameMeasurement,
                        ['unidad_id']: datanotice.data.measurements_id,
                        ['files']: datanotice.data.Files,
                        ['cantidad']: datanotice.data.amount,
                    });
              } else {
                 alert(message_responses[response.data.message]);
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });

        return info1;
    };

    useEffect(() => {
        peticionGetUserCombo();
        peticionGetMedicionCombo();
        peticionGetTypeItemCombo();
        peticionGetTareasById();
    }, []);

    const selectusuarios = [];

    let resp_edit = [];
    let obser_edit = [];
    let part_edit = [];

    let resp1 = [];
    let obser1 = [];
    let part1 = [];

    const fecha_edit1 = new Date(dats.startdate === '' ? new Date() : dats.startdate);
    const fecha_edit2 = new Date(dats.deadline === '' ? new Date() : dats.deadline);

    categor.datos.map(i => {
        selectusuarios.push({
            cat: i.id,
            key: i.name,
        });
    });

    dats.Responsable.map(i => {
        resp_edit.push(parseInt(i.user_id));
        resp1.push({
            cat: i.user_id,
            key: i.nameresponsable,
        });
    });

    for(let i=0;i < dats.Observers.length;i++){
        obser_edit.push(parseInt(dats.Observers[i].user_id));
        obser1.push({
            cat: parseInt(dats.Observers[i].user_id),
            key: dats.Observers[i].nameobserver,
        });
    }

    dats.Participants.map(q => {
        part_edit.push(parseInt(q.user_id));
        part1.push({
            cat: parseInt(q.user_id),
            key: q.nameobserver,
        });
    });

    const handlechangeObs = (e) =>{
        setObservadores(Array.isArray(e)?e.map(x=>x.cat):[]);
    };

    const handlechangeRes = (e) =>{
        setResponsables(Array.isArray(e)?e.map(x=>x.cat):[]);
    };

    const handlechangePar = (e) =>{
        setParticipantes(Array.isArray(e)?e.map(x=>x.cat):[]);
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2
    });
    
    const formatterPeso1 = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 3
    });

    let dia = new Date(clearedDate1).getDate();
    let mes = new Date(clearedDate1).getMonth()+1;
    let año = new Date(clearedDate1).getFullYear();
    let fecha = año+'-'+mes+'-'+dia;

    let dia1 = new Date(clearedDate2).getDate();
    let mes1 = new Date(clearedDate2).getMonth()+1;
    let año1 = new Date(clearedDate2).getFullYear();
    let fecha1 = año1+'-'+mes1+'-'+dia1;

    const handleCloseSave = (e) => {

        //e.preventDefault();
        let titulo = dats.titulo;
        let obs = dats.obs;
        let des = dats.descripcion;
        let medio = dats.medio;
        let personal_tarea = dats.personal;
        let sumi = dats.suministros;
        let fechalimite = fecha1;
        let fechainicio = fecha;
        let tarea_prioridad = prioridad === '' ? dats.prioridad_1 : prioridad;
        let tarea_estado = tareaestado === '' ? dats.estado : tareaestado;
        let presupuesto = dats.pres === '' ? 0 : dats.pres;
        let presupuesto_exe = dats.pres_exe === '' ? 0 : dats.pres_exe;;
        let observador_tarea = observadores.length === 0 ? obser_edit : observadores;
        let responsable_tarea = responsables.length === 0 ? resp_edit : responsables;
        let participante_tarea = participantes.length === 0 ? part_edit : participantes;

        const formattedData = {
            constructions_id: datahomi.idProject,
            typeitems_id: itemselect === '' ? parseInt(dats.tipoitem_id) : parseInt(itemselect),
            measurements_id: unidadselect === '' ? parseInt(dats.unidad_id) : parseInt(unidadselect),
            creator_id: userId,
            startdate: clearedDate1 === null ? dats.startdate.split(' ')[0] : fechainicio,
            deadline: clearedDate2 === null ? dats.deadline.split(' ')[0] : fechalimite,
            title: titulo,
            description: des,
            observations: obs,
            budget: presupuesto,
            amount: parseFloat(dats.cantidad),
            means: medio,
            personal: personal_tarea,
            supplies: sumi,
            executed_budget: presupuesto_exe,
            priority: tarea_prioridad,
            status: tarea_estado,
            responsable: responsable_tarea,
            observers: observador_tarea,
            participants: participante_tarea,
            type: datsfile.ext === '' ? dats.files[0] === undefined ? '' : dats.files[0].file.split('.')[1] : datsfile.ext,
            file: archivobase64 === '' ? dats.files[0] === undefined ? '' : dats.files[0].file64 : archivobase64,
        };
        //console.log([formattedData]);
        axios({
            method: 'PUT',
            url: DEDALO_EDIT_TAREA_CONSTRUCTION+'&id='+parseInt(id_task),
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se edito la tarea exitósamente", "", "success");
            } else {
                alert("ERROR: Server Error");
            }
        })
        //.catch(error => {
        //    manageError(error);
        //})
    };

    //console.log(formatterPeso.format(Math.ceil(dats.pres * dats.cantidad)));

    return (
        <div>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div>
                    <div>
                        <Button className="btn btn-success rounded-circle" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar"
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <Card style={{ width: '70%'}}>
                        <Card.Header className="text-center">
                            <Modal.Title>Editar Tarea</Modal.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <MdIcons.MdSubtitles />
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Titulo tarea" aria-label="Titulo tarea"
                                            aria-describedby="basic-addon1" value={dats.titulo}
                                            type="email" onChange={handleInputChange}
                                            id="titulo" name="titulo"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <MdIcons.MdOutlineSpeakerNotes/>
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Observaciones" aria-label="Observaciones"
                                            aria-describedby="basic-addon1" value={dats.obs}
                                            type="text" onChange={handleInputChange}
                                            id="obs" name="obs"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <BsIcons.BsFileMedical />
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Medio" aria-label="Medio"
                                            aria-describedby="basic-addon1" value={dats.medio}
                                            type="email" onChange={handleInputChange}
                                            id="medio" name="medio"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <BsIcons.BsFillFilePersonFill/>
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Personal que lo conforma" aria-label="Personal que lo conforma"
                                            aria-describedby="basic-addon1" value={dats.personal}
                                            type="text" onChange={handleInputChange}
                                            id="personal" name="personal"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                        <InputGroup.Text id="basic-addon1">
                                            <GrIcons.GrStatusCritical/>
                                        </InputGroup.Text>
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangePrioridad} id="select" name="select"
                                        >
                                            <option value="">{dats.prioridad_1}</option>
                                            <option value='high'>Alto</option>
                                            <option value='medium'>Medio</option>
                                            <option value='low'>Bajo</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                        <InputGroup.Text id="basic-addon1">
                                            <BiIcons.BiStats/>
                                        </InputGroup.Text>
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangeEstadoTarea} id="select" name="select"
                                        >
                                            <option value="">{dats.estado}</option>
                                            <option value='pending'>pendiente</option>
                                            <option value='in process'>en progreso</option>
                                            <option value='under review'>bajo revisión</option>
                                            <option value='completed'>Completado</option>
                                            <option value='closed'>Cerrada</option>
                                            <option value='canceled'>Cancelada</option>
                                            <option value='reopened'>Reabierta</option>
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <MdIcons.MdAttachMoney/>
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Presupuesto" aria-label="Presupuesto"
                                            aria-describedby="basic-addon1" value={dats.pres}
                                            type="text" onChange={handleInputChange}
                                            id="pres" name="pres"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <MdIcons.MdMoneyOffCsred/>
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Presupuesto Ejecutado" aria-label="Presupuesto Ejecutado"
                                            aria-describedby="basic-addon1" value={dats.pres_exe === '' ? '0' : dats.pres_exe}
                                            type="text" onChange={handleInputChange}
                                            id="pres_exe" name="pres_exe"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <MdIcons.MdConstruction/>
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Suministros" aria-label="Suministros"
                                            aria-describedby="basic-addon1" value={dats.suministros}
                                            type="email" onChange={handleInputChange}
                                            id="suministros" name="suministros"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <InputGroup.Text id="basic-addon1">
                                            <FaIcons.FaSortAmountUpAlt/>
                                        </InputGroup.Text>
                                        <FormControl
                                            placeholder="Cantidad" aria-label="cantidad"
                                            aria-describedby="basic-addon1" value={dats.cantidad}
                                            type="text" onChange={handleInputChange}
                                            id="cantidad" name="cantidad"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                        <InputGroup.Text id="basic-addon1">
                                            <GrIcons.GrStatusCritical/>
                                        </InputGroup.Text>
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangeItem} id="select_item" name="select_item"
                                        >
                                            <option value="">{dats.tipoitem}</option>
                                            {typecombo.datos.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col md={4} xs={12}>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                        <InputGroup.Text id="basic-addon1">
                                            <BiIcons.BiStats/>
                                        </InputGroup.Text>
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangeMedida} id="select_und" name="select_und"
                                        >
                                            <option value="">{dats.unidad}</option>
                                            {medicioncombo.datos.map((item, index) => (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12} className="mb-3">
                                    <div>
                                        <b className="me-2">Total de la tarea:</b>
                                        {dats.cantidad === '' || parseFloat(dats.cantidad) === 0 ?
                                            formatterPeso1.format(dats.pres)
                                        :
                                            formatterPeso1.format(dats.pres * dats.cantidad)
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={12}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1" className="me-2">
                                            <BiIcons.BiCalendarAlt className="me-2"/>
                                            Fecha Inicial
                                        </InputGroup.Text>
                                        <KeyboardDatePicker
                                            value={clearedDate1 === null ? fecha_edit1 : clearedDate1}
                                            onChange={handleClearedDateChange1} locale={esLocale}
                                            format="dd/MMMM/yyyy" autoOk
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={6} xs={12}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1" className="me-2">
                                            <BiIcons.BiCalendarAlt className="me-2"/>
                                            Fecha Inicial
                                        </InputGroup.Text>
                                        <KeyboardDatePicker
                                            value={clearedDate2 === null ? fecha_edit2 : clearedDate2}
                                            onChange={handleClearedDateChange2} locale={esLocale}
                                            format="dd/MMMM/yyyy" autoOk
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={12}>
                                    <div>
                                        <div>
                                            <span htmlFor="basic-url">Observadores</span>
                                        </div>
                                        <div>
                                            <Multiselect
                                                options={selectusuarios}
                                                selectedValues={obser1}
                                                displayValue="key"
                                                onSelect={handlechangeObs}
                                                placeholder="Seleccione"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={12}>
                                    <div>
                                        <div>
                                            <span htmlFor="basic-url">Responsables</span>
                                        </div>
                                        <div>
                                            <Multiselect
                                                options={selectusuarios}
                                                selectedValues={resp1}
                                                displayValue="key"
                                                onSelect={handlechangeRes}
                                                placeholder="Seleccione"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col md={6} xs={12}>
                                    <div>
                                        <div>
                                            <span htmlFor="basic-url">Participantes</span>
                                        </div>
                                        <div>
                                            <Multiselect
                                                options={selectusuarios}
                                                selectedValues={part1}
                                                displayValue="key"
                                                onSelect={handlechangePar}
                                                placeholder="Seleccione"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xs={12}>
                                    <label style={{fontSize: 14,fontWeight: 'bold'}}>Seleccione un archivo</label>
                                    <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                        <FormControl
                                            type="file" onChange={()=>convertToBase64()}
                                            id="pdfup" name="pdfup"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={12} xs={12}>
                                    <InputGroup className="textareatask">
                                        <InputGroup.Text>
                                            <CgIcons.CgNotes/>
                                        </InputGroup.Text>
                                        <FormControl as="textarea" aria-label="With textarea" onChange={handleInputChange}
                                            placeholder="Descripcion de la tarea" id="descripcion" name="descripcion"
                                            value={dats.descripcion}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>

                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="secondary" onClick={() => {window.history.back()}} className="me-3">
                                Cancelar
                            </Button>
                            {dats.estado === 'canceled' || dats.estado === 'closed' ?
                                <Button variant="primary" disabled>
                                    Editar Tarea
                                </Button>
                            :
                                <Button variant="primary" onClick={handleCloseSave}>
                                    Editar Tarea
                                </Button>
                            }
                        </Card.Footer>
                    </Card>
                </div>
            </div>

        </div>
    );

}

export default VistaProyectos;