import React, {useState, Fragment, useEffect} from "react";
import {Redirect, Link, useParams} from "react-router-dom";
import { Routes } from '../../utils/routes';
import { 
    Card, Button, DropdownButton, Modal, Dropdown, Row, Col,
    InputGroup, FormControl,Form, Container
} from 'react-bootstrap';
import {
    DEDALO_ALL_FOLDERS,DEDALO_NEW_FOLDERS,DEDALO_DEL_FOLDERS,
    DEDALO_UPDATE_FOLDERS,DEDALO_FOLDERS_BY_ID,DEDALO_DEL_FILES,
    DEDALO_NEW_FILES,APIAddress,DEDALO_FILES_BY_ID,DEDALO_ALL_USER_COMBO,
    DEDALO_NEW_SEND_FILES,DEDALO_SHARED_FOLDERS
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import Select  from 'react-select';
import {useUserSession} from "../../hooks";
import Navbar from '../navbar';
import Swal from "sweetalert2";
import Checkbox from '@material-ui/core/Checkbox';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as CgIcons from "react-icons/cg";
import Word from '../../imagenes/iconos/word.png';
import Excel from '../../imagenes/iconos/excel.png';
import Powerpoint from '../../imagenes/iconos/powerpoint.png';
import Publisher from '../../imagenes/iconos/publisher.png';
import Visio from '../../imagenes/iconos/visio.png';
import Pdf from '../../imagenes/iconos/pdf.png';
import WinRar from '../../imagenes/iconos/winrar.png';
import AutoDesk from '../../imagenes/iconos/autodesk.png';
import JPG from '../../imagenes/iconos/iconoJPG.png';
import PNG from '../../imagenes/iconos/iconoPNG.png';


const ArchivosGenerales = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    let data_emp2 = JSON.parse(localStorage.getItem('Ruta_Carpeta'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [seleccionarchivo, setSeleccionArchivo] = useState(0);
    const [extensionarchivo, setExtensionArchivo] = useState();
    const [archivobase64, setArchivoBase64] = useState();
    const [participantes, setParticipantes] = useState([]);
    const [envio_ok, setEnvioOk] = useState(false);
    const [idfolder, setIdFolder] = useState(0);
    const [idfolderchg, setIdFolderChg] = useState(0);

    const [concatenado, setConcatenado] = useState([]);
    const [concatenado2, setConcatenado2] = useState([]);
    const [concatenado3, setConcatenado3] = useState([]);
    let sinDuplicados = [];
    let sinDuplicados2 = [];
    
    const {idcontenido} = useParams();
    const {nom_crp} = useParams();
        
    const [folders, setFolders] = useState({
        datos: [],
    });

    const [archivos, setArchivos] = useState({
        datos: [],
    });
    
    const [dats, setDat] = useState({
        ext: '',
        title_file: '',
        nom_nuevo: '',
    });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        //setSelectPlano(e);
        setShow(true);
    };

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (e) => {
        setShow1(true);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => {
        setShow2(false);
        setEnvioOk(false);
    };
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setShow3(true);
        setIdFolder(e);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (e) => {
        setShow4(true);
        setIdFolderChg(e);
    };

    const convertToBase64 = () => {
        //Read File
        var selectedFile = document.getElementById("pdfup").files;
        let selectedFile1 = document.getElementById("pdfup").value;
        let nom1_archivo = document.getElementById('pdfup').files[0];
        let nom2_archivo = document.getElementById('pdfup');
        let extension = selectedFile1.substring(selectedFile1.lastIndexOf('.'),selectedFile1.length);
        let MAXIMO_TAMANIO_BYTES = 50000000;

        setDat({
            ...dats,
            ['title_file']: nom1_archivo.name.split('.')[0],
            ['ext']: extension
        });

        if(nom1_archivo.size > MAXIMO_TAMANIO_BYTES){
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire("!!! Atencion !!!",`El tamaño del archivo supero el permitido de ${tamanioEnMb}Mb`,"info");
            nom2_archivo.value = "";
            
            setDat({
                ...dats,
                ['title_file']: '',
                ['ext']: ''
            });
        }else{
            //Check File is not Empty
            if (selectedFile.length > 0) {
                // Select the very first file from list
                var fileToLoad = selectedFile[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                //var base64_fix;
                // Onload of file read the file content
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    //base64_fix = base64.split(',')
                    setArchivoBase64(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
                // Obtener extensión del archivo
            }
        }
    };

    const [categor, setCategor] = useState({
        datos: [],
        files: [],
        info_carpeta: [],
        carpeta_compartida: [],
    });

    const [categor2, setCategor2] = useState({
        datos: [],
    });

    const [filesdataid, setFilesDataId] = useState({
        files_data: [],
    });

    let ruta_y = '';

    let cantidad_dat = concatenado.length;

    const elementoRepite = (valor) => {
        let vecesRepetidas = 0;
        for(let i of sinDuplicados){
            if (i.nom_crp == valor) {
                vecesRepetidas ++;
                if (vecesRepetidas > 0) {
                    return true;
                    break;
                }
            }
        }
        return false;
    }
    
    concatenado.forEach(i => {
        if (!elementoRepite(i.nom_crp)){
            sinDuplicados.push(i);
        } 
    });

    const regresar_crp = (id) => {
        let cnt = --cantidad_dat;   
        sinDuplicados.splice((id+1),cnt);
        setConcatenado3(sinDuplicados);
        setConcatenado(sinDuplicados);
    }

    sinDuplicados.map(qw => {
        ruta_y += qw.nom_crp;
    });

    //console.log(concatenado3.length,sinDuplicados.length);
    
    const peticionGetUserCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_ALL_USER_COMBO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_USER_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor2({
                    ...categor2,
                    ['datos']: datanotice,
                });
                //handleAdd2();
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
            }
        });
      
        return info1;
    }

    const peticionGetCategor=async(rt_file)=>{
        //e.preventDefault();
        let ruta_file = rt_file;

        let formattedData = {
            sharedwith: userId,
            folder: ruta_file === undefined ? concatenado3.length === 0 ? ruta_y+nom_crp+'/' : sinDuplicados.length > 1 ? ruta_y : ruta_y+nom_crp+'/' : ruta_file,
        };
        //console.log(formattedData);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_FOLDERS_BY_ID+'&id='+idcontenido,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                if(datanotice.length === 0){
                    setCategor({
                        ...categor,
                        ['datos']: [],
                        ['files']: [],
                        ['info_carpeta']: datanotice,
                        ['carpeta_compartida']: [],
                    });
                }else{
                    setCategor({
                        ...categor,
                        ['datos']: datanotice.folders,
                        ['files']: datanotice.files,
                        ['info_carpeta']: datanotice,
                        ['carpeta_compartida']: datanotice.folders_Shared,
                    });
                }
            } else {
                //alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
            alert(
                error.response.data.message === "VALIDATOR_ERROR"
                ? error.response.data.data.error_message
                : message_responses[error.response.data.message]
            );
            } else {
            alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };
    
    const peticionGetCategorFilesById=async(idinfo,e)=>{
        //e.preventDefault();
        //let info1 = await axios.get(DEDALO_ALL_FOLDERS+'?page=1&rows=10&order=name&inorder=asc&search=&creator_id=13')
        if(idinfo === undefined){
            let iu = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_FILES_BY_ID+'&id='+idinfo,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setFilesDataId({
                        ...filesdataid,
                        ['files_data']: datanotice.data,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
              .catch(error => {
                  if (error.response && error.response.data) {
                    alert(
                      error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                  } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                  }
              });
          
            return info1;
        }
    };
    
    const peticionDelete = (e) => {
        //e.preventDefault();
        let ruta = '';
        
        sinDuplicados.map(ew => {
            ruta += ew.nom_crp
        });
        
        if(folders.datos.length > 0){
            folders.datos.map(id_folder => {
                //axios.delete(DEDALO_DEL_FOLDERS(id_folder), attachToken(user))
                axios({
                    method: 'DELETE',
                    url: DEDALO_DEL_FOLDERS+'&id='+id_folder,
                    headers: {Authorization: userToken},
                })
                .then(response=>{
                    if (response.data.code === '200') {
                        //Swal.fire("Se elimino el item exitósamente", "", "success");
                        peticionGetCategor(ruta);
                        setSeleccionArchivo(0);
                        setFolders({
                            ...folders,
                            ['datos']: [],
                        });
                    } else {
                        alert("ERROR: Server Error folders");
                    }
                })
            });
            Swal.fire("Se elimino el item exitósamente", "", "success");
        }

        if(archivos.datos.length > 0){
            archivos.datos.map(id_file => {
                //axios.delete(DEDALO_DEL_FILES(id_file), attachToken(user))
                axios({
                    method: 'DELETE',
                    url: DEDALO_DEL_FILES+'&id='+id_file,
                    headers: {Authorization: userToken},
                })
                .then(response=>{
                    if (response.data.code === '200') {
                        //Swal.fire("Se elimino el item exitósamente", "", "success");
                        peticionGetCategor(ruta);
                        setSeleccionArchivo(0);
                        setArchivos({
                            ...archivos,
                            ['datos']: [],
                        });
                    } else {
                        alert("ERROR: Server Error files");
                    }
                })
            });
            Swal.fire("Se elimino el item exitósamente", "", "success");
        }
    };

    const peticionDeleteFiles = (idfiles) => {
        let files_id = idfiles;
        let ruta = '';
        
        sinDuplicados.map(ew => {
            ruta += ew.nom_crp
        });

        axios({
            method: 'DELETE',
            url: DEDALO_DEL_FILES+'&id='+files_id,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el item exitósamente", "", "success");
                peticionGetCategor(ruta);
                //setSeleccionArchivo(0);
            } else {
                alert("ERROR: Server Error");
            }
        })
    };   
    
    useEffect(() => {
        peticionGetCategor();
        peticionGetUserCombo();
        //peticionGetCategorFilesById();
        //handleAdd();
    }, [idcontenido]);

    useEffect(() => {
        peticionGetCategorFilesById();
    }, [ruta_y]);

    useEffect(() => {
        handleAdd();
    }, [idcontenido]);
    
    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeCheckFolders = () => {
        let array_check = [];
        let sa = document.querySelectorAll(".validar");
        sa.forEach(element => {
            if(element.checked){
                array_check.push(element.value);
            }
        });
        setFolders({
            ...folders,
            ['datos']: array_check,
        });
    };

    const handleChangeCheckFiles = () => {
        let array_files = [];
        let sa_files = document.querySelectorAll(".validar");
        sa_files.forEach(element => {
            if(element.checked){
                array_files.push(element.value);
            }
        });
        setArchivos({
            ...archivos,
            ['datos']: array_files,
        });
    };
    
    const handleCloseSaveFolder = (e) => {
    
        e.preventDefault();
        let titulo = dats.title;
        let ruta = '';
        let ruta2 = [];

        concatenado.map(item => {
            ruta2.push(item.nom_crp);
        });

        let filteredArray = ruta2.filter(function(ele , pos){
            return ruta2.indexOf(ele) == pos;
        });

        filteredArray.map(item => {
            ruta += item;
        });
        
        const formattedData = {
            name: ruta+titulo+'/',
            creator_id: userId,
        };
        //console.log(formattedData);
        if(titulo == '') {
            Swal.fire({
                title: 'Atencion',
                text: 'El campo nombre de la carpeta son obligatorios',
                icon: 'warning',
            });
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NEW_FOLDERS,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó la carpeta exitósamente", "", "success");
                    document.getElementById('title').value = '';
                    peticionGetCategor(ruta);
                    handleClose();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const handleCloseSaveFile = (e) => {
        
        e.preventDefault();
        let titulo = dats.title_file;
        let ruta = '';
        
        sinDuplicados.map(ew => {
            ruta += ew.nom_crp
        });
        
        const formattedData = {
            title: dats.title_file,
            creator_id: userId,
            type: dats.ext.split('.')[1],
            //folder: categor.info_carpeta.name,
            folder: ruta,
            file: archivobase64,
        };
        //console.log(formattedData);
        if(titulo == '') {
            Swal.fire({
                title: 'Atencion',
                text: 'El campo campo nombre de archivo son obligatorios',
                icon: 'warning',
            });
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NEW_FILES,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el archivo exitósamente", "", "success");
                    setDat({
                        ...dats,
                        ['title_file']: '',
                        ['ext']: '',
                    })
                    peticionGetCategor(ruta);
                    handleClose1();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const handleCloseSendFile = (e) => {
    
        e.preventDefault();

        const formattedData = {
            title: filesdataid.files_data.title,
            creator_id: userId,
            target_id: participantes,
            type: filesdataid.files_data.file.split('.')[1],
            file: filesdataid.files_data.file64,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_FILES, formattedData, attachToken(user))
        axios({
            method: 'POST',
            url: DEDALO_NEW_SEND_FILES,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se creó el archivo exitósamente", "", "success");
                document.getElementById('name').value = '';
                //peticionGetCategorFiles();
                //setEnvioOk(true);
                handleClose2();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const handleCloseSendFolder = (e) => {
    
        e.preventDefault();

        const formattedData = {
            creatorid: userId,
            sharedwith: participantes,
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_SHARED_FOLDERS+'&id='+idfolder,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Atencion","Se compartio el folder exitósamente", "success");
                peticionGetCategor();
                handleClose3();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const handleCloseSaveNomFolder = (e) => {
    
        e.preventDefault();
        const formattedData = {
            creator_id: userId,
            name: dats.nom_nuevo,
        };
        //console.log(formattedData);
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_FOLDERS+'&id='+idfolderchg,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Atencion","Se edito el folder exitósamente", "success");
                peticionGetCategor();
                handleClose4();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        })
    };

    const descargaarchivo = (data) =>{
        let url = APIAddress;
        const downloadinstance = document.createElement('a');
        //downloadinstance.href = url+'/'+data;
        downloadinstance.href = filesdataid.files_data.file64;
        downloadinstance.target = '_blank';
        downloadinstance.download = data.split('/')[data.split('/').length-1];
        downloadinstance.click();
        //console.log(downloadinstance.href);
    };

    const selectusuarios = [];
    categor2.datos.map(i => {
        selectusuarios.push({
            value: i.id,
            label: i.name,
        });
    });

    const handlechangePar = (e) =>{
        setParticipantes(Array.isArray(e)?e.map(x=>x.value):[]);
    };

    const handleAdd = (e2) => {
        setConcatenado([
            ...concatenado,
            {id_crp: parseInt(idcontenido), nom_crp: nom_crp+'/'}
        ]);
    };
    


    return (
        <div>
           <div className="container-fluid flex-grow-1 container-p-y">
                <Card className="mb-4">
                    <Card.Header as="h6" className="with-elements">
                        <div className="card-header-title">
                            <Link to={Routes.COMPARTIR_ARCHIVOS} className="text-decoration-none text-success me-1">
                                <b>Mi Unidad</b>
                            </Link>
                            / {
                                    sinDuplicados.map((item,ind) => (
                                        <Link to={Routes.CONTENIDO_CARPETA(item.id_crp,item.nom_crp)} className="text-decoration-none">
                                            <a onClick={()=>regresar_crp(ind)} className='text-decoration-none text-dark'>
                                                <span key={ind}>{item.nom_crp}</span>
                                            </a>
                                        </Link>
                                    ))
                                }
                        </div>
                        <div className="card-header-elements ml-auto seccion_menu">
                            {seleccionarchivo === 1 ?
                                <div className="d-flex">
                                    <Button className="btn btn-danger me-3 border border-danger rounded text-danger" 
                                        variant="light" onClick={()=>{setSeleccionArchivo(0)}} size="sm"
                                    >
                                        Cancelar
                                    </Button>
                                    <Button className="btn btn-success me-3 border border-success rounded text-success" 
                                        variant="light" size="sm" onClick={()=>{peticionDelete()}}
                                    >
                                        Eliminar
                                    </Button>
                                    <DropdownButton id="dropdown-basic-button" title="Acciones" size="sm"
                                        variant="light" className="border border-success rounded"
                                    >
                                        <Dropdown.Item onClick={handleShow}>
                                            <MdIcons.MdOutlineCreateNewFolder className="text-success me-2 fs-4"/>
                                            Agregar Carpeta
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={handleShow1}>
                                            <CgIcons.CgFileAdd className="text-primary me-2 fs-4"/>
                                            Agregar archivo
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={()=>{setSeleccionArchivo(1)}}>
                                            <MdIcons.MdDeleteOutline className="text-danger me-2 fs-4"/>
                                            Eliminar Item
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            :
                                <DropdownButton id="dropdown-basic-button" title="Acciones" size="sm"
                                    variant="light" className="border border-success rounded"
                                >
                                    <Dropdown.Item onClick={handleShow}>
                                        <MdIcons.MdOutlineCreateNewFolder className="text-success me-2 fs-4"/>
                                        Agregar Carpeta
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleShow1}>
                                        <CgIcons.CgFileAdd className="text-primary me-2 fs-4"/>
                                        Agregar archivo
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=>{setSeleccionArchivo(1)}}>
                                        <MdIcons.MdDeleteOutline className="text-danger me-2 fs-4"/>
                                        Eliminar Item
                                    </Dropdown.Item>
                                </DropdownButton>
                            }
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {categor.datos.length === 0 && categor.files.length === 0 ?
                            <div className="text-center">
                                <h5 className="text-center">No hay nada en esta carpeta</h5>
                            </div>
                        :
                        seleccionarchivo === 1 ?
                            <div>{/* seccion de carpetas y archivos a eliminar*/}
                                <div className="row row-cols-md-6 row-cols-xs-12 ml-2"> {/* carpetas */}
                                    {categor.datos.map(q => (
                                        <div className="col" key={q.id}>
                                            <div className="mb-3">
                                                <input
                                                    value={q.id}
                                                    type="checkbox"
                                                    id="checkdelete" name="checkdelete"
                                                    onChange={handleChangeCheckFolders}
                                                    color="primary"
                                                    className="validar form-check-input"
                                                />
                                                <Button className="boton_carpeta text-start btn btn-light border border-success">
                                                    <AiIcons.AiFillFolderOpen className="text-primary me-2 fs-4"/>
                                                    {q.name}
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <hr/>
                                <div className="row row-cols-md-5 row-cols-xs-12 ml-2"> {/* archivos */}
                                    {categor.files.map(w => (
                                        <div className="col" key={w.id}>
                                            <div className="mb-3">
                                                <input
                                                    value={w.id}
                                                    type="checkbox"
                                                    id="checkdelete" name="checkdelete"
                                                    onChange={handleChangeCheckFiles}
                                                    color="primary"
                                                    className="validar form-check-input"
                                                />
                                                <Card style={{ width: '16rem' }}>
                                                    <Card.Header variant="top" className="text-center">
                                                        {w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.pptx' ?
                                                            <img src={Powerpoint} alt="pptx" title="pptx" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.docx' ?
                                                            <img src={Word} alt="word" title="word" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.rar' ?
                                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.zip' ?
                                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.7z' ?
                                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.pdf' ?
                                                            <img src={Pdf} alt="pdf" title="pdf" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.xlsx' ?
                                                            <img src={Excel} alt="pptx" title="pptx" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.dwg' ?
                                                            <img src={AutoDesk} alt="pptx" title="pptx" width="100px" height="100px" />
                                                        :
                                                            ''
                                                        }
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Card.Title className="with-elements">
                                                            <div className="fs-6">
                                                                {w.title}
                                                            </div>
                                                            <div className="ml-auto">
                                                                <DropdownButton id="dropdown-basic-button" size="sm"
                                                                    variant="light" className="rounded"
                                                                >
                                                                    <Dropdown.Item>
                                                                        <FaIcons.FaDownload className="text-primary me-2 fs-5"/>
                                                                        Descargar Item
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <MdIcons.MdDeleteOutline className="text-danger me-2 fs-4"/>
                                                                        Eliminar Item
                                                                    </Dropdown.Item>
                                                                </DropdownButton>
                                                            </div>
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        :
                            <div>{/* seccion de carpetas y archivos a mostrar*/}
                                <div className="row row-cols-md-6 row-cols-xs-12"> {/* carpetas */}
                                    {categor.datos.map(q => (
                                        <div>
                                            <div className="col" key={q.id}>
                                                <Button className="boton_carpeta text-start btn btn-light border border-success d-flex">
                                                    <Link to={Routes.CONTENIDO_CARPETA(q.id,q.name)}
                                                        className="text-decoration-none d-flex text-dark w-100"
                                                    >
                                                        <div>
                                                            <AiIcons.AiFillFolderOpen className="text-primary me-2 fs-4"/>
                                                        </div>
                                                        <div>
                                                            {q.name}
                                                        </div>
                                                    </Link>
                                                    <div className="ms-auto">
                                                        <div class="dropdown-center acciones_folders">
                                                            <BsIcons.BsThreeDotsVertical id="dropdownCenterBtn" data-bs-toggle="dropdown"
                                                                className="text-primary me-2 fs-6 dropdown-toggle" aria-expanded="false"
                                                            />
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownCenterBtn">
                                                                <li><a class="dropdown-item" onClick={()=>handleShow3(q.id)}>
                                                                    <BsIcons.BsFillShareFill className="text-primary me-2 fs-6"/>
                                                                    Compartir
                                                                </a></li>
                                                                <li><a class="dropdown-item" onClick={()=>handleShow4(q.id)}>
                                                                    <MdIcons.MdOutlinePublishedWithChanges className="text-danger me-2 fs-6"/>
                                                                    Cambiar nombre
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <div className="mt-4">
                                        <p>
                                            <strong>Carpetas compartidas</strong>
                                        </p>
                                    </div>
                                    <div className="row row-cols-md-6 row-cols-xs-12"> {/* carpetas compartidas */}
                                        {categor.carpeta_compartida.map(x => (
                                            <div>
                                                <div className="col" key={x.id}>
                                                    <Button className="boton_carpeta text-start btn btn-light border border-success d-flex">
                                                        <Link to={Routes.CONTENIDO_CARPETA(x.id)}
                                                            className="text-decoration-none d-flex text-dark w-100"
                                                        >
                                                            <div>
                                                                <AiIcons.AiFillFolderOpen className="text-primary me-2 fs-4"/>
                                                            </div>
                                                            <div>
                                                                {x.name}
                                                            </div>
                                                        </Link>
                                                        <div className="ms-auto">
                                                            <div class="dropdown-center acciones_folders">
                                                                <BsIcons.BsThreeDotsVertical id="dropdownCenterBtn" data-bs-toggle="dropdown"
                                                                    className="text-primary me-2 fs-6 dropdown-toggle" aria-expanded="false"
                                                                />
                                                                <ul class="dropdown-menu" aria-labelledby="dropdownCenterBtn">
                                                                    <li><a class="dropdown-item" href="#">
                                                                        <BsIcons.BsFillShareFill className="text-primary me-2 fs-6"/>
                                                                        Compartir
                                                                    </a></li>
                                                                    <li><a class="dropdown-item" href="#">
                                                                        <MdIcons.MdOutlinePublishedWithChanges className="text-danger me-2 fs-6"/>
                                                                        Cambiar nombre
                                                                    </a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <hr/>
                                <div className="row row-cols-md-5 row-cols-xs-12"> {/* archivos */}
                                    {categor.files.map(w => (
                                        <div>
                                            <div className="col mb-3" key={w.id}>
                                                <Card style={{ width: '16rem' }}>
                                                    <Card.Header variant="top" className="text-center">
                                                        {w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.pptx' ?
                                                            <img src={Powerpoint} alt="pptx" title="pptx" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.docx' ?
                                                            <img src={Word} alt="word" title="word" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.rar' ?
                                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.zip' ?
                                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.7z' ?
                                                            <img src={WinRar} alt="winrar" title="winrar" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.pdf' ?
                                                            <img src={Pdf} alt="pdf" title="pdf" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.xlsx' ?
                                                            <img src={Excel} alt="pptx" title="pptx" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.dwg' ?
                                                            <img src={AutoDesk} alt="pptx" title="pptx" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.jpg' || w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.jpeg'?
                                                            <img src={JPG} alt="JPG" title="JPG" width="100px" height="100px" />
                                                        :w.file.substring(w.file.lastIndexOf('.'),w.file.length) === '.png' ?
                                                            <img src={PNG} alt="PNG" title="PNG" width="100px" height="100px" />
                                                        :
                                                            ''
                                                        }
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Card.Title className="with-elements">
                                                            <div className="fs-6 text">
                                                                {w.title}
                                                            </div>
                                                            <div className="ml-auto seccion_menu_archivos">
                                                                <DropdownButton id="dropdown-basic-button" size="sm"
                                                                    variant="light" className="rounded" onClick={()=>peticionGetCategorFilesById(w.id)}
                                                                >
                                                                    <Dropdown.Item onClick={()=>descargaarchivo(w.file)}>
                                                                        <FaIcons.FaDownload className="text-primary me-2 fs-5"/>
                                                                        Descargar Item
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item onClick={handleShow2}>
                                                                        <MdIcons.MdOutlineShare className="text-success me-1 fs-4"/>
                                                                        Compartir Archivo
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>peticionDeleteFiles(w.id)}>
                                                                        <MdIcons.MdDeleteOutline className="text-danger me-2 fs-4"/>
                                                                        Eliminar Item
                                                                    </Dropdown.Item>
                                                                </DropdownButton>
                                                            </div>
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                                
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </Card.Body>
                </Card>
           </div>
            {/* MODAL CREAR CARPETAS */}
            <Modal show={show} onHide={handleClose} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Crear Carpeta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary"/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre de la carpeta" aria-label="Nombre de la carpeta"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="title" name="title"
                                />
                            </InputGroup>
                        </Col>
                    </Row>                    

                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={handleCloseSaveFolder}>
                        Crear Carpeta
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL CREAR ARCHIVOS */}
            <Modal show={show1} onHide={handleClose1} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Cargar Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary"/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Titulo" aria-label="titulo"
                                    aria-describedby="basic-addon1" value={dats.title_file}
                                    type="text" onChange={handleInputChange}
                                    id="title_file" name="title_file"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineDocumentScanner className="text-primary"/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Ext." aria-label="Ext."
                                    aria-describedby="basic-addon1" value={dats.ext}
                                    type="text" onChange={handleInputChange}
                                    id="creator" name="creator" disabled
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    type="file" onChange={()=>convertToBase64()}
                                    id="pdfup" name="pdfup"
                                />
                            </InputGroup>
                        </Col>
                    </Row>                   

                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" variant="secondary" onClick={handleClose1}>
                        Cancelar
                    </Button>
                    <Button className="boton2" variant="primary" onClick={handleCloseSaveFile}>
                        Cargar Archivo
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL COMPARTIR ARCHIVOS */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Compartir archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            Nombre del Archivo
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    placeholder="Nombre del archivo" aria-label="Nombre del archivo"
                                    aria-describedby="basic-addon1" disabled
                                    type="text" value={filesdataid.files_data.title}
                                    id="name" name="name" onChange={handleInputChange}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                Compartir con
                                <Select isMulti options={selectusuarios} onChange={handlechangePar} className="w-100" ></Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={handleCloseSendFile}>
                        Confirmar Envio
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL COMPARTIR FODLERS */}
            <Modal show={show3} onHide={handleClose3} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Compartir Carpetas</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                Compartir con
                                <Select isMulti options={selectusuarios} onChange={handlechangePar} className="w-100" ></Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={handleCloseSendFolder}>
                        Confirmar Envio
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL CAMBIAR NOMBRE CARPETA */}
            <Modal show={show4} onHide={handleClose4} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar Nombre Carpeta</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <FormControl
                                    placeholder="Nombre de la Carpeta" aria-label="Nombre de la Carpeta"
                                    aria-describedby="basic-addon1" type="text"
                                    id="nom_nuevo" name="nom_nuevo" onChange={handleInputChange}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose4}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={handleCloseSaveNomFolder}>
                        Confirmar Cambio
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ArchivosGenerales;