import React, { useState, Fragment, useEffect } from "react";
import {
    Card, Button, Container, Row, Col, InputGroup, Form,
    Table, DropdownButton, Dropdown, Modal, FormControl,
} from 'react-bootstrap';
import {
    DEDALO_ALL_CRM_VENDORS, DEDALO_NEW_CRM_VENDORS, DEDALO_DEL_CRM_VENDORS,
    DEDALO_UPDATE_CRM_VENDORS, DEDALO_CIUDADES, DEDALO_CRM_VENDORS_BY_ID,
} from "../../assets/APIAdresses";
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {
    ExcelExport,
    ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { useUserSession } from "../../hooks";
import Swal from "sweetalert2";
import { Redirect, useParams } from "react-router-dom";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import Navbar from '../navbar';
import { Routes } from '../../utils/routes';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as GrIcons from "react-icons/gr";

function CrmVendors() {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { user } = useUserSession();
    const [ciudadproveedor, setCiudadProveedor] = useState('');
    const [empresabyid, setEmpresaById] = useState();
    const [autoretenedor, setAutoretenedor] = useState('');

    const [infofiltro, setInfoFiltro] = useState({
        datos: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [ciudades, setCiudades] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        nombre: '',
        dir: '',
        rut: '',
        nit: '',
        ciudad: '',
        contact: '',
        contact_position: '',
        tel1: '',
        tel2: '',
        email: '',
        tel_emp: '',
        email_emp: '',
        descripcion: '',
        obs: '',
        tarifa_ica: '',
        regimen_ica: '',
    });

    const [inputfilter, setInputFilter] = useState({
        formulario: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        nombre_edit: '',
        dir_edit: '',
        rut_edit: '',
        nit_edit: '',
        ciudad_edit: '',
        contact_edit: '',
        contact_position_edit: '',
        tel1_edit: '',
        tel2_edit: '',
        email_edit: '',
        tel_emp_edit: '',
        email_emp_edit: '',
        descripcion_edit: '',
        obs_edit: '',
        tarifa_ica: '',
        regimen_ica: '',
        autoretenedor_check: '',
    });

    const peticionGetCategor = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 2000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CRM_VENDORS+'?page=1&rows=1000&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CRM_VENDORS,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setCategor({
                        ...categor,
                        ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
                }
            });

        return info1;
    };

    const peticionGetVendorById = async () => {
        if (empresabyid === undefined) {
            let iu = 0;
        } else {

            let info1 = await axios({
                method: 'POST',
                url: DEDALO_CRM_VENDORS_BY_ID + '&id=' + empresabyid,
                headers: { Authorization: userToken },
            })
                .then(response => {
                    if (response.data.code === '200') {
                        let datanotice = response.data.msg;
                        //console.log(datanotice.data);
                        setDat_Edit({
                            ...dats_edit,
                            ['nombre_edit']: datanotice.data.name,
                            ['dir_edit']: datanotice.data.address,
                            ['rut_edit']: datanotice.data.rut,
                            ['nit_edit']: datanotice.data.nit,
                            ['ciudad_edit']: datanotice.data.cities_id,
                            ['contact_edit']: datanotice.data.contact,
                            ['contact_position_edit']: datanotice.data.contact_position,
                            ['tel1_edit']: datanotice.data.contact_phone1,
                            ['tel2_edit']: datanotice.data.contact_phone2,
                            ['email_edit']: datanotice.data.contact_email,
                            ['tel_emp_edit']: datanotice.data.vendors_phone,
                            ['email_emp_edit']: datanotice.data.vendors_email,
                            ['descripcion_edit']: datanotice.data.description,
                            ['obs_edit']: datanotice.data.observations,
                            ['tarifa_ica']: datanotice.data.ica_rate,
                            ['regimen_ica']: datanotice.data.iva_regimen,
                            ['autoretenedor_check']: datanotice.data.self_retaining,
                        });

                    } else {
                        alert(message_responses[response.data.message]);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        alert(
                            error.response.data.message === "VALIDATOR_ERROR"
                                ? error.response.data.data.error_message
                                : message_responses[error.response.data.message]
                        );
                    } else {
                        alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
                    }
                });

            return info1;
        }
    };

    const peticionGetCiudades = async () => {

        //let info1 = await axios.get(DEDALO_ALL_CRM_VENDORS+'?page=1&rows=1000&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CIUDADES,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setCiudades({
                        ...ciudades,
                        ['datos']: datanotice.data,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
                }
            });

        return info1;
    };

    const peticionDelete = (iduser) => {
        let delId = parseInt(iduser);
        //axios.delete(DEDALO_DEL_CRM_VENDORS(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_CRM_VENDORS + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el proveedor exitósamente", "", "success");
                    //window.location.reload();
                    peticionGetCategor();
                } else {
                    Swal.fire('No se puede eliminar el proveedor, valide que no este seleccionado en otro proceso', "", "warning");
                    //alert(response.data.msg.error);
                }
            })

    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetCiudades();
    }, []);

    useEffect(() => {
        peticionGetVendorById();
    }, [empresabyid]);

    const handleChangePrioridad = (event) => {
        setCiudadProveedor(event.target.value);
    };

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
        setAutoretenedor('');
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setShow3(true);
        setAutoretenedor('');
    };

    const handleInputChange = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {
        setDat_Edit({
            ...dats_edit,
            [event.target.name]: event.target.value
        })
    };

    const handleInputChangeInputFilter = (event) => {
        setInputFilter({
            ...inputfilter,
            [event.target.name]: event.target.value
        })
    };

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };

    const handleChangeAuto = (event) => {
        setAutoretenedor(event.target.value);
    };

    const handleCloseSave = (e) => {

        //e.preventDefault();
        let nombre_emp = dats.nombre;
        let dir_emp = dats.dir;
        let rut_emp = dats.rut;
        let nit_emp = dats.nit;
        let ciudad_emp = ciudadproveedor;
        let contacto_emp = dats.contact;
        let contactocargo_emp = dats.contact_position;
        let tel_emp1 = dats.tel1;
        let tel_emp2 = dats.tel2;
        let email_emp1 = dats.email;
        let tel_emp3 = dats.tel_emp;
        let email_emp2 = dats.email_emp;
        let desc_emp = dats.descripcion;
        let obs_emp = dats.obs;

        const formattedData = {
            enterprice_id: data_emp.IdEmp,
            name: nombre_emp,
            address: dir_emp,
            rut: rut_emp,
            nit: nit_emp,
            cities_id: ciudad_emp,
            contact: contacto_emp,
            contact_position: contactocargo_emp,
            contact_phone1: tel_emp1,
            contact_phone2: tel_emp2,
            contact_email: email_emp1,
            vendors_phone: tel_emp3,
            vendors_email: email_emp2,
            description: desc_emp,
            observations: obs_emp,
            iva_regimen: dats.regimen_ica === '' ? '' : parseFloat(dats.regimen_ica),
            self_retaining: autoretenedor,
            ica_rate: dats.tarifa_ica === '' ? '' : parseFloat(dats.tarifa_ica),
        };
        //console.log(formattedData);
        if (nombre_emp === '' || ciudad_emp === '') {
            Swal.fire({
                title: 'Atencion',
                text: 'Los campos nombre y ciudad son obligatorios',
                icon: 'warning',
            });
        } else {
            axios({
                method: 'POST',
                url: DEDALO_NEW_CRM_VENDORS,
                headers: { Authorization: userToken },
                data: formattedData,
            })
                .then(response => {
                    if (response.data.code === '200') {
                        Swal.fire("Se creo el proveedor exitósamente", "", "success");
                        document.getElementById("nombre").value = "";
                        document.getElementById("dir").value = "";
                        document.getElementById("rut").value = "";
                        document.getElementById("nit").value = "";
                        document.getElementById("ciudad").value = "";
                        document.getElementById("contact").value = "";
                        document.getElementById("contact_position").value = "";
                        document.getElementById("tel1").value = "";
                        document.getElementById("tel2").value = "";
                        document.getElementById("email").value = "";
                        document.getElementById("tel_emp").value = "";
                        document.getElementById("email_emp").value = "";
                        document.getElementById("descripcion").value = "";
                        document.getElementById("obs").value = "";
                        peticionGetCategor();
                        handleClose2();
                    } else {
                        alert("ERROR: Server Error");
                    }
                })
                .catch(error => {
                    manageError(error);
                });
        }
    };

    const handleCloseSaveEdit = (e) => {

        //e.preventDefault();
        let nombre_emp = dats_edit.nombre_edit;
        let dir_emp = dats_edit.dir_edit;
        let rut_emp = dats_edit.rut_edit;
        let nit_emp = dats_edit.nit_edit;
        let contacto_emp = dats_edit.contact_edit;
        let contactocargo_emp = dats_edit.contact_position_edit;
        let tel_emp1 = dats_edit.tel1_edit;
        let tel_emp2 = dats_edit.tel2_edit;
        let email_emp1 = dats_edit.email_edit;
        let tel_emp3 = dats_edit.tel_emp_edit;
        let email_emp2 = dats_edit.email_emp_edit;
        let desc_emp = dats_edit.descripcion_edit;
        let obs_emp = dats_edit.obs_edit;
        let ciudad_emp = ciudadproveedor === '' ? dats_edit.ciudad_edit : ciudadproveedor;

        const formattedData = {
            enterprice_id: data_emp.IdEmp,
            name: nombre_emp,
            address: dir_emp,
            rut: rut_emp,
            nit: nit_emp,
            cities_id: ciudad_emp,
            contact: contacto_emp,
            contact_position: contactocargo_emp,
            contact_phone1: tel_emp1,
            contact_phone2: tel_emp2,
            contact_email: email_emp1,
            vendors_phone: tel_emp3,
            vendors_email: email_emp2,
            description: desc_emp,
            observations: obs_emp,
            iva_regimen: dats_edit.regimen_ica === '' ? '' : parseFloat(dats_edit.regimen_ica),
            self_retaining: autoretenedor,
            ica_rate: dats_edit.tarifa_ica === '' ? '' : parseFloat(dats_edit.tarifa_ica),
        };
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_CRM_VENDORS, formattedData, attachToken(user))
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_CRM_VENDORS + '&id=' + empresabyid,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el proveedor exitósamente", "", "success");
                    peticionGetCategor();
                    handleClose3();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });
    };

    const formulario = document.querySelector('#formulario');

    const filtrar = (e) => {
        e.preventDefault();
        let data_filtrado = [];
        let datos_proveedores = categor.datos;
        const texto = formulario.value.toLowerCase();
        //let texto = inputfilter.formulario.toLowerCase();
        let input_text = inputfilter.formulario;

        if (input_text === '') {
            //console.log('no econtrado');
            data_filtrado = [];
            setInfoFiltro({
                ...infofiltro,
                ['datos']: data_filtrado,
            });
        } else {
            for (let i = 0; i < datos_proveedores.length; i++) {
                let nombre_emp = datos_proveedores[i].name.toLowerCase();
                if (nombre_emp.indexOf(texto) !== -1) {
                    data_filtrado.push(datos_proveedores[i]);
                    setInfoFiltro({
                        ...infofiltro,
                        ['datos']: data_filtrado,
                    });
                }
            }
        };
        //console.log(data_filtrado,texto);
    };

    const _exporter = React.createRef();

    const excelExport = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = categor.datos === undefined ? [] : categor.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = categor.datos === undefined ? [] : categor.datos.length / postPerPage; // numero total de paginas
    let pageNumbers = []; //se guardan los numeros de paginas

    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () => {
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () => {
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };

    const paginador = () => {
        return (
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{ cursor: 'pointer' }}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                                :
                                <li class="page-item" style={{ cursor: 'pointer' }}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{ cursor: 'pointer' }}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{ cursor: 'pointer' }}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                                :
                                <li class="page-item" style={{ cursor: 'pointer' }}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };

    if (!user) {
        window.location.href = Routes.LOGIN;
    }

    return (
        <div >
            {/*<Navbar modulo="Proveedores" usuario={user.name}/>*/}
            <div className="w-100">
                <br />
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                    <div className="d-flex w-100">
                        <div>
                            <Button className="botonpersonal me-2" size="sm" onClick={handleShow2}
                                style={{ backgroundColor: "#02B371", color: '#fff' }}>
                                Agregar Proveedor
                            </Button>
                        </div>
                        <div>
                            <Button className="botonpersonal me-2" size="sm" onClick={excelExport}
                                style={{ backgroundColor: "#02B371", color: '#fff' }}>
                                Descargar Excel
                            </Button>
                        </div>
                    </div>
                    <div className="text-start"> {/* barra de busqueda */}
                        <form onSubmit={filtrar}>
                            <div className="d-flex">
                                <div className="me-2">
                                    <FormControl
                                        type="search"
                                        placeholder="Search"
                                        className="me-2"
                                        aria-label="Search"
                                        id="formulario"
                                        name="formulario"
                                        style={{ width: '100%' }}
                                        onChange={handleInputChangeInputFilter}
                                    />
                                </div>
                                <div>
                                    <Button variant="outline-success" id="buscar" onClick={filtrar} style={{ background: '#02B371', color: '#fff' }}>
                                        Buscar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <br />
                <div>{/* formato de exportar excel */}
                    <ExcelExport
                        data={categor.datos}
                        fileName="Proveedores.xlsx"
                        ref={_exporter}
                    >
                        <ExcelExportColumn field="id" title="Id" width={90} />
                        <ExcelExportColumn field="name" title="Nombre Proveedor" />
                        <ExcelExportColumn field="rut" title="Rut Proveedor" />
                        <ExcelExportColumn field="nit" title="Nit Proveedor" />
                        <ExcelExportColumn field="contact" title="Contacto Proveedor" />
                        <ExcelExportColumn field="contact_position" title="Cargo contacto" />
                        <ExcelExportColumn field="contact_phone1" title="Telefono 1" />
                        <ExcelExportColumn field="contact_phone2" title="Telefono 2" />
                        <ExcelExportColumn field="contact_email" title="Email Contacto" />
                        <ExcelExportColumn field="vendors_phone" title="Telefono contacto" />
                        <ExcelExportColumn field="vendors_email" title="Email Empresarial" />
                        <ExcelExportColumn field="address" title="Direccion proveedor" />
                    </ExcelExport>
                </div>
                <Container> {/* seccion de proveedores */}
                    {categor.datos === undefined ?
                        <div className="mensajeinfo">
                            <h4>
                                En el momento no hay proveedores registrados.
                            </h4>
                        </div>
                        :
                        infofiltro.datos.length === 0 ?
                            <div>
                                <Row xs={12} md={4} className="g-4 mb-4">
                                    {currentPost.map(i => (
                                        <Col>
                                            <Card key={i} style={{ borderRadius: "1em", height: "auto" }} >
                                                <Card.Header className="card_header_custom" >
                                                    <div className="d-flex">
                                                        <div>
                                                            <div>
                                                                <span><b>{i.name}</b></span>
                                                            </div>
                                                        </div>
                                                        <div className="ms-auto d-flex justify-content-center align-items-center">
                                                            <div class="dropdown">
                                                                <BsIcons.BsThreeDotsVertical class="dropdown-toggle" type="button"
                                                                    data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setEmpresaById(i.id)}
                                                                />
                                                                <ul class="dropdown-menu menu_actas">
                                                                    <li>
                                                                        <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={handleShow3}>
                                                                            Editar
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => peticionDelete(i.id)}>
                                                                            Eliminar
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body className="card_body">
                                                    <Card.Text >
                                                        <p>
                                                            <b>Direccion</b>
                                                            <div>
                                                                {i.address}
                                                            </div>
                                                        </p>
                                                        <p>
                                                            <b>Nit</b>
                                                            <div>
                                                                {i.nit}
                                                            </div>
                                                        </p>
                                                        <p>
                                                            <b>Telefonos</b>
                                                            <div>
                                                                {i.vendors_phone}
                                                            </div>
                                                        </p>
                                                        <p>
                                                            <b>Correo de contacto</b>
                                                            <div>
                                                                {i.vendors_email}
                                                            </div>
                                                        </p>
                                                        <p>
                                                            <b>Descripcion</b>
                                                            <div className="scrollbar_item mb-3">
                                                                {i.description}
                                                            </div>
                                                        </p>
                                                        <p>
                                                            <b>Observaciones</b>
                                                            <div className="scrollbar_item">
                                                                {i.observations}
                                                            </div>
                                                        </p>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                                {paginador()}
                            </div>
                            :
                            <Row xs={12} md={4} className="g-4">
                                {infofiltro.datos.map(q => (
                                    <Col>
                                        <Card key={q} style={{ borderRadius: "1em", height: "auto" }} >
                                            <Card.Header className="card_header_custom" >
                                                <div className="d-flex">
                                                    <div>
                                                        <div>
                                                            <span><b>{q.name}</b></span>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto d-flex justify-content-center align-items-center">
                                                        <div class="dropdown">
                                                            <BsIcons.BsThreeDotsVertical class="dropdown-toggle" type="button"
                                                                data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setEmpresaById(q.id)}
                                                            />
                                                            <ul class="dropdown-menu menu_actas">
                                                                <li>
                                                                    <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={handleShow3}>
                                                                        Editar
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => peticionDelete(q.id)}>
                                                                        Eliminar
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body className="card_body">
                                                <Card.Text >
                                                    <p>
                                                        <b>Direccion</b>
                                                        <div>
                                                            {q.address}
                                                        </div>
                                                    </p>
                                                    <p>
                                                        <b>Nit</b>
                                                        <div>
                                                            {q.nit}
                                                        </div>
                                                    </p>
                                                    <p>
                                                        <b>Telefonos</b>
                                                        <div>
                                                            {q.vendors_phone}
                                                        </div>
                                                    </p>
                                                    <p>
                                                        <b>Correo de contacto</b>
                                                        <div>
                                                            {q.vendors_email}
                                                        </div>
                                                    </p>
                                                    <p>
                                                        <b>Descripcion</b>
                                                        <div className="scrollbar_item mb-3">
                                                            {q.description}
                                                        </div>
                                                    </p>
                                                    <p>
                                                        <b>Observaciones</b>
                                                        <div className="scrollbar_item">
                                                            {q.observations}
                                                        </div>
                                                    </p>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                    }
                    <br />
                </Container>
            </div>
            {/* MODAL NUEVA EMPRESA */}
            <Modal show={show2} onHide={handleClose2} size="xl" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Cargar Proveedor Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsBuilding className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre del proveedor" aria-label="Nombre del proveedor"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="nombre" name="nombre"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaMapMarkerAlt className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Direccion" aria-label="Direccion"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="dir" name="dir"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={2} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <AiIcons.AiOutlineNumber className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Rut" aria-label="Rut"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="rut" name="rut"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={2} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <AiIcons.AiOutlineNumber className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nit" aria-label="Nit"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="nit" name="nit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdLocationCity className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangePrioridad} id="ciudad" name="ciudad"
                                >
                                    <option value="">Seleccione la ciudad</option>
                                    {ciudades.datos.map(i => (
                                        <option value={i.id}>{i.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <AiIcons.AiOutlineContacts className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Contacto" aria-label="Contacto"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="contact" name="contact"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cargo del contacto" aria-label="Cargo del contacto"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="contact_position" name="contact_position"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsTelephonePlus className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono 1" aria-label="Telefono 1"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="tel1" name="tel1"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsTelephonePlus className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono 2" aria-label="Telefono 2"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="tel2" name="tel2"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineAlternateEmail className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Correo contacto" aria-label="Correo contacto"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="email" name="email"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillTelephoneFill className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono empresarial" aria-label="Telefono empresarial"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="tel_emp" name="tel_emp"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <AiIcons.AiTwotoneMail className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Correo empresarial" aria-label="Correo empresarial"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="email_emp" name="email_emp"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdLocationCity className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Regimen Iva" aria-label="Regimen Iva"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="regimen_ica" name="regimen_ica"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <div className="d-flex">
                                    <div className="me-2">
                                        Autoretenedor
                                    </div>
                                    <div>
                                        <Form>
                                            <Form.Check
                                                inline label="SI" id='radio1' name='radio1' value='yes'
                                                type='checkbox' onChange={handleChangeAuto}
                                            />
                                            <Form.Check
                                                inline label="NO" id='radio2' name='radio2' value='no'
                                                type='checkbox' onChange={handleChangeAuto}
                                            />
                                        </Form>
                                    </div>
                                </div>
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdLocationCity className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Tarifa Ica" aria-label="Tarifa Ica"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="tarifa_ica" name="tarifa_ica"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsCardText className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Observaciones" aria-label="Observaciones"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="obs" name="obs"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" placeholder="Descripcion"
                                        onChange={handleInputChange} id="descripcion" name="descripcion"
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={() => handleCloseSave()}>
                        Cargar Proveedor
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR EMPRESA */}
            <Modal show={show3} onHide={handleClose3} size="xl" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Proveedor Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsBuilding className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre del proveedor" aria-label="Nombre del proveedor"
                                    aria-describedby="basic-addon1" value={dats_edit.nombre_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="nombre_edit" name="nombre_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaMapMarkerAlt className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Direccion" aria-label="Direccion"
                                    aria-describedby="basic-addon1" value={dats_edit.dir_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="dir_edit" name="dir_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={2} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <AiIcons.AiOutlineNumber className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Rut" aria-label="Rut"
                                    aria-describedby="basic-addon1" value={dats_edit.rut_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="rut_edit" name="rut_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={2} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <AiIcons.AiOutlineNumber className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nit" aria-label="Nit"
                                    aria-describedby="basic-addon1" value={dats_edit.nit_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="nit_edit" name="nit_edit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdLocationCity className="text-primary" />
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangePrioridad} id="ciudad" name="ciudad"
                                >
                                    <option value="">Seleccione la ciudad</option>
                                    {ciudades.datos.map(i => (
                                        <option value={i.id}>{i.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <AiIcons.AiOutlineContacts className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Contacto" aria-label="Contacto"
                                    aria-describedby="basic-addon1" value={dats_edit.contact_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="contact_edit" name="contact_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineSubtitles className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cargo del contacto" aria-label="Cargo del contacto"
                                    aria-describedby="basic-addon1" value={dats_edit.contact_position_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="contact_position_edit" name="contact_position_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsTelephonePlus className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono 1" aria-label="Telefono 1"
                                    aria-describedby="basic-addon1" value={dats_edit.tel1_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tel1_edit" name="tel1_edit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsTelephonePlus className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono 2" aria-label="Telefono 2"
                                    aria-describedby="basic-addon1" value={dats_edit.tel2_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tel2_edit" name="tel2_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdOutlineAlternateEmail className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Correo contacto" aria-label="Correo contacto"
                                    aria-describedby="basic-addon1" value={dats_edit.email_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="email_edit" name="email_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsFillTelephoneFill className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Telefono empresarial" aria-label="Telefono empresarial"
                                    aria-describedby="basic-addon1" value={dats_edit.tel_emp_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tel_emp_edit" name="tel_emp_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <AiIcons.AiTwotoneMail className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Correo empresarial" aria-label="Correo empresarial"
                                    aria-describedby="basic-addon1" value={dats_edit.email_emp_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="email_emp_edit" name="email_emp_edit"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdLocationCity className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Regimen Iva" aria-label="Regimen Iva"
                                    aria-describedby="basic-addon1" value={dats_edit.regimen_ica}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="regimen_ica" name="regimen_ica"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <div className="d-flex">
                                    <div className="me-2">
                                        Autoretenedor
                                    </div>
                                    <div>
                                        <Form>
                                            <Form.Check
                                                inline label="SI" id='radio1_edit' name='radio1_edit' value='yes'
                                                type='checkbox' onChange={handleChangeAuto}
                                            />
                                            <Form.Check
                                                inline label="NO" id='radio2_edit' name='radio2_edit' value='no'
                                                type='checkbox' onChange={handleChangeAuto}
                                            />
                                        </Form>
                                    </div>
                                </div>
                            </InputGroup>
                        </Col>
                        <Col md={3} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdLocationCity className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Tarifa Ica" aria-label="Tarifa Ica"
                                    aria-describedby="basic-addon1" value={dats_edit.tarifa_ica}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tarifa_ica" name="tarifa_ica"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup.Text id="basic-addon1">
                                    <BsIcons.BsCardText className="text-primary" />
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Observaciones" aria-label="Observaciones"
                                    aria-describedby="basic-addon1" value={dats_edit.obs_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="obs_edit" name="obs_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{ width: "100%", margin: "auto" }}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary" />
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" placeholder="Descripcion"
                                        onChange={handleInputChangeEdit} id="descripcion_edit" name="descripcion_edit"
                                        value={dats_edit.descripcion_edit}
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={() => handleCloseSaveEdit()}>
                        Editar Proveedor
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CrmVendors;