import React, {useState, Fragment, useEffect} from "react";
import { 
    Row, Modal, Button, InputGroup, Form, 
    Col, FormControl, Table
} from 'react-bootstrap'
import Select  from 'react-select';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as GrIcons from "react-icons/gr";
import * as BiIcons from "react-icons/bi";
import {
    DEDALO_CONSTRUCCION_BY_ID, DEDALO_ALL_CONSTRUCTIONS_TASK,
    DEDALO_DEL_TAREA_CONSTRUCTION, DEDALO_CUADRILLAS, DEDALO_DEL_CUADRILLAS,
    DEDALO_ALL_BLUEPRINTS,DEDALO_DEL_BLUEPRINTS,DEDALO_BLUEPRINTS_BY_ID,
    DEDALO_NEW_BLUEPRINTS,DEDALO_EDIT_BLUEPRINTS,DEDALO_ALL_PERSONAL_CONSTRUCTION,
    DEDALO_PERSONAL_COMBO_CONSTRUCTION,DEDALO_ASIGN_PERSONAL_CONSTRUCTION,
    DEDALO_DEL_PERSONAL_CONSTRUCTION,DEDALO_CAMBIO_ESTADO_TASK,DEDALO_CUADRILLAS_BY_ID,
    DEDALO_ALL_INVENTORY,DEDALO_CONSTRUCCION_TASK_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";

const CambioEstadoTarea = (info) => {
    const {userToken} = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [selectestadotask, setSelectEstadoTask] = useState('');
    const [selectestadotaskid, setSelectEstadoTaskId] = useState();
    const [statustask, setStatusTask] = useState();

    const estado_tarea = info.dat;

    const handleChangeSelectEstado = (event) => {
        setSelectEstadoTask(event.target.value);
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = (e,status) => {
        setStatusTask(status);
        setShow4(true);
    };

    const handleCloseSaveCambioEstado = (e) => {
    
        //e.preventDefault();
        
        const formattedData = {
            status: selectestadotask,
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_CAMBIO_ESTADO_TASK+'&id='+info.id_cam,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se cambio el estado exitósamente", "", "success");
                document.getElementById("select_status").value = "";
                info.actu();
                info.data_pres();
                handleClose4();
            }else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    return (
        <div>
            <span onClick={handleShow4}>
                Cambiar de estado
            </span>
            <div>
                {/* MODAL CAMBIO DE ESTADO TAREA */}
                <Modal show={show4} onHide={handleClose4} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Cambiar estado de la tarea</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{width: "50%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsFillFilePostFill/>
                                    </InputGroup.Text>
                                    {estado_tarea === 'pending' ?
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangeSelectEstado} id="select_status" name="select_status"
                                        >
                                            <option value="">Seleccione el estado</option>
                                            <option value='in process'>
                                                En progreso
                                            </option>
                                            <option value='canceled'>
                                                Cancelado
                                            </option>
                                            <option value='closed'>
                                                Cerrado
                                            </option>
                                        </Form.Select>
                                    :estado_tarea === 'in process' ?
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangeSelectEstado} id="select_status" name="select_status"
                                        >
                                            <option value="">Seleccione el estado</option>
                                            <option value='closed'>
                                                Cerrado
                                            </option>
                                        </Form.Select>
                                    :estado_tarea === 'completed' ?
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangeSelectEstado} id="select_status" name="select_status"
                                        >
                                            <option value="">Seleccione el estado</option>
                                            <option value='closed'>
                                                Cerrado
                                            </option>
                                            <option value='reopened'>
                                                Reabierto
                                            </option>
                                        </Form.Select>
                                    :estado_tarea === 'closed' ?
                                        <div className="ml-2">
                                            <h4>la tarea se ha cerrado</h4>
                                        </div>
                                    :estado_tarea === 'canceled' ?
                                        <div className="ml-2">
                                            <h4>la tarea se ha cancelado</h4>
                                        </div>
                                    :estado_tarea === 'under review' ?
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangeSelectEstado} id="select_status" name="select_status"
                                        >
                                            <option value="">Seleccione el estado</option>
                                            <option value='closed'>
                                                Cerrado
                                            </option>
                                            <option value='reopened'>
                                                Reabierto
                                            </option>
                                        </Form.Select>
                                    :estado_tarea === 'reopened' ?
                                        <Form.Select aria-label="Default select example"
                                            onChange={handleChangeSelectEstado} id="select_status" name="select_status"
                                        >
                                            <option value="">Seleccione el estado</option>
                                            <option value='completed'>
                                                Completado
                                            </option>
                                            <option value='closed'>
                                                Cerrado
                                            </option>
                                        </Form.Select>
                                    :
                                        ''
                                    }
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose4}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={()=>handleCloseSaveCambioEstado()}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                
            </div>
        </div>
    );
}

export default CambioEstadoTarea;