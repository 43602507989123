import React, {useState, Fragment, useEffect} from "react";
import { 
    Row, Modal, Button, InputGroup, Form, 
    Col, FormControl, Table
} from 'react-bootstrap';
import * as BsIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";

import {
    DEDALO_COMBO_PRODUCTO,DEDALO_NUEVO_INVENTARIO,DEDALO_ALL_INVENTORY,
} from "../../../../assets/APIAdresses";
import {message_responses} from "../../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../../../hooks";
import Swal from "sweetalert2";
import {
    attachToken,
    manageError,
} from "../../../../utils/utils";

function InventarioNuevo(actualizar){
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const idProject = parseInt(datahomi.idProject);
    const {userToken} = useUserSession();
    const {user} = useUserSession();    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [productoselect, setProductoSelect] = useState('');

    const [dats, setDat] = useState({
        monto: '',
        monto_minimo: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const handleChangeEstadoTarea = (event) => {
        setProductoSelect(event.target.value);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const peticionGetUserCombo=async()=>{
        //let info1 = await axios.get(DEDALO_COMBO_PRODUCTO)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_PRODUCTO,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice.data);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                //alert(message_responses[response.data.message]);
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetUserCombo();
    }, []);

    const selectusuarios = [];
    if(categor.datos.length > 0){
        categor.datos.map(i => {
            selectusuarios.push({
                value: i.id,
                label: i.name,
            });
        });
    }
    
    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let monto = dats.monto;
        let monto_minimo = dats.monto_minimo;
        let producto_id = productoselect;
        
        const formattedData = {
            amount: monto,
            minimum_amount: monto_minimo,
            products_id: producto_id,
            constructions_id: idProject,
        };
        //console.log(formattedData);
        if(monto === '' || monto_minimo === '' || producto_id === '') {
            Swal.fire({
                icon: 'info',
                title: 'No se puede guardar el item nuevo',
                text: 'Porfavor verifique que los campos no este vacio',
            });
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NUEVO_INVENTARIO,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el item exitósamente", "", "success");
                    document.getElementById('monto').value = '';
                    document.getElementById('monto_minimo').value = '';
                    document.getElementById('select').value = '';
                    actualizar.actualizar();
                    handleClose();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    return (
        <div>
            <span variant="default" size="xs" className="md-btn-flat" 
                title="Agregar Item" onClick={handleShow} 
            >
                Agregar Item de Inventario
            </span>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Agregar Nuevo Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaSortAmountUpAlt className="me-2 text-primary"/>
                                    Cantidad
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad" aria-label="Monto"
                                    aria-describedby="basic-addon1"
                                    type="email" onChange={handleInputChange}
                                    id="monto" name="monto"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaSortAmountDown className="me-2 text-primary"/>
                                    Cant. Mínima
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad Minima" aria-label="Monto Minima"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="monto_minimo" name="monto_minimo"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <FaIcons.FaElementor/>
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example"
                                    onChange={handleChangeEstadoTarea} id="select" name="select"
                                >
                                    <option value="">Producto</option>
                                    {categor.datos.map(i => (
                                        <option value={i.id}>{i.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup> 
                        </Col>
                    </Row>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleCloseSave}>
                        Crear Inventario
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default InventarioNuevo;