import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,Dropdown,
} from 'react-bootstrap';
import {
    DEDALO_ALL_DEPARTAMENT,DEDALO_NEW_DEPARTAMENT,DEDALO_UPDATE_DEPARTAMENT,
    DEDALO_DEL_DEPARTAMENT,DEDALO_DEPARTAMENT_COMBO,DEDALO_DEPARTAMENT_BY_ID,
    DEDALO_CIUDADES,DEDALO_CRM_ENTERPRICES_COMBO,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {Redirect, useParams, Link} from "react-router-dom";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";



const DepartamentosEmp = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();   
    const {user} = useUserSession();
    const [depselect, setDepSelect] = useState();
    const [departamento, setDepartamento] = useState();

    const {IdDep} = useParams();

    const [dats, setDat] = useState({
        nombre: '',
        dir: '',
        rut: '',
        ciudad: '',
        tel1: '',
        tel2: '',
        email: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        nombre_edit: '',
        dir_edit: '',
        rut_edit: '',
        ciudad_edit: '',
        ciudadId_edit: '',
        tel_1_edit: '',
        tel_2_edit: '',
        email_edit: '',
    });

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [citiesdep, setCitiesDep] = useState({
        datos: [],
    });

    const [depcombo, setDepCombo] = useState({
        datos: [],
    });

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setDepartamento(e);
        setShow3(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {   
        setDat_Edit({
            ...dats_edit,
            [event.target.name] : event.target.value
        })
    };

    const handleChangeSelectDep = (event) => {
        setDepSelect(event.target.value);
    };

    const peticionGetCategor=async()=>{
        const params_data = {
            //enterprice_id: data_emp.IdEmp,
            enterprice_id: IdDep,
            search: '',
            perpage: 500,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CRM_ENTERPRICES+'?page=1&rows=100&order=name&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_DEPARTAMENT,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.data);
                if(datanotice.records.length === 0){
                    setCategor({
                        ...categor,
                        ['datos']: [],
                    });
                }else{
                    setCategor({
                        ...categor,
                        ['datos']: datanotice.records,
                    });
                }
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                //alert("Por favor discúlpanos :| , hemos tenido un error al cargar los departamentos");
                Swal.fire("En el momento no hay departamentos creados", "", "info");
            }
        });
      
        return info1;
    };

    const peticionGetCitiesCombo=async()=>{
        
        //let info1 = await axios.get(DEDALO_ALL_CRM_ENTERPRICES+'?page=1&rows=100&order=name&inorder=asc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CIUDADES,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setCitiesDep({
                    ...citiesdep,
                    ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar las ciudades");
            }
        });
      
        return info1;
    };

    const peticionGetDepCombo=async()=>{

        const params_data = {
            enterprice_id: IdDep,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_DEPARTAMENT_COMBO,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setDepCombo({
                    ...depcombo,
                    ['datos']: datanotice.data,
                });
            } else {
                //alert(message_responses[response.data.message]);
                setDepCombo({
                    ...depcombo,
                    ['datos']: [],
                });
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los departamentos.");
            }
        });
      
        return info1;
    };
    
    const peticionDelete = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_DEL_CRM_ENTERPRICES(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_DEPARTAMENT+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se ha eliminado el departamento exitosamente", "", "success");
                peticionGetCategor();
                setCategor({
                    ...categor,
                    ['datos']: [],
                });
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                Swal.fire("No se pudo eliminar el departamento", "", "warning");
            }
        });
        
    };

    const peticionGetDepartmentById=async()=>{
        if(departamento === undefined){
             let iu = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_DEPARTAMENT_BY_ID+'&id='+departamento,
                headers: {Authorization: userToken},
            })
            .then(response=>{
            if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setDat_Edit({
                        ...dats_edit,
                        ['nombre_edit']: datanotice.data.name,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
        
            return info1;
        }
     };
    
    useEffect(() => {
        peticionGetCategor();
        peticionGetCitiesCombo();
        peticionGetDepCombo();
    }, []);

    useEffect(() => {
        peticionGetDepartmentById();
    }, [departamento]);

    const handleCloseSave = (e) => {
    
        let nombre_emp = dats.nombre;
        
        const formattedData = {
            enterprice_id: parseInt(IdDep),
            name: nombre_emp,
            parent_id: parseInt(depselect),
        };
        //console.log(formattedData);
        if(nombre_emp === ''){
            Swal.fire({
                title: 'Atencion',
                text: 'El campo nombre departamento son obligatorios',
                icon: 'warning',
            });
        }else{
            axios({
                method: 'POST',
                url: DEDALO_NEW_DEPARTAMENT,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creo el departamento exitósamente", "", "success");
                    document.getElementById("nombre").value = "";
                    peticionGetCategor();
                    peticionGetDepCombo();
                    handleClose2();
                }else {
                    Swal.fire("El nombre del departamento ya existe", "", "warning");
                }
            })
            .catch(error => {
                manageError(error);
            });
        }
    };

    const handleCloseSaveEdit = (e) => {
    
        let nombre_emp_edit = dats_edit.nombre_edit;
        
        const formattedData = {
            enterprice_id: IdDep,
            name: nombre_emp_edit,
        };
        console.log(formattedData);
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_DEPARTAMENT+'&id='+departamento,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se edito el departamento exitósamente", "", "success");
                document.getElementById("nombre_edit").value = "";
                peticionGetCategor();
                handleClose3();
            }else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    return (
        <div>
            <div className="w-100 mt-3 dep_emp">
                <div className="d-flex container-fluid d-grid gap-2 d-md-flex">
                    <div>
                        <Button className="btn btn-success rounded-circle" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar"
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div className="text-center text-primary w-100 titulo_emp">
                        <h2>Departamentos de la empresa </h2>
                    </div>
                </div>
                <hr/>
                <div className="d-flex container-fluid d-grid gap-2 d-md-flex mb-3">
                    <Button className="btn btn-success" size="sm" onClick={handleShow2}>
                        Agregar nuevo departamento
                    </Button>
                </div>
                <div className="container">
                    <Row>
                        {categor.datos.map((item, index) => (
                            <Col md={4} xs={12} key={index} className="mb-2">
                                <Card className="border-rounded">
                                    <Card.Header as="h6" className="with-elements">
                                        <div className="card-header-title">
                                            Dep. {item.name}
                                        </div>
                                        <div className="card-header-elements ml-auto tableactions">
                                            <DropdownButton
                                                id="dropdown-button-dark-example2"
                                                variant="primary"
                                                menuVariant="dark"
                                                className="mt-2"
                                            >
                                                <Dropdown.Item onClick={()=>handleShow3(item.id)}>
                                                    Editar
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>peticionDelete(item.id)}>
                                                    Eliminar
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </Card.Header>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            {/* MODAL NUEVO DEPARTAMENTO */}
            <Modal show={show2} onHide={handleClose2} size="lg" centered className="iconosmodal">
                    <Modal.Header closeButton>
                        <Modal.Title>Crear departamento nuevo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsBuilding className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Nombre del departamento" aria-label="Nombre del departamento."
                                        aria-describedby="basic-addon1" required={true}
                                        type="text" onChange={handleInputChange}
                                        id="nombre" name="nombre"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <MdIcons.MdLocationCity className="text-primary"/>
                                    </InputGroup.Text>
                                    <Form.Select aria-label="Default select example"
                                        onChange={handleChangeSelectDep} id="select_city" name="select_city"
                                    >
                                        <option value="">Seleccione el departamento</option>
                                        {depcombo.datos.map((item, index) => (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="boton1" onClick={handleClose2}>
                            Cerrar
                        </Button>
                        <Button className="boton2" onClick={handleCloseSave}>
                            Cargar Departamento
                        </Button>
                    </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR DEPARTAMENTO */}
            <Modal show={show3} onHide={handleClose3} size="lg" centered className="iconosmodal">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Departamento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12} xs={12}>
                                <InputGroup className="mb-3" style={{width: "50%", margin: "auto"}}>
                                    <InputGroup.Text id="basic-addon1">
                                        <BsIcons.BsBuilding className="text-primary"/>
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Nombre de la Empresa" aria-label="Nombre de la Empresa"
                                        aria-describedby="basic-addon1" value={dats_edit.nombre_edit}
                                        type="text" onChange={handleInputChangeEdit}
                                        id="nombre_edit" name="nombre_edit"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="boton1" onClick={handleClose3}>
                            Cerrar
                        </Button>
                        <Button className="boton2" onClick={handleCloseSaveEdit}>
                            Editar Departamento
                        </Button>
                    </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DepartamentosEmp;