import React, {useState, Fragment, useEffect} from "react";
import { 
    Card, Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,Dropdown,
} from 'react-bootstrap';
import {
    DEDALO_ALL_TYPE_ITEMS,DEDALO_NEW_TYPE_ITEMS,DEDALO_UPDATE_TYPE_ITEMS,
    DEDALO_DEL_TYPE_ITEMS,DEDALO_TYPE_ITEMS_COMBO,DEDALO_TYPE_ITEMS_BY_ID,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Navbar from '../navbar';
import Swal from "sweetalert2";
import TipoProducto from './tipoproducto';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const ItemType = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {user} = useUserSession();
    const [product_type_id, setProduct_Type_Id] = useState('');

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [inputfilter, setInputFilter] = useState({
        formulario: '',
    });

    const [infofiltro, setInfoFiltro] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        name: '',
        desc: '',
    });

    const [dats_edit, setDat_Edit] = useState({
        name_edit: '',
        desc_edit: '',
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            enterprice_id: data_emp.IdEmp,
            perpage: 800,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_TYPE_ITEMS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice.data);
                  setCategor({
                    ...categor,
                    ['datos']: datanotice.records,
                  });
              } else {
                 //alert(message_responses[response.data.message]);
                 alert('error producto 1');
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetProductById=async(IdTypeItem)=>{
        let info_type = IdTypeItem;
        setProduct_Type_Id(info_type);

        if(info_type === undefined){
             let iu = 0;
        }else{
            //let info1 = await axios.get(DEDALO_ALL_PRODUCTS+'?page=1&rows=1000&order=name&inorder=desc&search=')
             let info1 = await axios({
                 method: 'POST',
                 url: DEDALO_TYPE_ITEMS_BY_ID+'&id='+info_type,
                 headers: {Authorization: userToken},
             })
             .then(response=>{
                if (response.data.code === '200') {
                     let datanotice = response.data.msg;
                     //console.log(datanotice.data);
                     setDat_Edit({
                         ...dats_edit,
                         ['name_edit']: datanotice.data.name,
                         ['desc_edit']: datanotice.data.description,
                     });
                  } else {
                     alert(message_responses[response.data.message]);
                  }
             })
             .catch(error => {
                 if (error.response && error.response.data) {
                     alert(
                         error.response.data.message === "VALIDATOR_ERROR"
                         ? error.response.data.data.error_message
                         : message_responses[error.response.data.message]
                     );
                 } else {
                     alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                 }
             });
          
            return info1;
        }
     };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (e) => {
        setShow3(true);
    };

    const handleInputChange = (event) => {
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {
        setDat_Edit({
            ...dats_edit,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeInputFilter = (event) => {
        setInputFilter({
            ...inputfilter,
            [event.target.name] : event.target.value
        })
    };

    const handleChangePag = (event) => {
        setCurrentPage(event.target.value);
    };

    const handleCloseSave = (e) => {

        let name = dats.name;
        let description = dats.desc;

        const formattedData = {
            name: name,
            description: dats.desc,
            enterprice_id: data_emp.IdEmp
        };
        //console.log(formattedData);
        if(name === ''){
            Swal.fire("No se pudo crear el tipo de item, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            //axios.post(DEDALO_NEW_PRODUCT_TYPE, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NEW_TYPE_ITEMS,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se creó el tipo de item exitósamente", "", "success");
                    document.getElementById('name').value = '';
                    document.getElementById('desc').value = '';
                    peticionGetCategor();
                    handleClose2();
                } else {
                    alert("ERROR: Server Error");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const handleCloseSaveEdit = (e) => {
        let name_edit = dats_edit.name_edit;
        let des_edit = dats_edit.desc_edit;

        const formattedData = {
            name: name_edit,
            description: des_edit,
            enterprice_id: data_emp.IdEmp
        };
        //console.log(formattedData);
        if(name_edit === ''){
            Swal.fire("No se pudo editar el tipo item, valide que los campos esten con la informacion pertinente", "", "warning");
        }else{
            axios({
                method: 'PUT',
                url: DEDALO_UPDATE_TYPE_ITEMS+'&id='+product_type_id,
                headers: {Authorization: userToken},
                data: formattedData,
            })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el tipo item exitósamente", "", "success");
                    peticionGetCategor();
                    handleClose3();
                } else {
                    Swal.fire("El item "+name_edit+" ingresado ya existe", "", "info");
                }
            })
            .catch(error => {
                manageError(error);
            })
        }
    };

    const peticionDelete = (iduser) => {
        let delId = iduser;

        axios({
            method: 'DELETE',
            url: DEDALO_DEL_TYPE_ITEMS+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el tipo de item exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const filtrar = async(e) => {
        e.preventDefault();
        let input_text = inputfilter.formulario;

        if(input_text === ''){
            setInfoFiltro({
                ...infofiltro,
                ['datos']: [],
            });
        }else{
            const params_data = {
                search: input_text,
                perpage: 900,
                page: 1,
                orderField: 'name',
                order: 'asc',
            };
            //console.log(params_data);
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_ALL_TYPE_ITEMS,
                headers: {Authorization: userToken},
                data: params_data
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    if(datanotice.length === 0){
                        mensaje_data(input_text);
                    }else{
                        setInfoFiltro({
                            ...infofiltro,
                            ['datos']: datanotice.records,
                        });
                    }
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                        ? error.response.data.data.error_message
                        : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
          
            return info1;
        }
    };

    const mensaje_data = (texto_in) => {
        setInfoFiltro({
            ...infofiltro,
            ['datos']: [],
        });
        return Swal.fire("No hay datos con el nombre o iniciales "+texto_in+" ingresado", "", "warning");
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(8);
    let indexOfLastPOst = currentPage === '' ? 1 * postPerPage : currentPage * postPerPage;
    let indexOfFirstPost = indexOfLastPOst - postPerPage;
    let currentPost = categor.datos === undefined ? [] : categor.datos.slice(indexOfFirstPost, indexOfLastPOst); //porcion de data a mostrar
    let div_pag_p = categor.datos === undefined ? [] : categor.datos.length/postPerPage; // numero total de paginas
    let pageNumbers = []; //se guardan los numeros de paginas

    const paginate = pageNumber => setCurrentPage(pageNumber);

    for (let i = 1; i <= Math.ceil(div_pag_p); i++) {
        pageNumbers.push(i);
    }

    const nextHandler = () =>{
        const nextPage = parseInt(currentPage) + 1;
        paginate(nextPage);
    };

    const prevHandler = () =>{
        const prevPage = parseInt(currentPage) - 1;
        paginate(prevPage);
    };

    const paginador = () =>{
        return(
            <div className="d-flex w-100">
                <div className="w-100">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-sm">
                            {currentPage <= 1 ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Ant.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={prevHandler}>
                                        Ant.
                                    </a>
                                </li>
                            }
                            <li class="page-item me-2" style={{cursor: 'pointer'}}>
                                <div>
                                    <Form.Select onChange={handleChangePag} size="sm">
                                        <option value="">Ir a la pagina</option>
                                        {pageNumbers.map(number => (
                                            <option value={number}>{number}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </li>
                            {currentPage === Math.ceil(div_pag_p) ?
                                <li class="page-item disabled" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2">
                                        Sig.
                                    </a>
                                </li>
                            :
                                <li class="page-item" style={{cursor: 'pointer'}}>
                                    <a class="page-link me-2" onClick={nextHandler}>
                                        Sig.
                                    </a>
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
                <div className="w-100 text-end">
                    <span>
                        pagina {currentPage === '' ? 1 : currentPage} de {Math.ceil(div_pag_p)}
                    </span>
                </div>
            </div>
        );
    };

    return(
        <div>
            <div>
                <Card className="tablaproductos mb-4">
                    <Card.Header as="h6" className="with-elements">
                        <div className="card-header-title">Tipo de Item</div>
                        <div className="card-header-elements ml-auto">
                            <div >
                                <DropdownButton
                                    id="dropdown-button-dark-example2" variant="secondary"
                                    menuVariant="dark" 
                                >
                                    <Dropdown.Item onClick={()=>handleShow2()}>
                                        Nuevo Tipo de Item
                                    </Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="mb-3 w-100 d-flex justify-content-center"> {/* barra de busqueda */}
                            <div>
                                <Form className="d-flex">
                                    <FormControl
                                        type="search" size="sm"
                                        placeholder="Ingrese el nombre"
                                        className="me-2"
                                        aria-label="Buscar"
                                        id="formulario"
                                        name="formulario"
                                        style={{width: '100%'}}
                                        onChange={handleInputChangeInputFilter}
                                    />
                                    <Button id="buscar" size="sm" onClick={filtrar} className="btn_buscar">
                                        Buscar
                                    </Button>
                                </Form>
                            </div>
                        </div>
                        {infofiltro.datos.length === 0 ?
                            <div>
                                <Table responsive bordered hover size="sm" >
                                    <thead className="bg-primary text-light">
                                        <tr className="text-center">
                                            <th>#</th>
                                            <th style={{width: '20em'}}>Nombre Item</th>
                                            <th style={{width: '24em'}}>Descripcion</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{fontSize: "0.87em"}}>
                                        {currentPost.map(i => (
                                            <tr className="text-center">
                                                <td>{i.id}</td>
                                                <td>{i.name}</td>
                                                <td>{i.description}</td>
                                                <td>
                                                    <div className="opciones">
                                                        <div class="dropdown icono_tipo_item">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                            >
                                                            </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1"
                                                                onClick={()=>peticionGetProductById(i.id)}
                                                            >
                                                                <li>
                                                                    <a class="dropdown-item" href="#" onClick={()=>handleShow3(i.id)}>
                                                                        Editar
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item" href="#" onClick={()=>peticionDelete(i.id)}>
                                                                        Eliminar
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {paginador()}
                            </div>
                        :
                            <div style={{maxHeight: '25em', overflowY: 'auto', overflowX: 'hidden'}}> {/* seccion de filtro */}
                                <Table responsive bordered hover size="sm" >
                                    <thead className="bg-primary text-light">
                                        <tr className="text-center">
                                            <th>#</th>
                                            <th style={{width: '20em'}}>Nombre Item</th>
                                            <th style={{width: '24em'}}>Descripcion</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{fontSize: "0.87em"}}>
                                        {infofiltro.datos.map(f => (
                                            <tr className="text-center">
                                                <td>{f.id}</td>
                                                <td>{f.name}</td>
                                                <td>{f.description}</td>
                                                <td>
                                                    <div className="opciones">
                                                        <div class="dropdown">
                                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown" aria-expanded="false"
                                                            >
                                                            </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1"
                                                                onClick={()=>peticionGetProductById(f.id)}
                                                            >
                                                                <li>
                                                                    <a class="dropdown-item" href="#" onClick={()=>handleShow3(f.id)}>
                                                                        Editar
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item" href="#" onClick={()=>peticionDelete(f.id)}>
                                                                        Eliminar
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </Card.Body>
                </Card>
            </div>

            {/* MODAL CREAR PRODUCTO */}
            <Modal show={show2} onHide={handleClose2} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Crear Tipo Item Nuevo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDriveFileRenameOutline/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre tipo item" aria-label="Nombre del item"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="name" name="name"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdPriceChange/>
                                </InputGroup.Text>
                                <FormControl as="textarea" aria-label="With textarea"
                                    placeholder="Descripcion" id="desc" name="desc"
                                    onChange={handleInputChange}
                                />
                            </InputGroup>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={()=>handleCloseSave()}>
                        Crear Tipo
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR TIPO PRODUCTO */}
            <Modal show={show3} onHide={handleClose3} size="md" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Tipo Producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdDriveFileRenameOutline/>
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre del producto" aria-label="Nombre del producto"
                                    aria-describedby="basic-addon1" value={dats_edit.name_edit}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="name_edit" name="name_edit"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    <MdIcons.MdPriceChange/>
                                </InputGroup.Text>
                                <FormControl as="textarea" aria-label="With textarea"
                                    placeholder="Descripcion" id="desc_edit" name="desc_edit"
                                    value={dats_edit.desc_edit} onChange={handleInputChangeEdit}
                                />
                            </InputGroup>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={()=>handleCloseSaveEdit()}>
                        Editar Tipo Producto
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ItemType;