import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Button, InputGroup, FormControl, Container, Row, Col,
  Form, Table,Card,Modal,
} from 'react-bootstrap';
import {
    DEDALO_GET_ALL_USERS_BY_ID, DEDALO_ELIMINAR_USUARIO,DEDALO_ACTUALIZAR_USUARIO,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {DateTimePicker} from '@material-ui/pickers';
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import { Routes } from "../../utils/routes";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import * as Bsicons from 'react-icons/bs';
import * as Aiicons from 'react-icons/ai';
import * as Hiicons from 'react-icons/hi';
import * as Biicons from "react-icons/bi";
import Navbar from '../navbar';
import logo from '../../imagenes/dedalo_logo.png';

function PerfilDedalo() {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userId} = useUserSession();
    const {userToken} = useUserSession();
    const {user} = useUserSession();
    const [userData, setUserData] = useState(Routes.PERFIL_DEDALO);

    const [categor, setCategor] = useState({
        datos: [],
    });

    const id = user.id;
    
    const peticionGetCategor=async()=>{
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS_BY_ID+'&id='+id)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_GET_ALL_USERS_BY_ID+'&id='+userId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
            alert(
                error.response.data.message === "VALIDATOR_ERROR"
                ? error.response.data.data.error_message
                : message_responses[error.response.data.message]
            );
            } else {
            alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
            }
        });
      
        return info1;
    }
    
    useEffect(() => {
        peticionGetCategor();
    }, []);

    const valores = window.location.pathname;

    let data_home = categor.datos.Enterprices === undefined ? [] : categor.datos.Enterprices.find(d_h => (parseInt(d_h.idEnterprice) === parseInt(data_emp.IdEmp)));

    if(!user){
        window.location.href = Routes.LOGIN;
    };
    
    return (
        <div>
            {/*<Navbar modulo="Editar usuarios" usuario={user.name}/>*/}
            <div className="w-100 mt-5">
                <Container>
                    <Card className="botonesaccion">
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col md={6} xs={12}>
                                        <div class="contentimgperfil">
                                            {categor.datos.image === null || categor.datos.image === '' ?
                                                <img src={logo} alt="dedalo" title={categor.datos.name}/>
                                            :
                                                <img src={categor.datos.image} alt="dedalo" title={categor.datos.name}/>
                                            }
                                        </div>
                                    </Col>
                                    <Col md={3} xs={12} className="cardtags">
                                        <p>
                                            <b>
                                                <Faicons.FaUserCircle className="me-2"/>
                                                Nombre
                                            </b> <br/>
                                            {categor.datos.name}
                                        </p>
                                        <p>
                                            <b>
                                                <Mdicons.MdContactPhone className="me-2"/>
                                                Telefono 1
                                            </b> 
                                            <br/>{categor.datos.phone1 === '0' ? 'No tiene' : categor.datos.phone1}
                                        </p>
                                        <p>
                                            <b>
                                                <Mdicons.MdContactPhone className="me-2"/>
                                                Telefono 2
                                            </b> 
                                            <br/>{categor.datos.phone2 === '0' ? 'No tiene' : categor.datos.phone2}
                                        </p>
                                        <p>
                                            <b>
                                                <Faicons.FaUserTie className="me-2"/>
                                                Tipo de usuario
                                            </b> <br/>
                                            {categor.datos.role_id === 1 ?
                                                'Usuario Standard'
                                            :categor.datos.role_id === 2 ?
                                                'Usuario Administrador'
                                            :categor.datos.role_id === 3 ?
                                                'Usuario Soporte'
                                            :categor.datos.role_id === 5 ?
                                                'Usuario Planos'
                                            :categor.datos.role_id === 6 ?
                                                'Usuario Administrador'
                                            :
                                                'Usuario Supervisor'
                                            }
                                        </p>
                                        <br/>
                                        {valores === userData ?
                                            <p>
                                                <Button className="btn btn-success" style={{backgroundColor: "#02B371"}}>
                                                    <Mdicons.MdModeEdit className="me-2"/>
                                                    <Link to={Routes.EDITAR_USUARIO(id)} style={{color: "white", textDecoration: "none"}}>
                                                        Editar Informacion
                                                    </Link>
                                                </Button>
                                            </p>
                                        :
                                            parseInt(categor.datos.role_id) === 2 || parseInt(categor.datos.role_id) === 3 || parseInt(categor.datos.role_id) === 7 ?
                                                <p>
                                                    <Link to={Routes.REPORTE} style={{color: "white", textDecoration: "none"}}>
                                                        <Button className="btn btn-success" style={{backgroundColor: "#02B371"}}>
                                                            <Hiicons.HiDocumentReport className="me-2"/>
                                                            Generar Reporte
                                                        </Button>
                                                    </Link>
                                                </p>
                                            :
                                                null
                                        }
                                    </Col>
                                    <Col md={3} xs={12} className="cardtags">
                                        <p>
                                            <b>
                                                <Faicons.FaAddressBook className="me-2"/>
                                                Dirección
                                            </b> 
                                            <br/>{categor.datos.address}
                                        </p>
                                        <p>
                                            <b>
                                                <Mdicons.MdEmail className="me-2"/>
                                                Correo electronico
                                            </b> 
                                            <br/>{categor.datos.email}
                                        </p>
                                        <p>
                                            <b>
                                                <Aiicons.AiFillProject className="me-2"/>
                                                Proyecto
                                            </b> 
                                            <br/>{categor.datos.constructionname}
                                        </p>
                                        <p>
                                            <b>
                                                <Mdicons.MdLocationPin className="me-2"/>
                                                Ubicacion del Proyecto
                                            </b> 
                                            <br/>{categor.datos.constructionaddress}
                                        </p>
                                        <p>
                                            <b>
                                                <Faicons.FaBuilding className="me-2"/>
                                                Empresa
                                            </b> 
                                            <br/>{data_home.nameEnterprice}
                                        </p>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
            
        </div>
    );
}

export default PerfilDedalo;