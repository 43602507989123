import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,FloatingLabel,
} from 'react-bootstrap';
import {
    DEDALO_UPDATE_QUOTATION,DEDALO_QUOTATION_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import {Mensaje_Error_Create_Cot} from '../../mensajeserror/mensajeserror';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from "sweetalert2";
import * as MdIcons from "react-icons/md";

const Cotizacion_Editar = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();
    const [body, setBody] = useState('');
    const [body1, setBody1] = useState('');

    let {IdCotEdit} = useParams()

    const [dats_edit, setDat_Edit] = useState({
        name: '',
        observations: '',
        document: '',
    });

    const peticionGetVendorById=async()=>{
        let id_cotizacion = parseInt(IdCotEdit);

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_QUOTATION_BY_ID+'&id='+id_cotizacion,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice.data);
                setDat_Edit({
                    ...dats_edit,
                    ['name']: datanotice.data.name,
                    ['observations']: datanotice.data.observations,
                    ['document']: datanotice.data.document,
                });
                setBody(datanotice.data.document);
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar las empresas");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetVendorById();
    }, [IdCotEdit]);

    const handleInputChangeData = (event) => {
        setDat_Edit({
            ...dats_edit,
            [event.target.name] : event.target.value
        })
    };

    const handleChange = (e) => {
        //console.log(e);
        setBody(e);
    };

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, 
                { 'font': ['Georgia', 'times-new-roman', 'arial'] }
            ],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
                {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'link', 'image', 'video', 
        'code-block','align'
    ];

    const handleCloseSaveEdit = (e) => {
            
        const formattedData = {
            name: dats_edit.name,
            users_id: userId,
            enterprice_id: data_emp.IdEmp,
            document: body,
            observations: dats_edit.observations
        };
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_CRM_VENDORS, formattedData, attachToken(user))
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_QUOTATION+'&id='+parseInt(IdCotEdit),
            headers: {Authorization: userToken},
            data: formattedData,
        })
            .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se edito la cotizacion exitósamente", "", "success");
                peticionGetVendorById();
            }else {
                Mensaje_Error_Create_Cot(response.data.msg.error)
            }
        })
        .catch(error => {
            manageError(error);
        });
    };

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" style={{backgroundColor: "#02B371"}}
                            onClick={handleCloseSaveEdit}
                        >
                            Guardar Cotizacion editada
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mt-4 container-fluid text-center">
                <h3>
                    Cotizacion a editar
                </h3>
                <hr/>
                <div>
                    <div className="mb-4 px-4">
                        <Row>
                            <Col md={5} xs={12}>
                                <FormControl
                                    placeholder="Nombre de la cotizacion" aria-label="Nombre de la cotizacion"
                                    aria-describedby="basic-addon1" value={dats_edit.name}
                                    type="text" onChange={handleInputChangeData}
                                    id="name" name="name"
                                />
                            </Col>
                            <Col md={7} xs={12}>
                                <FormControl as="textarea" aria-label="With textarea" placeholder="Descripcion"
                                    onChange={handleInputChangeData} id="observations" name="observations"
                                    value={dats_edit.observations}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="container mb-4">
                        <ReactQuill
                            className="bg-light text-dark area_acta"
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder="Escribe aquí..."
                            value={body}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cotizacion_Editar;