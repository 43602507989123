import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,FloatingLabel,
} from 'react-bootstrap';
import {
    DEDALO_ALL_COST_CENTER,DEDALO_NEW_COST_CENTER,DEDALO_UPDATE_COST_CENTER,
    DEDALO_DEL_COST_CENTER,DEDALO_COST_CENTER_BY_ID
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
//import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import Swal from "sweetalert2";
import {
    Mensaje_Error_Create_Cost,Mensaje_Error_Edit_Cost
} from '../../mensajeserror/mensajeserror';
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";
import Img1 from './imagenes_reportes/logo_geo.png';

const Centro_Costos = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession(); 
    const {user} = useUserSession();
    const [editcentrocosto, setEditCentroCosto] = useState('');
    const [estado,setEstado] = useState('');
    const [prioridad,setPrioridad] = useState('');

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [costbyid, setCostById] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        codigo: '',
        nombre: '',
        grupo: '',
        cantidad: '',
        valor: '',
        departamento: '',
        categoria: '',
        seccion: '',
        recurso: '',
        tipo: '',
        clase: '',
        nivel: '',
        observaciones: '',
    });

    const [datsedit, setDatEdit] = useState({
        codigo: '',
        nombre: '',
        grupo: '',
        cantidad: '',
        valor: '',
        departamento: '',
        categoria: '',
        seccion: '',
        recurso: '',
        tipo: '',
        clase: '',
        nivel: '',
        observaciones: '',
        estado: '',
        prioridad: '',
    });

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (e) => {
        setShow2(true);
    };

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (id_cost) => {
        peticionGetCostById(id_cost);
        setEditCentroCosto(id_cost);
        setShow3(true);
    };

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeEdit = (event) => {   
        setDatEdit({
            ...datsedit,
            [event.target.name] : event.target.value
        })
    };

    const handleInputChangeStatus = (event) => {
        setEstado(event.target.value);
    };

    const handleInputChangePrioridad = (event) => {
        setPrioridad(event.target.value);
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const peticionGetCategor=async()=>{
        const params_data = {
            search: '',
            perpage: 10000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_INVENTORY_REQUIREMENT+"?page=1&rows=100&order=&inorder=desc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_COST_CENTER,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice.records,
                });
            } else {
                Swal("!! Atencion !!","En el momento no hay datos para mostrar","info");
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert('salida');
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 2");
            }
        });
      
        return info1;
    };

    const peticionGetCostById= async(idedit)=>{
        let id_edit = idedit;
        
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COST_CENTER_BY_ID+'&id='+id_edit,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setDatEdit({
                    ...datsedit,
                    ["codigo"]: datanotice. code,
                    ["nombre"]: datanotice.name,
                    ["grupo"]: datanotice.groups,
                    ["cantidad"]: datanotice.amount,
                    ["valor"]: datanotice.value,
                    ["departamento"]: datanotice.department,
                    ["categoria"]: datanotice.category,
                    ["seccion"]: datanotice.section,
                    ["recurso"]: datanotice.resource,
                    ["tipo"]: datanotice.type,
                    ["clase"]: datanotice.class,
                    ["nivel"]: datanotice.level,
                    //["observaciones"]: datanotice.,
                    ["estado"]: datanotice.status,
                    ["prioridad"]: datanotice.priority,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
            }
        });
      
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
    }, []);

    const peticionDelete = (iduser) => {
        let delId = iduser;
        //console.log(delId);
        //axios.delete(DEDALO_DEL_INVENTORY_REQUIREMENT(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_COST_CENTER+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino el requerimiento exitósamente", "", "success");
                //window.location.reload();
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const handleCloseSaveCost = (e) => {
    
        //e.preventDefault();
        
        const formattedData = {
            name: dats.nombre,
            enterprice_id: parseInt(data_emp.IdEmp),
            code: dats.codigo,
            status: estado,
            groups: dats.grupo,
            amount: parseInt(dats.cantidad),
            users_id: parseInt(userId),
            value: parseInt(dats.valor),
            department: dats.departamento,
            category: dats.categoria,
            section: dats.seccion,
            resource: dats.recurso,
            type: dats.tipo,
            class: dats.clase,
            level: parseInt(dats.nivel),
            priority: prioridad,
            observations: dats.observaciones,
        };
        //console.log(formattedData);
        axios({
            method: 'POST',
            url: DEDALO_NEW_COST_CENTER,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se creo el centro de costo exitósamente", "", "success");
                peticionGetCategor();
                handleClose2();
            }else {
                Mensaje_Error_Create_Cost(response.data.msg.error);
            }
        })
        //.catch(error => {
        //    manageError(error);
        //});
    };
    
    const handleCloseSaveCostEdit = (e) => {
    
        //e.preventDefault();
        
        const formattedData = {
            name: datsedit.nombre,
            enterprice_id: parseInt(data_emp.IdEmp),
            code: datsedit.codigo,
            status: estado === '' ? datsedit.estado : estado,
            groups: datsedit.grupo,
            amount: parseInt(datsedit.cantidad),
            users_id: parseInt(userId),
            value: parseInt(datsedit.valor),
            department: datsedit.departamento,
            category: datsedit.categoria,
            section: datsedit.seccion,
            resource: datsedit.recurso,
            type: datsedit.tipo,
            class: datsedit.clase,
            level: parseInt(datsedit.nivel),
            priority: prioridad === '' ? datsedit.prioridad : prioridad,
            observations: dats.observaciones,
        };
        //console.log(formattedData);
        axios({
            method: 'PUT',
            url: DEDALO_UPDATE_COST_CENTER+'&id='+parseInt(editcentrocosto),
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se edito el centro exitósamente", "", "success");
                peticionGetCategor();
                handleClose3();
            }else {
                Mensaje_Error_Edit_Cost(response.data.msg.error);
            }
        })
        //.catch(error => {
        //    manageError(error);
        //});
    };

    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm" 
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" onClick={handleShow2}
                            title="Nuevo" style={{backgroundColor: "#02B371"}}
                        >
                            Crear Centro de costos
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid text-center mb-4">
                <h3>
                    <b>Centro de costos generados</b>
                </h3>
            </div>
            <div className="container-fluid">
                <Table responsive striped size="sm">
                    <thead>
                        <tr className="text-center fw-bold">
                            <td>id</td>
                            <td>Nombre</td>
                            <td>Empresa</td>
                            <td>Codigo</td>
                            <td>Estado</td>
                            <td>Grupo</td>
                            <td>Cantidad</td>
                            <td>Valor</td>
                            <td>Departamento</td>
                            <td>Categoria</td>
                            <td>Seccion</td>
                            <td>Recurso</td>
                            <td>Tipo</td>
                            <td>Clase</td>
                            <td>Nivel</td>
                            <td>Prioridad</td>
                            <td>Acciones</td>
                        </tr>
                    </thead>
                    <tbody>
                        {categor.datos === undefined ?
                            ''
                        :
                            categor.datos.map((i,index)=>(
                                <tr className="text-center" style={{fontSize: '0.8em'}}>
                                    <td>{index+1}</td>
                                    <td>{i.name}</td>
                                    <td>{i.enterprice_name}</td>
                                    <td>{i.code}</td>
                                    <td>
                                        {i.status === 'blocked' ?
                                            'Bloqueado'
                                        :i.status === 'active' ?
                                            'Activo'
                                        :
                                            'Inactivo'
                                        }
                                    </td>
                                    <td>{i.groups}</td>
                                    <td>{parseInt(i.amount)}</td>
                                    <td>{formatterPeso.format(i.value)}</td>
                                    <td>{i.department}</td>
                                    <td>{i.category}</td>
                                    <td>{i.section}</td>
                                    <td>{i.resource}</td>
                                    <td>{i.type}</td>
                                    <td>{i.class}</td>
                                    <td>{i.level}</td>
                                    <td>
                                        {i.priority === 'hight' ?
                                            'Alto'
                                        :i.priority === 'medium' ?
                                            'Medio'
                                        :
                                            'Bajo'
                                        }
                                    </td>
                                    <td>
                                        <div class="dropdown acciones_folders">
                                            <BsIcons.BsThreeDotsVertical role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                                                className="dropdown-toggle"
                                            />
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <li>
                                                    <a class="dropdown-item cursor-pointer" onClick={()=>handleShow3(i.id)}>
                                                        <MdIcons.MdModeEditOutline style={{color: '#2471a3',fontSize: '1.2em'}}
                                                            className="me-2"
                                                        />
                                                        Editar
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item cursor-pointer" onClick={()=>peticionDelete(i.id)}>
                                                        <MdIcons.MdDelete style={{color: '#e74c3c',fontSize: '1.2em'}}
                                                            className="me-2"
                                                        />
                                                        Eliminar
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            {/* MODAL CREAR CENTRO DE COSTOS */}
            <Modal show={show2} onHide={handleClose2} size="xl" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Crear Centro de Costo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={3} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Codigo:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Codigo" aria-label="Codigo"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="codigo" name="codigo"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Estado:
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={handleInputChangeStatus}
                                    id="select_product" name="select_product"
                                >
                                    <option value="">Seleccione Status</option>
                                    <option value="active">Activo</option>
                                    <option value="blocked">Bloqueado</option>
                                    <option value="inactive">Inactivo</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={5} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Nombre:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre centro costo" aria-label="Nombre centro costo"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="nombre" name="nombre"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Grupo:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="grupo del centro" aria-label="grupo del centro"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="grupo" name="grupo"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Cantidad:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad centro costo" aria-label="Cantidad centro costo"
                                    aria-describedby="basic-addon1"
                                    type="number" onChange={handleInputChange}
                                    id="cantidad" name="cantidad"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Valor:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Valor centro costo" aria-label="Valor centro costo"
                                    aria-describedby="basic-addon1"
                                    type="number" onChange={handleInputChange}
                                    id="valor" name="valor"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Departamento:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Departamento centro de costo" aria-label="Departamento centro de costo"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="departamento" name="departamento"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Categoria:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Categoria centro de costo" aria-label="Categoria centro de costo"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="categoria" name="categoria"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Seccion:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Seccion centro de costo" aria-label="Seccion centro de costo"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="seccion" name="seccion"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Recurso:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Recurso centro de costo" aria-label="Recurso centro de costo"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="recurso" name="recurso"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Tipo:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Tipo centro de costo" aria-label="Tipo centro de costo"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="tipo" name="tipo"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Clase:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Clase centro de costo" aria-label="Clase centro de costo"
                                    aria-describedby="basic-addon1"
                                    type="text" onChange={handleInputChange}
                                    id="clase" name="clase"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Nivel:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nivel centro de costo" aria-label="Nivel centro de costo"
                                    aria-describedby="basic-addon1"
                                    type="number" onChange={handleInputChange}
                                    id="nivel" name="nivel"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Prioridad:
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={handleInputChangePrioridad}
                                    id="select_prioridad" name="select_prioridad"
                                >
                                    <option value="">Prioridad</option>
                                    <option value="hight">Alta</option>
                                    <option value="medium">Media</option>
                                    <option value="low">Baja</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} ms={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary me-2"/>
                                        Observaciones:
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" 
                                        onChange={handleInputChange} id="observaciones" name="observaciones"
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose2}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={handleCloseSaveCost}>
                        Crear Centro
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* MODAL EDITAR CENTRO DE COSTOS */}
            <Modal show={show3} onHide={handleClose3} size="xl" centered className="iconosmodal">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Centro de Costo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={3} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Codigo:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Codigo" aria-label="Codigo"
                                    aria-describedby="basic-addon1" value={datsedit.codigo}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="codigo" name="codigo"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Estado:
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={handleInputChangeStatus}
                                    id="select_product" name="select_product"
                                >
                                    <option value="">{datsedit.estado}</option>
                                    <option value="active">Activo</option>
                                    <option value="blocked">Bloqueado</option>
                                    <option value="inactive">Inactivo</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={5} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Nombre:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nombre centro costo" aria-label="Nombre centro costo"
                                    aria-describedby="basic-addon1" value={datsedit.nombre}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="nombre" name="nombre"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Grupo:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="grupo del centro" aria-label="grupo del centro"
                                    aria-describedby="basic-addon1" value={datsedit.grupo}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="grupo" name="grupo"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Cantidad:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Cantidad centro costo" aria-label="Cantidad centro costo"
                                    aria-describedby="basic-addon1" value={datsedit.cantidad}
                                    type="number" onChange={handleInputChangeEdit}
                                    id="cantidad" name="cantidad"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Valor:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Valor centro costo" aria-label="Valor centro costo"
                                    aria-describedby="basic-addon1" value={datsedit.valor}
                                    type="number" onChange={handleInputChangeEdit}
                                    id="valor" name="valor"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Departamento:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Departamento centro de costo" aria-label="Departamento centro de costo"
                                    aria-describedby="basic-addon1" value={datsedit.departamento}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="departamento" name="departamento"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Categoria:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Categoria centro de costo" aria-label="Categoria centro de costo"
                                    aria-describedby="basic-addon1" value={datsedit.categoria}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="categoria" name="categoria"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Seccion:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Seccion centro de costo" aria-label="Seccion centro de costo"
                                    aria-describedby="basic-addon1" value={datsedit.seccion}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="seccion" name="seccion"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Recurso:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Recurso centro de costo" aria-label="Recurso centro de costo"
                                    aria-describedby="basic-addon1" value={datsedit.recurso}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="recurso" name="recurso"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Tipo:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Tipo centro de costo" aria-label="Tipo centro de costo"
                                    aria-describedby="basic-addon1" value={datsedit.tipo}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="tipo" name="tipo"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Clase:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Clase centro de costo" aria-label="Clase centro de costo"
                                    aria-describedby="basic-addon1" value={datsedit.clase}
                                    type="text" onChange={handleInputChangeEdit}
                                    id="clase" name="clase"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto", }}>
                                <InputGroup.Text id="basic-addon1">
                                    Nivel:
                                </InputGroup.Text>
                                <FormControl
                                    placeholder="Nivel centro de costo" aria-label="Nivel centro de costo"
                                    aria-describedby="basic-addon1"value={datsedit.nivel}
                                    type="number" onChange={handleInputChangeEdit}
                                    id="nivel" name="nivel"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={4} ms={12}>
                            <InputGroup className="mb-3" size="sm" style={{width: "100%", margin: "auto"}}>
                                <InputGroup.Text id="basic-addon1">
                                    Prioridad:
                                </InputGroup.Text>
                                <Form.Select aria-label="Default select example" onChange={handleInputChangePrioridad}
                                    id="select_prioridad" name="select_prioridad"
                                >
                                    <option value="">{datsedit.prioridad}</option>
                                    <option value="hight">Alta</option>
                                    <option value="medium">Media</option>
                                    <option value="low">Baja</option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} ms={12}>
                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <BsIcons.BsChatText className="text-primary me-2"/>
                                        Observaciones:
                                    </InputGroup.Text>
                                    <FormControl as="textarea" aria-label="With textarea" 
                                        onChange={handleInputChange} id="observaciones" name="observaciones"
                                    />
                                </InputGroup>
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="boton1" onClick={handleClose3}>
                        Cerrar
                    </Button>
                    <Button className="boton2" onClick={handleCloseSaveCostEdit}>
                        Editar Centro
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Centro_Costos;