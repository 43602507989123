import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Card, Button, InputGroup, FormControl, Container, Row, Col,
  Form,
} from 'react-bootstrap';
import {
    DEDALO_RESET_PASSWORD_OUT
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import { 
    BsFillPeopleFill, BsFillPersonFill, BsFillTelephoneFill
} from "react-icons/bs";
import { 
    MdSecurity, MdAddLocationAlt
} from "react-icons/md";
import { Routes } from "../../utils/routes";
import Navbar from '../navbar';


function SetResetPass() {
    const [redirectTo, setRedirectTo] = useState();
    const {userToken} = useUserSession();
    //const {user} = useUserSession();
    const [age, setAge] = React.useState('');
    const [boton, setBoton] = useState(0);
    const [dats, setDat] = useState({
        correo: '',
        pass: '',
        c_pass: '',
    });
    
    const valores = window.location.search;
    const urlParams = new URLSearchParams(valores);
    var emailurl = urlParams.get('email');
    var keyurl = urlParams.get('key');
    
    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleCloseSave = (e) => {
    
        //e.preventDefault();
        let email = emailurl;
        let pwd = dats.pass;
        let cpwd = dats.c_pass;
        let urlkey = keyurl;
        
        const formattedData = {
            email: email,
            key: urlkey, 
            password: pwd,
            c_password: cpwd,
        };
        //console.log(formattedData);
        //axios.post(DEDALO_RESET_PASSWORD, formattedData)
        axios({
            method: 'POST',
            url: DEDALO_RESET_PASSWORD_OUT,
            headers: {Authorization: userToken},
            data: formattedData,
        })
          .then(response => {
            
            if (response.data.code === '200') {
                Swal.fire("Se reestablecio la contraseña del correo "+email+" registrado.", "", "success");
                document.getElementById('pass').value = '';
                document.getElementById('c_pass').value = '';
                setBoton(1);
            } else {
                Swal.fire("Las contraseñas no son las mismas, porfavor validen que sean iguales", "", "info");
            }
        })
        .catch(error => {
            Swal.fire("Las contraseñas no son las mismas, porfavor validen que sean iguales", "", "info");
        })
    };


    return (
        <div>
            <Navbar modulo="Reestablecer contraseña"/>
            
            <div className="w-100">
                <Container className="w-50" style={{minHeight: '86vh'}}>
                    <Card style={{ marginTop: "7%"}}>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col>
                                        <p>
                                            Ingrese los datos que se muestran a continuacion para reestablecer la contraseña.
                                        </p>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                            <FormControl
                                                placeholder="Correo" aria-label="Correo"
                                                aria-describedby="basic-addon1"
                                                type="email" onChange={handleInputChange}
                                                id="correo" name="correo" value={emailurl}
                                                disabled
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdSecurity/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Nueva Contraseña" aria-label="Contraseña"
                                                aria-describedby="basic-addon1"
                                                type="password" onChange={handleInputChange}
                                                id="pass" name="pass"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <MdSecurity/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Confirmar Contraseña" aria-label="Confirmar Contraseña"
                                                aria-describedby="basic-addon1"
                                                type="password" onChange={handleInputChange}
                                                id="c_pass" name="c_pass"
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col style={{textAlign: "center"}}>
                                        {boton === 1 ?
                                            <Button variant="primary" onClick={()=>{window.location.href = Routes.HOME}}>
                                                Regresar
                                            </Button>
                                        :
                                            <Button className="btn btn-success" onClick={()=>handleCloseSave()} style={{backgroundColor: "#02B371"}}>
                                                Restablecer contraseña
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </div>
    );
}

export default SetResetPass;
