import React, {useState, Fragment, useEffect} from "react";
import { 
  Card, Button, Container, Row, Col,
  Table,NavDropdown,Nav,InputGroup,Form
} from 'react-bootstrap';
import {
    DEDALO_GET_ALL_USERS,DEDALO_CHANGE_STATUS_SUBTASK,
    DEDALO_ALL_CONSTRUCTIONS_SUBTASK,DEDALO_DEL_SUBTASK,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import Navbar from '../../navbar';
import { Routes } from '../../../utils/routes';
import LogoDedalo from '../../../imagenes/dedalo_logo.png';
import * as MdIcons from 'react-icons/md';

function TablaSubTareas() {
    const {userToken} = useUserSession();
    const {idtarea} = useParams();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = parseInt(datahomi.idProject);
    const {userId} = useUserSession();
    const {user} = useUserSession();
    
    const [categor, setCategor] = useState({
        datos: [],
    });

    const [usuariosinfo, setUsuariosInfo] = useState({
        datos: [],
    });
    
    const peticionGetCategor=async()=>{
        const params_data = {
            constructions_task_id: parseInt(idtarea),
            search: '',
            perpage: 200,
            page: 1,
            orderField: 'title',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_ALL_CONSTRUCTIONS_SUBTASK+"?constructions_task_id="+idtarea+"&page=1&rows=100&order=id&inorder=asc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_CONSTRUCTIONS_SUBTASK,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice.records,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
            } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos.-");
            }
        });
      
        return info1;
    }
    
    const peticionDelete = (iduser) => {
        let delId = iduser;
        
        //axios.delete(DEDALO_DEL_SUBTASK(delId), attachToken(user))
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_SUBTASK+'&id='+delId,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                Swal.fire("Se elimino la Subtarea exitósamente", "", "success");
                peticionGetCategor();
            } else {
                alert("ERROR: Server Error");
            }
        })
    };

    const peticionGetAllUsers=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS+"?page=1&rows=1000&order=id&inorder=desc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_GET_ALL_USERS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice);
                setUsuariosInfo({
                    ...usuariosinfo,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    }
    
    useEffect(() => {
        peticionGetCategor();
        peticionGetAllUsers();
    }, []);


    let responsablessubtarea = [];
    let observadoressubtarea = [];
    let participantessubtarea = [];

    if(categor.datos === undefined){
        let yu = null;
    }else{
        categor.datos.map(e => (
            responsablessubtarea.push({id: e.id, resp: e.Responsable}),
            observadoressubtarea.push({id: e.id, resp: e.Observers}),
            participantessubtarea.push({id: e.id, resp: e.Participants})
        ));
    };

    const CambiarEstado = (e) => {
        let status_id = e;

        const formattedData = {
            status: 'in process',
        };
        
        //console.log(formattedData);
        //axios.post(DEDALO_NEW_SUBTASK, formattedData, attachToken(user))
        axios({
            method: 'PUT',
            url: DEDALO_CHANGE_STATUS_SUBTASK+'&id='+status_id,
            headers: {Authorization: userToken},
            data: formattedData,
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se creó la Subtarea exitósamente", "", "success");
            } else {
                alert("ERROR: Server Error");
            }
        })
        //.catch(error => {
        //    manageError(error);
        //})
    };    

    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div >
            {/*<Navbar modulo="Tabla Subtareas" usuario={user.name}/>*/}
            <br/>
            <div className="w-100">
                <div className="boton_regresar">
                    <Button className="btn btn-primary me-2 rounded-circle ml-4" title="Regresar" 
                        onClick={()=>{window.history.back()}} size="sm"
                    >
                        <MdIcons.MdOutlineKeyboardBackspace/>
                    </Button>
                    {user.role_id === 6 || user.role_id === 5 || user.role_id === 1 ?
                        ''
                    :
                        <Link to={Routes.NUEVA_SUBTAREA(idtarea)}>
                            <Button className="btn btn-success me-2"
                                style={{backgroundColor: "#02B371"}} size='sm'
                            >
                                Subtarea Nueva
                            </Button>
                        </Link>
                    }    
                </div>
                <Container>
                    <br/>
                    <Table responsive bordered hover size="sm" style={{fontSize: "0.9rem"}} className="tabla_subtareas">
                        <thead style={{textAlign: 'center'}}>
                            <tr className="bg-primary text-white">
                                <th>Titulo Subtarea</th>
                                <th>Descripcion</th>
                                <th>Observaciones</th>
                                <th>Prioridad</th>
                                <th>Fecha Limite</th>
                                <th>Usuario</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody style={{textAlign: 'center'}}>
                            {categor.datos === undefined ?
                                ''
                            :
                                categor.datos.map(item => (
                                    <tr key={item}>
                                        <td>
                                            {item.title}
                                        </td>
                                        <td style={{minWidth: "20%",maxWidth: "20%"}}>
                                            {item.description}
                                        </td>
                                        <td>
                                            {item.observations}
                                        </td>
                                        <td>
                                            {item.priority === 'high' ?
                                                'Alto'
                                            :item.priority === 'medium' ?
                                                'Medio'
                                            :item.priority === 'low' ?
                                                'Bajo'
                                            :
                                                ''
                                            }
                                        </td>
                                        <td>
                                            {item.deadline}
                                        </td>
                                        <td>
                                            {usuariosinfo.datos.map(e => (
                                                parseInt(item.creator_id) === e.id ?
                                                    <div class="d-flex justify-content-between">
                                                        <div class="d-flex justify-content-between" key={item.id}>
                                                            <div>
                                                                <img src={e.image === '' ? LogoDedalo : e.image} className="me-2" 
                                                                    style={{width: "50px", height: "50px", borderRadius: "30px"}}/>
                                                            </div>
                                                            <div>
                                                                <b style={{color: "#d35400"}}>
                                                                    {e.name}
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                    ''
                                            ))}
                                        </td>
                                        <td>
                                            <NavDropdown id="collasible-nav-dropdown" className="menu_subtareas">
                                                <NavDropdown.Item className="editar">
                                                    <Link to={Routes.EDITAR_SUBTAREA} className="text-decoration-none"
                                                        onClick={()=>{localStorage.setItem("projectHomiSubtask", JSON.stringify({idtask: idtarea, idsubtask: item.id}))}}
                                                    >
                                                        Editar
                                                    </Link>
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>peticionDelete(item.id)}>
                                                    Eliminar
                                                </NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=>CambiarEstado(item.id)}>
                                                    Cambiar estado
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Container>
            </div>
        </div>
    );
}

export default TablaSubTareas;
