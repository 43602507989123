import React, {useState, Fragment, useEffect} from "react";
import Swal from "sweetalert2";

export const Mensaje_Error_Requisicion_edit = (msj_req) => {
    const msj = [
        {id: 1, err: `position is required!`, es: 'se requiere cargo!'},
        {id: 2, err: `price is required!`, es: 'El precio es requerido.'},
    ];
    
    const msj2 = [
        {id: 1, err: `Kitchen's inventory dont exists`, es: 'El inventario de la cocina no existe.'},
        {id: 2, err: `Kitchen is being used`, es: 'La cocina está siendo utilizada.'},
        {id: 3, err: `Inventory is not enough`, es: 'El inventario no es suficiente.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Create_Cost = (msj_req) => {
    const msj = [
        {err: `name is required!`, es: 'se requiere nombre!'},
        {err: `code is required!`, es: 'El Codigo es requerido.'},
        {err: `status is required!`, es: 'El estado es requerido.'},
        {err: `groups is required!`, es: 'El grupo es requerido.'},
        {err: `department is required!`, es: 'El departamento es requerido.'},
        {err: `category is required!`, es: 'La categoria es requerida.'},
        {err: `section is required!`, es: 'La seccion es requerida.'},
        {err: `type is required!`, es: 'El tipo es requerido.'},
    ];
    
    const msj2 = [
        {err: `Name already exists`, es: 'El nombre ya existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Edit_Cost = (msj_req) => {
    const msj = [
        {id: 1, err: `groups is required!`, es: 'se requiere grupo!'},
        {id: 2, err: `price is required!`, es: 'El precio es requerido.'},
    ];
    
    const msj2 = [
        {id: 1, err: `Kitchen's inventory dont exists`, es: 'El inventario de la cocina no existe.'},
        {id: 2, err: `Kitchen is being used`, es: 'La cocina está siendo utilizada.'},
        {id: 3, err: `Inventory is not enough`, es: 'El inventario no es suficiente.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Create_Req = (msj_req) => {
    const msj = [
        {err: `position is required!`, es: 'se requiere el cargo!'},
    ];
    
    const msj2 = [
        {err: `Name already exists`, es: 'El nombre ya existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Del_Cot = (msj_req) => {
    const msj = [
        {err: `position is required!`, es: 'se requiere el cargo!'},
    ];
    
    const msj2 = [
        {err: `Proyect has been approved`, es: 'No se puede eliminar, el proyecto ha sido aprobado.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Create_Cot = (msj_req) => {
    const msj = [
        {err: `position is required!`, es: 'se requiere el cargo!'},
        {err: `document is required!`, es: 'se requiere el documento!'},
    ];
    
    const msj2 = [
        {err: `Name already exists`, es: 'El nombre ya existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Create_Acta = (msj_req) => {
    const msj = [
        {err: `number_certificate is required!`, es: 'se requiere el numero de acta!'},
        {err: `year_certificate is required!`, es: 'se requiere el año!'},
        {err: `contractor is required!`, es: 'se requiere el contratante!'},
        {err: `constructor_name is required!`, es: 'se requiere el contratista!'},
        {err: `object is required!`, es: 'se requiere el objeto!'},
        {err: `value is required!`, es: 'se requiere el precio!'},
        {err: `term is required!`, es: 'se requiere el plazo!'},
        {err: `startdate is required!`, es: 'se requiere la fecha de inicio!'},
        {err: `meet_day is required!`, es: 'se requiere el dia!'},
        {err: `meet_month is required!`, es: 'se requiere el mes!'},
        {err: `meet_person_one is required!`, es: 'se requiere el nombre de la persona!'},
        {err: `document_person_one is required!`, es: 'se requiere el documento de la persona!'},
        {err: `document_person_two is required!`, es: 'se requiere el documento delgerente!'},
        {err: `contractual_order is required!`, es: 'se requiere el numero de la orden contractual!'},
        {err: `enterprice is required!`, es: 'se requiere el nombre de la empresa !'},
        {err: `enterprice_nit is required!`, es: 'se requiere el nit de la empresa!'},
        {err: `observations is required!`, es: 'se requiere las observaciones!'},
    ];
    
    const msj2 = [
        {err: `City dont exists`, es: 'La ciudad no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio creada para este proyecto.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Edit_Acta = (msj_req) => {
    const msj = [
        {err: `number_certificate is required!`, es: 'se requiere el numero de acta!'},
        {err: `year_certificate is required!`, es: 'se requiere el año!'},
        {err: `contractor is required!`, es: 'se requiere el contratante!'},
        {err: `constructor_name is required!`, es: 'se requiere el contratista!'},
        {err: `object is required!`, es: 'se requiere el objeto!'},
        {err: `value is required!`, es: 'se requiere el precio!'},
        {err: `term is required!`, es: 'se requiere el plazo!'},
        {err: `startdate is required!`, es: 'se requiere la fecha de inicio!'},
        {err: `meet_day is required!`, es: 'se requiere el dia!'},
        {err: `meet_month is required!`, es: 'se requiere el mes!'},
        {err: `meet_person_one is required!`, es: 'se requiere el nombre de la persona!'},
        {err: `document_person_one is required!`, es: 'se requiere el documento de la persona!'},
        {err: `document_person_two is required!`, es: 'se requiere el documento delgerente!'},
        {err: `contractual_order is required!`, es: 'se requiere el numero de la orden contractual!'},
        {err: `enterprice is required!`, es: 'se requiere el nombre de la empresa !'},
        {err: `enterprice_nit is required!`, es: 'se requiere el nit de la empresa!'},
    ];
    
    const msj2 = [
        {err: `City dont exists`, es: 'La ciudad no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Create_Ant = (msj_req) => {
    const msj = [
        {err: `number_advances is required!`, es: 'se requiere el numero del anticipo!'},
        {err: `document_type is required!`, es: 'se requiere el tipo de documento!'},
        {err: `description is required!`, es: 'se requiere la descripcion!'},
        {err: `advance_reason is required!`, es: 'se requiere el motivo del anticipo!'},
    ];
    
    const msj2 = [
        {err: `City dont exists`, es: 'La ciudad no existe.'},
        {err: `Cost center dont exists`, es: 'falta el centro de costos.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Legalizacion = (msj_req) => {
    const msj = [
        {err: `number_legalization is required!`, es: 'se requiere el numero de legalizacion!'},
        {err: `document_type is required!`, es: 'se requiere el tipo de documento!'},
        {err: `document_number is required!`, es: 'se requiere el numero del documento!'},
    ];
    
    const msj2 = [
        {err: `City dont exists`, es: 'La ciudad no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_NoConformidad = (msj_req) => {
    const msj = [
        {err: `number_not_conforming is required!`, es: 'se requiere el numero de no conformidad!'},
        {err: `period_not_conforming is required!`, es: 'se requiere el periodo!'},
        {err: `process is required!`, es: 'se requiere el proceso!'},
        {err: `not_conforming_year is required!`, es: 'se requiere el año!'},
        {err: `responsible is required!`, es: 'se requiere el responsable!'},
        {err: `objetive is required!`, es: 'se requiere el objetivo!'},
        {err: `indicator is required!`, es: 'se requiere el indicador!'},
        {err: `goal is required!`, es: 'se requiere la meta!'},
        {err: `formulation is required!`, es: 'se requiere la formulacion!'},
        {err: `it_measure is required!`, es: 'se requiere el campo de medicion!'},
        {err: `measurement_frequency is required!`, es: 'se requiere la frecuencia de medicion!'},
        {err: `data_source is required!`, es: 'se requiere la fuente de datos!'},
        {err: `analysis_tool is required!`, es: 'se requiere la herramienta de medicion!'},
        {err: `bimonthly is required!`, es: 'se requiere los datos de calificacion!'},
        {err: `analysis_information is required!`, es: 'se requiere el campo de analisis!'},
    ];
    
    const msj2 = [
        {err: `City dont exists`, es: 'La ciudad no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_CumpliminetoCrono = (msj_req) => {
    const msj = [
        {err: `number_not_conforming is required!`, es: 'se requiere el numero de no conformidad!'},
        {err: `period_not_conforming is required!`, es: 'se requiere el periodo!'},
        {err: `process is required!`, es: 'se requiere el proceso!'},
        {err: `not_conforming_year is required!`, es: 'se requiere el año!'},
        {err: `responsible is required!`, es: 'se requiere el responsable!'},
        {err: `objetive is required!`, es: 'se requiere el objetivo!'},
        {err: `indicator is required!`, es: 'se requiere el indicador!'},
        {err: `goal is required!`, es: 'se requiere la meta!'},
        {err: `formulation is required!`, es: 'se requiere la formulacion!'},
        {err: `it_measure is required!`, es: 'se requiere el campo de medicion!'},
        {err: `measurement_frequency is required!`, es: 'se requiere la frecuencia de medicion!'},
        {err: `data_source is required!`, es: 'se requiere la fuente de datos!'},
        {err: `analysis_tool is required!`, es: 'se requiere la herramienta de medicion!'},
        {err: `bimonthly is required!`, es: 'se requiere los datos de calificacion!'},
        {err: `analysis_information is required!`, es: 'se requiere el campo de analisis!'},
    ];
    
    const msj2 = [
        {err: `City dont exists`, es: 'La ciudad no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Cuadrocomparativo = (msj_req) => {
    const msj = [
        {err: `number_not_conforming is required!`, es: 'se requiere el numero de no conformidad!'},
        {err: `period_not_conforming is required!`, es: 'se requiere el periodo!'},
        {err: `process is required!`, es: 'se requiere el proceso!'},
        {err: `not_conforming_year is required!`, es: 'se requiere el año!'},
        {err: `responsible is required!`, es: 'se requiere el responsable!'},
        {err: `objetive is required!`, es: 'se requiere el objetivo!'},
        {err: `indicator is required!`, es: 'se requiere el indicador!'},
        {err: `goal is required!`, es: 'se requiere la meta!'},
        {err: `formulation is required!`, es: 'se requiere la formulacion!'},
        {err: `it_measure is required!`, es: 'se requiere el campo de medicion!'},
        {err: `measurement_frequency is required!`, es: 'se requiere la frecuencia de medicion!'},
        {err: `data_source is required!`, es: 'se requiere la fuente de datos!'},
        {err: `analysis_tool is required!`, es: 'se requiere la herramienta de medicion!'},
        {err: `bimonthly is required!`, es: 'se requiere los datos de calificacion!'},
        {err: `analysis_information is required!`, es: 'se requiere el campo de analisis!'},
    ];
    
    const msj2 = [
        {err: `City dont exists`, es: 'La ciudad no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_PerfilCargo = (msj_req) => {
    const msj = [
        {err: `number_not_conforming is required!`, es: 'se requiere el numero de no conformidad!'},
        {err: `period_not_conforming is required!`, es: 'se requiere el periodo!'},
        {err: `process is required!`, es: 'se requiere el proceso!'},
        {err: `not_conforming_year is required!`, es: 'se requiere el año!'},
        {err: `responsible is required!`, es: 'se requiere el responsable!'},
        {err: `objetive is required!`, es: 'se requiere el objetivo!'},
        {err: `indicator is required!`, es: 'se requiere el indicador!'},
        {err: `goal is required!`, es: 'se requiere la meta!'},
        {err: `formulation is required!`, es: 'se requiere la formulacion!'},
        {err: `it_measure is required!`, es: 'se requiere el campo de medicion!'},
        {err: `measurement_frequency is required!`, es: 'se requiere la frecuencia de medicion!'},
        {err: `data_source is required!`, es: 'se requiere la fuente de datos!'},
        {err: `analysis_tool is required!`, es: 'se requiere la herramienta de medicion!'},
        {err: `bimonthly is required!`, es: 'se requiere los datos de calificacion!'},
        {err: `analysis_information is required!`, es: 'se requiere el campo de analisis!'},
    ];
    
    const msj2 = [
        {err: `Job Profile dont exists`, es: 'Perfil de trabajo no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Seleccion_Proveedor = (msj_req) => {
    const msj = [
        {err: `date is required!`, es: 'se requiere la fecha de seleccion!'},
        {err: `supplier_type is required!`, es: 'se requiere el proveedor!'},
    ];
    
    const msj2 = [
        {err: `Job Profile dont exists`, es: 'Perfil de trabajo no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Evaluacion_Proveedor = (msj_req) => {
    const msj = [
        {err: `date is required!`, es: 'se requiere la fecha de seleccion!'},
        {err: `supplier_type is required!`, es: 'se requiere el proveedor!'},
    ];
    
    const msj2 = [
        {err: `Crm provider dont exists`, es: 'Seleccione el proveedor no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Comparativo = (msj_req) => {
    const msj = [
        {err: `desription is required!`, es: 'se requiere la descripcion del cuadro!'},
        {err: `supplier_type is required!`, es: 'se requiere el proveedor!'},
    ];
    
    const msj2 = [
        {err: `Requisition dont exists`, es: 'La requisicion no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Salida_Equipos = (msj_req) => {
    //console.log(msj_req);
    const msj = [
        {err: `out_date is required!`, es: 'se requiere la la fecha de salida!'},
        {err: `authorized is required!`, es: 'se requiere nombre de quien autoriza!'},
        {err: `dependence is required!`, es: 'se requiere la dependencia!'},
        {err: `responsible is required!`, es: 'se requiere el responsable!'},
        {err: `position is required!`, es: 'se requiere el cargo del responsable!'},
        {err: `project is required!`, es: 'se requiere el proyecto!'},
        {err: `responsible_project is required!`, es: 'se requiere el responsable del proyecto!'},
        {err: `position_project is required!`, es: 'se requiere el cargo del responsable del proyecto!'},
    ];
    
    const msj2 = [
        {err: `Requisition dont exists`, es: 'La requisicion no existe.'},
        {err: `certificate already exists`, es: 'Ya existe un acta de inicio para este proyecto existe.'},
    ];

    msj2.map(q =>{
        if(msj_req === q.err){
            Swal.fire("!!Atencion!!", q.es, "info");
        }
    });

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};

export const Mensaje_Error_Inven_Gene = (msj_req) => {
    //console.log(msj_req);
    const msj = [
        {err: `amount is required!`, es: 'se requiere la cantidad!'},
        {err: `minimum_amount is required!`, es: 'se requiere la cantidad minima!'},
        {err: `products_id is required!`, es: 'se requiere un producto seleccionado!'},
        {err: `enterprice_id is required!`, es: 'se requiere seleccionar una empresa!'},
        {err: `inventory_general_id is required!`, es: 'se requiere seleccionar un inventario general!'},
        {err: `type is required!`, es: 'se requiere el tipo de movimiento!'}
    ];

    msj.map(q1 =>{
        if(msj_req.split(' ')[0] === q1.err.split(' ')[0]){
            Swal.fire("!!Atencion!!", q1.es, "info");
        }
    });
};
