import React, {useState, Fragment, useEffect,useRef} from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav,Table,DropdownButton,ButtonGroup,Dropdown,
    Card,Container,FloatingLabel,
} from 'react-bootstrap';
import {
    DEDALO_REQUISITIONS_COMBO,DEDALO_COMBO_VENDORS_FULL,DEDALO_COST_CENTER_COMBO,
    DEDALO_COMBO_INVENTARIO,DEDALO_NEW_SHOPPING_SUPPLIES,DEDALO_SHOPPING_SUPPLIES_BY_ID,
} from "../../../assets/APIAdresses";
import {message_responses} from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import {useUserSession} from "../../../hooks";
import {Redirect,useParams,Link} from "react-router-dom";
import { Routes } from '../../../utils/routes';
import Swal from "sweetalert2";
import html2PDF from "jspdf-html2canvas";
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";
import Img1 from './imagenes_reportes/logo_geo.png';

const RequisicionNueva = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();
    const {userId} = useUserSession();
    const {user} = useUserSession();

    let {IdVer} = useParams();

    const [datsedit, setDatEdit] = useState({
        date: '',
        type: '',
        produced_by: '',
        dead_date: '',
        way_to_pay: '',
        numberpay: '',
        number_shopping: '',
        concept: '',
        descuento: '',
        crm_vendor_id: '',
        shopping_supplies: [],
    });

    const [customerscombo, setCustomersCombo] = useState({
        datos: [],
    });

    const peticionGetClientesCombo=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_VENDORS_FULL,
            headers: {Authorization: userToken},
            data: params_data,
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setCustomersCombo({
                    ...customerscombo,
                    ['datos']: datanotice,
                });
            } else {
                setCustomersCombo({
                    ...customerscombo,
                    ['datos']: [],
                });
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
              alert(
                error.response.data.message === "VALIDATOR_ERROR"
                  ? error.response.data.data.error_message
                  : message_responses[error.response.data.message]
              );
            } else {
              alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos 2");
            }
        });
      
        return info1;
        
    };

    const peticionGetOrdenById=async()=>{
        let useredit = parseInt(IdVer);

        if(useredit === undefined){
            let ry = 0;
        }else{
            let info1 = await axios({
                method: 'POST',
                url: DEDALO_SHOPPING_SUPPLIES_BY_ID+'&id='+useredit,
                headers: {Authorization: userToken},
            })
            .then(response=>{
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    //console.log(datanotice);
                    setDatEdit({
                        ...datsedit,
                        ['date']: datanotice.date,
                        ['type']: datanotice.type,
                        ['dead_date']: datanotice.dead_date,
                        ['way_to_pay']: datanotice.way_to_pay,
                        ['numberpay']: datanotice.numberpay,
                        ['number_shopping']: datanotice.number_shopping,
                        ['concept']: datanotice.concept,
                        ['descuento']: datanotice.descuento,
                        ['crm_vendor_id']: datanotice.crm_vendor_id,
                        ['produced_by']: datanotice.produced_by,
                        ['shopping_supplies']: datanotice.shopping_supplies,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                  alert(
                    error.response.data.message === "VALIDATOR_ERROR"
                      ? error.response.data.data.error_message
                      : message_responses[error.response.data.message]
                  );
                } else {
                  alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
            return info1;
        }
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    useEffect(() => {
        peticionGetClientesCombo();
        peticionGetOrdenById();
    }, []);

    const printContainer = useRef(null);

    const createPDF = async () => {
        await html2PDF(printContainer.current, {
            jsPDF: {
                format: "letter"
            },
            margin: {
                top: 7,
                right: 5,
                bottom: 7,
                left: 5
            },
            html2canvas: {
                scrollX: 0,
                scrollY: -window.scrollY
            },
            imageType: "image/jpeg",
            output: "./pdf/generate.pdf"
        });
    }; 

    let shopping_supplies = datsedit.shopping_supplies;
    let total_unico = [];

    shopping_supplies.map((row, index) => {
        total_unico.push(
            {
                id: row.id,
                total: (parseInt(row.price) + parseInt(row.iva)) * parseInt(row.amount)
            },
        );
    });

    let info_proveedor = customerscombo.datos.filter(x => x.id === parseInt(datsedit.crm_vendor_id));

    let sumall_iva = shopping_supplies.map(item => parseInt(item.iva)).reduce((prev, curr) => (prev + curr), 0);
    let sumall_vunit = shopping_supplies.map(item => parseInt(item.price)).reduce((prev, curr) => (prev + curr), 0);
    let sum_tatal = total_unico.map(item => item.total).reduce((prev, curr) => (prev + curr), 0);

    let ceros = '';
    let prueba = datsedit.number_shopping.length;
    for(let i = 0;i < 10;i++){
        ceros += '0';
    }
    let newStr = ceros.substr(prueba);
    
    return(
        <div>
            <br/>
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => {window.history.back()}} title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            <MdIcons.MdArrowBackIosNew/>
                        </Button>
                    </div>
                    <div>
                        <Button className="btn btn-success me-2" size="sm" onClick={createPDF}
                            title="Regresar" style={{backgroundColor: "#02B371"}}
                        >
                            Descargar PDF
                        </Button>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container-fluid flex-grow-1 container-p-y">
                <div className="container " ref={printContainer} style={{padding: '1em 2em 1em 2em'}}>
                    <Row className="border border-dark"> {/* cabecera del formato */}
                        <Col md={2} xs={2} className="text-center">
                            <img src={Img1} alt="logo" width="200" height="150" className="img-fluid py-3" />
                        </Col>
                        <Col md={8} xs={8} className="d-flex justify-content-center align-items-center border-start border-end border-dark">
                            <div >
                                <h3>
                                    FORMATO ORDEN DE COMPRA O SERVICIO
                                </h3>
                            </div>
                        </Col>
                        <Col md={2} xs={2} >
                            <div className="mt-3">
                                <div>
                                    <span>
                                        <b>Código: FO-CP-03-02</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b>Fecha: 01/08/2015</b>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <b>Página 1 de 1</b>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-dark border-start border-end border-botom" style={{backgroundColor: '#DAEEF3'}}>
                        <Col md={12} xs={12} className="border-dark">
                            <Row>
                                <Col md={5} xs={5} className='py-3'>
                                    <div className="border border-dark text-center" style={{backgroundColor: '#ffffff'}}>
                                        <p className="m-0">
                                            GEOESTRUCTURAS LTDA. - NIT: 800.028.676-2
                                        </p>
                                        <p className="m-0">
                                            Carrera 12 No. 67-38 - Teléfono: 5 45 00  14
                                        </p>
                                    </div>
                                </Col>
                                <Col md={5} xs={5}></Col>
                                <Col md={2} xs={2} className='py-3'>
                                    <div className="border border-dark pl-4 py-2" style={{backgroundColor: '#ffffff'}}>
                                        <Form>
                                            <Form.Check 
                                                type='checkbox' checked={datsedit.type === 'service order' ? true : false}
                                                id="servicio" value='service order'
                                                label={`Orden de servicio`}
                                            />
                                            <Form.Check 
                                                type='checkbox' checked={datsedit.type === 'purchase order' ? true : false}
                                                id="compra" value='purchase order'
                                                label={`Orden de compra`}
                                            />
                                        </Form>
                                    </div>
                                    <div className="border-start border-end border-bottom border-dark d-flex px-4 py-1"
                                        style={{backgroundColor: '#ffffff'}}
                                    >
                                        <div className="me-2">
                                            <b>
                                                <span>No.</span>
                                            </b>
                                        </div>
                                        <div>
                                            <b className="text-muted">
                                                <span>{newStr+datsedit.number_shopping}</span>
                                            </b>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={12} className="border-dark border-bottom">
                            <Row>
                                <Col md={7} xs={7} className='pb-3'>
                                    <div className="border border-dark">  {/* seccion info proveedor */}
                                        <div className="text-center d-flex justify-content-center align-items-center"
                                            style={{height: '2em',backgroundColor: '#D8D8D8'}}
                                        >
                                            <span>
                                                <b>PROVEEDOR</b>
                                            </span>
                                        </div>
                                        <div style={{backgroundColor: '#ffffff'}}>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-1 border-end border-dark" style={{width: '20%'}}>
                                                    <b>RAZON SOCIAL:</b>
                                                </p>
                                                <p className="m-0 px-2" style={{width: '80%'}}>
                                                    <span>
                                                        {info_proveedor[0] === undefined ? '' : info_proveedor[0].name}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                                    <b>NIT:</b>
                                                </p>
                                                <p className="m-0 px-2" style={{width: '80%'}}>
                                                    {info_proveedor[0] === undefined ? '' : info_proveedor[0].nit}
                                                </p>
                                            </div>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                                    <b>DIRECCION:</b>
                                                </p>
                                                <p className="m-0 px-2" style={{width: '80%'}}>
                                                    {info_proveedor[0] === undefined ? '' : info_proveedor[0].address}
                                                </p>
                                            </div>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                                    <b>CIUDAD:</b>
                                                </p>
                                                <p className="m-0 px-2" style={{width: '80%'}}>
                                                    {info_proveedor[0] === undefined ? '' : info_proveedor[0].cities_id}
                                                </p>
                                            </div>
                                            <div className="d-flex border-top border-dark">
                                                <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                                    <b>TÉLEFONO:</b>
                                                </p>
                                                <p className="m-0 px-2" style={{width: '80%'}}>
                                                    {info_proveedor[0] === undefined ? '' : info_proveedor[0].vendors_phone}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={2} xs={2}></Col>
                                <Col md={3} xs={3}  className='pb-3'>
                                    <div className="border border-dark"> {/* seccion de fechas */}
                                        <div className="d-flex border-bottom border-dark">
                                            <p className="m-0 px-2 border-end border-dark" style={{width: '40%',fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                <b>FECHA DE ELABORACION</b>
                                            </p>
                                            <p className="m-0 px-3" style={{width: '60%',fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                <b>FECHA DE VENCIMIENTO</b>
                                            </p>
                                        </div>
                                        <div className="d-flex"> 
                                            <p className="m-0 border-end border-dark text-center py-1" style={{width: '40%',fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                                <span>{new Date(datsedit.date).toLocaleDateString()}</span>
                                            </p>
                                            <p className="m-0 text-center py-1"  style={{width: '60%',fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                                <span>{new Date(datsedit.dead_date).toLocaleDateString()}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p className="m-0 px-2 border-top border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                <b>ELABORADO POR</b>
                                            </p>
                                            <p className="m-0 px-2" style={{fontSize: '0.9em',backgroundColor: '#ffffff'}}>
                                                {datsedit.produced_by}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="m-0 px-2 border-top border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                <b>FORMA DE PAGO</b>
                                            </p>
                                            <p className="m-0 px-2" style={{fontSize: '0.9em',backgroundColor: '#ffffff'}}>
                                                <span>
                                                    {datsedit.way_to_pay === 'cash' ?
                                                        'Efectivo'
                                                    :datsedit.way_to_pay === 'transfer' ?
                                                        'Transferencia'
                                                    :
                                                        'Deposito'
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                        {datsedit.way_to_pay === 'transfer' || datsedit.way_to_pay === 'deposit' ?
                                            <div>
                                                <p className="m-0 px-2 border-top border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                                    <b>NUMERO DE PAGO</b>
                                                </p>
                                                <p className="m-0 px-2" style={{fontSize: '0.8em',backgroundColor: '#ffffff',height: '1.7em'}}>
                                                    <span>{datsedit.numberpay}</span>
                                                </p>
                                            </div>
                                        :
                                            ''
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div className="mb-3 border border-dark"> {/* seccion de concepto */}
                                <div className="text-center border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                    <b>POR CONCEPTO DE:</b>
                                </div>
                                <div className="px-3 py-1" style={{height: '2em',fontSize: '0.9em',backgroundColor: '#ffffff'}}>
                                    <span>
                                        {datsedit.concept}
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="border-dark border-start border-end border-botom"> {/* tabla de contenidos del formato */}
                        <Col md={12} xs={12} className="border-dark border-bottom">
                            <div className="my-2">
                                <Table responsive id="tabla_req" name="tabla_req">
                                    <thead className="text-center">
                                        <tr style={{fontSize: '0.8em'}} className='fw-bold'>
                                            <th>ord. servicio</th>
                                            <th>Requisicion</th>
                                            <th>descripcion</th>
                                            <th>centro de costos</th>
                                            <th>cantidad</th>
                                            <th>valor unitario</th>
                                            <th>iva</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            datsedit.shopping_supplies.map((row, i) =>(
                                                <tr key={i}>
                                                    <td className="text-center" style={{width: '6em'}}>{i+1}</td>
                                                    <td style={{width: '5em'}} className="text-center">
                                                        <span>{row.requisitions_id}</span>
                                                    </td>
                                                    <td style={{width: '20em'}} className="text-center">
                                                        <span>{row.nameProduct}</span>
                                                    </td>
                                                    <td style={{width: '14em'}} className="text-center">
                                                        <span>{row.nameCostCenter}</span>
                                                    </td>
                                                    <td style={{width: '7em'}} className="text-center">
                                                        <span>{parseInt(row.amount)}</span>
                                                    </td>
                                                    <td style={{width: '8em'}} className="text-center">
                                                        <span>{formatterPeso.format(row.price)}</span>
                                                    </td>
                                                    <td style={{width: '8em'}} className="text-center">
                                                        <span>{formatterPeso.format(row.iva)}</span>
                                                    </td>
                                                    <td style={{width: '8em'}} className="text-center">
                                                        <span>{formatterPeso.format((parseInt(row.price) + parseInt(row.iva)) * parseInt(row.amount))}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col md={12} xs={12} className='border-bottom border-top border-dark page-break'> {/* seccion de valores */}
                            <Row>
                                <Col md={9} xs={9} className='border-end border-bottom border-dark'>
                                    <div>
                                    </div>
                                </Col>
                                <Col md={3} xs={3} >
                                    <Row>
                                        <Col md={4} xs={4} className='border-end border-bottom border-dark'>
                                            <div className="text-center">
                                                {formatterPeso.format(sumall_vunit)}
                                            </div>
                                        </Col>
                                        <Col md={4} xs={4} className='border-end border-bottom border-dark'>
                                            <div className="text-center">
                                                {formatterPeso.format(sumall_iva)}
                                            </div>
                                        </Col>
                                        <Col md={4} xs={4} className='border-bottom border-dark'>
                                            <div className="text-center">
                                                {formatterPeso.format(sum_tatal)}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} xs={4} className='border-end border-dark'>
                                            <div className="text-center">
                                                <b>SUBTOTAL</b>
                                            </div>
                                        </Col>
                                        <Col md={8} xs={8}>
                                            <div className="text-end">
                                                {sumall_vunit === NaN ? '$ 0' : formatterPeso.format(sumall_vunit)}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={9} xs={9} className='border-end border-dark'>
                                    <div>
                                    </div>
                                </Col>
                                <Col md={3} xs={3} >
                                    <Row className="border-top border-dark">
                                        <Col md={4} xs={4} className='border-end border-dark'>
                                            <div className="">
                                                <b>DESCUENTO</b>
                                            </div>
                                        </Col>
                                        <Col md={8} xs={8}>
                                            <div>
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="border-top border-dark">
                                        <Col md={4} xs={4} className='border-end border-dark'>
                                            <div className="text-center">
                                                <b>IVA</b>
                                            </div>
                                        </Col>
                                        <Col md={8} xs={8}>
                                            <div className="text-end">
                                                {formatterPeso.format(sumall_iva)}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="border-top border-dark">
                                        <Col md={4} xs={4} className='border-end border-dark'>
                                            <div className="text-center">
                                                <b>TOTAL</b>
                                            </div>
                                        </Col>
                                        <Col md={8} xs={8}>
                                            <div className="text-end">
                                                {formatterPeso.format((sumall_iva+sumall_vunit))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="border-start border-end border border-bottom border-dark"> {/* seccion de datos de recepcion */}
                        <Col md={12} xs={12} className='py-4'>
                            <div className='border border-dark'>
                                <div className="p-2 text-center" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                    <b>DATOS DE RECEPCION DEL SERVICIO O BIENES</b>
                                </div>
                                <div>
                                    <div className="d-flex border-top border-dark">
                                        <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                            <b>DIRECCION:</b>
                                        </p>
                                        <p className="m-0 px-3" style={{width: '80%'}}>
                                        </p>
                                    </div>
                                    <div className="d-flex border-top border-dark">
                                        <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                            <b>CIUDAD:</b>
                                        </p>
                                        <p className="m-0 px-3" style={{width: '80%'}}>
                                        </p>
                                    </div>
                                    <div className="d-flex border-top border-dark">
                                        <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                            <b>TELEFONO:</b>
                                        </p>
                                        <p className="m-0 px-3" style={{width: '80%'}}>
                                        </p>
                                    </div>
                                    <div className="d-flex border-top border-dark">
                                        <p className="m-0 px-2 border-end border-dark" style={{width: '20%'}}>
                                            <b>PROYECTO:</b>
                                        </p>
                                        <p className="m-0 px-3" style={{width: '80%'}}>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 border border-dark">
                                <div className="p-2 border-bottom border-dark" style={{fontSize: '0.8em',backgroundColor: '#D8D8D8'}}>
                                    <b>OBSERVACIONES-CONDICIONES Y CARACTERISTICAS</b>
                                </div>
                                <div className="px-3 py-2" style={{height: '3em',fontSize: '0.8em',backgroundColor: '#ffffff'}}>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xs={12}>
                            <Row className="border-top border-dark">
                                <Col md={6} xs={6} className='border-end border-dark'>
                                    <div className="d-flex">
                                        <div className="d-flex justify-items-center align-items-center" style={{width: '40%',height: '2em'}}>
                                            <b>FIRMA DE RESPONSABLE:</b>
                                        </div>
                                        <div style={{width: '60%'}}>
                                            
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={6}>
                                    <div className="d-flex">
                                        <div className="d-flex justify-items-center align-items-center" style={{width: '40%',height: '2em'}}>
                                            <b>VERIFICADO POR:</b>
                                        </div>
                                        <div style={{width: '60%'}}>
                                            
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default RequisicionNueva;