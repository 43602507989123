import React, { useState, Fragment, useEffect } from "react";
import { io } from 'socket.io-client';

const USER_SESSION_NAME = "dedalo_usr";
const user = JSON.parse(localStorage.getItem(USER_SESSION_NAME));
const datos_memoria = user === null ? {} : user;
let dir_serv = process.env.REACT_APP_CHAT_ENDPOINT;

//export const socket = io.connect('https://socketio.hotelquintacamacho.com', {
export const socket = io.connect('https://io.chat.zpazium.com', {
//export const socket = io.connect('http://localhost:11000', {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999
});

socket.on('connect', function () {

    //     socket.on('connection:PONG', function() {
    //         //console.log('llego pong');
    //         //console.log(['send ping:',socket.id]);

    //         socket.emit('connection:PING', {
    //             idSocket:socket.id,
    //             timestamp: (new Date()).getTime(),
    //             idUser: datos_memoria.id,
    //             nameuser: datos_memoria.name,
    //             roluser: datos_memoria.role_id,
    //             imguser: datos_memoria.image,
    //             sexuser: datos_memoria.sex,
    //             app: 'dl',
    //         });

    //         socket.emit('dl:getPool', {
    //             idSocket:socket.id,
    //             timestamp: (new Date()).getTime(),
    //             idUser: datos_memoria.id,
    //             nameuser: datos_memoria.name,
    //             roluser: datos_memoria.role_id,
    //             imguser: datos_memoria.image,
    //             sexuser: datos_memoria.sex,
    //             app: 'dl',
    //         });
    //     });

    // socket.emit('dl:nuevo_usuario', {
    //     idSocket: socket.id,
    //     timestamp: (new Date()).getTime(),
    //     idUser: datos_memoria.id,
    //     nameuser: datos_memoria.name,
    //     roluser: datos_memoria.role_id,
    //     imguser: datos_memoria.image,
    //     sexuser: datos_memoria.sex,
    //     app: 'dl',
    // });

});

//socket.on('qc:getPool', function(data) {
//    console.log(data);
//})
