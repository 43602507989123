import Swal from "sweetalert2";
import Strings from "../assets/strings/strings";
import axios from "axios";

export const googleGeoCode = async address => () => {
  axios
    .get(address)
    .then(response => {
      return response;
    })
    .catch(e => {
      manageError(e);
    });
};

export const fetchData = (endpoint, setter, options = {}) => {
  const {onSuccess, preprocessData, requestOptions = {}, customManageError, manageResponse} = options;
  axios
    .get(endpoint, requestOptions)
    .then(response => {
      if (response.data.success) {
        let data = response.data.data;
        if (typeof preprocessData === "function") {
          data = preprocessData(data);
        }
        if (typeof manageResponse === "function") {
          manageResponse(response);
        }
        setter(data);
        onSuccess && onSuccess(data);
      } else {
        //alert("ERROR: Server Error.");
      }
    })
    .catch(e => {
      if (customManageError) {
        customManageError();
      } else {
        manageError(e);
      }
    });
};

export const manageError = error => {
  console.error(error);
  let subtitle = "";
  if (error.response && error.response.data.message === "VALIDATOR_ERROR") {
    let errors = error.response.data.data.error_message;
    for (let key in errors) {
      subtitle += (Strings.get(errors[key]) || errors[key]) + "<br>";
    }
  }
  let swalOptions = {
    title:
      Strings.get(error.response ? error.response.data.message : "serverError") ||
      error.response.statusText,
    html: subtitle,
    icon: "error",
  };
  Swal.fire(swalOptions);
};

export const getBase64 = async file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const formatterCurrency = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 0,
});

export const attachToken = (user, config = {}) => {
  if (user && user.token) {
    config.headers = {
      Authorization: user.token,
      Accept: "application/json",
      ...config.headers,
    };
  }
  return config;
};

export const showGenericRequestFailedModal = () => {
  return Swal.fire("Tu solicitud ha fallado. Por favor intenta nuevamente", "", "error");
};

export const showSuccessModal = text => {
  return Swal.fire(text, "", "success");
};

export const compareServerDateAndClientDate = (serverDate, clientDate) => {
  let sDate = serverDate.split(" ");
  sDate = (sDate[0]+"T"+sDate[1]);
  const date = new Date();
  const cDate = clientDate ? clientDate : new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,19);
  if(sDate>cDate){
    return 1;
  }else if(sDate<cDate){
    return -1;
  }
  return 0;
};

function fillWithZero(str) {
  return ("" + str).length >= 2 ? str : "0" + str;
}

//modified from https://stackoverflow.com/questions/542938/how-do-i-get-the-number-of-days-between-two-dates-in-javascript
export function getDaysDiff(date1,date2){
  let d1 = date1;
  let d2 = date2;
  if(typeof date1 === "string"){
    d1 = new Date(date1);
  }
  if(typeof date2 === "string"){
    d2 = new Date(date2);
  }
  if(d2>d1){
    let aux = d1;
    d1 = d2;
    d2 = aux;
  }
  return Math.round((d1-d2)/(1000*60*60*24));
}

export function getHoursDiff(date1,date2){
  let d1 = date1;
  let d2 = date2;
  if(typeof date1 === "string"){
    d1 = new Date(date1);
  }
  if(typeof date2 === "string"){
    d2 = new Date(date2);
  }
  if(d2>d1){
    let aux = d1;
    d1 = d2;
    d2 = aux;
  }
  return Math.round((d1-d2)/(1000*60*60));
}

export function getFormattedDate(date) {
  return `${date.getFullYear()}-${fillWithZero(date.getMonth() + 1)}-${fillWithZero(date.getDate())}`;
}

export function getRentalMaximumPermittedStartDate(){
  return new Date(new Date().setDate(new Date().getDate()+365));
}

export function getRentalMaximumPermittedDate(){
  return new Date(new Date().setDate(new Date().getDate()+386));
}

export const formatDate = (serverDate) => {
  const date = serverDate.split(" ");
  let modifiedDate = date[0].split("-");
  modifiedDate = modifiedDate[2] + "/" + modifiedDate[1] + "/" + modifiedDate[0];
  return modifiedDate + "   " + date[1].slice(0,5);
}

const availableStates = [
  "BOGOTA D.C.",
  "CALDAS",
  "SANTANDER"
];

export function stateIsAvailable(stateName){
    for(let i=0; i<availableStates.length; i++){
      if (stateName===availableStates[i]) return true;
    }
    return false;
}