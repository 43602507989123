import React, {useState, Fragment, useEffect} from "react";
import { 
  Card, Button, Container, Row, Col,
  Table,DropdownButton,Dropdown,Form
} from 'react-bootstrap';
import {
    DEDALO_GET_ALL_CONSTRUCTIONS,DEDALO_ELIMINAR_CONSTRUCCION,DEDALO_EDITAR_CONSTRUCCION
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams, Link} from "react-router-dom";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import Navbar from '../navbar';
import { Routes } from '../../utils/routes';
import logo from '../../imagenes/dedalo_logo.png';

function ProyectosDedalo() {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();    
    const {userId} = useUserSession();
    const {user} = useUserSession(); 
    const [categor, setCategor] = useState({
        datos: [],
    });
    
    const peticionGetCategor=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //let info1 = await axios.get(DEDALO_GET_ALL_CONSTRUCTIONS+"?page=1&rows=1000&order=address&inorder=asc&search=")
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_GET_ALL_CONSTRUCTIONS,
            headers: {Authorization: userToken},
            data: params_data
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice.records,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };
    
    const peticionDelete = (iduser) => {
        let delId = iduser;
        Swal.fire({
            title: 'Esta apunto de eliminar el proyecto, oprima si para confirmar o cancelar la operacion',
            showDenyButton: true,
            confirmButtonText: 'Si, eliminar',
            denyButtonText: `No, cancelar`,
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                //axios.delete(DEDALO_ELIMINAR_CONSTRUCCION(delId), attachToken(user))
                axios({
                    method: 'DELETE',
                    url: DEDALO_ELIMINAR_CONSTRUCCION+'&id='+delId,
                    headers: {Authorization: userToken},
                })
                .then(response=>{
                    if (response.data.code === '200') {
                        Swal.fire("Se ha eliminado el proyecto exitosamente", "", "success");
                        peticionGetCategor();
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data) {
                        Swal.fire("No se pudo eliminar el proyecto ya que se encuentra en curso", "", "warning");
                    }
                });
            } else if (result.isDenied) {
                Swal.fire('Se ha cancelado la operacion', '', 'info')
            }
        })
    };
    
    useEffect(() => {
        peticionGetCategor();
    }, []);

    /*const proyectoselect = (projectId) =>{
        window.location.href = Routes.PROYECTO_HOMI;
        localStorage.setItem("projectHomi", JSON.stringify({idProject: projectId}));
    }*/

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    let data = categor.datos === undefined ? [] : categor.datos;

    let projects = [];

    user.enterprices.map(en=>{
        if(en.id === data_emp.IdEmp){
            if(en.proyects){
                projects.push(en.proyects);
            }else{
                projects = [];
            }
        } 
    });
    
    return (
        <div >
            {/*<Navbar modulo="Proyectos" usuario={user.name}/>*/}
            <div className="w-100">
                <br/>
                <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                    <Link to={Routes.PROYECTO_NUEVO} style={{color: "white", textDecoration: "none"}}>
                        <Button className="botonobras me-2" size="sm" style={{backgroundColor: "#02B371"}}>
                            agregar proyecto
                        </Button>
                    </Link>
                </div>
                <br/>
                <Container>
                    <Row xs={12} md={4} className="g-4 text-center mb-4">
                        {data.length === 0 ?
                            <div className="mensajeinfo">
                                <h4>
                                    En el momento no hay proyectos en curso
                                </h4>
                            </div>
                        :
                            data.map(i => (
                                projects.length === 0 ?
                                    user.role_id === 3 || user.role_id === 2 ?
                                        <Col>
                                            <Card key={i} style={{minHeight: '26em', maxHeight: '26em'}}>
                                                <Card.Header className="cardheaderimg">
                                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end seccion_menu">
                                                        <DropdownButton align="end" variant="secondary" size="sm">
                                                            <Dropdown.Item onClick={()=>peticionDelete(i.id)}>
                                                                Eliminar
                                                            </Dropdown.Item>
                                                            <Dropdown.Item >
                                                                <Link to={Routes.INFORME} style={{color: "white", textDecoration: "none"}}
                                                                    onClick={()=>{localStorage.setItem("Informe", JSON.stringify({idinforme: i.id}));}}
                                                                >
                                                                    Ver informe
                                                                </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item >
                                                                <Link to={Routes.INFORME_PDF} style={{color: "white", textDecoration: "none"}}
                                                                        onClick={()=>{localStorage.setItem("Informe", JSON.stringify({idinforme: i.id}));}}
                                                                >
                                                                    Descargar PDF
                                                                </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item >
                                                                <Link to={Routes.FLUJOGRAMA_CRUD(i.id)} style={{color: "white", textDecoration: "none"}}>
                                                                    Ver Flujograma
                                                                </Link>
                                                            </Dropdown.Item>
                                                        </DropdownButton>
                                                    </div>
                                                </Card.Header>
                                                <Card.Img variant="top" src='' style={{width: "100%"}} />
                                                <Card.Body>
                                                    <Card.Title>{i.name}</Card.Title>
                                                    <Card.Text>
                                                        <p>Direccion: {i.address}</p>
                                                        <p>Presupuesto: {formatterPeso.format(i.budget)}</p>
                                                        <p>Ciudad: {i.cityname}</p>
                                                    </Card.Text> 
                                                        <Link to={Routes.PROYECTO_HOMI} style={{color: "white", textDecoration: "none"}}
                                                            onClick={()=>{localStorage.setItem("projectHomi", JSON.stringify({idProject: i.id}));}}
                                                        >
                                                            <Button className="btn btn-success me-2" size="sm"
                                                                style={{backgroundColor: "#02B371",color: 'white !important'}}>
                                                                ver proyecto
                                                            </Button>
                                                        </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    :
                                        projects.map(p=>(
                                            parseInt(p.constructions_id) === i.id ?
                                                <Col>
                                                    <Card key={i} style={{minHeight: '26em', maxHeight: '26em'}}>
                                                        <Card.Header className="cardheaderimg">
                                                            <div class="d-grid gap-2 d-md-flex justify-content-md-end seccion_menu">
                                                                <DropdownButton align="end" variant="secondary" size="sm">
                                                                    <Dropdown.Item onClick={()=>peticionDelete(i.id)}>
                                                                        Eliminar
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <Link to={Routes.INFORME} style={{color: "white", textDecoration: "none"}}
                                                                            onClick={()=>{localStorage.setItem("Informe", JSON.stringify({idinforme: i.id}));}}
                                                                        >
                                                                            Ver informe
                                                                        </Link>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <Link to={Routes.INFORME_PDF} style={{color: "white", textDecoration: "none"}}
                                                                                onClick={()=>{localStorage.setItem("Informe", JSON.stringify({idinforme: i.id}));}}
                                                                        >
                                                                            Descargar PDF
                                                                        </Link>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <Link to={Routes.FLUJOGRAMA_CRUD(i.id)} style={{color: "white", textDecoration: "none"}}>
                                                                            Ver Flujograma
                                                                        </Link>
                                                                    </Dropdown.Item>
                                                                </DropdownButton>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Img variant="top" src='' style={{width: "100%"}} />
                                                        <Card.Body>
                                                            <Card.Title>{i.name}</Card.Title>
                                                            <Card.Text>
                                                                <p>Direccion: {i.address}</p>
                                                                <p>Presupuesto: {formatterPeso.format(i.budget)}</p>
                                                                <p>Ciudad: {i.cityname}</p>
                                                            </Card.Text> 
                                                                <Link to={Routes.PROYECTO_HOMI} style={{color: "white", textDecoration: "none"}}
                                                                    onClick={()=>{localStorage.setItem("projectHomi", JSON.stringify({idProject: i.id}));}}
                                                                >
                                                                    <Button className="btn btn-success me-2" size="sm"
                                                                        style={{backgroundColor: "#02B371",color: 'white !important'}}>
                                                                        ver proyecto
                                                                    </Button>
                                                                </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            :    
                                                ''
                                        ))
                                :
                                    user.role_id === 3 || user.role_id === 2 ?
                                        <Col>
                                            <Card key={i} style={{minHeight: '26em', maxHeight: '26em'}}>
                                                <Card.Header className="cardheaderimg">
                                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end seccion_menu">
                                                        <DropdownButton align="end" variant="secondary" size="sm">
                                                            <Dropdown.Item onClick={()=>peticionDelete(i.id)}>
                                                                Eliminar
                                                            </Dropdown.Item>
                                                            <Dropdown.Item >
                                                                <Link to={Routes.INFORME} style={{color: "white", textDecoration: "none"}}
                                                                    onClick={()=>{localStorage.setItem("Informe", JSON.stringify({idinforme: i.id}));}}
                                                                >
                                                                    Ver informe
                                                                </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item >
                                                                <Link to={Routes.INFORME_PDF} style={{color: "white", textDecoration: "none"}}
                                                                        onClick={()=>{localStorage.setItem("Informe", JSON.stringify({idinforme: i.id}));}}
                                                                >
                                                                    Descargar PDF
                                                                </Link>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item >
                                                                <Link to={Routes.FLUJOGRAMA_CRUD(i.id)} style={{color: "white", textDecoration: "none"}}>
                                                                    Ver Flujograma
                                                                </Link>
                                                            </Dropdown.Item>
                                                        </DropdownButton>
                                                    </div>
                                                </Card.Header>
                                                <Card.Img variant="top" src='' style={{width: "100%"}} />
                                                <Card.Body>
                                                    <Card.Title>{i.name}</Card.Title>
                                                    <Card.Text>
                                                        <p>Direccion: {i.address}</p>
                                                        <p>Presupuesto: {formatterPeso.format(i.budget)}</p>
                                                        <p>Ciudad: {i.cityname}</p>
                                                    </Card.Text> 
                                                        <Link to={Routes.PROYECTO_HOMI} style={{color: "white", textDecoration: "none"}}
                                                            onClick={()=>{localStorage.setItem("projectHomi", JSON.stringify({idProject: i.id}));}}
                                                        >
                                                            <Button className="btn btn-success me-2" size="sm"
                                                                style={{backgroundColor: "#02B371",color: 'white !important'}}>
                                                                ver proyecto
                                                            </Button>
                                                        </Link>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    :
                                        projects.map(p=>(
                                            parseInt(p.constructions_id) === i.id ?
                                                <Col>
                                                    <Card key={i} style={{minHeight: '26em', maxHeight: '26em'}}>
                                                        <Card.Header className="cardheaderimg">
                                                            <div class="d-grid gap-2 d-md-flex justify-content-md-end seccion_menu">
                                                                <DropdownButton align="end" variant="secondary" size="sm">
                                                                    <Dropdown.Item onClick={()=>peticionDelete(i.id)}>
                                                                        Eliminar
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <Link to={Routes.INFORME} style={{color: "white", textDecoration: "none"}}
                                                                            onClick={()=>{localStorage.setItem("Informe", JSON.stringify({idinforme: i.id}));}}
                                                                        >
                                                                            Ver informe
                                                                        </Link>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <Link to={Routes.INFORME_PDF} style={{color: "white", textDecoration: "none"}}
                                                                                onClick={()=>{localStorage.setItem("Informe", JSON.stringify({idinforme: i.id}));}}
                                                                        >
                                                                            Descargar PDF
                                                                        </Link>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <Link to={Routes.FLUJOGRAMA_CRUD(i.id)} style={{color: "white", textDecoration: "none"}}>
                                                                            Ver Flujograma
                                                                        </Link>
                                                                    </Dropdown.Item>
                                                                </DropdownButton>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Img variant="top" src='' style={{width: "100%"}} />
                                                        <Card.Body>
                                                            <Card.Title>{i.name}</Card.Title>
                                                            <Card.Text>
                                                                <p>Direccion: {i.address}</p>
                                                                <p>Presupuesto: {formatterPeso.format(i.budget)}</p>
                                                                <p>Ciudad: {i.cityname}</p>
                                                            </Card.Text> 
                                                                <Link to={Routes.PROYECTO_HOMI} style={{color: "white", textDecoration: "none"}}
                                                                    onClick={()=>{localStorage.setItem("projectHomi", JSON.stringify({idProject: i.id}));}}
                                                                >
                                                                    <Button className="btn btn-success me-2" size="sm"
                                                                        style={{backgroundColor: "#02B371",color: 'white !important'}}>
                                                                        ver proyecto
                                                                    </Button>
                                                                </Link>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            :    
                                                ''
                                        ))
                            ))
                        }
                    </Row>
                    
                </Container>
            </div>
        </div>
    );
}

export default ProyectosDedalo;
