import React, {useState, Fragment, useEffect} from "react";
import { 
    Alert,Button,
} from 'react-bootstrap';
import {
    DEDALO_ALL_PERSONAL_DEDALO,DEDALO_NEW_PERSONAL_DEDALO,
    DEDALO_PERSONAL_DEDALO_COMBO,DEDALO_DEL_PERSONAL,
    DEDALO_EDIT_PERSONAL,DEDALO_PERSONAL_BY_ID,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import { Routes } from "../../utils/routes";
import Navbar from '../navbar';
import Swal from "sweetalert2";
import Logo from '../../imagenes/dedalo_logo.png';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const AlertLicencia = (data) => {
    const [show_danger, setShowDanger] = useState(true);
    const [show_warning, setShowWarning] = useState(true);
    const [show_info, setShowInfo] = useState(true);

    let numero = data.dias;

    setTimeout(() => {
        setShowDanger(false);
        setShowWarning(false);
        setShowInfo(false);
    }, 10000);
    
    
    return (
        <div>
            {parseInt(numero) <= 5 ?
                show_danger &&
                    <Alert variant="danger" onClose={() => setShowDanger(false)} dismissible>
                        <Alert.Heading>
                            <MdIcons.MdDangerous size={30} className="me-2"/>
                            <strong className="fs-5">Recordatorio de licencia! </strong>
                            <span className="fs-6">
                                su licencia de conexion esta a {numero} dias de caducar, por favor contacte al administrador de la aplicacion para renovar la licencia.
                            </span>
                        </Alert.Heading>
                    </Alert>
            :parseInt(numero) > 5 && parseInt(numero) <= 15 ?
                show_warning &&
                    <Alert variant="warning" onClose={() => setShowWarning(false)} dismissible>
                        <Alert.Heading>
                            <MdIcons.MdWarning size={27} className="me-2"/>
                            <strong className="fs-5">Recordatorio de licencia! </strong>
                            <span className="fs-6">
                                en este momento su licencia se encuentra a {numero} dias de vencer.
                            </span>
                        </Alert.Heading>
                    </Alert>
            :parseInt(numero) > 15 && parseInt(numero) <= 30 ?
                show_info &&
                    <Alert variant="info" onClose={() => setShowInfo(false)} dismissible>
                        <Alert.Heading>
                            <MdIcons.MdInfo size={27} className="me-2"/>
                            <strong className="fs-5">Recordatorio de licencia! </strong>
                            <span className="fs-6">
                                en este momento su licencia se encuentra a {numero} dias de vencer.
                            </span>
                        </Alert.Heading>
                    </Alert>
            :
                ''
            }
        </div>
    );
}

export default AlertLicencia;