import React, { useState, Fragment, useEffect } from "react";
import {
    Dropdown, Accordion, Row, Col, Card,
} from 'react-bootstrap';
import {
    DEDALO_GET_ALL_CONSTRUCTIONS, DEDALO_ELIMINAR_CONSTRUCCION,
} from "../../assets/APIAdresses";
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import Swal from "sweetalert2";
import { useUserSession } from "../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from "../../utils/routes";
import Grafico_Presupuesto from '../construcciones/diagramas/diagramapresupuesto';
import Grafico_Pres_Exe from '../construcciones/diagramas/diagramatareasejecutadas';
import Grafico_Items from '../construcciones/diagramas/graficotipoitems';
import { socket } from '../../assets/js/conexion_socket';
import * as Faicons from 'react-icons/fa';

function PerfilDedalo() {
    const dedalo_usr = JSON.parse(localStorage.getItem('dedalo_usr'));
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const { userToken } = useUserSession();
    const datahomi = JSON.parse(localStorage.getItem('projectHomi'));
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const id = datahomi === null ? 0 : parseInt(datahomi.idProject);

    const [categor2, setCategor2] = useState({
        datos: [],
    });

    const peticionGetProyectos = async () => {
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 20000,
            page: 1,
            orderField: 'name',
            order: 'asc',
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_GET_ALL_CONSTRUCTIONS,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(response);
                    setCategor2({
                        ...categor2,
                        ['datos']: datanotice.records
                    });
                } else {
                    setCategor2({
                        ...categor2,
                        ['datos']: [],
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 45-5");
                    console.log(error);
                }
            });
        return info1;
    };

    const usuario_nuevo = () => {
        if (dedalo_usr === null) {
            console.log('pasa por aca');
        } else {
            socket.emit('dl:nuevo_usuario', {
                idSocket: socket.id,
                timestamp: (new Date()).getTime(),
                idUser: dedalo_usr.id,
                nameuser: dedalo_usr.name,
                roluser: dedalo_usr.role_id,
                imguser: dedalo_usr.image,
                sexuser: dedalo_usr.sex,
                app: 'dl',
            });
        };
    }

    useEffect(() => {
        peticionGetProyectos();
        usuario_nuevo();
    }, []);

    const peticionDelete = (iduser) => {
        let delId = iduser;
        Swal.fire({
            title: 'Esta apunto de eliminar el proyecto, oprima si para confirmar o cancelar la operacion',
            showDenyButton: true,
            confirmButtonText: 'Si, eliminar',
            denyButtonText: `No, cancelar`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                //axios.delete(DEDALO_ELIMINAR_CONSTRUCCION(delId), attachToken(user))
                axios({
                    method: 'DELETE',
                    url: DEDALO_ELIMINAR_CONSTRUCCION + '&id=' + delId,
                    headers: { Authorization: userToken },
                })
                    .then(response => {
                        if (response.data.code === '200') {
                            Swal.fire("Se ha eliminado el proyecto exitosamente", "", "success");
                            peticionGetProyectos();
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.data) {
                            Swal.fire("No se pudo eliminar el proyecto ya que se encuentra en curso", "", "warning");
                        }
                    });
            } else if (result.isDenied) {
                Swal.fire('Se ha cancelado la operacion', '', 'info')
            }
        })
    };

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    let data = categor2.datos === undefined ? [] : categor2.datos;
    let proyectos = [];
    let indo_project = user.enterprices;

    indo_project.map(x => {
        proyectos.push(x.proyects === undefined ? 0 : x.proyects);
    });

    //console.log(data);

    if (!user) {
        window.location.href = Routes.LOGIN;
    }

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div>
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-button-dark-example1" size="sm"
                            style={{ background: '#5D6D7E', border: '#5D6D7E', color: '#FFFFFF' }}
                        >
                            Menu de Opciones
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                            <Dropdown.Item>
                                <Link to={Routes.PROYECTO_NUEVO} style={{ color: "white", textDecoration: "none" }}>
                                    agregar proyecto
                                </Link>
                            </Dropdown.Item>
                            {/*user.role_id === 7 || user.role_id === 3 || user.role_id === 2 || user.role_id === 6 ?
                                <div>
                                    <Dropdown.Item>
                                        <Link to={Routes.COT_LICITACIONES} style={{ color: "white", textDecoration: "none" }}>
                                            Cotizaciones
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={Routes.ACTAS} style={{ color: "white", textDecoration: "none" }}>
                                            acta de Inicio
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={Routes.FORMATO_SALIDA} style={{ color: "white", textDecoration: "none" }}>
                                            Formato de salida
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={Routes.INVENTARIO_GENERAL} style={{ color: "white", textDecoration: "none" }}>
                                            Inventario general
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={Routes.MOVE_INVENTARIO_GENERAL} style={{ color: "white", textDecoration: "none" }}>
                                            Movimiento Inventario
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Link to={Routes.REQ_INVENTARIO_GENERAL} style={{ color: "white", textDecoration: "none" }}>
                                            Requerimiento Inventario
                                        </Link>
                                    </Dropdown.Item>
                                </div>
                            :
                                ''
                            */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="container-fluid mt-4">
                <Row>
                    {data.length === 0 ?
                        <div className="mensajeinfo">
                            <h4>
                                En el momento no hay proyectos en curso
                            </h4>
                        </div>
                        :
                        <Accordion className="mb-4">
                            {data.map((i, indx) => (
                                user.role_id === 3 || user.role_id === 4 || user.role_id === 2 ?
                                    <Col md={12}>
                                        <Accordion.Item eventKey={indx} className='acordionBtn'>
                                            <Accordion.Header>{i.name}</Accordion.Header>
                                            <Accordion.Body>
                                                <Card className="card-user mb-3">
                                                    <Card.Header as="h6" className="with-elements cabecera_home">
                                                        <div className="card-header-title">menu proyecto</div>
                                                        <div className="card-header-elements ml-auto">
                                                            <div className="opciones ">
                                                                <div class="dropdown icono_tipo_item">
                                                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                                        data-bs-toggle="dropdown" aria-expanded="false"
                                                                    >
                                                                    </button>
                                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <Link class="dropdown-item cursor-pointer"
                                                                                to={Routes.PROYECTO_HOMI}
                                                                                onClick={() => {
                                                                                    localStorage.setItem("projectHomi", JSON.stringify(
                                                                                        { idProject: i.id, nom_project: i.name },
                                                                                    ));
                                                                                }}
                                                                            >
                                                                                Ingresar al proyecto
                                                                            </Link>
                                                                        </li>
                                                                        {user.role_id === 6 ?
                                                                            ''
                                                                            :
                                                                            <div>
                                                                                <li>
                                                                                    <a class="dropdown-item cursor-pointer" onClick={() => peticionDelete(i.id)}>
                                                                                        Eliminar
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <Link class="dropdown-item cursor-pointer"
                                                                                        to={Routes.EDITAR_PROYECTO(i.id)}
                                                                                    >
                                                                                        Editar
                                                                                    </Link>
                                                                                </li>
                                                                            </div>
                                                                        }
                                                                        <li>
                                                                            <Link class="dropdown-item cursor-pointer"
                                                                                to={Routes.INFORME}
                                                                                onClick={() => { localStorage.setItem("Informe", JSON.stringify({ idinforme: i.id })); }}
                                                                            >
                                                                                Ver informe
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link class="dropdown-item cursor-pointer"
                                                                                to={Routes.INFORME_PDF}
                                                                                onClick={() => { localStorage.setItem("Informe", JSON.stringify({ idinforme: i.id })); }}
                                                                            >
                                                                                Descargar PDF
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link class="dropdown-item cursor-pointer" to={Routes.FLUJOGRAMA_CRUD(i.id)}>
                                                                                Ver Flujograma
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link class="dropdown-item cursor-pointer" to={Routes.CRONOGRAMA_PROYECT(i.id)}>
                                                                                Cronograma del proyecto
                                                                            </Link>
                                                                        </li>
                                                                        {user.role_id === 1 || user.role_id === 6 ?
                                                                            ''
                                                                            :
                                                                            <div>
                                                                                <li>
                                                                                    <Link class="dropdown-item cursor-pointer" to={Routes.CENTRO_COSTOS}>
                                                                                        Centro de costos
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link class="dropdown-item cursor-pointer" to={Routes.FICHA_TECNICA(i.id)}>
                                                                                        Ficha tecnica
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link class="dropdown-item cursor-pointer" to={Routes.SISTEMA_GESTION_CALIDAD(i.id)}>
                                                                                        SGC
                                                                                    </Link>
                                                                                </li>
                                                                            </div>
                                                                        }
                                                                        <li>
                                                                            <Link class="dropdown-item cursor-pointer" to={Routes.FORMATO_SEGUIMIENTO(i.id)}>
                                                                                Seguimiento de actividades
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col md={4}>
                                                                <div>
                                                                    <p>
                                                                        <strong>Direccion del proyecto:</strong> {i.address}
                                                                    </p>
                                                                    <p>
                                                                        <strong>Presupuesto del proyecto:</strong> {formatterPeso.format(i.budget)}
                                                                    </p>
                                                                    <p>
                                                                        <strong>Ciudad del proyecto:</strong> {i.cityname}
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                            <Col md={8}>
                                                                <div>
                                                                    <Card className="w-100 mb-2">
                                                                        <Row noGutters className="row-bordered h-100 linkscolors">
                                                                            <Col sm={12} md={4} lg={4} className="d-flex align-items-center">
                                                                                <a href="#d"
                                                                                    className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto"
                                                                                >
                                                                                    <i className="lnr lnr-checkmark-circle display-5 d-block text-primary"></i>
                                                                                    <span className="media-body d-block ml-3 tamaño_letra">
                                                                                        <span className="text-big font-weight-bolder">
                                                                                            {
                                                                                                formatterPeso.format(i.budget)
                                                                                            }
                                                                                        </span>
                                                                                        <br />
                                                                                        <small className="text-muted">Presupuesto general</small>
                                                                                    </span>
                                                                                </a>
                                                                            </Col>
                                                                            <Col sm={12} md={4} lg={4} className="d-flex align-items-center">
                                                                                <a href="#d"
                                                                                    className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto"
                                                                                >
                                                                                    <i className="lnr lnr-construction display-5 d-block text-primary"></i>
                                                                                    <span className="media-body d-block ml-3 tamaño_letra">
                                                                                        <span className="text-big font-weight-bolder">
                                                                                            {formatterPeso.format(i.executed_budget)}
                                                                                        </span><br />
                                                                                        <small className="text-muted">Presupuesto ejecutado</small>
                                                                                    </span>
                                                                                </a>
                                                                            </Col>
                                                                            <Col sm={12} md={4} lg={4} className="d-flex align-items-center">
                                                                                <a href="#d"
                                                                                    className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto"
                                                                                >
                                                                                    <i className="lnr lnr-chart-bars display-5 d-block text-primary"></i>
                                                                                    <span className="media-body d-block ml-3 tamaño_letra">
                                                                                        <span className="text-big font-weight-bolder">
                                                                                            {formatterPeso.format(i.budget - i.executed_budget)}
                                                                                        </span>
                                                                                        <br />
                                                                                        <small className="text-muted">Presupuesto por ejecutar</small>
                                                                                    </span>
                                                                                </a>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                    <Card className="w-100 mb-4">
                                                                        <Row>
                                                                            <Col sm={12} md={4} lg={4}>
                                                                                <div className="px-3 py-2">
                                                                                    <div className="d-flex">
                                                                                        <div><b>Total Administracion</b></div>
                                                                                        <div className="ms-auto">
                                                                                            {formatterPeso.format(i.adminvalue)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <div><b>Total Contingencia</b></div>
                                                                                        <div className="ms-auto">
                                                                                            {formatterPeso.format(i.continvalue)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <div><b>Total Utilidades</b></div>
                                                                                        <div className="ms-auto">
                                                                                            {formatterPeso.format(i.utilvalue)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <div><b>Total con Iva</b></div>
                                                                                        <div className="ms-auto">
                                                                                            {formatterPeso.format(i.ivavalue)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex">
                                                                                        <div><b>Total</b></div>
                                                                                        <div className="ms-auto">
                                                                                            {formatterPeso.format(i.totalvalue)}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro Grafico presupuesto */}
                                                                <Card className="w-100 mb-4">
                                                                    <Card.Header className="with-elements">
                                                                        <div className="text-small card-header-title">Grafica de presupuesto</div>
                                                                    </Card.Header>
                                                                    <div className="px-2">
                                                                        <br />
                                                                        <Grafico_Presupuesto id_home={i.id} />
                                                                        <br />
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                            <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro Grafico presupuesto tareas ejecutadas */}
                                                                <Card className="w-100 mb-4">
                                                                    <Card.Header className="with-elements">
                                                                        <div className="text-small card-header-title">Grafica de presupuesto tareas ejecutadas</div>
                                                                    </Card.Header>
                                                                    <div className="px-2">
                                                                        <br />
                                                                        <Grafico_Pres_Exe id_home={i.id} />
                                                                        <br />
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro Grafico torta */}
                                                                <Card className="w-100 mb-4">
                                                                    <Card.Header className="with-elements">
                                                                        <div className="text-small card-header-title">Grafica tipos de items</div>
                                                                    </Card.Header>
                                                                    <div className="px-2">
                                                                        <br />
                                                                        <Grafico_Items id_home={i.id} />
                                                                        <br />
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Col>
                                    :
                                    proyectos.map((x, ind) => (
                                        x.constructions_id === undefined ?
                                            ''
                                            :
                                            i.id === parseInt(x.constructions_id) ?
                                                <Col md={12}>
                                                    <Accordion.Item eventKey={ind} className='acordionBtn'>
                                                        <Accordion.Header>{i.name}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Card className="card-user mb-3">
                                                                <Card.Header as="h6" className="with-elements cabecera_home">
                                                                    <div className="card-header-title">menu proyecto</div>
                                                                    <div className="card-header-elements ml-auto">
                                                                        <div className="opciones ">
                                                                            <div class="dropdown icono_tipo_item">
                                                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                                                >
                                                                                </button>
                                                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                    <li>
                                                                                        <Link class="dropdown-item cursor-pointer"
                                                                                            to={Routes.PROYECTO_HOMI}
                                                                                            onClick={() => { localStorage.setItem("projectHomi", JSON.stringify({ idProject: i.id })); }}
                                                                                        >
                                                                                            Ingresar al proyecto
                                                                                        </Link>
                                                                                    </li>
                                                                                    {user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <div>
                                                                                            <li>
                                                                                                <a class="dropdown-item cursor-pointer" onClick={() => peticionDelete(i.id)}>
                                                                                                    Eliminar
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link class="dropdown-item cursor-pointer"
                                                                                                    to={Routes.EDITAR_PROYECTO(i.id)}
                                                                                                >
                                                                                                    Editar
                                                                                                </Link>
                                                                                            </li>
                                                                                        </div>
                                                                                    }
                                                                                    <li>
                                                                                        <Link class="dropdown-item cursor-pointer"
                                                                                            to={Routes.INFORME}
                                                                                            onClick={() => { localStorage.setItem("Informe", JSON.stringify({ idinforme: i.id })); }}
                                                                                        >
                                                                                            Ver informe
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link class="dropdown-item cursor-pointer"
                                                                                            to={Routes.INFORME_PDF}
                                                                                            onClick={() => { localStorage.setItem("Informe", JSON.stringify({ idinforme: i.id })); }}
                                                                                        >
                                                                                            Descargar PDF
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link class="dropdown-item cursor-pointer" to={Routes.FLUJOGRAMA_CRUD(i.id)}>
                                                                                            Ver Flujograma
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link class="dropdown-item cursor-pointer" to={Routes.CRONOGRAMA_PROYECT(i.id)}>
                                                                                            Cronograma del proyecto
                                                                                        </Link>
                                                                                    </li>
                                                                                    {user.role_id === 1 || user.role_id === 6 ?
                                                                                        ''
                                                                                        :
                                                                                        <div>
                                                                                            <li>
                                                                                                <Link class="dropdown-item cursor-pointer" to={Routes.MENU_CONTABILIDAD(i.id)}>
                                                                                                    Contabilidad
                                                                                                </Link>
                                                                                            </li>
                                                                                            <li>
                                                                                                <Link class="dropdown-item cursor-pointer" to={Routes.FICHA_TECNICA(i.id)}>
                                                                                                    Ficha tecnica
                                                                                                </Link>
                                                                                            </li>
                                                                                        </div>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card.Header>
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <div>
                                                                                <p>
                                                                                    <strong>Direccion del proyecto:</strong> {i.address}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Presupuesto del proyecto:</strong> {formatterPeso.format(i.budget)}
                                                                                </p>
                                                                                <p>
                                                                                    <strong>Ciudad del proyecto:</strong> {i.cityname}
                                                                                </p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={8}>
                                                                            <div>
                                                                                <Card className="w-100 mb-2">
                                                                                    <Row noGutters className="row-bordered h-100 linkscolors">
                                                                                        <Col sm={12} md={4} lg={4} className="d-flex align-items-center">
                                                                                            <a href="#d"
                                                                                                className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto"
                                                                                            >
                                                                                                <i className="lnr lnr-checkmark-circle display-5 d-block text-primary"></i>
                                                                                                <span className="media-body d-block ml-3 tamaño_letra">
                                                                                                    <span className="text-big font-weight-bolder">
                                                                                                        {
                                                                                                            formatterPeso.format(i.budget)
                                                                                                        }
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <small className="text-muted">Presupuesto general</small>
                                                                                                </span>
                                                                                            </a>
                                                                                        </Col>
                                                                                        <Col sm={12} md={4} lg={4} className="d-flex align-items-center">
                                                                                            <a href="#d"
                                                                                                className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto"
                                                                                            >
                                                                                                <i className="lnr lnr-construction display-5 d-block text-primary"></i>
                                                                                                <span className="media-body d-block ml-3 tamaño_letra">
                                                                                                    <span className="text-big font-weight-bolder">
                                                                                                        {formatterPeso.format(i.executed_budget)}
                                                                                                    </span><br />
                                                                                                    <small className="text-muted">Presupuesto ejecutado</small>
                                                                                                </span>
                                                                                            </a>
                                                                                        </Col>
                                                                                        <Col sm={12} md={4} lg={4} className="d-flex align-items-center">
                                                                                            <a href="#d"
                                                                                                className="card-body media align-items-center text-body text-decoration-none iconos_presupuesto"
                                                                                            >
                                                                                                <i className="lnr lnr-chart-bars display-5 d-block text-primary"></i>
                                                                                                <span className="media-body d-block ml-3 tamaño_letra">
                                                                                                    <span className="text-big font-weight-bolder">
                                                                                                        {formatterPeso.format(i.budget - i.executed_budget)}
                                                                                                    </span>
                                                                                                    <br />
                                                                                                    <small className="text-muted">Presupuesto por ejecutar</small>
                                                                                                </span>
                                                                                            </a>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card>
                                                                                <Card className="w-100 mb-4">
                                                                                    <Row>
                                                                                        <Col sm={12} md={4} lg={4}>
                                                                                            <div className="px-3 py-2">
                                                                                                <div className="d-flex">
                                                                                                    <div><b>Total Administracion</b></div>
                                                                                                    <div className="ms-auto">
                                                                                                        {formatterPeso.format(i.adminvalue)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <div><b>Total Contingencia</b></div>
                                                                                                    <div className="ms-auto">
                                                                                                        {formatterPeso.format(i.continvalue)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <div><b>Total Utilidades</b></div>
                                                                                                    <div className="ms-auto">
                                                                                                        {formatterPeso.format(i.utilvalue)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <div><b>Total con Iva</b></div>
                                                                                                    <div className="ms-auto">
                                                                                                        {formatterPeso.format(i.ivavalue)}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="d-flex">
                                                                                                    <div><b>Total</b></div>
                                                                                                    <div className="ms-auto">
                                                                                                        {formatterPeso.format(i.totalvalue)}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro Grafico presupuesto */}
                                                                            <Card className="w-100 mb-4">
                                                                                <Card.Header className="with-elements">
                                                                                    <div className="text-small card-header-title">Grafica de presupuesto</div>
                                                                                </Card.Header>
                                                                                <div className="px-2">
                                                                                    <br />
                                                                                    <Grafico_Presupuesto id_home={i.id} />
                                                                                    <br />
                                                                                </div>
                                                                            </Card>
                                                                        </Col>
                                                                        <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro Grafico presupuesto tareas ejecutadas */}
                                                                            <Card className="w-100 mb-4">
                                                                                <Card.Header className="with-elements">
                                                                                    <div className="text-small card-header-title">Grafica de presupuesto tareas ejecutadas</div>
                                                                                </Card.Header>
                                                                                <div className="px-2">
                                                                                    <br />
                                                                                    <Grafico_Pres_Exe id_home={i.id} />
                                                                                    <br />
                                                                                </div>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl={6} className="d-flex align-items-stretch"> {/* cuadro Grafico torta */}
                                                                            <Card className="w-100 mb-4">
                                                                                <Card.Header className="with-elements">
                                                                                    <div className="text-small card-header-title">Grafica tipos de items</div>
                                                                                </Card.Header>
                                                                                <div className="px-2">
                                                                                    <br />
                                                                                    <Grafico_Items id_home={i.id} />
                                                                                    <br />
                                                                                </div>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Card>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Col>
                                                :
                                                ''
                                    ))
                            ))}
                        </Accordion>
                    }
                </Row>
            </div>
        </div>

    );
}

export default PerfilDedalo;