import React, { useState, Fragment, useEffect } from "react";
import {
    Card, Button, InputGroup, FloatingLabel, FormControl, Row, Col,
    Form, Nav, Table, Dropdown, DropdownButton,
} from 'react-bootstrap';
import {
    DEDALO_CONSTRUCCION_BY_ID, DEDALO_ALL_SEGUIMIENTO_ACTVIDAD, DEDALO_SEGUIMIENTO_ACTVIDAD_BY_ID,
    DEDALO_NEW_SEGUIMIENTO_ACTVIDAD, DEDALO_UPDATE_SEGUIMIENTO_ACTVIDAD, DEDALO_DEL_SEGUIMIENTO_ACTVIDAD
} from "../../assets/APIAdresses";
import { message_responses } from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import { useUserSession } from "../../hooks";
import { Redirect, Link, useParams } from "react-router-dom";
import { Routes } from '../../utils/routes';
import Navbar from '../navbar';
import Swal from "sweetalert2";
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const Seguimiento_Actividades = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();
    const [nuevo, setNuevo] = useState(false);
    const [editar, setEditar] = useState(false);
    const [editarid, setEditarid] = useState(0);

    const { id } = useParams();

    const [dats, setDat] = useState({
        datos: [],
        datos_seguimiento: [],
        area: '',
        sprint: '',
        cargo: '',
        porcentaje: '',
        plazo: '',
        obs: '',

        area_edit: '',
        sprint_edit: '',
        cargo_edit: '',
        porcentaje_edit: '',
        plazo_edit: '',
        obs_edit: '',
    });

    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let today = new Date();

    const [rows, setRows] = useState([
        { id: 0, checkbox_check: '', obs_seguimiento: '', obs_retraso: '', cant: '', obs_tareas: '' },
    ]);

    const peticionGetCategor = async () => {
        const params_data = {
            constructions_id: parseInt(id),
            search: '',
            perpage: 200000,
            page: 1,
            orderField: 'date',
            order: 'asc',
        };

        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_SEGUIMIENTO_ACTVIDAD,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data;
                    //console.log(datanotice);
                    setDat({
                        ...dats,
                        ['datos_seguimiento']: datanotice.records,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(error.response.data.message);
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
                }
            });

        return info1;
    };

    const peticionGetConstructionById = async () => {
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CONSTRUCCION_BY_ID + '&id=' + parseInt(id),
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg;
                    //console.log(datanotice.data);
                    setDat({
                        ...dats,
                        ['datos']: datanotice.data,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert(
                        error.response.data.message === "VALIDATOR_ERROR"
                            ? error.response.data.data.error_message
                            : message_responses[error.response.data.message]
                    );
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
        return info1;
    };

    const peticionGetSeguimientoById = async (idSeg) => {
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_SEGUIMIENTO_ACTVIDAD_BY_ID + '&id=' + parseInt(idSeg),
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.msg.data;
                    let d = [];
                    console.log(datanotice.data);
                    setDat({
                        ...dats,
                        ['area_edit']: datanotice.area,
                        ['sprint_edit']: datanotice.sprint,
                        ['cargo_edit']: datanotice.position,
                        ['porcentaje_edit']: datanotice.accumulated_percentage,
                        ['plazo_edit']: datanotice.term,
                        ['obs_edit']: datanotice.observations,
                    });

                    setRows([
                        {
                            id: 0, checkbox_check: `check_${datanotice.delay}`, obs_seguimiento: datanotice.daily_advance,
                            obs_retraso: datanotice.cause_delay, cant: datanotice.task, obs_tareas: datanotice.task_description
                        },
                    ]);

                    let checkedit1 = document.getElementById('default_checkbox_yes');
                    let checkedit2 = document.getElementById('default_checkbox_no');

                    if (datanotice.delay === 'yes') {
                        checkedit1.checked = true;
                    }
                    if (datanotice.delay === 'no') {
                        checkedit2.checked = true;
                    }
                } else {
                    alert('salida');
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert('error.response.data.message');
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
                }
            });
        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetConstructionById();
    }, []);

    const handleInputChange = (e, index) => {
        const { name, value, checked } = e.target;
        const list = [...rows];
        list[index][name] = value;
        setRows(list);
    };

    const handleInputChangeCheck = (e, index) => {
        const { name, value, checked } = e.target;
        const list = [...rows];
        list[index]['checkbox_check'] = checked === false ? '' : value;
        setRows(list);
    };

    const agregarFila = () => {
        setRows([
            ...rows,
            {
                id: rows.length + 0, checkbox_check: '', obs_seguimiento: '',
                obs_retraso: '', cant: '', obs_tareas: ''
            },
        ]);
    };

    const eliminarFila = (i) => {
        const list = [...rows];
        list.splice(i, 1);
        setRows(list);
    };

    const handleInputChangeText = (event) => {
        setDat({
            ...dats,
            [event.target.name]: event.target.value
        })
    };

    const editarseguimiento = (idSeg) => {
        peticionGetSeguimientoById(idSeg);
        setNuevo(!nuevo);
        setEditar(!editar);
        setEditarid(parseInt(idSeg));
    };

    let tareas = [];
    let obs_tareas = [];
    let obs_seguimiento = [];
    let checkbox_check = [];
    let obs_retraso = [];

    rows.map((row, index) => {
        tareas.push(row.cant);
        obs_tareas.push(row.obs_tareas);
        obs_seguimiento.push(row.obs_seguimiento);
        checkbox_check.push(row.checkbox_check.split('_')[1]);
        obs_retraso.push(row.obs_retraso);
    });

    const handleCloseSave = (e) => {
        const proyecto = dats.area;
        const dia = today.getDate();
        const mes = today.getMonth() + 1;
        const year = today.getFullYear();

        const formattedData = {
            users_id: userId,
            constructions_id: id,
            date: year + '-' + mes + '-' + dia,
            name: user.name,
            area: dats.area,
            position: dats.cargo,
            sprint: dats.sprint,
            term: dats.plazo,
            accumulated_percentage: dats.porcentaje,
            observations: dats.obs,
            task_description: obs_tareas,
            daily_advance: obs_seguimiento,
            task: tareas,
            delay: checkbox_check,
            cause_delay: obs_retraso

        };
        //console.log(formattedData);
        if (proyecto === '') {
            Swal.fire("No se pudo crear el proyecto, valide que los campos esten con la informacion pertinente", "", "warning");
        } else {
            //axios.post(DEDALO_NUEVA_CONSTRUCCION, formattedData, attachToken(user))
            axios({
                method: 'POST',
                url: DEDALO_NEW_SEGUIMIENTO_ACTVIDAD,
                headers: { Authorization: userToken },
                data: formattedData,
            })
                .then(response => {
                    if (response.data.code === '200') {
                        Swal.fire("Se creó el seguimiento exitósamente", "", "success");
                        document.getElementById('area').value = '';
                        document.getElementById('sprint').value = '';
                        document.getElementById('cargo').value = '';
                        document.getElementById('porcentaje').value = '';
                        document.getElementById('plazo').value = '';
                        document.getElementById('obs').value = '';
                        peticionGetCategor();
                        setRows([
                            { id: 0, checkbox_check: '', obs_seguimiento: '', obs_retraso: '', cant: '', obs_tareas: '' },
                        ]);
                    } else {
                        //alert("ERROR: Server Error");
                        Swal.fire('response.data.msg.error', "", "info");
                    }
                })
                .catch(error => {
                    //manageError(error);
                    Swal.fire(error, "", "info");
                })

        }
    };

    const handleCloseSaveEdit = (e) => {
        const dia = today.getDate();
        const mes = today.getMonth() + 1;
        const year = today.getFullYear();

        const formattedData = {
            users_id: userId,
            constructions_id: id,
            date: year + '-' + mes + '-' + dia,
            name: user.name,
            area: dats.area_edit,
            position: dats.cargo_edit,
            sprint: dats.sprint_edit,
            term: dats.plazo_edit,
            accumulated_percentage: dats.porcentaje_edit,
            observations: dats.obs_edit,
            task_description: obs_tareas,
            daily_advance: obs_seguimiento,
            task: tareas,
            delay: checkbox_check,
            cause_delay: obs_retraso

        };
        //console.log(formattedData);

        axios({
            method: 'POST',
            url: DEDALO_UPDATE_SEGUIMIENTO_ACTVIDAD + '&id=' + editarid,
            headers: { Authorization: userToken },
            data: formattedData,
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se edito el seguimiento exitósamente", "", "success");
                    peticionGetCategor();
                    setNuevo(!nuevo);
                    setEditar(!editar);
                    setRows([
                        { id: 0, checkbox_check: '', obs_seguimiento: '', obs_retraso: '', cant: '', obs_tareas: '' },
                    ]);
                } else {
                    //alert("ERROR: Server Error");
                    Swal.fire('response.data.msg.error', "", "info");
                }
            })
            .catch(error => {
                //manageError(error);
                Swal.fire(error, "", "info");
            })
    };

    const peticionDelete = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_SEGUIMIENTO_ACTVIDAD + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el item exitósamente", "", "success");
                    //window.location.reload();
                    peticionGetCategor();
                } else {
                    alert("ERROR: Server Error");
                }
            })
    };

    const cancelar_edicion = () => {
        setNuevo(!nuevo);
        setEditar(!editar);
        setRows([
            { id: 0, checkbox_check: '', obs_seguimiento: '', obs_retraso: '', cant: '', obs_tareas: '' },
        ]);
    };


    return (
        <div>
            <div className="d-flex container-fluid my-2">
                <div>
                    <Button className="btn btn-success rounded-circle me-2" size="sm" style={{ backgroundColor: "#198754", color: "#fff" }}
                        onClick={() => { window.history.back() }} title="Regresar"
                    >
                        <MdIcons.MdArrowBackIosNew />
                    </Button>
                </div>
                <div>
                    <Button className="btn btn-success" size="sm" style={{ backgroundColor: "#198754", color: "#fff" }}
                        onClick={() => { setNuevo(!nuevo) }} title="Regresar"
                    >
                        Nuevo Seguimiento
                    </Button>
                </div>
            </div>
            <div className="container-fluid mb-4">
                <Row>
                    <Col md={nuevo === true ? 6 : 12}>
                        {/*<Col md={6}>*/}
                        <div className="container-fluid text-center mb-4">
                            <h3>formatos de seguimiento generados</h3>
                        </div>
                        <div className={nuevo === false ? "container" : ""}>
                            {/*<div>*/}
                            <Table striped bordered hover size="sm">
                                <thead className="text-center">
                                    <th>id</th>
                                    <th style={{ width: '12rem' }}>tarea</th>
                                    <th>Realizado por</th>
                                    <th style={{ width: '8rem' }}>fecha realizado</th>
                                    <th style={{ width: '8rem' }}>hay retraso</th>
                                    <th>observaciones retraso</th>
                                    <th>observaciones</th>
                                    <th>Acciones</th>
                                </thead>
                                <tbody className="text-center">
                                    {dats.datos_seguimiento === undefined ?
                                        ''
                                        :
                                        dats.datos_seguimiento.map((x, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{x.task}</td>
                                                <td>{x.name}</td>
                                                <td>{x.date}</td>
                                                <td>{x.delay}</td>
                                                <td>{x.cause_delay}</td>
                                                <td>{x.observations}</td>
                                                <td>
                                                    <div class="dropdown acciones_folders">
                                                        <BsIcons.BsThreeDotsVertical class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => editarseguimiento(x.id)}>
                                                                    Editar
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => peticionDelete(x.id)}>
                                                                    Eliminar
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    {nuevo === true ?
                        editar === true ?
                            <Col md={6}>
                                <div className="border border-dark-subtle p-2">
                                    <div className="d-flex">
                                        <div>
                                            <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#198754", color: "#fff" }}
                                                onClick={handleCloseSaveEdit}
                                            >
                                                Editar seguimiento
                                            </Button>
                                        </div>
                                        <div className="ms-auto">
                                            <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#198754", color: "#fff" }}
                                                onClick={cancelar_edicion}
                                            >
                                                cerrar
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <Row>
                                            <Col md={8}>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Nombre</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" value={user.name} disabled
                                                        aria-describedby="inputGroup-sizing-sm"
                                                    />
                                                </InputGroup>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Proyecto</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" value={dats.datos.name === undefined ? "" : dats.datos.name}
                                                        aria-describedby="inputGroup-sizing-sm" disabled
                                                    />
                                                </InputGroup>
                                                <Row>
                                                    <Col md={6}>
                                                        <InputGroup size="sm" className="mb-3">
                                                            <InputGroup.Text id="inputGroup-sizing-sm">porcentaje</InputGroup.Text>
                                                            <Form.Control
                                                                aria-label="Small" className="text-center"
                                                                aria-describedby="inputGroup-sizing-sm" value={dats.porcentaje_edit}
                                                                name="porcentaje_edit" id="porcentaje_edit"
                                                                onChange={handleInputChangeText}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputGroup size="sm" className="mb-3">
                                                            <InputGroup.Text id="inputGroup-sizing-sm">tiempo de duracion</InputGroup.Text>
                                                            <Form.Control
                                                                aria-label="Small" name="plazo_edit" id="plazo_edit"
                                                                aria-describedby="inputGroup-sizing-sm" value={dats.plazo_edit}
                                                                onChange={handleInputChangeText}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Fecha</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" value={today.toLocaleDateString("es-CO", options)}
                                                        aria-describedby="inputGroup-sizing-sm" disabled
                                                    />
                                                </InputGroup>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Area</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" name="area_edit" id="area_edit" value={dats.area_edit}
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        onChange={handleInputChangeText}
                                                    />
                                                </InputGroup>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Sprint No.</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" name="sprint_edit" id="sprint_edit"
                                                        aria-describedby="inputGroup-sizing-sm" value={dats.sprint_edit}
                                                        onChange={handleInputChangeText}
                                                    />
                                                </InputGroup>
                                                <InputGroup size="sm" className="mb-1">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">cargo</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" name="cargo_edit" id="cargo_edit"
                                                        aria-describedby="inputGroup-sizing-sm" value={dats.cargo_edit}
                                                        onChange={handleInputChangeText}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <strong>Observaciones</strong>
                                                    <Form.Control
                                                        as="textarea" name="obs_edit" id="obs_edit" value={dats.obs_edit}
                                                        placeholder="Digite las observaciones"
                                                        onChange={handleInputChangeText}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="px-3" style={{ maxHeight: '27rem', overflow: 'hidden', overflowY: 'auto' }}>
                                            {rows.map((row, i) => (
                                                <div className="mb-3">
                                                    <div className="d-flex">
                                                        <div className="d-flex py-2">
                                                            <div className="me-2">
                                                                <h3><strong>Tarea:</strong></h3>
                                                            </div>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <Form.Control
                                                                    type="text" name="cant" size="sm" value={row.cant}
                                                                    onChange={(e) => handleInputChange(e, i)}
                                                                    style={{ marginRight: '0', width: '30rem' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={i + 1 === 1 ? "d-none" : "ms-auto d-flex justify-content-center align-items-center"}>
                                                            <BsIcons.BsTrashFill color="red" onClick={() => eliminarFila(i)} title="eliminar segimiento"
                                                                style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <strong>Observaciones de la tarea</strong>
                                                        <Form.Control
                                                            as="textarea" name="obs_tareas"
                                                            placeholder="Digite las observaciones" value={row.obs_tareas}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <strong>Avance diario</strong>
                                                        <Form.Control
                                                            as="textarea" name="obs_seguimiento"
                                                            placeholder="Digite el seguimiento" value={row.obs_seguimiento}
                                                            style={{ height: '100px' }} onChange={(e) => handleInputChange(e, i)}
                                                        />
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <div className="me-2">
                                                            <strong>Hay retraso de la tarea:</strong>
                                                        </div>
                                                        <div className="me-2">
                                                            <Form.Check
                                                                type={'checkbox'} onChange={(e) => handleInputChangeCheck(e, i)}
                                                                id={`default_checkbox_yes`} name={`checkbox_check`}
                                                                label={`SI`} value={'check_yes'} className="checks_box"
                                                            />
                                                        </div>
                                                        <div className="me-2">
                                                            <Form.Check
                                                                type={'checkbox'} onChange={(e) => handleInputChangeCheck(e, i)}
                                                                id={`default_checkbox_no`} name={`checkbox_check`}
                                                                label={`NO`} value={'check_no'} className="checks_box"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={row.checkbox_check === "check_yes" ? "d-block" : "d-none"}>
                                                        <Form.Control
                                                            as="textarea" name="obs_retraso" onChange={(e) => handleInputChange(e, i)}
                                                            placeholder="Digite el retraso" value={row.obs_retraso}
                                                            style={{ height: '100px' }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            :
                            <Col md={6}>
                                <div className="border border-dark-subtle p-2">
                                    <div className="d-flex">
                                        <div>
                                            <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#198754", color: "#fff" }}
                                                onClick={handleCloseSave}
                                            >
                                                Guardar seguimiento
                                            </Button>
                                        </div>
                                        <div>
                                            <Button className="btn btn-primary me-2" size="sm" style={{ backgroundColor: "#0D6EFD", color: '#fff' }}
                                                onClick={agregarFila} title="Agregar Item"
                                            >
                                                Agregar Item
                                            </Button>
                                        </div>
                                        <div className="ms-auto">
                                            <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#198754", color: "#fff" }}
                                                onClick={() => { setNuevo(!nuevo) }}
                                            >
                                                cerrar
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <Row>
                                            <Col md={8}>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Nombre</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" value={user.name} disabled
                                                        aria-describedby="inputGroup-sizing-sm"
                                                    />
                                                </InputGroup>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Proyecto</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" value={dats.datos.name === undefined ? "" : dats.datos.name}
                                                        aria-describedby="inputGroup-sizing-sm" disabled
                                                    />
                                                </InputGroup>
                                                <Row>
                                                    <Col md={6}>
                                                        <InputGroup size="sm" className="mb-3">
                                                            <InputGroup.Text id="inputGroup-sizing-sm">porcentaje</InputGroup.Text>
                                                            <Form.Control
                                                                aria-label="Small" className="text-center"
                                                                aria-describedby="inputGroup-sizing-sm"
                                                                name="porcentaje" id="porcentaje"
                                                                onChange={handleInputChangeText}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputGroup size="sm" className="mb-3">
                                                            <InputGroup.Text id="inputGroup-sizing-sm">tiempo de duracion</InputGroup.Text>
                                                            <Form.Control
                                                                aria-label="Small" name="plazo" id="plazo"
                                                                aria-describedby="inputGroup-sizing-sm"
                                                                onChange={handleInputChangeText}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Fecha</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" value={today.toLocaleDateString("es-CO", options)}
                                                        aria-describedby="inputGroup-sizing-sm" disabled
                                                    />
                                                </InputGroup>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Area</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" name="area" id="area"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        onChange={handleInputChangeText}
                                                    />
                                                </InputGroup>
                                                <InputGroup size="sm" className="mb-3">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">Sprint No.</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" name="sprint" id="sprint"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        onChange={handleInputChangeText}
                                                    />
                                                </InputGroup>
                                                <InputGroup size="sm" className="mb-1">
                                                    <InputGroup.Text id="inputGroup-sizing-sm">cargo</InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Small" name="cargo" id="cargo"
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        onChange={handleInputChangeText}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <strong>Observaciones</strong>
                                                    <Form.Control
                                                        as="textarea" name="obs" id="obs"
                                                        placeholder="Digite las observaciones"
                                                        onChange={handleInputChangeText}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="px-3" style={{ maxHeight: '27rem', overflow: 'hidden', overflowY: 'auto' }}>
                                            {rows.map((row, i) => (
                                                <div className="mb-3">
                                                    <div className="d-flex">
                                                        <div className="d-flex py-2">
                                                            <div className="me-2">
                                                                <h3><strong>Tarea:</strong></h3>
                                                            </div>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <Form.Control
                                                                    type="text" name="cant" size="sm" value={row.cant}
                                                                    onChange={(e) => handleInputChange(e, i)}
                                                                    style={{ marginRight: '0', width: '30rem' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className={i + 1 === 1 ? "d-none" : "ms-auto d-flex justify-content-center align-items-center"}>
                                                            <BsIcons.BsTrashFill color="red" onClick={() => eliminarFila(i)} title="eliminar segimiento"
                                                                style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <strong>Observaciones de la tarea</strong>
                                                        <Form.Control
                                                            as="textarea" name="obs_tareas"
                                                            placeholder="Digite las observaciones" value={row.obs_tareas}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <strong>Avance diario</strong>
                                                        <Form.Control
                                                            as="textarea" name="obs_seguimiento"
                                                            placeholder="Digite el seguimiento" value={row.obs_seguimiento}
                                                            style={{ height: '100px' }} onChange={(e) => handleInputChange(e, i)}
                                                        />
                                                    </div>
                                                    <div className="d-flex mb-2">
                                                        <div className="me-2">
                                                            <strong>Hay retraso de la tarea:</strong>
                                                        </div>
                                                        <div className="me-2">
                                                            <Form.Check
                                                                type={'checkbox'} onChange={(e) => handleInputChangeCheck(e, i)}
                                                                id={`default_checkbox_${i + 1}`} name={`checkbox_check`}
                                                                label={`SI`} value={'check_yes'} className="checks_box"
                                                            />
                                                        </div>
                                                        <div className="me-2">
                                                            <Form.Check
                                                                type={'checkbox'} onChange={(e) => handleInputChangeCheck(e, i)}
                                                                id={`default_checkbox_${i + 1}`} name={`checkbox_check`}
                                                                label={`NO`} value={'check_no'} className="checks_box"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={row.checkbox_check === "check_yes" ? "d-block" : "d-none"}>
                                                        <Form.Control
                                                            as="textarea" name="obs_retraso" onChange={(e) => handleInputChange(e, i)}
                                                            placeholder="Digite el retraso" value={row.obs_retraso}
                                                            style={{ height: '100px' }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        :
                        ''
                    }
                </Row>
            </div>
        </div>
    );
}

export default Seguimiento_Actividades;