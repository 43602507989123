import React from "react";
import './App.scss';
import './assets/js/conexion_socket';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import {UserSessionContextProvider} from "./hooks/useUserSession";
import {Redirect, useParams} from "react-router-dom";
//import Sidebar from './components/siderbar';
import Sidebar from './components/sidebar/sidebar2';
import Navbar from './components/navbar';
import Login from './components/login/Login';
import TablaUsuarios from './components/usuarios/totalusuario';
import home from './components/body/home';
import Editarusuario from './components/usuarios/editarusuario';
//import UsuarioNuevo from './components/login/crearusuario';
import RecuperaContraseña from './components/login/recuperarcontraseña';
import RecuperaContraseñaLogin from './components/usuarios/reestablecercontraseña';
import CrearUsuarioNuevos from './components/usuarios/crearusuarionuevo';
import RestablecerContraseña from './components/login/set-password';
import DedaloPerfil from './components/usuarios/dedaloperfil';
import ProyectosDedalo from './components/construcciones/proyectos';
import ProyectoHomi from './components/construcciones/proyectohomi';
import NuevoProyecto from './components/construcciones/agregarproyecto';
import EditarProyecto from './components/construcciones/editarproyecto';
import EditarCuadrilla from './components/construcciones/modalesinfo/modaleditarcuadrilla';
import LineaDeTiempo from './components/construcciones/LineaDeTiempo';
import Subtareas from './components/construcciones/subtareas/subtareas';
import NuevaSubtarea from './components/construcciones/subtareas/nuevasubtarea';
import EditarSubtarea from './components/construcciones/subtareas/editarsubtarea';
import InfoCortes from './components/construcciones/cortesproyecto/TablaCortes';
import CrmEmpresas from './components/empresas/crmempresas';
import Requerimientos from './components/construcciones/almacen/nuevorequerimiento';
import PersonalDedalo from './components/personal/personal';
import Productos from './components/productos/productos';
import Proveedores from './components/proveedores/proveedores';
import Compartir from './components/CompartirArchivos/compartir';
import ContenidoCarpeta from './components/CompartirArchivos/contenidocarpeta';
import Movimiento from './components/construcciones/almacen/movimientoinvetario';
import Informe from './components/informes/informe';
import InformePdf from './components/informes/informePDF';
import ReporteGeneral from './components/usuarios/reporte';
import ReportePdf from './components/usuarios/reportePDF';
import Licencia from './components/multiempresas/comprobarlicencia';
import Mensajelicencia from './components/empresas/alertlicencia';
import Comentarios_tareas from './components/construcciones/comentarios/comentariostareas';
import Diagrama from './components/construcciones/diagramas/diagramatareas';
import ReunionNueva from './components/reuniones/reuniones';
import EditarReunion from './components/reuniones/editaracta';
import TareaNueva from './components/construcciones/modalesinfo/modaltareas';
import EditarTarea from './components/construcciones/modalesinfo/modaleditartareanuevo';
import Departamentos_Emp from './components/empresas/productosdep';
import VerOrganigrama from './components/empresas/organigrama';
import FlujogramaProyecto from './components/construcciones/flujograma/flujograma';
import CrudFlujograma from './components/construcciones/flujograma/crudflujograma';
import EditarFlujograma from './components/construcciones/flujograma/editflujograma';
import VerFlujograma from './components/construcciones/flujograma/verflujograma';
import Cronograma from './components/construcciones/diagramas/cronograma';
import Menu from './components/contabilidad/menu_contabilidad';
import Req from './components/contabilidad/requisiciones/requisiciones';
import Req_nueva from './components/contabilidad/requisiciones/requisiones_nuevas';
import Req_editar from './components/contabilidad/requisiciones/requisiones_editar';
import Req_Preview from './components/contabilidad/requisiciones/ver_requisicion';
import Ordenes from './components/contabilidad/ordenes de compra/ordenes_compra';
import Ord_nueva from './components/contabilidad/ordenes de compra/ordenes_nuevas';
import Ord_editar from './components/contabilidad/ordenes de compra/orden_editar';
import Ver_ord from './components/contabilidad/ordenes de compra/vista_orden';
import Centro_cst from './components/contabilidad/centro_costos/centrocostos';
import Cot_Licitaciones from './components/contabilidad/licitaciones/cotizacion_licitacion';
import Cot_Nueva from './components/contabilidad/licitaciones/cotizacion_nueva';
import Cot_Editar from './components/contabilidad/licitaciones/cotizacion_editar';
import Acta from './components/contabilidad/acta de inicio/actas';
import Acta_Nueva from './components/contabilidad/acta de inicio/acta_inicio_new';
import Acta_Editar from './components/contabilidad/acta de inicio/acta_inicio_act';
import Anticipo from './components/contabilidad/anticipos/anticipos';
import Ant_Nueva from './components/contabilidad/anticipos/anticipos_nuevo';
import Ant_Editar from './components/contabilidad/anticipos/anticipos_edit';
import Leg_ant from './components/contabilidad/anticipos/legalizaciones';
import Leg_ant_edit from './components/contabilidad/anticipos/legalizaciones_edit';
import FichaTecnica from './components/contabilidad/ficha tecnica/ficha_tecnica';
import Elem_no_Confor from './components/contabilidad/ficha tecnica/elementos no conformes/elem_no_conforme';
import Elem_no_Confor_New from './components/contabilidad/ficha tecnica/elementos no conformes/elem_no_confor_new';
import Elem_no_Confor_Edit from './components/contabilidad/ficha tecnica/elementos no conformes/elem_no_confor_edit';
import Cumplimiento_crono from './components/contabilidad/ficha tecnica/cumplimiento cronograma/cumplimiento';
import Cumplimiento_crono_New from './components/contabilidad/ficha tecnica/cumplimiento cronograma/cumplimiento_new';
import Cumplimiento_crono_Edit from './components/contabilidad/ficha tecnica/cumplimiento cronograma/cumplimiento_edit';
import PerfilCargo from './components/personal/perfil cargo/perfil';
import PerfilCargo_New from './components/personal/perfil cargo/perfil_nuevo';
import PerfilCargo_Edit from './components/personal/perfil cargo/perfil_edit';
import MenuFormatos from './components/contabilidad/formatos proveedores/menu_formatos';
import CuadroComparativo from './components/contabilidad/formatos proveedores/cuadro comparativo/cuadro_comparativo';
import CuadroComparativo_New from './components/contabilidad/formatos proveedores/cuadro comparativo/cuadro_comparativo_new';
import CuadroComparativo_Edit from './components/contabilidad/formatos proveedores/cuadro comparativo/cuadro_comparativo_edit';
import EvaluacionProveedor from './components/contabilidad/formatos proveedores/evaluacion proveedores/evaluacion_proveedor';
import EvaluacionProveedor_New from './components/contabilidad/formatos proveedores/evaluacion proveedores/evaluacion_proveedor_new';
import EvaluacionProveedor_Edit from './components/contabilidad/formatos proveedores/evaluacion proveedores/evaluacion_proveedor_edit';
import ReevaluacionProveedor from './components/contabilidad/formatos proveedores/reevaluacion proveedores/reevaluacion_proveedor';
import ReevaluacionProveedor_New from './components/contabilidad/formatos proveedores/reevaluacion proveedores/reevaluacion_proveedor_new';
import ReevaluacionProveedor_Edit from './components/contabilidad/formatos proveedores/reevaluacion proveedores/reevaluacion_proveedor_edit';
import SelectProveedor from './components/contabilidad/formatos proveedores/seleccion proveedores/select_proveedor';
import SelectProveedor_New from './components/contabilidad/formatos proveedores/seleccion proveedores/select_proveedor_new';
import SelectProveedor_Edit from './components/contabilidad/formatos proveedores/seleccion proveedores/select_proveedor_edit';
import Inventario_General from './components/inventario_general/inventario_general';
import Move_Inventario_General from './components/inventario_general/movimiento_inventario';
import Req_Inventario_General from './components/inventario_general/requerimiento_inventario';
import Chat_Dedalo from './components/chat_dedalo/chat';
import SistemaGC from '../src/components/sgc/sistemagestion';
import Formato_Seguimiento from '../src/components/seguimiento_actividades/seguimiento';

import FormatoSalida from './components/contabilidad/formato_salida/formatos_realizados';
import FormatoSalida_New from './components/contabilidad/formato_salida/formato_salida_new';
import FormatoSalida_Edit from './components/contabilidad/formato_salida/formato_salida_edit';
import FormatoVacaciones from './components/sgc/formatos/formatoVacaciones';
import FormatoVacacionesEdit from './components/sgc/formatos/formatoVacacionesEdit';
import FormatoVacacionesLista from './components/sgc/formatos/listavacaciones';
import FormatoPermiso from './components/sgc/formatos/formatoPermiso';
import FormatoPermisoEdit from './components/sgc/formatos/formatoPermisoEdit';
import FormatoPermisoLista from './components/sgc/formatos/listapermisos';
import Formularios_Zpazium from './components/zpazium/formularios';

function App() {
  const USER_SESSION_NAME = "dedalo_usr";
  const USER_SESSION_TOKEN = "dedalo_usr_token";
  const user = JSON.parse(localStorage.getItem(USER_SESSION_NAME));
  const token_user = JSON.parse(localStorage.getItem(USER_SESSION_TOKEN));
  const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));

  return (
    <div className="fondo_body">
        <Router>
          <UserSessionContextProvider userSession={user} TokenUser={token_user}>
            <Switch>
              <Route path="/" component={Login} exact/>
              {/*<Route path="/usuario_nuevo" component={UsuarioNuevo} exact/>*/}
              <Route path="/reset_password" component={RecuperaContraseña} exact/>
              <Route path="/set-password" component={RestablecerContraseña} exact/>
              <Route path="/Licencia_Empresa" component={Licencia} exact/>
              <div className="flex">
                <Sidebar/>
                <div className="content w-100">
                  <Navbar usuario={user === null ? '' : user.name}/>
                  <Mensajelicencia dias={data_emp === null ? 0 : data_emp.DiasLicence}/>
                  <Route path="/home" component={home} exact/>
                  <Route path="/tabla_usuario" component={TablaUsuarios} exact/>
                  <Route path="/editar_usuario/:id" component={Editarusuario} exact/>
                  <Route path="/reestablecer_contraseña" component={RecuperaContraseñaLogin} exact/>
                  <Route path="/crear_usuario_nuevo" component={CrearUsuarioNuevos} exact/>
                  <Route path="/Dedalo_perfil" component={DedaloPerfil} exact/>
                  <Route path="/Proyectos_Dedalo" component={ProyectosDedalo} exact/>
                  <Route path="/Proyectos" component={ProyectoHomi} exact/>
                  <Route path="/Proyecto_Nuevo" component={NuevoProyecto} exact/>
                  <Route path="/Proyecto_Editar/:idproject" component={EditarProyecto} exact/>
                  <Route path="/Editar_Cuadrilla/:idcuadrilla" component={EditarCuadrilla} exact/>
                  <Route path="/Linea_de_Tiempo/:Idtask" component={LineaDeTiempo} exact/>
                  <Route path="/Subtareas/:idtarea" component={Subtareas} exact/>
                  <Route path="/Nueva_Subtareas/:idtarea" component={NuevaSubtarea} exact/>
                  <Route path="/Editar_Subtarea" component={EditarSubtarea} exact/>
                  <Route path="/Info_cortes" component={InfoCortes} exact/>
                  <Route path="/Crm_Empresas" component={CrmEmpresas} exact/>
                  <Route path="/Requerimientos" component={Requerimientos} exact/>
                  <Route path="/Personal" component={PersonalDedalo} exact/>
                  <Route path="/Productos" component={Productos} exact/>
                  <Route path="/Proveedores" component={Proveedores} exact/>
                  <Route path="/Compartir_Archivos" component={Compartir} exact/>
                  <Route path="/Contenido_Carpeta/:idcontenido/:nom_crp" component={ContenidoCarpeta} exact/>
                  <Route path="/Informe" component={Informe} exact/>
                  <Route path="/Informe_pdf" component={InformePdf} exact/>
                  <Route path="/Reporte" component={ReporteGeneral} exact/>
                  <Route path="/Reporte_pdf" component={ReportePdf} exact/>
                  <Route path="/Movimiento_inventario" component={Movimiento} exact/>
                  <Route path="/Comentarios_Tareas/:Idtask" component={Comentarios_tareas} exact/>
                  <Route path="/Diagrama_tareas" component={Diagrama} exact/>
                  <Route path="/Nueva_reunion" component={ReunionNueva} exact/>
                  <Route path="/Editar_Acta/:IdActa" component={EditarReunion} exact/>
                  <Route path="/Nueva_tarea" component={TareaNueva} exact/>
                  <Route path="/Editar_tarea/:id_task" component={EditarTarea} exact/>
                  <Route path="/Dep_empresas/:IdDep" component={Departamentos_Emp} exact/>
                  <Route path="/Ver_Organigrama/:Id_emp" component={VerOrganigrama} exact/>
                  <Route path="/Crud_Flujograma/:Id_project" component={CrudFlujograma} exact/>
                  <Route path="/Nuevo_Flujograma/:Idproject" component={FlujogramaProyecto} exact/>
                  <Route path="/Editar_Flujograma/:Idproject/:IdFlujo" component={EditarFlujograma} exact/>
                  <Route path="/Ver_Flujograma/:Idproject/:IdFlujo" component={VerFlujograma} exact/>
                  <Route path="/Cronograma_proyect/:IdProyect" component={Cronograma} exact/>

                  {/*<Route path="/Menu_Contabilidad/:IdProjectContabilidad" component={Menu} exact/>*/}
                  <Route path="/Requisiciones" component={Req} exact/>
                  <Route path="/Requisicion_nueva" component={Req_nueva} exact/>
                  <Route path="/Requisicion_edit/:IdEditar" component={Req_editar} exact/>
                  <Route path="/Requisicion_Preview/:IdEditar" component={Req_Preview} exact/>
                  <Route path="/Ordenes_Compra" component={Ordenes} exact/>
                  <Route path="/Orden_nueva" component={Ord_nueva} exact/>
                  <Route path="/Orden_editar/:IdEditarOrd" component={Ord_editar} exact/>
                  <Route path="/Ver_Orden/:IdVer" component={Ver_ord} exact/>
                  <Route path="/Centro_Costos" component={Centro_cst} exact/>
                  <Route path="/Licitaciones" component={Cot_Licitaciones} exact/>
                  <Route path="/Licitaciones_nueva" component={Cot_Nueva} exact/>
                  <Route path="/Licitaciones_editar/:IdCotEdit" component={Cot_Editar} exact/>
                  <Route path="/Actas" component={Acta} exact/>
                  <Route path="/Acta_nueva" component={Acta_Nueva} exact/>
                  <Route path="/Acta_editar/:IdActaEdit" component={Acta_Editar} exact/>
                  <Route path="/Anticipos" component={Anticipo} exact/>
                  <Route path="/Anticipos_nueva" component={Ant_Nueva} exact/>
                  <Route path="/Anticipos_editar/:IdAntEdit" component={Ant_Editar} exact/>
                  <Route path="/Legalizacion_Ant" component={Leg_ant} exact/>
                  <Route path="/Legalizacion_Ant_Edit/:IdLegAntEdit" component={Leg_ant_edit} exact/>
                  <Route path="/Ficha_tecnica/:IdProjectFicha" component={FichaTecnica} exact/>
                  <Route path="/Elementos_no_conformes" component={Elem_no_Confor} exact/>
                  <Route path="/Elementos_no_conformes_new" component={Elem_no_Confor_New} exact/>
                  <Route path="/Elementos_no_conformes_edit/:IdElementEdit" component={Elem_no_Confor_Edit} exact/>
                  <Route path="/Cumplimiento_cronograma" component={Cumplimiento_crono} exact/>
                  <Route path="/Cumplimiento_crono_new" component={Cumplimiento_crono_New} exact/>
                  <Route path="/Cumplimiento_crono_edit/:IdElementEdit" component={Cumplimiento_crono_Edit} exact/>
                  <Route path="/Perfil_Cargo" component={PerfilCargo} exact/>
                  <Route path="/Perfil_Cargo_new" component={PerfilCargo_New} exact/>
                  <Route path="/Perfil_Cargo_edit/:IdElementEdit" component={PerfilCargo_Edit} exact/>
                  <Route path="/Menu_formatos" component={MenuFormatos} exact/>
                  <Route path="/Cuadro_Comparativo" component={CuadroComparativo} exact/>
                  <Route path="/Cuadro_Comparativo_New" component={CuadroComparativo_New} exact/>
                  <Route path="/Cuadro_Comparativo_Edit/:IdElementEdit" component={CuadroComparativo_Edit} exact/>
                  <Route path="/Evaluacion_Proveedor" component={EvaluacionProveedor} exact/>
                  <Route path="/Evaluacion_Proveedor_New" component={EvaluacionProveedor_New} exact/>
                  <Route path="/Evaluacion_Proveedor_Edit/:IdElementEdit" component={EvaluacionProveedor_Edit} exact/>
                  <Route path="/Reevaluacion_Proveedor" component={ReevaluacionProveedor} exact/>
                  <Route path="/Reevaluacion_Proveedor_New" component={ReevaluacionProveedor_New} exact/>
                  <Route path="/Reevaluacion_Proveedor_Edit/:IdElementEdit" component={ReevaluacionProveedor_Edit} exact/>
                  <Route path="/Select_Proveedor" component={SelectProveedor} exact/>
                  <Route path="/Select_Proveedor_New" component={SelectProveedor_New} exact/>
                  <Route path="/Select_Proveedor_Edit/:IdElementEdit" component={SelectProveedor_Edit} exact/>
                  <Route path="/Formato_Salida" component={FormatoSalida} exact/>
                  <Route path="/Formato_Salida_New" component={FormatoSalida_New} exact/>
                  <Route path="/Formato_Salida_Edit/:IdElementEdit" component={FormatoSalida_Edit} exact/>
                  <Route path="/Formularios_Zpazium" component={Formularios_Zpazium} exact/>
                  <Route path="/Inventario_General" component={Inventario_General} exact/>
                  <Route path="/Move_Inventario_General" component={Move_Inventario_General} exact/>
                  <Route path="/Req_Inventario_General" component={Req_Inventario_General} exact/>
                  <Route path="/Sistema_Gestion/:IdProjectContabilidad" component={SistemaGC} exact/>
                  <Route path="/Formato_Vacaciones" component={FormatoVacaciones} exact/>
                  <Route path="/Formato_Permiso" component={FormatoPermiso} exact/>
                  <Route path="/Formato_Permiso_Edit/:id" component={FormatoPermisoEdit} exact/>
                  <Route path="/Formatos_Permisos_lista" component={FormatoPermisoLista} exact/>
                  <Route path="/Formato_Vacaciones_Edit/:id" component={FormatoVacacionesEdit} exact/>
                  <Route path="/Formatos_Vacaciones_lista" component={FormatoVacacionesLista} exact/>
                  <Route path="/Formato_Seguimiento/:id" component={Formato_Seguimiento} exact/>
                  <Chat_Dedalo />
                </div>
              </div>
            </Switch>
          </UserSessionContextProvider>
        </Router>
    </div>
  );
}

export default App;
