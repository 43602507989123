import React, { useState, Fragment, useEffect } from "react";
import {
    Button, InputGroup, Modal, FormControl, Row, Col,
    Form, Nav, Table, DropdownButton, ButtonGroup, Dropdown,
    Card, Container,
} from 'react-bootstrap';
import {
    DEDALO_ALL_ADVANCES, DEDALO_DEL_ADVANCES, DEDALO_ALL_LEGALIZATION,
    DEDALO_DEL_LEGALIZATION,
} from "../../../assets/APIAdresses";
import { message_responses } from "../../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../../utils/utils";
import { useUserSession } from "../../../hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { Routes } from '../../../utils/routes';
import Swal from "sweetalert2";
import * as MdIcons from "react-icons/md";
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import * as BiIcons from "react-icons/bi";

const Anticipo_Inicio = () => {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const { userToken } = useUserSession();
    const { userId } = useUserSession();
    const { user } = useUserSession();

    const [categor, setCategor] = useState({
        datos: [],
    });

    const [categor2, setCategor2] = useState({
        datos: [],
    });

    const peticionGetCategor = async () => {
        const params_data = {
            enterprice_id: parseInt(data_emp.IdEmp),
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "number_advances",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_ADVANCES,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setCategor({
                        ...categor,
                        ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.4");
                }
            });

        return info1;
    };

    const peticionGetCategor2 = async () => {
        const params_data = {
            enterprice_id: parseInt(data_emp.IdEmp),
            search: "",
            perpage: 20000,
            page: 1,
            orderField: "number_legalization",
            order: "asc"
        };
        //console.log(params_data);
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_ALL_LEGALIZATION,
            headers: { Authorization: userToken },
            data: params_data
        })
            .then(response => {
                if (response.data.code === '200') {
                    let datanotice = response.data.data.records;
                    //console.log(datanotice.data);
                    setCategor2({
                        ...categor2,
                        ['datos']: datanotice,
                    });
                } else {
                    alert(message_responses[response.data.message]);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.5");
                } else {
                    alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos 3.5");
                }
            });

        return info1;
    };

    useEffect(() => {
        peticionGetCategor();
        peticionGetCategor2();
    }, []);

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });

    const peticionDelete = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_ADVANCES + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el item exitósamente", "", "success");
                    //window.location.reload();
                    peticionGetCategor();
                } else {
                    alert("ERROR: Server Error");
                }
            })
    };

    const peticionDeleteLegal = (iduser) => {
        let delId = iduser;
        //axios.delete(DEDALO_ELIMINAR_USUARIO+'&id='+delId)
        axios({
            method: 'DELETE',
            url: DEDALO_DEL_LEGALIZATION + '&id=' + delId,
            headers: { Authorization: userToken },
        })
            .then(response => {
                if (response.data.code === '200') {
                    Swal.fire("Se elimino el item exitósamente", "", "success");
                    //window.location.reload();
                    peticionGetCategor2();
                } else {
                    alert("ERROR: Server Error");
                }
            })
    };

    //console.log(categor);

    return (
        <div>
            <br />
            <div className="container-fluid d-grid gap-2 d-md-flex justify-content-md-start">
                <div className="d-flex w-100">
                    <div>
                        <Button className="btn btn-success rounded-circle me-2" size="sm"
                            onClick={() => { window.history.back() }} title="Regresar" style={{ backgroundColor: "#02B371", color: '#fff' }}
                        >
                            <MdIcons.MdArrowBackIosNew />
                        </Button>
                    </div>
                    <div>
                        <Link to={Routes.ANT_NUEVA}>
                            <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371", color: '#fff' }}>
                                Generar Anticipo nuevo
                            </Button>
                        </Link>
                    </div>
                    <div>
                        <Link to={Routes.LEGALIZACION_ANT}>
                            <Button className="btn btn-success me-2" size="sm" style={{ backgroundColor: "#02B371", color: '#fff' }}>
                                Generar Legalizacion
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <br />
            <div className="container-fluid">
                <Row>
                    <Col md={7}>
                        <Card>
                            <Card.Header>Anticipos Generados</Card.Header>
                            <Card.Body>
                                <Table responsive striped size="sm">
                                    <thead>
                                        <tr className="text-center">
                                            <th>#</th>
                                            <th>anticipo No.</th>
                                            <th>fecha anticipo</th>
                                            <th>Solicitado por</th>
                                            <th>Cargo</th>
                                            <th>Valor anticipo</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categor.datos === undefined ?
                                            ''
                                            :
                                            categor.datos.map((x, index) => (
                                                <tr key={index} className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{x.number_advances}</td>
                                                    <td>{x.advance_day + '-' + x.advance_month + '-' + x.advance_year}</td>
                                                    <td>{x.nameUser}</td>
                                                    <td>{x.position}</td>
                                                    <td>{formatterPeso.format(x.totalvalue)}</td>
                                                    <td>
                                                        {userId === parseInt(x.users_id) ?
                                                            <div class="dropdown">
                                                                <Button className="rounded-circle btn btn-light" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <HiIcons.HiDotsVertical class="cursor-pointer dropdown-toggle" />
                                                                </Button>
                                                                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">

                                                                    <Link to={Routes.ANT_EDITAR(x.id)} className='text-decoration-none'>
                                                                        <li>
                                                                            <a class="dropdown-item cursor-pointer">
                                                                                Editar
                                                                            </a>
                                                                        </li>
                                                                    </Link>
                                                                    <li>
                                                                        <a class="dropdown-item cursor-pointer" onClick={() => peticionDelete(x.id)}>
                                                                            Eliminar
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={5}>
                        <Card>
                            <Card.Header>Legalizaciones Generadas</Card.Header>
                            <Card.Body>
                                <Table responsive striped size="sm">
                                    <thead>
                                        <tr className="text-center">
                                            <th>#</th>
                                            <th>Num Legalizacion</th>
                                            <th>Año Legalizacion</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {categor2.datos === undefined ?
                                            ''
                                            :
                                            categor2.datos.map((l, index) => (
                                                <tr className="text-center">
                                                    <td>{index + 1}</td>
                                                    <td>{l.number_legalization}</td>
                                                    <td>{l.legalization_year}</td>
                                                    <td>
                                                        <div class="dropdown">
                                                            <Button className="rounded-circle btn btn-light" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <HiIcons.HiDotsVertical class="cursor-pointer dropdown-toggle" />
                                                            </Button>
                                                            <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                                <Link to={Routes.LEGALIZACION_ANT_EDIT(l.id)} className='text-decoration-none'>
                                                                    <li>
                                                                        <a class="dropdown-item cursor-pointer">
                                                                            Editar
                                                                        </a>
                                                                    </li>
                                                                </Link>
                                                                <li>
                                                                    <a class="dropdown-item cursor-pointer" onClick={() => peticionDeleteLegal(l.id)}>
                                                                        Eliminar
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Anticipo_Inicio;