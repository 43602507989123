import React, {useState, Fragment, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { 
  Button, InputGroup, FormControl, Container, Row, Col,
  Form, Table, Card,
} from 'react-bootstrap';
import {
    DEDALO_GET_ALL_USERS_BY_ID, DEDALO_COMBO_CONSTRUCTION,DEDALO_ACTUALIZAR_USUARIO,
    DEDALO_GET_ALL_USERS,DEDALO_CRM_ENTERPRICES_COMBO,DEDALO_DEPARTAMENT_COMBO,
} from "../../assets/APIAdresses";
import {message_responses} from "../../assets/MESSAGE_RESPONSES";
import axios from "axios";
import {
    attachToken,
    manageError,
} from "../../utils/utils";
import {useUserSession} from "../../hooks";
import Swal from "sweetalert2";
import {Redirect, useParams} from "react-router-dom";
import { Routes } from "../../utils/routes";
import * as Faicons from 'react-icons/fa';
import * as Mdicons from 'react-icons/md';
import * as Bsicons from 'react-icons/bs';
import Navbar from '../navbar';
import logo from '../../imagenes/dedalo_logo.png';

function EditarUsuario() {
    const data_emp = JSON.parse(localStorage.getItem('Nombre_empresa'));
    const {userToken} = useUserSession();    
    const {userId} = useUserSession();
    const {user} = useUserSession(); 
    const [infoimg, setInfoImg] = useState([]);
    const [confirma, setConfirma] = useState(0);
    const [age, setAge] = useState('');
    const [rolusuario, setRolUsuario] = useState('');
    const [genero, setGenero] = useState('');
    const [comboemp1, setComboEmp1] = useState();
    const [depselect, setDepSelect] = useState('');
    
    const [categor, setCategor] = useState({
        datos: [],
    });

    const [comboconstruction, setComboConstruction] = useState({
        datos: [],
    });

    const [comboemp, setComboEmp] = useState({
        datos: [],
    });

    const [combodep, setComboDep] = useState({
        datos: [],
    });

    const [dats, setDat] = useState({
        datos: [],
        correo: '',
        name: '',
        tel1: '',
        tel2: '',
        dir: '',
        image: '',
        rol_user: '',
        sex: '',
        empresa: [],
    });
    
    const {id} = useParams();

    const handleInputChange = (event) => {   
        setDat({
            ...dats,
            [event.target.name] : event.target.value
        })
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleChangeEmp = (event) => {
        setComboEmp1(event.target.value);
    };

    const handleChangeRolUsuario = (event) => {
        setRolUsuario(event.target.value);
    };

    const handleChangeGenero = (event) => {
        setGenero(event.target.value);
    };

    const handleChangeDepartment = (event) => {
        setDepSelect(event.target.value);
    };

    const found = dats.empresa.find(element => parseInt(element.idEnterprice) === data_emp.IdEmp);
    let found2 = '';
    let found3 = 0;

    if (found === undefined) {
        found2 = '';
        found3 = '';
    }else{
        found2 = found.nameDepartment;
        found3 = found.idDepartment;
    };
    
    const peticionGetCategor=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
            search: '',
            perpage: 100,
            page: 1,
            orderField: 'name',
            order: 'desc',
        };
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS_BY_ID+'&id='+id)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_GET_ALL_USERS,
            headers: {Authorization: userToken},
            data: params_data
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.data.records;
                //console.log(datanotice);
                setCategor({
                    ...categor,
                    ['datos']: datanotice,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
        })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos - 225");
              }
          });
      
        return info1;
    };

    const peticionGetUserById=async()=>{
        //let info1 = await axios.get(DEDALO_GET_ALL_USERS_BY_ID(id))
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_GET_ALL_USERS_BY_ID+'&id='+id,
            headers: {Authorization: userToken},
        })
        .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                  console.log(datanotice);
                  setDat({
                    ...dats,
                    ['datos']: datanotice,
                    ['correo']: datanotice.email,
                    ['name']: datanotice.name,
                    ['tel1']: datanotice.phone1,
                    ['tel2']: datanotice.phone2,
                    ['dir']: datanotice.address,
                    ['image']: datanotice.image,
                    ['rol_user']: datanotice.role_id,
                    ['sex']: datanotice.sex,
                    ['empresa']: datanotice.Enterprices,
                  });
              } else {
                 alert(message_responses[response.data.message]);
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetComboConstruction=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_COMBO_CONSTRUCTION)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_COMBO_CONSTRUCTION,
            headers: {Authorization: userToken},
            data: params_data,
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg.data;
                //console.log(datanotice);
                setComboConstruction({
                    ...comboconstruction,
                    ['datos']: datanotice,
                });
              } else {
                setComboConstruction({
                    ...comboconstruction,
                    ['datos']: [],
                });
              }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los productos");
              }
          });
      
        return info1;
    };

    const peticionGetComboEmp=async()=>{
        //let info1 = await axios.get(DEDALO_COMBO_CONSTRUCTION)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_CRM_ENTERPRICES_COMBO,
            headers: {Authorization: userToken},
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setComboEmp({
                    ...comboemp,
                    ['datos']: datanotice.data,
                });
            } else {
                alert(message_responses[response.data.message]);
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };

    const peticionGetComboDep=async()=>{
        const params_data = {
            enterprice_id: data_emp.IdEmp,
        };
        //let info1 = await axios.get(DEDALO_COMBO_CONSTRUCTION)
        let info1 = await axios({
            method: 'POST',
            url: DEDALO_DEPARTAMENT_COMBO,
            headers: {Authorization: userToken},
            data: params_data,
        })
          .then(response=>{
            if (response.data.code === '200') {
                let datanotice = response.data.msg;
                //console.log(datanotice);
                setComboDep({
                    ...combodep,
                    ['datos']: datanotice.data,
                });
            } else {
                setComboDep({
                    ...combodep,
                    ['datos']: [],
                });
            }
          })
          .catch(error => {
              if (error.response && error.response.data) {
                alert(
                  error.response.data.message === "VALIDATOR_ERROR"
                    ? error.response.data.data.error_message
                    : message_responses[error.response.data.message]
                );
              } else {
                alert("Por favor discúlpanos :| , hemos tenido un error al cargar los datos");
              }
          });
      
        return info1;
    };
    
    const handleCloseSave = (e) => {
        let email = dats.correo;
        let name = dats.name;
        let tel1 = dats.tel1;
        let tel2 = dats.tel2;
        let dir = dats.dir;
        
        const formattedData = {
            email: email,
            name: name,
            phone1: tel1,
            phone2: tel2,
            address: dir,
            sex: genero === '' ? dats.sex : genero,
            role_id: rolusuario.length === 0 ? dats.rol_user : rolusuario,
            construction_id: age,
            department: depselect === '' ? parseInt(found3) : parseInt(depselect),
            enterprice_id: data_emp.IdEmp,
            image: infoimg.length === 0 ? dats.image : infoimg,
        };
        //console.log(formattedData);
        axios({
            method: 'PUT',
            url: DEDALO_ACTUALIZAR_USUARIO+'&id='+id,
            headers: {Authorization: userToken},
            data: formattedData
        })
        .then(response => {
            if (response.data.code === '200') {
                Swal.fire("Se actualizó la Informacion exitósamente", "", "success");
                setConfirma(1);
                peticionGetUserById();
            } else {
                alert("ERROR: Server Error");
            }
        })
        .catch(error => {
            manageError(error);
        });
    };
    
    useEffect(() => {
        peticionGetCategor();
        peticionGetUserById();
        peticionGetComboConstruction();
        peticionGetComboEmp();
        peticionGetComboDep();
    }, []);

    function encodeImageFileAsURL() {
        var file = document.getElementById("icon-button-file").files[0];
        var reader = new FileReader();
        reader.onloadend = function() {
          setInfoImg(reader.result);
        }
        reader.readAsDataURL(file);
    };

    

    //console.log(combodep);

    if(!user){
        window.location.href = Routes.LOGIN;
    }
    
    return (
        <div>
            {/*<Navbar modulo="Editar usuarios" usuario={user.name}/>*/}
            <div className="w-100 mt-5">
                <Container>
                    <Card className="botonesaccion">
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col md={6} xs={12} className="text-center">
                                        <div className="contentimgedit">
                                            {dats.image === '' || dats.image === null || dats.image.split('/')[0] === 'Recordings' ?
                                                <img
                                                    src={logo} class="img-thumbnail" width="200" height="180" 
                                                    title={dats.name} alt={dats.name}
                                                />
                                            :
                                                <img
                                                    src={dats.image} class="img-thumbnail" width="200" height="180" 
                                                    title={dats.name} alt={dats.name}
                                                />
                                            }
                                        </div>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                            <FormControl
                                                placeholder="Correo" aria-label="Correo"
                                                aria-describedby="basic-addon1" value={dats.correo}
                                                type="email" onChange={handleInputChange}
                                                id="correo" name="correo" 
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <Bsicons.BsFillPersonFill/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Nombre" aria-label="Nombre"
                                                aria-describedby="basic-addon1" value={dats.name}
                                                type="text" onChange={handleInputChange}
                                                id="name" name="name"
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <Bsicons.BsFillTelephoneFill/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Telefono 1" aria-label="Telefono 1"
                                                aria-describedby="basic-addon1" value={dats.tel1}
                                                type="text" onChange={handleInputChange}
                                                id="tel1" name="tel1"
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <Bsicons.BsFillTelephoneFill/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Telefono 2" aria-label="Telefono 2"
                                                aria-describedby="basic-addon1" value={dats.tel2}
                                                type="text" onChange={handleInputChange}
                                                id="tel2" name="tel2"
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto"}}>
                                            <InputGroup.Text id="basic-addon1">
                                                <Mdicons.MdAddLocationAlt/>
                                            </InputGroup.Text>
                                            <FormControl
                                                placeholder="Dirección" aria-label="Dirección"
                                                aria-describedby="basic-addon1" value={dats.dir}
                                                type="text" onChange={handleInputChange}
                                                id="dir" name="dir"
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <Bsicons.BsFillPeopleFill/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeGenero} id="select_sex" name="select_sex"
                                            >
                                                <option value="">{dats.sex === 'male' ? 'Hombre' : 'Mujer'}</option>
                                                <option value="female">Mujer</option>
                                                <option value="male">Hombre</option>
                                            </Form.Select>
                                        </InputGroup>
                                        {user.role_id === 3 || user.role_id === 2 ?
                                            <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                                <InputGroup.Text id="basic-addon1">
                                                    <Bsicons.BsFillPeopleFill/>
                                                </InputGroup.Text>
                                                <Form.Select aria-label="Default select example"
                                                    onChange={handleChange} id="selectproyecto" name="selectproyecto"
                                                >
                                                    <option value="">Seleccione un proyecto</option>
                                                    {comboconstruction.datos.map(i => (
                                                        <option key={i.id} value={i.id}>{i.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </InputGroup>
                                        :
                                            ''
                                        }
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <Bsicons.BsFillPeopleFill/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeRolUsuario} id="select_rol" name="select_rol"
                                            >
                                                <option value="">
                                                    {parseInt(dats.rol_user) === 1 ? 
                                                        'Usuario' 
                                                    :parseInt(dats.rol_user) === 2 ? 
                                                        'Administrador' 
                                                    :parseInt(dats.rol_user) === 3 ? 
                                                        'Soporte' 
                                                    :parseInt(dats.rol_user) === 4 ? 
                                                        'Supervisor'
                                                    :parseInt(dats.rol_user) === 5 ? 
                                                        'Planos'
                                                    :
                                                        'Almacenista'
                                                    }
                                                </option>
                                                <option value={1}>Usuario</option>
                                                <option value={2}>Administrador</option>
                                                <option value={3}>Soporte</option>
                                                <option value={5}>Planos</option>
                                                <option value={6}>Almacenista</option>
                                                <option value={7}>Supervisor</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <InputGroup className="mb-3" style={{width: "100%", margin: "auto", }}>
                                            <InputGroup.Text id="basic-addon1">
                                                <Faicons.FaBuilding/>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Default select example"
                                                onChange={handleChangeDepartment} id="selectemp" name="selectemp"
                                            >
                                                <option>{found2 === '' ? 'seleecione el departamanto' : found2}</option>
                                                {combodep.datos.map((i,index) => (
                                                    <option key={index} value={i.id}>{i.name}</option>
                                                ))}
                                            </Form.Select>
                                        </InputGroup>
                                        <input accept="image/*" type="file" id="icon-button-file" name="icon-button-file" onChange={()=>encodeImageFileAsURL()}/>
                                    </Col>
                                </Row>
                                <Row className="text-center">
                                    <Col md={12} xs={12} style={{marginTop: "4%"}}>
                                        <Button className="btn btn-success me-2" style={{backgroundColor: "#02B371"}}
                                            onClick={()=>{window.history.back(); peticionGetCategor();}}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button className="btn btn-success" style={{backgroundColor: "#02B371"}}
                                            onClick={()=>handleCloseSave()}
                                        >
                                            Editar Información
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <br/>
                    
                </Container>
            </div>
            
        </div>
        
    );
}

export default EditarUsuario;