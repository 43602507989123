// TODO : remove this file
export const message_responses = {
  GET_ALL_COMMMERCIAL_SPACES_ERROR: "No pudimos cargar los espacios comerciales.",
  CREATE_VIEW_SUCCESS: "La vista al espacio comercial ha sido creada.",
  USER_WITHOUT_VIEWS: "No Tienes vistas",
  CREATE_COMMERCIAL_SPACE_VIEW_SUCCESS:
    "Ahora podras acceder a la información del sitio.",
  COMMERCIAL_SPACE_IS_ALREADY_SALE_REQUEST_FOR_BUYER:
    "El espacio comercial ya esta en solicitud de compra.",
  CREATE_SALE_REQUEST_SUCCESS:
    "La solicitud de compra ha sido creada, te enviaremos a la sala de chats.",
  IS_NOT_OWNER_COMMERCIAL_SPACE: "No eres el dueño del espacio comercial ",

  CREATE_RENT_REQUEST_SUCCESS:
    "La solicitud de arrendamiento ha sido creada, te enviaremos a la sala de chats.",
  COMMERCIAL_SPACE_IS_ALREADY_RENT_REQUEST_FOR_LESSEE:
    "Ya hay una solicitud de arrendamiento por parte del usuario.",
  "The lessee id and lessor id must be different.":
    "El Id del usuario debe ser diferente al arrendatario",

  CREATE_CHAT_SUCCESS: "Un chat ha sido creado.",

  msg: "Crm vendors already in use"
};
